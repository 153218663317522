import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { MobileInput } from '../../components/Input';
import Stepper from '../../components/Stepper';
import { useAuth } from '../../context/AuthProvider';
import './Auth.scss';
import { useAlert } from 'react-alert';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';
import { MOBILE_REGEX } from '../../const/shared';

const VerifyPhone = () => {
  const [isPosting, setIsPosting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobile: '',
    },
  });

  const {
    verifyPhone,
    newUserDetails,
    setNewUserDetails,
    navState,
    setNavState,
  } = useAuth();

  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    RudderStack.page('Auth_VerifyPhone');
  }, []);

  useEffect(() => {
    if (navState > 4) {
      return;
    }

    setNavState(4);
  });

  const navigate = useNavigate();
  const alert = useAlert();

  const onSubmit = async (data: { mobile: string }) => {
    const mobile = `+${data.mobile}`;
    if (isPosting) {
      return;
    }

    setIsPosting(true);
    try {
      setNewUserDetails({
        ...newUserDetails,
        mobile: mobile,
      });
      const res = await verifyPhone(mobile);
      setIsPosting(false);
      if (res) {
        navigate('/auth/verifyPhoneSubmit');
      }
    } catch (error: any) {
      setIsPosting(false);
      alert.error(error.message.toString());
    }
  };

  return (
    <AuthWrapper>
      <>
        <Stepper
          numberOfSteps={5}
          containerStyle="auth-stepper"
          currentStep={navState}
        />
        <p className="auth-create-account-title">Verify your account</p>
        <p className="auth-create-account-subtitle">
          Please enter your phone number, so we can send you a verification code
          and verify your identity.
        </p>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: MOBILE_REGEX,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <MobileInput
                error={!!errors.mobile}
                onChange={onChange}
                value={value}
              />
              {errors.mobile && (
                <div className="purchase-error-text">
                  <ErrorText
                    text={
                      errors.mobile.type === 'pattern' && value !== '61'
                        ? 'Please enter a valid Australian phone number'
                        : 'Phone number is required'
                    }
                  />
                </div>
              )}
            </>
          )}
          name="mobile"
        />
        <div className="auth-button">
          <Button
            title="Send Code"
            role="Secondary"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </>
    </AuthWrapper>
  );
};

export default VerifyPhone;
