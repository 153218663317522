import React from 'react';
import ArrowBack from '../../../../components/ArrowBack';
import { ReactComponent as BlackColorLogo } from '../../../../assets/svg/logo/FullColorBlack.svg';

import './styles.scss';
import Faq from '../Faq';
import { useNavigate } from 'react-router-dom';

interface OwnProps {
  showFAQ?: boolean;
  backText?: string;
  showBack?: boolean;
}

const ActivationTopBar: React.FC<OwnProps> = ({
  showFAQ = true,
  backText = 'BACK TO CARDS',
  showBack = true,
}) => {
  const navigate = useNavigate();

  return (
    <div className="activation-topbar">
      <div className="top">
        <div className="logo" onClick={() => navigate('/')}>
          <BlackColorLogo width={116} height={34} />
        </div>

        <div className="mobileTop">
          <div className="back">
            {showBack && <ArrowBack text={backText} />}
          </div>
          {showFAQ && <Faq />}
        </div>
      </div>
    </div>
  );
};

export default ActivationTopBar;
