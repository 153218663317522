import React from 'react';

import './styles.scss';

interface OwnProps {
  value: boolean;
  onChange: () => void;
}

const Switch: React.FC<OwnProps> = ({ value, onChange }) => {
  return (
    <label className="purchase-switch">
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className="slider round" />
    </label>
  );
};

export default Switch;
