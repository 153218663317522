import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserGiftCard } from '..';
import { useApi } from '../../../api/ApiProvider';
import SectionItem from '../../../components/SectionItem';
import { map } from '../../../const/theme';
import {
  Datum,
  GiftCardProgramType,
  UserGiftCardStatus,
} from '../../../generated';
import { getMoneyDisplay } from '../../../util/helpers';
import TransactionItem from './TransactionItem';
import TransactionLoader from './TransactionLoader';
import ArrowRight from '../../../assets/svg/side-drawer/ArrowRight.svg';
import Scroller from '../../../components/Scroller';
import Colors from '../../../const/Colors';
import { MAX_PAGINATION_TAKE_SIZE } from '../../../shared/const';
import Loader from '../../../components/Loader';

type Props = {
  userGiftCard?: UserGiftCard;
};

const Header = styled.div`
  display: flex;
  gap: 3rem;
  img {
    flex: 0 0 15rem;
    width: 15rem;
    height: 9rem;
    border-radius: 15px;
  }

  div.name {
    font-weight: bold;
    font-size: 2rem;
    ${map({ tablet: '2.9rem' }, (size) => `font-size: ${size};`)}
    line-height: 2.8rem;
    font-family: Agrandir-TextBold;
    ${map({ tablet: '40rem' }, (maxWidth) => `max-width: ${maxWidth};`)};
    text-overflow: ellipsis;
    overflow: hidden;

    // two line ellipsis
    display: -webkit-box !important;
    ${map({ tablet: 'block !important' }, (display) => `display: ${display};`)};
    -webkit-line-clamp: 2;
    ${map({ tablet: '1' }, (lineClamp) => `-webkit-line-clamp: ${lineClamp};`)};
    -webkit-box-orient: vertical;
    white-space: normal;
    ${map({ tablet: 'nowrap' }, (whiteSpace) => `white-space: ${whiteSpace};`)};
  }

  div.value {
    font-size: 1.7rem;
    ${map({ tablet: '2rem' }, (size) => `font-size: ${size};`)}
    line-height: 2.8rem;
  }
`;

const Container = styled.div`
  flex: 1;
`;

const RecentActivityContainer = styled.div`
  margin-top: 2rem;
  .no-activity {
    font-weight: bold;
    font-family: Agrandir-TextBold;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colours.line1};
  }
  .view-all {
    font-weight: bold;
    font-family: Agrandir-TextBold;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colours.black70};
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    img {
      width: 1.6rem;
    }
  }
`;

const Divider = styled.div`
  margin: 2rem 0;
  border-top: 1px solid ${(props) => props.theme.colours.border};
`;

const SectionHeader = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colours.line1};
  line-height: 1.6rem;
  margin: 2rem 0;
`;

type MyCardImgContainerProps = {
  width: number;
  height: number;
};

const MyCardImgContainer = styled.div<MyCardImgContainerProps>`
  position: relative;
  overflow: hidden;
  transition: width 0.5s;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
  position: relative;
  min-width: ${(props) => `${props.width}px`};
  min-height: ${(props) => `${props.height}px`};

  .scroller-container {
    height: 1.5rem;
    margin: 0;
    position: absolute;
    top: calc(50% - 1rem);
    left: -2%;
    transform: rotate(-5deg);
    padding: 0.35rem;
    width: 103%;
    * {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }
  }

  > img {
    border-radius: 0.8rem !important;
    ${map(
      { tablet: '3.6rem' },
      (borderRadius) => `border-radius: ${borderRadius};`
    )};
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  ${map(
    { tablet: 'calc(100% - 7rem)' },
    (minHeight) => `min-height: ${minHeight};`
  )};
`;

const AffiliationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: ${Colors.black};
  margin-bottom: 2.1rem;
`;

const RecentActivityText = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${Colors.line1};
  font-weight: 700;
  margin-bottom: 2rem;
`;

const getProperScroller = (cardStatus?: UserGiftCardStatus) => {
  const scrollerSpeed = 50;
  if (cardStatus === UserGiftCardStatus.Blocked) {
    return (
      <Scroller content="SUSPENDED • SUSPENDED • " speed={scrollerSpeed} />
    );
  } else if (cardStatus === UserGiftCardStatus.Cancelled) {
    return (
      <Scroller content="CANCELLED • CANCELLED • " speed={scrollerSpeed} />
    );
  }
};

const MyCardDetails = ({ userGiftCard }: Props) => {
  const { cardTransactionApi, merchantApi } = useApi();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<Datum | undefined>(undefined);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [isMerchantsLoading, setIsMerchantsLoading] = useState(true);
  const [merchantLocations, setMerchantLocations] = useState(0);
  const getCardTransactions = useCallback(
    async (userGiftCardId: number) => {
      setLoadingTransactions(true);
      const { data } = await cardTransactionApi.getCardTransactions(
        userGiftCardId
      );
      const userTransactions = data?.data || [];
      setTransaction(userTransactions[0] ?? undefined);
      setLoadingTransactions(false);
    },
    [cardTransactionApi]
  );

  useEffect(() => {
    if (!!userGiftCard?.id) {
      getCardTransactions(userGiftCard.id);
    }
  }, [userGiftCard?.id, getCardTransactions]);

  const retailersDisplay = userGiftCard?.details?.giftCard?.retailersCount ?? 0;

  const isPastCard = useMemo(() => {
    return (
      userGiftCard?.status === UserGiftCardStatus.Past ||
      userGiftCard?.status === UserGiftCardStatus.Blocked ||
      userGiftCard?.status === UserGiftCardStatus.Cancelled
    );
  }, [userGiftCard?.status]);

  const { displayAffiliationText, giftCardName } = userGiftCard || {};
  const cardName = useMemo(() => {
    return displayAffiliationText ? `${giftCardName}*` : `${giftCardName}`;
  }, [displayAffiliationText, giftCardName]);

  const isBranded = useMemo(
    () => userGiftCard?.giftCardProgramType === GiftCardProgramType.Brand,
    [userGiftCard]
  );
  const merchantId = useMemo(
    () => userGiftCard?.brandMerchant?.id ?? 0,
    [userGiftCard?.brandMerchant?.id]
  );

  useEffect(() => {
    (async () => {
      if (isBranded) {
        try {
          setIsMerchantsLoading(true);
          const merchantLocationResponse =
            await merchantApi.getMerchantLocations(
              merchantId,
              undefined,
              undefined,
              undefined,
              undefined,
              MAX_PAGINATION_TAKE_SIZE,
              undefined
            );
          const allLocations = merchantLocationResponse.data.results || [];
          setMerchantLocations(allLocations.length);
          setIsMerchantsLoading(false);
        } catch (error) {
          setIsMerchantsLoading(false);
          console.log(error);
        }
      }
      setIsMerchantsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBranded, merchantId]);

  const isMerchantOnly = useMemo(() => {
    return merchantLocations === 0 && isBranded;
  }, [merchantLocations, isBranded]);

  if (!userGiftCard) {
    return <></>;
  }

  return (
    <DetailsWrapper>
      <Header>
        <MyCardImgContainer width={125} height={75}>
          <img
            src={userGiftCard.frontImage ?? ''}
            alt={userGiftCard.giftCardName ?? ''}
          />
          {getProperScroller(userGiftCard.status)}
        </MyCardImgContainer>
        <div style={{ overflow: 'hidden' }}>
          <div className="name">{cardName}</div>
          <div className="value">
            {getMoneyDisplay(userGiftCard.details?.cardBalances)} remaining
          </div>
        </div>
      </Header>
      <Container>
        <RecentActivityContainer>
          {loadingTransactions ? (
            <TransactionLoader />
          ) : transaction ? (
            <>
              <RecentActivityText>RECENT ACTIVITY</RecentActivityText>
              <TransactionItem data={transaction} />
              <div
                className="view-all"
                onClick={() => {
                  navigate(`/my-cards/${userGiftCard.id}/transactions`);
                }}>
                VIEW ALL TRANSACTIONS
                <img src={ArrowRight} alt="View all" />
              </div>
            </>
          ) : (
            <div className="no-activity">NO RECENT ACTIVITY</div>
          )}
        </RecentActivityContainer>
        <Divider />
        {!isPastCard && !isMerchantsLoading ? (
          <>
            <SectionHeader>USE YOUR CARD</SectionHeader>
            {!isMerchantOnly && isBranded ? (
              <SectionItem
                onClick={() => {
                  navigate(`/my-cards/${userGiftCard.id}/brand-retailers`);
                }}
                color="red"
                header="See where you can shop"
                description={`Shop at ${merchantLocations} locations`}
              />
            ) : (
              !isBranded && (
                <SectionItem
                  onClick={() => {
                    navigate(`/my-cards/${userGiftCard.id}/retailers`);
                  }}
                  color="red"
                  header="See where you can shop"
                  description={`Shop at ${retailersDisplay.toLocaleString(
                    'en-US'
                  )} retailers`}
                />
              )
            )}
            {!isMerchantOnly && (
              <SectionItem
                onClick={() => {
                  navigate(`/my-cards/${userGiftCard.id}/spend-in-store`);
                }}
                color="orange"
                header="Spend in-store"
                description="Connect your Apple or Google wallet"
              />
            )}
            <SectionItem
              onClick={() => {
                navigate(`/my-cards/${userGiftCard.id}/spend-online`);
              }}
              color="lilac"
              header="Spend online"
              description="Grab your card details"
            />
            <Divider />
            <SectionHeader>MANAGE YOUR CARD</SectionHeader>
            <SectionItem
              onClick={() => {
                navigate(`/my-cards/${userGiftCard.id}/manage-pin`);
              }}
              color="seaGreen"
              header="Manage PIN"
              description="Change or reset PIN"
            />
          </>
        ) : isPastCard ? null : (
          <Loader />
        )}
      </Container>
      {displayAffiliationText && (
        <AffiliationText>
          *This brand is not affiliated with Special. This gift card isn’t
          issued by this brand and there’s no commercial relationship between
          the two companies.
        </AffiliationText>
      )}
    </DetailsWrapper>
  );
};

export default MyCardDetails;
