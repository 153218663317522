import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ErrorText } from '../../../../../components/ErrorText';
import { Input, Select } from '../../../../../components/Input';
import { map } from '../../../../../const/theme';
import { useAppContext } from '../../../../../context/AppProvider';
import { getTimeZoneDisplay } from '../../../../../helpers/cards';
import { getFormattedTime, getInitialTime } from '../../../../../helpers/utils';
import Calendar from '../Calender';
import { Months, Years } from './constants';

import './styles.scss';

const DeliveryGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const DeliveryItem = styled.div`
  width: 48%;
  font-size: 1.5rem;
  font-weight: normal;
`;

const DeliveryText = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.9rem;
  letter-spacing: 0.2rem;
  margin-bottom: 2rem;
`;

const TimeGrid = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  margin-top: 2rem;
`;

const TimeItem = styled.div`
  text-align: center;
  max-width: 25%;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 0.4rem;
`;

const AMPMWrapper = styled.div`
  text-align: center;
  max-width: 25%;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 0.4rem;
  margin-right: 0;
  ${map(
    { tablet: '1.4rem' },
    (marginRight) => `margin-right: ${marginRight};`
  )};
`;

const AMPM = styled.div`
  border-radius: 5rem;
  width: 10rem;
  height: 5rem;
  max-height: 5rem;
  border: 0.1rem solid #cbd0d6;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  height: 0.1rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  ${map({ tablet: '4.8rem' }, (size) => `margin-bottom: ${size};`)};
`;

const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
  event.target.select();
};

interface props {
  setDate: any;
  date?: string;
  timeZoneId?: number;
  isTimeZoneSelected?: boolean;
  setIsTimeZoneSelected?: (value: boolean) => void;
  isValidDate?: boolean;
  setIsValidDate?: (value: boolean) => void;
}

const DateTimePicker = ({
  date,
  setDate,
  timeZoneId,
  isTimeZoneSelected,
  setIsTimeZoneSelected,
  isValidDate = true,
  setIsValidDate,
}: props) => {
  const { timezones } = useAppContext();
  const [timeZoneIdSelected, setSelectedTimeZoneId] = useState(timeZoneId);
  const selectedTimezone = timezones.find((x) => x.id === timeZoneIdSelected);

  const currentDate = moment.utc(date);
  const tomorrow = moment.utc().add(1, 'day');

  const [month, setMonth] = useState(
    parseInt(getInitialTime('MM', date, selectedTimezone)) - 1 ||
      currentDate.month() ||
      tomorrow.month()
  );
  const [year, setYear] = useState(
    parseInt(getInitialTime('yyyy', date, selectedTimezone)) ||
      currentDate.year() ||
      tomorrow.year()
  );
  const [day, setDay] = useState(
    parseInt(getInitialTime('DD', date, selectedTimezone)) ||
      currentDate.date() ||
      tomorrow.date()
  );

  const [hour, setHour] = useState(
    getInitialTime('hh', date, selectedTimezone) || '07'
  );
  const [min, setMin] = useState(
    getInitialTime('mm', date, selectedTimezone) || '00'
  );
  const [am, setAm] = useState(
    getInitialTime('a', date, selectedTimezone) || 'am'
  );

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    let hourInt = parseInt(hour ?? '0');
    let minInt = parseInt(min ?? '0');
    if (am === 'pm' && hourInt < 12) {
      hourInt += 12;
    } else if (hourInt > 12) {
      hourInt %= 12;
    } else {
      hourInt = am === 'am' && hourInt === 12 ? 0 : hourInt;
    }

    const selectedTimezone = timezones.find((x) => x.id === timeZoneIdSelected);

    const currentUtcDate = moment.utc();

    let selectedUtcDate = moment
      .utc([year, month, day, hourInt, minInt])
      .add(-(selectedTimezone?.timeZoneDifference ?? 0), 'minutes');

    if (selectedUtcDate.isBefore(currentUtcDate)) {
      setIsValidDate && setIsValidDate(false);
      selectedUtcDate = currentUtcDate;
    } else {
      setIsValidDate && setIsValidDate(true);
    }
    setDate(selectedUtcDate.toISOString(), timeZoneIdSelected);
    // eslint-disable-next-line
  }, [month, year, day, timeZoneIdSelected, hour, min, am]);

  const timezoneOptions = useMemo(() => {
    return timezones.map((x) => {
      return {
        label: getTimeZoneDisplay(x),
        value: x.id,
      };
    });
  }, [timezones]);

  const onHourBlur = useCallback(() => {
    if (!hour) {
      setHour('12');
    } else if (hour === '0') {
      setHour('01');
    } else {
      setHour(getFormattedTime(hour));
    }
  }, [hour]);

  const onMinuteBlur = useCallback(() => {
    if (!min) {
      setMin('00');
    } else {
      setMin(getFormattedTime(min));
    }
  }, [min]);

  return (
    <div className="dateTimePicker">
      <DeliveryGrid>
        <DeliveryItem>
          <Select
            options={Months}
            onChange={(value) => {
              const { value: monthValue } = value;

              if (monthValue === 0) {
                setMonth(0);
              } else if (monthValue) {
                setMonth(+monthValue);
              }
            }}
            value={month}
          />
        </DeliveryItem>
        <DeliveryItem>
          <Select
            options={Years}
            onChange={(value) => {
              const { value: yearValue } = value;
              if (yearValue) {
                setYear(+yearValue);
              }
            }}
            value={year}
          />
        </DeliveryItem>
      </DeliveryGrid>
      <Calendar
        selectedYear={year}
        selectedMonth={month}
        selectedDate={day}
        onSelectDate={setDay}
      />
      <Divider />
      <DeliveryText>SET DELIVERY TIME:</DeliveryText>
      <Select
        error={isTimeZoneSelected === false}
        label={
          (timeZoneId
            ? timezoneOptions.find((el) => el.value === timeZoneId)?.label
            : 'Recipient time zone') || 'Recipient time zone'
        }
        options={timezoneOptions}
        onChange={(value) => {
          const { value: timezoneValue } = value;
          if (timezoneValue) {
            setSelectedTimeZoneId(+timezoneValue);
            setIsTimeZoneSelected && setIsTimeZoneSelected(true);
          }
        }}
        value={timeZoneIdSelected}
      />
      {isTimeZoneSelected === false && (
        <div style={{ marginTop: '0.8rem' }}>
          <ErrorText text="Please select a time zone" />
        </div>
      )}
      <TimeGrid>
        <TimeItem>
          <Input
            type="text"
            style={{ width: 85, textAlign: 'center' }}
            value={hour}
            placeholder={'00'}
            maxLength={2}
            min={0}
            max={12}
            inputMode="numeric"
            onChange={(e) => {
              let inputHour = e.target.value as string;
              let { max, min } = e.target;
              inputHour = Math.max(
                Number(min),
                Math.min(Number(max), Number(inputHour))
              ).toString();
              setHour(inputHour.replace(/[^0-9]/g, '').toString());
            }}
            onBlur={onHourBlur}
            onFocus={onFocus}
          />
        </TimeItem>
        <TimeItem>{':'}</TimeItem>
        <TimeItem>
          <Input
            style={{ width: 85, textAlign: 'center' }}
            type="text"
            value={min}
            placeholder={'00'}
            maxLength={2}
            min={0}
            max={59}
            inputMode="numeric"
            onChange={(e) => {
              let inputMin = e.target.value as string;
              let { max, min } = e.target;
              inputMin = Math.max(
                Number(min),
                Math.min(Number(max), Number(inputMin))
              ).toString();
              setMin(inputMin.replace(/[^0-9]/g, '').toString());
            }}
            onBlur={onMinuteBlur}
            onFocus={onFocus}
          />
        </TimeItem>
        <AMPMWrapper>
          <AMPM>
            <div
              onClick={() => setAm('am')}
              className={`pill${am === 'am' ? 'Active' : ''}`}>
              <span className={`pillText${am === 'am' ? 'Active' : ''}`}>
                AM
              </span>
            </div>
            <div
              onClick={() => setAm('pm')}
              className={`pill${am === 'pm' ? 'Active' : ''}`}>
              <span className={`pillText${am === 'pm' ? 'Active' : ''}`}>
                PM
              </span>
            </div>
          </AMPM>
        </AMPMWrapper>
      </TimeGrid>
      {!isValidDate && (
        <div className="purchase-error-text">
          <ErrorText text="Select a time and date in the future" />
        </div>
      )}
    </div>
  );
};

export default DateTimePicker;
