import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useAccountDeletion } from '../../../context/AccountDeletionProvider';
import DeleteAccountRoutes from './routes';

interface OwnProps {}

const DeleteAccountModal: React.FC<OwnProps> = () => {
  const location = useLocation();
  const {
    showDeleteAccountModal,
    setShowDeleteAccountModal,
    setDeletionRequestReason,
  } = useAccountDeletion();

  useEffect(() => {
    if (location.pathname === '/settings' && showDeleteAccountModal) {
      setDeletionRequestReason(undefined);
      setShowDeleteAccountModal(false);
    }
  }, [
    location.pathname,
    showDeleteAccountModal,
    setShowDeleteAccountModal,
    setDeletionRequestReason,
  ]);

  return (
    <div className="Modal">
      <Routes>
        <Route path="/delete-account/*" element={<DeleteAccountRoutes />} />
      </Routes>
    </div>
  );
};

export default DeleteAccountModal;
