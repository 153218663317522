import { Route, Routes } from 'react-router';
import OptIn from '../containers/OptIn';
import OptInConfirmation from '../containers/OptIn/OptInConfirmation';
import OptInInfo from '../containers/OptIn/OptInInfo';

const OptInRoutes = () => {
  return (
    <Routes>
      <Route path="/info" element={<OptInInfo />} />
      <Route path="/confirmation" element={<OptInConfirmation />} />
      <Route path="*" element={<OptIn />} />
    </Routes>
  );
};

export default OptInRoutes;
