export const PHONE_NUMBER_VERIFIED = 'phone_number_verified';
export const PHONE_NUMBER_ATTRIBUTE = 'phone_number';

export const EMAIL_ATTRIBUTE = 'email';
export const SMS_SENDING_GAP_IN_SECONDS = 60;
export const EMAIL_SENDING_GAP_IN_SECONDS = 60;

export const LAST_SMS_SENT_KEY = 'LastSMSSent';
export const LAST_EMAIL_SENT_KEY = 'LastEmailSent';
export const BIO_METRICS_OPT_IN_KEY = 'OptInBiometrics';

export const IS_SESSION_TIME_OUT_KEY = 'IsSessionTimeout';
export const SESSION_HAS_TIMED_OUT = 'SessionHasTimedOut';
