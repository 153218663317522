import styled from 'styled-components';
import Colors from '../../../../const/Colors';

export const BrandRetailersHeaderContainer = styled.div`
  background-color: ${Colors.white};
  .location {
    font-size: 1.6rem;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-container {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .right-container {
      display: flex;
      white-space: nowrap;
      gap: 0.8rem;
      > img {
        flex: 0 0 1.5rem;
        height: 2rem;
        width: 1.5rem;
      }
    }
  }
`;
