import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Body from '../../../components/Body';
import { PurchaseWrapper } from '../../../components/Purchase';
import { usePurchase } from '../../../context/PurchaseProvider';
import { ProgramProfileVM } from '../../../generated/api';
import { ReactComponent as Selected } from '../../../assets/svg/purchase/Selected.svg';
import Divider from '../../../components/Divider';
import { getSelectedProfileImage } from '../../../helpers/cards';
import { Button } from '../../../components/Button';
import GoldenConfettiImage from '../../../assets/svg/purchase/GoldenConfetti.png';
import ConfettiImage from '../../../assets/svg/purchase/Confetti.png';
import FireworksImage from '../../../assets/svg/purchase/Fireworks.png';

import './styles.scss';
import { useAlert } from 'react-alert';
import { RudderStack } from '../../../services/shared/RudderStack';
import { isBrandMerchant } from '../../../helpers/utils';
import PurchaseBack from '../common/Back';

type Props = {};

type AnimationTypes = {
  confetti: string;
  fireworks: string;
  goldConfetti: string;
  none: string;
};

export type AnimationType = keyof AnimationTypes;

const Customise = (props: Props) => {
  const purchase = usePurchase();
  const navigate = useNavigate();
  const alert = useAlert();
  const [selectedProfileId, setSelectedProfileId] = useState(
    purchase.newPurchaseDetails.profileId || 0
  );
  const { cardDetails: card } = purchase;

  const [selectedAnimation, setSelectedAnimation] = useState<string>(
    purchase.newPurchaseDetails.celebration || 'none'
  );

  useEffect(() => {
    RudderStack.page('Purchase_Customise');
  }, []);

  useEffect(() => {
    setSelectedAnimation(purchase.newPurchaseDetails.celebration || 'none');
  }, [purchase.newPurchaseDetails.celebration]);

  useEffect(() => {
    setSelectedProfileId(purchase.newPurchaseDetails.profileId || 0);
  }, [purchase.newPurchaseDetails.profileId]);

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(card, selectedProfileId);
  }, [card, selectedProfileId]);

  const onSelectAnimation = (celebrationType: AnimationType) => {
    let newCelebration: string | undefined = celebrationType;
    if (purchase.newPurchaseDetails.celebration === newCelebration) {
      newCelebration = undefined;
    }
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      celebration: newCelebration,
    });
  };

  const onContinue = () => {
    if (!selectedProfileId) {
      alert.error('You need to select a design');
      return;
    }
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      profileId: selectedProfileId,
    });
    navigate('/purchase/delivery');
  };

  const backLink = '/purchase/personalise';
  const isBranded = isBrandMerchant(card);

  return (
    <Body
      theme="white"
      disableFooter
      headerDisableShopNow
      headerVariant="purchase"
      displayMobileMenu={false}>
      <div className="content">
        <div className="purchase-customise">
          <PurchaseBack isBranded={isBranded} backLink={backLink} />
          <PurchaseWrapper
            giftCard={card}
            showBackButton={false}
            cardImage={cardFrontImage}
            showAnimation
            animationType={selectedAnimation}
            waveBackLink={backLink}>
            <div className="purchase-flow-header">Customise gift</div>
            <div className="purchase-flow-description">
              Choose a custom design that best suits the person you're gifting.
            </div>
            {!isBranded && (
              <div>
                <div className="selectDesign">
                  <div className="select-text">SELECT DESIGN:</div>
                  <div className="designContainer">
                    {card.giftCardProgram?.programProfiles?.map(
                      (profile: ProgramProfileVM) => (
                        <div key={profile.id}>
                          <div
                            onClick={() =>
                              setSelectedProfileId(profile.id ?? -1)
                            }
                            className="design"
                            style={{
                              backgroundColor: profile['colour'] || '',
                            }}>
                            {profile.id === selectedProfileId && (
                              <Selected className="selected" />
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="purchase-customise-divider">
                  <Divider />
                </div>
              </div>
            )}

            <div className="selectCelebration">
              <div className="select-text">SELECT A CELEBRATION ANIMATION:</div>
              <div className="designContainer celebrationContainer">
                <div
                  onClick={() => {
                    onSelectAnimation('goldConfetti');
                  }}
                  className="celebration">
                  <img
                    src={GoldenConfettiImage}
                    className="animationImage"
                    alt="Golden Confetti"
                  />
                  {selectedAnimation === 'goldConfetti' && (
                    <Selected className="celebrationSelected" />
                  )}
                </div>

                <div
                  onClick={() => {
                    onSelectAnimation('confetti');
                  }}
                  className="celebration"
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                  }}>
                  <img
                    src={ConfettiImage}
                    className="animationImage"
                    alt="Confetti"
                  />
                  {selectedAnimation === 'confetti' && (
                    <Selected className="celebrationSelected" />
                  )}
                </div>

                <div
                  onClick={() => {
                    onSelectAnimation('fireworks');
                  }}
                  className="celebration">
                  <img
                    src={FireworksImage}
                    className="animationImage"
                    alt="Fireworks"
                  />
                  {selectedAnimation === 'fireworks' && (
                    <Selected className="celebrationSelected" />
                  )}
                </div>
              </div>
            </div>

            <Button
              role="Primary"
              title="CONTINUE TO DELIVERY"
              onClick={onContinue}
            />
          </PurchaseWrapper>
        </div>
      </div>
    </Body>
  );
};

export default Customise;
