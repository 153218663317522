import { useEffect } from 'react';

const useKeyboardEvent = (handleKeyPress: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress]);
};

export default useKeyboardEvent;
