import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '../../../../components/ArrowBack';
import Cancel from '../common/Cancel';
import { BASE_DELETE_ACCOUNT_ROUTE } from '../const';
import { ReactComponent as CircularDelete } from '../../../../assets/svg/delete-account/CircularDelete.svg';
import { ReactComponent as RightArrow } from '../../../../assets/svg/delete-account/RightArrow.svg';
import { RudderStack } from '../../../../services/shared/RudderStack';

import { ArrowWrapper, HeaderText, MobileOnlyBlock, SubText } from '../styles';
import {
  BoldText,
  CancelWrapper,
  CircularDeleteWrapper,
  DeleteWrapper,
  Left,
  NormalText,
  Wording,
} from './styles';

const DeleteAccountSettings = () => {
  const navigate = useNavigate();
  return (
    <div>
      <MobileOnlyBlock style={{ marginBottom: '1.8rem' }}>
        <ArrowBack text="BACK" />
      </MobileOnlyBlock>
      <HeaderText>Account settings</HeaderText>
      <SubText style={{ marginBottom: '3rem' }}>Manage your settings</SubText>

      <DeleteWrapper
        onClick={() => {
          RudderStack.track('Account Deletion Started');
          navigate(`${BASE_DELETE_ACCOUNT_ROUTE}/question-answer`);
        }}>
        <Left>
          <CircularDeleteWrapper>
            <CircularDelete />
          </CircularDeleteWrapper>
          <Wording>
            <BoldText>Delete account</BoldText>
            <NormalText>Delete your Special account</NormalText>
          </Wording>
        </Left>
        <ArrowWrapper className="arrow-wrapper">
          <RightArrow />
        </ArrowWrapper>
      </DeleteWrapper>
      <CancelWrapper>
        <Cancel />
      </CancelWrapper>
    </div>
  );
};

export default DeleteAccountSettings;
