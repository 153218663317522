import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const InitialWrapper = styled.div`
  background-color: ${Colors.electricBlue};
`;

export const ContentWrapper = styled.div`
  max-width: 144rem;
  padding: 0 2rem;
  ${map({ tablet: '0 3.4rem' }, (padding) => `padding: ${padding};`)}
  padding-top: min(3.8rem, 9vw);
  ${map({ tablet: '3.5rem' }, (paddingTop) => `padding-top: ${paddingTop};`)}
  padding-bottom: 0;
  margin: 0 auto;
  color: ${Colors.black};
`;

export const StarterText = styled.div`
  font-size: min(2rem, 5vw);
  line-height: min(2.8rem, 5vw);
  letter-spacing: 0.04em;
`;

export const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 8.9rem;
  ${map({ tablet: '8.4rem' }, (marginTop) => `margin-top: ${marginTop};`)}
`;

export const MainText = styled.span`
  font-size: min(8.8rem, 22vw);
  ${map(
    { tablet: 'min(13.7rem, 9.6vw)' },
    (fontSize) => `font-size: ${fontSize};`
  )}
  line-height: min(9.3rem, 23vw);
  ${map(
    { tablet: 'min(11.7rem, 8.12vw)' },
    (lineHeight) => `line-height: ${lineHeight};`
  )}
  font-weight: 700;
  font-family: 'Kobe-bold';
  text-transform: uppercase;
  color: ${Colors.electricBlue};
`;

export const HappyText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SpecialText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FathersDayText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 85%;
`;

export const FathersDayTextWrapper = styled.div`
  display: inline-block;
  font-size: min(8.8rem, 22vw);
  ${map(
    { tablet: 'min(13.7rem, 9.6vw)' },
    (fontSize) => `font-size: ${fontSize};`
  )}
  line-height: min(9.3rem, 23vw);
  ${map(
    { tablet: 'min(11.7rem, 8.12vw)' },
    (lineHeight) => `line-height: ${lineHeight};`
  )}
  font-weight: 700;
  font-family: 'Kobe-bold';
  text-transform: uppercase;
  color: ${Colors.electricBlue};
  position: relative;
`;

export const SubText = styled.div`
  font-size: min(2.4rem, 6vw);
  line-height: min(3.1rem, 7.7vw);
  color: ${Colors.orangeSun};
  font-weight: 700;
  margin-top: 2rem;
  ${map({ tablet: '1.5rem' }, (marginTop) => `margin-top: ${marginTop};`)}
  margin-bottom: 2.7rem;
  ${map(
    { tablet: '3.6rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )}
`;

export const GiftWrapper = styled.span`
  max-width: min(6.7rem, 18.8vw);
  ${map({ tablet: 'min(10rem, 7vw)' }, (maxWidth) => `max-width: ${maxWidth};`)}
  svg {
    max-width: min(6.7rem, 18.8vw);
    ${map(
      { tablet: 'min(10rem, 7vw)' },
      (maxWidth) => `max-width: ${maxWidth};`
    )}
    position: absolute;
    transform: translateX(120%);
    top: -0.5rem;
    right: 0;
    filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.25));
  }
`;

export const HeartsWrapper = styled.span`
  display: none;
  ${map({ tablet: 'inline' }, (display) => `display: ${display};`)}
  max-width: min(6.7rem, 18.8vw);
  ${map(
    { tablet: 'min(12.4rem, 8.6vw)' },
    (maxWidth) => `max-width: ${maxWidth};`
  )}
  svg {
    max-width: min(6.7rem, 18.8vw);
    ${map(
      { tablet: 'min(12.4rem, 8.6vw)' },
      (maxWidth) => `max-width: ${maxWidth};`
    )}
    position: relative;
    top: 1rem;
    ${map({ tablet: '-0.5rem' }, (top) => `top: ${top};`)}
    left: 1.5rem;
    ${map({ tablet: '1rem' }, (left) => `left: ${left};`)}
    filter: drop-shadow( 0px 5px 3px rgba(0, 0, 0, .25))
  }
`;

export const EyeWrapper = styled.span`
  display: none;
  ${map({ tablet: 'inline' }, (display) => `display: ${display};`)}
  max-width: min(6.7rem, 18.8vw);
  ${map(
    { tablet: 'min(12.4rem, 8.6vw)' },
    (maxWidth) => `max-width: ${maxWidth};`
  )}
  svg {
    max-width: min(6.7rem, 18.8vw);
    ${map(
      { tablet: 'min(12.4rem, 8.6vw)' },
      (maxWidth) => `max-width: ${maxWidth};`
    )}
    position: relative;
    top: 1rem;
    ${map({ tablet: '0.5rem' }, (top) => `top: ${top};`)}
    left: 1.5rem;
    ${map({ tablet: '0rem' }, (left) => `left: ${left};`)}
    filter: drop-shadow( 0px 5px 3px rgba(0, 0, 0, .25))
  }
`;
