import { useState, useEffect } from 'react';

type ReturnType<T> = [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>
];

export const LocalStorageFolderName = 'SpecialItems';

export const useLocalStorage = <T extends {}>(
  key: string,
  initialValue?: T
): ReturnType<T> => {
  const [state, setState] = useState<T | undefined>(() => {
    if (!initialValue) return;
    try {
      const localStorageFolder = JSON.parse(
        localStorage.getItem(LocalStorageFolderName) || '{}'
      );
      return localStorageFolder[key] ? localStorageFolder[key] : initialValue;
    } catch (err) {
      return initialValue;
    }
  });

  useEffect(() => {
    if (state) {
      try {
        let localStorageFolder = JSON.parse(
          localStorage.getItem(LocalStorageFolderName) || '{}'
        );
        localStorageFolder[key] = state;
        localStorage.setItem(
          LocalStorageFolderName,
          JSON.stringify(localStorageFolder)
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, [state, key]);

  return [state, setState];
};
