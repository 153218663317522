import React from 'react';
import ArrowBack from '../../../../components/ArrowBack';
import { IPurchaseBackProps } from './types';

const PurchaseBack: React.FC<IPurchaseBackProps> = ({
  isBranded = false,
  backLink = '',
  backText = 'BACK',
}) => {
  if (isBranded) {
    return null;
  }
  return (
    <div className="purchase-flow-back">
      <ArrowBack text={backText} to={backLink} />
    </div>
  );
};

export default PurchaseBack;
