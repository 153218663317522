import styled from 'styled-components';
import { map } from '../../../const/theme';

const Description = styled.p`
  line-height: 3.36rem;
  letter-spacing: 0.04rem;
  margin: 0 0 2rem 0;
  font-size: 2rem;
  ${map({ tablet: '0 0 4rem 0' }, (size) => `margin: ${size};`)}
  ${map({ tablet: '2.4rem' }, (size) => `font-size: ${size};`)}
`;

type props = {
  children: HTMLElement | string | null | undefined;
};

const PurchaseDescription = ({ children }: props) => (
  <Description>{children}</Description>
);
export default PurchaseDescription;
