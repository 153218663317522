import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import { Option } from '../../components/Option';
import { PasswordComplexity } from '../../components/PasswordComplexity';
import Stepper from '../../components/Stepper';
import { COMMON_ERR_MSG, PASSWORD_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import { RegisterNewUser } from '../../generated';
import './Auth.scss';
import { useAlert } from 'react-alert';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';

const CreateAccountPassword = () => {
  const {
    signIn,
    newUserDetails,
    navState,
    setNavState,
    createAccountStepperClicks,
  } = useAuth();
  const navigate = useNavigate();
  const api = useApi();
  const alert = useAlert();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [optInMarketing, setOpenInMarketing] = useState(true);
  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    RudderStack.page('Auth_CreateAccountPassword');
  }, []);

  useEffect(() => {
    if (navState > 3) {
      return;
    }

    setNavState(3);
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = async (data: any) => {
    if (isCreating) {
      return;
    }
    setIsCreating(true);
    const newUser: RegisterNewUser = {
      firstName: newUserDetails.firstName.trim(),
      lastName: newUserDetails.lastName.trim(),
      password: data.password,
      email: newUserDetails.email.trim(),
      mobileNumber: '',
      optInMarketing: optInMarketing,
    };
    try {
      const result = await api.userApi.registerNewUser(undefined, newUser);
      if (result.data) {
        await signIn(newUserDetails.email.trim(), data.password);
        setIsCreating(false);
        navigate('/auth/verifyPhone');
      } else {
        alert.error(COMMON_ERR_MSG);
        setIsCreating(false);
      }
    } catch {
      alert.error(COMMON_ERR_MSG);
      setIsCreating(false);
    }
  };

  const [passwordComplexityText, setPasswordComplexityText] = useState('');

  const updateComplexityText = (score: number) => {
    switch (score) {
      case 2:
        setPasswordComplexityText('Fair');
        break;
      case 3:
        setPasswordComplexityText('Good');
        break;
      case 4:
        setPasswordComplexityText('Excellent');
        break;
      default:
        setPasswordComplexityText('');
    }
  };

  return (
    <AuthWrapper>
      <>
        <Stepper
          numberOfSteps={5}
          currentStep={navState}
          containerStyle="auth-stepper"
          onStepClick={createAccountStepperClicks}
        />
        <p className="auth-create-account-title">
          Keep your cards & details safe
        </p>
        <p className="auth-create-account-subtitle">
          Password must be at least 8 characters long (case sensitive) with one
          number or special character.
        </p>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: PASSWORD_REGEX,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                className="auth-input"
                label="Password"
                type="password"
                autoFocus
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.password}
                maxLength={50}
              />
              {errors.password && (
                <div>
                  <ErrorText text="Enter a password that is at least 8 characters, includes one number and special character, and is not a commonly used password" />
                </div>
              )}
              <PasswordComplexity
                text={passwordComplexityText}
                password={value}
                onUpdate={(score) => {
                  updateComplexityText(score);
                }}
              />
            </>
          )}
          name="password"
        />
        <Option
          value={`${optInMarketing}`}
          onChange={() => setOpenInMarketing(!optInMarketing)}
          type="checkbox"
          label="Opt in to receiving marketing communications"
        />
        <p className="auth-legal">
          By continuing, I understand and agree to the Special{' '}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          and the Special{' '}
          <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </a>
          , and that I am authorised to provide information on behalf of the
          recipient.
        </p>
        <Button
          startIconClass={'heart-icon'}
          loading={isCreating}
          title="Create Account"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
        />
      </>
    </AuthWrapper>
  );
};

export default CreateAccountPassword;
