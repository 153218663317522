import * as React from 'react';
import Colors from '../../../const/Colors';

interface OwnProps {
  color?: string;
}
const BottomStars: React.FC<OwnProps> = ({ color = Colors.electricBlue }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'100%'}
    height={'100%'}
    viewBox="0 0 118 118"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.366 47.292a37.63 37.63 0 0 1-6.249-13.243l.476 1.907-.48-1.927a37.637 37.637 0 0 1-4.27 28.48A37.6 37.6 0 0 1 14.65 79.627l-.148.037C34.49 74.683 54.713 86.52 60.097 106.2l.21.845c-.05-.2-.098-.399-.144-.599.052.195.102.39.151.587l-.207-.831c-4.484-19.907 7.796-39.846 27.81-44.834l-.153.039a37.58 37.58 0 0 1-28.516-4.23 37.617 37.617 0 0 1-10.882-9.886ZM77.427 12.641a21.125 21.125 0 0 1-3.507-7.434l.87 3.488-.874-3.5a21.128 21.128 0 0 1-2.396 15.987A21.105 21.105 0 0 1 58.5 30.79l-.083.02C69.643 28.014 81 34.666 84.016 45.722l.115.461a22.433 22.433 0 0 1-.079-.326c.028.105.056.212.082.319l-.112-.452c-2.527-11.179 4.368-22.38 15.608-25.181l-.087.021a21.095 21.095 0 0 1-16.007-2.374 21.116 21.116 0 0 1-6.109-5.549Z"
      fill={color}
    />
  </svg>
);

export default BottomStars;
