import {
  GiftCardDetailsVM,
  MerchantLocationDetailsVM,
  MerchantLocationVM,
  PromotionCodeVM,
  SpecialTimezoneVM,
  UserGiftCardStatus,
} from '../generated';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { UserGiftCard } from '../containers/MyCards';
import _ from 'lodash';

export const getSelectedProfileImage = (
  card: GiftCardDetailsVM,
  selectedProfileId: number
): string => {
  const programProfiles = card.giftCardProgram?.programProfiles ?? [];
  const defaultProgramProfile =
    programProfiles.length > 0 ? programProfiles[0] : null;
  return (
    programProfiles.find((x) => x.id === selectedProfileId)?.frontsideImage ??
    defaultProgramProfile?.frontsideImage ??
    ''
  );
};

export const getTimeZoneDisplay = (timeZone?: SpecialTimezoneVM): string => {
  if (!timeZone) {
    return `(UTC ${moment().format('Z')}) ${
      momentTz.tz.guess(true).split('/')[1]
    }`;
  }
  const hours = Math.floor((timeZone.timeZoneDifference ?? 0) / 60);
  const mins = (timeZone.timeZoneDifference ?? 0) - 60 * hours;
  const minsAppend = mins < 10 ? '0' : '';
  return `(UTC + ${hours}:${minsAppend}${mins}) ${timeZone.timeZoneDisplay}`;
};

export const getRetailersDisplay = (number: number): string => {
  if (number > 1000000) {
    return '1,000,000+';
  }
  if (number > 100000) {
    return '100,000+';
  }
  if (number > 10000) {
    return '10,000+';
  }
  if (number > 1000) {
    return '1,000+';
  }
  if (number > 100) {
    return '100+';
  }
  return number.toString();
};

export const getDiscountAmount = (
  value: number,
  promotionInUse: PromotionCodeVM
) => {
  const discountPercentage = promotionInUse.discountPercentage ?? 0;
  const maximumDiscountAmount = promotionInUse.maximumDiscountValue ?? 0;
  let discountAmount = (value * discountPercentage) / 100;
  return discountAmount > maximumDiscountAmount
    ? maximumDiscountAmount
    : discountAmount;
};

const comparePastCards = (a: UserGiftCard, b: UserGiftCard) => {
  return (a.turnPastDate || '') > (b.turnPastDate || '')
    ? -1
    : (b.turnPastDate || '') > (a.turnPastDate || '')
    ? 1
    : 0;
};

export const sortPastCards = (_pastCards: UserGiftCard[]) => {
  const _pastStatusSortedCards = _pastCards
    .filter((card) => card.status === UserGiftCardStatus.Past)
    .sort(comparePastCards);

  const _blockedStatusSortedCards = _pastCards
    .filter((card) => card.status === UserGiftCardStatus.Blocked)
    .sort(comparePastCards);

  const _cancelledStatusSortedCards = _pastCards
    .filter((card) => card.status === UserGiftCardStatus.Cancelled)
    .sort(comparePastCards);

  /**
   * precedence levels (lowest first)
   * Past: 1
   * Blocked: 2
   * Cancelled: 3
   */
  const sortedPastCards: UserGiftCard[] = [
    ..._pastStatusSortedCards,
    ..._blockedStatusSortedCards,
    ..._cancelledStatusSortedCards,
  ];
  return sortedPastCards;
};

export const getValidStates = (
  locations: (MerchantLocationVM | MerchantLocationDetailsVM)[]
) => {
  const allStates = locations.map((location) => location.state);
  const uniqueStates = _.uniq(allStates);
  return _.compact(uniqueStates);
};

export const getLocationAddress = (
  location: MerchantLocationVM | MerchantLocationDetailsVM
) => {
  const { addressLineOne, addressLineTwo, suburb, state, postcode } = location;
  const addressArray = [addressLineOne, addressLineTwo];
  const addressFirstPart = _.compact(addressArray)
    .join(', ')
    .replace(' ,', ',');
  return `${addressFirstPart}, ${suburb} ${state} ${postcode}`;
};
