import { createRef, useState } from 'react';
import './search.scss';
import _ from 'lodash';

interface SearchInputProps extends React.InputHTMLAttributes<any> {
  searchMatches: string[];
  isLoading: boolean;
  isMobile: boolean;
  scrolled: boolean;
  onSelectValue: (value: string) => void;
  onChangeValue: (value: string) => void;
  onSubmitEvent: () => void;
}

export const Search = ({
  value = '',
  placeholder,
  searchMatches = [],
  isLoading = false,
  isMobile = false,
  scrolled,
  onChangeValue,
  onSelectValue,
  onSubmitEvent,
  ...props
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState(value);
  const ref = createRef<HTMLInputElement>();

  const onChange = (value: string) => {
    ref.current?.focus();
    setSearchValue(value);
    onChangeValue(value);
  };

  const onClickSearchMatch = (value: string) => {
    setSearchValue(value);
    onSelectValue(value);
  };

  const getDefaultHeight = () => {
    if (isMobile) {
      if (scrolled) {
        return 40;
      } else {
        return 50;
      }
    } else {
      if (scrolled) {
        return 50;
      } else {
        return 60;
      }
    }
  };

  const calculateHeight = () => {
    const defaultHeight = getDefaultHeight();
    const listHeight = searchMatches.length
      ? searchMatches.length * 44 + 10
      : isLoading
      ? 54 // Single line for search
      : 0;

    return { height: `${defaultHeight + listHeight}px` };
  };

  const handleSearchKeydown = (event: any) => {
    switch (event.key) {
      case 'Enter':
        let mappedMatched = searchMatches.map((match) => {
          return match.toLowerCase();
        });
        let tempIndex = mappedMatched.findIndex((value) => {
          return value.toLowerCase() === searchValue.toString().toLowerCase();
        });
        document.getElementById(`option-${tempIndex}`)?.click();
        onSubmitEvent();
        break;
      case 'ArrowDown':
        event.preventDefault();
        document.getElementById('option-0')?.focus();
        break;
      default:
        break;
    }
  };

  const handleItemKeydown = (event: any, index: number) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        document.getElementById(`option-${index + 1}`)?.focus();
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (index > 0) {
          document.getElementById(`option-${index - 1}`)?.focus();
        } else {
          ref.current?.focus();
        }
        break;
      case 'Escape':
        event.preventDefault();
        ref.current?.focus();
        break;
      default:
        break;
    }
  };

  return (
    <div className="search-wrapper" style={calculateHeight()}>
      <label
        className="special-search"
        style={{ height: getDefaultHeight() + 'px' }}>
        <span className="search-icon icon" />
        <input
          ref={ref}
          {...props}
          className="search-field"
          placeholder={placeholder}
          value={searchValue}
          onKeyDown={(event) => {
            handleSearchKeydown(event);
          }}
          onChange={(e) => onChange(e.target.value)}
        />
        {!_.isEmpty(searchValue) && (
          <button
            className="search-clear icon"
            onClick={() => {
              setSearchValue('');
              onChangeValue('');
            }}></button>
        )}
        {isLoading ? (
          <ul className="search-match">
            <li>
              <span>Searching</span>
            </li>
          </ul>
        ) : (
          <ul className="search-match">
            {searchMatches.map((item, index) => (
              <li key={index}>
                <button
                  onKeyDown={(event) => handleItemKeydown(event, index)}
                  id={'option-' + index}
                  onClick={() => onClickSearchMatch(item)}>
                  {item}
                </button>
              </li>
            ))}
          </ul>
        )}
      </label>
    </div>
  );
};
