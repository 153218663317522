import { useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { DefaultStepColors } from "../../const/shared";
import './Stepper.scss';
type props = {
  numberOfSteps: number;
  currentStep: number;
  colors?: string[];
  containerStyle?: string;
  onStepClick?: {(navigate: NavigateFunction): void;} [];
}

const Stepper = ({
  numberOfSteps,
  currentStep,
  colors = DefaultStepColors,
  containerStyle = "",
  onStepClick,
}: props) => {

  const [numSteps, setNumSteps] = useState<null[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (numberOfSteps) {
      setNumSteps(Array.from(Array(numberOfSteps)));
    } else {
      setNumSteps([null]);
    }
  }, [numberOfSteps]);

  const renderSteps = useMemo(() => {
    let activeColorIndex = 0;
    return (
      <>
        {numSteps.map((arr, index) => {
          const isLastItem = index === numSteps.length - 1;
          let activeColor = colors[activeColorIndex];
          let seperatorColor = '#000000';
          if (activeColor) {
            activeColorIndex += 1;
          } else {
            // reset color index
            activeColorIndex = 1;
            activeColor = colors[0];
          }
          activeColor = index < currentStep ? activeColor : '#CBD0D6';
          seperatorColor =
            index < currentStep - 1 ? seperatorColor : '#CBD0D6';
          const click = onStepClick && onStepClick[index];
          return (
            <div
              key={`step-${index}`}
              className={'special-step-item-container'}>
              <div
                className={'special-step-dot'}
                style={{ borderColor: activeColor}}
                onClick={()=>{click && click(navigate);}}
              />
              {!isLastItem && (
                <div
                  className={'special-dot-separator'}
                  style={{ backgroundColor: seperatorColor}}
                />
              )}
            </div>
          );
        })}
      </>
    );
  }, [numSteps, currentStep, colors, onStepClick, navigate]);


  return (
    <>
      <div className={`special-container ${containerStyle}`}>
        {renderSteps}
      </div>
    </>
  );

}

export default Stepper