import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePurchase } from '../../../context/PurchaseProvider';
import { getSelectedProfileImage } from '../../../helpers/cards';
import CelebrationAnimation from '../../../components/CelebrationAnimation';
import DynamicCard from '../../../components/DynamicCard';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import Colors from '../../../const/Colors';
import {
  PurchaseSucess,
  CelebrationAnimationsStyle,
  CardSection,
  Woohoo,
  WoohooText,
  CardWrapper,
  Scallop,
  Details,
  DetailsWrapper,
  AffiliationText,
  LogoWrapper,
  Logo,
} from './styles';
import { useAppContext } from '../../../context/AppProvider';
import { SpecialTimezoneVM, UserGiftCardOrderStatus } from '../../../generated';
import moment, { Moment } from 'moment';
import OrderSummary from './OrderSummary';
import PurchaseEndHeader from '../common/Header';
import { useLocation } from 'react-router-dom';
import Failed from '../Failed';
import { getCardStarColors, isBrandMerchant } from '../../../helpers/utils';
import styled from 'styled-components';
import { map } from '../../../const/theme';
import { RudderStack } from '../../../services/shared/RudderStack';
import { useApi } from '../../../api/ApiProvider';
import {
  SHOW_AFFILIATION_TEXT_IN_ANIMATION,
  SHOW_BRAND_LOGO_IN_ANIMATION,
} from '../../../const/shared';

const CardStyle = styled.div`
  display: inline-block;
  height: 14rem;
  ${map({ tablet: '18.2rem' }, (height) => `height: ${height};`)}
  width: 22rem;
  ${map({ tablet: '29rem' }, (width) => `width: ${width};`)}
`;

interface OwnProps {}

interface LocationState {
  order: number;
  promoCode?: string;
  discountAmount?: number;
}

type Ref = null | HTMLDivElement;

export const AFFILIATION_TEXT = '*This brand is not affiliated with Special';
const DEFAULT_AFFILIATION_TEXT_COLOR = Colors.white;

const Success: React.FC<OwnProps> = () => {
  const successRef = useRef<Ref>(null);
  const animationRef = useRef<Ref>(null);
  const purchase = usePurchase();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const { cardDetails: card } = purchase;
  const { giftCardWorkflowApi } = useApi();
  const [orderStatus, setOrderStatus] = useState<UserGiftCardOrderStatus>();

  useEffect(() => {
    RudderStack.page('Purchase_Success');
  }, []);

  useEffect(() => {
    (async () => {
      if (locationState && locationState.order) {
        const { data: giftCardOrderStatus } =
          await giftCardWorkflowApi.getUserGiftCardOrderStatus(
            locationState.order
          );
        setOrderStatus(giftCardOrderStatus);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(
      card,
      purchase.newPurchaseDetails.profileId ?? -1
    );
  }, [card, purchase.newPurchaseDetails.profileId]);

  const { starOneColour, starTwoColour } = useMemo(() => {
    return getCardStarColors(card);
  }, [card]);

  const { timezones } = useAppContext();

  const selectedTimezone: SpecialTimezoneVM | undefined = useMemo(() => {
    const selectedTimezone = timezones.find(
      (x) => x.id === purchase.newPurchaseDetails.specialTimezoneId
    );
    return selectedTimezone;
  }, [purchase.newPurchaseDetails.specialTimezoneId, timezones]);

  const momentDate: Moment = useMemo(() => {
    const momentDate = moment
      .utc(purchase.newPurchaseDetails.deliveryDate)
      .add(selectedTimezone?.timeZoneDifference ?? 0, 'minutes');
    return momentDate;
  }, [purchase.newPurchaseDetails.deliveryDate, selectedTimezone]);

  const isBranded = isBrandMerchant(card);
  const {
    primaryColour,
    affiliationTextColour,
    squareCardColourLogo: logo,
  } = card?.giftCardProgram?.brandMerchant || {};

  const { displayAffiliationText } = card?.giftCardProgram || {};

  const topStarsColour = useMemo(() => {
    if (isBranded) {
      return affiliationTextColour || Colors.seaGreen;
    }
    return starOneColour || Colors.starOrange;
  }, [isBranded, affiliationTextColour, starOneColour]);

  const bottomStarsColour = useMemo(() => {
    if (isBranded) {
      return affiliationTextColour || Colors.seaGreen;
    }
    return starTwoColour || Colors.electricBlue;
  }, [isBranded, affiliationTextColour, starTwoColour]);

  const backgroundColor = useMemo(() => {
    return isBranded ? primaryColour || Colors.seaGreen : Colors.seaGreen;
  }, [isBranded, primaryColour]);

  if (!locationState || !locationState.order) {
    return <Failed />;
  }

  const onAnimationEnd = () => {
    if (successRef.current && animationRef.current) {
      successRef.current.style.position = 'unset';
      animationRef.current.style.display = 'none';
    }
  };

  return (
    <PurchaseSucess ref={successRef} style={{ backgroundColor }}>
      <PurchaseEndHeader />
      <CelebrationAnimationsStyle ref={animationRef}>
        <CelebrationAnimation
          animationType={purchase.newPurchaseDetails.celebration}
        />
      </CelebrationAnimationsStyle>

      <CardSection>
        {SHOW_BRAND_LOGO_IN_ANIMATION && (
          <LogoWrapper>
            <Logo src={logo || ''} alt="" />
          </LogoWrapper>
        )}
        <Woohoo>
          <WoohooText>WOOHOO!!</WoohooText>
        </Woohoo>
        <CardWrapper>
          <CardStyle>
            <DynamicCard
              imageURL={cardFrontImage}
              topStarsColor={topStarsColour}
              bottomStarsColor={bottomStarsColour}
              topStarHeight={47}
              topStarWidth={47}
              topStarTopPosition={-20}
              topStarLeftPosition={-47}
              bottomStarHeight={56}
              bottomStarWidth={56}
              bottomStarBottomPosition={-10}
              bottomStarRightPosition={-56}
            />
          </CardStyle>
        </CardWrapper>
      </CardSection>
      {SHOW_AFFILIATION_TEXT_IN_ANIMATION && displayAffiliationText && (
        <AffiliationText
          style={{
            color: affiliationTextColour || DEFAULT_AFFILIATION_TEXT_COLOR,
          }}>
          {AFFILIATION_TEXT}
        </AffiliationText>
      )}
      <Scallop>
        <Border height="62" fill={Colors.white} />
      </Scallop>
      <Details onAnimationEnd={onAnimationEnd}>
        <DetailsWrapper>
          <OrderSummary
            cardFrontImage={isBranded ? logo || '' : cardFrontImage}
            cardName={card.name || ''}
            momentDate={momentDate}
            order={locationState.order.toString()}
            paymentDetails="Paid by Card"
            selectedTimezone={selectedTimezone}
            promoCode={locationState.promoCode ?? ''}
            discountAmount={locationState.discountAmount}
            orderStatus={orderStatus}
            displayAffiliationText={displayAffiliationText}
            isBranded={isBranded}
          />
        </DetailsWrapper>
      </Details>
    </PurchaseSucess>
  );
};

export default Success;
