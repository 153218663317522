import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../context/AuthProvider';

export const useAuthGuard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, isLoadingLoggedInInfo, setRedirectURL } = useAuth();
  useEffect(() => {
    if (isLoadingLoggedInInfo || isLoggedIn) {
      return;
    }
    // set current pathname to the redirectUrl, so it will take you back once the auth flow is complete
    setRedirectURL(location.pathname);
    // taking user to the auth flow
    navigate('/auth');
  }, [isLoadingLoggedInInfo, isLoggedIn, location, setRedirectURL, navigate]);
};
