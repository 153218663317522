import styled from 'styled-components';
import { map } from '../../const/theme';

export const BrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${map(
    { tablet: 'row' },
    (flexDirection) => `flex-direction: ${flexDirection}}`
  )};
  cursor: pointer;
  &:hover {
    .brand-arrow {
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  ${map({ tablet: '50%' }, (width) => `width: ${width}}`)};
  object-fit: cover;
  border-radius: 1.2rem;
  &:hover {
    transform: scale(1.02);
    animation: hoverShimmer 0.5s ease;
  }

  @keyframes hoverShimmer {
    0% {
      filter: brightness(1);
    }
    50% {
      filter: brightness(1.1);
    }
    100% {
      filter: brightness(1);
    }
  }
`;

export const BrandDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.8rem;
  ${map({ tablet: '0' }, (marginTop) => `margin-top: ${marginTop}}`)};
  margin-left: 0rem;
  ${map({ tablet: '1.5rem' }, (marginLeft) => `margin-left: ${marginLeft}}`)};
`;

export const BrandName = styled.span`
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const PriceRange = styled.div`
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: 700;
  margin-top: 0.5rem;
`;

export const TagWrapper = styled.div`
  margin-top: 1rem;
`;

export const BrandArrow = styled.img`
  display: none;
  ${map({ tablet: 'block' }, (display) => `display: ${display}}`)};
  width: 2.2rem;
  opacity: 0;
  transition: all 0.5s ease;
  padding-bottom: 1rem;
`;
