import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserGiftCard } from '..';
import { Button } from '../../../components/Button';
import { SideDrawerContainer } from '../../../components/SideDrawer';
import {
  ManagePinHeader,
  ManagePinParagraph,
  ManagePinParagraphHeader,
  TextDesc,
} from '../shared-components/manage-pin';
import CardPin, { CardPinStatus } from '../../../components/CardPin';
import { useManageCard } from '../../../context/ManagePinProvider';
import {
  UNMATCHED_PIN_MSG,
  COMMON_ERR_MSG,
  DEFAULT_PIN_WARNING_MSG,
} from '../../../const/shared';
import { ErrorText } from '../../../components/ErrorText';
import { useAlert } from 'react-alert';
import { useApi } from '../../../api/ApiProvider';

type Props = {
  userGiftCard?: UserGiftCard;
};

const ConfirmPin = ({ userGiftCard }: Props) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<CardPinStatus>();
  const { initialPin, phoneVerified } = useManageCard();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { giftCardWorkflowApi } = useApi();
  const alert = useAlert();
  useEffect(() => {
    if (phoneVerified) {
      return;
    }
    navigate(`/my-cards/${userGiftCard?.id}`);
  }, [phoneVerified, userGiftCard, navigate]);

  const onComplete = async (pin: string) => {
    if (pin === initialPin) {
      setStatus('success');
      setIsLoading(true);
      try {
        const response = await giftCardWorkflowApi.updatePin(
          userGiftCard?.id || 0,
          undefined,
          { pin: pin }
        );
        const { isSuccessful, status } = response.data;
        setIsLoading(false);
        if (isSuccessful && status === 'Success') {
          navigate(`/my-cards/${userGiftCard?.id}/manage-pin/pin-updated`);
        } else {
          alert.error(COMMON_ERR_MSG);
        }
      } catch (error) {
        alert.error(COMMON_ERR_MSG);
        setIsLoading(false);
      }
    } else {
      alert.error(UNMATCHED_PIN_MSG);
      navigate(`/my-cards/${userGiftCard?.id}/manage-pin/set-pin`);
      setStatus('error');
    }
  };

  return (
    <>
      <ManagePinHeader />
      <SideDrawerContainer>
        <ManagePinParagraphHeader>
          Confirm your new PIN
        </ManagePinParagraphHeader>
        <ManagePinParagraph>
          Enter the same PIN as previously entered to confirm.
        </ManagePinParagraph>
        <CardPin
          length={4}
          placeholder=""
          onComplete={onComplete}
          hideValue
          status={status}
          size="small"></CardPin>
        {status === 'error' && (
          <div className="pinErrorText">
            <ErrorText
              text={UNMATCHED_PIN_MSG}
              style={{ paddingLeft: 0, marginTop: '0.8rem' }}
            />
          </div>
        )}
        <Button
          title="CONFIRM"
          onClick={() => {
            setStatus('error');
          }}
          loading={isLoading}
          style={{ width: '25rem', height: '4.5rem', marginTop: '3rem' }}
          role="Secondary"></Button>
        <TextDesc>{DEFAULT_PIN_WARNING_MSG}</TextDesc>
      </SideDrawerContainer>
    </>
  );
};

export default ConfirmPin;
