import { Hero, HeroTitle } from '../../components/Hero';
import FixedColumn from '../../components/Layouts/FixedColumn';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Present from '../../assets/svg/Present.svg';
import Hand from '../../assets/svg/Hand.svg';
import './privacy.scss';
import Body from '../../components/Body';
import { useEffect } from 'react';
import { RudderStack } from '../../services/shared/RudderStack';

const Privacy = () => {

  useEffect(() => {
    RudderStack.page("Privacy");
  },[])

  return (
    <Body theme="white">
      <div className="content">
        <Hero>
          <HeroTitle size="small">
            privacy
            <img className="icon mobile-hide" src={Present} alt="special present" />
            <br /> policy
            <img className="icon mobile-show" src={Present} alt="special present" />
          </HeroTitle>
        </Hero>
        <FixedColumn hideFixedColumnMobile={true}>
          <PrivacyPolicy />
          <img
            src={Hand}
            alt="hand holding special card"
            style={{ transform: 'translateY(10rem)' }}
          />
        </FixedColumn>
      </div>
    </Body>
  );
};
export default Privacy;
