import React, { RefObject, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ISideDrawerHandle,
  SideDrawerHeader,
} from '../../../../components/SideDrawer';
import RetailersBackButton from '../../../../assets/svg/my-cards/RetailersBackButton.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/svg/my-cards/Eye.svg';
import Location from '../../../../assets/svg/my-cards/Location.svg';
import styled from 'styled-components';
import Input from '../../../../components/Input/Input';
import Colors from '../../../../const/Colors';
import { IsMobileView } from '../../../../util/helper';

type Props = {
  total: number;
  sideDrawerRef: RefObject<ISideDrawerHandle>;
  onBackClose?: boolean;
  showHeaderInput?: boolean;
  searchTerm?: string;
  setSearchTerm?: (term: string) => void;
};

const RetailersHeaderContainer = styled.div`
  background-color: ${Colors.white};
  .location {
    font-size: 1.6rem;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-container {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .right-container {
      display: flex;
      white-space: nowrap;
      gap: 0.8rem;
      > img {
        flex: 0 0 1.5rem;
        height: 2rem;
        width: 1.5rem;
      }
    }
  }
`;

const HeaderWithInputStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .backClose {
    margin-right: 2rem;
  }

  .inputSection {
    position: relative;
    flex: 1;
  }
`;

const EyeStyle = styled.div`
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
`;

const RetailersHeader = ({
  total,
  onBackClose,
  sideDrawerRef,
  showHeaderInput,
  searchTerm,
  setSearchTerm,
}: Props) => {
  const navigate = useNavigate();
  const isMobile = IsMobileView();

  const placeHolderText = useMemo(() => {
    return isMobile
      ? `Search ${total.toLocaleString('en-US')} stores`
      : 'Filter by retailer name';
  }, [isMobile, total]);

  return (
    <RetailersHeaderContainer>
      <SideDrawerHeader>
        {!showHeaderInput ? (
          <>
            <div className="left-container">
              <img
                onClick={() => {
                  if (onBackClose) {
                    sideDrawerRef.current?.toggleShow();
                  } else {
                    navigate(-1);
                  }
                }}
                src={RetailersBackButton}
                alt="Back"
              />
              <div className="header-title">Featured Retailers</div>
            </div>
            <div className="right-container">
              <img src={Location} alt="Location" />
              <div className="location">
                {total.toLocaleString('en-US')} locations
              </div>
            </div>
          </>
        ) : (
          <HeaderWithInputStyles>
            <img
              className="backClose"
              onClick={() => {
                if (onBackClose) {
                  sideDrawerRef.current?.toggleShow();
                } else {
                  navigate(-1);
                }
              }}
              src={RetailersBackButton}
              alt="Back"
            />
            <div className="inputSection">
              <Input
                className="inputSection"
                label=""
                placeholder={placeHolderText}
                type="text"
                onChange={(event) => {
                  setSearchTerm && setSearchTerm(event.target.value);
                }}
                value={searchTerm}
                style={{ paddingRight: '5rem' }}
              />
              <EyeStyle>
                <EyeIcon />
              </EyeStyle>
            </div>
          </HeaderWithInputStyles>
        )}
      </SideDrawerHeader>
    </RetailersHeaderContainer>
  );
};

export default RetailersHeader;
