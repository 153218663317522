import React, { useMemo } from 'react';
import FailedArt from '../../../assets/svg/purchase/FailedArt.svg';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import { Button } from '../../../components/Button';

import { Link, useNavigate } from 'react-router-dom';
import Colors from '../../../const/Colors';
import styled from 'styled-components';
import PurchaseEndHeader from '../common/Header';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Breakpoints from '../../../const/breakpoints';
import { map } from '../../../const/theme';

const FailedArtStyle = styled.div`
  img {
    height: 35rem;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: left;
  }
`;

const Scallop = styled.div`
  position: relative;
  top: -3.5rem;
`;

const Details = styled.div`
  margin-top: -3.5rem;
  padding: 0 3rem;

  ${map({ tablet: '60rem' }, (maxWidth) => `max-width: ${maxWidth};`)};
  ${map({ tablet: '0 auto' }, (margin) => `margin: ${margin};`)};
  ${map({ tablet: '0,5rem' }, (marginTop) => `margin-top: ${marginTop};`)};
`;

const TitleStyle = styled.div`
  white-space: pre-line;
  font-size: 2.9rem;
  line-height: 4.1rem;
  text-align: center;
  font-family: 'Agrandir-TextBold';
  margin-bottom: 2rem;
  ${map(
    { tablet: '2.6rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
`;

const SubTitle = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 6rem;
  text-align: center;

  a {
    color: ${Colors.black};
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-bottom: 10.3rem;
  ${map({ tablet: '29rem' }, (maxWidth) => `max-width: ${maxWidth};`)};
`;

interface OwnProps {}

const TITLE = `Oops!
Something went wrong.`;

const Failed: React.FC<OwnProps> = () => {
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();
  const showHeader = useMemo(
    () => (windowWidth < Breakpoints.tablet ? false : true),
    [windowWidth]
  );

  const onClick = () => {
    navigate('/shop');
  };
  return (
    <div className="purchaseFailed">
      {showHeader && <PurchaseEndHeader />}

      <FailedArtStyle>
        <img src={FailedArt} alt="failed art" />
      </FailedArtStyle>

      <Scallop>
        <Border height="62" fill={Colors.white} />
      </Scallop>

      <Details>
        <TitleStyle>{TITLE}</TitleStyle>

        <SubTitle>
          Try again or get in touch with{' '}
          <Link to="/customer-service">Special customer service</Link>
        </SubTitle>

        <ButtonWrapper>
          <Button title="TRY AGAIN" role="Primary" onClick={onClick} />
        </ButtonWrapper>
      </Details>
    </div>
  );
};

export default Failed;
