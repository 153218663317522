import './heroTitle.scss';

type props = {
  uppercase?: boolean;
  inverse?: boolean;
  sideBySide?: boolean;
  children?: any;
  size?: 'small' | 'x-small' | undefined;
};

const HeroTitle = ({
  children,
  sideBySide,
  uppercase: upper = true,
  size,
  inverse,
}: props) => {
  return (
    <h1
      className={`title ${upper && 'upper'} ${inverse && 'inverse'} ${sideBySide && 'sideBySide'} ${
        size || ''
      }`}>
      {children}
    </h1>
  );
};

export default HeroTitle;
