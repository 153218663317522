import { useState} from "react";
import PhoneCodeModal from "./PhoneCodeModal";
import PhoneNumberModal from "./PhoneNumberModal";

type props = {
  setOpen(value: boolean): void;
}

const PhoneModal = ({setOpen}: props) => {
  const [sentCode, setSentCode] = useState(false);
  const [newNumber, setNewNumber ] = useState('');

  return (
    <>
      <div className="Modal">
        {sentCode ? (
          <>
            <PhoneCodeModal setOpen={setOpen} newNumber={newNumber} />
          </>
        ):(
          <>
            <PhoneNumberModal setOpen={setOpen} setSentCode={setSentCode} setNewNumber={setNewNumber} />
          </>
        )}
      </div>
    </>
  );
}

export default PhoneModal;