import styled from 'styled-components';
import { map } from '../../const/theme';

export const Logo = styled.img`
  max-width: 11rem;
  ${map({ tablet: '16rem' }, (maxWidth) => `max-width: ${maxWidth};`)}
  margin: 0 6.8rem;
  ${map({ tablet: '0 10rem' }, (margin) => `margin: ${margin};`)}
`;

export const TopScrollerWrapper = styled.div`
  margin-top: 9.4rem;
`;

export const BottomScrollerWrapper = styled.div`
  margin-top: 9.4rem;
`;

export const LoaderWrapper = styled.div`
  margin: 9.4rem 0;
`;
