import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import OptInWrapper from '../OptInWrapper';

interface IProps {}

const OptInConfirmation: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  return (
    <OptInWrapper hideBackButton={true}>
      <p className="header centered">Thank you! 🎉</p>
      <p className="desc centered">
        You've successfully claimed your business and we'll be in contact.
      </p>
      <Button
        title="GO TO HOMEPAGE"
        onClick={() => {
          navigate('/');
        }}
        role="Secondary"
        style={{ marginTop: '2.5rem' }}
      />
    </OptInWrapper>
  );
};

export default OptInConfirmation;
