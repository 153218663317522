import { Hero, HeroSubtitle, HeroTitle } from "../../components/Hero";
import Plugs from '../../assets/svg/Plugs.svg';
import { useLocation, useNavigate } from "react-router";
import { Button } from "../../components/Button";
import './404.scss';

const NotFound = () => {

  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="content" style={{background: "#E9C0FF"}}>
      <Hero>
        <HeroTitle sideBySide={true}>
          OOPS
          <div style={{textAlign: 'right'}}><img className="icon" src={Plugs} alt="plugs" /></div>
        </HeroTitle>
        <HeroSubtitle>
          404 PAGE NOT FOUND
        </HeroSubtitle>
      </Hero>
      <div className="notfound-container">
        <p className="notfound-text">The page you are looking for {location.pathname} can't be found.</p> 
        <p className="notfound-text">Check that you typed the address correctly, go back to your previous page or visit the home page.</p>
        <div className="notfound-button-container">
          <Button buttonClass="notfound-home-button" role="Primary" title="BACK TO HOME" startIconClass="heart-icon" onClick={()=>navigate('/')} />
          <Button buttonClass="notfound-previous-button" role="Primary" color="white" title="BACK TO PREVIOUS PAGE" onClick={()=>navigate(-1)} />
        </div>
      </div>
    </div >
  )
}

export default NotFound;