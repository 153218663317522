import React from 'react';
import { ReactComponent as EyeSvg } from '../../../assets/svg/activation/DarkEye.svg';
import Header from '../../../components/Header';
import { Button } from '../../../components/Button';

import {
  ButtonWrapper,
  Container,
  Wrapper,
  Divider,
  EyeStyles,
  MainSection,
  Message,
  PaddedWrapper,
  OopsText,
  SomethingText,
  RightWrapper,
} from './styles';
import { Link, useNavigate } from 'react-router-dom';
import Colors from '../../../const/Colors';

interface OwnProps {}

const SomethingWrong: React.FC<OwnProps> = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/');
  };

  return (
    <Wrapper>
      <Container>
        <div style={{ width: '100%' }}>
          <Header theme="something-wrong" displayMobileMenu={false} />
          <PaddedWrapper>
            <MainSection>
              <OopsText>OOPS</OopsText>
              <RightWrapper>
                <EyeStyles>
                  <EyeSvg height={'100%'} width={'100%'} />
                </EyeStyles>
                <SomethingText>Something went wrong</SomethingText>
              </RightWrapper>
            </MainSection>
            <Divider />
            <Message>
              This card cannot be used please contact our{' '}
              <Link to="/contact-us" style={supportStyle}>
                support
              </Link>
              .
            </Message>
          </PaddedWrapper>
        </div>
        <PaddedWrapper>
          <ButtonWrapper>
            <Button
              title="BACK TO HOME"
              startIconClass={'heart-icon'}
              role="Primary"
              onClick={onClick}
            />
          </ButtonWrapper>
        </PaddedWrapper>
      </Container>
    </Wrapper>
  );
};

const supportStyle: React.CSSProperties = {
  color: Colors.black,
};
export default SomethingWrong;
