import { Route, Routes } from "react-router";
import CreateAccountEmail from "../containers/Auth/CreateAccountEmail";
import CreateAccountName from "../containers/Auth/CreateAccountName";
import CreateAccountPassword from "../containers/Auth/CreateAccountPassword";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import ForgotPasswordSubmit from "../containers/Auth/ForgotPasswordSubmit";
import Login from "../containers/Auth/Login";
import PreCreateAccount from "../containers/Auth/PreCreateAccount";
import PreLogin from "../containers/Auth/PreLogin";
import SessionTimeOut from "../containers/Auth/SessionTimeOut";
import VerifyPhone from "../containers/Auth/VerifyPhone";
import VerifyPhoneSubmit from "../containers/Auth/VerifyPhoneSubmit";

const AuthRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<PreLogin />} />
        <Route path="createAccount" element={<PreCreateAccount />} />
        <Route path="login" element={<Login />} />
        <Route path="createAccountName" element={<CreateAccountName />} />
        <Route path="createAccountEmail" element={<CreateAccountEmail />} />
        <Route path="createAccountPassword" element={<CreateAccountPassword />} />
        <Route path="verifyPhone" element={<VerifyPhone />} />
        <Route path="verifyPhoneSubmit" element={<VerifyPhoneSubmit />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="forgotPasswordSubmit" element={<ForgotPasswordSubmit />} />
        <Route path="sessionTimeOut" element={<SessionTimeOut />} /> 
      </Routes>
    </>
  )

}

export default AuthRoutes;