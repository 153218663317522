import * as React from 'react';
import Colors from '../../../const/Colors';

interface OwnProps {
  color?: string;
}
const TopStars: React.FC<OwnProps> = ({ color = Colors.starOrange }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'100%'}
    height={'100%'}
    viewBox="0 0 118 118"
    fill="none">
    <path
      d="M34.694 56.21a37.632 37.632 0 0 1-14.361 2.861h3.288-3.308a37.635 37.635 0 0 1 26.602 11.03 37.6 37.6 0 0 1 11.002 26.643v.153c0-20.602 16.38-37.365 36.782-37.826h.863c-.204 0-.407-.001-.61-.005.2-.003.4-.004.6-.005h-.849C74.3 58.604 57.917 41.866 57.917 21.237v.158a37.577 37.577 0 0 1-11 26.648 37.617 37.617 0 0 1-12.223 8.169ZM8.097 19.633a21.124 21.124 0 0 1-8.062 1.606h3.863H.023a21.13 21.13 0 0 1 14.933 6.191 21.106 21.106 0 0 1 6.176 14.956v.086c0-11.569 9.202-20.98 20.66-21.233h.472c-.111 0-.223-.001-.334-.003l.328-.003h-.464C30.335 20.983 21.132 11.585 21.132 0v.089a21.093 21.093 0 0 1-6.174 14.958 21.116 21.116 0 0 1-6.861 4.586Z"
      fill={color}
    />
  </svg>
);

export default TopStars;
