import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import { EMAIL_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import { useAlert } from 'react-alert';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';

type FormValues = {
  email: string;
};

const ForgotPassword = () => {
  const auth = useAuth();
  const alert = useAlert();
  const navigate = useNavigate();
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
    reValidateMode: 'onBlur',
  });
  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    RudderStack.page("Auth_ForgotPassword");
  },[])


  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isPosting) {
      return false;
    }
    setIsPosting(true);
    try {
      const result = await auth.forgotPassword(data.email);
      if (!!result) {
        navigate('/auth/forgotPasswordSubmit', {
          state: {
            deliveryMedium: result.CodeDeliveryDetails.DeliveryMedium,
            destination: result.CodeDeliveryDetails.Destination,
            userName: data.email,
            isUpdate: false,
          },
        });
      } else {
        alert.error('You are sending code too fast, please wait 60 seconds.');
      }
    } catch (err: any) {
      setIsPosting(false);
      console.log(err);
    }

    setIsPosting(false);
  };

  return (
    <AuthWrapper>
      <>
        <p className="auth-create-account-title">Forgotten your password?</p>
        <p className="auth-create-account-subtitle">
          Enter the email address that you used to create your account.
        </p>
        <Controller
          control={control}
          rules={{ required: true, pattern: EMAIL_REGEX }}
          render={({ field: { onChange, value, onBlur } }) => (
            <Input
              value={value.toLowerCase()}
              onChange={onChange}
              type="text"
              inputMode="email"
              label="Email address"
            />
          )}
          name="email"
        />
        {errors.email && <ErrorText text="Enter a valid email address." />}
        <Button
          role="Secondary"
          title="Send Code"
          buttonClass={'auth-button'}
          onClick={handleSubmit(onSubmit)}
        />
      </>
    </AuthWrapper>
  );
};

export default ForgotPassword;
