import { Hero, HeroTitle } from '../../components/Hero';
import Image from '../../components/Image';
import ApplePay from '../../assets/svg/ApplePay.svg';
import Star from '../../assets/svg/Star.svg';
import ApplePayWhiteBorder from '../../assets/svg/ApplePayWhiteBorder.svg';
import PayWave from '../../assets/svg/PayWaveWhite.svg';
import ApplePayMobile from '../../assets/svg/ApplePayMobile.svg';
import PhoneAndWatch from '../../assets/svg/PhoneAndWatch.svg';
import Timeline from '../../components/Timeline';
import { Accordion } from '../../components/Accordion';
import Body from '../../components/Body';

import { Helmet } from 'react-helmet';

import './apple-pay-landing.scss';
import { useEffect } from 'react';
import { RudderStack } from '../../services/shared/RudderStack';

const ApplePayLanding = () => {
  useEffect(() => {
    RudderStack.page('AppPayLanding');
  }, []);

  const tabContent = [
    {
      label: 'Where can I use Apple Pay?',
      content:
        'You can use Apple Pay to make payments at any merchant where contactless payments are accepted. Simply look for the contactless symbol or Apple Pay mark. You may also use Apple Pay in-app and on the web with Safari when you see the “Buy with Apple Pay” button or Apple Pay as a payment option.',
    },
    {
      label: 'What is my Device Account Number, and where can I find it?',
      content:
        'All cards stored in Apple Pay are allocated a Device Account Number. This unique number ensures your payment information isn’t stored on your device or shared with the merchant when making a payment. You can find your Device Account Number by opening the Wallet app, selecting your Special Card and tapping the (i) symbol in the bottom righter corner.',
    },
    {
      label: 'Which cards are eligible for Apple Pay?',
      content: 'Apple Pay can be used with all Special Cards.',
    },
    {
      label: 'Where can I find out more information about Apple Pay?',
      content:
        'To find out more information on how to make secure payments and set up Apple Pay with your Special Card, please visit the Apple Pay webpage.',
    },
    {
      label: 'What Apple devices are compatible with Apple Pay?',
      content:
        'Apple Pay works with iPhone 6 and later in stores, apps, and websites in Safari; with Apple Watch in stores and apps (requires iPhone 6 or later); For a list of compatible Apple Pay devices, see here.',
    },
  ];

  return (
    <Body theme="soft-lilac">
      <Helmet>
        <title>Special Gift Cards | Apple Pay</title>
        <meta
          name="description"
          content="Apple Pay is the safer, faster way to pay in stores with your iPhone and Apple Watch. Quickly check out with the devices you carry with you every day.
          "
        />
      </Helmet>
      <div className="apple-wrapper">
        <div className="content">
          <Hero>
            <HeroTitle size="small" uppercase={false}>
              Apple Pay
            </HeroTitle>
          </Hero>
          <Image desktopImage={ApplePay} mobileImage={ApplePayMobile} />
          <h3 className="sub-heading set">
            How to set your gift card in the Special app and add it to Apple
            Wallet
          </h3>
          <Timeline />
          <p className="blurby col-72 centered">
            <h2 className="use">
              <span>How to use </span>
              <br /> Apple Pay
            </h2>
            Apple Pay is the safer, faster way to pay in stores with your iPhone
            and Apple Watch.
            <br />
            <br />
            Quickly check out with the devices you carry with you every day.
          </p>
          <div className="phone-watch">
            <img src={PhoneAndWatch} alt="phone and watch" />
            <div>
              <div>
                <img src={Star} alt="star" />
                <div>
                  <h3>To pay with Face ID on iPhone</h3>
                  <p className="blurby">
                    Double click the side button, glance at your screen, then
                    hold the iPhone near the reader.
                  </p>
                </div>
              </div>
              <div>
                <img src={Star} alt="star" />
                <div>
                  <h3>To pay with Apple Watch</h3>
                  <p className="blurby">
                    Double click the side button, glance at your screen, then
                    hold the iPhone near the reader.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="blurby centered col-60">
            <h3 className="sub-heading set safe">Safer & Faster</h3>
            Apple Pay is also the safer and faster way to pay online and in apps
            because you no longer have to create an account or fill out lengthy
            forms.
            <br />
            <br />
            <b>You can use Apple Pay wherever you see these symbols.</b>
            <div className="icons">
              <img src={ApplePayWhiteBorder} alt="apple pay" />
              <img src={PayWave} alt="pay wave" />
            </div>
          </p>
          <div className="videos">
            <div>
              <h3>How to use Face ID with Apple Pay</h3>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/eHoINVFTEME"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}></iframe>
            </div>
            <div>
              <h3>How to use Touch ID with Apple Pay</h3>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/35mdHemHWZk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}></iframe>
            </div>
          </div>
          <h2 className="faqs">Apple Pay FAQs</h2>
          <div className="faqs-accordian">
            <Accordion tabs={tabContent} />
          </div>
          <p className="disclaimer">
            Special Gift Card are issued by 545490 Pty. Ltd. ABN 83 648 605 225.
            Apple Pay, Apple Watch, Face ID, iPhone are trademarks of Apple
            Inc., registered in the U.S. and other countries.
          </p>
        </div>
      </div>
    </Body>
  );
};
export default ApplePayLanding;
