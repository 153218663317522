import React, { useMemo } from 'react';
import DynamicCard from '../../../../components/DynamicCard';
import Colors from '../../../../const/Colors';
import { useActivation } from '../../../../context/ActivationProvider';
import styled from 'styled-components';
import { map } from '../../../../const/theme';

const CardStyle = styled.div`
  display: inline-block;
  height: 14rem;
  ${map({ tablet: '18.2rem' }, (height) => `height: ${height};`)}
  width: 22rem;
  ${map({ tablet: '29rem' }, (width) => `width: ${width};`)}
`;

interface OwnProps {}

const ActivationCard: React.FC<OwnProps> = () => {
  const activation = useActivation();
  const isBranded = useMemo(() => {
    return !!activation.activationData.activationDetails.brandMerchant;
  }, [activation.activationData.activationDetails.brandMerchant]);

  const { affiliationTextColour } =
    activation?.activationData?.activationDetails?.brandMerchant || {};

  const frontImage = useMemo(
    () => activation.activationData.activationDetails.frontImage,
    [activation.activationData.activationDetails.frontImage]
  );

  const starOneColour = useMemo(
    () => activation.activationData.activationDetails.starOneColour,
    [activation.activationData.activationDetails.starOneColour]
  );

  const starTwoColour = useMemo(
    () => activation.activationData.activationDetails.starTwoColour,
    [activation.activationData.activationDetails.starTwoColour]
  );

  const topStarsColour = useMemo(() => {
    if (isBranded) {
      return affiliationTextColour || Colors.seaGreen;
    }
    return starOneColour || Colors.starOrange;
  }, [isBranded, affiliationTextColour, starOneColour]);

  const bottomStarsColour = useMemo(() => {
    if (isBranded) {
      return affiliationTextColour || Colors.seaGreen;
    }
    return starTwoColour || Colors.electricBlue;
  }, [isBranded, affiliationTextColour, starTwoColour]);

  return (
    <CardStyle>
      <DynamicCard
        imageURL={frontImage}
        topStarsColor={topStarsColour}
        bottomStarsColor={bottomStarsColour}
        topStarHeight={47}
        topStarWidth={47}
        topStarTopPosition={-20}
        topStarLeftPosition={-47}
        bottomStarHeight={56}
        bottomStarWidth={56}
        bottomStarBottomPosition={-10}
        bottomStarRightPosition={-56}
      />
    </CardStyle>
  );
};

export default ActivationCard;
