import styled from 'styled-components';
import Colors from '../../../../const/Colors';
import { map } from '../../../../const/theme';

export const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    .arrow-wrapper {
      opacity: 1;
    }
  }
`;

export const CircularDeleteWrapper = styled.div`
  height: 2.4rem;
  width: 2.4rem;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Wording = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1.8rem;
  margin-right: 1.8rem;
`;

export const BoldText = styled.span`
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
`;

export const NormalText = styled.span`
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: ${Colors.line1};
`;

export const CancelWrapper = styled.div`
  margin-top: 2rem;
  ${map({ mobile: '3rem' }, (marginTop) => `margin-top: ${marginTop};`)}
`;
