import React, { useEffect, useMemo, useState } from 'react';
import { useApi } from '../../api/ApiProvider';
import _ from 'lodash';

import Marquee from 'react-fast-marquee';
import Loader from '../Loader';
import Colors from '../../const/Colors';
import {
  LoaderWrapper,
  TopScrollerWrapper,
  BottomScrollerWrapper,
  Logo,
} from './styles';

export interface IBrandScrollerProps {}

const BrandScroller: React.FC<IBrandScrollerProps> = () => {
  const { homeApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [brandLogos, setBrandLogos] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const featuredMerchantResponse = await homeApi.getFeaturedMerchants();
        const logos = featuredMerchantResponse.data.map((merchant) => {
          return merchant.logo;
        });
        const validLogos = _.compact(logos);
        setBrandLogos(validLogos);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandLogoCount = useMemo(() => {
    return brandLogos.length;
  }, [brandLogos]);

  const CHUNK_SIZE = useMemo(() => {
    const hasEvenNumberOfLogos = brandLogoCount % 2 === 0;
    const size = Math.floor(
      hasEvenNumberOfLogos ? brandLogoCount / 2 : brandLogoCount / 2 + 1
    );
    return Math.max(size, 1);
  }, [brandLogoCount]);

  const [firstRowChunk, secondRowChunk] = useMemo(
    () => _.chunk(brandLogos, CHUNK_SIZE),
    [brandLogos, CHUNK_SIZE]
  );

  if (brandLogoCount === 0) {
    return null;
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader loadingTextStyle={{ color: Colors.white }} />;
      </LoaderWrapper>
    );
  }

  return (
    <div>
      <TopScrollerWrapper>
        <Marquee speed={100} gradient={false}>
          {firstRowChunk.map(
            (logo, index) => logo && <Logo src={logo} alt="logo" key={index} />
          )}
        </Marquee>
      </TopScrollerWrapper>
      <BottomScrollerWrapper>
        <Marquee speed={100} gradient={false} direction="right">
          {secondRowChunk.map(
            (logo, index) => logo && <Logo src={logo} alt="logo" key={index} />
          )}
        </Marquee>
      </BottomScrollerWrapper>
    </div>
  );
};

export default BrandScroller;
