import React from 'react';
import styled from 'styled-components';
import SpinnerSVG from '../../assets/svg/Spinner.svg';

type Props = {
  size?: number;
  marginRight?: number;
};

const SpinnerContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.marginRight}px;
  img {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;

const Spinner = ({ size = 45, marginRight = 0 }: Props) => {
  return (
    <SpinnerContainer size={size} marginRight={marginRight}>
      <img src={SpinnerSVG} alt="Loading..." />
    </SpinnerContainer>
  );
};

export default Spinner;
