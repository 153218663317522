import {
  createContext,
  MutableRefObject,
  useContext,
  useRef,
  useState,
} from 'react';
import { useApi } from '../api/ApiProvider';
import { PaymentIntentVM } from '../generated';
import { COMMON_ERR_MSG } from '../const/shared';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js';
import { useAppContext } from './AppProvider';

const PaymentContext = createContext<PaymentContextData>(
  {} as PaymentContextData
);

export interface IPaymentResult {
  error?: string;
  success: boolean;
  paymentIntentId?: string | null;
}

type PaymentContextData = {
  onSubmitCardPayment: (
    giftCardId: number,
    profileId: number,
    valueInDollar: number,
    promoCode: string,
    recipientEmail: string,
    recipientFullname: string,
    recipientPhoneNumber: string,
    paymentProvider: string
  ) => Promise<IPaymentResult>;
  getPaymentReceiptUrl: (userGiftCardId: number) => Promise<string | null>;
  createPaymentRequest: (cardName: string, valueInDollar: number) => void;
  updatePaymentRequest: (
    giftCardId: number,
    cardName: string,
    profileId: number,
    valueInDollar: number,
    promoCode: string,
    recipientEmail: string,
    recipientFullname: string,
    recipientPhoneNumber: string,
    paymentProvider: string,
    postPayment: (
      success: boolean,
      paymentIntentId?: string | null,
      error?: string
    ) => void
  ) => void;
  paymentRequest: PaymentRequest | undefined;
  paymentOptions: CanMakePaymentResult;
  paymentMethod: MutableRefObject<string>;
};

const PaymentProvider: React.FC = ({ children }) => {
  const { paymentApi } = useApi();
  const { sessionId, purchaseAttemptId } = useAppContext();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<
    PaymentRequest | undefined
  >(undefined);
  const [paymentOptions, setPaymentOptions] = useState<CanMakePaymentResult>(
    {} as CanMakePaymentResult
  );
  const paymentMethod = useRef<string>('App Pay');

  const createPaymentRequest = (cardName: string, valueInDollar: number) => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'AU',
        currency: 'aud',
        total: {
          label: cardName,
          amount: 100 * valueInDollar,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result && pr) {
          setPaymentOptions(result);
          setPaymentRequest(pr);
        }
      });
    }
  };

  const updatePaymentRequest = (
    giftCardId: number,
    cardName: string,
    profileId: number,
    valueInDollar: number,
    promoCode: string,
    recipientEmail: string,
    recipientFullname: string,
    recipientPhoneNumber: string,
    paymentProvider: string,
    postPayment: (
      success: boolean,
      paymentIntentId?: string | null,
      error?: string
    ) => void
  ) => {
    if (!paymentRequest || !stripe) {
      return;
    }

    paymentRequest.update({
      total: {
        label: cardName,
        amount: 100 * valueInDollar,
      },
    });

    paymentRequest.off('paymentmethod');

    paymentRequest.on('paymentmethod', async (ev) => {
      const intent = await fetchPaymentSheetParams(
        giftCardId,
        profileId,
        valueInDollar,
        promoCode,
        recipientEmail,
        recipientFullname,
        recipientPhoneNumber,
        paymentProvider
      );
      const clientSecret = intent?.clientSecret;
      if (clientSecret) {
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: true }
          );
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
          postPayment(false, null, confirmError.code);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          postPayment(true, paymentIntent?.id, confirmError);
        }
      }
    });
  };

  const getPaymentReceiptUrl = async (
    userGiftCardId: number
  ): Promise<string | null> => {
    if (userGiftCardId <= 0) {
      return null;
    }
    try {
      const response = await paymentApi.getPaymentReceiptUrl(userGiftCardId);
      const receiptUrl = response.data;
      return receiptUrl;
    } catch (err: any) {
      console.log(err);
      return null;
    }
  };

  const fetchPaymentSheetParams = async (
    giftCardId: number,
    profileId: number,
    valueInDollar: number,
    promoCode: string,
    recipientEmail: string,
    recipientFullname: string,
    recipientPhoneNumber: string,
    paymentProvider: string
  ): Promise<PaymentIntentVM | undefined | null> => {
    if (valueInDollar <= 0) {
      return null;
    }
    try {
      const valueInCent = valueInDollar * 100;
      const response = await paymentApi.createPaymentIntent(
        giftCardId,
        profileId,
        valueInCent,
        'Web',
        sessionId,
        purchaseAttemptId,
        navigator.userAgent,
        promoCode,
        recipientEmail,
        recipientFullname,
        recipientPhoneNumber,
        paymentProvider
      );
      const paymentIntentVM: PaymentIntentVM = response.data;
      return paymentIntentVM;
    } catch (err: any) {
      console.log(err);
      return null;
    }
  };

  const onSubmitCardPayment = async (
    giftCardId: number,
    profileId: number,
    valueInDollar: number,
    promoCode: string,
    recipientEmail: string,
    recipientFullname: string,
    recipientPhoneNumber: string,
    paymentProvider: string
  ): Promise<IPaymentResult> => {
    if (!elements || !stripe) {
      return {
        error: COMMON_ERR_MSG,
        success: false,
      };
    }

    paymentMethod.current = 'Credit Card';
    // Fetch the intent client secret from the backend
    const paymentIntent = await fetchPaymentSheetParams(
      giftCardId,
      profileId,
      valueInDollar,
      promoCode,
      recipientEmail,
      recipientFullname,
      recipientPhoneNumber,
      paymentProvider
    );
    const clientSecret = paymentIntent?.clientSecret;

    if (clientSecret) {
      // Confirm the payment with the card details
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement) as any,
          },
        },
        {
          handleActions: true,
        }
      );

      if (error) {
        return {
          error: error.message,
          success: false,
        };
      } else if (paymentIntent) {
        return {
          success: true,
          paymentIntentId: paymentIntent.id,
        };
      }
    }
    return {
      error: COMMON_ERR_MSG,
      success: false,
    };
  };

  return (
    <PaymentContext.Provider
      value={{
        onSubmitCardPayment,
        getPaymentReceiptUrl,
        createPaymentRequest,
        updatePaymentRequest,
        paymentOptions,
        paymentRequest,
        paymentMethod,
      }}>
      {children}
    </PaymentContext.Provider>
  );
};

function usePayment(): PaymentContextData {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('usePayment must be used within an AuthProvider');
  }

  return context;
}

export { PaymentProvider, usePayment };
