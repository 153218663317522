import './text-area-input.scss';

interface TextAreaInputProps extends React.InputHTMLAttributes<any> {
  label?: string;
  error?: boolean;
  labelStyle?: React.CSSProperties;
}

const TextAreaInput = ({
  label,
  placeholder,
  error,
  labelStyle,
  ...props
}: TextAreaInputProps) => {
  const errorClass = error ? 'error' : '';
  return (
    <label className={`special-text-area-input ${errorClass}`}>
      <textarea {...props} className={`field ${errorClass}`} placeholder=" " />
      <span className={`label ${errorClass}`} style={labelStyle}>
        {label}
      </span>
    </label>
  );
};

export default TextAreaInput;
