import styled from 'styled-components';
import Colors from '../../../../../const/Colors';

export const OptionStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
export const TextStyles = styled.div`
  font-size: 1.6rem;
  line-height: 2.1rem;
  padding-top: 0.4rem;
  color: ${Colors.black};
`;

export const RadioStyles = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  min-height: 2.4rem;
  min-width: 2.4rem;
  border-radius: 50%;
  border-width: 0.2rem;
  border-style: solid;
  padding: 0.2rem;
  margin-right: 1.1rem;
`;

export const InnerCircleStyle = styled.div`
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
`;
