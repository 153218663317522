import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import './Auth.scss';
import { Input } from '../../components/Input';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { EMAIL_REGEX } from '../../const/shared';
import { ErrorText } from '../../components/ErrorText';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';

const Login = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const alert = useAlert();

  useEffect(() => {
    RudderStack.page('Auth_Login');
  }, []);

  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userName: '',
      password: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await auth.signIn(data.userName.trim(), data.password.trim());
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      alert.error('Invalid email or password');
    }
  };

  return (
    <AuthWrapper
      className="auth-page-pre"
      containerClassName="auth-container-pre">
      <>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: EMAIL_REGEX,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              autoFocus
              label="Email"
              type="text"
              onBlur={onBlur}
              onChange={onChange}
              value={value.toLowerCase()}
              inputMode="email"
              error={!!errors.userName}
            />
          )}
          name="userName"
        />
        {errors.userName && (
          <div>
            <ErrorText text="Enter a valid email address" />
          </div>
        )}
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              className="auth-input"
              label="Password"
              type={'password'}
              error={!!errors.password}
              maxLength={50}
            />
          )}
          name="password"
        />
        {errors.password && (
          <div>
            <ErrorText text="Password is required" />
          </div>
        )}
        <p className="auth-forgot-link">
          <Link to="/auth/forgotPassword">Forgot your password?</Link>
        </p>
        <Button
          startIconClass="heart-icon"
          loading={isLoading}
          title="Log in"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
        />
        <div className="auth-cancel" onClick={() => navigate('/auth')}>
          CANCEL
        </div>
      </>
    </AuthWrapper>
  );
};

export default Login;
