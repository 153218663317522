import React from 'react';
import { useNavigate } from 'react-router-dom';
import RightThinArrowBack from '../../assets/svg/RightThinArrowBack.svg';
import { usePromotion } from '../../hooks/usePromotion';
import DiscountTag from './DiscountTag';
import './shop-card.scss';

type Props = {
  name?: string | null;
  minimalValue?: number;
  maximalValue?: number;
  id?: number;
  imgSrc: string;
  tag?: string | null;
  slug?: string | null;
};

const ShopCard = ({
  id,
  minimalValue,
  maximalValue,
  name,
  imgSrc,
  tag,
  slug,
}: Props) => {
  const navigate = useNavigate();
  const { displayPromo } = usePromotion();
  return (
    <div className="card" onClick={() => navigate(`/shop/${slug}`)}>
      <div className="card-image-container">
        <img className="card-image" src={imgSrc} alt="card" />
      </div>
      <div className="details">
        <p>{name}</p>
        <div className="price-wrapper">
          <p className="price">
            ${minimalValue}-${maximalValue}
          </p>
          <img src={RightThinArrowBack} alt="right arrow black" />
        </div>
      </div>
      {displayPromo && tag && <DiscountTag discountText={tag} />}
    </div>
  );
};

export default ShopCard;
