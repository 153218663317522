import React, { useCallback } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetError,
} from 'react-hook-form';
import { Moment } from 'moment';
import { FormValues } from '..';
import {
  Input,
  MobileInput,
  TextAreaInput,
} from '../../../../components/Input';
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  NAME_CHAR_LIMIT,
  NO_LINK_TEXT_REGEX,
} from '../../../../const/shared';
import { usePurchase } from '../../../../context/PurchaseProvider';
import { UserGiftCardSendingMethod } from '../../../../generated';
import styled from 'styled-components';
import { Alert } from '../../../../components/Alert';
import Info from '../../../../assets/svg/alert/Info.svg';
import DateTimePicker from '../../Delivery/common/DateTimePicker';
import Switch from '../Switch';
import { ErrorText } from '../../../../components/ErrorText';
import { MAX_MESSAGE_LENGTH } from '../../Personalise';

const ItemWrapper = styled.div`
  margin-bottom: 2rem;
`;
const SendNow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
`;

const SendNowText = styled.div`
  font-size: 1.6rem;
`;

const AlertWrapper = styled.div`
  .special-alert {
    width: unset;
    height: unset;
  }
`;

const MessageCount = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: right;
  color: #778899;
  margin-top: 0.4rem;
  letter-spacing: 0.1rem;
  margin-top: 0.3rem;
`;

interface OwnProps {
  control: Control<FormValues, any>;
  errors: FieldErrors;
  momentDate: Moment;
  setDate: (utcIsoDateString: string, timeZone: number) => void;
  cardMinimumValue: number;
  cardMaximumValue: number;
  setError: UseFormSetError<FormValues>;
  isValidDate: boolean;
  setIsValidDate: (value: boolean) => void;
  isTimezoneSelected?: boolean;
  setIsTimezoneSelected: (value?: boolean) => void;
}
const EditMode: React.FC<OwnProps> = ({
  control,
  errors,
  momentDate,
  setDate,
  cardMinimumValue,
  cardMaximumValue,
  setError,
  isValidDate,
  setIsValidDate,
  isTimezoneSelected,
  setIsTimezoneSelected,
}) => {
  const purchase = usePurchase();

  const validateCustomInput = useCallback(
    (inputValue: number) => {
      if (inputValue < cardMinimumValue || inputValue > cardMaximumValue) {
        setError('value', { message: 'Value Invalid' });
        return false;
      }
      return true;
    },
    [cardMinimumValue, cardMaximumValue, setError]
  );
  return (
    <>
      <ItemWrapper>
        <Controller
          control={control}
          defaultValue={purchase.newPurchaseDetails.value}
          rules={{ required: true, validate: validateCustomInput }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                type="text"
                label="Custom Value"
                onChange={(newValue) => {
                  const value = newValue.target.value as string;
                  onChange(parseInt(value || '0'));
                }}
                onBlur={onBlur}
                error={!!errors.value}
                value={
                  value.toString() ||
                  purchase.newPurchaseDetails.value?.toString()
                }
              />
              {errors.value && (
                <div className="purchase-error-text">
                  <ErrorText
                    text={
                      errors.value.type === 'validate'
                        ? `Please enter a value between ${cardMinimumValue} and ${cardMaximumValue}`
                        : 'Invalid Value'
                    }
                  />
                </div>
              )}
            </>
          )}
          name="value"
        />
      </ItemWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          rules={{ required: true, pattern: NO_LINK_TEXT_REGEX }}
          defaultValue={purchase.newPurchaseDetails.receiverFirstName || ''}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                type="text"
                label="To (first name)"
                onChange={onChange}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                error={!!errors.receiverFirstName}
                value={value}
                maxLength={NAME_CHAR_LIMIT}
              />
              {errors.receiverFirstName && (
                <div className="purchase-error-text">
                  {errors.receiverFirstName?.type === 'pattern' && (
                    <ErrorText text="Recipient first name contains invalid characters" />
                  )}
                  {errors.receiverFirstName?.type === 'required' && (
                    <ErrorText text="Please enter the recipient's first name" />
                  )}
                </div>
              )}
            </>
          )}
          name="receiverFirstName"
        />
      </ItemWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          rules={{ required: true, pattern: NO_LINK_TEXT_REGEX, minLength: 2 }}
          defaultValue={purchase.newPurchaseDetails.receiverLastName || ''}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                type="text"
                label="To (last name)"
                onChange={onChange}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                error={!!errors.receiverLastName}
                value={value}
                maxLength={NAME_CHAR_LIMIT}
              />
              {errors.receiverLastName && (
                <div className="purchase-error-text">
                  {errors.receiverLastName?.type === 'pattern' && (
                    <ErrorText text="Recipient last name contains invalid characters" />
                  )}
                  {errors.receiverLastName?.type === 'required' && (
                    <ErrorText text="Please enter the recipient's last name" />
                  )}
                  {errors.receiverLastName?.type === 'minLength' && (
                    <ErrorText text="A minimum of 2 characters is required" />
                  )}
                </div>
              )}
            </>
          )}
          name="receiverLastName"
        />
      </ItemWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          rules={{ required: true, pattern: NO_LINK_TEXT_REGEX }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                type="text"
                label="From"
                onChange={onChange}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                error={!!errors.from}
                value={value}
                maxLength={NAME_CHAR_LIMIT}
              />
              {errors.from && (
                <div className="purchase-error-text">
                  {errors.from?.type === 'pattern' && (
                    <ErrorText text="Sender name contains invalid characters" />
                  )}
                  {errors.from?.type === 'required' && (
                    <ErrorText text="Please enter the sender name" />
                  )}
                </div>
              )}
            </>
          )}
          name="from"
        />
      </ItemWrapper>
      {purchase.newPurchaseDetails.sendingMethod ===
      UserGiftCardSendingMethod.Sms ? (
        <ItemWrapper>
          <Controller
            control={control}
            rules={{ required: true, pattern: MOBILE_REGEX }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <MobileInput
                  placeholder={`${purchase.newPurchaseDetails.receiverFirstName}'s number`}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.receiverPhoneNumber}
                  value={value}
                />
                {errors.receiverPhoneNumber && (
                  <div className="purchase-error-text">
                    <ErrorText
                      text={
                        errors.receiverPhoneNumber.type === 'pattern'
                          ? 'Please enter a valid Australian phone number'
                          : `Please enter the recipient’s phone number`
                      }
                    />
                  </div>
                )}
              </>
            )}
            name="receiverPhoneNumber"
          />
        </ItemWrapper>
      ) : (
        <ItemWrapper>
          <Controller
            control={control}
            rules={{ required: true, pattern: EMAIL_REGEX }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Input
                  type="text"
                  label={`${purchase.newPurchaseDetails.receiverFirstName}'s email`}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.receiverEmail}
                  inputMode="email"
                  value={value.toLowerCase()}
                />
                {errors.receiverEmail && (
                  <div className="purchase-error-text">
                    <ErrorText
                      text={
                        errors.receiverEmail.type === 'pattern'
                          ? 'Please enter a valid email address'
                          : `Please enter the recipient's email address`
                      }
                    />
                  </div>
                )}
              </>
            )}
            name="receiverEmail"
          />
        </ItemWrapper>
      )}
      <ItemWrapper>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <>
                <Input
                  type="text"
                  label="Scheduled delivery"
                  value={
                    purchase.newPurchaseDetails.sendNow === true ||
                    purchase.newPurchaseDetails.sendNow === undefined
                      ? 'Now'
                      : momentDate.format('DD-MM-YY | h:mm a')
                  }
                  onChange={() => {}}
                  editable={false}
                />
                <SendNow>
                  <SendNowText>Send Now</SendNowText>
                  <Switch
                    value={
                      purchase.newPurchaseDetails.sendNow === true ||
                      purchase.newPurchaseDetails.sendNow === undefined
                    }
                    onChange={() => {
                      const preValue =
                        purchase.newPurchaseDetails.sendNow === true ||
                        purchase.newPurchaseDetails.sendNow === undefined;
                      setIsValidDate(!preValue);
                      purchase.setNewPurchaseDetails({
                        ...purchase.newPurchaseDetails,
                        sendNow: !preValue,
                        deliveryDate: !preValue
                          ? undefined
                          : purchase.newPurchaseDetails.deliveryDate,
                        specialTimezoneId: !preValue
                          ? undefined
                          : purchase.newPurchaseDetails.specialTimezoneId,
                      });
                    }}
                  />
                </SendNow>
                <AlertWrapper>
                  <Alert
                    title="The card may not be delivered at the exact selected time."
                    role="Info"
                    icon={Info}
                  />
                </AlertWrapper>
                {purchase.newPurchaseDetails.sendNow === false && (
                  <DateTimePicker
                    date={purchase.newPurchaseDetails.deliveryDate}
                    setDate={setDate}
                    timeZoneId={purchase.newPurchaseDetails.specialTimezoneId}
                    isTimeZoneSelected={isTimezoneSelected}
                    setIsTimeZoneSelected={setIsTimezoneSelected}
                    isValidDate={isValidDate}
                    setIsValidDate={setIsValidDate}
                  />
                )}
              </>
            );
          }}
          name="deliveryDate"
        />
      </ItemWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextAreaInput
                label="Message"
                maxLength={MAX_MESSAGE_LENGTH}
                onChange={onChange}
                style={{ height: 200 }}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                error={!!errors.message}
                value={value}
              />
              <MessageCount>
                {value.length || 0}/{MAX_MESSAGE_LENGTH}
              </MessageCount>
              {errors.message && (
                <div className="purchase-error-text">
                  {errors.message?.type === 'pattern' && (
                    <ErrorText text="Message contains invalid characters" />
                  )}
                  {errors.message?.type === 'required' && (
                    <ErrorText text="Please write a personal message to the recipient" />
                  )}
                </div>
              )}
            </>
          )}
          name="message"
        />
      </ItemWrapper>
    </>
  );
};

export default EditMode;
