import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { map } from '../../const/theme';
import './side-drawer.scss';
import SideDrawerClose from '../../assets/svg/side-drawer/SideDrawerClose.svg';
interface IProps {
  children: JSX.Element;
  hideClose?: boolean;
}

interface IContainerProps {
  show: boolean;
}

const Container = styled.div<IContainerProps>`
  width: 100%;
  ${map({ tablet: '73rem' }, (size) => `width: ${size};`)}
  background: ${(props) => props.theme.colours.white};
  position: fixed;
  transition: all 0.2s ease-in-out;
  top: 0;
  right: ${(props) => (props.show ? '-1px' : '-100%')};
  height: 100vh;
  z-index: 999;
  border-left: 1px solid ${(props) => props.theme.colours.white};
  p {
    margin: 0;
  }
`;

const InnerContainer = styled.div`
  overflow-y: auto;
  height: 100vh;
  margin-top: 3rem;
  ${map({ tablet: '2.7rem' }, (marginTop) => `margin-top: ${marginTop};`)}
  padding: 0rem 3rem 13.5rem;
  ${map({ tablet: '0rem 10rem 3.5rem' }, (size) => `padding: ${size};`)}
`;

const BackDropContainer = styled.div`
  position: fixed;
  z-index: 20;
  height: 100vmax;
  width: 100vmax;
  display: block;
  background: ${(props) => props.theme.colours.black};
  opacity: 0.2;
  left: 0;
  top: 0;
`;

const CloseButton = styled.div`
  margin-bottom: 2rem;

  img {
    width: 3.2rem;
    ${map({ tablet: '4rem' }, (size) => `width: ${size};`)}
    cursor: pointer;

    &.close-btn {
      position: relative;
      left: 0rem;
      ${map({ tablet: '-5rem' }, (size) => `left: ${size};`)}
    }
  }
`;

export const SideDrawerHeader = styled.div`
  display: flex;
  gap: 3rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colours.black};
  img {
    flex: 0 0 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
    ${map({ tablet: '4rem' }, (size) => `height: ${size}`)}
    ${map({ tablet: '4rem' }, (size) => `width: ${size}`)}
    ${map({ tablet: '4rem' }, (size) => `flex: 0 0 ${size}`)}
    cursor: pointer;
  }

  div.header-title {
    font-size: 2rem;
    margin-top: 0.2rem;
    ${map({ tablet: '2.9rem' }, (size) => `font-size: ${size}`)}
    font-weight: bold;
  }
`;

export const SideDrawerContainer = styled.div``;

export interface ISideDrawerHandle {
  toggleShow: () => void;
}

const SideDrawer = forwardRef(
  (
    { children, hideClose = false }: IProps,
    ref: React.Ref<ISideDrawerHandle>
  ) => {
    const [show, setShow] = useState<boolean>(false);
    useImperativeHandle(
      ref,
      () => {
        return {
          toggleShow: () => {
            setShow((pre) => !pre);
          },
        };
      },
      []
    );

    useEffect(() => {
      if (show) {
        document.body.style.overflow = 'hidden';
      }

      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [show]);

    return (
      <>
        {show && (
          <BackDropContainer
            onClick={() => {
              setShow(false);
            }}
          />
        )}
        <Container className={show ? 'toothed' : undefined} show={show}>
          <InnerContainer id="SideDrawerInnerContainer">
            {!hideClose && (
              <CloseButton
                onClick={() => {
                  setShow(false);
                }}>
                <img className="close-btn" src={SideDrawerClose} alt="Close" />
              </CloseButton>
            )}
            {children}
          </InnerContainer>
        </Container>
      </>
    );
  }
);

export default SideDrawer;
