import './error-text.scss';

interface ErrorTextProps {
  text?: string;
  style?: {};
}

export const ErrorText = ({ text, ...props }: ErrorTextProps) => {
  return (
    <div className="special-error-text" {...props}>
      {text}
    </div>
  );
};
