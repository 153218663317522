import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import Body from '../../components/Body';
import { Button } from '../../components/Button';
import { Tab } from '../../components/Tab';
import { Tag, TagRole } from '../../components/Tag';
import {
  OrderVM,
  UserGiftCardDeliveryStatus,
  UserGiftCardOrderStatus,
  UserGiftCardStatus,
} from '../../generated';
import OrderModal from './OrderModal';
import './Orders.scss';
import NoOrderImage from '../../assets/svg/OrdersNotFound.svg';
import PageLoader from '../../components/PageLoader';
import SideDrawer, { ISideDrawerHandle } from '../../components/SideDrawer';
import { GIFT_CARD_WIDTH_HEIGHT_RATIO } from '../../const/shared';
import RightArrow from '../../assets/svg/RightThinArrowBack.svg';

import { useAppContext } from '../../context/AppProvider';
import { getOrderDate } from '../../helpers/utils';
import { useAuthGuard } from '../../hooks/useAuthGuard';
import { RudderStack } from '../../services/shared/RudderStack';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Breakpoints from '../../const/breakpoints';

export interface OrdersSorted {
  active: OrderVM[] | undefined;
  past: OrderVM[] | undefined;
}

type Tabs = 'ACTIVE' | 'PAST';

interface Status {
  title: string;
  role: TagRole;
}

const Orders = () => {
  const { userGiftCardApi } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<Tabs>('ACTIVE');
  const [orders, setOrders] = useState<OrdersSorted | null | undefined>(
    undefined
  );
  const [selectedOrder, setSelectedOrder] = useState<OrderVM>({} as OrderVM);
  const sideDrawerRef = useRef<ISideDrawerHandle>(null);

  const navigate = useNavigate();
  const { timezones } = useAppContext();
  const loadCards = useCallback(async () => {
    const response = await userGiftCardApi.myOrders(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      Math.pow(2, 30) - 1
    );
    const list = response.data.results;
    const activeOrders: OrderVM[] | undefined = [];
    const pastOrders: OrderVM[] | undefined = [];

    list?.forEach((order) => {
      if (
        order.deliveryStatus === UserGiftCardDeliveryStatus.Pending &&
        (order.orderStatus === UserGiftCardOrderStatus.Sold ||
          order.orderStatus === UserGiftCardOrderStatus.Review) &&
        order.status !== UserGiftCardStatus.Cancelled
      ) {
        activeOrders.push(order);
      } else {
        pastOrders.push(order);
      }
    });

    const sortedActiveOrders = activeOrders.sort((a: OrderVM, b: OrderVM) =>
      a.deliveryDate!.localeCompare(b.deliveryDate || '')
    );

    const sortedPastOrders = pastOrders.sort((a: OrderVM, b: OrderVM) =>
      b.deliveryDate!.localeCompare(a.deliveryDate || '')
    );

    const orders = {
      active: sortedActiveOrders,
      past: sortedPastOrders,
    };

    setOrders(orders);
    setIsLoading(false);
  }, [userGiftCardApi]);

  useAuthGuard();

  useEffect(() => {
    RudderStack.page('MyOrders');
  }, []);

  useEffect(() => {
    if (isLoading) loadCards();
  }, [isLoading, loadCards]);

  useEffect(() => {
    if (orders?.active?.length === 0) {
      setActiveTab('PAST');
    }
  }, [orders?.active]);

  function hasOrders() {
    return orders?.active?.length || orders?.past?.length;
  }

  const getStatus = (order: OrderVM): Status => {
    const status: Status = {
      title: '',
      role: 'Orange',
    };

    if (order.status === UserGiftCardStatus.Cancelled) {
      if (order.orderStatus === UserGiftCardOrderStatus.Refunded) {
        return { title: 'REFUNDED', role: 'GreenBorder' };
      }
      return { ...status, title: 'CANCELLED' };
    }
    if (
      order.status === UserGiftCardStatus.Claimed ||
      order.status === UserGiftCardStatus.Past
    ) {
      return { ...status, title: 'CLAIMED' };
    }

    if (order.orderStatus === UserGiftCardOrderStatus.Review) {
      return { ...status, title: 'PENDING' };
    }

    switch (order.deliveryStatus) {
      case UserGiftCardDeliveryStatus.Pending:
        return { ...status, title: 'SCHEDULED' };
      case UserGiftCardDeliveryStatus.Delivered:
        return { title: 'DELIVERED', role: 'GreenBorder' };
      case UserGiftCardDeliveryStatus.DeliveryFailed:
      default:
        return { ...status, title: 'ERROR' };
    }
  };
  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(() => {
    return windowWidth < Breakpoints.tablet;
  }, [windowWidth]);

  const [showMobileMenu, setShowMobileMenu] = useState(true);
  return (
    <div className="my-orders">
      <PageLoader isLoading={isLoading} />
      <Body
        theme="white"
        headerVariant="my-cards"
        disableFooter={true}
        displayMobileMenu={showMobileMenu}>
        {hasOrders() ? (
          <>
            {!showMobileMenu && setShowMobileMenu(true)}
            <div className="orderTabs">
              {(orders?.active || []).length > 0 && (
                <Tab
                  title={`${(orders?.active || []).length} ACTIVE`}
                  role={activeTab === 'ACTIVE' ? 'Active' : 'Regular'}
                  onClick={() => setActiveTab('ACTIVE')}
                />
              )}
              {(orders?.past || []).length > 0 && (
                <Tab
                  title={`${(orders?.past || []).length} PAST`}
                  role={activeTab === 'PAST' ? 'Active' : 'Regular'}
                  onClick={() => setActiveTab('PAST')}
                />
              )}
            </div>
            <div className="orderContainer">
              {(activeTab === 'ACTIVE' ? orders?.active : orders?.past)?.map(
                (order) => {
                  const { title, role } = getStatus(order);
                  return (
                    <div
                      key={order.id}
                      className="orderRow"
                      onClick={() => {
                        setSelectedOrder(order);
                        sideDrawerRef.current?.toggleShow();
                      }}>
                      <img
                        src={order.profile?.frontsideImage ?? ''}
                        height={100 * GIFT_CARD_WIDTH_HEIGHT_RATIO}
                        width={100}
                        style={{ borderRadius: '5px' }}
                        alt="Card"
                      />
                      <div style={{ minWidth: '20%' }}>
                        <p className="orderBoldText">{order.giftCardName}</p>
                        <p className="orderNumber">#{order.id}</p>
                      </div>
                      <div className="orderInfo">
                        <div className="orderData">
                          <p className="orderSubText">Recipient</p>
                          <p
                            className="orderBoldText"
                            style={{
                              width: isMobileView ? '14rem' : '16rem',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}>
                            {(order.userGiftCardPersonalization
                              ?.receiverFirstName ?? '') +
                              ' ' +
                              (order.userGiftCardPersonalization
                                ?.receiverLastName ?? '')}
                          </p>
                        </div>
                        <div className="orderData">
                          <p className="orderSubText">Scheduled</p>
                          <p className="orderBoldText">
                            {getOrderDate(order, timezones)}
                          </p>
                        </div>
                      </div>
                      <div>
                        <Tag title={title} role={role} />
                      </div>
                      <div>
                        <img src={RightArrow} className="orderArrow" alt="" />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <SideDrawer ref={sideDrawerRef}>
              <OrderModal order={selectedOrder} />
            </SideDrawer>
          </>
        ) : (
          <>
            {isMobileView ? (
              <>
                {showMobileMenu && setShowMobileMenu(false)}
                <div className="noOrderBackground">
                  <img
                    src={NoOrderImage}
                    className="noOrderImage"
                    alt="background"
                  />
                </div>
                <div className="no-order-content">
                  <p className="content-title">
                    You haven't purchased any gift cards
                  </p>
                  <p className="content-text">
                    Does a loved one have a special occasion coming up? Browse
                    the range for gift ideas.
                  </p>
                  <Button
                    role="Secondary"
                    title="Shop Now"
                    startIconClass="heart-icon"
                    style={{ marginTop: '2.4rem' }}
                    onClick={() => navigate('/shop')}
                  />
                </div>
              </>
            ) : (
              <div className="noOrderBackground">
                <img
                  src={NoOrderImage}
                  className="noOrderImage"
                  alt="background"
                />
                <div className="Modal">
                  <p className="modal-title">
                    You haven't purchased any gift cards
                  </p>
                  <p className="modal-text" style={{ marginTop: '1.2rem' }}>
                    Does a loved one have a special occasion coming up? Browse
                    the range for gift ideas.
                  </p>
                  <Button
                    role="Secondary"
                    title="Shop Now"
                    startIconClass="heart-icon"
                    style={{ marginTop: '2.4rem' }}
                    onClick={() => navigate('/shop')}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Body>
    </div>
  );
};

export default Orders;
