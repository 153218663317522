import './layoutContent.scss';
import Stars from '../../../assets/svg/Stars2.svg';

type props = {
  children: any;
};

const LayoutContent = ({ children }: props) => {
  return (
    <div className="layout-wrapper">
      <img className="" src={Stars} alt="stars" />
      <p className="text">{children}</p>
    </div>
  );
};
export default LayoutContent;
