import React from 'react';
import SvgBackArrow from '../Svg/BackArrow';
import { useNavigate } from 'react-router-dom';

import './styles.scss';
import Colors from '../../const/Colors';

interface OwnProps {
  arrowHeight?: number;
  arrowWidth?: number;
  arrowColor?: string;
  text?: string;
  to?: string;
  textColor?: string;
}

const ArrowBack: React.FC<OwnProps> = ({
  arrowColor,
  arrowHeight,
  arrowWidth,
  text,
  textColor = Colors.black,
  to,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="arrowBack" onClick={onClick}>
      <SvgBackArrow
        height={arrowHeight}
        width={arrowWidth}
        color={arrowColor}
      />
      {text && (
        <span className="text" style={{ color: textColor }}>
          {text}
        </span>
      )}
    </div>
  );
};

export default ArrowBack;
