import { createBreakpoint, createMap } from 'styled-components-breakpoint';
import Colors from './Colors';

export const breakpoints = {
  mobile: 576,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
};

export const breakpoint = createBreakpoint(breakpoints);
export const map = createMap(breakpoints);

export const theme = {
  colours: Colors,
  breakpoints: breakpoints,
};
