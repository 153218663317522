import './fixedColumn.scss';

type props = {
  children: any;
  hideFixedColumnMobile?: boolean;
  inverse?: boolean;
  noStick?: boolean;
};

const FixedColumn = ({ children, hideFixedColumnMobile, inverse, noStick }: props) => {
  return (
    <div
      className={`fixed-col-wrapper ${
        hideFixedColumnMobile && 'hide-mobile-content'
      } ${inverse && 'inverse'} ${noStick && 'no-stick'}`}>
      {children}
    </div>
  );
};

export default FixedColumn;
