import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { UserGiftCardActivationDetailsVM } from '../generated';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface ActivationData {
  id: string;
  token: string;
  activationDetails: UserGiftCardActivationDetailsVM;
  pin?: string;
}

type ActivationContextData = {
  activationData: ActivationData;
  setActivationData: (activationData: ActivationData) => void;
};

const ActivationContext = createContext<ActivationContextData>(
  {} as ActivationContextData
);

const ActivationProvider = ({ children }: any) => {
  const [localActivationData, setLocalActivationData] =
    useLocalStorage<ActivationData>('localActivation', {} as ActivationData);

  const [activationData, setActivationDataLocal] = useState<ActivationData>(
    localActivationData || ({} as ActivationData)
  );

  const setActivationData = useCallback(
    (newActivationData: ActivationData) => {
      setLocalActivationData(newActivationData);
      setActivationDataLocal(newActivationData);
    },
    [setLocalActivationData]
  );

  useEffect(() => {
    setActivationDataLocal(localActivationData || activationData);
  }, [localActivationData, activationData]);

  return (
    <ActivationContext.Provider
      value={{
        activationData,
        setActivationData,
      }}>
      {children}
    </ActivationContext.Provider>
  );
};

function useActivation(): ActivationContextData {
  const context = useContext(ActivationContext);

  if (!context) {
    throw new Error('useActivation must be used within an ActivationProvider');
  }

  return context;
}

export { ActivationContext, ActivationProvider, useActivation };
