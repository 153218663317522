import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DeleteAccountSettings from '../AccountSettings';
import Confirm from '../Confirm';
import Legal from '../Legal';
import QuestionAnswer from '../QuestionAnswer';

const DeleteAccountRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DeleteAccountSettings />} />
      <Route path="/question-answer" element={<QuestionAnswer />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/confirm" element={<Confirm />} />
    </Routes>
  );
};

export default DeleteAccountRoutes;
