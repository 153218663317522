import React, { useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import Colors from '../../../../../const/Colors';
import styled from 'styled-components';

const CalenderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.8rem;
`;

const Days = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  max-width: 100%;
`;

const CalenderNumberContainer = styled.div`
  flex: 1;
  height: 4rem;
  text-align: center;
  max-width: 10%;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  line-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  cursor: pointer;
`;

interface props {
  selectedYear: number;
  selectedMonth: number;
  selectedDate: number;
  onSelectDate: any;
  allowPast?: boolean;
}
const weekDayShort = moment.weekdaysShort();

const isToday = (selectedYear: number, selectedMonth: number, date: number) => {
  return (
    new Date().toDateString() ===
    new Date(selectedYear, selectedMonth, date).toDateString()
  );
};

const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const Calendar = ({
  selectedYear,
  selectedMonth,
  selectedDate,
  onSelectDate,
  allowPast = false,
}: props) => {
  const isDateSelectable = useCallback(
    (selectedDate: number, selectedMonth: number, selectedYear: number) => {
      if (
        allowPast ||
        moment().unix() <
          moment(new Date(selectedYear, selectedMonth, selectedDate)).unix() ||
        isToday(selectedYear, selectedMonth, selectedDate)
      ) {
        return true;
      }
      return false;
    },
    [allowPast]
  );

  const getStyle = (date: number) => {
    const canSelect = isDateSelectable(date, selectedMonth, selectedYear);

    if (date === selectedDate && canSelect) {
      return {
        containerStyle: {
          backgroundColor: Colors.black,
        },
        textStyle: {
          color: Colors.white,
        },
      };
    }
    if (!canSelect) {
      return {
        containerStyle: {
          backgroundColor: Colors.white,
        },
        textStyle: {
          color: Colors.grey1,
        },
      };
    }

    if (isToday(selectedYear, selectedMonth, date)) {
      return {
        containerStyle: {
          backgroundColor: Colors.lightGrey,
        },
        textStyle: {
          color: Colors.electricBlue,
          fontWeight: 'bold',
        },
      };
    }

    return {
      containerStyle: {
        backgroundColor: Colors.white,
      },
      textStyle: {
        color: Colors.black,
      },
    };
  };

  const matrix = useMemo(() => {
    let matrix: Array<Array<number>> = [];
    const firstDay = new Date(selectedYear, selectedMonth, 1).getDay();

    const maxDays = nDays[selectedMonth];
    if (selectedMonth === 1) {
      // February
      if (
        (selectedYear % 4 === 0 && selectedYear % 100 !== 0) ||
        selectedYear % 400 === 0
      ) {
        // eslint-disable-next-line
        selectedMonth += 1;
      }
    }
    let counter = 1;
    for (let row = 1; row < 7; row++) {
      matrix[row] = [];
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = -1;
        if (row === 1 && col >= firstDay) {
          // Fill in rows only after the first day of the month
          matrix[row][col] = counter++;
        } else if (row > 1 && counter <= maxDays) {
          // Fill in rows only if the counter's not greater than
          // the number of days in the month
          matrix[row][col] = counter++;
        }
      }
    }

    // remove rows that have no dates on them
    const filteredMatrix = matrix.filter((m) => m && new Set(m).size !== 1);
    return filteredMatrix;
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (!isDateSelectable(selectedDate, selectedMonth, selectedYear)) {
      onSelectDate(new Date().getDate());
    }
    // eslint-disable-next-line
  }, [selectedDate, selectedYear, selectedMonth, isDateSelectable]);

  return (
    <div style={{ paddingBottom: 15 }}>
      <CalenderWrapper>
        {weekDayShort.map((item, index) => {
          return <Days key={index}>{item}</Days>;
        })}
      </CalenderWrapper>
      {matrix.map((row, rowIndex) => {
        const rowItems = row.map((date: number, colIndex: number) => {
          const { containerStyle, textStyle } = getStyle(date);
          return (
            <CalenderNumberContainer
              key={colIndex}
              onClick={() => {
                if (isDateSelectable(date, selectedMonth, selectedYear)) {
                  onSelectDate(date);
                }
              }}
              style={containerStyle}>
              <span style={textStyle}>{date !== -1 ? date : ''}</span>
            </CalenderNumberContainer>
          );
        });
        return <CalenderWrapper key={rowIndex}>{rowItems}</CalenderWrapper>;
      })}
    </div>
  );
};

export default Calendar;
