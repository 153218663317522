import moment from 'moment';

export const Months: { label: string; value: any }[] = [
  {
    label: 'January',
    value: 0,
  },
  {
    label: 'Febuary',
    value: 1,
  },
  {
    label: 'March',
    value: 2,
  },
  {
    label: 'April',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'June',
    value: 5,
  },
  {
    label: 'July',
    value: 6,
  },
  {
    label: 'August',
    value: 7,
  },
  {
    label: 'September',
    value: 8,
  },
  {
    label: 'October',
    value: 9,
  },
  {
    label: 'November',
    value: 10,
  },
  {
    label: 'December',
    value: 11,
  },
];

export const Years: { label: string; value: any }[] = [
  {
    label: moment().year().toString(),
    value: moment().year(),
  },
  {
    label: (moment().year() + 1).toString(),
    value: moment().year() + 1,
  },
];
