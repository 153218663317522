import { Button } from "../../components/Button";
import Plugs from '../../assets/svg/Plugs.svg';
import { Hero, HeroTitle, HeroSubtitle } from "../../components/Hero";
import './500.scss';
import { useNavigate } from "react-router";
const InternalError = () => {

  const navigate = useNavigate();

  return (
    <div className="content" style={{background: "#00CCA8"}}>
      <Hero>
        <HeroTitle sideBySide={true}>
          OOPS
          <div style={{textAlign: 'right'}}><img className="icon" src={Plugs} alt="plugs" /></div>
        </HeroTitle>
        <HeroSubtitle>
          500 INTERNAL SERVER ERROR
        </HeroSubtitle>
      </Hero>
      <div className="notfound-container">
        <p className="notfound-text">Something went wrong on our end and we are working as quickly as possible to resolve the issue.</p> 
        <p className="notfound-text">In the meantime, you can wait a few minutes and refresh the page again, try a different browser or let us help you find your way back.</p>
        <div className="notfound-button-container">
          <Button buttonClass="notfound-home-button" role="Primary" title="BACK TO HOME" startIconClass="orange-heart-icon" onClick={()=>navigate('/')} />
          <Button buttonClass="notfound-previous-button" role="Primary" color="white" title="BACK TO PREVIOUS PAGE" onClick={()=>navigate(-1)}/>
        </div>
      </div>
    </div >
  )
}

export default InternalError;