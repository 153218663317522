import { Moment } from 'moment';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import Colors from '../../../../const/Colors';
import { usePurchase } from '../../../../context/PurchaseProvider';
import {
  SpecialTimezoneVM,
  UserGiftCardOrderStatus,
  UserGiftCardSendingMethod,
} from '../../../../generated';
import { getTimeZoneDisplay } from '../../../../helpers/cards';
import { formatPhoneNumber } from '../../../../helpers/utils';
import {
  HeaderText,
  Text,
  OrderText,
  CardDetailsContainer,
  GiftDetailsWrapper,
  CardDetailTitle,
  StoreCard,
  CardDetail,
  CardDetailText,
  SingleSection,
  SingleValue,
  ButtonWrapper,
  GiftCardDetails,
  TagWrapper,
  ButtomSectionWrapper,
  BrandCard,
} from './styles';
import PromoRed from '../../../../assets/svg/purchase/PromoRed.svg';
import { Tag } from '../../../../components/Tag';
import { StaticAffilationText } from '../styles';
import { AFFILIATION_TEXT } from '..';

interface OwnProps {
  order: string;
  cardFrontImage: string;
  cardName: string;
  paymentDetails: string;
  momentDate: Moment;
  selectedTimezone: SpecialTimezoneVM | undefined;
  promoCode?: string;
  discountAmount?: number;
  orderStatus?: UserGiftCardOrderStatus;
  displayAffiliationText?: boolean;
  isBranded?: boolean;
}

const OrderSummary: React.FC<OwnProps> = ({
  momentDate,
  selectedTimezone,
  cardFrontImage,
  cardName,
  order,
  paymentDetails,
  promoCode,
  discountAmount,
  orderStatus,
  displayAffiliationText = false,
  isBranded = false,
}) => {
  const { newPurchaseDetails, setNewPurchaseDetails } = usePurchase();
  const navigate = useNavigate();

  const onBuyAnother = () => {
    setNewPurchaseDetails({});
    navigate('/shop');
  };

  const onManageCards = () => {
    setNewPurchaseDetails({});
    navigate('/my-orders');
  };

  const receiverDetail = useMemo(
    () =>
      newPurchaseDetails.sendingMethod === UserGiftCardSendingMethod.Sms
        ? formatPhoneNumber(newPurchaseDetails.receiverPhoneNumber || '')
        : newPurchaseDetails.receiverEmail,
    [
      newPurchaseDetails.receiverEmail,
      newPurchaseDetails.receiverPhoneNumber,
      newPurchaseDetails.sendingMethod,
    ]
  );

  const message = useMemo(
    () =>
      orderStatus === UserGiftCardOrderStatus.Review
        ? `Your Special Card will be sent to ${receiverDetail} once payment is completed.`
        : `Thanks for making someone feel special. Your Special card has been sent to ${receiverDetail}.`,
    [receiverDetail, orderStatus]
  );

  const nameToDisplay = useMemo(() => {
    if (isBranded) {
      const baseText = 'Special Card for';
      const brandName = `${baseText} ${cardName}`;
      return displayAffiliationText ? `${brandName}*` : brandName;
    }
    return cardName;
  }, [isBranded, cardName, displayAffiliationText]);

  return (
    <div>
      <HeaderText>
        Thanks {newPurchaseDetails.from}! Your gift is on its way to{' '}
        {newPurchaseDetails.receiverFirstName}{' '}
        {newPurchaseDetails.receiverLastName}.
      </HeaderText>
      <OrderText>ORDER #{order}</OrderText>
      {orderStatus === UserGiftCardOrderStatus.Review && (
        <TagWrapper>
          <Tag title="PENDING" role="Orange" />
        </TagWrapper>
      )}
      <Text>{message}</Text>
      <CardDetailsContainer>
        <GiftDetailsWrapper>
          <GiftCardDetails>
            <CardDetailTitle>CARD DETAILS:</CardDetailTitle>
            <CardDetailText>{nameToDisplay}</CardDetailText>
          </GiftCardDetails>
          {isBranded ? (
            <BrandCard>
              <img src={cardFrontImage} alt="brand-card" />
            </BrandCard>
          ) : (
            <StoreCard>
              <img src={cardFrontImage} alt="store-card" />
            </StoreCard>
          )}
        </GiftDetailsWrapper>

        <CardDetail>
          <CardDetailTitle>RECIPIENT DETAILS:</CardDetailTitle>
          <CardDetailText>
            {newPurchaseDetails.receiverFirstName}{' '}
            {newPurchaseDetails.receiverLastName}
          </CardDetailText>
        </CardDetail>
        <CardDetail>
          <CardDetailTitle>DELIVERY DETAILS:</CardDetailTitle>
          <div>
            {newPurchaseDetails.sendNow ? (
              <CardDetailText>
                Sent immediately to{' '}
                {newPurchaseDetails.sendingMethod ===
                UserGiftCardSendingMethod.Sms
                  ? formatPhoneNumber(
                      newPurchaseDetails.receiverPhoneNumber || ''
                    )
                  : newPurchaseDetails.receiverEmail}
              </CardDetailText>
            ) : (
              <>
                <CardDetailText>
                  Sending at {momentDate.format('DD-MM-YY | h:mm a')}
                </CardDetailText>
                <CardDetailText>
                  {getTimeZoneDisplay(selectedTimezone)}
                </CardDetailText>
              </>
            )}
          </div>
        </CardDetail>
        <CardDetail>
          <CardDetailTitle>PAYMENT DETAILS:</CardDetailTitle>
          <CardDetailText>{paymentDetails}</CardDetailText>
        </CardDetail>
        <CardDetail
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}>
          <SingleSection>SUB TOTAL:</SingleSection>
          <SingleValue>${newPurchaseDetails.value?.toFixed(2)}</SingleValue>
        </CardDetail>
        {promoCode && (
          <CardDetail
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}>
            <GiftCardDetails>
              <CardDetailTitle>
                <img src={PromoRed} alt="Promotion" /> DISCOUNT:
              </CardDetailTitle>
              <CardDetailText className="promo-code">
                {promoCode}
              </CardDetailText>
            </GiftCardDetails>
            <SingleValue className="red-variant">
              -${discountAmount?.toFixed(2)}
            </SingleValue>
          </CardDetail>
        )}

        <CardDetail
          style={{
            borderBottomWidth: 0,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}>
          <SingleSection>TOTAL PAID:</SingleSection>
          <SingleValue>
            $
            {((newPurchaseDetails.value ?? 0) - (discountAmount ?? 0)).toFixed(
              2
            )}
          </SingleValue>
        </CardDetail>
      </CardDetailsContainer>

      <span
        style={{
          textAlign: 'center',
          fontSize: '1.7rem',
          lineHeight: '2.7rem',
          display: 'block',
        }}>
        Need help? Read the{' '}
        <a
          href="https://special.freshdesk.com/support/home"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: Colors.black,
            fontSize: '1.7rem',
            lineHeight: '2.7rem',
          }}>
          FAQs
        </a>{' '}
        or get in touch with{' '}
        <Link
          to="/contact-us"
          target="_blank"
          style={{
            color: Colors.black,
            fontSize: '1.7rem',
            lineHeight: '2.7rem',
          }}>
          Special customer service
        </Link>
      </span>
      <ButtomSectionWrapper>
        <ButtonWrapper>
          <Button
            role="Secondary"
            title="BUY ANOTHER GIFT CARD"
            onClick={onBuyAnother}
            startIconClass="present-icon"
          />
          <Button
            role="Secondary"
            title="MANAGE YOUR ORDERS"
            onClick={onManageCards}
            color="white"
          />
        </ButtonWrapper>
        {displayAffiliationText && (
          <StaticAffilationText>{AFFILIATION_TEXT}</StaticAffilationText>
        )}
      </ButtomSectionWrapper>
    </div>
  );
};

export default OrderSummary;
