import { Hub } from 'aws-amplify';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from '@aws-amplify/auth';
import {
  ConfigureAuth,
  GetUserName,
  IsPhoneVerified,
  Logout,
  ReVerifyPhone,
  SignIn,
  VerifyPhone,
  VerifyPhoneSubmit,
  VerifyEmail,
  VerifyEmailSubmit,
  FederatedLogin,
  GetJWTToken,
  ForgotPassword,
  ForgotPasswordSubmit,
  GetCurrentAuthenticatedUser,
  ChangePassword,
} from '../services/auth/AuthServices';
import { NewUserDetails } from '../services/auth/models/new-user-details';
import { AuthEvent } from '../services/auth/models/auth-event';
import {
  EMAIL_SENDING_GAP_IN_SECONDS,
  IS_SESSION_TIME_OUT_KEY,
  LAST_EMAIL_SENT_KEY,
  LAST_SMS_SENT_KEY,
  SMS_SENDING_GAP_IN_SECONDS,
  SESSION_HAS_TIMED_OUT,
} from '../const/auth';
import {
  GetItem,
  HasItem,
  RemoveItem,
  SaveItem,
} from '../services/shared/StorageService';
import { UserDetailsVM, UserStatus } from '../generated';
import { NavigateFunction, useNavigate } from 'react-router';
import { useApi } from '../api/ApiProvider';
import { useAlert } from 'react-alert';
import { RudderStack } from '../services/shared/RudderStack';
import { apiObject } from 'rudder-sdk-js';
import { LocalStorageFolderName } from '../hooks/useLocalStorage';
import { useIdleTimer } from 'react-idle-timer';
import EnvConfig from '../config/EnvConfig';

type AuthContextData = {
  isSocialAccount: boolean;
  socialProvider: string;
  isLoggedIn: boolean;
  lastSMSSent: Date | undefined;
  lastEmailSent: Date | undefined;
  signIn(email: string, password: string): Promise<boolean | undefined>;
  signOut(): void;
  forgotPassword(email: string): Promise<any>;
  forgotPasswordSubmit(
    email: string,
    code: string,
    password: string
  ): Promise<any>;
  getJwtToken(): Promise<string>;
  getEmail(): Promise<string>;
  verifyPhone(phone: string): Promise<boolean>;
  reVerifyPhone(): void;
  verifyPhoneSubmit(code: string): void;
  verifyEmail(): void;
  verifyEmailSubmit(code: string): void;
  newUserDetails: NewUserDetails;
  setNewUserDetails: (newUserDetails: NewUserDetails) => void;
  federatedSignin: (provider: CognitoHostedUIIdentityProvider) => void;
  userDetailsVM?: UserDetailsVM;
  setUserDetailsVM: React.Dispatch<
    React.SetStateAction<UserDetailsVM | undefined>
  >;
  navState: number;
  setNavState(value: number): void;
  createAccountStepperClicks: { (navigate: NavigateFunction): void }[];
  changePassword(oldPassword: string, newPassword: string): Promise<any>;
  setRedirectURL: React.Dispatch<React.SetStateAction<string>>;
  postSignIn: (cognitoUserToUse?: CognitoUser | undefined) => Promise<void>;
  isLoadingLoggedInInfo: boolean;
};

export interface ISignInReidrectPage {
  parentScreen: string;
  params?: any;
  childScreen?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const CreateAccountRoutes = [
  '/auth/createAccountName',
  '/auth/createAccountEmail',
  '/auth/createAccountPassword',
  '/auth/verifyPhone',
  '/auth/verifyPhoneSubmit',
];

const idleTimeout = 15 * 60 * 1000; // 15 mins

const AuthProvider: React.FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [lastSMSSent, setLastSMSSentInternal] = useState<Date>();
  const [lastEmailSent, setLastEmailSentInternal] = useState<Date>();
  const [isSocialAccount, setIsSocialAccount] = useState(false);
  const [socialProvider, setSocialProvider] = useState('');
  const [userDetailsVM, setUserDetailsVM] = useState<
    UserDetailsVM | undefined
  >();
  const [redirectUrl, setRedirectURL] = useState<string>('');
  const [navState, setNavState] = useState<number>(0);
  const [isLoadingLoggedInInfo, setIsLoadingLoggedInInfo] =
    useState<boolean>(true);
  const { userApi } = useApi();
  const navigate = useNavigate();
  const alert = useAlert();
  const createAccountStepperClicks = [
    (navigate: NavigateFunction) => {
      if (navState >= 1) navigate(CreateAccountRoutes[0]);
    },
    (navigate: NavigateFunction) => {
      if (navState >= 2) navigate(CreateAccountRoutes[1]);
    },
    (navigate: NavigateFunction) => {
      console.log(navState);
      if (navState >= 3) navigate(CreateAccountRoutes[2]);
    },
    (navigate: NavigateFunction) => {
      if (navState >= 4) navigate(CreateAccountRoutes[3]);
    },
    (navigate: NavigateFunction) => {
      if (navState >= 5) navigate(CreateAccountRoutes[4]);
    },
  ];

  const { pause, reset, start, getRemainingTime } = useIdleTimer({
    timeout: idleTimeout,
    crossTab: true,
    syncTimers: 200,
  });

  useEffect(() => {
    if (!isLoggedIn || isLoadingLoggedInInfo) {
      pause();
      return;
    }

    reset();
    start();

    const idleInterval = setInterval(async () => {
      const remainingTime = getRemainingTime();
      if (EnvConfig.env !== 'prod') {
        console.log(remainingTime);
      }
      if (remainingTime <= 0 && isLoggedIn) {
        SaveItem(IS_SESSION_TIME_OUT_KEY, true);
        signOut();
      }
    }, 1000);

    return () => {
      idleInterval && clearInterval(idleInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isLoadingLoggedInInfo]);

  const getJwtToken = async (): Promise<string> => {
    return await GetJWTToken();
  };

  const setLastSMSSent = (value: Date) => {
    setLastSMSSentInternal(value);
    SaveItem(LAST_SMS_SENT_KEY, value);
  };

  const setLastEmailSent = (value: Date) => {
    setLastEmailSentInternal(value);
    SaveItem(LAST_EMAIL_SENT_KEY, value);
  };

  const loadDataFromStorage = () => {
    if (HasItem(LAST_SMS_SENT_KEY)) {
      const value = GetItem(LAST_SMS_SENT_KEY);
      const valueFromLocalStorage =
        value !== null ? new Date(value.toString()) : null;
      if (
        valueFromLocalStorage instanceof Date &&
        !isNaN(valueFromLocalStorage.getTime())
      ) {
        setLastSMSSentInternal(valueFromLocalStorage);
      }
    }
    if (HasItem(LAST_EMAIL_SENT_KEY)) {
      const value = GetItem(LAST_EMAIL_SENT_KEY);
      const valueFromLocalStorage =
        value !== null ? new Date(value.toString()) : null;
      if (
        valueFromLocalStorage instanceof Date &&
        !isNaN(valueFromLocalStorage.getTime())
      ) {
        setLastEmailSentInternal(valueFromLocalStorage);
      }
    }
  };

  const [newUserDetails, setNewUserDetails] = useState<NewUserDetails>({
    firstName: '',
    lastName: '',
    email: '',
    optInMarketing: false,
    mobile: '',
  });

  const postSignIn = useCallback(
    async (cognitoUserToUse: CognitoUser | undefined = undefined) => {
      let cognitoUser = null;
      try {
        cognitoUser = cognitoUserToUse ?? (await GetCurrentAuthenticatedUser());
      } catch (err: any) {}

      if (!cognitoUser) {
        return;
      }
      cognitoUser.getUserData(async (err: any, result: any) => {
        const identities = result?.UserAttributes.find(
          (el: any) => el.Name === 'identities'
        )?.Value;
        setIsSocialAccount(!!identities);
        let provider = '';
        if (!!identities) {
          provider = JSON.parse(identities)[0]?.providerName;
        }
        setSocialProvider(provider);

        const userDataFromCognito = {
          id: result?.UserAttributes.find((el: any) => el.Name === 'sub')
            ?.Value,
          email: result?.UserAttributes.find((el: any) => el.Name === 'email')
            ?.Value,
          firstName: result?.UserAttributes.find(
            (el: any) => el.Name === 'given_name'
          )?.Value,
          lastName: result?.UserAttributes.find(
            (el: any) => el.Name === 'family_name'
          )?.Value,
          phoneNumber:
            result?.UserAttributes.find(
              (el: any) => el.Name === 'phone_number_verified'
            )?.Value === 'true'
              ? result?.UserAttributes.find(
                  (el: any) => el.Name === 'phone_number'
                )?.Value
              : undefined,
        };

        const userDetailsVM: UserDetailsVM = (
          await userApi.postSignIn(undefined, userDataFromCognito)
        ).data;
        setUserDetailsVM(userDetailsVM);

        const isPhoneVerified = !!userDetailsVM.mobileNumber;
        if (isPhoneVerified) {
          const isUserActive = userDetailsVM.status === UserStatus.Active;
          if (!isUserActive) {
            alert.error('User is not active');
            setIsLoggedIn(false);
            return;
          }
          setIsLoggedIn(true);
          try {
            window.smartlook &&
              window.smartlook('identify', userDetailsVM.id ?? -1);
          } catch (e) {}
          RudderStack.identify(userDetailsVM.id, {
            email: userDetailsVM.email,
            firstName: userDetailsVM.firstName,
            lastName: userDetailsVM.lastName,
            phone: userDetailsVM.mobileNumber,
            marketing_consent_provided: userDetailsVM.optInMarketing,
          } as apiObject);
          const url = redirectUrl || GetItem('redirectUrl');
          if (url) {
            navigate(url);
            setRedirectURL('');
          } else {
            navigate('/my-cards');
          }
        } else {
          navigate(CreateAccountRoutes[3]);
        }
        setIsLoadingLoggedInInfo(false);
      });
    },
    [navigate, userApi, redirectUrl, alert]
  );

  useEffect(() => {
    ConfigureAuth();
    if (
      HasItem(IS_SESSION_TIME_OUT_KEY) &&
      GetItem(IS_SESSION_TIME_OUT_KEY) === 'true'
    ) {
      RemoveItem(IS_SESSION_TIME_OUT_KEY);
      navigate('auth/sessionTimeout', { state: SESSION_HAS_TIMED_OUT });
    }
    loadDataFromStorage();

    const authEvent = ({ payload: { event, data } }: any) => {
      switch (event) {
        case AuthEvent.SignIn:
          (async () => {
            await postSignIn(data);
          })();
          break;
        case AuthEvent.SignOut:
          setIsLoggedIn(false);
          window.smartlook && window.smartlook('anonymize');
          (async () => {
            await RudderStack.reset();
          })();
          break;
        case AuthEvent.SignInFailure:
        case AuthEvent.CognitoHostedUIFailure:
          console.log('Sign in failure', data);
          break;
      }
    };

    Hub.listen('auth', authEvent);

    return () => {
      Hub.remove('auth', authEvent);
    };
  }, [postSignIn, navigate]);

  useEffect(() => {
    (async () => {
      let cognitoUser = null;
      try {
        cognitoUser = await GetCurrentAuthenticatedUser();
      } catch {}
      if (!cognitoUser) {
        setIsLoadingLoggedInInfo(false);
        return;
      }
      cognitoUser.getUserData(async (err: any, result: any) => {
        const identities = result?.UserAttributes.find(
          (el: any) => el.Name === 'identities'
        )?.Value;
        setIsSocialAccount(!!identities);
        const userDataFromCognito = {
          id: result?.UserAttributes.find((el: any) => el.Name === 'sub')
            ?.Value,
          email: result?.UserAttributes.find((el: any) => el.Name === 'email')
            ?.Value,
          firstName: result?.UserAttributes.find(
            (el: any) => el.Name === 'given_name'
          )?.Value,
          lastName: result?.UserAttributes.find(
            (el: any) => el.Name === 'family_name'
          )?.Value,
          phoneNumber:
            result?.UserAttributes.find(
              (el: any) => el.Name === 'phone_number_verified'
            )?.Value === 'true'
              ? result?.UserAttributes.find(
                  (el: any) => el.Name === 'phone_number'
                )?.Value
              : undefined,
        };
        const userDetailsVM: UserDetailsVM = (
          await userApi.postSignIn(undefined, userDataFromCognito)
        ).data;

        setUserDetailsVM(userDetailsVM);
        const isPhoneVerified = !!userDetailsVM.mobileNumber;
        if (isPhoneVerified) {
          const isUserActive = userDetailsVM.status === UserStatus.Active;
          if (!isUserActive) {
            alert.error('User is not active');
            setIsLoggedIn(false);
            return;
          }
          setIsLoggedIn(true);
        }
        setIsLoadingLoggedInInfo(false);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userApi]);

  const signIn = async (email: string, password: string) => {
    const user = await SignIn(email, password);
    return await IsPhoneVerified(user);
  };

  const signOut = async () => {
    localStorage.removeItem(LocalStorageFolderName);
    await Logout();
  };

  const forgotPassword = async (email: string): Promise<any> => {
    const currentTime = new Date();
    // if we have last SMS sent date
    if (lastSMSSent instanceof Date) {
      // the gap is how long has it been since the last SMS sent
      const gap = (currentTime.getTime() - lastSMSSent.getTime()) / 1000;
      // if it's less than 60 seconds, we don't do anything
      if (gap < SMS_SENDING_GAP_IN_SECONDS) {
        return false;
      }
    }
    setLastSMSSent(currentTime);
    return await ForgotPassword(email);
  };

  const forgotPasswordSubmit = async (
    email: string,
    code: string,
    password: string
  ): Promise<any> => {
    return await ForgotPasswordSubmit(email, code, password);
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    return await ChangePassword(oldPassword, newPassword);
  };

  const getEmail = async (): Promise<string> => {
    return await GetUserName();
  };

  const verifyPhone = async (phone: string): Promise<boolean> => {
    const currentTime = new Date();
    // if we have last SMS sent date
    if (lastSMSSent instanceof Date) {
      // the gap is how long has it been since the last SMS sent
      const gap = (currentTime.getTime() - lastSMSSent.getTime()) / 1000;
      // if it's less than 60 seconds, we don't do anything
      if (gap < SMS_SENDING_GAP_IN_SECONDS) {
        return false;
      }
    }
    setLastSMSSent(currentTime);
    return VerifyPhone(phone);
  };

  const reVerifyPhone = async () => {
    const currentTime = new Date();
    // if we have last SMS sent date
    if (lastSMSSent instanceof Date) {
      // the gap is how long has it been since the last SMS sent
      const gap = (currentTime.getTime() - lastSMSSent.getTime()) / 1000;
      // if it's less than 60 seconds, we don't do anything
      if (gap < SMS_SENDING_GAP_IN_SECONDS) {
        return;
      }
    }
    setLastSMSSent(currentTime);
    await ReVerifyPhone();
  };

  const verifyEmail = async () => {
    const currentTime = new Date();
    // if we have last Email sent date
    if (lastEmailSent instanceof Date) {
      // the gap is how long has it been since the last Email sent
      const gap = (currentTime.getTime() - lastEmailSent.getTime()) / 1000;
      // if it's less than 60 seconds, we don't do anything
      if (gap < EMAIL_SENDING_GAP_IN_SECONDS) {
        return;
      }
    }
    setLastEmailSent(currentTime);

    await VerifyEmail();
  };

  const verifyPhoneSubmit = async (code: string) => {
    await VerifyPhoneSubmit(code);
  };

  const verifyEmailSubmit = async (code: string) => {
    await VerifyEmailSubmit(code);
  };

  const federatedSignin = async (provider: CognitoHostedUIIdentityProvider) => {
    SaveItem('redirectUrl', redirectUrl);
    await FederatedLogin(provider);
  };

  return (
    <AuthContext.Provider
      value={{
        isSocialAccount,
        socialProvider,
        isLoggedIn: isLoggedIn,
        lastSMSSent,
        lastEmailSent,
        signIn,
        signOut,
        forgotPassword,
        forgotPasswordSubmit,
        verifyPhone,
        reVerifyPhone,
        verifyPhoneSubmit,
        verifyEmail,
        verifyEmailSubmit,
        getEmail,
        newUserDetails: newUserDetails,
        setNewUserDetails,
        federatedSignin,
        getJwtToken: getJwtToken,
        userDetailsVM,
        setUserDetailsVM,
        navState,
        setNavState,
        createAccountStepperClicks,
        changePassword,
        setRedirectURL,
        postSignIn,
        isLoadingLoggedInInfo,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
