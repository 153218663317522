import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import { COMMON_ERR_MSG, PASSWORD_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';

type props = {
  setOpen(value: boolean): void;
};

const PasswordModal = ({ setOpen }: props) => {
  const { changePassword, userDetailsVM } = useAuth();
  const { emailApi } = useApi();
  const alert = useAlert();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    setIsLoading(true);
    const { oldPassword, newPassword } = data;
    try {
      await changePassword(oldPassword, newPassword);
      await emailApi.sendUpdatePasswordEmail(userDetailsVM?.email ?? '');
      alert.success('Password Updated');
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      alert.error(COMMON_ERR_MSG);
    }
  };

  return (
    <>
      <div className="Modal">
        <p className="ModalTitle">Create new Password</p>
        <p className="ModalSubTitle">Please enter your current password.</p>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="Current Password"
              type={'password'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!errors.oldPassword}
            />
          )}
          name="oldPassword"
        />
        {errors.oldPassword && (
          <div>
            <ErrorText text="Password is required" />
          </div>
        )}
        <p className="ModalSubTitle">Please enter your new password.</p>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: PASSWORD_REGEX,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="New Password"
              type={'password'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!errors.newPassword}
            />
          )}
          name="newPassword"
        />
        {errors.newPassword && (
          <div>
            <ErrorText text="Invalid Password" />
          </div>
        )}
        <Button
          title="Next"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        />
        <div className="auth-cancel" onClick={() => setOpen(false)}>
          CANCEL
        </div>
      </div>
    </>
  );
};

export default PasswordModal;
