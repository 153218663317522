/* tslint:disable */
/* eslint-disable */
/**
 * Special.User.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminSpecialUserDetailsVM
 */
export interface AdminSpecialUserDetailsVM {
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'socialProviderName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'userDeletePendingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'userDeletionReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'userDeletionMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'id'?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof AdminSpecialUserDetailsVM
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSpecialUserDetailsVM
     */
    'mfaeNabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSpecialUserDetailsVM
     */
    'optInMarketing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'dateJoined'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSpecialUserDetailsVM
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof AdminSpecialUserDetailsVM
     */
    'userRole'?: UserRole;
}
/**
 * 
 * @export
 * @interface ApplePayProvisionResponse
 */
export interface ApplePayProvisionResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplePayProvisionResponse
     */
    'activationData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplePayProvisionResponse
     */
    'ephemeralPublicKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplePayProvisionResponse
     */
    'encryptedPassData'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplePayProvisionResponseIResponse
 */
export interface ApplePayProvisionResponseIResponse {
    /**
     * 
     * @type {ApplePayProvisionResponse}
     * @memberof ApplePayProvisionResponseIResponse
     */
    'result'?: ApplePayProvisionResponse;
    /**
     * 
     * @type {boolean}
     * @memberof ApplePayProvisionResponseIResponse
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplePayProvisionResponseIResponse
     */
    'hasFailed'?: boolean;
    /**
     * 
     * @type {ResponseStatus}
     * @memberof ApplePayProvisionResponseIResponse
     */
    'status'?: ResponseStatus;
}
/**
 * 
 * @export
 * @interface AvailableBalance
 */
export interface AvailableBalance {
    /**
     * 
     * @type {number}
     * @memberof AvailableBalance
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableBalance
     */
    'currencyCode'?: string | null;
}
/**
 * 
 * @export
 * @interface BooleanIResponse
 */
export interface BooleanIResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanIResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanIResponse
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanIResponse
     */
    'hasFailed'?: boolean;
    /**
     * 
     * @type {ResponseStatus}
     * @memberof BooleanIResponse
     */
    'status'?: ResponseStatus;
}
/**
 * 
 * @export
 * @interface BrandMerchantVM
 */
export interface BrandMerchantVM {
    /**
     * 
     * @type {number}
     * @memberof BrandMerchantVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'squareLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'squareCardLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'squareCardColourLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'primaryColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandMerchantVM
     */
    'affiliationTextColour'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BrandMerchantVM
     */
    'hasOnlineStore'?: boolean;
}
/**
 * 
 * @export
 * @interface CardBalance
 */
export interface CardBalance {
    /**
     * 
     * @type {AvailableBalance}
     * @memberof CardBalance
     */
    'availableBalance'?: AvailableBalance;
}
/**
 * 
 * @export
 * @interface CardDetails
 */
export interface CardDetails {
    /**
     * 
     * @type {number}
     * @memberof CardDetails
     */
    'creationTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardDetails
     */
    'modifiedTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'cardNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'panFirst6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'panLast4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'state'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CardDetails
     */
    'sequenceNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'cardProfileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CardDetails
     */
    'pinFailCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CardDetails
     */
    'reissue'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'expiry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'embossedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'pan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'cvv2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'encryptedId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'cardId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'privateCallbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    'privateTokenId'?: string | null;
}
/**
 * 
 * @export
 * @interface CardStatusInfo
 */
export interface CardStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof CardStatusInfo
     */
    'reason'?: string | null;
    /**
     * 
     * @type {UserGiftCardStatus}
     * @memberof CardStatusInfo
     */
    'status'?: UserGiftCardStatus;
}
/**
 * 
 * @export
 * @interface CardTransactions
 */
export interface CardTransactions {
    /**
     * 
     * @type {number}
     * @memberof CardTransactions
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardTransactions
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardTransactions
     */
    'endIndex'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CardTransactions
     */
    'lastPage'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CardTransactions
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<Datum>}
     * @memberof CardTransactions
     */
    'data'?: Array<Datum> | null;
}
/**
 * 
 * @export
 * @interface CognitoUser
 */
export interface CognitoUser {
    /**
     * 
     * @type {string}
     * @memberof CognitoUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CognitoUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CognitoUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CognitoUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CognitoUser
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface Datum
 */
export interface Datum {
    /**
     * 
     * @type {number}
     * @memberof Datum
     */
    'creationTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof Datum
     */
    'modifiedTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof Datum
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Datum
     */
    'transactionSourceCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof Datum
     */
    'transactionTypeCode'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Datum
     */
    'reversal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Datum
     */
    'fee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Datum
     */
    'tax'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Datum
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Datum
     */
    'amountText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Datum
     */
    'memo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Datum
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Datum
     */
    'counterPortfolioName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Datum
     */
    'callerReference'?: string | null;
    /**
     * 
     * @type {Parent}
     * @memberof Datum
     */
    'parent'?: Parent;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DeletionRequestReason {
    Unspecified = 'Unspecified',
    NoLongerInterested = 'NoLongerInterested',
    TechnicalIssues = 'TechnicalIssues',
    IncorrectAccount = 'IncorrectAccount',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface EditUser
 */
export interface EditUser {
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface EncryptedPayload
 */
export interface EncryptedPayload {
    /**
     * 
     * @type {string}
     * @memberof EncryptedPayload
     */
    'encryptedData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptedPayload
     */
    'publicKeyFingerprint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptedPayload
     */
    'encryptedKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptedPayload
     */
    'oaepHashingAlgorithm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptedPayload
     */
    'iv'?: string | null;
}
/**
 * 
 * @export
 * @interface FeaturedMerchantVM
 */
export interface FeaturedMerchantVM {
    /**
     * 
     * @type {number}
     * @memberof FeaturedMerchantVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeaturedMerchantVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturedMerchantVM
     */
    'logo'?: string | null;
}
/**
 * 
 * @export
 * @interface FundingAccountInfo
 */
export interface FundingAccountInfo {
    /**
     * 
     * @type {EncryptedPayload}
     * @memberof FundingAccountInfo
     */
    'encryptedPayload'?: EncryptedPayload;
}
/**
 * 
 * @export
 * @interface GiftCardDetailsVM
 */
export interface GiftCardDetailsVM {
    /**
     * 
     * @type {GiftCardProgramVM | GiftCardProgramDetailsVM}
     * @memberof GiftCardDetailsVM
     */
    'giftCardProgram'?: GiftCardProgramVM | GiftCardProgramDetailsVM | null;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'tag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'retailersDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVM
     */
    'minimumValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVM
     */
    'maximumValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'presetValues'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'availableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'availableTo'?: string;
    /**
     * 
     * @type {GiftCardStatus}
     * @memberof GiftCardDetailsVM
     */
    'giftCardStatus'?: GiftCardStatus;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVM
     */
    'locationCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVM
     */
    'retailersCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardDetailsVM
     */
    'metaDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GiftCardDetailsVM
     */
    'isPcloPromotion'?: boolean;
}
/**
 * 
 * @export
 * @interface GiftCardDetailsVMIPagedList
 */
export interface GiftCardDetailsVMIPagedList {
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVMIPagedList
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVMIPagedList
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDetailsVMIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {Array<GiftCardDetailsVM>}
     * @memberof GiftCardDetailsVMIPagedList
     */
    'results'?: Array<GiftCardDetailsVM> | null;
}
/**
 * 
 * @export
 * @interface GiftCardGraphVM
 */
export interface GiftCardGraphVM {
    /**
     * 
     * @type {GiftCardProgramVM | GiftCardProgramDetailsVM}
     * @memberof GiftCardGraphVM
     */
    'giftCardProgram'?: GiftCardProgramVM | GiftCardProgramDetailsVM | null;
    /**
     * 
     * @type {number}
     * @memberof GiftCardGraphVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'tag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'retailersDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GiftCardGraphVM
     */
    'minimumValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardGraphVM
     */
    'maximumValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'presetValues'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'availableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'availableTo'?: string;
    /**
     * 
     * @type {GiftCardStatus}
     * @memberof GiftCardGraphVM
     */
    'giftCardStatus'?: GiftCardStatus;
    /**
     * 
     * @type {number}
     * @memberof GiftCardGraphVM
     */
    'locationCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardGraphVM
     */
    'retailersCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardGraphVM
     */
    'metaDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GiftCardGraphVM
     */
    'isPcloPromotion'?: boolean;
}
/**
 * 
 * @export
 * @interface GiftCardProgramDetailsVM
 */
export interface GiftCardProgramDetailsVM {
    /**
     * 
     * @type {Array<MerchantVM | MerchantDetailsVM>}
     * @memberof GiftCardProgramDetailsVM
     */
    'merchants'?: Array<MerchantVM | MerchantDetailsVM> | null;
    /**
     * 
     * @type {Array<GiftCardVM | GiftCardDetailsVM | GiftCardGraphVM>}
     * @memberof GiftCardProgramDetailsVM
     */
    'giftCards'?: Array<GiftCardVM | GiftCardDetailsVM | GiftCardGraphVM> | null;
    /**
     * 
     * @type {number}
     * @memberof GiftCardProgramDetailsVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardProgramDetailsVM
     */
    'referenceId'?: string | null;
    /**
     * 
     * @type {GiftCardProgramType}
     * @memberof GiftCardProgramDetailsVM
     */
    'giftCardProgramType'?: GiftCardProgramType;
    /**
     * 
     * @type {number}
     * @memberof GiftCardProgramDetailsVM
     */
    'merchantId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GiftCardProgramDetailsVM
     */
    'displayAffiliationText'?: boolean;
    /**
     * 
     * @type {BrandMerchantVM}
     * @memberof GiftCardProgramDetailsVM
     */
    'brandMerchant'?: BrandMerchantVM;
    /**
     * 
     * @type {string}
     * @memberof GiftCardProgramDetailsVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GiftCardProgramDetailsVM
     */
    'merchantIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<SpecialCategoryVM | SpecialCategoryDetailsVM>}
     * @memberof GiftCardProgramDetailsVM
     */
    'specialCategories'?: Array<SpecialCategoryVM | SpecialCategoryDetailsVM> | null;
    /**
     * 
     * @type {Array<ProgramProfileVM>}
     * @memberof GiftCardProgramDetailsVM
     */
    'programProfiles'?: Array<ProgramProfileVM> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum GiftCardProgramType {
    Category = 'Category',
    Brand = 'Brand'
}

/**
 * 
 * @export
 * @interface GiftCardProgramVM
 */
export interface GiftCardProgramVM {
    /**
     * 
     * @type {number}
     * @memberof GiftCardProgramVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardProgramVM
     */
    'referenceId'?: string | null;
    /**
     * 
     * @type {GiftCardProgramType}
     * @memberof GiftCardProgramVM
     */
    'giftCardProgramType'?: GiftCardProgramType;
    /**
     * 
     * @type {number}
     * @memberof GiftCardProgramVM
     */
    'merchantId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GiftCardProgramVM
     */
    'displayAffiliationText'?: boolean;
    /**
     * 
     * @type {BrandMerchantVM}
     * @memberof GiftCardProgramVM
     */
    'brandMerchant'?: BrandMerchantVM;
    /**
     * 
     * @type {string}
     * @memberof GiftCardProgramVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GiftCardProgramVM
     */
    'merchantIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<SpecialCategoryVM | SpecialCategoryDetailsVM>}
     * @memberof GiftCardProgramVM
     */
    'specialCategories'?: Array<SpecialCategoryVM | SpecialCategoryDetailsVM> | null;
    /**
     * 
     * @type {Array<ProgramProfileVM>}
     * @memberof GiftCardProgramVM
     */
    'programProfiles'?: Array<ProgramProfileVM> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum GiftCardSortBy {
    MinimumValue = 'MinimumValue',
    MaximumValue = 'MaximumValue',
    AvailableFrom = 'AvailableFrom',
    AvailableTo = 'AvailableTo',
    GiftCardStatus = 'GiftCardStatus',
    Alphabetical = 'Alphabetical'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum GiftCardStatus {
    Unspecified = 'Unspecified',
    Active = 'Active',
    Draft = 'Draft',
    Disabled = 'Disabled',
    Archived = 'Archived',
    Obsolete = 'Obsolete'
}

/**
 * 
 * @export
 * @interface GiftCardVM
 */
export interface GiftCardVM {
    /**
     * 
     * @type {number}
     * @memberof GiftCardVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'tag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'retailersDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GiftCardVM
     */
    'minimumValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardVM
     */
    'maximumValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'presetValues'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'availableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'availableTo'?: string;
    /**
     * 
     * @type {GiftCardStatus}
     * @memberof GiftCardVM
     */
    'giftCardStatus'?: GiftCardStatus;
    /**
     * 
     * @type {number}
     * @memberof GiftCardVM
     */
    'locationCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardVM
     */
    'retailersCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GiftCardVM
     */
    'metaDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GiftCardVM
     */
    'isPcloPromotion'?: boolean;
}
/**
 * 
 * @export
 * @interface GlobalSettingVM
 */
export interface GlobalSettingVM {
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingVM
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalSettingVM
     */
    'enableIAP'?: boolean;
}
/**
 * 
 * @export
 * @interface GooglePayProvisionResponse
 */
export interface GooglePayProvisionResponse {
    /**
     * 
     * @type {FundingAccountInfo}
     * @memberof GooglePayProvisionResponse
     */
    'fundingAccountInfo'?: FundingAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof GooglePayProvisionResponse
     */
    'tokenizationAuthenticationValue'?: string | null;
}
/**
 * 
 * @export
 * @interface GooglePayProvisionResponseIResponse
 */
export interface GooglePayProvisionResponseIResponse {
    /**
     * 
     * @type {GooglePayProvisionResponse}
     * @memberof GooglePayProvisionResponseIResponse
     */
    'result'?: GooglePayProvisionResponse;
    /**
     * 
     * @type {boolean}
     * @memberof GooglePayProvisionResponseIResponse
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GooglePayProvisionResponseIResponse
     */
    'hasFailed'?: boolean;
    /**
     * 
     * @type {ResponseStatus}
     * @memberof GooglePayProvisionResponseIResponse
     */
    'status'?: ResponseStatus;
}
/**
 * 
 * @export
 * @interface IAPOTPNotification
 */
export interface IAPOTPNotification {
    /**
     * 
     * @type {string}
     * @memberof IAPOTPNotification
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAPOTPNotification
     */
    'customerNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAPOTPNotification
     */
    'personName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAPOTPNotification
     */
    'operatingProgramName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAPOTPNotification
     */
    'partnerName'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof IAPOTPNotification
     */
    'attributes'?: any | null;
    /**
     * 
     * @type {OTPEvent}
     * @memberof IAPOTPNotification
     */
    'eventFields'?: OTPEvent;
}
/**
 * 
 * @export
 * @interface IResponse
 */
export interface IResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IResponse
     */
    'isSuccessful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IResponse
     */
    'hasFailed'?: boolean;
    /**
     * 
     * @type {ResponseStatus}
     * @memberof IResponse
     */
    'status'?: ResponseStatus;
}
/**
 * 
 * @export
 * @interface MerchantDetailsVM
 */
export interface MerchantDetailsVM {
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'isoCategory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'businessEntity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantDetailsVM
     */
    'employees'?: number;
    /**
     * 
     * @type {Array<MerchantLocationVM | MerchantLocationDetailsVM>}
     * @memberof MerchantDetailsVM
     */
    'merchantLocations'?: Array<MerchantLocationVM | MerchantLocationDetailsVM> | null;
    /**
     * 
     * @type {Array<GiftCardProgramVM | GiftCardProgramDetailsVM>}
     * @memberof MerchantDetailsVM
     */
    'giftCardPrograms'?: Array<GiftCardProgramVM | GiftCardProgramDetailsVM> | null;
    /**
     * 
     * @type {Array<MerchantImageVM>}
     * @memberof MerchantDetailsVM
     */
    'merchantImages'?: Array<MerchantImageVM> | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantDetailsVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'webSiteUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'merchantNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'primaryColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantDetailsVM
     */
    'affiliationTextColour'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantDetailsVM
     */
    'isFeaturedBrand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantDetailsVM
     */
    'hasOnlineStore'?: boolean;
    /**
     * 
     * @type {MerchantStatus}
     * @memberof MerchantDetailsVM
     */
    'status'?: MerchantStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MerchantImageType {
    Unspecified = 'Unspecified',
    Logo = 'Logo',
    SquareLogo = 'SquareLogo',
    SquareCardLogo = 'SquareCardLogo',
    SquareCardColourLogo = 'SquareCardColourLogo',
    LogoWhite = 'LogoWhite'
}

/**
 * 
 * @export
 * @interface MerchantImageVM
 */
export interface MerchantImageVM {
    /**
     * 
     * @type {number}
     * @memberof MerchantImageVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantImageVM
     */
    'merchantId'?: number;
    /**
     * 
     * @type {MerchantImageType}
     * @memberof MerchantImageVM
     */
    'merchantImageType'?: MerchantImageType;
    /**
     * 
     * @type {string}
     * @memberof MerchantImageVM
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantLocationDetailsVM
 */
export interface MerchantLocationDetailsVM {
    /**
     * 
     * @type {MerchantVM | MerchantDetailsVM}
     * @memberof MerchantLocationDetailsVM
     */
    'merchant'?: MerchantVM | MerchantDetailsVM | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationDetailsVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationDetailsVM
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationDetailsVM
     */
    'merchantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'addressLineOne'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'addressLineTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'suburb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationDetailsVM
     */
    'postcode'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MerchantLocationSortBy {
    Name = 'Name',
    AddressLineOne = 'AddressLineOne',
    Suburb = 'Suburb',
    State = 'State',
    Country = 'Country',
    Postcode = 'Postcode'
}

/**
 * 
 * @export
 * @interface MerchantLocationVM
 */
export interface MerchantLocationVM {
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationVM
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationVM
     */
    'merchantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'addressLineOne'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'addressLineTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'suburb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationVM
     */
    'postcode'?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantLocationVMIPagedList
 */
export interface MerchantLocationVMIPagedList {
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationVMIPagedList
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationVMIPagedList
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationVMIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {Array<MerchantLocationVM | MerchantLocationDetailsVM>}
     * @memberof MerchantLocationVMIPagedList
     */
    'results'?: Array<MerchantLocationVM | MerchantLocationDetailsVM> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MerchantSortBy {
    Name = 'Name',
    MerchantNumber = 'MerchantNumber',
    Status = 'Status',
    Industry = 'Industry'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MerchantStatus {
    Active = 'Active',
    Disabled = 'Disabled'
}

/**
 * 
 * @export
 * @interface MerchantVM
 */
export interface MerchantVM {
    /**
     * 
     * @type {number}
     * @memberof MerchantVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'webSiteUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'merchantNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'primaryColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantVM
     */
    'affiliationTextColour'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantVM
     */
    'isFeaturedBrand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantVM
     */
    'hasOnlineStore'?: boolean;
    /**
     * 
     * @type {MerchantStatus}
     * @memberof MerchantVM
     */
    'status'?: MerchantStatus;
}
/**
 * 
 * @export
 * @interface MerchantVMIPagedList
 */
export interface MerchantVMIPagedList {
    /**
     * 
     * @type {number}
     * @memberof MerchantVMIPagedList
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantVMIPagedList
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantVMIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {Array<MerchantVM | MerchantDetailsVM>}
     * @memberof MerchantVMIPagedList
     */
    'results'?: Array<MerchantVM | MerchantDetailsVM> | null;
}
/**
 * 
 * @export
 * @interface NewApplePayProvision
 */
export interface NewApplePayProvision {
    /**
     * 
     * @type {string}
     * @memberof NewApplePayProvision
     */
    'nonce': string;
    /**
     * 
     * @type {string}
     * @memberof NewApplePayProvision
     */
    'nonceSignature': string;
    /**
     * 
     * @type {string}
     * @memberof NewApplePayProvision
     */
    'certificateLeaf': string;
    /**
     * 
     * @type {string}
     * @memberof NewApplePayProvision
     */
    'certificateRoot': string;
}
/**
 * 
 * @export
 * @interface NewDeletionRequest
 */
export interface NewDeletionRequest {
    /**
     * 
     * @type {DeletionRequestReason}
     * @memberof NewDeletionRequest
     */
    'deletionRequestReason'?: DeletionRequestReason;
    /**
     * 
     * @type {string}
     * @memberof NewDeletionRequest
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NewUserGiftCardWorkflow
 */
export interface NewUserGiftCardWorkflow {
    /**
     * 
     * @type {number}
     * @memberof NewUserGiftCardWorkflow
     */
    'giftCardId'?: number;
    /**
     * 
     * @type {UserGiftCardStyle}
     * @memberof NewUserGiftCardWorkflow
     */
    'cardStyle'?: UserGiftCardStyle;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'celebration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'giftWrap'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'receiverEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'receiverPhoneNumber'?: string | null;
    /**
     * 
     * @type {UserGiftCardSendingMethod}
     * @memberof NewUserGiftCardWorkflow
     */
    'sendingMethod'?: UserGiftCardSendingMethod;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'receiverFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'receiverLastName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewUserGiftCardWorkflow
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewUserGiftCardWorkflow
     */
    'profileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewUserGiftCardWorkflow
     */
    'specialTimezoneId'?: number;
    /**
     * 
     * @type {UserGiftCardType}
     * @memberof NewUserGiftCardWorkflow
     */
    'cardType'?: UserGiftCardType;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'paymentIntentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewUserGiftCardWorkflow
     */
    'sendNow'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'promoCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewUserGiftCardWorkflow
     */
    'discountAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewUserGiftCardWorkflow
     */
    'paymentProvider'?: string | null;
}
/**
 * 
 * @export
 * @interface OTPEmail
 */
export interface OTPEmail {
    /**
     * 
     * @type {string}
     * @memberof OTPEmail
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OTPEmail
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OTPEmail
     */
    'emailNormalized'?: string | null;
}
/**
 * 
 * @export
 * @interface OTPEvent
 */
export interface OTPEvent {
    /**
     * 
     * @type {string}
     * @memberof OTPEvent
     */
    'activationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OTPEvent
     */
    'activationMethod'?: string | null;
    /**
     * 
     * @type {OTPEmail}
     * @memberof OTPEvent
     */
    'email'?: OTPEmail;
    /**
     * 
     * @type {OTPPhone}
     * @memberof OTPEvent
     */
    'phone'?: OTPPhone;
}
/**
 * 
 * @export
 * @interface OTPPhone
 */
export interface OTPPhone {
    /**
     * 
     * @type {string}
     * @memberof OTPPhone
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OTPPhone
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OTPPhone
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OTPPhone
     */
    'phoneNumberNormalized'?: string | null;
}
/**
 * 
 * @export
 * @interface OptInRequestDto
 */
export interface OptInRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OptInRequestDto
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptInRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptInRequestDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptInRequestDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptInRequestDto
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OptOutRequestDto
 */
export interface OptOutRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OptOutRequestDto
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptOutRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptOutRequestDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptOutRequestDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptOutRequestDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptOutRequestDto
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderVM
 */
export interface OrderVM {
    /**
     * 
     * @type {number}
     * @memberof OrderVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderVM
     */
    'userGiftCardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderVM
     */
    'giftCardName'?: string | null;
    /**
     * 
     * @type {UserGiftCardPersonalizationVM}
     * @memberof OrderVM
     */
    'userGiftCardPersonalization'?: UserGiftCardPersonalizationVM;
    /**
     * 
     * @type {number}
     * @memberof OrderVM
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderVM
     */
    'activated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderVM
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {UserGiftCardType}
     * @memberof OrderVM
     */
    'cardType'?: UserGiftCardType;
    /**
     * 
     * @type {UserGiftCardStatus}
     * @memberof OrderVM
     */
    'status'?: UserGiftCardStatus;
    /**
     * 
     * @type {UserGiftCardOrderStatus}
     * @memberof OrderVM
     */
    'orderStatus'?: UserGiftCardOrderStatus;
    /**
     * 
     * @type {UserGiftCardDeliveryStatus}
     * @memberof OrderVM
     */
    'deliveryStatus'?: UserGiftCardDeliveryStatus;
    /**
     * 
     * @type {ProgramProfileVM}
     * @memberof OrderVM
     */
    'profile'?: ProgramProfileVM;
}
/**
 * 
 * @export
 * @interface OrderVMIPagedList
 */
export interface OrderVMIPagedList {
    /**
     * 
     * @type {number}
     * @memberof OrderVMIPagedList
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderVMIPagedList
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderVMIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {Array<OrderVM>}
     * @memberof OrderVMIPagedList
     */
    'results'?: Array<OrderVM> | null;
}
/**
 * 
 * @export
 * @interface Parent
 */
export interface Parent {
    /**
     * 
     * @type {string}
     * @memberof Parent
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof Parent
     */
    'authorizations'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof Parent
     */
    'creationTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parent
     */
    'originalTransactionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Parent
     */
    'callerReference'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentIntentVM
 */
export interface PaymentIntentVM {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentVM
     */
    'paymentIntentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentVM
     */
    'clientSecret'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentVM
     */
    'customerId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProgramProfileStatus {
    Active = 'Active',
    Draft = 'Draft',
    Disabled = 'Disabled'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ProgramProfileType {
    Category = 'Category',
    Brand = 'Brand'
}

/**
 * 
 * @export
 * @interface ProgramProfileVM
 */
export interface ProgramProfileVM {
    /**
     * 
     * @type {number}
     * @memberof ProgramProfileVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'frontsideImage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'backsideImage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProgramProfileVM
     */
    'merchantId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'referenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'colour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'starOneColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'starTwoColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramProfileVM
     */
    'lastFourDigitsColour'?: string | null;
    /**
     * 
     * @type {ProgramProfileType}
     * @memberof ProgramProfileVM
     */
    'programProfileType'?: ProgramProfileType;
    /**
     * 
     * @type {ProgramProfileStatus}
     * @memberof ProgramProfileVM
     */
    'programProfileStatus'?: ProgramProfileStatus;
}
/**
 * 
 * @export
 * @interface PromotionCodeVM
 */
export interface PromotionCodeVM {
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeVM
     */
    'promoCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeVM
     */
    'expiryDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeVM
     */
    'discountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeVM
     */
    'maximumDiscountValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeVM
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeVM
     */
    'updatedOn'?: string;
}
/**
 * 
 * @export
 * @interface ProvisionData
 */
export interface ProvisionData {
    /**
     * 
     * @type {string}
     * @memberof ProvisionData
     */
    'jsonString'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterNewUser
 */
export interface RegisterNewUser {
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUser
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUser
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterNewUser
     */
    'optInMarketing'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ResponseStatus {
    Success = 'Success',
    SuccessButWarnings = 'SuccessButWarnings',
    Error = 'Error',
    Unauthorised = 'Unauthorised',
    Invalid = 'Invalid',
    NotFound = 'NotFound'
}

/**
 * 
 * @export
 * @interface SetPin
 */
export interface SetPin {
    /**
     * 
     * @type {string}
     * @memberof SetPin
     */
    'pin'?: string | null;
}
/**
 * 
 * @export
 * @interface SpecialCategoryDetailsVM
 */
export interface SpecialCategoryDetailsVM {
    /**
     * 
     * @type {Array<GiftCardProgramVM | GiftCardProgramDetailsVM>}
     * @memberof SpecialCategoryDetailsVM
     */
    'giftCardPrograms'?: Array<GiftCardProgramVM | GiftCardProgramDetailsVM> | null;
    /**
     * 
     * @type {number}
     * @memberof SpecialCategoryDetailsVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpecialCategoryDetailsVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialCategoryDetailsVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {SpecialCategoryStatus}
     * @memberof SpecialCategoryDetailsVM
     */
    'status'?: SpecialCategoryStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SpecialCategorySortBy {
    Name = 'Name',
    Parent = 'Parent'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SpecialCategoryStatus {
    Active = 'Active',
    Disabled = 'Disabled'
}

/**
 * 
 * @export
 * @interface SpecialCategoryVM
 */
export interface SpecialCategoryVM {
    /**
     * 
     * @type {number}
     * @memberof SpecialCategoryVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpecialCategoryVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialCategoryVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {SpecialCategoryStatus}
     * @memberof SpecialCategoryVM
     */
    'status'?: SpecialCategoryStatus;
}
/**
 * 
 * @export
 * @interface SpecialCategoryVMIPagedList
 */
export interface SpecialCategoryVMIPagedList {
    /**
     * 
     * @type {number}
     * @memberof SpecialCategoryVMIPagedList
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpecialCategoryVMIPagedList
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpecialCategoryVMIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {Array<SpecialCategoryVM | SpecialCategoryDetailsVM>}
     * @memberof SpecialCategoryVMIPagedList
     */
    'results'?: Array<SpecialCategoryVM | SpecialCategoryDetailsVM> | null;
}
/**
 * 
 * @export
 * @interface SpecialTimezoneVM
 */
export interface SpecialTimezoneVM {
    /**
     * 
     * @type {number}
     * @memberof SpecialTimezoneVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpecialTimezoneVM
     */
    'timeZoneDisplay'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpecialTimezoneVM
     */
    'timeZoneDifference'?: number;
}
/**
 * 
 * @export
 * @interface UserDetailsVM
 */
export interface UserDetailsVM {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'id'?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserDetailsVM
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsVM
     */
    'mfaeNabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsVM
     */
    'optInMarketing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'dateJoined'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsVM
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof UserDetailsVM
     */
    'userRole'?: UserRole;
}
/**
 * 
 * @export
 * @interface UserGiftCardActivationDetailsVM
 */
export interface UserGiftCardActivationDetailsVM {
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'animationType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'frontImage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'cardProgramName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'starOneColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'starTwoColour'?: string | null;
    /**
     * 
     * @type {GiftCardProgramType}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'giftCardProgramType'?: GiftCardProgramType;
    /**
     * 
     * @type {boolean}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'displayAffiliationText'?: boolean | null;
    /**
     * 
     * @type {BrandMerchantVM}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'brandMerchant'?: BrandMerchantVM;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardActivationDetailsVM
     */
    'panLast4'?: string | null;
}
/**
 * 
 * @export
 * @interface UserGiftCardActivationValidationVM
 */
export interface UserGiftCardActivationValidationVM {
    /**
     * 
     * @type {boolean}
     * @memberof UserGiftCardActivationValidationVM
     */
    'isTokenValid'?: boolean;
    /**
     * 
     * @type {UserGiftCardStatus}
     * @memberof UserGiftCardActivationValidationVM
     */
    'status'?: UserGiftCardStatus;
}
/**
 * 
 * @export
 * @interface UserGiftCardBlockReasonVM
 */
export interface UserGiftCardBlockReasonVM {
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardBlockReasonVM
     */
    'blockReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardBlockReasonVM
     */
    'blockMemo'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardDeliveryStatus {
    Unspecified = 'Unspecified',
    Pending = 'Pending',
    Delivered = 'Delivered',
    DeliveryFailed = 'DeliveryFailed'
}

/**
 * 
 * @export
 * @interface UserGiftCardDetailsVM
 */
export interface UserGiftCardDetailsVM {
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardDetailsVM
     */
    'giftCardId'?: number;
    /**
     * 
     * @type {GiftCardVM | GiftCardDetailsVM | GiftCardGraphVM}
     * @memberof UserGiftCardDetailsVM
     */
    'giftCard'?: GiftCardVM | GiftCardDetailsVM | GiftCardGraphVM | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'userId'?: string | null;
    /**
     * 
     * @type {UserGiftCardPersonalizationVM}
     * @memberof UserGiftCardDetailsVM
     */
    'userGiftCardPersonalization'?: UserGiftCardPersonalizationVM;
    /**
     * 
     * @type {object}
     * @memberof UserGiftCardDetailsVM
     */
    'userGiftCardStory'?: object;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'provisioningJson'?: string | null;
    /**
     * 
     * @type {CardDetails}
     * @memberof UserGiftCardDetailsVM
     */
    'cardDetails'?: CardDetails;
    /**
     * 
     * @type {Array<CardBalance>}
     * @memberof UserGiftCardDetailsVM
     */
    'cardBalances'?: Array<CardBalance> | null;
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardDetailsVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardDetailsVM
     */
    'value'?: number;
    /**
     * 
     * @type {UserGiftCardType}
     * @memberof UserGiftCardDetailsVM
     */
    'cardType'?: UserGiftCardType;
    /**
     * 
     * @type {UserGiftCardStatus}
     * @memberof UserGiftCardDetailsVM
     */
    'status'?: UserGiftCardStatus;
    /**
     * 
     * @type {UserGiftCardDeliveryStatus}
     * @memberof UserGiftCardDetailsVM
     */
    'deliveryStatus'?: UserGiftCardDeliveryStatus;
    /**
     * 
     * @type {UserGiftCardOrderStatus}
     * @memberof UserGiftCardDetailsVM
     */
    'orderStatus'?: UserGiftCardOrderStatus;
    /**
     * 
     * @type {GiftCardProgramType}
     * @memberof UserGiftCardDetailsVM
     */
    'giftCardProgramType'?: GiftCardProgramType;
    /**
     * 
     * @type {UserGiftCardBlockReasonVM}
     * @memberof UserGiftCardDetailsVM
     */
    'userGiftCardBlockReason'?: UserGiftCardBlockReasonVM;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'lastFourColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'kartaCardId'?: string | null;
    /**
     * 
     * @type {UserDetailsVM | AdminSpecialUserDetailsVM}
     * @memberof UserGiftCardDetailsVM
     */
    'creator'?: UserDetailsVM | AdminSpecialUserDetailsVM | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'giftCardName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'frontImage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'activated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardDetailsVM
     */
    'turnPastDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserGiftCardDetailsVM
     */
    'displayAffiliationText'?: boolean | null;
    /**
     * 
     * @type {BrandMerchantVM}
     * @memberof UserGiftCardDetailsVM
     */
    'brandMerchant'?: BrandMerchantVM;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardOrderStatus {
    Unspecified = 'Unspecified',
    Sold = 'Sold',
    Cancelled = 'Cancelled',
    Review = 'Review',
    Refunded = 'Refunded',
    UnCaptured = 'UnCaptured',
    CaptureFailed = 'CaptureFailed'
}

/**
 * 
 * @export
 * @interface UserGiftCardPersonalizationVM
 */
export interface UserGiftCardPersonalizationVM {
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardPersonalizationVM
     */
    'userGiftCardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'receiverFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'receiverLastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'message'?: string | null;
    /**
     * 
     * @type {UserGiftCardStyle}
     * @memberof UserGiftCardPersonalizationVM
     */
    'cardStyle'?: UserGiftCardStyle;
    /**
     * 
     * @type {SpecialTimezoneVM}
     * @memberof UserGiftCardPersonalizationVM
     */
    'specialTimeZone'?: SpecialTimezoneVM;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'celebration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'giftWrap'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'receiverEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardPersonalizationVM
     */
    'receiverPhoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserGiftCardPersonalizationVM
     */
    'sendNow'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardSendingMethod {
    Email = 'Email',
    Sms = 'SMS'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardSortBy {
    Value = 'Value',
    Activated = 'Activated',
    CardType = 'CardType',
    Status = 'Status',
    CreatorName = 'CreatorName',
    CreatorEmail = 'CreatorEmail',
    CreatedOn = 'CreatedOn'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardStatus {
    Unspecified = 'Unspecified',
    Created = 'Created',
    Claimed = 'Claimed',
    Past = 'Past',
    Blocked = 'Blocked',
    CreationFailed = 'CreationFailed',
    Cancelled = 'Cancelled',
    PendingCreation = 'PendingCreation'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardStyle {
    Unspecified = 'Unspecified',
    Plain = 'Plain',
    Stripes = 'Stripes',
    Spots = 'Spots'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserGiftCardType {
    Unspecified = 'Unspecified',
    Physical = 'Physical',
    Digital = 'Digital'
}

/**
 * 
 * @export
 * @interface UserGiftCardVM
 */
export interface UserGiftCardVM {
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardVM
     */
    'value'?: number;
    /**
     * 
     * @type {UserGiftCardType}
     * @memberof UserGiftCardVM
     */
    'cardType'?: UserGiftCardType;
    /**
     * 
     * @type {UserGiftCardStatus}
     * @memberof UserGiftCardVM
     */
    'status'?: UserGiftCardStatus;
    /**
     * 
     * @type {UserGiftCardDeliveryStatus}
     * @memberof UserGiftCardVM
     */
    'deliveryStatus'?: UserGiftCardDeliveryStatus;
    /**
     * 
     * @type {UserGiftCardOrderStatus}
     * @memberof UserGiftCardVM
     */
    'orderStatus'?: UserGiftCardOrderStatus;
    /**
     * 
     * @type {GiftCardProgramType}
     * @memberof UserGiftCardVM
     */
    'giftCardProgramType'?: GiftCardProgramType;
    /**
     * 
     * @type {UserGiftCardBlockReasonVM}
     * @memberof UserGiftCardVM
     */
    'userGiftCardBlockReason'?: UserGiftCardBlockReasonVM;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'lastFourColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'kartaCardId'?: string | null;
    /**
     * 
     * @type {UserDetailsVM | AdminSpecialUserDetailsVM}
     * @memberof UserGiftCardVM
     */
    'creator'?: UserDetailsVM | AdminSpecialUserDetailsVM | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'giftCardName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'frontImage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'activated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGiftCardVM
     */
    'turnPastDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserGiftCardVM
     */
    'displayAffiliationText'?: boolean | null;
    /**
     * 
     * @type {BrandMerchantVM}
     * @memberof UserGiftCardVM
     */
    'brandMerchant'?: BrandMerchantVM;
}
/**
 * 
 * @export
 * @interface UserGiftCardVMIPagedList
 */
export interface UserGiftCardVMIPagedList {
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardVMIPagedList
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardVMIPagedList
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGiftCardVMIPagedList
     */
    'total'?: number;
    /**
     * 
     * @type {Array<UserGiftCardVM | UserGiftCardDetailsVM>}
     * @memberof UserGiftCardVMIPagedList
     */
    'results'?: Array<UserGiftCardVM | UserGiftCardDetailsVM> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserRole {
    Normal = 'Normal',
    Internal = 'Internal',
    Client = 'Client'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserStatus {
    Active = 'Active',
    Disabled = 'Disabled',
    Pending = 'Pending',
    Error = 'Error',
    PendingDeletion = 'PendingDeletion',
    Deleted = 'Deleted'
}

/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a single row of global settings
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSettings: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/GlobalSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a single row of global settings
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalSettings(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSettingVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalSettings(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a single row of global settings
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSettings(apiVersion?: string, options?: any): AxiosPromise<GlobalSettingVM> {
            return localVarFp.getGlobalSettings(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Returns a single row of global settings
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getGlobalSettings(apiVersion?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getGlobalSettings(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CardTransactionApi - axios parameter creator
 * @export
 */
export const CardTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a paginated list of transactions for the specified UserGiftCard
         * @param {number} userGiftCardId 
         * @param {number} [offset] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardTransactions: async (userGiftCardId: number, offset?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('getCardTransactions', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/CardTransaction/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardTransactionApi - functional programming interface
 * @export
 */
export const CardTransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardTransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a paginated list of transactions for the specified UserGiftCard
         * @param {number} userGiftCardId 
         * @param {number} [offset] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardTransactions(userGiftCardId: number, offset?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardTransactions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardTransactions(userGiftCardId, offset, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CardTransactionApi - factory interface
 * @export
 */
export const CardTransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardTransactionApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a paginated list of transactions for the specified UserGiftCard
         * @param {number} userGiftCardId 
         * @param {number} [offset] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardTransactions(userGiftCardId: number, offset?: number, apiVersion?: string, options?: any): AxiosPromise<CardTransactions> {
            return localVarFp.getCardTransactions(userGiftCardId, offset, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardTransactionApi - object-oriented interface
 * @export
 * @class CardTransactionApi
 * @extends {BaseAPI}
 */
export class CardTransactionApi extends BaseAPI {
    /**
     * 
     * @summary Returns a paginated list of transactions for the specified UserGiftCard
     * @param {number} userGiftCardId 
     * @param {number} [offset] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardTransactionApi
     */
    public getCardTransactions(userGiftCardId: number, offset?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return CardTransactionApiFp(this.configuration).getCardTransactions(userGiftCardId, offset, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends the OptIn email
         * @param {string} [apiVersion] 
         * @param {OptInRequestDto} [optInRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOptInEmail: async (apiVersion?: string, optInRequestDto?: OptInRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/OptIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optInRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends the OptOut email
         * @param {string} [apiVersion] 
         * @param {OptOutRequestDto} [optOutRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOptOutEmail: async (apiVersion?: string, optOutRequestDto?: OptOutRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/OptOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optOutRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends the ResetPassword email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail: async (email: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sendResetPasswordEmail', 'email', email)
            const localVarPath = `/Email/ResetPassword/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends the UpdatedPassword email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUpdatePasswordEmail: async (email: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sendUpdatePasswordEmail', 'email', email)
            const localVarPath = `/Email/UpdatePassword/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sends the OptIn email
         * @param {string} [apiVersion] 
         * @param {OptInRequestDto} [optInRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOptInEmail(apiVersion?: string, optInRequestDto?: OptInRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOptInEmail(apiVersion, optInRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends the OptOut email
         * @param {string} [apiVersion] 
         * @param {OptOutRequestDto} [optOutRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOptOutEmail(apiVersion?: string, optOutRequestDto?: OptOutRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOptOutEmail(apiVersion, optOutRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends the ResetPassword email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendResetPasswordEmail(email: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendResetPasswordEmail(email, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends the UpdatedPassword email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendUpdatePasswordEmail(email: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendUpdatePasswordEmail(email, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Sends the OptIn email
         * @param {string} [apiVersion] 
         * @param {OptInRequestDto} [optInRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOptInEmail(apiVersion?: string, optInRequestDto?: OptInRequestDto, options?: any): AxiosPromise<IResponse> {
            return localVarFp.sendOptInEmail(apiVersion, optInRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends the OptOut email
         * @param {string} [apiVersion] 
         * @param {OptOutRequestDto} [optOutRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOptOutEmail(apiVersion?: string, optOutRequestDto?: OptOutRequestDto, options?: any): AxiosPromise<IResponse> {
            return localVarFp.sendOptOutEmail(apiVersion, optOutRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends the ResetPassword email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail(email: string, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.sendResetPasswordEmail(email, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends the UpdatedPassword email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUpdatePasswordEmail(email: string, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.sendUpdatePasswordEmail(email, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary Sends the OptIn email
     * @param {string} [apiVersion] 
     * @param {OptInRequestDto} [optInRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendOptInEmail(apiVersion?: string, optInRequestDto?: OptInRequestDto, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendOptInEmail(apiVersion, optInRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends the OptOut email
     * @param {string} [apiVersion] 
     * @param {OptOutRequestDto} [optOutRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendOptOutEmail(apiVersion?: string, optOutRequestDto?: OptOutRequestDto, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendOptOutEmail(apiVersion, optOutRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends the ResetPassword email
     * @param {string} email 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendResetPasswordEmail(email: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendResetPasswordEmail(email, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends the UpdatedPassword email
     * @param {string} email 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendUpdatePasswordEmail(email: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendUpdatePasswordEmail(email, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GiftCardApi - axios parameter creator
 * @export
 */
export const GiftCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
         * @param {string} slug 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftCard: async (slug: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getGiftCard', 'slug', slug)
            const localVarPath = `/GiftCard/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PagedList of type GiftCardVM using the filter provided
         * @param {Array<GiftCardStatus>} [statuses] 
         * @param {string} [dateModifiedAfter] 
         * @param {Array<number>} [selectedCategoriesIds] 
         * @param {GiftCardSortBy} [sortBy] 
         * @param {GiftCardProgramType} [giftCardProgramType] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftCards: async (statuses?: Array<GiftCardStatus>, dateModifiedAfter?: string, selectedCategoriesIds?: Array<number>, sortBy?: GiftCardSortBy, giftCardProgramType?: GiftCardProgramType, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (dateModifiedAfter !== undefined) {
                localVarQueryParameter['dateModifiedAfter'] = (dateModifiedAfter as any instanceof Date) ?
                    (dateModifiedAfter as any).toISOString() :
                    dateModifiedAfter;
            }

            if (selectedCategoriesIds) {
                localVarQueryParameter['selectedCategoriesIds'] = selectedCategoriesIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (giftCardProgramType !== undefined) {
                localVarQueryParameter['giftCardProgramType'] = giftCardProgramType;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftCardApi - functional programming interface
 * @export
 */
export const GiftCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftCardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
         * @param {string} slug 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftCard(slug: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftCard(slug, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PagedList of type GiftCardVM using the filter provided
         * @param {Array<GiftCardStatus>} [statuses] 
         * @param {string} [dateModifiedAfter] 
         * @param {Array<number>} [selectedCategoriesIds] 
         * @param {GiftCardSortBy} [sortBy] 
         * @param {GiftCardProgramType} [giftCardProgramType] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftCards(statuses?: Array<GiftCardStatus>, dateModifiedAfter?: string, selectedCategoriesIds?: Array<number>, sortBy?: GiftCardSortBy, giftCardProgramType?: GiftCardProgramType, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailsVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftCards(statuses, dateModifiedAfter, selectedCategoriesIds, sortBy, giftCardProgramType, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftCardApi - factory interface
 * @export
 */
export const GiftCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftCardApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
         * @param {string} slug 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftCard(slug: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardDetailsVM> {
            return localVarFp.getGiftCard(slug, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PagedList of type GiftCardVM using the filter provided
         * @param {Array<GiftCardStatus>} [statuses] 
         * @param {string} [dateModifiedAfter] 
         * @param {Array<number>} [selectedCategoriesIds] 
         * @param {GiftCardSortBy} [sortBy] 
         * @param {GiftCardProgramType} [giftCardProgramType] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftCards(statuses?: Array<GiftCardStatus>, dateModifiedAfter?: string, selectedCategoriesIds?: Array<number>, sortBy?: GiftCardSortBy, giftCardProgramType?: GiftCardProgramType, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<GiftCardDetailsVMIPagedList> {
            return localVarFp.getGiftCards(statuses, dateModifiedAfter, selectedCategoriesIds, sortBy, giftCardProgramType, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GiftCardApi - object-oriented interface
 * @export
 * @class GiftCardApi
 * @extends {BaseAPI}
 */
export class GiftCardApi extends BaseAPI {
    /**
     * 
     * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
     * @param {string} slug 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public getGiftCard(slug: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).getGiftCard(slug, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PagedList of type GiftCardVM using the filter provided
     * @param {Array<GiftCardStatus>} [statuses] 
     * @param {string} [dateModifiedAfter] 
     * @param {Array<number>} [selectedCategoriesIds] 
     * @param {GiftCardSortBy} [sortBy] 
     * @param {GiftCardProgramType} [giftCardProgramType] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public getGiftCards(statuses?: Array<GiftCardStatus>, dateModifiedAfter?: string, selectedCategoriesIds?: Array<number>, sortBy?: GiftCardSortBy, giftCardProgramType?: GiftCardProgramType, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).getGiftCards(statuses, dateModifiedAfter, selectedCategoriesIds, sortBy, giftCardProgramType, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GiftCardWorkflowApi - axios parameter creator
 * @export
 */
export const GiftCardWorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Manages creation of a new Card in Karta and it\'s corresponding UserGiftCard in Special DB
         * @param {string} [apiVersion] 
         * @param {NewUserGiftCardWorkflow} [newUserGiftCardWorkflow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGiftCard: async (apiVersion?: string, newUserGiftCardWorkflow?: NewUserGiftCardWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUserGiftCardWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the userGiftCard details for the activation flow
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationUserGiftCardDetails: async (userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow/GetActivationUserGiftCardDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGiftCardID !== undefined) {
                localVarQueryParameter['userGiftCardID'] = userGiftCardID;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User GiftCard Order Status for given card id
         * @param {number} [userGiftCardID] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGiftCardOrderStatus: async (userGiftCardID?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow/GetUserGiftCardOrderStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGiftCardID !== undefined) {
                localVarQueryParameter['userGiftCardID'] = userGiftCardID;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Webhook endpoint for Karta OTP Notifications
         * @param {string} [apiVersion] 
         * @param {IAPOTPNotification} [iAPOTPNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iAPOTPNotification: async (apiVersion?: string, iAPOTPNotification?: IAPOTPNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow/IAPOTPNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAPOTPNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary check whether the card is already activated
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCardAlreadyActivated: async (userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow/IsCardAlreadyActivated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGiftCardID !== undefined) {
                localVarQueryParameter['userGiftCardID'] = userGiftCardID;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the Pin of an existing UserGiftCard belonging to the currentUser
         * @param {number} userGiftCardID 
         * @param {string} [token] the activation token
         * @param {string} [apiVersion] 
         * @param {SetPin} [setPin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPin: async (userGiftCardID: number, token?: string, apiVersion?: string, setPin?: SetPin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardID' is not null or undefined
            assertParamExists('setPin', 'userGiftCardID', userGiftCardID)
            const localVarPath = `/GiftCardWorkflow/SetPin/{userGiftCardID}`
                .replace(`{${"userGiftCardID"}}`, encodeURIComponent(String(userGiftCardID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the currentUsers Pin for the specified card via Karta Api.
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {SetPin} [setPin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePin: async (userGiftCardId: number, apiVersion?: string, setPin?: SetPin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('updatePin', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/GiftCardWorkflow/UpdatePin/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validate the activation token of the gift card
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateActivationToken: async (userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow/ValidateActivationToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGiftCardID !== undefined) {
                localVarQueryParameter['userGiftCardID'] = userGiftCardID;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify the activation token of the gift card
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyActivationToken: async (userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardWorkflow/VerifyActivationToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGiftCardID !== undefined) {
                localVarQueryParameter['userGiftCardID'] = userGiftCardID;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftCardWorkflowApi - functional programming interface
 * @export
 */
export const GiftCardWorkflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftCardWorkflowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Manages creation of a new Card in Karta and it\'s corresponding UserGiftCard in Special DB
         * @param {string} [apiVersion] 
         * @param {NewUserGiftCardWorkflow} [newUserGiftCardWorkflow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGiftCard(apiVersion?: string, newUserGiftCardWorkflow?: NewUserGiftCardWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGiftCard(apiVersion, newUserGiftCardWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the userGiftCard details for the activation flow
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivationUserGiftCardDetails(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGiftCardActivationDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivationUserGiftCardDetails(userGiftCardID, token, itemId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User GiftCard Order Status for given card id
         * @param {number} [userGiftCardID] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGiftCardOrderStatus(userGiftCardID?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGiftCardOrderStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGiftCardOrderStatus(userGiftCardID, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Webhook endpoint for Karta OTP Notifications
         * @param {string} [apiVersion] 
         * @param {IAPOTPNotification} [iAPOTPNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iAPOTPNotification(apiVersion?: string, iAPOTPNotification?: IAPOTPNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iAPOTPNotification(apiVersion, iAPOTPNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary check whether the card is already activated
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isCardAlreadyActivated(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isCardAlreadyActivated(userGiftCardID, token, itemId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the Pin of an existing UserGiftCard belonging to the currentUser
         * @param {number} userGiftCardID 
         * @param {string} [token] the activation token
         * @param {string} [apiVersion] 
         * @param {SetPin} [setPin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPin(userGiftCardID: number, token?: string, apiVersion?: string, setPin?: SetPin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPin(userGiftCardID, token, apiVersion, setPin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the currentUsers Pin for the specified card via Karta Api.
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {SetPin} [setPin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePin(userGiftCardId: number, apiVersion?: string, setPin?: SetPin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePin(userGiftCardId, apiVersion, setPin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary validate the activation token of the gift card
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateActivationToken(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGiftCardActivationValidationVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateActivationToken(userGiftCardID, token, itemId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verify the activation token of the gift card
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyActivationToken(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyActivationToken(userGiftCardID, token, itemId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftCardWorkflowApi - factory interface
 * @export
 */
export const GiftCardWorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftCardWorkflowApiFp(configuration)
    return {
        /**
         * 
         * @summary Manages creation of a new Card in Karta and it\'s corresponding UserGiftCard in Special DB
         * @param {string} [apiVersion] 
         * @param {NewUserGiftCardWorkflow} [newUserGiftCardWorkflow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGiftCard(apiVersion?: string, newUserGiftCardWorkflow?: NewUserGiftCardWorkflow, options?: any): AxiosPromise<number> {
            return localVarFp.createGiftCard(apiVersion, newUserGiftCardWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the userGiftCard details for the activation flow
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationUserGiftCardDetails(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: any): AxiosPromise<UserGiftCardActivationDetailsVM> {
            return localVarFp.getActivationUserGiftCardDetails(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User GiftCard Order Status for given card id
         * @param {number} [userGiftCardID] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGiftCardOrderStatus(userGiftCardID?: number, apiVersion?: string, options?: any): AxiosPromise<UserGiftCardOrderStatus> {
            return localVarFp.getUserGiftCardOrderStatus(userGiftCardID, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Webhook endpoint for Karta OTP Notifications
         * @param {string} [apiVersion] 
         * @param {IAPOTPNotification} [iAPOTPNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iAPOTPNotification(apiVersion?: string, iAPOTPNotification?: IAPOTPNotification, options?: any): AxiosPromise<IResponse> {
            return localVarFp.iAPOTPNotification(apiVersion, iAPOTPNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary check whether the card is already activated
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCardAlreadyActivated(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isCardAlreadyActivated(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the Pin of an existing UserGiftCard belonging to the currentUser
         * @param {number} userGiftCardID 
         * @param {string} [token] the activation token
         * @param {string} [apiVersion] 
         * @param {SetPin} [setPin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPin(userGiftCardID: number, token?: string, apiVersion?: string, setPin?: SetPin, options?: any): AxiosPromise<IResponse> {
            return localVarFp.setPin(userGiftCardID, token, apiVersion, setPin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the currentUsers Pin for the specified card via Karta Api.
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {SetPin} [setPin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePin(userGiftCardId: number, apiVersion?: string, setPin?: SetPin, options?: any): AxiosPromise<IResponse> {
            return localVarFp.updatePin(userGiftCardId, apiVersion, setPin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validate the activation token of the gift card
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateActivationToken(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: any): AxiosPromise<UserGiftCardActivationValidationVM> {
            return localVarFp.validateActivationToken(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verify the activation token of the gift card
         * @param {number} [userGiftCardID] 
         * @param {string} [token] the activation token
         * @param {string} [itemId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyActivationToken(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.verifyActivationToken(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GiftCardWorkflowApi - object-oriented interface
 * @export
 * @class GiftCardWorkflowApi
 * @extends {BaseAPI}
 */
export class GiftCardWorkflowApi extends BaseAPI {
    /**
     * 
     * @summary Manages creation of a new Card in Karta and it\'s corresponding UserGiftCard in Special DB
     * @param {string} [apiVersion] 
     * @param {NewUserGiftCardWorkflow} [newUserGiftCardWorkflow] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public createGiftCard(apiVersion?: string, newUserGiftCardWorkflow?: NewUserGiftCardWorkflow, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).createGiftCard(apiVersion, newUserGiftCardWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the userGiftCard details for the activation flow
     * @param {number} [userGiftCardID] 
     * @param {string} [token] the activation token
     * @param {string} [itemId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public getActivationUserGiftCardDetails(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).getActivationUserGiftCardDetails(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User GiftCard Order Status for given card id
     * @param {number} [userGiftCardID] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public getUserGiftCardOrderStatus(userGiftCardID?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).getUserGiftCardOrderStatus(userGiftCardID, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Webhook endpoint for Karta OTP Notifications
     * @param {string} [apiVersion] 
     * @param {IAPOTPNotification} [iAPOTPNotification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public iAPOTPNotification(apiVersion?: string, iAPOTPNotification?: IAPOTPNotification, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).iAPOTPNotification(apiVersion, iAPOTPNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary check whether the card is already activated
     * @param {number} [userGiftCardID] 
     * @param {string} [token] the activation token
     * @param {string} [itemId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public isCardAlreadyActivated(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).isCardAlreadyActivated(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the Pin of an existing UserGiftCard belonging to the currentUser
     * @param {number} userGiftCardID 
     * @param {string} [token] the activation token
     * @param {string} [apiVersion] 
     * @param {SetPin} [setPin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public setPin(userGiftCardID: number, token?: string, apiVersion?: string, setPin?: SetPin, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).setPin(userGiftCardID, token, apiVersion, setPin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the currentUsers Pin for the specified card via Karta Api.
     * @param {number} userGiftCardId 
     * @param {string} [apiVersion] 
     * @param {SetPin} [setPin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public updatePin(userGiftCardId: number, apiVersion?: string, setPin?: SetPin, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).updatePin(userGiftCardId, apiVersion, setPin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validate the activation token of the gift card
     * @param {number} [userGiftCardID] 
     * @param {string} [token] the activation token
     * @param {string} [itemId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public validateActivationToken(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).validateActivationToken(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verify the activation token of the gift card
     * @param {number} [userGiftCardID] 
     * @param {string} [token] the activation token
     * @param {string} [itemId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardWorkflowApi
     */
    public verifyActivationToken(userGiftCardID?: number, token?: string, itemId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardWorkflowApiFp(this.configuration).verifyActivationToken(userGiftCardID, token, itemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ping
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ping
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckApiFp(configuration)
    return {
        /**
         * 
         * @summary Ping
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ping(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * 
     * @summary Ping
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public ping(apiVersion?: string, options?: AxiosRequestConfig) {
        return HealthCheckApiFp(this.configuration).ping(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of FeaturedMerchantVM
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedMerchants: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Home/GetFeaturedMerchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a list of FeaturedMerchantVM
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturedMerchants(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeaturedMerchantVM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturedMerchants(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a list of FeaturedMerchantVM
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedMerchants(apiVersion?: string, options?: any): AxiosPromise<Array<FeaturedMerchantVM>> {
            return localVarFp.getFeaturedMerchants(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary Returns a list of FeaturedMerchantVM
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getFeaturedMerchants(apiVersion?: string, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).getFeaturedMerchants(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MerchantApi - axios parameter creator
 * @export
 */
export const MerchantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a MerchantDetailsVM for the specified MerchantId
         * @param {number} merchantId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchant: async (merchantId: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            assertParamExists('getMerchant', 'merchantId', merchantId)
            const localVarPath = `/Merchant/{merchantId}`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a Paged List of type MerchantLocationVM
         * @param {number} [merchantId] 
         * @param {string} [state] 
         * @param {MerchantLocationSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantLocations: async (merchantId?: number, state?: string, sortBy?: MerchantLocationSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Merchant/GetMerchantLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PagedList of type MerchantVM using the filter provided
         * @param {MerchantSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {number} [programId] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchants: async (sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a Paged List of type MerchantVM where Merchants have GiftCardPrograms associated with the specified GiftCard
         * @param {string} slug 
         * @param {MerchantSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {number} [programId] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantsForGiftCard: async (slug: string, sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getMerchantsForGiftCard', 'slug', slug)
            const localVarPath = `/Merchant/GiftCard/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantApi - functional programming interface
 * @export
 */
export const MerchantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MerchantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a MerchantDetailsVM for the specified MerchantId
         * @param {number} merchantId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchant(merchantId: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchant(merchantId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a Paged List of type MerchantLocationVM
         * @param {number} [merchantId] 
         * @param {string} [state] 
         * @param {MerchantLocationSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchantLocations(merchantId?: number, state?: string, sortBy?: MerchantLocationSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantLocationVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchantLocations(merchantId, state, sortBy, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PagedList of type MerchantVM using the filter provided
         * @param {MerchantSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {number} [programId] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchants(sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchants(sortBy, sortByDesc, programId, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a Paged List of type MerchantVM where Merchants have GiftCardPrograms associated with the specified GiftCard
         * @param {string} slug 
         * @param {MerchantSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {number} [programId] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchantsForGiftCard(slug: string, sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchantsForGiftCard(slug, sortBy, sortByDesc, programId, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MerchantApi - factory interface
 * @export
 */
export const MerchantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MerchantApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a MerchantDetailsVM for the specified MerchantId
         * @param {number} merchantId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchant(merchantId: number, apiVersion?: string, options?: any): AxiosPromise<MerchantDetailsVM> {
            return localVarFp.getMerchant(merchantId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a Paged List of type MerchantLocationVM
         * @param {number} [merchantId] 
         * @param {string} [state] 
         * @param {MerchantLocationSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantLocations(merchantId?: number, state?: string, sortBy?: MerchantLocationSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<MerchantLocationVMIPagedList> {
            return localVarFp.getMerchantLocations(merchantId, state, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PagedList of type MerchantVM using the filter provided
         * @param {MerchantSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {number} [programId] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchants(sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<MerchantVMIPagedList> {
            return localVarFp.getMerchants(sortBy, sortByDesc, programId, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a Paged List of type MerchantVM where Merchants have GiftCardPrograms associated with the specified GiftCard
         * @param {string} slug 
         * @param {MerchantSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {number} [programId] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantsForGiftCard(slug: string, sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<MerchantVMIPagedList> {
            return localVarFp.getMerchantsForGiftCard(slug, sortBy, sortByDesc, programId, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantApi - object-oriented interface
 * @export
 * @class MerchantApi
 * @extends {BaseAPI}
 */
export class MerchantApi extends BaseAPI {
    /**
     * 
     * @summary Returns a MerchantDetailsVM for the specified MerchantId
     * @param {number} merchantId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public getMerchant(merchantId: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).getMerchant(merchantId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a Paged List of type MerchantLocationVM
     * @param {number} [merchantId] 
     * @param {string} [state] 
     * @param {MerchantLocationSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public getMerchantLocations(merchantId?: number, state?: string, sortBy?: MerchantLocationSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).getMerchantLocations(merchantId, state, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PagedList of type MerchantVM using the filter provided
     * @param {MerchantSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {number} [programId] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public getMerchants(sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).getMerchants(sortBy, sortByDesc, programId, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a Paged List of type MerchantVM where Merchants have GiftCardPrograms associated with the specified GiftCard
     * @param {string} slug 
     * @param {MerchantSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {number} [programId] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public getMerchantsForGiftCard(slug: string, sortBy?: MerchantSortBy, sortByDesc?: boolean, programId?: number, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).getMerchantsForGiftCard(slug, sortBy, sortByDesc, programId, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a credit card payment method intent
         * @param {number} [giftCardId] 
         * @param {number} [programProfileId] 
         * @param {number} [amount] 
         * @param {string} [channel] 
         * @param {string} [sessionId] 
         * @param {string} [attemptId] 
         * @param {string} [userAgent] 
         * @param {string} [promoCode] 
         * @param {string} [recipientEmail] 
         * @param {string} [recipientFullname] 
         * @param {string} [recipientPhoneNumber] 
         * @param {string} [paymentProvider] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentIntent: async (giftCardId?: number, programProfileId?: number, amount?: number, channel?: string, sessionId?: string, attemptId?: string, userAgent?: string, promoCode?: string, recipientEmail?: string, recipientFullname?: string, recipientPhoneNumber?: string, paymentProvider?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payment/CreatePaymentIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftCardId !== undefined) {
                localVarQueryParameter['giftCardId'] = giftCardId;
            }

            if (programProfileId !== undefined) {
                localVarQueryParameter['programProfileId'] = programProfileId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }

            if (attemptId !== undefined) {
                localVarQueryParameter['attemptId'] = attemptId;
            }

            if (userAgent !== undefined) {
                localVarQueryParameter['userAgent'] = userAgent;
            }

            if (promoCode !== undefined) {
                localVarQueryParameter['promoCode'] = promoCode;
            }

            if (recipientEmail !== undefined) {
                localVarQueryParameter['recipientEmail'] = recipientEmail;
            }

            if (recipientFullname !== undefined) {
                localVarQueryParameter['recipientFullname'] = recipientFullname;
            }

            if (recipientPhoneNumber !== undefined) {
                localVarQueryParameter['recipientPhoneNumber'] = recipientPhoneNumber;
            }

            if (paymentProvider !== undefined) {
                localVarQueryParameter['paymentProvider'] = paymentProvider;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a credit card payment method intent
         * @param {number} [userGiftCardId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentReceiptUrl: async (userGiftCardId?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payment/GetPaymentReceiptUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGiftCardId !== undefined) {
                localVarQueryParameter['userGiftCardId'] = userGiftCardId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a credit card payment method intent
         * @param {number} [giftCardId] 
         * @param {number} [programProfileId] 
         * @param {number} [amount] 
         * @param {string} [channel] 
         * @param {string} [sessionId] 
         * @param {string} [attemptId] 
         * @param {string} [userAgent] 
         * @param {string} [promoCode] 
         * @param {string} [recipientEmail] 
         * @param {string} [recipientFullname] 
         * @param {string} [recipientPhoneNumber] 
         * @param {string} [paymentProvider] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPaymentIntent(giftCardId?: number, programProfileId?: number, amount?: number, channel?: string, sessionId?: string, attemptId?: string, userAgent?: string, promoCode?: string, recipientEmail?: string, recipientFullname?: string, recipientPhoneNumber?: string, paymentProvider?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntentVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPaymentIntent(giftCardId, programProfileId, amount, channel, sessionId, attemptId, userAgent, promoCode, recipientEmail, recipientFullname, recipientPhoneNumber, paymentProvider, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a credit card payment method intent
         * @param {number} [userGiftCardId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentReceiptUrl(userGiftCardId?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentReceiptUrl(userGiftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a credit card payment method intent
         * @param {number} [giftCardId] 
         * @param {number} [programProfileId] 
         * @param {number} [amount] 
         * @param {string} [channel] 
         * @param {string} [sessionId] 
         * @param {string} [attemptId] 
         * @param {string} [userAgent] 
         * @param {string} [promoCode] 
         * @param {string} [recipientEmail] 
         * @param {string} [recipientFullname] 
         * @param {string} [recipientPhoneNumber] 
         * @param {string} [paymentProvider] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentIntent(giftCardId?: number, programProfileId?: number, amount?: number, channel?: string, sessionId?: string, attemptId?: string, userAgent?: string, promoCode?: string, recipientEmail?: string, recipientFullname?: string, recipientPhoneNumber?: string, paymentProvider?: string, apiVersion?: string, options?: any): AxiosPromise<PaymentIntentVM> {
            return localVarFp.createPaymentIntent(giftCardId, programProfileId, amount, channel, sessionId, attemptId, userAgent, promoCode, recipientEmail, recipientFullname, recipientPhoneNumber, paymentProvider, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a credit card payment method intent
         * @param {number} [userGiftCardId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentReceiptUrl(userGiftCardId?: number, apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPaymentReceiptUrl(userGiftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Create a credit card payment method intent
     * @param {number} [giftCardId] 
     * @param {number} [programProfileId] 
     * @param {number} [amount] 
     * @param {string} [channel] 
     * @param {string} [sessionId] 
     * @param {string} [attemptId] 
     * @param {string} [userAgent] 
     * @param {string} [promoCode] 
     * @param {string} [recipientEmail] 
     * @param {string} [recipientFullname] 
     * @param {string} [recipientPhoneNumber] 
     * @param {string} [paymentProvider] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public createPaymentIntent(giftCardId?: number, programProfileId?: number, amount?: number, channel?: string, sessionId?: string, attemptId?: string, userAgent?: string, promoCode?: string, recipientEmail?: string, recipientFullname?: string, recipientPhoneNumber?: string, paymentProvider?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).createPaymentIntent(giftCardId, programProfileId, amount, channel, sessionId, attemptId, userAgent, promoCode, recipientEmail, recipientFullname, recipientPhoneNumber, paymentProvider, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a credit card payment method intent
     * @param {number} [userGiftCardId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getPaymentReceiptUrl(userGiftCardId?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getPaymentReceiptUrl(userGiftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionApi - axios parameter creator
 * @export
 */
export const PromotionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
         * @param {string} [promoCode] 
         * @param {number} [giftCardId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePromoCode: async (promoCode?: string, giftCardId?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (promoCode !== undefined) {
                localVarQueryParameter['promoCode'] = promoCode;
            }

            if (giftCardId !== undefined) {
                localVarQueryParameter['giftCardId'] = giftCardId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionApi - functional programming interface
 * @export
 */
export const PromotionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
         * @param {string} [promoCode] 
         * @param {number} [giftCardId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePromoCode(promoCode?: string, giftCardId?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionCodeVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePromoCode(promoCode, giftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionApi - factory interface
 * @export
 */
export const PromotionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
         * @param {string} [promoCode] 
         * @param {number} [giftCardId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePromoCode(promoCode?: string, giftCardId?: number, apiVersion?: string, options?: any): AxiosPromise<PromotionCodeVM> {
            return localVarFp.validatePromoCode(promoCode, giftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionApi - object-oriented interface
 * @export
 * @class PromotionApi
 * @extends {BaseAPI}
 */
export class PromotionApi extends BaseAPI {
    /**
     * 
     * @summary Returns a GiftCardDetailsVM for the specified userGiftCardId
     * @param {string} [promoCode] 
     * @param {number} [giftCardId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public validatePromoCode(promoCode?: string, giftCardId?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).validatePromoCode(promoCode, giftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecialCategoryApi - axios parameter creator
 * @export
 */
export const SpecialCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a PagedList of type SpecialCategoryVM using the filter provided
         * @param {SpecialCategorySortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialCategories: async (sortBy?: SpecialCategorySortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SpecialCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a SpecialCategoryDetailsVM for the specified specialCategoryId
         * @param {number} specialCategoryId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialCategory: async (specialCategoryId: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'specialCategoryId' is not null or undefined
            assertParamExists('getSpecialCategory', 'specialCategoryId', specialCategoryId)
            const localVarPath = `/SpecialCategory/{specialCategoryId}`
                .replace(`{${"specialCategoryId"}}`, encodeURIComponent(String(specialCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialCategoryApi - functional programming interface
 * @export
 */
export const SpecialCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a PagedList of type SpecialCategoryVM using the filter provided
         * @param {SpecialCategorySortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialCategories(sortBy?: SpecialCategorySortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecialCategoryVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialCategories(sortBy, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a SpecialCategoryDetailsVM for the specified specialCategoryId
         * @param {number} specialCategoryId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialCategory(specialCategoryId: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecialCategoryDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialCategory(specialCategoryId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecialCategoryApi - factory interface
 * @export
 */
export const SpecialCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialCategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a PagedList of type SpecialCategoryVM using the filter provided
         * @param {SpecialCategorySortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialCategories(sortBy?: SpecialCategorySortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<SpecialCategoryVMIPagedList> {
            return localVarFp.getSpecialCategories(sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a SpecialCategoryDetailsVM for the specified specialCategoryId
         * @param {number} specialCategoryId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialCategory(specialCategoryId: number, apiVersion?: string, options?: any): AxiosPromise<SpecialCategoryDetailsVM> {
            return localVarFp.getSpecialCategory(specialCategoryId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecialCategoryApi - object-oriented interface
 * @export
 * @class SpecialCategoryApi
 * @extends {BaseAPI}
 */
export class SpecialCategoryApi extends BaseAPI {
    /**
     * 
     * @summary Returns a PagedList of type SpecialCategoryVM using the filter provided
     * @param {SpecialCategorySortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialCategoryApi
     */
    public getSpecialCategories(sortBy?: SpecialCategorySortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return SpecialCategoryApiFp(this.configuration).getSpecialCategories(sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a SpecialCategoryDetailsVM for the specified specialCategoryId
     * @param {number} specialCategoryId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialCategoryApi
     */
    public getSpecialCategory(specialCategoryId: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return SpecialCategoryApiFp(this.configuration).getSpecialCategory(specialCategoryId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecialTimezoneApi - axios parameter creator
 * @export
 */
export const SpecialTimezoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of active timezones
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimezones: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SpecialTimezone/Timezones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialTimezoneApi - functional programming interface
 * @export
 */
export const SpecialTimezoneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialTimezoneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a list of active timezones
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimezones(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialTimezoneVM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimezones(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecialTimezoneApi - factory interface
 * @export
 */
export const SpecialTimezoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialTimezoneApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a list of active timezones
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimezones(apiVersion?: string, options?: any): AxiosPromise<Array<SpecialTimezoneVM>> {
            return localVarFp.getTimezones(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecialTimezoneApi - object-oriented interface
 * @export
 * @class SpecialTimezoneApi
 * @extends {BaseAPI}
 */
export class SpecialTimezoneApi extends BaseAPI {
    /**
     * 
     * @summary Returns a list of active timezones
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialTimezoneApi
     */
    public getTimezones(apiVersion?: string, options?: AxiosRequestConfig) {
        return SpecialTimezoneApiFp(this.configuration).getTimezones(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the UserDetails of the current session
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/UserDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint returns true if the specified emailAddress is already registered in the database
         * @param {string} emailAddress 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailAddressTaken: async (emailAddress: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('isEmailAddressTaken', 'emailAddress', emailAddress)
            const localVarPath = `/User/IsEmailAddressTaken/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint will send card information to the service provider when provisioning a card to Apple Pay wallet
         * @param {string} userId 
         * @param {number} cardId 
         * @param {string} [apiVersion] 
         * @param {NewApplePayProvision} [newApplePayProvision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApplePayProvision: async (userId: string, cardId: number, apiVersion?: string, newApplePayProvision?: NewApplePayProvision, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postApplePayProvision', 'userId', userId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('postApplePayProvision', 'cardId', cardId)
            const localVarPath = `/User/PayProvision/Apple/{userId}/{cardId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newApplePayProvision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint will send card information to the service provider when provisioning a card to Google Pay wallet
         * @param {string} userId 
         * @param {number} cardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGooglePayProvision: async (userId: string, cardId: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postGooglePayProvision', 'userId', userId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('postGooglePayProvision', 'cardId', cardId)
            const localVarPath = `/User/PayProvision/Google/{userId}/{cardId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint return user status and if doesn\'t exist create one
         * @param {string} [apiVersion] 
         * @param {CognitoUser} [cognitoUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignIn: async (apiVersion?: string, cognitoUser?: CognitoUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/PostSignIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cognitoUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint creates a new User for self-registering members
         * @param {string} [apiVersion] 
         * @param {RegisterNewUser} [registerNewUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser: async (apiVersion?: string, registerNewUser?: RegisterNewUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerNewUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers the current users phone number
         * @param {string} phoneNumber 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPhoneNumber: async (phoneNumber: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('registerPhoneNumber', 'phoneNumber', phoneNumber)
            const localVarPath = `/User/RegisterPhone/{phoneNumber}`
                .replace(`{${"phoneNumber"}}`, encodeURIComponent(String(phoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets an active User to be in the PendingDeletion state
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {NewDeletionRequest} [newDeletionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUserDeletion: async (userId: string, apiVersion?: string, newDeletionRequest?: NewDeletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('requestUserDeletion', 'userId', userId)
            const localVarPath = `/User/RequestDeletion/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDeletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the User Email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: async (email: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('updateEmail', 'email', email)
            const localVarPath = `/User/Email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user\'s last logged in date
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastLoggedInDate: async (userId: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateLastLoggedInDate', 'userId', userId)
            const localVarPath = `/User/UpdateLastLoggedInDate/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the current users phone number
         * @param {string} phoneNumber 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneNumber: async (phoneNumber: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('updatePhoneNumber', 'phoneNumber', phoneNumber)
            const localVarPath = `/User/Phone/{phoneNumber}`
                .replace(`{${"phoneNumber"}}`, encodeURIComponent(String(phoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the User name details
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {EditUser} [editUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, apiVersion?: string, editUser?: EditUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            const localVarPath = `/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the UserDetails of the current session
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetails(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsVM | AdminSpecialUserDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetails(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint returns true if the specified emailAddress is already registered in the database
         * @param {string} emailAddress 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isEmailAddressTaken(emailAddress: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isEmailAddressTaken(emailAddress, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint will send card information to the service provider when provisioning a card to Apple Pay wallet
         * @param {string} userId 
         * @param {number} cardId 
         * @param {string} [apiVersion] 
         * @param {NewApplePayProvision} [newApplePayProvision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApplePayProvision(userId: string, cardId: number, apiVersion?: string, newApplePayProvision?: NewApplePayProvision, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplePayProvisionResponseIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApplePayProvision(userId, cardId, apiVersion, newApplePayProvision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint will send card information to the service provider when provisioning a card to Google Pay wallet
         * @param {string} userId 
         * @param {number} cardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postGooglePayProvision(userId: string, cardId: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GooglePayProvisionResponseIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postGooglePayProvision(userId, cardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint return user status and if doesn\'t exist create one
         * @param {string} [apiVersion] 
         * @param {CognitoUser} [cognitoUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignIn(apiVersion?: string, cognitoUser?: CognitoUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsVM | AdminSpecialUserDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignIn(apiVersion, cognitoUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint creates a new User for self-registering members
         * @param {string} [apiVersion] 
         * @param {RegisterNewUser} [registerNewUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerNewUser(apiVersion?: string, registerNewUser?: RegisterNewUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerNewUser(apiVersion, registerNewUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers the current users phone number
         * @param {string} phoneNumber 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPhoneNumber(phoneNumber: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPhoneNumber(phoneNumber, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets an active User to be in the PendingDeletion state
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {NewDeletionRequest} [newDeletionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestUserDeletion(userId: string, apiVersion?: string, newDeletionRequest?: NewDeletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestUserDeletion(userId, apiVersion, newDeletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the User Email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmail(email: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(email, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user\'s last logged in date
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLastLoggedInDate(userId: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLastLoggedInDate(userId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the current users phone number
         * @param {string} phoneNumber 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhoneNumber(phoneNumber: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhoneNumber(phoneNumber, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the User name details
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {EditUser} [editUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, apiVersion?: string, editUser?: EditUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, apiVersion, editUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the UserDetails of the current session
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails(apiVersion?: string, options?: any): AxiosPromise<UserDetailsVM | AdminSpecialUserDetailsVM> {
            return localVarFp.getUserDetails(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint returns true if the specified emailAddress is already registered in the database
         * @param {string} emailAddress 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailAddressTaken(emailAddress: string, apiVersion?: string, options?: any): AxiosPromise<BooleanIResponse> {
            return localVarFp.isEmailAddressTaken(emailAddress, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint will send card information to the service provider when provisioning a card to Apple Pay wallet
         * @param {string} userId 
         * @param {number} cardId 
         * @param {string} [apiVersion] 
         * @param {NewApplePayProvision} [newApplePayProvision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApplePayProvision(userId: string, cardId: number, apiVersion?: string, newApplePayProvision?: NewApplePayProvision, options?: any): AxiosPromise<ApplePayProvisionResponseIResponse> {
            return localVarFp.postApplePayProvision(userId, cardId, apiVersion, newApplePayProvision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint will send card information to the service provider when provisioning a card to Google Pay wallet
         * @param {string} userId 
         * @param {number} cardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGooglePayProvision(userId: string, cardId: number, apiVersion?: string, options?: any): AxiosPromise<GooglePayProvisionResponseIResponse> {
            return localVarFp.postGooglePayProvision(userId, cardId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint return user status and if doesn\'t exist create one
         * @param {string} [apiVersion] 
         * @param {CognitoUser} [cognitoUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignIn(apiVersion?: string, cognitoUser?: CognitoUser, options?: any): AxiosPromise<UserDetailsVM | AdminSpecialUserDetailsVM> {
            return localVarFp.postSignIn(apiVersion, cognitoUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint creates a new User for self-registering members
         * @param {string} [apiVersion] 
         * @param {RegisterNewUser} [registerNewUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser(apiVersion?: string, registerNewUser?: RegisterNewUser, options?: any): AxiosPromise<string> {
            return localVarFp.registerNewUser(apiVersion, registerNewUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers the current users phone number
         * @param {string} phoneNumber 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPhoneNumber(phoneNumber: string, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.registerPhoneNumber(phoneNumber, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets an active User to be in the PendingDeletion state
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {NewDeletionRequest} [newDeletionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUserDeletion(userId: string, apiVersion?: string, newDeletionRequest?: NewDeletionRequest, options?: any): AxiosPromise<IResponse> {
            return localVarFp.requestUserDeletion(userId, apiVersion, newDeletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the User Email
         * @param {string} email 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(email: string, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.updateEmail(email, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user\'s last logged in date
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastLoggedInDate(userId: string, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.updateLastLoggedInDate(userId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the current users phone number
         * @param {string} phoneNumber 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneNumber(phoneNumber: string, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.updatePhoneNumber(phoneNumber, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the User name details
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {EditUser} [editUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, apiVersion?: string, editUser?: EditUser, options?: any): AxiosPromise<IResponse> {
            return localVarFp.updateUser(userId, apiVersion, editUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Returns the UserDetails of the current session
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserDetails(apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserDetails(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint returns true if the specified emailAddress is already registered in the database
     * @param {string} emailAddress 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public isEmailAddressTaken(emailAddress: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).isEmailAddressTaken(emailAddress, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint will send card information to the service provider when provisioning a card to Apple Pay wallet
     * @param {string} userId 
     * @param {number} cardId 
     * @param {string} [apiVersion] 
     * @param {NewApplePayProvision} [newApplePayProvision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApplePayProvision(userId: string, cardId: number, apiVersion?: string, newApplePayProvision?: NewApplePayProvision, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApplePayProvision(userId, cardId, apiVersion, newApplePayProvision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint will send card information to the service provider when provisioning a card to Google Pay wallet
     * @param {string} userId 
     * @param {number} cardId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postGooglePayProvision(userId: string, cardId: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postGooglePayProvision(userId, cardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint return user status and if doesn\'t exist create one
     * @param {string} [apiVersion] 
     * @param {CognitoUser} [cognitoUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postSignIn(apiVersion?: string, cognitoUser?: CognitoUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postSignIn(apiVersion, cognitoUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint creates a new User for self-registering members
     * @param {string} [apiVersion] 
     * @param {RegisterNewUser} [registerNewUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerNewUser(apiVersion?: string, registerNewUser?: RegisterNewUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerNewUser(apiVersion, registerNewUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers the current users phone number
     * @param {string} phoneNumber 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerPhoneNumber(phoneNumber: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerPhoneNumber(phoneNumber, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets an active User to be in the PendingDeletion state
     * @param {string} userId 
     * @param {string} [apiVersion] 
     * @param {NewDeletionRequest} [newDeletionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public requestUserDeletion(userId: string, apiVersion?: string, newDeletionRequest?: NewDeletionRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).requestUserDeletion(userId, apiVersion, newDeletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the User Email
     * @param {string} email 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateEmail(email: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateEmail(email, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user\'s last logged in date
     * @param {string} userId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateLastLoggedInDate(userId: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateLastLoggedInDate(userId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the current users phone number
     * @param {string} phoneNumber 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePhoneNumber(phoneNumber: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updatePhoneNumber(phoneNumber, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the User name details
     * @param {string} userId 
     * @param {string} [apiVersion] 
     * @param {EditUser} [editUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userId: string, apiVersion?: string, editUser?: EditUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(userId, apiVersion, editUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserGiftCardApi - axios parameter creator
 * @export
 */
export const UserGiftCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change status of a card
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {CardStatusInfo} [cardStatusInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCardStatus: async (userGiftCardId: number, apiVersion?: string, cardStatusInfo?: CardStatusInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('changeCardStatus', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/UserGiftCard/ChangeCardStatus/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardStatusInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a card associated to an account
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeCardAccount: async (userGiftCardId: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('closeCardAccount', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/UserGiftCard/CloseCardAccount/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing UserGiftCardProvisioning entity for the specified UserGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProvisioningDetails: async (userGiftCardId: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('deleteProvisioningDetails', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/UserGiftCard/Provisioning/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a UserGiftCardDetailsVM for the specified userGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGiftCard: async (userGiftCardId: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('getUserGiftCard', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/UserGiftCard/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PagedList of type UserGiftCardVM for the currentUser using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGiftCards: async (startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (cardTypes) {
                localVarQueryParameter['cardTypes'] = cardTypes;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myActiveOrders: async (startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserGiftCard/MyActiveOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (cardTypes) {
                localVarQueryParameter['cardTypes'] = cardTypes;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myOrders: async (startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserGiftCard/MyOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (cardTypes) {
                localVarQueryParameter['cardTypes'] = cardTypes;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPastOrders: async (startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserGiftCard/MyPastOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (cardTypes) {
                localVarQueryParameter['cardTypes'] = cardTypes;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDesc !== undefined) {
                localVarQueryParameter['sortByDesc'] = sortByDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds or Updates a UserGiftCardProvisioning entity for the specified UserGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {ProvisionData} [provisionData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProvisioningDetails: async (userGiftCardId: number, apiVersion?: string, provisionData?: ProvisionData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGiftCardId' is not null or undefined
            assertParamExists('saveProvisioningDetails', 'userGiftCardId', userGiftCardId)
            const localVarPath = `/UserGiftCard/Provisioning/{userGiftCardId}`
                .replace(`{${"userGiftCardId"}}`, encodeURIComponent(String(userGiftCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(provisionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGiftCardApi - functional programming interface
 * @export
 */
export const UserGiftCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGiftCardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change status of a card
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {CardStatusInfo} [cardStatusInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCardStatus(userGiftCardId: number, apiVersion?: string, cardStatusInfo?: CardStatusInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCardStatus(userGiftCardId, apiVersion, cardStatusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a card associated to an account
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeCardAccount(userGiftCardId: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeCardAccount(userGiftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing UserGiftCardProvisioning entity for the specified UserGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProvisioningDetails(userGiftCardId: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProvisioningDetails(userGiftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a UserGiftCardDetailsVM for the specified userGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGiftCard(userGiftCardId: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGiftCardDetailsVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGiftCard(userGiftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PagedList of type UserGiftCardVM for the currentUser using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGiftCards(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGiftCardVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGiftCards(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myActiveOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myActiveOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPastOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderVMIPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPastOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds or Updates a UserGiftCardProvisioning entity for the specified UserGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {ProvisionData} [provisionData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProvisioningDetails(userGiftCardId: number, apiVersion?: string, provisionData?: ProvisionData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProvisioningDetails(userGiftCardId, apiVersion, provisionData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserGiftCardApi - factory interface
 * @export
 */
export const UserGiftCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGiftCardApiFp(configuration)
    return {
        /**
         * 
         * @summary Change status of a card
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {CardStatusInfo} [cardStatusInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCardStatus(userGiftCardId: number, apiVersion?: string, cardStatusInfo?: CardStatusInfo, options?: any): AxiosPromise<IResponse> {
            return localVarFp.changeCardStatus(userGiftCardId, apiVersion, cardStatusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a card associated to an account
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeCardAccount(userGiftCardId: number, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.closeCardAccount(userGiftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing UserGiftCardProvisioning entity for the specified UserGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProvisioningDetails(userGiftCardId: number, apiVersion?: string, options?: any): AxiosPromise<IResponse> {
            return localVarFp.deleteProvisioningDetails(userGiftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a UserGiftCardDetailsVM for the specified userGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGiftCard(userGiftCardId: number, apiVersion?: string, options?: any): AxiosPromise<UserGiftCardDetailsVM> {
            return localVarFp.getUserGiftCard(userGiftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PagedList of type UserGiftCardVM for the currentUser using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGiftCards(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<UserGiftCardVMIPagedList> {
            return localVarFp.getUserGiftCards(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myActiveOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<OrderVMIPagedList> {
            return localVarFp.myActiveOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<OrderVMIPagedList> {
            return localVarFp.myOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<UserGiftCardStatus>} [statuses] 
         * @param {Array<UserGiftCardType>} [cardTypes] 
         * @param {UserGiftCardSortBy} [sortBy] 
         * @param {boolean} [sortByDesc] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPastOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: any): AxiosPromise<OrderVMIPagedList> {
            return localVarFp.myPastOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds or Updates a UserGiftCardProvisioning entity for the specified UserGiftCardId
         * @param {number} userGiftCardId 
         * @param {string} [apiVersion] 
         * @param {ProvisionData} [provisionData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProvisioningDetails(userGiftCardId: number, apiVersion?: string, provisionData?: ProvisionData, options?: any): AxiosPromise<IResponse> {
            return localVarFp.saveProvisioningDetails(userGiftCardId, apiVersion, provisionData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGiftCardApi - object-oriented interface
 * @export
 * @class UserGiftCardApi
 * @extends {BaseAPI}
 */
export class UserGiftCardApi extends BaseAPI {
    /**
     * 
     * @summary Change status of a card
     * @param {number} userGiftCardId 
     * @param {string} [apiVersion] 
     * @param {CardStatusInfo} [cardStatusInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public changeCardStatus(userGiftCardId: number, apiVersion?: string, cardStatusInfo?: CardStatusInfo, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).changeCardStatus(userGiftCardId, apiVersion, cardStatusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a card associated to an account
     * @param {number} userGiftCardId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public closeCardAccount(userGiftCardId: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).closeCardAccount(userGiftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing UserGiftCardProvisioning entity for the specified UserGiftCardId
     * @param {number} userGiftCardId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public deleteProvisioningDetails(userGiftCardId: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).deleteProvisioningDetails(userGiftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a UserGiftCardDetailsVM for the specified userGiftCardId
     * @param {number} userGiftCardId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public getUserGiftCard(userGiftCardId: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).getUserGiftCard(userGiftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PagedList of type UserGiftCardVM for the currentUser using the filter provided
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<UserGiftCardStatus>} [statuses] 
     * @param {Array<UserGiftCardType>} [cardTypes] 
     * @param {UserGiftCardSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public getUserGiftCards(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).getUserGiftCards(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<UserGiftCardStatus>} [statuses] 
     * @param {Array<UserGiftCardType>} [cardTypes] 
     * @param {UserGiftCardSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public myActiveOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).myActiveOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<UserGiftCardStatus>} [statuses] 
     * @param {Array<UserGiftCardType>} [cardTypes] 
     * @param {UserGiftCardSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public myOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).myOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PagedList of type OrderVM for UserGiftCards ordered by the currentUser and using the filter provided
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<UserGiftCardStatus>} [statuses] 
     * @param {Array<UserGiftCardType>} [cardTypes] 
     * @param {UserGiftCardSortBy} [sortBy] 
     * @param {boolean} [sortByDesc] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public myPastOrders(startDate?: string, endDate?: string, statuses?: Array<UserGiftCardStatus>, cardTypes?: Array<UserGiftCardType>, sortBy?: UserGiftCardSortBy, sortByDesc?: boolean, search?: string, take?: number, skip?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).myPastOrders(startDate, endDate, statuses, cardTypes, sortBy, sortByDesc, search, take, skip, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds or Updates a UserGiftCardProvisioning entity for the specified UserGiftCardId
     * @param {number} userGiftCardId 
     * @param {string} [apiVersion] 
     * @param {ProvisionData} [provisionData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGiftCardApi
     */
    public saveProvisioningDetails(userGiftCardId: number, apiVersion?: string, provisionData?: ProvisionData, options?: AxiosRequestConfig) {
        return UserGiftCardApiFp(this.configuration).saveProvisioningDetails(userGiftCardId, apiVersion, provisionData, options).then((request) => request(this.axios, this.basePath));
    }
}


