import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { GiftCardDetailsVM, NewUserGiftCardWorkflow } from '../generated';
import { useLocalStorage } from '../hooks/useLocalStorage';

type PurchaseContextData = {
  newPurchaseDetails: NewUserGiftCardWorkflow;
  setNewPurchaseDetails: (newPurchaseDetails: NewUserGiftCardWorkflow) => void;
  cardDetails: GiftCardDetailsVM;
  setCardDetails: (newCardDetails: GiftCardDetailsVM) => void;
  setCardAndPurchaseDetails: (
    newerPurchaseDetails: NewUserGiftCardWorkflow,
    newerCardDetails: GiftCardDetailsVM,
    newMerchantLocations?: number
  ) => void;
  merchantLocations?: number;
};

const PurchaseContext = createContext<PurchaseContextData>(
  {} as PurchaseContextData
);

const PurchaseProvider = ({ children }: any) => {
  const [newPurchaseDetails, setNewPurchaseDetailsLocal] =
    useState<NewUserGiftCardWorkflow>({} as NewUserGiftCardWorkflow);

  const [cardDetails, setCardDetailsLocal] = useState<GiftCardDetailsVM>(
    {} as GiftCardDetailsVM
  );

  const [merchantLocations, setMerchantLocations] = useState<
    number | undefined
  >(undefined);

  const [localPurchase, setLocalPurchase] =
    useLocalStorage<PurchaseContextData>('localPurchase', {
      newPurchaseDetails,
      setNewPurchaseDetails: setNewPurchaseDetailsLocal,
      cardDetails,
      setCardDetails: setCardDetailsLocal,
      setCardAndPurchaseDetails: () => {},
      merchantLocations,
    });

  const setNewPurchaseDetails = useCallback(
    (newerPurchaseDetails: NewUserGiftCardWorkflow) => {
      if (localPurchase) {
        setLocalPurchase({
          ...localPurchase,
          newPurchaseDetails: newerPurchaseDetails,
        });
      }
      setNewPurchaseDetailsLocal(newerPurchaseDetails);
    },
    [localPurchase, setLocalPurchase]
  );

  const setCardDetails = useCallback(
    (newerCardDetails: GiftCardDetailsVM) => {
      if (localPurchase) {
        setLocalPurchase({ ...localPurchase, cardDetails: newerCardDetails });
      }
      setCardDetailsLocal(newerCardDetails);
    },
    [localPurchase, setLocalPurchase]
  );

  const setCardAndPurchaseDetails = useCallback(
    (
      newerPurchaseDetails: NewUserGiftCardWorkflow,
      newerCardDetails: GiftCardDetailsVM,
      newMerchantLocations?: number
    ) => {
      if (localPurchase) {
        const { newPurchaseDetails, cardDetails, merchantLocations, ...rest } =
          localPurchase;
        setLocalPurchase({
          ...rest,
          newPurchaseDetails: newerPurchaseDetails,
          cardDetails: newerCardDetails,
          merchantLocations: newMerchantLocations,
        });
      }
      setNewPurchaseDetailsLocal(newerPurchaseDetails);
      setCardDetailsLocal(newerCardDetails);
      setMerchantLocations(newMerchantLocations);
    },
    [localPurchase, setLocalPurchase]
  );

  useEffect(() => {
    setNewPurchaseDetailsLocal(
      localPurchase?.newPurchaseDetails || newPurchaseDetails
    );
  }, [localPurchase?.newPurchaseDetails, newPurchaseDetails]);

  useEffect(() => {
    setCardDetailsLocal(localPurchase?.cardDetails || cardDetails);
  }, [localPurchase?.cardDetails, cardDetails]);

  return (
    <PurchaseContext.Provider
      value={{
        newPurchaseDetails: newPurchaseDetails,
        setNewPurchaseDetails,
        cardDetails,
        setCardDetails,
        setCardAndPurchaseDetails,
        merchantLocations: merchantLocations,
      }}>
      {children}
    </PurchaseContext.Provider>
  );
};

function usePurchase(): PurchaseContextData {
  const context = useContext(PurchaseContext);

  if (!context) {
    throw new Error('usePurchase must be used within an PurchaseProvider');
  }

  return context;
}

export { PurchaseContext, PurchaseProvider, usePurchase };
