import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useApi } from '../../api/ApiProvider';
import Body from '../../components/Body';
import Categories from '../../components/Categories';
import Scroller from '../../components/Scroller';
import { Search } from '../../components/Search';
import Flower from '../../assets/svg/Flower.svg';
import Flower2 from '../../assets/svg/Flower2.svg';
import {
  GiftCardDetailsVM,
  GiftCardProgramType,
  GiftCardSortBy,
  GiftCardStatus,
  SpecialCategoryDetailsVM,
} from '../../generated';
import './shop.scss';
import { Button } from '../../components/Button';
import useStateRef from '../../util/UseStateRef';
import Loader from '../../components/Loader';
import ShopCard from '../../components/ShopCard';
import { ScrollerMessage } from '../../const/shared';
import {
  MAX_PAGINATION_TAKE_SIZE,
  SEARCH_DEBOUNCE_DELAY,
} from '../../shared/const';
import { useDebounce } from '../../hooks/useDebounce';
import { RudderStack } from '../../services/shared/RudderStack';
import { Helmet } from 'react-helmet';
import { Alert } from '../../components/Alert';
import Info from '../../assets/svg/alert/Info.svg';
import { AffiliationText, BrandWrapper } from './styles';
import BrandCard from '../../components/BrandCard';
import _ from 'lodash';
interface PagedResults {
  take: number;
  skip: number;
  total: number;
}

const Shop = () => {
  const { giftCardApi } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isBrandLoading, setIsBrandLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasMoreBrandCards, setHasMoreBrandCards] = useState<boolean>(false);
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(
    'Search by Featured retailers'
  );
  const searchTermToUse = useDebounce(searchTerm.trim(), SEARCH_DEBOUNCE_DELAY);

  useEffect(() => {
    RudderStack.page('Shop');
  }, []);

  const [selectedCategories, setSelectedCategories] = useState<
    SpecialCategoryDetailsVM[]
  >([]);

  const [brandPagedResults, setBrandPageResults] = useState<PagedResults>({
    take: 12,
    skip: 0,
    total: 0,
  });
  const [categoryCards, setCategoryCards] = useState<GiftCardDetailsVM[]>([]);
  const [brandCards, setBrandCards] = useState<GiftCardDetailsVM[]>([]);

  const [atTop, setAtTop] = useStateRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsLoading(true);
    setIsBrandLoading(true);
    try {
      const selectedCategoryIds = selectedCategories.map((x) => x.id || 0);
      const categoryCardsPromise = giftCardApi.getGiftCards(
        [GiftCardStatus.Active],
        undefined,
        selectedCategoryIds,
        GiftCardSortBy.Alphabetical,
        GiftCardProgramType.Category,
        undefined,
        searchTermToUse,
        MAX_PAGINATION_TAKE_SIZE,
        0
      );
      const brandCardsPromise = giftCardApi.getGiftCards(
        [GiftCardStatus.Active],
        undefined,
        selectedCategoryIds,
        GiftCardSortBy.Alphabetical,
        GiftCardProgramType.Brand,
        undefined,
        searchTermToUse,
        brandPagedResults.take,
        0
      );

      const promises = [categoryCardsPromise, brandCardsPromise];
      Promise.all(promises).then((responses) => {
        const [categoryCardsResponse, brandCardsResponse] = responses;
        setBrandPageResults({
          skip: brandPagedResults.take,
          take: brandCardsResponse?.data.take || 0,
          total: brandCardsResponse?.data.total || 0,
        });
        setCategoryCards(categoryCardsResponse?.data.results || []);
        setBrandCards(brandCardsResponse?.data.results || []);
        setIsLoading(false);
        setIsBrandLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      setIsBrandLoading(false);
      console.log('Error: ', error);
    }
    // eslint-disable-next-line
  }, [searchTermToUse, selectedCategories]);

  useEffect(() => {
    const handleScroll = () => {
      const distanceFromTop = window.scrollY;
      if (distanceFromTop <= 300) {
        setAtTop(false);
      } else {
        setAtTop(true);
      }
    };

    const handleWidth = () => {
      const width = window.innerWidth;
      if (width < 500) {
        setSearchPlaceHolder('search');
      } else {
        setSearchPlaceHolder('Search by Featured retailers');
      }

      if (width < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWidth);
    handleWidth();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWidth);
    };
  }, [setAtTop, setSearchPlaceHolder, isMobile]);

  const loadBrandCardResults = (reset: boolean) => {
    if (
      brandPagedResults.total > brandCards.length &&
      hasMoreBrandCards &&
      brandPagedResults.skip < brandPagedResults.total
    ) {
      const selectedCategoryIds = selectedCategories.map((x) => x.id || 0);
      giftCardApi
        .getGiftCards(
          [GiftCardStatus.Active],
          undefined,
          selectedCategoryIds,
          GiftCardSortBy.Alphabetical,
          GiftCardProgramType.Brand,
          undefined,
          searchTermToUse,
          brandPagedResults.take,
          reset ? 0 : brandPagedResults.skip
        )
        .then((brandCardsResponse) => {
          setBrandPageResults({
            skip: reset
              ? brandPagedResults.take
              : brandPagedResults.skip + brandPagedResults.take,
            take: brandCardsResponse?.data.take || 0,
            total: brandCardsResponse?.data.total || 0,
          });

          reset
            ? setBrandCards(brandCardsResponse.data.results || [])
            : setBrandCards((prev) => {
                const allCards = _.uniqBy(
                  [...prev, ...(brandCardsResponse.data.results || [])],
                  'id'
                );
                return allCards;
              });
        });
    }
  };

  const handleCategorySelect = (
    category: SpecialCategoryDetailsVM,
    add: boolean
  ) => {
    add
      ? setSelectedCategories([category])
      : setSelectedCategories(
          selectedCategories.filter((selected) => selected.id !== category.id)
        );
  };

  const take = (
    cardsBlock: Array<GiftCardDetailsVM>,
    skip: number,
    take: number
  ) => {
    return cardsBlock.slice(skip, skip + take);
  };

  const mapCard = (giftCard: GiftCardDetailsVM) => {
    const src =
      giftCard.giftCardProgram?.programProfiles?.flatMap(
        (x) => x.frontsideImage || ''
      ) || [];

    return (
      <ShopCard
        key={giftCard.id ?? -1}
        id={giftCard.id}
        imgSrc={src[0]}
        name={giftCard.name}
        minimalValue={giftCard.minimumValue}
        maximalValue={giftCard.maximumValue}
        tag={giftCard.tag}
        slug={giftCard.slug}
      />
    );
  };

  const mapBrandCard = (giftCard: GiftCardDetailsVM) => {
    const { squareCardColourLogo: logoUrl } =
      giftCard.giftCardProgram?.brandMerchant || {};
    const { displayAffiliationText } = giftCard.giftCardProgram || {};
    const { id, tag } = giftCard;

    return (
      <BrandCard
        key={id ?? -1}
        id={id}
        name={giftCard.name || ''}
        logoUrl={logoUrl || ''}
        minimalValue={giftCard.minimumValue}
        maximalValue={giftCard.maximumValue}
        tag={tag}
        displayAffiliationText={displayAffiliationText}
        slug={giftCard.slug}
      />
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollView = () => {
    setTimeout(() => {
      window.scrollTo({ behavior: 'smooth', top: 280 });
    }, 300);
  };

  const getDefaultHeight = () => {
    if (isMobile) {
      return 40;
    }
    return 50;
  };

  const cardsBlockToDisplay = useMemo(() => {
    const blockCount = Math.floor(categoryCards.length / 12) + 1;
    let cardsBlockToDisplay = new Array(blockCount);
    for (let i = 0; i < blockCount; i++) {
      cardsBlockToDisplay[i] = categoryCards.slice(i * 12, i * 12 + 12);
    }
    return cardsBlockToDisplay;
  }, [categoryCards]);

  const brandCardsBlockToDisplay = useMemo(() => {
    const blockCount = Math.floor(brandCards.length / 12) + 1;
    let brandCardsBlockToDisplay = new Array(blockCount);
    for (let i = 0; i < blockCount; i++) {
      brandCardsBlockToDisplay[i] = brandCards.slice(i * 12, i * 12 + 12);
    }
    return brandCardsBlockToDisplay;
  }, [brandCards]);

  useEffect(() => {
    setHasMoreBrandCards(brandPagedResults.total > brandCards.length);
  }, [brandCards, brandPagedResults.total]);

  return (
    <Body
      theme="white"
      headerDisableShopNow={isMobile ? true : false}
      displayCareers={false}>
      <Helmet>
        <title>Special Gift Cards | Shop Now</title>
        <meta
          name="description"
          content="Special Gift Cards are sent via e-mail or SMS & are available in any value 
          from $5 to $1000 and can be used at thousands of retailers across 
          Australia."
        />
      </Helmet>
      <div className="shop-wrapper">
        <h1 style={{ letterSpacing: 0 }}>
          <img src={Flower} alt="flower" />
          shop
          <img src={Flower2} alt="flower" />
        </h1>
        <p className="subheading center edge-p">
          All our Special Cards are sent via e-mail or SMS & are available in
          any value from $5 to $1000.
        </p>
        <div ref={header} className="search-container">
          <div className="sticky">
            <div style={{ flex: 1 }}>
              <Search
                scrolled={atTop}
                isMobile={isMobile}
                searchMatches={[]}
                onSelectValue={() => {}}
                onSubmitEvent={() => scrollView}
                isLoading={false}
                onChangeValue={(value) => {
                  setSearchTerm(value);
                }}
                placeholder={searchPlaceHolder}
              />

              {!isMobile && categoryCards?.length > 0 && !!searchTermToUse && (
                <Alert
                  title="This retailer features on this Special Card(s)."
                  role="Info"
                  style={{
                    width: '35rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  icon={Info}
                />
              )}
            </div>
            <div
              className={`search-button${atTop ? ' at-top' : ''}`}
              style={{ height: `${getDefaultHeight()}px` }}
              ref={ref}>
              <Button
                style={{ height: `${getDefaultHeight()}px` }}
                role="Secondary"
                color="white"
                title="back to top"
                onClick={scrollToTop}
              />
            </div>
          </div>
        </div>
        <Categories
          selected={selectedCategories}
          setSelectedCategory={handleCategorySelect}
          // categories={categories}
          unfilterCategories={() => {
            setSelectedCategories([]);
          }}
        />
        {(isLoading || (!isLoading && categoryCards.length > 0)) && (
          <div>
            <div className="content">
              <h2 style={{ letterSpacing: 0, fontWeight: 'normal' }}>
                Special Cards
              </h2>
              <p className="subheading">
                Gift cards are to VHS what Special Cards are to DVDs. Trade your
                video cassette recorder for high convenience today.
              </p>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                {cardsBlockToDisplay.map((cardsBlock, i) => {
                  return (
                    <div key={i}>
                      <div
                        key={0}
                        className={`cards ${i !== 0 ? 'spacer' : ''} content`}>
                        {take(cardsBlock, 0, 6).map((card) => mapCard(card))}
                      </div>
                      {cardsBlock.length > 6 && (
                        <Scroller inverse={true} content={ScrollerMessage} />
                      )}
                      <div className="cards content">
                        {take(cardsBlock, 6, 6).map((card) => mapCard(card))}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {!isLoading && categoryCards.length > 0 && (
          <Scroller inverse={true} content={ScrollerMessage} />
        )}
        {(isBrandLoading || (!isBrandLoading && brandCards.length > 0)) && (
          <div>
            <div
              className="content"
              style={{
                marginTop: isMobile && categoryCards.length > 0 ? '4rem' : 0,
              }}>
              <h2
                style={{
                  letterSpacing: 0,
                  marginTop: categoryCards.length > 0 ? 0 : '4rem',
                  fontWeight: 'normal',
                }}>
                Gift By Brands
              </h2>
              <p className="subheading">
                You can send a Special Card to be used at any of these brands
                below.
              </p>
            </div>
            {isBrandLoading ? (
              <Loader />
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => loadBrandCardResults(false)}
                hasMore={hasMoreBrandCards}
                loader={<Loader key={0} />}>
                {brandCardsBlockToDisplay.map((cardsBlock, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        marginTop: i === 0 ? 0 : isMobile ? '2.4rem' : '7rem',
                      }}>
                      <BrandWrapper
                        key={0}
                        className={`${i !== 0 ? 'spacer' : ''} content`}>
                        {take(cardsBlock, 0, 12).map((card) =>
                          mapBrandCard(card)
                        )}
                      </BrandWrapper>
                    </div>
                  );
                })}
              </InfiniteScroll>
            )}
            {!isBrandLoading && brandCards.length > 0 && (
              <AffiliationText>
                *This brand is not affiliated with Special.
              </AffiliationText>
            )}
          </div>
        )}
      </div>
    </Body>
  );
};
export default Shop;
