import { CSSProperties } from 'styled-components';
import './heroSubtitle.scss';

type props = {
  children?: any;
  inverse?: boolean;
  style?: CSSProperties;
};

const HeroSubtitle = ({ children, inverse, style }: props) => {
  return (
    <p style={style} className={`subtitle ${inverse && 'inverse'}`}>
      {children}
    </p>
  );
};

export default HeroSubtitle;
