import React, { useMemo } from 'react';

import styled from 'styled-components';
import Colors from '../../../../const/Colors';
import { map } from '../../../../const/theme';
import { ReactComponent as MobileLogo } from '../../../../assets/svg/logo/Black.svg';
import { ReactComponent as NonMobileLogo } from '../../../../assets/svg/logo/FullColorBlack.svg';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import Breakpoints from '../../../../const/breakpoints';
import UserMenu from '../../../../components/Header/UserMenu';
import { useNavigate } from 'react-router-dom';

const HeaderWrapper = styled.div`
  height: 7.2rem;
  background-color: ${Colors.white};
  ${map(
    { tablet: Colors.white },
    (bgColor) => `background-color: ${bgColor};`
  )};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  ${map({ tablet: '1.8rem 3.2rem' }, (padding) => `padding: ${padding};`)};
  ${map({ tablet: 0 }, (margin) => `margin: ${margin};`)};
  border-bottom: 2px solid ${Colors.black};
  ${map(
    { tablet: 'none' },
    (borderBottom) => `border-bottom: ${borderBottom};`
  )};
  z-index: 6;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const PurchaseEndHeader = () => {
  const { windowWidth } = useWindowDimensions();
  const Logo = useMemo(
    () => (windowWidth < Breakpoints.tablet ? MobileLogo : NonMobileLogo),
    [windowWidth]
  );
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Logo width={116} height={34} onClick={onClick} />
      </LogoWrapper>
      <UserMenu theme="black" />
    </HeaderWrapper>
  );
};

export default PurchaseEndHeader;
