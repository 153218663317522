import React from 'react';

interface OwnProps {
  isBranded?: boolean;
  style?: React.CSSProperties;
}

const BrandBlock: React.FC<OwnProps> = ({ isBranded, style, children }) => {
  if (!isBranded) {
    return null;
  }

  return <div style={style}>{children}</div>;
};

export default BrandBlock;
