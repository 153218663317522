import React from 'react';

import styled from 'styled-components';
import Colors from '../../../../../const/Colors';

interface OwnProps {
  label: string;
  selected: boolean;
  onClick: (param?: any) => void;
}

interface SendWrapperProps {
  selected: boolean;
}

interface LabelProps {
  selected: boolean;
}

const SendWrapper = styled.div<SendWrapperProps>`
  border: ${(props) =>
    props.selected ? `2px solid ${Colors.black}` : `1px solid ${Colors.line2}`};
  border-radius: 0.9rem;
  padding: 2.4rem 1.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  flex: 1;
  width: 100%;
`;

const Label = styled.span<LabelProps>`
  font-weight: ${(props) => (props.selected ? 'bold' : '')};
  font-size: 1.5rem;
  line-height: 1.9rem;
`;
const SendItem: React.FC<OwnProps> = ({ label, selected, onClick }) => {
  return (
    <>
      <SendWrapper selected={selected} onClick={onClick}>
        <Label selected={selected}>{label}</Label>
      </SendWrapper>
    </>
  );
};

export default SendItem;
