import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Breakpoints from '../../../../const/breakpoints';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { Button } from '../../../Button';
import ArrowDown from '../../../../assets/svg/ArrowDown.svg';
import { map } from '../../../../const/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: min(1.2rem, 2.8vw);
  ${map(
    { tablet: 'min(2.4rem, 1.86vw)' },
    (marginTop) => `margin-top: ${marginTop};`
  )}
  padding-bottom: min(2.3rem, 5.5vw);
  ${map(
    { tablet: 'min(4.1rem, 2.84vw)' },
    (paddingBottom) => `padding-bottom: ${paddingBottom};`
  )}
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TextStyle = styled.div`
  font-size: min(5.7vw, 2.4rem);
  line-height: min(5.7vw, 3.4rem);
  font-weight: bold;
  margin-right: 2rem;
  letter-spacing: 0.01em;
`;

const ImageStyle = styled.div`
  display: flex;
  align-items: center;
  img {
    height: min(12vw, 5.2rem);
  }
`;

interface OwnProps {
  onClick: () => void;
  isWhite?: boolean;
}

const FindSomething: React.FC<OwnProps> = ({ onClick, isWhite = true }) => {
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  return (
    <Container>
      <Left onClick={onClick}>
        <TextStyle>Find something special</TextStyle>
        <ImageStyle>
          <img src={ArrowDown} alt="Arrow Down" />
        </ImageStyle>
      </Left>
      {!isMobileView && (
        <div>
          <Button
            role="Primary"
            title="SHOP NOW"
            color={isWhite ? 'white' : undefined}
            startIconClass="flower-icon"
            style={{ marginTop: 0, width: 'auto' }}
            onClick={() => navigate('/shop')}
          />
        </div>
      )}
    </Container>
  );
};

export default FindSomething;
