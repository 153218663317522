import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import SocialLogin from '../../components/SocialLogin';
import { RudderStack } from '../../services/shared/RudderStack';
import './Auth.scss';
import AuthWrapper from './AuthWrapper';

const PreCreateAccount = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RudderStack.page("Auth_PreCreateAccount");
  },[])
  return (
    <>
      <AuthWrapper
        className="auth-page-pre"
        containerClassName="auth-container-pre">
        <>
          <p className="auth-title">Create an Account</p>
          <p className="auth-subtitle">
            Already have an account?{' '}
            <Link to="/auth/" onClick={() => navigate('/auth/')}>
              Log in
            </Link>
          </p>
          <Button
            title="CREATE ACCOUNT WITH EMAIL"
            startIconClass={'heart-icon'}
            role="Secondary"
            onClick={() => navigate('/auth/createAccountName')}
          />
          <SocialLogin />
        </>
      </AuthWrapper>
    </>
  );
};

export default PreCreateAccount;
