import React, { useMemo } from 'react';

import './styles.scss';

interface OwnProps {
  value: string;
  selectedValue: string;
  inputValue: string;
  handleSelectedValue: (value: string) => void;
}
const ValuesGridItem: React.FC<OwnProps> = ({
  value,
  selectedValue,
  inputValue,
  handleSelectedValue,
}) => {
  const isSelected = useMemo(
    () => value === selectedValue,
    [value, selectedValue]
  );
  const getClassName = useMemo(
    () => `valuesGridItem ${isSelected ? 'purchase-grid-item-active' : ''}`,
    [isSelected]
  );

  return (
    <div className={getClassName} onClick={() => handleSelectedValue(value)}>
      {value}
    </div>
  );
};

export default ValuesGridItem;
