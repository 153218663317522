import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import SocialLogin from '../../components/SocialLogin';
import { RudderStack } from '../../services/shared/RudderStack';
import './Auth.scss';
import AuthWrapper from './AuthWrapper';

const PreLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    RudderStack.page("Auth_PreLogin");
  },[])

  return (
    <AuthWrapper
      className="auth-page-pre"
      containerClassName="auth-container-pre"
      topLeftComponent="Close">
      <>
        <p className="auth-title">Log in</p>
        <p className="auth-subtitle">
          New to Special?{' '}
          <Link
            to="/auth/createAccount"
            onClick={() => navigate('/auth/createAccount')}>
            Create an Account
          </Link>
        </p>
        <Button
          title="LOG IN WITH EMAIL"
          startIconClass={'heart-icon'}
          role="Secondary"
          onClick={() => navigate('/auth/login')}
        />
        <SocialLogin />
      </>
    </AuthWrapper>
  );
};

export default PreLogin;
