import React from 'react';
import ValuesGridItem from './ValuesGridItem';
import { CUSTOM_BUTTON_TEXT } from '../../shared';

import './styles.scss';

interface OwnProps {
  values: string;
  selectedValue: string;
  inputValue: string;
  handleSelectedValue: (value: string) => void;
}

const ValuesGrid: React.FC<OwnProps> = ({
  values,
  selectedValue,
  inputValue,
  handleSelectedValue,
}) => {
  const formattedValues = values.split(',');

  return (
    <div className="valuesGrid">
      {formattedValues.map((value, index) => (
        <ValuesGridItem
          value={`$${value}`}
          selectedValue={selectedValue}
          handleSelectedValue={handleSelectedValue}
          inputValue={inputValue}
          key={`${value}=${index}`}
        />
      ))}
      <ValuesGridItem
        value={CUSTOM_BUTTON_TEXT}
        selectedValue={selectedValue}
        inputValue={inputValue}
        handleSelectedValue={handleSelectedValue}
      />
    </div>
  );
};

export default ValuesGrid;
