import { useMemo } from 'react';
import { To, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { PurchaseSteps } from '../../../const/urls/purchase/PurchaseUrls';
import { MenuLink } from '../MenuLink';
import PurchaseLinkIndicatorInActive from '../../../assets/svg/header/PurchaseLinkIndicatorInActive.svg';
import styled from 'styled-components';

const StepIndicator = styled.img`
  position: relative;
  top: 0.4rem;
  margin-right: 1rem;
  width: 1.6rem;
`;
export const PurchaseMenuLink = ({ ...props }) => {
  const toPath: To = props?.to || '';
  const resolved = useResolvedPath(toPath);
  const match = useMatch({ path: resolved.pathname, end: true });
  const location = useLocation();
  const pathName = location.pathname;

  const isActive = useMemo(() => {
    const currentStepValue =
      PurchaseSteps.find((x) => x.link === pathName)?.stepValue ?? 0;

    return currentStepValue >= props.stepValue;
  }, [pathName, props.stepValue]);

  return (
    <MenuLink
      noHighLightColor={props.noHighLightColor}
      className={`purchase-header-link ${!isActive && 'disabled'}`}
      to={toPath}
      active={!!match && !!toPath}>
      <>
        <StepIndicator
          src={isActive ? props.activeSvg : PurchaseLinkIndicatorInActive}
          alt="Stepper"
        />
        {props.children}
      </>
    </MenuLink>
  );
};
