import styled from 'styled-components';
import Arrow from '../../../assets/svg/LeftArrowBlue.svg';

type props = {
  title: string | null | undefined;
  link: string;
};

const Wrapper = styled.a`
  margin: 3rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: 'Agrandir-TextBold';
  letter-spacing: 0.04rem;

  &:hover {
    text-decoration: none;
  }

  img {
    margin-right: 0.5rem;
  }
`;

const GoBack = ({ title, link }: props) => {
  return (
    <Wrapper href={link}>
      <img src={Arrow} alt="arrow" style={{marginRight: '1.6rem'}}/>
      {title}
    </Wrapper>
  );
};
export default GoBack;
