import { useMemo, useState } from 'react';
import {
  OrderVM,
  UserGiftCardDeliveryStatus,
  UserGiftCardOrderStatus,
} from '../../generated';
import Download from '../../assets/svg/Download.svg';
import './OrderModal.scss';
import { usePayment } from '../../context/PaymentProvider';
import { useAlert } from 'react-alert';
import { useAppContext } from '../../context/AppProvider';
import { getOrderDate } from '../../helpers/utils';
import { ReactComponent as WarningIcon } from '../../assets/svg/Warning.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getTimeZoneDisplay } from '../../helpers/cards';
import { CSSProperties } from 'styled-components';
type props = {
  order: OrderVM;
};

const SINGLE_LINE_THRESHOLD = 480;

const OrderModal = ({ order }: props) => {
  const [isFetchingReceipt, setIsFetchingReceipt] = useState<boolean>(false);
  const { getPaymentReceiptUrl } = usePayment();
  const alert = useAlert();
  const { timezones } = useAppContext();
  const showInvoice = order.orderStatus !== UserGiftCardOrderStatus.Review;

  const onDownloadInvoice = () => {
    if (isFetchingReceipt) {
      return;
    }
    setIsFetchingReceipt(true);
    const windowReference = window.open();
    getPaymentReceiptUrl(order.userGiftCardId ?? 0)
      .then((receiptUrl) => {
        if (receiptUrl && windowReference) {
          windowReference.location = receiptUrl;
        }
      })
      .catch((err) => {
        alert.error('Unable to get receipt, please try again later');
      })
      .finally(() => setIsFetchingReceipt(false));
  };

  const deliveredMessage = useMemo(() => {
    return `Your card order has been delivered to ${order.userGiftCardPersonalization?.receiverFirstName} ${order.userGiftCardPersonalization?.receiverLastName}`;
  }, [order]);

  const getDisplayMessage = (order: OrderVM): string => {
    switch (order.deliveryStatus) {
      case UserGiftCardDeliveryStatus.Delivered:
        return deliveredMessage;
      case UserGiftCardDeliveryStatus.Pending:
        return 'Sit tight. Your order is still processing.';
      default:
        return 'There has been an error with delivering you order.';
    }
  };

  const { windowWidth } = useWindowDimensions();
  const isSingleLine = useMemo(
    () => windowWidth <= SINGLE_LINE_THRESHOLD,
    [windowWidth]
  );
  const subTextStyle = useMemo(
    (): CSSProperties => ({
      marginBottom: isSingleLine ? 8 : 0,
    }),
    [isSingleLine]
  );

  return (
    <div className="orderModalWrapper">
      <div className="orderModal">
        <div className="orderModalRow" style={{ justifyContent: 'flex-start' }}>
          <img
            src={order.profile?.frontsideImage ?? ''}
            style={{ borderRadius: '5px' }}
            width={125}
            alt="Card"
          />
          <div>
            <p className="orderModalHeaderText">{order.giftCardName}</p>
            <p className="orderModalNumber">#{order.id}</p>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          {order.deliveryStatus === UserGiftCardDeliveryStatus.Pending && (
            <WarningIcon style={{ marginRight: 10 }} />
          )}
          <span>{getDisplayMessage(order)}</span>
        </div>

        <hr className="orderModalDivider" />
        <div className="orderModalRow">
          <div className="orderRowItem">
            <p className="orderModalSubText" style={subTextStyle}>
              Recipient
            </p>
            <p className="orderModalBoldText">
              {order.userGiftCardPersonalization?.receiverFirstName +
                ' ' +
                order.userGiftCardPersonalization?.receiverLastName}
            </p>
          </div>
          <div className="orderRowItem">
            <p className="orderModalSubText" style={subTextStyle}>
              Scheduled
            </p>
            <p className="orderModalBoldText">
              {getOrderDate(order, timezones)}
            </p>
          </div>
        </div>
        <div className="orderModalRow">
          <div
            className="orderRowItem"
            style={{ order: isSingleLine ? 2 : undefined }}>
            <p className="orderModalSubText" style={subTextStyle}>
              Send To
            </p>
            <p className="orderModalBoldText">
              {order.userGiftCardPersonalization?.receiverEmail ||
                order.userGiftCardPersonalization?.receiverPhoneNumber ||
                `${order.userGiftCardPersonalization?.receiverFirstName} ${order.userGiftCardPersonalization?.receiverLastName}`}
            </p>
          </div>
          {order.userGiftCardPersonalization?.specialTimeZone && (
            <div className="orderRowItem">
              <p className="orderModalSubText" style={subTextStyle}>
                TimeZone
              </p>
              <p className="orderModalBoldText">
                {getTimeZoneDisplay(
                  order.userGiftCardPersonalization?.specialTimeZone
                )}
              </p>
            </div>
          )}
        </div>
        <div className="orderModalRow">
          <div>
            <p className="orderModalSubText" style={subTextStyle}>
              Special Card Value
            </p>
            <p className="orderModalBoldText">${order.value}</p>
          </div>
        </div>
        <div className="orderModalRow">
          <div>
            <p className="orderModalSubText" style={subTextStyle}>
              Personalised Message
            </p>
            <p>{order.userGiftCardPersonalization?.message}</p>
          </div>
        </div>
        {showInvoice && (
          <div
            className="orderModalDownload"
            onClick={() => onDownloadInvoice()}>
            <img src={Download} alt="download" />
            <p className="orderModalDownloadText">Download Invoice</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderModal;
