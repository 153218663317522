import React, { useEffect, useMemo, useState } from 'react';
import LandingGift from '../../../assets/svg/activation/LandingGift.svg';
import { ReactComponent as Star } from '../../../assets/svg/activation/Star.svg';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import { ReactComponent as Logo } from '../../../assets/svg/logo/White.svg';

import './styles.scss';
import SvgHeart from '../../../components/Svg/Heart';
import { Button } from '../../../components/Button';
// import DownloadApp from '../../../components/DownloadApp';
// import Divider from '../../../components/Divider';
import useQuery from '../../../hooks/useQuery';
import { useApi } from '../../../api/ApiProvider';
import { useActivation } from '../../../context/ActivationProvider';
import { useAuth } from '../../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Colors from '../../../const/Colors';
import { RudderStack } from '../../../services/shared/RudderStack';
import SomethingWrong from '../SomethingWrong';
import { UserGiftCardStatus } from '../../../generated';

type Props = {};

const Landing = (props: Props) => {
  const navigate = useNavigate();
  const query = useQuery();
  const id = query.get('id');
  const token = query.get('token');

  const activation = useActivation();
  const { giftCardWorkflowApi } = useApi();
  const [isValidActivationToken, setIsValidActivationToken] = useState<
    boolean | undefined
  >(false);
  const [cardStatus, setCardStatus] = useState<
    UserGiftCardStatus | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    RudderStack.page('Activation_Landing');
  }, []);

  useEffect(() => {
    (async () => {
      if (!token || !id) {
        return;
      }
      setIsLoading(true);
      try {
        const parsedId = parseInt(id);
        const {
          data: { isTokenValid, status },
        } = await giftCardWorkflowApi.validateActivationToken(parsedId, token);
        setIsValidActivationToken(isTokenValid);
        setCardStatus(status);
        if (!isTokenValid || status === UserGiftCardStatus.Cancelled) {
          setIsLoading(false);
          return;
        }

        const activationDetails =
          await giftCardWorkflowApi.getActivationUserGiftCardDetails(
            parsedId,
            token
          );
        activation.setActivationData({
          id: id,
          token: token,
          activationDetails: activationDetails.data,
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log('error in landing page: ', err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);
  const { isLoggedIn, setRedirectURL } = useAuth();

  const buttonText = useMemo(() => {
    return isLoggedIn ? 'UNWRAP AND ACTIVATE' : 'LOG IN OR CREATE ACCOUNT';
  }, [isLoggedIn]);

  const onClick = () => {
    if (isLoggedIn) {
      navigate('/activate/unwrap');
    } else {
      setRedirectURL('/activate/unwrap');
      navigate('/auth/');
    }
  };

  if (
    (!id ||
      !token ||
      (isValidActivationToken && cardStatus === UserGiftCardStatus.Cancelled) ||
      !isValidActivationToken ||
      !activation.activationData.activationDetails) &&
    !isLoading
  ) {
    return <SomethingWrong />;
  }

  if (isLoading) {
    return (
      <div className="activationLandingLoading">
        <Loader />;
      </div>
    );
  }

  return (
    <div className="landing">
      <div className="logo">
        <Logo width={116} height={33} />
      </div>
      <div className="container">
        <img src={LandingGift} alt="landing-gift" className="landingGift" />
        <div className="wording">
          <div className="receiver">
            Hey {activation.activationData.activationDetails.to},
          </div>
          <div className="message">
            {activation.activationData.activationDetails.message}
          </div>
          <div className="gifter">
            <div className="heart">
              <SvgHeart />
            </div>
            <div className="gifterText">
              From {activation.activationData.activationDetails.from}
            </div>
          </div>
        </div>
      </div>

      <Border height="62" fill={Colors.white} className="scallop" />
      <div className="activation-unwrap">
        <div className="container">
          <div className="star1">
            <Star height={40} width={40} />
          </div>
          <div className="star2">
            <Star height={80} width={80} />
          </div>
          <div className="title">Unwrap & Activate</div>
          <div className="subTitle">
            To unwrap and activate your Special gift card, you’ll need to create
            an account or log in.
          </div>
          <div className="buttonWrapper">
            <Button
              role="Primary"
              startIconClass="heart-icon"
              title={buttonText}
              onClick={onClick}
            />
          </div>

          {/* <div className="divider">
            <Divider text="OR" />
          </div>
          <div className="downloadSection">
            <div className="downloadText">
              Download the Special app and create an account
            </div>
            <DownloadApp />
          </div> */}

          <div className="ps">
            <span className="isBold">PS. </span>
            <span>
              Gift cards can’t be spent until activated because that’s how we
              keep them secure.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
