import React from 'react';
import SvgHeart from '../../../../components/Svg/Heart';
import Colors from '../../../../const/Colors';

import './styles.scss';

interface OwnProps {
  heartColor?: string;
  reverseOrder?: boolean;
}

const Faq: React.FC<OwnProps> = ({
  heartColor = Colors.softLillac,
  reverseOrder = false,
}) => {
  return (
    <div
      className="faq"
      style={{ flexDirection: reverseOrder ? 'row-reverse' : 'row' }}>
      <a
        className="text"
        href="https://special.freshdesk.com/support/home"
        target="_blank"
        rel="noreferrer"
        style={{
          marginRight: reverseOrder ? 0 : 12,
          marginLeft: reverseOrder ? 12 : 0,
        }}>
        FAQ
      </a>
      <SvgHeart height={22} width={22} fill={heartColor} />
    </div>
  );
};

export default Faq;
