import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import Stepper from '../../components/Stepper';
import { EMAIL_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import { useAlert } from 'react-alert';

import AuthWrapper from './AuthWrapper';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import { RudderStack } from '../../services/shared/RudderStack';

const CreateAccountEmail = () => {
  const alert = useAlert();
  const {
    newUserDetails,
    setNewUserDetails,
    navState,
    setNavState,
    createAccountStepperClicks,
  } = useAuth();
  const navigate = useNavigate();
  const { userApi } = useApi();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: newUserDetails.email,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    RudderStack.page('Auth_CreateAccountEmail');
  }, []);

  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  const onSubmit = async (data: any) => {
    const res = await userApi.isEmailAddressTaken(data.email);
    if (!res.data) {
      setNewUserDetails({
        ...newUserDetails,
        ...data,
      });
      navigate('/auth/createAccountPassword');
    } else {
      alert.error('Oops, the email is already taken, please try another one.');
    }
  };

  useEffect(() => {
    if (navState > 2) {
      return;
    }

    setNavState(2);
  });

  return (
    <>
      <AuthWrapper>
        <>
          <Stepper
            numberOfSteps={5}
            currentStep={navState}
            containerStyle="auth-stepper"
            onStepClick={createAccountStepperClicks}
          />
          <p className="auth-create-account-title">
            What is your email address?
          </p>
          <Controller
            control={control}
            rules={{
              required: true,
              pattern: EMAIL_REGEX,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                className="auth-input"
                label="Email address"
                autoFocus
                type={'text'}
                onBlur={onBlur}
                onChange={onChange}
                value={value.toLowerCase()}
                inputMode="email"
                error={!!errors.email}
              />
            )}
            name="email"
          />
          {errors.email && (
            <div>
              <ErrorText text="Enter a valid email address" />
            </div>
          )}
          <Button
            title="Next"
            role="Secondary"
            onClick={handleSubmit(onSubmit)}
          />
        </>
      </AuthWrapper>
    </>
  );
};

export default CreateAccountEmail;
