import momentTz from 'moment-timezone';
import { useMemo } from 'react';
import {
  BANNER_EXPIRY_DATE,
  BANNER_START_DATE,
  DEFAULT_TIMEZONE,
} from '../const/promotionDates';

export const usePromotion = (
  startDate = BANNER_START_DATE,
  expiryDate = BANNER_EXPIRY_DATE,
  timezone = DEFAULT_TIMEZONE
) => {
  const currentDate = momentTz.tz(new Date(), timezone);

  const isBeforeBannerDate = useMemo(
    () => currentDate.isBefore(startDate),
    [currentDate, startDate]
  );

  const isAfterBannerDate = useMemo(
    () => currentDate.isAfter(expiryDate),
    [currentDate, expiryDate]
  );

  const displayPromo = !(isBeforeBannerDate || isAfterBannerDate);

  return {
    displayPromo,
    isBeforeBannerDate,
    isAfterBannerDate,
  };
};
