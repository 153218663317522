import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Body from '../../../components/Body';
import { Button } from '../../../components/Button';
import { PurchaseWrapper } from '../../../components/Purchase';
import { useAppContext } from '../../../context/AppProvider';
import { useAuth } from '../../../context/AuthProvider';
import { usePurchase } from '../../../context/PurchaseProvider';
import {
  getSelectedProfileImage,
  getTimeZoneDisplay,
} from '../../../helpers/cards';
import { isBrandMerchant } from '../../../helpers/utils';
import { RudderStack } from '../../../services/shared/RudderStack';
import PurchaseBack from '../common/Back';
import EditMode from './EditMode';
import EditModeFooter from './EditMode/Footer';
import NormalMode from './NormalMode';

type Props = {};

export type FormValues = {
  value: number;
  receiverFirstName: string;
  receiverLastName: string;
  from: string;
  deliveryDate: string;
  receiverPhoneNumber: string;
  receiverEmail: string;
  message: string;
};

const Summary = (props: Props) => {
  const purchase = usePurchase();
  const navigate = useNavigate();
  const { isLoggedIn, setRedirectURL } = useAuth();
  const { cardDetails: card } = purchase;

  const cardMinimumValue = useMemo(
    () => card.minimumValue || 5,
    [card.minimumValue]
  );
  const cardMaximumValue = useMemo(
    () => card.maximumValue || 1000,
    [card.maximumValue]
  );

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(
      card,
      purchase.newPurchaseDetails.profileId ?? -1
    );
  }, [card, purchase.newPurchaseDetails.profileId]);

  const [initialPurchaseDetails, setInitialPurchaseDetails] = useState(
    purchase.newPurchaseDetails
  );

  const resetInitialPurchaseDetails = () => {
    setInitialPurchaseDetails(initialPurchaseDetails);
    purchase.setNewPurchaseDetails(initialPurchaseDetails);
  };

  useEffect(() => {
    RudderStack.page('Purchase_Summary');
  }, []);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);
  const [isTimezoneSelected, setIsTimezoneSelected] = useState<boolean>();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      value: purchase.newPurchaseDetails.value || 0,
      from: purchase.newPurchaseDetails.from || '',
      receiverFirstName: purchase.newPurchaseDetails.receiverFirstName || '',
      receiverLastName: purchase.newPurchaseDetails.receiverLastName || '',
      message: purchase.newPurchaseDetails.message || '',
      deliveryDate: purchase.newPurchaseDetails.sendNow
        ? new Date().toISOString()
        : purchase.newPurchaseDetails.deliveryDate,
      receiverPhoneNumber:
        purchase.newPurchaseDetails.receiverPhoneNumber || '',
      receiverEmail: purchase.newPurchaseDetails.receiverEmail || '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    const sendNow =
      purchase.newPurchaseDetails.sendNow === true ||
      purchase.newPurchaseDetails.sendNow === undefined;

    if (!sendNow && !purchase.newPurchaseDetails.specialTimezoneId) {
      setIsTimezoneSelected(false);
      return;
    }

    if (validate(data) && isValidDate) {
      setInitialPurchaseDetails(purchase.newPurchaseDetails);
      setIsEditMode(false);
    }
  };

  const validate = (data: FormValues) => {
    if (data.value < cardMinimumValue || data.value > cardMaximumValue) {
      setError('value', { message: 'Value Invalid' });
      return false;
    }
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      ...data,
      deliveryDate: purchase.newPurchaseDetails.deliveryDate,
    });
    return true;
  };

  const next = () => {
    if (isLoggedIn) {
      navigate('/purchase/payment');
    } else {
      setRedirectURL('/purchase/payment');
      navigate('/auth/');
    }
  };

  const { timezones } = useAppContext();

  const timezoneOptions = useMemo(() => {
    return timezones.map((x) => {
      return {
        label: getTimeZoneDisplay(x),
        value: x.id,
      };
    });
  }, [timezones]);

  const setDate = (utcIsoDateString: string, timeZone: number) => {
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      deliveryDate: utcIsoDateString,
      specialTimezoneId: timeZone,
    });
  };

  const momentDate: Moment = useMemo(() => {
    const selectedTimezone = timezones.find(
      (x) => x.id === purchase.newPurchaseDetails.specialTimezoneId
    );
    const momentDate = moment
      .utc(purchase.newPurchaseDetails.deliveryDate)
      .add(selectedTimezone?.timeZoneDifference ?? 0, 'minutes');
    return momentDate;
  }, [
    purchase.newPurchaseDetails.deliveryDate,
    purchase.newPurchaseDetails.specialTimezoneId,
    timezones,
  ]);

  const backLink = '/purchase/delivery';
  const isBranded = isBrandMerchant(card);

  return (
    <Body
      theme="white"
      disableFooter
      headerDisableShopNow
      headerVariant="purchase"
      displayMobileMenu={false}>
      <div className="content">
        <div className="purchase-delivery">
          <PurchaseBack isBranded={isBranded} backLink={backLink} />
          <PurchaseWrapper
            giftCard={card}
            showBackButton={false}
            cardImage={cardFrontImage}
            waveBackLink={backLink}>
            <div className="purchase-flow-header">Summary {card.name}</div>
            <div className="purchase-flow-description">
              Check the details of your order before you pay.
            </div>

            {isEditMode ? (
              <EditMode
                control={control}
                errors={errors}
                momentDate={momentDate}
                setDate={setDate}
                cardMinimumValue={cardMinimumValue}
                cardMaximumValue={cardMaximumValue}
                setError={setError}
                isValidDate={isValidDate}
                setIsValidDate={setIsValidDate}
                isTimezoneSelected={isTimezoneSelected}
                setIsTimezoneSelected={setIsTimezoneSelected}
              />
            ) : (
              <NormalMode
                setIsEditMode={setIsEditMode}
                momentDate={momentDate}
                timezoneOptions={timezoneOptions}
              />
            )}

            {isEditMode ? (
              <EditModeFooter
                setIsEditMode={setIsEditMode}
                reset={reset}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                resetInitialPurchaseDetails={resetInitialPurchaseDetails}
              />
            ) : (
              <Button
                role="Primary"
                title="CONTINUE TO PAYMENT"
                onClick={next}
              />
            )}
          </PurchaseWrapper>
        </div>
      </div>
    </Body>
  );
};

export default Summary;
