import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import OptOutWrapper from '../OptOutWrapper';

interface IProps {}

const OptOutConfirmation: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  return (
    <OptOutWrapper hideBackButton={true}>
      <p className="header centered">Your request has been submitted.</p>
      <p className="desc centered">
        Our commercial team will review your request and be in contact.
      </p>
      <Button
        title="GO TO HOMEPAGE"
        onClick={() => {
          navigate('/');
        }}
        role="Secondary"
        style={{ marginTop: '2.5rem' }}
      />
    </OptOutWrapper>
  );
};

export default OptOutConfirmation;
