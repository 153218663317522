import React from 'react';
import { Datum } from '../../../../generated';
import styled from 'styled-components';
import moment from 'moment';
import Amount from '../Amount';

type Props = { data: Datum };

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const LeftContainer = styled.div`
  .memo {
    font-size: bold;
    font-weight: 1.5rem;
    line-height: 1.9rem;
    font-family: 'Agrandir-TextBold', sans-serif;
    margin-bottom: 0.5rem;
    word-break: break-word;
    margin-right: 1.4rem;
  }

  .time {
    font-size: normal;
    font-weight: 1.5rem;
    line-height: 2.1rem;
    color: ${(props) => props.theme.colours.line1};
  }
`;

const RightContainer = styled.div`
  .amount {
    font-size: bold;
    font-weight: 1.6rem;
    font-family: 'Agrandir-TextBold', sans-serif;
  }
`;

const TransactionItem = ({ data }: Props) => {
  const { amount, memo, creationTime } = data;

  return (
    <Container>
      <LeftContainer>
        <div className="memo">{memo}</div>
        <div className="time">
          {moment(creationTime).format('ddd, MMM DD YYYY h:mmA')}
        </div>
      </LeftContainer>
      <RightContainer>
        <Amount amount={amount || 0} />
      </RightContainer>
    </Container>
  );
};

export default TransactionItem;
