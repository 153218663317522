import * as React from 'react';
import Colors from '../../../const/Colors';

interface OwnProps {
  height?: number;
  width?: number;
  color?: string;
}

const SvgBackArrow: React.FC<OwnProps> = ({
  height = 21,
  width = 21,
  color = Colors.electricBlue,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none">
      <path d="M20.5 10.5H7.5" stroke={color} />
      <path
        d="M4.5897e-07 10.5L9.75 19.5933L9.75 1.40673L4.5897e-07 10.5Z"
        fill={color}
      />
    </svg>
  );
};

export default SvgBackArrow;
