import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';
import { IStatePillProps } from './types';

export const StateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${map(
    { tablet: 'repeat(9, 1fr)' },
    (gridTemplateColumns) => `grid-template-columns: ${gridTemplateColumns}`
  )};
  gap: 1.2rem 1.6rem;
  ${map({ tablet: '0rem 0.4rem' }, (gap) => `gap: ${gap}`)};
`;

export const StatePill = styled.div<IStatePillProps>`
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-color: ${Colors.white};
  border-radius: 0.9rem;
  width: 100%;
  padding: 0.8rem 1rem 0.6rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 7.2rem;
  min-height: 3.8rem;
  word-break: break-all;
  cursor: ${({ isPresent }) => (isPresent ? 'pointer' : 'not-allowed')};
  border: ${(props) =>
    props.isSelected
      ? `2px solid ${Colors.black}`
      : `1px solid ${Colors.socialBorderColor}`};
  opacity: ${(props) => (props.isPresent ? 1 : 0.2)};
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
`;

export const LocationName = styled.div`
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-transform: uppercase;
  color: ${Colors.black};
  font-weight: 700;
  margin-bottom: 0.4rem;
  word-break: break-all;
  letter-spacing: 0.04em;
`;

export const LocationAddress = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${Colors.line1};
  margin-bottom: 1.6rem;
  word-break: break-all;
  width: 94%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LocationBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${Colors.socialBorderColor};
  margin-bottom: 2rem;
`;

export const StickySection = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${Colors.white};
  padding-bottom: 2rem;
`;

export const LocationsWrapper = styled.div`
  margin-top: 2rem;
  ${map({ tablet: '2.4rem' }, (marginTop) => `marginTop: ${marginTop}`)};
`;
