import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Body from '../../../components/Body';
import { Button } from '../../../components/Button';
import { PurchaseWrapper } from '../../../components/Purchase';
import { usePurchase } from '../../../context/PurchaseProvider';
import { getSelectedProfileImage } from '../../../helpers/cards';

import styled from 'styled-components';
import SendItem from './common/SendItem';
import { UserGiftCardSendingMethod } from '../../../generated';
import { Controller, useForm } from 'react-hook-form';
import { Input, MobileInput } from '../../../components/Input';
import { EMAIL_REGEX, MOBILE_REGEX } from '../../../const/shared';
import { ErrorText } from '../../../components/ErrorText';
import { Alert } from '../../../components/Alert';
import Info from '../../../assets/svg/alert/Info.svg';
import DateTimePicker from './common/DateTimePicker';
import { map } from '../../../const/theme';
import { RudderStack } from '../../../services/shared/RudderStack';
import { isBrandMerchant } from '../../../helpers/utils';
import PurchaseBack from '../common/Back';

const SendVia = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubText = styled.div`
  font-size: 1.25rem;
  line-height: 1.9rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

const DeliveryText = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.9rem;
  letter-spacing: 0.08em;
  margin-bottom: 2rem;
`;

const AlertWrapper = styled.div`
  .special-alert {
    width: unset;
    height: unset;
  }
`;

const ButtonWrapper = styled.div`
  padding-bottom: 5.5rem;
`;

const MarginWrapper = styled.div`
  margin-bottom: 2rem;
  ${map({ tablet: '4.8rem' }, (size) => `margin-bottom: ${size};`)}
`;

type Props = {};

type FormValues = {
  receiverEmail: string;
  receiverPhoneNumber: string;
};

const Delivery = (props: Props) => {
  const purchase = usePurchase();
  const navigate = useNavigate();

  const [isTimezoneSelected, setIsTimezoneSelected] = useState<boolean>();
  const [isValidDate, setIsValidDate] = useState(true);
  const { cardDetails: card } = purchase;

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(
      card,
      purchase.newPurchaseDetails.profileId ?? -1
    );
  }, [card, purchase.newPurchaseDetails]);

  const next = async (data: FormValues) => {
    const sendBySms =
      purchase.newPurchaseDetails.sendingMethod ===
        UserGiftCardSendingMethod.Sms ||
      purchase.newPurchaseDetails.sendingMethod === undefined;

    if (sendBySms) {
      if (!data.receiverPhoneNumber) {
        setError('receiverPhoneNumber', {
          type: 'validate',
          message: 'Please Enter Recipients Phone Number',
        });
        return;
      }
    } else {
      if (!data.receiverEmail) {
        setError('receiverEmail', {
          type: 'validate',
          message: 'Please Enter Recipients Email',
        });
        return;
      }
    }
    const sendImmediately = purchase.newPurchaseDetails.sendNow ?? true;
    if (!purchase.newPurchaseDetails.specialTimezoneId && !sendImmediately) {
      setIsTimezoneSelected(false);
      return;
    }
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      ...data,
      sendNow: sendImmediately,
      sendingMethod: sendBySms
        ? UserGiftCardSendingMethod.Sms
        : UserGiftCardSendingMethod.Email,
    });
    if (isValidDate) {
      navigate('/purchase/summary');
    }
    return;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      receiverEmail: purchase.newPurchaseDetails.receiverEmail || '',
      receiverPhoneNumber:
        purchase.newPurchaseDetails.receiverPhoneNumber || '',
    },
  });

  useEffect(() => {
    RudderStack.page('Purchase_Delivery');
  }, []);

  useEffect(() => {
    if (purchase.newPurchaseDetails.receiverEmail) {
      setValue('receiverEmail', purchase.newPurchaseDetails.receiverEmail);
    }
    if (purchase.newPurchaseDetails.receiverPhoneNumber) {
      setValue(
        'receiverPhoneNumber',
        purchase.newPurchaseDetails.receiverPhoneNumber
      );
    }
  }, [purchase.newPurchaseDetails, setValue]);

  const setDate = (dateISOString: string, timeZone: number) => {
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      specialTimezoneId: timeZone,
      deliveryDate: dateISOString,
    });
  };

  const backLink = '/purchase/customise';
  const isBranded = isBrandMerchant(card);

  return (
    <Body
      theme="white"
      disableFooter
      headerDisableShopNow
      headerVariant="purchase"
      displayMobileMenu={false}>
      <div className="content">
        <div className="purchase-delivery">
          <PurchaseBack isBranded={isBranded} backLink={backLink} />
          <PurchaseWrapper
            giftCard={card}
            showBackButton={false}
            cardImage={cardFrontImage}
            waveBackLink={backLink}>
            <div className="purchase-flow-header">Set delivery</div>
            <div className="purchase-flow-description">
              Let us know where & when you'd like your gift to be sent.
            </div>

            <SendVia style={{ marginBottom: '2rem' }}>
              <div style={{ flex: 1, marginRight: '0.5rem' }}>
                <SendItem
                  label="Send Via SMS"
                  onClick={() => {
                    purchase.setNewPurchaseDetails({
                      ...purchase.newPurchaseDetails,
                      sendingMethod: UserGiftCardSendingMethod.Sms,
                    });
                  }}
                  selected={
                    purchase.newPurchaseDetails.sendingMethod ===
                      UserGiftCardSendingMethod.Sms ||
                    purchase.newPurchaseDetails.sendingMethod === undefined
                  }
                />
              </div>
              <div style={{ flex: 1, marginLeft: '0.5rem' }}>
                <SendItem
                  label="Send Via Email"
                  selected={
                    purchase.newPurchaseDetails.sendingMethod ===
                    UserGiftCardSendingMethod.Email
                  }
                  onClick={() => {
                    purchase.setNewPurchaseDetails({
                      ...purchase.newPurchaseDetails,
                      sendingMethod: UserGiftCardSendingMethod.Email,
                    });
                  }}
                />
              </div>
            </SendVia>

            {purchase.newPurchaseDetails.sendingMethod ===
              UserGiftCardSendingMethod.Sms ||
            purchase.newPurchaseDetails.sendingMethod === undefined ? (
              <div>
                <DeliveryText>ENTER THE RECIPIENT'S PHONE NUMBER</DeliveryText>
                <Controller
                  key="Phone"
                  control={control}
                  rules={{
                    required: true,
                    pattern: MOBILE_REGEX,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MarginWrapper>
                      <MobileInput
                        error={!!errors.receiverPhoneNumber}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      />
                      {errors.receiverPhoneNumber && (
                        <div className="purchase-error-text">
                          <ErrorText text="Please enter the recipient's phone number" />
                        </div>
                      )}
                    </MarginWrapper>
                  )}
                  name="receiverPhoneNumber"
                />
              </div>
            ) : (
              <div>
                <DeliveryText>ENTER THE RECIPIENT'S EMAIL</DeliveryText>
                <Controller
                  key="Email"
                  control={control}
                  rules={{
                    pattern: EMAIL_REGEX,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MarginWrapper>
                      <Input
                        type="text"
                        label="Recipient Email Address"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value.toLowerCase()}
                        inputMode="email"
                        error={!!errors.receiverEmail}
                      />
                      {errors.receiverEmail && (
                        <div className="purchase-error-text">
                          <ErrorText
                            text={
                              errors.receiverEmail.type === 'pattern'
                                ? 'Please enter a valid email address'
                                : `Please enter the recipient's email address`
                            }
                          />
                        </div>
                      )}
                    </MarginWrapper>
                  )}
                  name="receiverEmail"
                />
              </div>
            )}

            <SubText>Would you like to schedule this gift?</SubText>

            <MarginWrapper>
              <SendVia>
                <div style={{ flex: 1, marginRight: '0.5rem' }}>
                  <SendItem
                    label="Send the card immediately"
                    selected={purchase.newPurchaseDetails.sendNow ?? true}
                    onClick={() => {
                      purchase.setNewPurchaseDetails({
                        ...purchase.newPurchaseDetails,
                        sendNow: true,
                        deliveryDate: new Date().toISOString(),
                      });
                    }}
                  />
                </div>

                <div style={{ flex: 1, marginLeft: '0.5rem' }}>
                  <SendItem
                    label="Send on a specific date"
                    selected={!(purchase.newPurchaseDetails.sendNow ?? true)}
                    onClick={() => {
                      purchase.setNewPurchaseDetails({
                        ...purchase.newPurchaseDetails,
                        sendNow: false,
                        deliveryDate: new Date().toISOString(),
                      });
                    }}
                  />
                </div>
              </SendVia>
            </MarginWrapper>
            <MarginWrapper>
              {!(purchase.newPurchaseDetails.sendNow ?? true) && (
                <div>
                  <AlertWrapper>
                    <Alert
                      title="The card may not be delivered at the exact selected time."
                      role="Info"
                      icon={Info}
                    />
                  </AlertWrapper>
                  <DateTimePicker
                    setDate={setDate}
                    date={purchase.newPurchaseDetails.deliveryDate}
                    timeZoneId={purchase.newPurchaseDetails.specialTimezoneId}
                    isTimeZoneSelected={isTimezoneSelected}
                    setIsTimeZoneSelected={setIsTimezoneSelected}
                    isValidDate={isValidDate}
                    setIsValidDate={setIsValidDate}
                  />
                </div>
              )}
            </MarginWrapper>
            <ButtonWrapper>
              <Button
                role="Primary"
                title="CONTINUE TO SUMMARY"
                onClick={handleSubmit(next)}
              />
            </ButtonWrapper>
          </PurchaseWrapper>
        </div>
      </div>
    </Body>
  );
};

export default Delivery;
