import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Body from '../../../components/Body';
import { Button } from '../../../components/Button';
import { ErrorText } from '../../../components/ErrorText';
import { MoneyInput } from '../../../components/Input';
import { PurchaseWrapper } from '../../../components/Purchase';
import { usePurchase } from '../../../context/PurchaseProvider';
import { getSelectedProfileImage } from '../../../helpers/cards';
import { isBrandMerchant } from '../../../helpers/utils';
import { RudderStack } from '../../../services/shared/RudderStack';
import PurchaseBack from '../common/Back';
import '../commonStyles.scss';
import ValuesGrid from '../general/ValuesGrid';
import { CUSTOM_BUTTON_TEXT } from '../shared';

import './styles.scss';

type Props = {};

const DEFAULT_MINIMUM_VALUE = 5;
const DEFAULT_MAXIMUM_VALUE = 1000;

const SetValue = (props: Props) => {
  const {
    newPurchaseDetails,
    setNewPurchaseDetails,
    cardDetails: card,
  } = usePurchase();
  const navigate = useNavigate();

  const [selectedProfileId, setSelectedProfileId] = useState(
    newPurchaseDetails.profileId || 0
  );

  const secondLowestValue = useMemo(
    () => `$${card.presetValues?.split(',')[1]}` || '',
    [card.presetValues]
  );

  const minimumValue = useMemo(
    () => card.minimumValue || DEFAULT_MINIMUM_VALUE,
    [card.minimumValue]
  );
  const maximumValue = useMemo(
    () => card.maximumValue || DEFAULT_MAXIMUM_VALUE,
    [card.maximumValue]
  );

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(card, selectedProfileId);
  }, [card, selectedProfileId]);

  const [inputValue, setInputValue] = useState('');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const refinedInputValue = e.currentTarget.value.replace(/[^0-9]/g, '');
    setInputValue(refinedInputValue);
  };

  const getSelectedValue = useCallback(() => {
    const { value } = newPurchaseDetails;
    const presetValueArray = card.presetValues?.split(',');
    if (value) {
      if (value && presetValueArray?.includes(value.toString())) {
        return `$${value}`;
      } else {
        setInputValue(value.toString());
        return CUSTOM_BUTTON_TEXT;
      }
    } else {
      return secondLowestValue;
    }
  }, [card.presetValues, newPurchaseDetails, secondLowestValue]);

  const [selectedValue, setSelectedValue] = useState(getSelectedValue);
  const handleSelectedValue = (value: string) => {
    setSelectedValue(value);
    setInputError(false);
    setErrorText('');
  };

  const [errorText, setErrorText] = useState('');
  const [inputError, setInputError] = useState(false);

  const validateCustomInput = useCallback(
    (customInputValue: string) => {
      const numericCustomValue = parseInt(customInputValue);
      if (numericCustomValue < minimumValue && !isNaN(numericCustomValue)) {
        setInputError(true);
        setErrorText(`Must have a minimum value of $${minimumValue}`);
        return false;
      } else if (numericCustomValue > maximumValue) {
        setInputError(true);
        setErrorText(`Must have a maximum value of $${maximumValue}`);
        return false;
      } else if (customInputValue && !numericCustomValue) {
        setInputError(true);
        setErrorText('Please enter your custom value');
      } else {
        setInputError(false);
        setErrorText('');
        return true;
      }
    },
    [maximumValue, minimumValue]
  );

  useEffect(() => {
    RudderStack.page('Purchase_SetValue');
  }, []);

  useEffect(() => {
    setSelectedValue(getSelectedValue);
  }, [getSelectedValue]);

  useEffect(() => {
    if (selectedValue !== CUSTOM_BUTTON_TEXT) {
      setInputValue('');
    }
  }, [selectedValue, setInputValue]);

  useEffect(() => {
    if (selectedValue === CUSTOM_BUTTON_TEXT) {
      validateCustomInput(inputValue);
    }
  }, [selectedValue, inputValue, validateCustomInput]);

  useEffect(() => {
    setSelectedProfileId(newPurchaseDetails.profileId || 0);
  }, [newPurchaseDetails.profileId]);

  const onContinue = () => {
    if (!inputError) {
      setNewPurchaseDetails({
        ...newPurchaseDetails,
        value:
          selectedValue === CUSTOM_BUTTON_TEXT
            ? parseInt(inputValue.replace('$', ''))
            : parseInt(selectedValue.replace('$', '')),
      });
      navigate('/purchase/personalise');
    }
  };

  const onBlur = () => {
    if (!inputValue && selectedValue === CUSTOM_BUTTON_TEXT) {
      setInputError(true);
      setErrorText('Please enter your custom value');
    }
  };
  const backLink = useMemo(() => {
    return `/shop/${newPurchaseDetails.giftCardId}`;
  }, [newPurchaseDetails.giftCardId]);

  const isBranded = isBrandMerchant(card);

  return (
    <Body
      theme="white"
      disableFooter
      headerDisableShopNow
      headerVariant="purchase"
      displayMobileMenu={false}>
      <div className="content">
        <PurchaseBack isBranded={isBranded} backLink={backLink} />
        <PurchaseWrapper
          giftCard={card}
          cardImage={cardFrontImage}
          showBackButton={false}
          waveBackLink={backLink}>
          <div className="purchase-flow-header">
            How much would you like to gift?
          </div>
          <div className="purchase-flow-description">
            Select a preset value, or choose a custom value between $
            {minimumValue} & ${maximumValue}.
          </div>
          <ValuesGrid
            values={card.presetValues || ''}
            selectedValue={selectedValue}
            handleSelectedValue={handleSelectedValue}
            inputValue={inputValue}
          />
          {selectedValue === CUSTOM_BUTTON_TEXT && (
            <div className="setValueInputSection">
              <MoneyInput
                type="text"
                label="Custom Value"
                value={`$ ${inputValue}`}
                onChange={onInputChange}
                className="inputSection"
                autoFocus
                onBlur={onBlur}
                errorText={errorText}
              />
              {errorText && (
                <div className="errorText">
                  <ErrorText text={errorText} />
                </div>
              )}
            </div>
          )}
          <Button
            title="CONTINUE TO RECIPIENT"
            role="Primary"
            onClick={onContinue}
          />
        </PurchaseWrapper>
      </div>
    </Body>
  );
};

export default SetValue;
