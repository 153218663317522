import { Hero, HeroTitle } from '../../components/Hero';
import FixedColumn from '../../components/Layouts/FixedColumn';
import TermsAndConditions from '../../components/TermsAndConditions';
import Hand from '../../assets/svg/Hand.svg';
import Present from '../../assets/svg/Present.svg';
import './terms.scss';
import Body from '../../components/Body';
import { useEffect } from 'react';
import { RudderStack } from '../../services/shared/RudderStack';

const Terms = () => {

  useEffect(() => {
    RudderStack.page("Terms");
  },[])


  return (
    <Body theme="white">
      <div className="content">
        <Hero>
          <HeroTitle size="x-small">
            terms & <img className="icon" src={Present} alt="special present" />
            <br /> conditions
          </HeroTitle>
        </Hero>
        <FixedColumn hideFixedColumnMobile={true}>
          <TermsAndConditions />
          <img
            src={Hand}
            alt="hand holding special card"
            style={{ transform: 'translateY(10rem)' }}
          />
        </FixedColumn>
      </div>
    </Body>
  );
};
export default Terms;
