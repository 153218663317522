import {
  Input,
  MobileInput,
  Select,
  TextAreaInput,
} from '../../components/Input';
import Hearts from '../../assets/svg/Hearts.svg';
import Stars from '../../assets/svg/Stars.svg';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button } from '../../components/Button';
import { EMAIL_REGEX, TEXT_REGEX } from '../../const/shared';
import './contact.scss';
import { Hero, HeroSubtitle, HeroTitle } from '../../components/Hero';
import axios from '@aws-amplify/storage/node_modules/axios';
import Modal from '../../components/Modal';
import { useEffect, useMemo, useState } from 'react';
import Body from '../../components/Body';
import EnvConfig from '../../config/EnvConfig';
import { RudderStack } from '../../services/shared/RudderStack';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Breakpoints from '../../const/breakpoints';

import { Helmet } from 'react-helmet';

type FormValues = {
  Name: string;
  PhoneNumber: string;
  Email: string;
  Reason:
    | 'General Enquiry'
    | 'Order Enquiry'
    | 'Product Enquiry'
    | 'Technical Support'
    | 'Feedback'
    | 'Other'
    | '';
  Subject: string;
  Message: string;
};

type requestType = {
  name: string;
  type:
    | 'General Enquiry'
    | 'Order Enquiry'
    | 'Product Enquiry'
    | 'Technical Support'
    | 'Feedback'
    | 'Other';
  subject: string;
  description: string;
  status: number;
  priority: number;
  email?: string;
  phone?: string;
};

const Contact = () => {
  const [openModal, setOpenModal] = useState<boolean|null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      Name: '',
      PhoneNumber: '',
      Email: '',
      Subject: '',
      Reason: '',
      Message: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    RudderStack.page('ContactUs');
  }, []);

  useEffect(() => {
    if (openModal === false) {
      window.location.reload();
    }
  }, [openModal]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (data.PhoneNumber.length < 3 && !data.Email) {
      setError('Email', { type: 'required' });
      setError('PhoneNumber', { type: 'required' });
      return;
    }
    let reqObj: requestType = {
      name: data.Name,
      type: data.Reason || 'General Enquiry',
      subject: data.Subject,
      description: data.Message,
      status: 2,
      priority: 1,
    };
    if (data.Email) {
      reqObj = { ...reqObj, email: data.Email };
    }
    if (data.PhoneNumber) {
      reqObj = { ...reqObj, phone: data.PhoneNumber };
    }

    axios
      .post(`${EnvConfig.freshDeskApiUrl}/api/v2/tickets`, reqObj, {
        auth: {
          username: EnvConfig.freshDeskApiKey,
          password: 'X',
        },
      })
      .then(() => {
        setOpenModal(true);
        reset();
      });
  };

  const options: { value: string; label: string }[] = [
    {
      value: 'General Enquiry',
      label: 'General Enquiry',
    },
    {
      value: 'Order Enquiry',
      label: 'Order Enquiry',
    },
    {
      value: 'Product Enquiry',
      label: 'Product Enquiry',
    },
    {
      value: 'Technical Support',
      label: 'Technical Support',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  const { windowWidth } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return windowWidth < Breakpoints.tablet;
  }, [windowWidth]);
  return (
    <Body theme="sea-green">
      <Helmet>
        <title>Special Gift Cards | Contact Us</title>
        <meta
          name="description"
          content="Need assistance with your Special Gift Card? Please enquire with our friendly customer service team and we'll get back to you as soon as possible."
        />
      </Helmet>
      <div className="content contact">
        <Hero>
          <HeroTitle size="small">
            CONTACT <img className="icon" src={Hearts} alt="Hearts" />
          </HeroTitle>
          <HeroSubtitle style={{ marginTop: isMobile ? '5.6rem' : '9.5rem' }}>
            Gift Cards just became{' '}
            <span>
              Special<span>&trade;</span>
            </span>
          </HeroSubtitle>
        </Hero>
        <div className="contact-container">
          <img src={Stars} alt="Stars" />
          <p className="contact-title">make an enquiry</p>
          <Controller
            control={control}
            rules={{ required: true, pattern: TEXT_REGEX }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="contact-input">
                <Input
                  type={'text'}
                  value={value}
                  autoFocus
                  onChange={onChange}
                  label="Your name"
                  error={!!errors.Name}
                />
              </div>
            )}
            name="Name"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="contact-input">
                <MobileInput
                  value={value}
                  onChange={onChange}
                  country="au"
                  error={!!errors.PhoneNumber}
                />
              </div>
            )}
            name="PhoneNumber"
          />
          <Controller
            control={control}
            rules={{ pattern: EMAIL_REGEX }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="contact-input">
                <Input
                  type={'text'}
                  value={value}
                  inputMode="email"
                  onChange={onChange}
                  label="Email"
                  error={!!errors.Email}
                />
              </div>
            )}
            name="Email"
          />
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="contact-input">
                <Input
                  type={'text'}
                  value={value}
                  onChange={onChange}
                  label="Subject"
                  error={!!errors.Subject}
                />
              </div>
            )}
            name="Subject"
          />
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="contact-input">
                <Select
                  label="Reason for enquiry"
                  value={value}
                  onChange={(option) => onChange(option.value)}
                  options={options}
                  error={!!errors.Reason}
                />
              </div>
            )}
            name="Reason"
          />
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="contact-input">
                <TextAreaInput
                  value={`${value}`}
                  onChange={onChange}
                  label="Message..."
                  error={!!errors.Message}
                />
              </div>
            )}
            name="Message"
          />
          <div className="contact-input">
            <Button
              title="SUBMIT"
              role="Primary"
              startIconClass="heart-icon"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
      <Modal
        open={!!openModal}
        setOpen={setOpenModal}
        title="Thank you!"
        text="Thank you for your enquiry. We'll get back to you as soon as possible. Stay Special!"
      />
    </Body>
  );
};

export default Contact;
