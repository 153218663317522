import './accordion.scss';

interface Tab {
  label?: string;
  content?: string;
}

interface AccordionProps {
  tabs: Tab[];
}

export const Accordion = ({ tabs }: AccordionProps) => {
  return (
    <div className="special-accordion">
      {tabs.map((item, index) => (
        <div className="tab" key={index}>
          <input type="checkbox" id={`tab-check-${index}`} />
          <label className="tab-label" htmlFor={`tab-check-${index}`}>
            {item.label}
          </label>
          <div className="tab-content">{item.content}</div>
        </div>
      ))}
    </div>
  );
};
