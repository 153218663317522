import React from 'react';
import { ReactComponent as TagIcon } from '../../../assets/svg/Tag.svg';
import { TagWrapper, TagIconWrapper, TextStyle } from './styles';

interface OwnProps {
  discountText?: string;
}

const DiscountTag: React.FC<OwnProps> = ({ discountText }) => {
  return (
    <TagWrapper>
      <TagIconWrapper>
        <TagIcon height={'100%'} width={'100%'} />
      </TagIconWrapper>
      <TextStyle>{discountText}</TextStyle>
    </TagWrapper>
  );
};

export default DiscountTag;
