import { LottieOptions, useLottie } from 'lottie-react';

import { Confetti as ConfettiAnimations } from '../../assets/animations/Confetti';
import { Fireworks as FireworksAnimations } from '../../assets/animations/Fireworks';
import { GoldConfetti as GoldConfettiAnimations } from '../../assets/animations/GoldConfetti';

interface OwnProps {
  animationType?: string | null;
  loop?: boolean;
  autoPlay?: boolean;
}

const getAnimation = (animtaionName?: string | null) => {
  switch (animtaionName) {
    case 'confetti':
      return ConfettiAnimations;
    case 'fireworks':
      return FireworksAnimations;
    case 'goldConfetti':
      return GoldConfettiAnimations;
    default:
      return;
  }
};

const CelebrationAnimation: React.FC<OwnProps> = ({
  animationType,
  loop = false,
  autoPlay = true,
}) => {
  const options: LottieOptions = {
    animationData: getAnimation(animationType),
    loop: loop,
    autoPlay: autoPlay,
  };
  const { View } = useLottie(options);
  return View;
};

export default CelebrationAnimation;
