import React, { useMemo } from 'react';
import { AlertTemplateProps } from 'react-alert';
import Info from '../../assets/svg/toaster/Info.svg';
import Warning from '../../assets/svg/toaster/Warning.svg';
import Error from '../../assets/svg/toaster/Error.svg';

import styled from 'styled-components';

interface IContainerProps {
  borderColor: string;
  background: string;
}

const ToasterContainer = styled.div<IContainerProps>`
  border: 1px solid ${(props) => props.borderColor};
  margin: 0 0 5rem !important;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 1rem;
  background: ${(props) => props.background};

  .message {
    margin-top: 0.3rem;
    font-size: 1.3rem;
  }
`;

export const Toaster: React.FC<AlertTemplateProps> = ({
  style,
  options,
  message,
  close,
}) => {
  const { icon, background, borderColor } = useMemo(() => {
    switch (options.type) {
      case 'error':
        return { icon: Error, background: '#fff6f2', borderColor: '#ffdacc' };
      case 'info':
        return { icon: Warning, background: '#fffbf2', borderColor: '#ffdb99' };
      case 'success':
      default:
        return { icon: Info, background: '#f2fcfb', borderColor: '#b3f0e5' };
    }
  }, [options.type]);
  return (
    <ToasterContainer
      style={style}
      background={background}
      borderColor={borderColor}>
      <img src={icon} alt={options.type} />
      <div className="message">{message}</div>
    </ToasterContainer>
  );
};
