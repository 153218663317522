import { useState, useMemo } from 'react';

type Value = string | number | null | undefined;

interface Option {
  value: Value;
  label: string;
}
interface SelectProps {
  label?: string;
  value?: Value;
  placeholder?: string;
  options?: Option[];
  onChange?: (item: Option) => void;
  error?: boolean;
}

const Select = ({
  label,
  value,
  placeholder,
  options = [],
  onChange,
  error,
}: SelectProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<Value>(value);

  const onShowDropdown = () => setShowDropdown(!showDropdown);

  const onClickOption = (item: Option) => {
    setSelectValue(item.value);
    if (onChange) {
      onChange(item);
    }
  };

  const displayLabel = useMemo(() => {
    if ((!selectValue && selectValue !== 0) || options.length < 1) {
      return placeholder;
    }
    return (
      options.find((option) => option.value === selectValue)?.label ||
      placeholder
    );
  }, [selectValue, options, placeholder]);

  const hasDropdown = useMemo(() => {
    return showDropdown && options?.length > 0;
  }, [showDropdown, options]);

  const getLabelClass = () => {
    if (showDropdown) return 'focus-';
    if (selectValue || selectValue === 0) {
      return 'value-';
    }
    return '';
  };

  return (
    <label
      className={`special-input select ${error && 'select-error'} ${
        hasDropdown ? 'active-dropdown' : ''
      }`}
      onClick={onShowDropdown}>
      {!!label && (
        <span className={`${getLabelClass()}label ${error && 'error'}`}>
          {label}
        </span>
      )}
      <div className={`value ${hasDropdown ? 'active' : ''}`}>
        {displayLabel ||
          (showDropdown && <span style={{ color: '#728291' }}>Select</span>)}
      </div>
      {hasDropdown && (
        <ul className="select-options">
          {options?.map((option) => (
            <li key={option.value} onClick={() => onClickOption(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </label>
  );
};

export default Select;
