import React from 'react';
import Flower from '../../../assets/svg/Flower.svg';
import './side-drawer-loader.scss';

type Props = { isLoading: boolean };

const SideDrawerLoader = ({ isLoading }: Props) => {
  return (
    <div className="side-drawer-loader-wrapper">
      {isLoading && (
        <>
          <img className="loader" src={Flower} alt="loading" />
          <p>Loading...</p>
        </>
      )}
    </div>
  );
};

export default SideDrawerLoader;
