import { useRef, useEffect, useMemo } from 'react';

// Styles
import './homepage.scss';

// Components
import Scroller from '../../components/Scroller';
import Banner from '../../components/Banner';
import { Hero, HeroTitle } from '../../components/Hero';
import { Button } from '../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import Body from '../../components/Body';

// Assets
import ArrowDown from '../../assets/svg/ArrowDown.svg';
import Card from '../../assets/svg/CardExample.svg';
import Eye from '../../assets/svg/home/Eye.svg';
import Glasses from '../../assets/svg/home/Glasses.svg';
import Sticker from '../../assets/svg/home/Sticker.svg';
import WhatMakesUsSpecial from '../../assets/svg/home/WhatMakesUsSpecial.svg';
import Heart from '../../assets/svg/home/Heart.svg';
import Flower from '../../assets/svg/home/Flower.svg';
import Pillow from '../../assets/svg/home/Pillow.svg';
import Star from '../../assets/svg/home/Star.svg';
import HeroStar from '../../assets/svg/home/HeroStar.svg';
import FaceBlonde from '../../assets/svg/home/FaceBlonde.svg';
import BannerIcon from '../../assets/svg/home/BannerIcon.svg';
import ImageLeft from '../../assets/svg/home/ImageLeft.svg';
import ImageRight from '../../assets/svg/home/ImageRight.svg';
import VennHeart from '../../assets/svg/home/VennHeart.svg';
import SmallStars from '../../assets/svg/home/SmallStars.svg';
import { AppleUrl, GoogleUrl } from '../../util/StoreUrls';
import styled from 'styled-components';
import EnvConfig, { HomePageCard } from '../../config/EnvConfig';
import ShopCard from '../../components/ShopCard';
import { map } from '../../const/theme';
import { ScrollerMessage } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import PageLoader from '../../components/PageLoader';
import { usePromotion } from '../../hooks/usePromotion';
import { GET_APPS_ID } from './consts';
import { RudderStack } from '../../services/shared/RudderStack';
import BrandScroller from '../../components/BrandScroller';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Breakpoints from '../../const/breakpoints';
import FathersDayBanner from '../../components/Banners/FathersDayBanner';

type Ref = null | HTMLDivElement;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 2.9rem;
  color: ${(props) => props.theme.colours.white};
  margin-bottom: 2.2rem;
  letter-spacing: 0.3px;
  ${map({ laptop: '4rem' }, (x) => `margin-bottom: ${x};`)}
  .right-container {
    flex: 1 1 auto;
    justify-content: flex-end;
    display: none;
    ${map({ laptop: 'flex' }, (display) => `display: ${display};`)}
    button {
      .chevron-right-icon {
        filter: invert(1);
      }
    }
  }
`;

const FindSomethingSpecialContainer = styled.div`
  display: flex;
  align-items: center;
  .right-container {
    flex: 1 1 auto;
    justify-content: flex-end;
    display: none;
    ${map({ laptop: 'flex' }, (display) => `display: ${display};`)}
    button {
      color: ${(props) => props.theme.colours.black};
    }
  }
`;

const BrowseAllCardsMobile = styled.div`
  display: flex;
  ${map({ laptop: 'none' }, (display) => `display: ${display};`)}
  margin-bottom: 4rem;
  ${map({ laptop: '0' }, (x) => `margin-bottom: ${x};`)}
  button {
    .chevron-right-icon {
      filter: invert(1);
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
`;

const Homepage = () => {
  const specialRef = useRef<Ref>(null);
  const appSectionRef = useRef<Ref>(null);
  const navigate = useNavigate();
  const { isLoadingLoggedInInfo } = useAuth();
  const { displayPromo } = usePromotion();
  const scrollTo = (ref: Ref) => {
    ref?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  };

  useEffect(() => {
    RudderStack.page('HomePage');
  }, []);

  useEffect(() => {
    const hasAppSectionId = window.location.hash.includes(GET_APPS_ID);
    let delayScroll: NodeJS.Timeout | undefined = undefined;

    if (hasAppSectionId && !isLoadingLoggedInInfo) {
      delayScroll = setTimeout(() => {
        if (appSectionRef && appSectionRef.current) {
          const scrollHeight = appSectionRef.current.offsetTop;
          window.scrollTo({ behavior: 'smooth', top: scrollHeight, left: 0 });
        }
      }, 100);
    }
    return () => {
      delayScroll && clearTimeout(delayScroll);
    };
  });

  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  if (isLoadingLoggedInInfo) {
    return <PageLoader isLoading={isLoadingLoggedInInfo} />;
  }

  return (
    <>
      <Body theme="electric-blue" isHome={true}>
        {displayPromo ? (
          <FathersDayBanner
            onSomethingClick={() => scrollTo(specialRef?.current)}
          />
        ) : (
          <div className="content home">
            <p className="hello">
              👋 Hi there! We’re Special, and we’re putting the magic into Gift
              Cards.
            </p>
            <Hero showBorder={false}>
              <HeroTitle size="small">
                The gift{' '}
                <span className="no-wrap">
                  card
                  <img
                    className="icon card home-card"
                    src={Card}
                    alt="special card"
                  />
                </span>
                <br />
                <span className="no-wrap">
                  <img className="icon home-eye" src={Eye} alt="special eye" />{' '}
                  for{' '}
                  <img
                    className="icon home-glasses"
                    src={Glasses}
                    alt="special glasses"
                  />{' '}
                  <br />{' '}
                </span>
                every
                <img
                  className="icon no-pad home-star"
                  src={HeroStar}
                  alt="special star"
                />
                thing.{' '}
                <img
                  className="icon home-cake"
                  src={Sticker}
                  alt="special cake"
                />
              </HeroTitle>
            </Hero>
            <FindSomethingSpecialContainer>
              <div>
                <button
                  onClick={() => scrollTo(specialRef?.current)}
                  className="scroll-to">
                  <h2>Find something Special</h2>
                  <img src={ArrowDown} alt="Arrow Down" />
                </button>
              </div>
              <div className="right-container scroll-to">
                <Button
                  role="Primary"
                  title="SHOP NOW"
                  color="white"
                  startIconClass="flower-icon"
                  style={{ marginTop: 0, width: 'auto' }}
                  onClick={() => navigate('/shop')}
                />
              </div>
            </FindSomethingSpecialContainer>
          </div>
        )}
        <Scroller content={ScrollerMessage} />
        <div className="content home cards-container">
          <SectionHeader>
            Popular Special Cards
            <div className="right-container">
              <Button
                role="Secondary"
                title="BROWSE ALL CARDS"
                endIconClass="arrow-right-icon"
                style={{ marginTop: 0, width: 'auto' }}
                onClick={() => navigate('/shop')}
              />
            </div>
          </SectionHeader>
          <CardContainer>
            <div className="home-cards">
              {EnvConfig.popularCards.map((card: HomePageCard) => (
                <ShopCard
                  id={card.id}
                  key={card.id}
                  name={card.name}
                  maximalValue={card.maxValue}
                  minimalValue={card.minimalValue}
                  imgSrc={card.imgSrc}
                  tag={card.tag}
                  slug={card.slug}
                />
              ))}
            </div>
          </CardContainer>
          <BrowseAllCardsMobile>
            <Button
              role="Secondary"
              title="BROWSE ALL CARDS"
              endIconClass="arrow-right-icon"
              style={{ marginTop: '3.5rem' }}
              onClick={() => navigate('/shop')}
            />
          </BrowseAllCardsMobile>
        </div>

        <div className="content home">
          <div className="values-container">
            <img src={WhatMakesUsSpecial} alt="What makes us Special?" />
            <div className="values">
              <div className="value">
                <img src={Heart} alt="Heart" />
                <p>
                  gifts from thousands of places, australia wide, in-store &
                  online.
                </p>
              </div>
              <div className="value">
                <img src={Flower} alt="Flower" />
                <p>pay safer & faster using your mobile digital wallet.</p>
              </div>
              <div className="value">
                <img src={Pillow} alt="Ridged Squared" />
                <p>no fees to buy, send or use our Special cards.</p>
              </div>
            </div>
          </div>
        </div>
        <div ref={appSectionRef}>
          <Banner>
            <div className="app-banner" id={`SPECIAL_ID_${GET_APPS_ID}`}>
              <div>
                <h2>Have you got the App?</h2>
                <h3>
                  <b>Buy</b>, <b>send</b> and <b>manage</b> your Special Cards.
                </h3>
                <p>
                  Get yours now - available on the iOS and Android app stores!
                </p>
                <div className="app-links">
                  <a href={AppleUrl} target="_blank" rel="noreferrer">
                    <Button
                      role="App-Store"
                      color="transparent"
                      startIconClass={`apple-store-white-icon link`}
                    />
                  </a>
                  <a href={GoogleUrl} target="_blank" rel="noreferrer">
                    <Button
                      role="App-Store"
                      color="transparent"
                      startIconClass={`google-play-white-icon link`}
                    />
                  </a>
                </div>
              </div>
              <img src={BannerIcon} alt="Special App Icon" />
            </div>
          </Banner>
        </div>
        <div className="brand-section">
          <div className="content brand-section-header">
            <span className="header-title">
              Specials Cards from your favourite brands
              <img
                src={SmallStars}
                alt="Small Stars"
                className="brand-small-stars"
              />
            </span>
          </div>
          <BrandScroller />
          <div className="content brand-section-footer">
            <Link className="brand-button" to="/shop">
              <Button
                role={isMobileView ? 'Secondary' : 'Primary'}
                title="SHOP BRAND GIFT CARDS NOW"
                endIconClass={isMobileView ? 'arrow-right-icon' : ''}
              />
            </Link>
            <div className="brand-affiliation-text">
              *Special is not affiliated with these  retailers
            </div>
          </div>
        </div>
        <div ref={specialRef} className="content home home-blurb-container">
          <div className="home-blurb">
            <img className="face" src={FaceBlonde} alt="Special Character" />
            <h2>
              Special Cards can be spent at thousands of places, both large and
              small, in-store and online, across Australia. You aren’t locked in
              to a handful of specific major retailers.
            </h2>
            <Link className="leave-color" to="/how-it-works">
              <Button role="Primary" title="How It Works"></Button>
            </Link>
            <img className="star" src={Star} alt="Special Star" />
          </div>
        </div>
        <div className="content home cards-container">
          <SectionHeader>
            Special cards
            <div className="right-container">
              <Button
                role="Secondary"
                title="BROWSE ALL CARDS"
                endIconClass="arrow-right-icon"
                style={{ marginTop: 0, width: 'auto' }}
                onClick={() => navigate('/shop')}
              />
            </div>
          </SectionHeader>
          <CardContainer>
            <div className="home-cards">
              {EnvConfig.specialCards.map((card: HomePageCard) => (
                <ShopCard
                  id={card.id}
                  key={card.id}
                  name={card.name}
                  maximalValue={card.maxValue}
                  minimalValue={card.minimalValue}
                  imgSrc={card.imgSrc}
                  slug={card.slug}
                />
              ))}
            </div>
          </CardContainer>
          <BrowseAllCardsMobile>
            <Button
              role="Secondary"
              title="BROWSE ALL CARDS"
              endIconClass="arrow-right-icon"
              style={{ marginTop: '3.5rem' }}
              onClick={() => navigate('/shop')}
            />
          </BrowseAllCardsMobile>
        </div>
        <Scroller content={ScrollerMessage} />
        <div className="content home our-mission-pad">
          <div
            className="split-container reverse"
            style={{ paddingTop: '2rem' }}>
            <div className="split-content">
              <h2 className="stars-1">Surprise, delight & excite</h2>
              <p>
                <span>It all starts with the big reveal</span> <br />
                Special Cards are sent virtually-wrapped, so your Special
                recipient gets the full experience of a perfect surprise.
              </p>
              <p>
                <span>Timeless gifts to use at any time</span> <br />
                The balance on your Special Card doesn’t expire, so there's no
                rush for your recipient to choose what they want & get that
                Special something.
              </p>
            </div>
            <div className="split-image">
              <img src={ImageRight} alt="Special Redhead Figure" />
            </div>
          </div>
          <div className="breaker">
            <img src={VennHeart} alt="Interlinked Hearts" />
          </div>
          <div className="split-container">
            <div className="split-image">
              <img src={ImageLeft} alt="Special Purple Haired Figure" />
            </div>
            <div className="split-content">
              <h2 className="stars-2">Our mission</h2>
              <p>
                We’re here to change the way the world thinks about gifting,
                helping people connect with those they care about by giving
                gifts that have meaning beyond the mundane.
              </p>
              <Link className="link" to="/our-mission">
                <Button role="Primary" title="Learn More"></Button>
              </Link>
            </div>
          </div>
        </div>
      </Body>
    </>
  );
};
export default Homepage;
