import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { useApi } from '../../api/ApiProvider';
import Alert from '../../assets/svg/card-details/AlertIcons.svg';
import Present from '../../assets/svg/Present2.svg';
import Body from '../../components/Body';
import { Button } from '../../components/Button';
import {
  PurchaseDescription,
  PurchaseHeader,
  PurchaseWrapper,
} from '../../components/Purchase';
import SideDrawer, { ISideDrawerHandle } from '../../components/SideDrawer';
import Breakpoints from '../../const/breakpoints';
import { usePurchase } from '../../context/PurchaseProvider';
import { GiftCardDetailsVM } from '../../generated';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Retailers from '../MyCards/Retailers';
import { RudderStack } from '../../services/shared/RudderStack';
import { useAppContext } from '../../context/AppProvider';
import BrandBlock from '../../components/BrandBlock';
import { Tag } from '../../components/Tag';
import { isBrandMerchant } from '../../helpers/utils';
import Divider from '../../components/Divider';
import { Helmet } from 'react-helmet';

import {
  ButtonWrapper,
  DividerWrapper,
  Faq,
  HowItWorks,
  ImportantHeader,
  ImportantText,
  LinkWrapper,
  StaticImportantText,
  TagWrapper,
  Terms,
} from './styles';
import BrandRetailers from '../MyCards/BrandRetailers';
import { MAX_PAGINATION_TAKE_SIZE } from '../../shared/const';

const CardDetails = () => {
  const navigate = useNavigate();
  const purchase = usePurchase();
  const { startNewPurchaseAttempt } = useAppContext();

  let { id } = useParams();
  let slug = id ? id.toString() : '';

  const { giftCardApi, merchantApi } = useApi();
  const [giftCard, setGiftCard] = useState<GiftCardDetailsVM>();
  const sideDrawerRef = useRef<ISideDrawerHandle>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMerchantsLoading, setIsMerchantsLoading] = useState(true);
  const [merchantLocations, setMerchantLocations] = useState(0);
  const retailersDisplay: number = giftCard?.retailersCount ?? 0;

  useEffect(() => {
    RudderStack.page('CardDetails');
  }, []);

  useEffect(() => {
    const delayScroll = setTimeout(() => {
      window.scrollTo({ behavior: 'auto', top: 0, left: 0 });
    }, 100);
    return () => {
      delayScroll && clearTimeout(delayScroll);
    };
  }, []);

  useEffect(() => {
    giftCardApi
      .getGiftCard(slug)
      .then((res) => {
        setGiftCard(res.data);
      })
      .catch((err) => {
        if (err.response.status > 500) {
          navigate('/500');
        }
        if (err.response.status > 400) {
          navigate('/404');
        }
      });
    setIsLoading(false);
  }, [giftCardApi, id, navigate, slug]);

  const backButton = {
    text: 'more cards',
    link: '/shop',
  };

  const onBuyNow = () => {
    startNewPurchaseAttempt();
    const newerPurchaseDetails = {
      giftCardId: giftCard?.id,
      profileId:
        (giftCard?.giftCardProgram?.programProfiles &&
          giftCard?.giftCardProgram?.programProfiles[0]?.id) ??
        -1,
    };

    const newerCardDetails = giftCard || {};
    purchase.setCardAndPurchaseDetails(
      newerPurchaseDetails,
      newerCardDetails,
      merchantLocations
    );
  };

  const buyNowAndNavigate = () => {
    onBuyNow();
    navigate('/purchase/set-value');
  };

  const footerButton = {
    text: 'Buy Now',
    buttonImage: Present,
    disabled: isLoading && isMerchantsLoading,
    onClick: onBuyNow,
    to: '/purchase/set-value',
  };
  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  const isBranded = isBrandMerchant(giftCard);
  const merchantId = useMemo(
    () => giftCard?.giftCardProgram?.merchantId || -1,
    [giftCard]
  );
  const displayAffiliationText = useMemo(() => {
    if (!giftCard) {
      return false;
    }
    return giftCard.giftCardProgram?.displayAffiliationText ?? false;
  }, [giftCard]);

  useEffect(() => {
    (async () => {
      if (isBranded) {
        try {
          setIsMerchantsLoading(true);
          const merchantLocationResponse =
            await merchantApi.getMerchantLocations(
              merchantId,
              undefined,
              undefined,
              undefined,
              undefined,
              MAX_PAGINATION_TAKE_SIZE,
              undefined
            );
          const allLocations = merchantLocationResponse.data.results || [];
          setMerchantLocations(allLocations.length);
          setIsMerchantsLoading(false);
        } catch (error) {
          setIsMerchantsLoading(false);
          console.log(error);
        }
      }
      setIsMerchantsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBranded, merchantId]);

  const cardName = useMemo(() => {
    if (isBranded) {
      if (displayAffiliationText) {
        return `Special Card for ${giftCard?.name}*`;
      }
      return `Special Card for ${giftCard?.name}`;
    }
    return giftCard?.name;
  }, [giftCard, isBranded, displayAffiliationText]);

  const isOnlineStore = useMemo(() => {
    return giftCard?.giftCardProgram?.brandMerchant?.hasOnlineStore ?? false;
  }, [giftCard]);

  const shouldHideRetailersButton = useMemo(() => {
    return merchantLocations === 0 && isBranded;
  }, [merchantLocations, isBranded]);

  const headerBottomMargin = useMemo(() => {
    if (isBranded) {
      return isMobileView ? '2.75rem' : '4rem';
    }
    return '1rem';
  }, [isBranded, isMobileView]);

  const spendAtText = useMemo(() => {
    if (isBranded) {
      return `Spend at ${merchantLocations} locations`;
    }
    return `Spend at ${retailersDisplay.toLocaleString('en-US')} retailers`;
  }, [isBranded, merchantLocations, retailersDisplay]);

  return (
    <Body theme="white" footerButton={footerButton} displayCareers={false}>
      <Helmet>
        <title>
          Special Gift Cards | {giftCard?.metaTitle ? giftCard?.metaTitle : ''}
        </title>
        <meta
          name="description"
          content={giftCard?.metaDescription ? giftCard?.metaDescription : ''}
        />
      </Helmet>

      <div className="content">
        <PurchaseWrapper
          giftCard={giftCard}
          backButton={backButton}
          waveBackLink={backButton.link}>
          {isLoading === false && isMerchantsLoading === false ? (
            <>
              <BrandBlock isBranded={isBranded}>
                <TagWrapper>
                  {isOnlineStore && <Tag title="ONLINE" role="GreenBorder" />}
                  {merchantLocations > 0 && (
                    <Tag title="IN-STORE" role="Orange" />
                  )}
                </TagWrapper>
              </BrandBlock>
              <PurchaseHeader
                containerStyle={{ marginBottom: headerBottomMargin }}>
                {cardName}
              </PurchaseHeader>
              {!isBranded && (
                <PurchaseDescription>
                  {giftCard?.description}
                </PurchaseDescription>
              )}
            </>
          ) : (
            <>
              <ContentLoader
                speed={2}
                width={390}
                height={240}
                viewBox="0 0 476 300"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                <rect x="0" y="0" rx="0" ry="0" width="390" height="80" />
                <rect x="0" y="100" rx="0" ry="0" width="390" height="20" />
                <rect x="0" y="140" rx="0" ry="0" width="390" height="20" />
                <rect x="0" y="180" rx="0" ry="0" width="390" height="20" />
                <rect x="0" y="220" rx="0" ry="0" width="390" height="20" />
              </ContentLoader>
            </>
          )}
          <ButtonWrapper>
            {!isMobileView && (
              <Button
                onClick={buyNowAndNavigate}
                role="Primary"
                disabled={isLoading && isMerchantsLoading}
                title="buy now"
                startIconClass="present-icon"></Button>
            )}
            {!shouldHideRetailersButton && (
              <Button
                buttonClass="light-border split no-case"
                onClick={() => {
                  sideDrawerRef.current?.toggleShow();
                }}
                color="white"
                role="Primary"
                title={spendAtText}
                startIconClass="flower-icon"
                endIconClass="chevron-right-icon"></Button>
            )}
          </ButtonWrapper>
          <BrandBlock isBranded={isBranded}>
            <ImportantHeader>Important Information</ImportantHeader>
            {displayAffiliationText && (
              <ImportantText>
                *This brand is not affiliated with Special.
              </ImportantText>
            )}
            <ImportantText>{giftCard?.retailersDescription}</ImportantText>
            <StaticImportantText>
              Why wait...gift cards just got Special.
            </StaticImportantText>
            <DividerWrapper>
              <Divider />
            </DividerWrapper>
          </BrandBlock>
          {!isBranded && (
            <div>
              <HowItWorks>How it works</HowItWorks>
              <Faq>
                <img src={Alert} alt="tick" />
                Gift from big brands & local heroes, in-store & online
              </Faq>
              <Faq>
                <img src={Alert} alt="tick" />
                Personalise, add a sweet design & send it anytime.
              </Faq>
              <Faq>
                <img src={Alert} alt="tick" />
                Gifts that never expire, any value from $5 to $1000.
              </Faq>
            </div>
          )}
          <LinkWrapper href="https://special.freshdesk.com/support/home">
            <Button
              buttonClass="light-border"
              color="white"
              style={{
                height: isMobileView ? 60 : 38,
                maxWidth: isMobileView ? '100%' : '35rem',
                textTransform: 'none',
              }}
              role="Primary"
              title="GIFTING FAQs"
              startIconClass={Present}></Button>
          </LinkWrapper>

          <Terms>
            View gift card
            <a href="/terms-conditions">terms & conditions.</a>
          </Terms>
        </PurchaseWrapper>
        <SideDrawer hideClose={true} ref={sideDrawerRef}>
          {isBranded ? (
            <BrandRetailers
              onBackClose={true}
              sideDrawerRef={sideDrawerRef}
              giftCardId={+(id || -1)}
              merchantId={merchantId}
              brandName={giftCard?.name || ''}
              hasOnlineStore={isOnlineStore}
            />
          ) : (
            <Retailers
              retailersDescription={giftCard?.retailersDescription ?? ''}
              onBackClose={true}
              sideDrawerRef={sideDrawerRef}
              giftCardId={+(id || -1)}
              locationCount={giftCard?.locationCount ?? 0}
              slug={slug}
            />
          )}
        </SideDrawer>
      </div>
    </Body>
  );
};
export default CardDetails;
