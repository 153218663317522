import { AxiosResponse } from 'axios';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { from, Subscription } from 'rxjs';
import { useApi } from '../../api/ApiProvider';
import {
  UserGiftCardDetailsVM,
  UserGiftCardStatus,
  UserGiftCardVM,
} from '../../generated';
import Body from '../../components/Body';
import MyCardItem from './MyCardItem';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Tab } from '../../components/Tab';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useMemo } from 'react';
import './my-cards.scss';
import PageLoader from '../../components/PageLoader';
import SideDrawer, { ISideDrawerHandle } from '../../components/SideDrawer';
import { IsMobileView, IsTabletView } from '../../util/helper';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MyCardDetails from './MyCardDetails';
import { Button } from '../../components/Button';
import NoCardImage from '../../assets/svg/CardsNotFound.svg';
import RecentActivities from './RecentActivities';
import SpendInStore from './SpendInStore';
import SpendOnline from './SpendOnline';
import ManagePin from './ManagePin';
import ManagePinVerify from './ManagePinVerify';
import SetPin from './SetPin';
import ConfirmPin from './ConfirmPin';
import { ManageCardProvider } from '../../context/ManagePinProvider';
import PinUpdated from './PinUpdated';
import Retailers from './Retailers';
import SideDrawerLoader from './SideDrawerLoader';
import { MAX_PAGINATION_TAKE_SIZE } from '../../shared/const';
import { map } from '../../const/theme';
import { useAuthGuard } from '../../hooks/useAuthGuard';
import { RudderStack } from '../../services/shared/RudderStack';
import { sortPastCards } from '../../helpers/cards';
import BrandRetailers from './BrandRetailers';

type Props = {};

const MyCardListingContainer = styled.div`
  padding-bottom: 14rem;
  ${map({ tablet: '0' }, (x) => `padding-bottom: ${x};`)}
`;

const TabContainer = styled.div`
  padding-top: 8rem;
  padding-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

enum MyCardsTab {
  Active,
  Past,
}

export interface UserGiftCard extends UserGiftCardVM {
  details?: UserGiftCardDetailsVM;
}

const MyCards = (props: Props) => {
  const isMobileView = IsMobileView();
  const isTabletView = IsTabletView();
  const { userGiftCardApi } = useApi();
  const [userGiftCards, setUserGiftCards] = useState<Array<UserGiftCard>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<MyCardsTab>(MyCardsTab.Active);
  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const sideDrawerRef = useRef<ISideDrawerHandle>(null);
  const navigate = useNavigate();
  const [selectedUserGiftCardId, setSelectedUserGiftCardId] = useState<
    number | undefined
  >(undefined);
  const activeUserGiftCard: UserGiftCard | undefined = useMemo(() => {
    return userGiftCards.find((x) => x.id === selectedUserGiftCardId);
  }, [userGiftCards, selectedUserGiftCardId]);
  const { windowWidth: width } = useWindowDimensions();

  useAuthGuard();

  const gap = useMemo(() => {
    if (width > 2200) {
      return 320;
    }
    if (width > 1800) {
      return 220;
    }
    if (width > 1000) {
      return 120;
    }

    return 220;
  }, [width]);

  const spaceBetween = useMemo(() => {
    if (isMobileView) {
      return gap;
    } else if (isTabletView) {
      return gap + 100;
    } else {
      return gap / 2;
    }
  }, [isMobileView, isTabletView, gap]);

  useEffect(() => {
    RudderStack.page('MyCards');
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const subscription: Subscription = from(
      userGiftCardApi.getUserGiftCards(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        MAX_PAGINATION_TAKE_SIZE
      )
    ).subscribe((result: AxiosResponse) => {
      setUserGiftCards(result.data.results);
      setIsLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [userGiftCardApi]);
  const { activeTabCount, pastCardCount } = useMemo(() => {
    return {
      activeTabCount: userGiftCards.filter(
        (x) => x.status === UserGiftCardStatus.Claimed
      ).length,
      pastCardCount: userGiftCards.filter(
        (x) =>
          x.status === UserGiftCardStatus.Past ||
          x.status === UserGiftCardStatus.Blocked ||
          x.status === UserGiftCardStatus.Cancelled
      ).length,
    };
  }, [userGiftCards]);

  const userGiftCardsToDisplay = useMemo(() => {
    if (activeTab === MyCardsTab.Active) {
      return userGiftCards
        .filter((x) => x.status === UserGiftCardStatus.Claimed)
        .sort((a, b) => ((a.activated || 0) < (b?.activated || 0) ? 1 : -1));
    }
    if (activeTab === MyCardsTab.Past) {
      const pastCards = userGiftCards.filter(
        (x) =>
          x.status === UserGiftCardStatus.Past ||
          x.status === UserGiftCardStatus.Blocked ||
          x.status === UserGiftCardStatus.Cancelled
      );
      const sortedPastCards = sortPastCards(pastCards);
      return sortedPastCards;
    }
    return userGiftCards;
  }, [userGiftCards, activeTab]);

  const hasCard = useMemo(() => {
    return userGiftCards.length > 0;
  }, [userGiftCards]);

  return (
    <div className="my-cards-container">
      <PageLoader isLoading={isLoading} />
      <Body
        theme="white"
        disableFooter
        headerVariant="my-cards"
        displayMobileMenu={showMobileMenu}>
        {hasCard ? (
          <>
            {!showMobileMenu && setShowMobileMenu(true)}
            <TabContainer>
              <div id="tabs">
                <Tab
                  role={activeTab === MyCardsTab.Active ? 'Active' : 'Regular'}
                  onClick={() => {
                    setActiveTab(MyCardsTab.Active);
                  }}
                  title={`${activeTabCount} Active`}
                />
                <Tab
                  role={activeTab === MyCardsTab.Past ? 'Active' : 'Regular'}
                  onClick={() => {
                    setActiveTab(MyCardsTab.Past);
                  }}
                  title={`${pastCardCount} Past`}
                />
              </div>
            </TabContainer>
            <MyCardListingContainer>
              <Swiper
                className="no-select"
                spaceBetween={spaceBetween}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                slidesPerView={isMobileView ? 2 : 3}
                centeredSlides
                modules={[Pagination]}>
                {userGiftCardsToDisplay.map((userGiftCard, index) => (
                  <SwiperSlide key={userGiftCard.id}>
                    {({ isActive }) => (
                      <MyCardItem
                        width={width / 3 - gap}
                        isActive={isActive}
                        isSelected={selectedUserGiftCardId === userGiftCard.id}
                        userGiftCard={userGiftCard}
                        onClick={() => {
                          setSelectedUserGiftCardId(userGiftCard.id);
                          navigate(`/my-cards/${userGiftCard.id}`);
                          sideDrawerRef.current?.toggleShow();
                        }}
                        setDetails={(details: UserGiftCardDetailsVM) => {
                          userGiftCard.details = details;
                          setUserGiftCards([...userGiftCards]);
                        }}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </MyCardListingContainer>
            <SideDrawer ref={sideDrawerRef}>
              <ManageCardProvider>
                {!activeUserGiftCard?.details ? (
                  <SideDrawerLoader isLoading={true} />
                ) : (
                  <Routes>
                    <Route
                      path="/:id"
                      element={
                        <MyCardDetails userGiftCard={activeUserGiftCard} />
                      }
                    />
                    <Route
                      path="/:id/transactions"
                      element={
                        <RecentActivities userGiftCard={activeUserGiftCard} />
                      }
                    />
                    <Route
                      path="/:id/spend-in-store"
                      element={<SpendInStore />}
                    />
                    <Route
                      path="/:id/spend-online"
                      element={
                        <SpendOnline userGiftCard={activeUserGiftCard} />
                      }
                    />
                    <Route
                      path="/:id/manage-pin"
                      element={<ManagePin userGiftCard={activeUserGiftCard} />}
                    />
                    <Route
                      path="/:id/manage-pin/verify"
                      element={
                        <ManagePinVerify userGiftCard={activeUserGiftCard} />
                      }
                    />
                    <Route
                      path="/:id/manage-pin/set-pin"
                      element={<SetPin userGiftCard={activeUserGiftCard} />}
                    />
                    <Route
                      path="/:id/manage-pin/confirm-pin"
                      element={<ConfirmPin userGiftCard={activeUserGiftCard} />}
                    />
                    <Route
                      path="/:id/manage-pin/pin-updated"
                      element={<PinUpdated userGiftCard={activeUserGiftCard} />}
                    />
                    <Route
                      path="/:id/retailers"
                      element={
                        <Retailers
                          sideDrawerRef={sideDrawerRef}
                          retailersDescription={
                            activeUserGiftCard?.details?.giftCard
                              ?.retailersDescription ?? ''
                          }
                          onBackClose={false}
                          giftCardId={
                            activeUserGiftCard?.details?.giftCardId ?? -1
                          }
                          locationCount={
                            activeUserGiftCard?.details?.giftCard
                              ?.locationCount ?? 0
                          }
                        />
                      }
                    />
                    <Route
                      path="/:id/brand-retailers"
                      element={
                        <BrandRetailers
                          onBackClose={false}
                          sideDrawerRef={sideDrawerRef}
                          giftCardId={
                            activeUserGiftCard.details.giftCardId ?? -1
                          }
                          merchantId={
                            activeUserGiftCard.brandMerchant?.id ?? -1
                          }
                          brandName={
                            activeUserGiftCard.brandMerchant?.name ?? ''
                          }
                          hasOnlineStore={
                            activeUserGiftCard.brandMerchant?.hasOnlineStore ??
                            false
                          }
                        />
                      }
                    />
                  </Routes>
                )}
              </ManageCardProvider>
            </SideDrawer>
          </>
        ) : (
          <>
            {isMobileView ? (
              <>
                {showMobileMenu && setShowMobileMenu(false)}
                <div className="noCardBackground">
                  <img
                    src={NoCardImage}
                    className="noCardImage"
                    alt="background"
                  />
                </div>
                <div className="no-card-content">
                  <p className="content-title">No cards here yet!</p>
                  <p className="content-text">
                    The best gifts to give are ones that people know you'll
                    love. Browse the range and let others know what you'd like.
                  </p>
                  <Button
                    role="Secondary"
                    title="Shop NOW"
                    startIconClass="heart-icon"
                    style={{ marginTop: '2.4rem' }}
                    onClick={() => navigate('/shop')}
                  />
                </div>
              </>
            ) : (
              <div className="noCardBackground">
                <div className="Modal">
                  <p className="modal-title">No cards here yet!</p>
                  <p className="modal-text">
                    The best gifts to give are ones that people know you'll
                    love. Browse the range and let others know what you'd like.
                  </p>
                  <Button
                    role="Secondary"
                    title="Shop NOW"
                    startIconClass="heart-icon"
                    style={{ marginTop: '2.4rem' }}
                    onClick={() => navigate('/shop')}
                  />
                </div>
                <img
                  src={NoCardImage}
                  className="noCardImage"
                  alt="background"
                />
              </div>
            )}
          </>
        )}
      </Body>
    </div>
  );
};

export default MyCards;
