import { useMemo } from 'react';
import Colors from '../../../const/Colors';
import { useActivation } from '../../../context/ActivationProvider';

export const useActivationBrands = () => {
  const activation = useActivation();
  const isBranded = useMemo(() => {
    return !!activation.activationData.activationDetails.brandMerchant;
  }, [activation.activationData.activationDetails.brandMerchant]);
  const {
    affiliationTextColour,
    primaryColour,
    squareCardColourLogo: logo,
  } = activation?.activationData?.activationDetails?.brandMerchant || {};
  const backgroundColor = useMemo(() => {
    return isBranded ? primaryColour || Colors.seaGreen : Colors.seaGreen;
  }, [isBranded, primaryColour]);

  const { displayAffiliationText } =
    activation?.activationData?.activationDetails || {};

  return {
    affiliationTextColour,
    backgroundColor,
    displayAffiliationText,
    logo,
    isBranded,
  };
};
