import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../404';
import CardReveal from './CardReveal';
import ConfirmPin from './ConfirmPin';
import Failed from './Failed';
import Landing from './Landing';
import SetPin from './SetPin';
import Success from './Success';
import Unwrap from './Unwrap';

const ActivationRouting = () => {
  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="unwrap" element={<Unwrap />} />
      <Route path="card-reveal" element={<CardReveal />} />
      <Route path="set-pin" element={<SetPin />} />
      <Route path="confirm-pin" element={<ConfirmPin />} />
      <Route path="success" element={<Success />} />
      <Route path="failed" element={<Failed />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ActivationRouting;
