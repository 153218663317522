import { SpecialCategoryDetailsVM } from '../../generated';
import RightArrow from '../../assets/svg/RightArrowBlue.svg';
import LeftArrow from '../../assets/svg/LeftArrowBlue.svg';
import DownCaret from '../../assets/svg/DownCaret.svg';
import UpCaret from '../../assets/svg/AccordionArrowUp.svg';
import './categories.scss';
import { useEffect, useRef, useState, useCallback } from 'react';
import AllCards from '../../assets/svg/categories/AllCards.svg';
import ActiveWear from '../../assets/svg/categories/ActiveWear.svg';
import Alcohol from '../../assets/svg/categories/Alcohol.svg';
import Clothing from '../../assets/svg/categories/Clothing.svg';
import Entertainment from '../../assets/svg/categories/Entertainment.svg';
import Food from '../../assets/svg/categories/Food.svg';
import HairAndBeauty from '../../assets/svg/categories/HairAndBeauty.svg';
import Health from '../../assets/svg/categories/Health.svg';
import HomeAndGarden from '../../assets/svg/categories/HomeAndGarden.svg';
import OutDoors from '../../assets/svg/categories/OutDoors.svg';
import Shopping from '../../assets/svg/categories/Shopping.svg';
import Tech from '../../assets/svg/categories/Tech.svg';

type props = {
  // categories: SpecialCategoryDetailsVM[];
  selected: SpecialCategoryDetailsVM[];
  setSelectedCategory: (
    category: SpecialCategoryDetailsVM,
    add: boolean
  ) => void;
  unfilterCategories: () => void;
};

const Categories = ({
  /*categories,*/ selected,
  setSelectedCategory,
  unfilterCategories,
}: props) => {
  const categories = [
    {
      id: 1,
      name: 'Activewear',
      image: ActiveWear,
    },
    {
      id: 2,
      name: 'Alcohol & Liquor',
      image: Alcohol,
    },
    {
      id: 3,
      name: 'Clothing & Accessories',
      image: Clothing,
    },
    {
      id: 4,
      name: 'Entertainment',
      image: Entertainment,
    },
    {
      id: 5,
      name: 'Food & Drink',
      image: Food,
    },
    {
      id: 6,
      name: 'Hair & Beauty',
      image: HairAndBeauty,
    },
    {
      id: 7,
      name: 'Health & Beauty',
      image: Health,
    },
    {
      id: 8,
      name: 'Home & Garden',
      image: HomeAndGarden,
    },
    {
      id: 9,
      name: 'Outdoors',
      image: OutDoors,
    },
    {
      id: 10,
      name: 'Shopping',
      image: Shopping,
    },
    {
      id: 11,
      name: 'Tech & Electronics',
      image: Tech,
    },
  ];
  const [scrollPosition, setScrollPosition] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [mouseX, setMouseX] = useState<number | null>(null);
  const [initialDown, setInitialDown] = useState<number | null>(null);
  const [initialOffset, setInitialOffset] = useState<number | null>(null);
  const [index, setIndex] = useState(0);
  const [selectedCategory, setCategory] = useState<
    { id: number; name: string; image: any } | undefined
  >();
  const ref = useRef<HTMLDivElement>(null);
  let startDrag: any;

  const handleScroll = (direction: 'left' | 'right') => {
    const containerWidth = 1440;
    const cardWidth = 158 * categories.length;

    if (cardWidth <= containerWidth) return;

    if (direction === 'left') {
      handleOffset(index + 1);
    }
    if (direction === 'right') {
      handleOffset(index - 1);
    }
  };

  const handleOffset = useCallback(
    (newIndex: number) => {
      const maxWidth = ref.current?.clientWidth || 1376;
      const cardWidth = 158 * (categories.length + 1);
      const rightMaxOffset = Math.max(cardWidth - 10, maxWidth) - maxWidth;

      let offset = newIndex * 158;
      if (newIndex < index && offset <= -1 * rightMaxOffset) {
        offset = -1 * rightMaxOffset;
        newIndex = index;
      }
      if (newIndex > index && offset >= 0) {
        offset = 0;
        newIndex = 0;
      }

      setIndex(newIndex);
      setScrollPosition(offset / 10);
    },
    [index, categories.length]
  );

  const handleDrag = (event: MouseEvent) => {
    const maxWidth = ref.current?.clientWidth || 1376;
    const cardWidth = 158 * (categories.length + 1);
    const rightMaxOffset = Math.max(cardWidth - 10, maxWidth) - maxWidth;

    const diff = event.clientX - (initialDown || 0);

    setScrollPosition(
      Math.min(
        0,
        Math.max(rightMaxOffset / -10, (initialOffset || 0) + diff / 10)
      )
    );
  };

  const handleTouchDrag = (event: any) => {
    const maxWidth = ref.current?.clientWidth || 1376;
    const cardWidth = 158 * (categories.length + 1);
    const rightMaxOffset = Math.max(cardWidth - 10, maxWidth) - maxWidth;

    const diff = event.touches[0].clientX - (initialDown || 0);

    setScrollPosition(
      Math.min(
        0,
        Math.max(rightMaxOffset / -10, (initialOffset || 0) + diff / 10)
      )
    );
  };

  const handleMouseDown = () => {
    startDrag = setTimeout(() => {
      setInitialDown(mouseX);
      setInitialOffset(scrollPosition);
      setIsDragging(true);
    }, 300);
  };

  const handleTouchStart = (event: any) => {
    startDrag = setTimeout(() => {
      setMouseX(event.touches[0].clientX);
      setInitialDown(mouseX);
      setInitialOffset(scrollPosition);

      setIsDragging(true);
    }, 100);
  };

  const handleMouseUp = () => {
    clearTimeout(startDrag);
    setTimeout(() => {
      setIsDragging(false);
      setInitialOffset(scrollPosition);
    });
  };

  const handleTouchEnd = () => {
    clearTimeout(startDrag);
    setIsDragging(false);
    setInitialOffset(scrollPosition);
  };

  const handleMouseMove = (event: any) => {
    setMouseX(event.clientX);
    if (isDragging) {
      handleDrag(event);
    }
  };

  useEffect(() => {
    const handleContext = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    window.addEventListener('resize', () => handleOffset(index));
    let innerRef = ref.current;
    innerRef?.addEventListener('contextmenu', handleContext);
    return () => {
      window.removeEventListener('resize', () => handleOffset(index));
      innerRef?.removeEventListener('contextmenu', handleContext);
    };
  }, [handleOffset, index]);

  const showBefore = () => {
    return scrollPosition !== 0;
  };

  const showAfter = () => {
    const maxWidth = ref.current?.clientWidth || 1376;
    const cardWidth = 158 * (categories.length + 1);
    const rightMaxOffset = Math.max(cardWidth - 10, maxWidth) - maxWidth;

    return rightMaxOffset !== scrollPosition * -1 * 10;
  };

  const mapCategory = (category: { id: number; name: string; image: any }) => {
    const isSelected = selected.some((selected) => selected.id === category.id);
    return (
      <button
        key={category.id}
        onClick={() => {
          if (!isDragging) {
            setSelectedCategory(category, !isSelected);
            setCategory(category);
          }
        }}
        className={`item ${isSelected && 'active'}`}>
        <img src={category.image} alt={category.name + ' category'} />
        <p>{category.name}</p>
      </button>
    );
  };
  return (
    <>
      <div
        className={`category-wrapper content desktop
        ${showBefore() && 'show-before'}
        ${showAfter() && 'show-after'}
        `}>
        <div className="navigation">
          <img
            src={LeftArrow}
            alt="left arrow"
            onClick={() => handleScroll('left')}
          />
          <img
            src={RightArrow}
            alt="right arrow"
            onClick={() => handleScroll('right')}
          />
        </div>
        <div
          onMouseDown={() => handleMouseDown()}
          onMouseUp={() => handleMouseUp()}
          onMouseMove={(event) => handleMouseMove(event)}
          onMouseLeave={() => setIsDragging(false)}
          onTouchStart={(event) => handleTouchStart(event)}
          onTouchEnd={() => handleTouchEnd()}
          onTouchMove={(event) => {
            handleTouchDrag(event);
          }}
          ref={ref}
          className={`items ${isDragging && 'is-dragging'}`}
          style={{
            height: categories.length > 0 ? '11.2rem' : '0rem',
            transform: `translateX(${scrollPosition}rem)`,
          }}>
          <button
            onClick={() => {
              unfilterCategories();
            }}
            className={`item ${!selected.length && 'active'}`}>
            <img src={AllCards} alt="All Cards" />
            <p>All Cards</p>
          </button>
          {categories.map((category) => mapCategory(category))}
        </div>
      </div>
      <div className="category-wrapper content mobile">
        <div
          className="category-header"
          onClick={() => setModalVisible(!modalVisible)}>
          <p style={{ textTransform: 'uppercase' }}>
            {selectedCategory?.name || 'All Cards'}
          </p>
          <div style={{ display: 'flex' }}>
            <p style={{ textTransform: 'uppercase', paddingRight: '1rem' }}>
              Categories
            </p>
            <img src={modalVisible ? UpCaret : DownCaret} alt="down carret" />
          </div>
        </div>
        <div className="category-divider" />
        <div
          className="modal-wrapper"
          style={
            modalVisible
              ? { height: '35rem', opacity: 1 }
              : { height: 0, opacity: 0 }
          }>
          <div className="modal-categories">
            <p>looking for something different?</p>
            <div className="items">
              <button
                onClick={() => {
                  unfilterCategories();
                  setCategory(undefined);
                }}
                className={`item ${!selected.length && 'active'}`}>
                <img src={AllCards} alt="All Cards" />
                <p>All Cards</p>
              </button>
              {categories.map((category) => mapCategory(category))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Categories;
