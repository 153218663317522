import './link.scss';
import type { LinkProps, To } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

interface AppLinkProps extends LinkProps {
  to: To;
  startIconClass?: string;
  endIconClass?: string;
  color?: string;
  hoverColor?: string;
  external?: boolean;
  active?: boolean;
  variant?: 'mobile' | null;
  noHighLightColor?: boolean;
}

export const Link = ({
  to,
  startIconClass,
  endIconClass,
  color,
  hoverColor,
  active,
  variant,
  external = false,
  ...props
}: AppLinkProps) => {
  const colorClass = color ? color : 'black';
  const activeClass = active ? 'active' : '';
  const variantClass = variant ? variant : '';
  const hoverClass = hoverColor ? hoverColor : 'default-hover';

  return external ? (
    <a
      rel="noreferrer"
      target="_blank"
      href={to.toString()}
      className={`special-link ${colorClass} ${hoverClass} ${activeClass} ${variantClass} ${
        props.className
      } ${props.noHighLightColor ? 'no-highlight-color' : ''}`}>
      {!!startIconClass && <span className={`icon ${startIconClass}`} />}
      <span>{props.children}</span>
      {!!endIconClass && <span className={`icon ${endIconClass}`} />}
    </a>
  ) : (
    <HashLink
      to={to}
      className={`special-link ${colorClass} ${hoverClass} ${activeClass} ${variantClass} ${
        props.className
      } ${props.noHighLightColor ? 'no-highlight-color' : ''}`}>
      {!!startIconClass && <span className={`icon ${startIconClass}`} />}
      <span>{props.children}</span>
      {!!endIconClass && <span className={`icon ${endIconClass}`} />}
    </HashLink>
  );
};
