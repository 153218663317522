import React from 'react';
import Spinner from '../Spinner';
import './button.scss';

interface IButtonProps {
  title?: string;
  role?:
    | 'Primary'
    | 'Secondary'
    | 'Secondary Link'
    | 'Tertiary'
    | 'Ghost'
    | 'Apple-Pay Primary'
    | 'Apple-Pay Secondary'
    | 'Apple-Pay Tertiary'
    | 'Browse'
    | 'Cart'
    | 'App-Store'
    | 'Social'
    | 'Sticker'
    | 'Icon-Link right'
    | 'Icon-Link left'
    | 'Icon-Link right menu'
    | 'Icon-Link left menu'
    | 'Store-Search'
    | 'Mobile-Menu';
  startIconClass?: string;
  endIconClass?: string;
  color?: string;
  active?: boolean;
  onClick?: () => void;
  buttonClass?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: {};
}

export const Button: React.FC<IButtonProps> = ({
  title,
  role,
  startIconClass,
  endIconClass,
  color,
  buttonClass,
  active,
  disabled = false,
  loading = false,
  children,
  ...props
}) => {
  const roleClass = role?.toLocaleLowerCase();
  const colorClass = color ? color : 'black';
  const activeClass = active ? 'active' : '';
  return (
    <button
      type="button"
      disabled={loading || disabled}
      className={`special-button ${roleClass} ${colorClass} ${activeClass} ${buttonClass}`}
      {...props}>
      {!!startIconClass && !loading && <span className={startIconClass} />}
      {loading && <Spinner size={22} marginRight={10} />}
      {children}
      {!!title && <span className="button-title">{title}</span>}
      {!!endIconClass && <span className={endIconClass} />}
    </button>
  );
};
