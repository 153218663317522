import { useEffect, useRef } from 'react';
import { PROMO_TC_ID } from './consts';
import './termsAndConditions.scss';

const TermsAndConditions = () => {
  const promoRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const hasScrollId = window.location.hash.includes(PROMO_TC_ID);
    let delayScroll: NodeJS.Timeout | undefined = undefined;

    if (hasScrollId) {
      delayScroll = setTimeout(() => {
        if (promoRef && promoRef.current) {
          const scrollHeight = promoRef.current?.offsetTop;
          window.scrollTo({ behavior: 'smooth', top: scrollHeight, left: 0 });
        }
      }, 100);
    }

    return () => {
      delayScroll && clearTimeout(delayScroll);
    };
  }, []);

  return (
    <div className="terms-wrapper">
      <b>Special Terms & Conditions</b>
      <p>
        <b>Section 1 - Special Card & Pays-enabled Card Terms and Conditions</b>
        <br />
        For the purpose of these terms:
      </p>
      <ol type="a">
        <li>
          ‘We, Us and Our’ means SPECIAL.COM.AU PTY LTD, ABN 99 652 350 715
          (‘Special’);
        </li>
        <li>
          ‘You’ means the Card purchaser, or user who has acquired the Card;
        </li>
        <li>‘Pays’ means Apple Pay or Google Pay;</li>
        <li>
          ‘Special Card’, ‘Gift Card’ and ‘Card’ means the Special Physical Card
          & Pays-enabled Card issued by 545490 Pty Ltd, ABN 83 648 605 225
          trading as Karta Co (‘Karta’) and distributed by Special; and
        </li>
        <li>
          ‘Device’, means a mobile phone or wearable device that allows you to
          store a Card to the Device.
        </li>
      </ol>
      <b>Background</b>
      <ol>
        <li>
          By allowing the addition of the Card into your Pays-enabled Device,
          you agree to be bound by these Terms and Conditions. You also
          acknowledge that you have read and understood our Special Privacy
          Policy, available at{' '}
          <a href="/privacy-policy">www.special.com.au/privacy-policy</a>
        </li>
      </ol>
      <b>How the Card works and where you can use it</b>
      <ol start={2}>
        <li>
          The Card can be used for purchasing goods and services at
          participating merchants, where the Card is accepted for electronic
          transactions (excluding transactions at ATMs or over the counter at
          financial institutions). The Card can be used as a debit or credit
          card and cannot be linked to any deposit account. Please note, some
          merchants may not accept the Card.
        </li>
        <li>
          The Card cannot be used to make transactions that exceed the Card’s
          available balance. To complete such a transaction, you will need to
          pay the difference by another method, if the merchant agrees.
        </li>
        <li>
          The Card is not reloadable and may be subject to value limits. It is
          only valid and useable until the expiry date shown on the website and
          the mobile Device. While the Card has an expiry, the value of your
          Card does not expire. Please contact Special in regards to any unspent
          funds upon expiry.
        </li>
        <li>
          Once issued to You, the Card must be activated by using a 6 digit code
          used for verifying your Device and/or during an identification
          verification check ("Activation Code”), within 30 minutes of receiving
          the code. If you do not enter the code in time, you can request a new
          code to be sent.
        </li>
        <li>
          The Card can only be used subject to the merchant's terms and
          conditions. Authorisations may be declined at some merchants (such as
          gambling merchants or merchants who we choose or those that do not to
          accept the Card). We are not liable in any way when authorisation is
          declined for any particular transaction, except where the
          authorisation has been declined because of an act or omission on our
          part. We note that we may also add and remove merchants at any given
          time and will update the website and app accordingly. Special is not
          liable for any delays in updating corresponding website and app lists
          where changes to the merchants list have been made.
        </li>
      </ol>
      <b>Fees</b>
      <ol start={7}>
        <li>
          We will not charge any fees for using the Card. However, some
          merchants may charge you for using the Card and such fees may be
          deducted from the balance of your Card at the time of the transaction.
        </li>
      </ol>
      <b>Your Obligations</b>
      <ol start={8}>
        <li>
          You are responsible for all transactions on the Card, except where
          there has been fraud or negligence by our employees or agents. If you
          notice any error relating to the Card, you should immediately notify
          Customer Service by sending an email to <span> </span>
          <a href="mailto:customer.service@special.com.au">
            customer.service@special.com.au
          </a>
        </li>
        <li>
          If you have a problem with a purchase made with the Card, or a dispute
          with a merchant, you must deal directly with the merchant involved. If
          you cannot resolve the dispute with the merchant, you can contact
          Customer Service by sending an email to <span> </span>
          <a href="mailto:customer.service@special.com.au">
            customer.service@special.com.au
          </a>
        </li>
        <li>
          You are responsible for checking your transaction history, knowing the
          available balance, date of expiry and pin for the Card, all of which
          is available to you on your Device.
        </li>
      </ol>
      <b>Liability</b>
      <ol start={11}>
        <li>
          We are not liable in circumstances where you have other cards stored
          on your Device for payment and you inadvertently use a different Card
          when using your Device as a payment method. You must ensure that you
          select the correct card prior to using your Device as a payment
          method. We note split payments online or via physical POS payments are
          dependent on merchant discretion. Special is not liable in
          circumstances where split payments are not accepted.
        </li>
        <li>
          We are not liable for:
          <ul>
            <li>
              the use, functionality or availability of a Device, the
              availability of merchants who allow the use of the Card as
              payment, or a reduced level of service caused by the failure of
              third party communication and network providers (except to the
              extent deemed liable under the ePayments Code); and/or
            </li>
            <li>
              temporary interruptions in service due to failure beyond their
              control including, but not limited to, the failure of
              interconnecting operating systems, computer viruses, forces of
              nature, labour disputes and armed conflicts.
            </li>
          </ul>
        </li>
        <li>
          We are not liable for transactions on your Card if:
          <ol type="a">
            <li>your Device security measures are compromised;</li>
            <li>
              where you allow others access to, or the means to access, your
              Device; or
            </li>
            <li>
              any other circumstances in which you allow others to circumvent
              the security measures of your Device.
            </li>
          </ol>
        </li>
        <li>
          To the extent permitted by law, we:
          <ol type="a">
            <li>
              exclude all statutory or implied conditions and warranties and any
              other liability we may have to you (including liability for
              indirect or consequential loss) that may arise under statute or at
              law, including, without limitation for breach of contract, in tort
              (including negligence) or under any other cause of action
              howsoever arising out of, or in any way related to, the use of the
              Card and our goods and services;
            </li>
            <li>
              limit our liability under any condition or warranty which cannot
              be legally excluded in relation to the supply of goods or services
              to (at our election) replacing the goods or services or supplying
              equivalent goods or services again; and
            </li>
            <li>
              make no warranties or representations about our goods or services
              or the content related to our goods and services, including but
              not limited to warranties or representations; that such content
              will be complete, accurate or up-to-date or that access to the
              Card or related services will be uninterrupted, error-free or free
              from viruses.
            </li>
          </ol>
        </li>
      </ol>
      <b>Our Rights</b>
      <ol start={15}>
        <li>
          We may restrict, halt or stop the use of the Card if suspicious or
          illegal activities are noticed.
        </li>
      </ol>
      <b>Refunds</b>
      <ol start={16}>
        <li>
          Any refunds on Card transactions are subject to the policy of the
          specific merchant. Refunds may be in the form of applying a credit to
          the Card, cash refund or in-store credit, which is at the Merchants
          discretion. If the Card expires or is revoked before you have spent
          any funds resulting from a refund (whether or not the original
          transaction being refunded was made using the Card) then you will have
          no access to those funds. Where you have completed the Special payment
          process and purchased a card, refunds will not be provided for
          accidental or negligent purchases
        </li>
      </ol>
      <b>Queries and Complaints</b>
      <ol start={17}>
        <li>
          If you have a query about the Card, you should initially contact
          Special at any of the following:
          <br />
          <a href="mailto:customer.service@special.com.au">
            customer.service@special.com.au
          </a>
        </li>
      </ol>
      <b>Privacy and Information Collection</b>
      <ol start={18}>
        <li>
          Information may be disclosed to third parties about the Card, or
          transactions made with the Card, whenever allowed by law, and also
          where necessary to operate the Card and process transactions. A full
          Privacy Policy can be viewed on the Special website. Special may
          collect your personal information:
          <ul>
            <li>to identify you;</li>
            <li>to provide information about a product or service;</li>
            <li>to consider your request for a product or service;</li>
            <li>to provide you with a product or service;</li>
            <li>
              to assist in arrangements with other organisations in relation to
              the promotion and provision of a product or service or suspend its
              operation until it is provided;
            </li>
            <li>
              to perform administrative, reporting and operational tasks
              (including systems development and testing, staff training, and
              market or customer satisfaction research);
            </li>
            <li>
              to prevent or investigate any fraud or crime (or a suspected fraud
              or crime); and
            </li>
            <li>as required by relevant laws and scheme rules.</li>
          </ul>
          Where you do not provide some or all of the information requested by
          Special we may be unable to provide you with a product or service.
        </li>
      </ol>
      <b>Providing your information to others</b>
      <ol start={19}>
        <li>
          Special may provide your information:
          <ul>
            <li>to another member of its group; </li>
            <li>
              to any outsourced service providers (for example mailing houses,
              data switch service companies);
            </li>
            <li>
              to regulatory bodies, government agencies, law enforcement bodies
              and courts;
            </li>
            <li>to other parties as is authorised or required by law; or </li>
            <li>
              to participants in the payments system and other financial
              institutions for the purpose of resolving disputes, errors or
              other matters arising out of your use of the Card or third parties
              using your Card or Card information.
            </li>
          </ul>
        </li>
      </ol>
      <b>Contact Us</b>
      <ol start={20}>
        <li>
          Please contact us if you have any questions or comments about our
          privacy policies and procedures. We welcome your feedback.
        </li>
      </ol>
      <b>Complaints</b>
      <ol start={21}>
        <li>
          Additionally, if you wish to make a general complaint about our
          services, please contact us directly at{' '}
          <a href="mailto:support@special.com.au">support@special.com.au</a>{' '}
          addressing your complaint to the Complaints Officer. If you need to
          resolve an issue or make a complaint about how we collect, use or
          store your personal information, please contact us directly at{' '}
          <a href="mailto:support@special.com.au">support@special.com.au</a>{' '}
          addressing your complaint to the Privacy Officer.
        </li>
      </ol>
      <p>
        If you are not satisfied with our response, you can contact the Office
        of the Australian Information Commissioner GPO Box 5218, Sydney NSW 2001{' '}
        <br />
        <br />
        (p) <a href="tel:1300363992">1300 363 992</a>
        <br />
        (e) <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a>
        <br />
        (w){' '}
        <a href="https://www.oaic.gov.au" target="_blank" rel="noreferrer">
          www.oaic.gov.au
        </a>{' '}
        (where you can make a complaint online)
      </p>
      <b>
        Anti-Money Laundering and Counter Terrorism Financing, and its
        associated rules, regulatory guides and regulations. (“AML”)
      </b>
      <ol start={22}>
        <li>
          You acknowledge and agree that:
          <ul>
            <li>
              where required, you will provide to Special all information
              reasonably requested by Special in order for Special to comply
              with the fraud monitoring and anti-money laundering and counter
              terrorism financing obligations imposed on it pursuant to the AML
              Legislation;
            </li>
            <li>
              Where you do not provide Special the information required above,
              Special may refuse to provide You its services;
            </li>
            <li>
              Special may be legally required to disclose information about you
              to regulatory and/or law enforcement agencies;
            </li>
            <li>
              Special may block, delay, freeze or refuse any transactions for
              legitimate business, prudential or regulatory reasons, where
              Special considers reasonable grounds exist to believe that the
              relevant transactions are fraudulent, in breach of the AML
              Legislation or any other relevant law or term within this
              agreement;
            </li>
            <li>
              where transactions are blocked, delayed, frozen or refused by
              Special, you agree that Special is not liable for any loss
              suffered by it or other third parties arising directly or
              indirectly as a result of Special taking this action; and
            </li>
            <li>
              where required, Special will monitor all transactions that arise
              pursuant to your use of the Card in accordance with its
              obligations imposed on it in accordance with the AML/CTF
              Legislation.
            </li>
          </ul>
        </li>
      </ol>
      <b>Changes to Terms and Conditions</b>
      <ol start={23}>
        <li>
          We may change or add to these Terms and Conditions at any time and any
          changes to these Terms and Conditions can be viewed on the Special
          website. By continuing to use the website you will be deemed to accept
          any variation of these terms. We will make any changes in accordance
          with any applicable legislation and industry codes. If we believe a
          change is unfavourable to you, we will usually give you 7 day's notice
          or no notice if the change is in accordance with law and industry
          codes.
        </li>
      </ol>
      <b>Applicable law</b>
      <ol start={24}>
        <li>
          These Conditions of Use are to be construed and enforced in accordance
          with the laws of NSW, Australia. Any dispute arising from your receipt
          or use of a Card is exclusively subject to the jurisdiction of the
          courts of NSW, Australia.
        </li>
      </ol>
      <b>Reasonable Use</b>
      <ol start={25}>
        <li>
          You agree to access and use Special in accordance with our requirement
          that your use be reasonable. In particular, by accessing Special, you
          agree and acknowledge that:
          <ol type="a">
            <li>
              all access to our services is subject to reasonable use; and
            </li>
            <li>
              where we think your usage is unreasonable we may suspend or refuse
              access to the services and your account for legitimate business,
              prudential or regulatory reasons.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        We aim to provide a quality service to all our clients at all times. The
        reasonable use requirement is designed to ensure that no customer is
        unfairly disadvantaged by the behaviour of other customers. If at any
        time we think a customer’s usage may disadvantage other clients, we may
        suspend, restrict or refuse access to our services.
      </p>
      <b>Section 2 - Terms of Use </b>
      <br /> <br />
      <b>Introduction</b>
      <ol>
        <li>
          This website is operated by or on behalf of SPECIAL.COM.AU PTY LTD,
          ABN 99 652 350 715 and its related bodies corporate (together “We, Us,
          Our and Special’”).
        </li>
      </ol>
      <b>Acceptance</b>
      <ol start={2}>
        <li>
          By visiting or using <a href="/">www.special.com.au</a> you
          acknowledge and agree with the website terms of use. You must only use
          this website for the purposes for which it was designed and offered
          and not for any other purpose. By using this website, you agree to use
          it in a responsible manner, taking into account the effects your use
          of this website may have on other users. Examples of acceptable use
          would include browsing the various pages and contacting the site
          owner. Examples of inappropriate use would include planting malware,
          viruses or, using the site to attempt to spread spam or other
          unnecessary content.
        </li>
      </ol>
      <b>User and Intellectual Property Rights </b>
      <ol start={3}>
        <li>
          You are provided with access to it only for your personal and
          non-commercial use. Other than for these permitted purposes, and for
          the purposes of and subject to the conditions prescribed under
          statutes that apply in your location, you may not, in any form or by
          any means:
          <ul>
            <li>
              adapt, reproduce, store, distribute, transmit, print, display,
              perform, publish or create derivative works from any part of this
              website; or
            </li>
            <li>
              commercialise any information, products or services obtained from
              any part of this website, without our written permission.
            </li>
          </ul>
        </li>
        <li>
          All rights in this website and the content on this website including
          copyright, design rights, patents, inventions, knowhow, database
          rights, trademarks, source codes and any other intellectual property
          rights in any of the foregoing are reserved to Special and/or their
          content and technology providers. All trade names, trademarks, service
          marks and other product and service names and logos (the “Marks”)
          displayed on the website are proprietary to their respective owners
          and are protected by applicable trademark and copyright laws. These
          Marks may be registered or unregistered marks of Special. Apple Pay is
          a trademark of Apple Inc., registered in the U.S. and other countries
          and regions.
        </li>
        <li>
          Special is a registered trademark. Copyright in the information
          contained in this site subsists under the Copyright Act 1968 (Cth)
          and, through international treaties, the laws of many other countries.
          Except as permitted under the Copyright Act 1968 (Cth) or other
          applicable laws, no part of this publication may be otherwise
          reproduced, adapted, performed in public or transmitted in any form by
          any process (graphic, electronic or mechanical, including
          photocopying, recording, taping or by storage in an information
          retrieval system) without the specific written consent of Special.
          Nothing contained on the website should be construed as granting any
          licence or right of use of any other person’s or entity’s trademark
          which is displayed on this website without their express permission.
          You may not remove, change or obscure the Special logo or any notices
          of proprietary rights on any content of this website.
        </li>
      </ol>
      <b>Disclaimer and liability</b>
      <ol start={6}>
        <li>
          The content on this website (including any publications) is intended
          only to provide a summary and general overview on matters of interest.
          It is not intended to be comprehensive nor does it constitute
          financial or legal advice. You should seek legal or other professional
          advice before acting or relying on any of the content. Special does
          not warrant or represent that the information in this site is free
          from errors or omissions or is suitable for your intended use. Subject
          to any terms implied by law and which cannot be excluded, Special
          accepts no responsibility for any loss, damage, cost or expense
          (whether direct or indirect) incurred by you as a result of any error,
          omission or misrepresentation in any information in this site.
          Information provided on Special as to future matters are not
          guaranteed to be accurate and any statements as to past performance do
          not represent future performance. Words implying natural persons
          include partnerships, incorporated bodies, associations and public
          authorities. All fees and charges are inclusive of GST, unless
          otherwise stated.{' '}
        </li>
      </ol>
      <b>Links and Subscriptions</b>
      <ol start={7}>
        <li>
          Any information provided by third party data suppliers has not been
          verified by Special. Special does not make any representation or
          warranty as to the timeliness, reliability, accuracy or completeness
          of the material, nor does it accept any responsibility arising in any
          way for errors in, or omissions from that material. Your use of any
          link to a linked website is entirely at your own risk. This website
          may contain links to other websites solely for your convenience only
          and may not remain current or be maintained. We do not endorse,
          recommend or approve of any information, products or services referred
          to on such linked sites and assumes no responsibility for the contents
          of any other website to which this website offers links. Where you
          have received a message via email, the message is intended only for
          the addressee and contains information which may be confidential.
          Where you download or print copies of any information contained in
          this email, you acknowledge that it is for your personal and private
          use, and that it may not be reproduced, republished, broadcast or
          otherwise distributed without Special’s prior written consent.
        </li>
      </ol>
      <b>Personal Logins and Restricted Areas</b>
      <ol start={8}>
        <li>
          We reserve the right to implement areas which allow users to login in
          and us to access personal materials. By creating a login, you are
          responsible for the security of your login information. Any restricted
          areas must not be accessed without permission or authorisation. If
          your account is compromised as a result of failure to secure your
          login details, we are not liable for any losses you sustain as a
          result.
        </li>
      </ol>
      <b>Cookies and Privacy Policy</b>
      <ol start={9}>
        <li>
          These website terms of use incorporate, and should be read together
          with, our Privacy Policy. Special will use only the personal data
          which you submit to us via the website in accordance with the Special
          Privacy Policy. The Special Privacy Policy also describes how this
          website may collect, store and use cookies. We will not disclose any
          private information provided by you through our website excepting
          where required by law. For more information on this please refer to
          our Privacy Policy.{' '}
        </li>
      </ol>
      <b>PIN Management</b>
      <ol start={10}>
        <li>
          When choosing a PIN, avoid obvious number combinations, sequences, or
          your birthday. Never share your PIN, memorise it and don't write it
          down. Otherwise you may be liable for unauthorised transactions. You
          should check your statements and advise us if you think there has been
          unauthorised charges.
        </li>
      </ol>
      <b>Account Deletion</b>
      <ol start={11}>
        <li>
          Once you confirm the deletion of your account, the account will be
          scheduled for deletion and immediately deactivated, preventing you
          from logging in to the account and accessing our services. After you
          delete your account, you will have a 5-day period, during which time
          you can cancel the deletion and reactivate your account. Where you
          reactivate your account in this period, your account will be fully
          restored and active. Once this period is over, your account will be
          permanently deleted and cannot be recovered. If you wish to continue
          using our services after your account has been deleted, you will be
          required to create a new account with us.
          <p>
            Please note, we will still keep a record of your deletion request
            and any other relevant account information required under relevant
            laws and regulations.
          </p>
          <p>
            If you have any questions or issues relating to deleting your
            account, you can contact our Support team at 
            <a href="mailto:customer.service@special.com.au">
              customer.service@special.com.au
            </a>
            .
          </p>
        </li>
      </ol>
      <div
        id={`SPECIAL_ID_${PROMO_TC_ID}`}
        ref={promoRef}
        style={{ marginBottom: 20 }}>
        <b>Promotional terms and conditions</b>
      </div>
      <div>
        <ol type="a">
          <li>
            <b>O-Week Promotion</b>
            <p>
              This offer is open to new and existing customers of Special and
              its affiliates, partners and third-party vendors that have not
              used any existing or previous promo code. The offer is valid from
              1 July 2022 (00.01 AEST) to 20 September 2022 (23:59 AEST). The
              promo code is limited to the purchase of Latte Lover, Pub Crawler
              and Fine Foodie Card, and entitles the customer to a 10% discount
              (up to the value of $20). The 10% discount will not be
              automatically applied, the discount will be applied to the order
              if the promo code UNITEN is applied at the checkout. Promo codes
              are limited to one order per customer. Promo codes are also
              limited to the first 15,000 customers. The standard Special Terms
              and Conditions and Privacy Policy will apply.
            </p>
          </li>
          <li>
            <b>Welcome Promotion</b>
            <p>
              This offer is open to new and existing customers of Special that
              have not used any existing or previous promo code. The offer is
              valid from 1 July 2022 (00.01 AEST) and may be discontinued at any
              time at Special’s discretion. The promo code is limited to the
              purchase of Latte Lover, Pub Crawler and Fine Foodie Card, and
              entitles the customer to a 10% discount (up-to the value of $20).
              The 10% discount will not be automatically applied, the discount
              will be applied to the order if the promo code WELCOME is applied
              at the checkout. Promo codes are limited to one order per
              customer. Note: Special is not liable for any delays in updating
              corresponding website and app where changes to the promotion have
              been made.
            </p>
          </li>
          <li>
            <b>Alinta Energy Promotion</b>
            <p>
              This offer is open to new and existing members of Alinta Energy
              and its relevant affiliates that have not used any existing or
              previous promo code. The offer is valid from 2 September 2022
              (00.01 AEST) to 4 September 2022 (23:59 AEST). The promo code is
              limited to the purchase of Latte Lover, Pub Crawler and Fine
              Foodie Card, and entitles the customer to a 10% discount (up to
              the value of $20). The 10% discount will not be automatically
              applied, the discount will be applied to the order if the promo
              code ALINTA10 is applied at the checkout. Promo codes are limited
              to one order per customer.
            </p>
          </li>
          <li>
            <b>
              General - Affiliate, partners and third- party vendor Promotions
            </b>
            <p>
              Special may also run promotional offers through its affiliates,
              partners and third-party vendors from time to time. These
              promotional offers are open to new and existing customers of
              Special and its affiliates, partners and third-party vendors that
              have not used any existing or previous promo code. Each
              promotional offer is valid from the stipulated start and end date
              provided by the specific affiliate, partner and/or third-party
              vendor. This promotional offer may also be discontinued at any
              time at Special’s, its affiliates, partners and third-party
              vendors discretion. The promotional offer may be limited to the
              purchase of specific Special category cards and entitles the
              customer to the specified discount under the particular
              promotional offer. The discount may not always be automatically
              applied, and in some instances may require a promo code to be
              applied to the order at the checkout. Promo codes are limited to
              one order per customer. The standard Special Terms and Conditions
              and Privacy Policy will also apply.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
