import { useNavigate } from 'react-router-dom';
import { UserGiftCard } from '..';
import { useAuth } from '../../../context/AuthProvider';
import { partialHidePhone } from '../../../util/helpers';
import { useState } from 'react';
import { Button } from '../../../components/Button';
import { CardPinStatus } from '../../../components/CardPin';
import { SideDrawerContainer } from '../../../components/SideDrawer';
import {
  ManagePinHeader,
  ManagePinParagraph,
  ManagePinParagraphHeader,
} from '../shared-components/manage-pin';
import PhoneVerification from '../../../components/PhoneVerification';
import { useManageCard } from '../../../context/ManagePinProvider';

const INVALID_PIN_ERROR_STRING = 'Invalid verification code';

type Props = {
  userGiftCard?: UserGiftCard;
};

const ManagePinVerify = ({ userGiftCard }: Props) => {
  const navigate = useNavigate();
  const { userDetailsVM } = useAuth();
  const { verifyPhoneSubmit } = useAuth();
  const { setPhoneVerified } = useManageCard();
  const [isPosting, setIsPosting] = useState(false);
  const [pinStatus, setPinStatus] = useState<CardPinStatus>(undefined);

  const onSubmit = async (pin: string) => {
    try {
      if (isPosting) {
        return;
      }
      setIsPosting(true);
      await verifyPhoneSubmit(pin);
      setPinStatus('success');
      setIsPosting(false);
      setPhoneVerified(true);
      navigate(`/my-cards/${userGiftCard?.id}/manage-pin/set-pin`);
    } catch (error: any) {
      if (error === INVALID_PIN_ERROR_STRING) {
        setPinStatus('error');
      }
      setIsPosting(false);
    }
  };

  return (
    <>
      <ManagePinHeader />
      <SideDrawerContainer>
        <ManagePinParagraphHeader>Verify your account</ManagePinParagraphHeader>
        <ManagePinParagraph>
          A verification code has been sent to your phone:{' '}
          {partialHidePhone(userDetailsVM?.mobileNumber || '', 'X')}.
        </ManagePinParagraph>
        <PhoneVerification
          pinStatus={pinStatus}
          onComplete={async (pin: string) => {
            onSubmit(pin);
          }}
        />
        <Button
          title="SUBMIT CODE"
          style={{ width: '28rem', marginTop: '2rem', height: '4.5rem' }}
          loading={isPosting}
          role="Secondary"
          onClick={() => {
            setPinStatus('error');
          }}></Button>
      </SideDrawerContainer>
    </>
  );
};

export default ManagePinVerify;
