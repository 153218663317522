import React from 'react';
import {
  ContentWrapper,
  EyeWrapper,
  FathersDayText,
  FathersDayTextWrapper,
  GiftWrapper,
  HappyText,
  HeartsWrapper,
  InitialWrapper,
  MainText,
  MainTextWrapper,
  SpecialText,
  StarterText,
  SubText,
} from './styles';

import FathersDayBackground from '../../../assets/svg/promotions/fathers-day/FathersDayBackground.png';
import FathersDayBackgroundMobile from '../../../assets/svg/promotions/fathers-day/FathersDayBackgroundMobile.png';
import { ReactComponent as GiftSvg } from '../../../assets/svg/promotions/fathers-day/Gift.svg';
import { ReactComponent as EyeSvg } from '../../../assets/svg/promotions/fathers-day/Eye.svg';
import { ReactComponent as HeartsSvg } from '../../../assets/svg/promotions/fathers-day/Hearts.svg';
import FindSomething from '../common/FindSomething';
import { IsMobileView } from '../../../util/helper';

interface OwnProps {
  onSomethingClick: () => void;
}

const FathersDayBanner: React.FC<OwnProps> = ({ onSomethingClick }) => {
  const isMobileView = IsMobileView();
  return (
    <InitialWrapper>
      <ContentWrapper
        style={{
          backgroundImage: isMobileView
            ? `url(${FathersDayBackgroundMobile})`
            : `url(${FathersDayBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        }}>
        <StarterText> 🎁 Gift Cards just became Special&trade;</StarterText>
        <MainTextWrapper>
          <HappyText>
            <MainText>HAPPY</MainText>
            <HeartsWrapper>
              <HeartsSvg height={'100%'} width={'100%'} />
            </HeartsWrapper>
          </HappyText>
          <SpecialText>
            <EyeWrapper>
              <EyeSvg height={'100%'} width={'100%'} />
            </EyeWrapper>
            <MainText>SPECIAL</MainText>
          </SpecialText>
          <FathersDayText>
            <MainText>
              <MainText
                style={{ marginRight: '1.2rem', display: 'inline-block' }}>
                FATHER'S
              </MainText>
              <FathersDayTextWrapper>
                DAY
                <GiftWrapper>
                  <GiftSvg width={'100%'} height={'100%'} />
                </GiftWrapper>
              </FathersDayTextWrapper>
            </MainText>
          </FathersDayText>
        </MainTextWrapper>
        <SubText>Treat Dad with a Special Gift Card this Father’s Day</SubText>

        <FindSomething onClick={onSomethingClick} isWhite={false} />
      </ContentWrapper>
    </InitialWrapper>
  );
};

export default FathersDayBanner;
