import { useEffect, useMemo, useState } from 'react';
import { Button } from '../Button';
import { Logo } from '../Logo';
import Sun from '../../assets/svg/header/Sun.svg';
import './header.scss';
import UserMenu from './UserMenu';
import { IsMobileView } from '../../util/helper';
import { MenuLink } from './MenuLink';
import { PurchaseMenuLink } from './PurchaseMenuLink';
import { PurchaseSteps } from '../../const/urls/purchase/PurchaseUrls';
import { Link } from 'react-router-dom';
import { CAREERS_LINK } from '../../const/links';

interface HeaderProps {
  theme?:
    | 'electric-blue'
    | 'white'
    | 'sea-green'
    | 'soft-lilac'
    | 'something-wrong';
  variant?: 'my-cards' | 'default' | 'purchase' | 'message';
  message?: string;
  disableShopNow?: boolean;
  footerButton?: {
    text: string;
    buttonImage: string;
    onClick: () => void;
    to: string;
    disabled?: boolean;
  };
  displayMobileMenu?: boolean;
  displayCareers?: boolean;
}

const Header = ({
  theme = 'electric-blue',
  variant = 'default',
  disableShopNow = false,
  footerButton,
  message,
  displayMobileMenu = true,
  displayCareers = true,
}: HeaderProps) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const menuLinkColor = useMemo(() => {
    switch (theme) {
      case 'electric-blue':
        return 'white';
      case 'white':
      case 'sea-green':
      case 'soft-lilac':
      case 'something-wrong':
        return 'black';
      default:
        return 'white';
    }
  }, [theme]);

  const menuHoverColor = useMemo(() => {
    switch (theme) {
      case 'sea-green':
        return 'custom-hover';
      default:
        return 'default-hover';
    }
  }, [theme]);

  const logoColor = useMemo(() => {
    switch (theme) {
      case 'electric-blue':
        return 'white';
      case 'white':
        return 'fullColorBlack';
      case 'sea-green':
      case 'soft-lilac':
      case 'something-wrong':
        return 'black';
      default:
        return 'white';
    }
  }, [theme]);

  const faqButtonIcon = useMemo(() => {
    switch (theme) {
      case 'electric-blue':
      case 'white':
      case 'soft-lilac':
        return 'left-heart-icon';
      case 'sea-green':
        return 'left-heart-purple-icon';
      case 'something-wrong':
        return '';
      default:
        return 'left-heart-icon';
    }
  }, [theme]);

  const mobileMenuButton = useMemo(() => {
    switch (theme) {
      case 'white':
      case 'soft-lilac':
      case 'sea-green':
      case 'something-wrong':
        return 'black';
      default:
        return '';
    }
  }, [theme]);

  const mobileMenuButtonIcon = useMemo(() => {
    switch (theme) {
      case 'white':
      case 'soft-lilac':
      case 'something-wrong':
      case 'sea-green':
        return 'mobile-menu-icon-white';
      default:
        return 'mobile-menu-icon-black';
    }
  }, [theme]);

  const userMenuTheme = useMemo(() => {
    switch (theme) {
      case 'white':
      case 'soft-lilac':
      case 'sea-green':
        return 'white';
      case 'something-wrong':
        return 'black';
      default:
        return 'black';
    }
  }, [theme]);

  const isMobileView = IsMobileView();

  const containerClass = useMemo(() => {
    switch (variant) {
      case 'my-cards':
      case 'purchase':
        return 'light-border';
      default:
        return '';
    }
  }, [variant]);

  const desktopMenuCenter = useMemo(() => {
    switch (variant) {
      case 'purchase':
        return (
          <div className="header-center">
            <div className="menu justify-content-center">
              {PurchaseSteps.map((step) => (
                <PurchaseMenuLink
                  key={step.stepValue}
                  stepValue={step.stepValue}
                  noHighLightColor={true}
                  activeSvg={step.stepActiveSvg}
                  to={step.link}>
                  {step.stepName}
                </PurchaseMenuLink>
              ))}
            </div>
          </div>
        );
      case 'my-cards':
        return (
          <div className="header-center">
            <div className="menu justify-content-center">
              <MenuLink
                noHighLightColor={true}
                className="header-link"
                color={menuLinkColor}
                to="/my-cards">
                MY GIFT CARDS
              </MenuLink>
              <MenuLink
                noHighLightColor={true}
                className="header-link"
                color={menuLinkColor}
                to="/my-orders">
                ORDERS
              </MenuLink>
              <MenuLink
                noHighLightColor={true}
                className="header-link"
                color={menuLinkColor}
                to="/settings">
                ACCOUNT SETTINGS
              </MenuLink>
            </div>
          </div>
        );
      case 'message':
        return (
          <div className="header-center">
            <div className="menu justify-content-start">
              <div className="header-message">{message}</div>
            </div>
          </div>
        );

      case 'default':
      default:
        return (
          <div className="header-center justify-content-start">
            <div className="menu">
              <MenuLink
                noHighLightColor={true}
                className="header-link"
                color={menuLinkColor}
                hoverColor={menuHoverColor}
                to="/our-mission">
                OUR MISSION
              </MenuLink>
              <MenuLink
                noHighLightColor={true}
                className="header-link"
                color={menuLinkColor}
                hoverColor={menuHoverColor}
                to="/how-it-works">
                HOW IT WORKS
              </MenuLink>
              {displayCareers && (
                <MenuLink
                  noHighLightColor={true}
                  className="header-link"
                  color={menuLinkColor}
                  hoverColor={menuHoverColor}
                  to={CAREERS_LINK}
                  external={true}>
                  CAREERS
                </MenuLink>
              )}
            </div>
          </div>
        );
    }
  }, [variant, menuLinkColor, message, displayCareers, menuHoverColor]);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.touchAction = 'unset';
    };
  }, [showMobileMenu]);

  return (
    <>
      {/* Desktop view menu */}
      <div className={`special-header ${theme}`}>
        <div className={`container ${containerClass}`}>
          <div className="header-left">
            <div className="logo">
              <MenuLink color={menuLinkColor} to="/">
                <Logo logoClass={`${logoColor} header`} />
              </MenuLink>
            </div>
            {!disableShopNow && (
              <MenuLink
                noHighLightColor={true}
                hoverColor={menuHoverColor}
                color={menuLinkColor}
                to="/shop">
                <span className="shop-now-link">
                  <img src={Sun} alt="Shop" /> SHOP NOW
                </span>
              </MenuLink>
            )}
          </div>
          {desktopMenuCenter}
          <div className="header-right">
            {!isMobileView && (
              <MenuLink
                color={menuLinkColor}
                startIconClass={faqButtonIcon}
                className={`${
                  theme === 'sea-green' && 'white-hover'
                } apple-pay faq`}
                external={true}
                to="https://special.freshdesk.com/support/home">
                FAQs
              </MenuLink>
            )}
            <UserMenu theme={userMenuTheme} />
          </div>
        </div>
      </div>

      {/* Mobile view menu */}
      <div
        className={`special-header mobile-menu  ${
          showMobileMenu ? 'active' : ''
        }`}>
        <div className="container">
          <div className="header-left">
            <div className="logo">
              <MenuLink color={menuLinkColor} to="/">
                <Logo logoClass="fullColorBlack header" />
              </MenuLink>
            </div>
          </div>
          <div className="header-right">
            <UserMenu theme={userMenuTheme} />
          </div>
        </div>

        <div className="menu">
          <div className="menu-item">
            <MenuLink
              color="black"
              variant="mobile"
              to="/shop"
              onClick={() => {
                setShowMobileMenu(false);
              }}>
              <img className="shop-now-icon-big" src={Sun} alt="Shop" />
              SHOP
            </MenuLink>
          </div>
          <div
            className="menu-item"
            onClick={() => {
              setShowMobileMenu(false);
            }}>
            <MenuLink color="black" variant="mobile" to="/our-mission">
              OUR MISSION
            </MenuLink>
          </div>
          <div className="menu-item">
            <MenuLink color="black" variant="mobile" to="/how-it-works">
              HOW IT WORKS
            </MenuLink>
          </div>
          <div
            className="menu-item"
            onClick={() => {
              setShowMobileMenu(false);
            }}>
            <MenuLink
              color="black"
              variant="mobile"
              to={CAREERS_LINK}
              external={true}>
              CAREERS
            </MenuLink>
          </div>
          <div
            className="menu-item"
            onClick={() => {
              setShowMobileMenu(false);
            }}>
            <MenuLink
              color="black"
              className="side-menu"
              external={true}
              to="https://special.freshdesk.com/support/home"
              variant="mobile"
              endIconClass="left-heart-icon">
              FAQs
            </MenuLink>
          </div>

          <div className="secondary-menu">
            <div
              className="secondary-menu-item"
              onClick={() => {
                setShowMobileMenu(false);
              }}>
              <MenuLink
                to="/#GetAppsSection"
                color="black"
                noHighLightColor={true}>
                Get the App
              </MenuLink>
            </div>
            <div
              className="secondary-menu-item"
              onClick={() => {
                setShowMobileMenu(false);
              }}>
              <MenuLink to="/contact-us" color="black">
                Contact Us
              </MenuLink>
            </div>
          </div>
        </div>
      </div>

      {displayMobileMenu && (
        <div
          className={`special-mobile-menu-button ${
            showMobileMenu ? 'active' : ''
          }`}>
          <div className={`container ${mobileMenuButton}`}>
            <Button
              role="Mobile-Menu"
              color="transparent"
              startIconClass={`${mobileMenuButtonIcon} ${
                showMobileMenu ? 'mobile-menu-icon-active' : ''
              }`}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
            {!disableShopNow && (
              <Link
                to={!!footerButton ? footerButton.to : '/shop'}
                style={{ textDecoration: 'none', color: 'black' }}>
                <div
                  className="shop-now"
                  onClick={!!footerButton ? footerButton.onClick : undefined}>
                  {!!footerButton ? footerButton.text : 'SHOP NOW'}
                  <img
                    src={!!footerButton ? footerButton.buttonImage : Sun}
                    alt="Shop"
                  />
                </div>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
