import axios from 'axios';
import React, { createContext, useContext, useMemo } from 'react';
import EnvConfig from '../config/EnvConfig';
import {
  Configuration,
  GiftCardApi,
  GiftCardWorkflowApi,
  UserApi,
  UserGiftCardApi,
  AdminApi,
  CardTransactionApi,
  PaymentApi,
  SpecialTimezoneApi,
  MerchantApi,
  EmailApi,
  SpecialCategoryApi,
  PromotionApi,
  HomeApi,
} from '../generated';
import { GetJWTToken } from '../services/auth/AuthServices';

type ApiContextData = {
  userApi: UserApi;
  userGiftCardApi: UserGiftCardApi;
  giftCardApi: GiftCardApi;
  giftCardWorkflowApi: GiftCardWorkflowApi;
  adminApi: AdminApi;
  cardTransactionApi: CardTransactionApi;
  paymentApi: PaymentApi;
  timezoneApi: SpecialTimezoneApi;
  merchantApi: MerchantApi;
  emailApi: EmailApi;
  categoryApi: SpecialCategoryApi;
  promotionApi: PromotionApi;
  homeApi: HomeApi;
};

const ApiContext = createContext<ApiContextData>({} as ApiContextData);

// axios interceptor
axios.interceptors.request.use(async (request) => {
  const token = await GetJWTToken();
  if (request.headers) {
    request.headers.Authorization = `Bearer ${token}`;
    request.headers.ApiKey = EnvConfig.roadhouseApiKey;
  }
  return request;
});

const ApiProvider: React.FC = ({ children }) => {
  const api = useMemo(() => {
    const apiConfiguration = new Configuration({
      basePath: EnvConfig.apiUrl,
    });
    return {
      userApi: new UserApi(apiConfiguration),
      userGiftCardApi: new UserGiftCardApi(apiConfiguration),
      giftCardApi: new GiftCardApi(apiConfiguration),
      giftCardWorkflowApi: new GiftCardWorkflowApi(apiConfiguration),
      adminApi: new AdminApi(apiConfiguration),
      cardTransactionApi: new CardTransactionApi(apiConfiguration),
      paymentApi: new PaymentApi(apiConfiguration),
      timezoneApi: new SpecialTimezoneApi(apiConfiguration),
      merchantApi: new MerchantApi(apiConfiguration),
      emailApi: new EmailApi(apiConfiguration),
      categoryApi: new SpecialCategoryApi(apiConfiguration),
      promotionApi: new PromotionApi(apiConfiguration),
      homeApi: new HomeApi(apiConfiguration),
    };
  }, []);

  return (
    <ApiContext.Provider value={{ ...api }}>{children}</ApiContext.Provider>
  );
};

function useApi(): ApiContextData {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
}

export { ApiProvider, useApi };
