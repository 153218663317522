import React from 'react';
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormReset,
} from 'react-hook-form';
import styled from 'styled-components';
import { FormValues } from '../..';
import { Button } from '../../../../../components/Button';

const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5.5rem;
`;

const FooterButton = styled.div`
  width: 48%;
`;

interface OwnProps {
  setIsEditMode: (mode: boolean) => void;
  reset: UseFormReset<FormValues>;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  resetInitialPurchaseDetails: () => void;
}

const EditModeFooter: React.FC<OwnProps> = ({
  setIsEditMode,
  reset,
  handleSubmit,
  onSubmit,
  resetInitialPurchaseDetails,
}) => {
  return (
    <FooterWrapper>
      <FooterButton>
        <Button
          title="Cancel"
          role="Primary"
          color="white"
          onClick={() => {
            setIsEditMode(false);
            reset();
            resetInitialPurchaseDetails();
          }}
        />
      </FooterButton>
      <FooterButton>
        <Button title="Save" role="Primary" onClick={handleSubmit(onSubmit)} />
      </FooterButton>
    </FooterWrapper>
  );
};

export default EditModeFooter;
