import { useNavigate } from 'react-router-dom';
import { UserGiftCard } from '..';
import { Button } from '../../../components/Button';
import { SideDrawerContainer } from '../../../components/SideDrawer';
import {
  ManagePinHeader,
  ManagePinParagraph,
  ManagePinParagraphHeader,
} from '../shared-components/manage-pin';

type Props = {
  userGiftCard?: UserGiftCard;
};

const PinUpdated = ({ userGiftCard }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <ManagePinHeader />
      <SideDrawerContainer>
        <ManagePinParagraphHeader>PIN Changed!</ManagePinParagraphHeader>
        <ManagePinParagraph>
          Your Card PIN has been changed successfully! Keep your PIN safe and do
          not forget your PIN.
        </ManagePinParagraph>

        <Button
          title="DONE"
          onClick={() => {
            navigate(`/my-cards/${userGiftCard?.id}`);
          }}
          style={{ width: '25rem', height: '4.5rem', marginTop: '3rem' }}
          role="Secondary"></Button>
      </SideDrawerContainer>
    </>
  );
};

export default PinUpdated;
