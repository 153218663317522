import { useState, useEffect } from 'react';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import Stepper from '../../components/Stepper';
import { useAuth } from '../../context/AuthProvider';
import { partialHidePhone } from '../../util/helpers';
import './Auth.scss';
import { CardPinStatus } from '../../components/CardPin';
import { INVALID_PIN_ERROR_STRING } from '../../const/shared';
import PhoneVerification from '../../components/PhoneVerification';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';

const VerifyPhoneSubmit = () => {
  const [pinStatus, setPinStatus] = useState<CardPinStatus>(undefined);
  const { userApi } = useApi();
  const {
    verifyPhoneSubmit,
    newUserDetails,
    navState,
    setNavState,
    postSignIn,
  } = useAuth();
  const [isPosting, setIsPosting] = useState(false);

  useEffect(() => {
    if (navState > 5) {
      return;
    }

    setNavState(5);
  });

  useEffect(() => {
    RudderStack.page("Auth_VerifyPhoneSubmit");
  },[])

  return (
    <AuthWrapper>
      <>
        <Stepper
          numberOfSteps={5}
          containerStyle="auth-stepper"
          currentStep={navState}
        />
        <p className="auth-create-account-title">Verify your account</p>
        <p className="auth-create-account-subtitle">
          A verification code has been sent to your phone:{' '}
          {partialHidePhone(newUserDetails.mobile, 'X')}
        </p>
        <PhoneVerification
          pinStatus={pinStatus}
          onComplete={async (pin) => {
            try {
              if (isPosting) {
                return;
              }
              setIsPosting(true);
              await verifyPhoneSubmit(pin);
              await userApi.registerPhoneNumber(newUserDetails.mobile);
              await postSignIn();
              setPinStatus('success');
              setIsPosting(false);
            } catch (error: any) {
              console.log(error);
              if (error === INVALID_PIN_ERROR_STRING) {
                setPinStatus('error');
              }
              setIsPosting(false);
            }
          }}
        />
        <div className="auth-button">
          <Button
            title="Submit Code"
            role="Secondary"
            loading={isPosting}
            onClick={() => {
              setPinStatus('error');
            }}
          />
        </div>
      </>
    </AuthWrapper>
  );
};

export default VerifyPhoneSubmit;
