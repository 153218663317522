import { useState, useMemo } from 'react';
import { InputProps, InputType } from './input-props';

const Input = ({
  type = 'text',
  label,
  error = false,
  editable = true,
  placeholder = '',
  labelStyles,
  ...props
}: InputProps) => {
  const forceLabelUp = !!placeholder && !!label;
  const errorClass = error ? 'error' : '';
  const [secureText, setSecureText] = useState<boolean>(true);

  const toggleRevealPassword = (): void => {
    setSecureText(!secureText);
  };

  const inputType = useMemo<InputType>(() => {
    if (type === 'password' && !secureText) {
      return 'text';
    }

    return type;
  }, [type, secureText]);

  return (
    <label className={`special-input ${props.className} ${errorClass}`}>
      <input
        {...props}
        className={`field ${errorClass} ${
          type === 'password' ? 'password-field' : ''
        }`}
        type={inputType}
        placeholder={placeholder || ' '}
        disabled={!editable}
      />
      {label && (
        <span
          className={`label ${errorClass} ${
            forceLabelUp ? 'force-label-up' : ''
          }`}
          style={labelStyles}>
          {label}
        </span>
      )}
      {type === 'password' && (
        <label className="show-password" onClick={toggleRevealPassword}>
          {secureText ? 'SHOW' : 'HIDE'}
        </label>
      )}
    </label>
  );
};

export default Input;
