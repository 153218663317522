import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '../../../../components/ArrowBack';
import { Button } from '../../../../components/Button';
import Cancel from '../common/Cancel';
import { BASE_DELETE_ACCOUNT_ROUTE } from '../const';
import { HeaderText, SubText } from '../styles';
import { BoldText, ButtonWrapper, CancelStyles, HeaderWrapper } from './styles';
import { RudderStack } from '../../../../services/shared/RudderStack';

const Legal = () => {
  const navigate = useNavigate();

  const onNext = () => {
    RudderStack.track('Account Deletion Disclaimer Accepted');
    navigate(`${BASE_DELETE_ACCOUNT_ROUTE}/confirm`);
  };

  return (
    <div>
      <ArrowBack text="BACK" />
      <HeaderWrapper>
        <HeaderText>Are you sure you want to delete your account?</HeaderText>
      </HeaderWrapper>
      <SubText style={{ marginBottom: 26 }}>
        After submitting this form, you have <BoldText>5 days</BoldText> to
        contact our customer support team to restore it before it's{' '}
        <BoldText>permanently deleted</BoldText>.
      </SubText>
      <SubText style={{ marginBottom: 26 }}>
        The following will also occur:
      </SubText>
      <SubText>
        <ul>
          <li>
            All active Special Cards will be <BoldText>cancelled</BoldText>
          </li>
          <li>
            Any <BoldText>remaining balance</BoldText> on Special Cards will be{' '}
            <BoldText>forfeited</BoldText>
          </li>
        </ul>
      </SubText>
      <SubText>
        Once your account is deleted you will need to create a new account to
        access the service again.
      </SubText>
      <ButtonWrapper>
        <Button title="Next" role="Secondary" onClick={onNext} />
        <CancelStyles>
          <Cancel
            onCancel={() => {
              RudderStack.track('Account Deletion Aborted', {
                interaction_location: 'Account Deletion Disclaimer',
              });
            }}
          />
        </CancelStyles>
      </ButtonWrapper>
    </div>
  );
};

export default Legal;
