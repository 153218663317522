export interface HomePageCard {
  id: number;
  imgSrc: string;
  maxValue: number;
  minimalValue: number;
  name: string;
  tag?: string;
  slug: string;
}

// dev
let EnvConfig = {
  env: 'undefined',
  roadhouseApiKey: 'NESTeFl0Dv9iPN6Gm8D5',
  apiUrl: 'https://api-user.dev.cloud.specialgift.io',
  webUrl: 'https://user.dev.cloud.specialgift.io',
  userPoolId: 'ap-southeast-2_8fzMY9yOx',
  userClientId: '3426bsgancs06fqm2rsj1f40pj',
  awsDomain: 'auth.dev.cloud.specialgift.io',
  awsRegion: 'ap-southeast-2',
  stripeTestModeOn: true,
  stripeConsumerKey:
    'pk_test_51K9gH8IMrvSBZ1gmsaT0Ayy0vp6zFRx5tU0ZKyvufwTnUwgSAUvS8myPvtxfycTikH66X1UPDDbbK6mVtczKGgQU001L3ZHOQj',
  stripeOfferKey:
    'pk_test_51Li96vCZuYunGPzRPz6YWJ7vlXtWQnSjNsl0QJNnXZ2RWUdcPvm5tiY9gDQtYzx55qtl0oj8bduHwvgWYF0B8gOF00LRpIFdgr',
  stripeUseDefaultKey: false,
  rudderWriteKey: '22cDSbLYhsMvnXUXz6U2lSXugJf',
  rudderDataPlaneUrl: 'https://kartatomebv.dataplane.rudderstack.com',
  freshDeskApiKey: 'ljJs8ciTLak9kpogQH1E',
  freshDeskApiUrl: 'https://special.freshdesk.com/',
  popularCards: [
    {
      id: 1,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 1',
      tag: '10% OFF',
      slug: 'stone-card-1',
    },
    {
      id: 2,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 2',
      tag: '10% OFF',
      slug: 'stone-card-2',
    },
    {
      id: 6,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 6',
      tag: '10% OFF',
      slug: 'stone-card-6',
    },
    {
      id: 7,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 7',
      slug: 'stone-card-7',
    },
  ],
  specialCards: [
    {
      id: 1,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 1',
      slug: 'stone-card-1',
    },
    {
      id: 2,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 2',
      slug: 'stone-card-2',
    },
    {
      id: 6,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 6',
      slug: 'stone-card-6',
    },
    {
      id: 7,
      imgSrc:
        'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
      maxValue: 1000,
      minimalValue: 5,
      name: 'Stone Card 7',
      slug: 'stone-card-7',
    },
  ],
};

// local
if (process.env.REACT_APP_ENV === 'local') {
  EnvConfig = {
    env: 'local',
    roadhouseApiKey: 'NESTeFl0Dv9iPN6Gm8D5',
    apiUrl: 'https://user-api-dev.specialgift.io',
    webUrl: 'http://localhost:3000',
    userPoolId: 'ap-southeast-2_L2hOiXYYE',
    userClientId: '38v9pv1vkk158fso0d40esscl4',
    awsDomain: 'auth-dev.specialgift.io',
    awsRegion: 'ap-southeast-2',
    stripeTestModeOn: true,
    stripeConsumerKey:
      'pk_test_51K9gH8IMrvSBZ1gmsaT0Ayy0vp6zFRx5tU0ZKyvufwTnUwgSAUvS8myPvtxfycTikH66X1UPDDbbK6mVtczKGgQU001L3ZHOQj',
    stripeOfferKey:
      'pk_test_51Li96vCZuYunGPzRPz6YWJ7vlXtWQnSjNsl0QJNnXZ2RWUdcPvm5tiY9gDQtYzx55qtl0oj8bduHwvgWYF0B8gOF00LRpIFdgr',
    stripeUseDefaultKey: false,
    rudderWriteKey: '22cDSbLYhsMvnXUXz6U2lSXugJf',
    rudderDataPlaneUrl: 'https://kartatomebv.dataplane.rudderstack.com',
    freshDeskApiKey: 'ljJs8ciTLak9kpogQH1E',
    freshDeskApiUrl: 'https://special.freshdesk.com/',
    popularCards: [
      {
        id: 1,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 1',
        tag: '10% OFF',
        slug: 'stone-card-1',
      },
      {
        id: 2,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 2',
        tag: '10% OFF',
        slug: 'stone-card-2',
      },
      {
        id: 6,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 6',
        tag: '10% OFF',
        slug: 'stone-card-6',
      },
      {
        id: 7,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 7',
        slug: 'stone-card-7',
      },
    ],
    specialCards: [
      {
        id: 1,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 1',
        slug: 'stone-card-1',
      },
      {
        id: 2,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 2',
        slug: 'stone-card-2',
      },
      {
        id: 6,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 6',
        slug: 'stone-card-6',
      },
      {
        id: 7,
        imgSrc:
          'https://dev-admin.specialgift.io/uploaded/131fc601-de48-4358-9587-c93af13276ad',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Stone Card 7',
        slug: 'stone-card-7',
      },
    ],
  };
}

// stage
if (process.env.REACT_APP_ENV === 'stage') {
  EnvConfig = {
    roadhouseApiKey: 'AIMQML1Wg88qbhQRHFov',
    webUrl: 'https://stage-user.specialgift.io',
    env: 'stage',
    apiUrl: 'https://user-api-stage.specialgift.io',
    userPoolId: 'ap-southeast-2_ayzXrxbSY',
    userClientId: '32uobg4q1s44hgdqi2o3hj2vf4',
    awsDomain: 'auth-stage.specialgift.io',
    awsRegion: 'ap-southeast-2',
    stripeTestModeOn: true,
    stripeConsumerKey:
      'pk_test_51K9gH8IMrvSBZ1gmsaT0Ayy0vp6zFRx5tU0ZKyvufwTnUwgSAUvS8myPvtxfycTikH66X1UPDDbbK6mVtczKGgQU001L3ZHOQj',
    stripeOfferKey:
      'pk_test_51Li96vCZuYunGPzRPz6YWJ7vlXtWQnSjNsl0QJNnXZ2RWUdcPvm5tiY9gDQtYzx55qtl0oj8bduHwvgWYF0B8gOF00LRpIFdgr',
    stripeUseDefaultKey: false,
    rudderWriteKey: '23IZeJvQkUstcEFzMr6nwauoXO7',
    rudderDataPlaneUrl: 'https://kartatomebv.dataplane.rudderstack.com',
    freshDeskApiKey: 'ljJs8ciTLak9kpogQH1E',
    freshDeskApiUrl: 'https://special.freshdesk.com/',
    popularCards: [
      {
        id: 7,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/24002fc0-0573-4109-b112-2ca769d29c92',
        maxValue: 1000,
        minimalValue: 5,
        name: 'DIY Legend',
        slug: 'diy-legend',
      },
      {
        id: 6,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/68d7f236-153e-4ca2-83f1-abd1fec2fb9d',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Chill Seeker',
        slug: 'chill-seeker',
      },
      {
        id: 10,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/af3684f9-dbde-43ec-af64-ee4d1c2bfcc7',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Aussie Explorer',
        slug: 'aussie-explorer',
      },
      {
        id: 12,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/d7d599d7-cba5-4dfc-8dd3-d16ee2eb6f01',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Sporty Star',
        slug: 'sporty-star',
      },
    ],
    specialCards: [
      {
        id: 11,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/e2f66289-d116-4d68-9834-f6540431ac21',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Pub crawler',
        slug: 'pub-crawler',
      },
      {
        id: 14,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/869b9e62-8ecb-4f65-bb8f-72e85ae71527',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Tech head',
        slug: 'tech-head',
      },
      {
        id: 5,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/35e17c09-c966-4134-b6c4-5ae4801f166a',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Film Buff',
        slug: 'film-buff',
      },
      {
        id: 16,
        imgSrc:
          'https://stage-admin.specialgift.io/uploaded/d231e809-2fde-4403-bd5b-75d50f83a0ae',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Latte Lover',
        slug: 'latte-lover',
      },
    ],
  };
}
// uat
else if (process.env.REACT_APP_ENV === 'uat') {
  EnvConfig = {
    env: 'uat',
    webUrl: 'https://uat-user.specialgift.io',
    roadhouseApiKey: 'T4vrUfqx8V8zF84NTADr',
    apiUrl: 'https://user-api-uat.specialgift.io',
    userPoolId: 'ap-southeast-2_9IX3qR4Cw',
    userClientId: '2rcianmuv71unhstctfooi3jj5',
    awsDomain: 'auth-uat.specialgift.io',
    awsRegion: 'ap-southeast-2',
    stripeTestModeOn: true,
    stripeConsumerKey:
      'pk_test_51K9gH8IMrvSBZ1gmsaT0Ayy0vp6zFRx5tU0ZKyvufwTnUwgSAUvS8myPvtxfycTikH66X1UPDDbbK6mVtczKGgQU001L3ZHOQj',
    stripeOfferKey:
      'pk_test_51Li96vCZuYunGPzRPz6YWJ7vlXtWQnSjNsl0QJNnXZ2RWUdcPvm5tiY9gDQtYzx55qtl0oj8bduHwvgWYF0B8gOF00LRpIFdgr',
    stripeUseDefaultKey: false,
    rudderWriteKey: '23IZeJvQkUstcEFzMr6nwauoXO7',
    rudderDataPlaneUrl: 'https://kartatomebv.dataplane.rudderstack.com',
    freshDeskApiKey: 'ljJs8ciTLak9kpogQH1E',
    freshDeskApiUrl: 'https://special.freshdesk.com/',
    popularCards: [
      {
        id: 6,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/1c557aff-4919-4161-8e76-fdb3f8e85b3f',
        maxValue: 1000,
        minimalValue: 5,
        name: 'DIY Legend',
        slug: 'diy-legend',
      },
      {
        id: 7,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/dc3200f0-a498-4fa4-b716-ddf61606a1a1',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Chill Seeker',
        slug: 'chill-seeker',
      },
      {
        id: 5,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/ad02baa2-dbd9-40d9-b954-d48cfea2fde7',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Aussie Explorer',
        slug: 'aussie-explorer',
      },
      {
        id: 13,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/86e9903d-025f-48d4-baae-6a3715278fd7',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Sporty Star',
        slug: 'sports-star',
      },
    ],
    specialCards: [
      {
        id: 12,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/26f1482c-b82d-4274-b797-5a932e7d1d63',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Pub crawler',
        slug: 'pub-crawler',
      },
      {
        id: 16,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/1aab65d7-8646-4a53-9c63-cbb183250496',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Tech head',
        slug: 'tech-head',
      },
      {
        id: 8,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/67cdfb2e-3753-4cf6-b6db-912fddc91a9a',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Film Buff',
        slug: 'film-buff',
      },
      {
        id: 11,
        imgSrc:
          'https://uat-admin.specialgift.io/uploaded/3424c99b-8bba-43b2-9600-d50e8fa6b589',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Latte Lover',
        slug: 'Latte Lover',
      },
    ],
  };
}
// prod
else if (process.env.REACT_APP_ENV === 'prod') {
  EnvConfig = {
    env: 'prod',
    webUrl: 'https://user.prod.cloud.special.com.au',
    roadhouseApiKey: '6enfGJLCqdxr6bJyQljt',
    apiUrl: 'https://api-user.prod.cloud.special.com.au',
    userPoolId: 'ap-southeast-2_eRYaVEP0t',
    userClientId: 'n5k8boai4oi3i3e2r4liumkf',
    awsDomain: 'auth.prod.cloud.special.com.au',
    awsRegion: 'ap-southeast-2',
    stripeTestModeOn: false,
    stripeConsumerKey:
      'pk_live_51K9gH8IMrvSBZ1gmHEyk0ImTJHXHogwbgRd3VaT42rxF1bPLTWapp78eEMVVn0tkGhJ4Xmr4yn6Lh4D7sNEFrt1F00pJYxHFnO',
    stripeOfferKey:
      'pk_test_51Li96vCZuYunGPzRPz6YWJ7vlXtWQnSjNsl0QJNnXZ2RWUdcPvm5tiY9gDQtYzx55qtl0oj8bduHwvgWYF0B8gOF00LRpIFdgr',
    stripeUseDefaultKey: false,
    rudderWriteKey: '23IZfrmMc4fXVDciTFgUrkKkcWq',
    rudderDataPlaneUrl: 'https://kartatomebv.dataplane.rudderstack.com',
    freshDeskApiKey: 'ljJs8ciTLak9kpogQH1E',
    freshDeskApiUrl: 'https://special.freshdesk.com/',
    popularCards: [
      {
        id: 36,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/f87949ce-ffc6-4ac7-a7cd-0593b1f946c8',
        maxValue: 1000,
        minimalValue: 5,
        name: 'DIY Legend',
        slug: 'diy-legend',
      },
      {
        id: 35,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/48d1dbfe-607e-40e1-9d4b-1a7ba7ee7685',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Chill Seeker',
        slug: 'chill-seeker',
      },
      {
        id: 39,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/68da6ed3-c483-407f-b659-14fec2e2958b',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Aussie Explorer',
        slug: 'aussie-explorer',
      },
      {
        id: 41,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/2730cb22-b9b8-460b-8673-c4b3ab530c6d',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Sporty Star',
        slug: 'sporty-star',
      },
    ],
    specialCards: [
      {
        id: 40,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/fca2a82d-9ab2-484e-ba94-c938d8fd2b15',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Pub crawler',
        slug: 'pub-crawler',
      },
      {
        id: 43,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/845f02c9-ba90-4d1a-8540-26031cda5e97',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Tech head',
        slug: 'tech-head',
      },
      {
        id: 34,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/20654bee-726d-419f-967f-d7fe895303ac',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Film Buff',
        slug: 'film-buff',
      },
      {
        id: 45,
        imgSrc:
          'https://admin.prod.cloud.special.com.au/uploaded/ea3d3d80-f9e5-443c-94ec-e3bb90bf5fd2',
        maxValue: 1000,
        minimalValue: 5,
        name: 'Latte Lover',
        slug: 'latte-lover',
      },
    ],
  };
}

export default EnvConfig;
