import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const LogoWrapper = styled.div`
  position: absolute;
  top: -4.8rem;
  ${map({ tablet: '2.2rem' }, (top) => `top: ${top};`)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Logo = styled.img`
  max-width: 16rem;
  z-index: 0;
`;

export const AffiliationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  position: absolute;
  top: 25.5rem;
  ${map({ tablet: '38.5rem' }, (top) => `top: ${top};`)}
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StaticAffilationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: ${Colors.black};
  margin-top: 3rem;
  ${map({ tablet: '5rem' }, (marginTop) => `margin-top: ${marginTop};`)}
  text-align: center;
`;

export const ActivationTopBarWrapper = styled.div`
  position: relative;
  top: -0.8rem;
  ${map({ tablet: '0rem' }, (top) => `top: ${top};`)}
  z-index: 2;
`;
