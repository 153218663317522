import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const MobileOnlyBlock = styled.div`
  display: block;
  ${map({ mobile: 'none' }, (display) => `display: ${display};`)}
`;

export const ArrowWrapper = styled.div`
  opacity: 1;
  ${map({ mobile: '0' }, (opacity) => `opacity: ${opacity};`)};
`;

export const HeaderText = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
  color: ${Colors.black};
`;

export const SubText = styled.div`
  font-size: 1.7rem;
  line-height: 2.7rem;
  color: ${Colors.black};
`;
