import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dotenv from 'dotenv';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// load the env variables early as services depends on them
dotenv.config();

const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

ReactDOM.render(
  <>
    <Helmet>
      <title>Special Gift Cards</title>
      <meta
        name="description"
        content="From big brands to local heroes, Special Gift Cards can be used at 
retailers of all kinds across Australia - online and in-store. Purchase a 
Special Gift Card today."
      />
      {iOS && (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      )}
    </Helmet>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
