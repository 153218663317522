import { breakpoints } from '../const/theme';

const TabletBreakPoint = 768;

export const IsMobileView = () => {
  const bodyWidth = document.body.offsetWidth;
  return bodyWidth < TabletBreakPoint;
};

export const IsTabletView = () => {
  const bodyWidth = document.body.offsetWidth;
  return bodyWidth < breakpoints.laptop && bodyWidth >= TabletBreakPoint;
};
