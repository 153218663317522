import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { CardPinStatus } from '../../components/CardPin';
import PhoneVerification from '../../components/PhoneVerification';
import { useAuth } from '../../context/AuthProvider';
import { partialHidePhone } from '../../util/helpers';

type props = {
  setOpen(value: boolean): void;
  newNumber: string;
};

const INVALID_PIN_ERROR_STRING = 'Invalid verification code';

const PhoneCodeModal = ({ setOpen, newNumber }: props) => {
  const alert = useAlert();
  const [pinStatus, setPinStatus] = useState<CardPinStatus>(undefined);
  const { verifyPhoneSubmit, userDetailsVM, setUserDetailsVM } = useAuth();
  const { userApi } = useApi();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <p className="ModalTitle">Verify your phone</p>
      <p className="ModalSubTitle">
        A verification code has been sent to your phone:{' '}
        {partialHidePhone(newNumber || '', 'X')}
      </p>
      <PhoneVerification
        pinStatus={pinStatus}
        onComplete={async (pin: string) => {
          setIsLoading(true);
          try {
            await verifyPhoneSubmit(pin);
            await userApi.updatePhoneNumber(newNumber || '');
            if (userDetailsVM) {
              setUserDetailsVM({
                ...userDetailsVM,
                mobileNumber: newNumber,
              });
            }
            setPinStatus('success');
            setIsLoading(false);
            setOpen(false);
          } catch (error: any) {
            setIsLoading(false);
            if (error === INVALID_PIN_ERROR_STRING) {
              setPinStatus('error');
              alert.error(error);
            }
          }
        }}
      />
      <Button
        title="Submit Code"
        role="Secondary"
        loading={isLoading}
        onClick={() => {
          setPinStatus('error');
        }}
      />
      <div className="auth-cancel" onClick={() => setOpen(false)}>
        CANCEL
      </div>
    </>
  );
};

export default PhoneCodeModal;
