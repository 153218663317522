import FacebookWhite from '../../../assets/svg/social/white/Facebook.svg';
import InstaWhite from '../../../assets/svg/social/white/Insta.svg';
import LinkedinWhite from '../../../assets/svg/social/white/Linkedin.svg';
import FacebookBlack from '../../../assets/svg/social/black/Facebook.svg';
import InstaBlack from '../../../assets/svg/social/black/Insta.svg';
import LinkedinBlack from '../../../assets/svg/social/black/Linkedin.svg';
import './footer-social-links.scss';

interface FooterSocialLinksProps {
  isLight: boolean;
  isMobile?: boolean;
}

const FooterSocialLinks = ({ isLight, isMobile = false }: FooterSocialLinksProps) => {
  return (
    <div className={`social-links ${isMobile ? isLight ? "mobile-light" : "mobile-dark" : ""}`}>
      <a
        href="https://www.facebook.com/specialgiftcards"
        target="_blank"
        rel="noreferrer">
        <img src={isLight ? FacebookWhite : FacebookBlack} alt="Facebook" />
      </a>
      <a
        href="https://www.instagram.com/specialgiftcards/"
        target="_blank"
        rel="noreferrer">
        <img src={isLight ? InstaWhite : InstaBlack} alt="Insta" />
      </a>
      <a
        href="https://www.linkedin.com/company/specialgiftcards"
        target="_blank"
        rel="noreferrer">
        <img src={isLight ? LinkedinWhite : LinkedinBlack} alt="Linked In" />
      </a>
    </div>
  );
};
export default FooterSocialLinks;
