import React, { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { SMS_SENDING_GAP_IN_SECONDS } from '../../const/auth';
import { useAuth } from '../../context/AuthProvider';
import CardPin, { CardPinStatus } from '../CardPin';
import { ErrorText } from '../ErrorText';

type Props = {
  onComplete: (pin: string) => void;
  pinStatus: CardPinStatus;
};

const ResendContainer = styled.div`
  margin: 1rem 0;
`;

const PhoneVerification = ({ onComplete, pinStatus }: Props) => {
  const [isResending, setIsResending] = useState(false);
  const [currentTime, setCurrentTime] = useState<Date>();
  const alert = useAlert();
  const { reVerifyPhone, lastSMSSent } = useAuth();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();

      setCurrentTime(currentTime);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onReVerifyPhone = async () => {
    if (isResending) {
      return;
    }

    if (timeGap !== null && timeGap < SMS_SENDING_GAP_IN_SECONDS) {
      return;
    }

    setIsResending(true);

    try {
      await reVerifyPhone();
      setIsResending(false);
      alert.success('Code sent');
    } catch (error: any) {
      setIsResending(false);
      alert.error(error.message.toString());
    }
  };

  const timeGap: number | null = useMemo(() => {
    if (currentTime instanceof Date && lastSMSSent instanceof Date) {
      const gap = Math.ceil(
        (currentTime.getTime() - lastSMSSent.getTime()) / 1000
      );
      return gap >= SMS_SENDING_GAP_IN_SECONDS
        ? SMS_SENDING_GAP_IN_SECONDS
        : gap;
    }
    return null;
  }, [lastSMSSent, currentTime]);

  const timerDisplay = useMemo(() => {
    if (timeGap !== null) {
      if (SMS_SENDING_GAP_IN_SECONDS - timeGap === 0) {
        return '';
      }
      return ` (${SMS_SENDING_GAP_IN_SECONDS - timeGap}s)`;
    }
    return '';
  }, [timeGap]);
  return (
    <>
      <CardPin
        size="small"
        length={6}
        placeholder={'0'}
        onComplete={onComplete}
        status={pinStatus}
      />
      {pinStatus === 'error' && (
        <div>
          <ErrorText
            style={{ paddingLeft: 0, marginTop: '0.8rem' }}
            text="Oops, that code doesn’t match. Try again."
          />
        </div>
      )}
      <ResendContainer>
        Code didn't arrive?{' '}
        <span className="auth-link" onClick={() => onReVerifyPhone()}>
          Resend code
        </span>
        {timerDisplay}
      </ResendContainer>
    </>
  );
};

export default PhoneVerification;
