import './image.scss';

type props = {
  desktopImage?: string;
  mobileImage?: string;
};

const Image = ({ desktopImage, mobileImage }: props) => {
  return (
    <div className="image-wrapper">
      {desktopImage && (
        <img
          className="desktop"
          src={desktopImage}
          alt="pay safer and faster using special gift card with apple pay"
        />
      )}
      {mobileImage && (
        <img
          className="mobile"
          src={mobileImage}
          alt="pay safer and faster using special gift card with apple pay"
        />
      )}
    </div>
  );
};
export default Image;
