import React, { useEffect, useRef } from 'react';
import Body from '../../components/Body';
import { Hero, HeroTitle, HeroSubtitle } from '../../components/Hero';
import HowItWorksArrow from '../../assets/svg/how-it-works/HowItWorksArrow.svg';
import StarsYellow from '../../assets/svg/how-it-works/StarsYellow.svg';
import StarsGreen from '../../assets/svg/how-it-works/StarsGreen.svg';
import FindThePerfectGift from '../../assets/svg/how-it-works/FindThePerfectGift.svg';
import TurnUpTheFun from '../../assets/svg/how-it-works/TurnUpTheFun.png';
import ScheduleForASpecialOccasion from '../../assets/svg/how-it-works/ScheduleForASpecialOccasion.svg';
import ShowSomeoneSpecial from '../../assets/svg/how-it-works/ShowSomeoneSpecial.svg';
import SignedSealedDeliverd from '../../assets/svg/how-it-works/SignedSealedDeliverd.svg';
import SomethingExciting from '../../assets/svg/how-it-works/SomethingExciting.svg';
import SpendInStoreOnline from '../../assets/svg/how-it-works/SpendInStoreOnline.svg';
import GiftsDesign from '../../assets/svg/how-it-works/GiftsDesign.svg';
import HowHeart from '../../assets/svg/how-it-works/HowHeart.svg';
import './how-it-works.scss';
import { RudderStack } from '../../services/shared/RudderStack';
import { Helmet } from 'react-helmet';

type Ref = null | HTMLDivElement;

const HowItWorks = () => {
  const givingRef = useRef<Ref>(null);
  const receivingRef = useRef<Ref>(null);

  useEffect(() => {
    RudderStack.page('HowItWorks');
  }, []);

  const scrollTo = (ref: Ref) => {
    ref?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  };

  return (
    <Body theme="soft-lilac">
      <Helmet>
        <title>Special Gift Cards | How It Works</title>
        <meta
          name="description"
          content="Choose a Gift Card design and celebration animation that plays when the gift is opened. Say something Special with a personal message."
        />
      </Helmet>
      <div className="content">
        <Hero>
          <HeroTitle>
            H<img className="hit-icon" src={HowHeart} alt="Heart" />
            w it <br />
            works
          </HeroTitle>
          <div className="how-it-works-subtitle">
            <HeroSubtitle>
              Gift Cards just became{' '}
              <span>
                Special<span>&trade;</span>
              </span>
            </HeroSubtitle>
            <div className="right-subtitle">
              <div onClick={() => scrollTo(givingRef?.current)}>
                <span>Giving</span>
                <img src={HowItWorksArrow} alt="HowItWorksArrow" />
              </div>
              <div onClick={() => scrollTo(receivingRef?.current)}>
                <span>Receiving</span>
                <img src={HowItWorksArrow} alt="HowItWorksArrow" />
              </div>
            </div>
          </div>
        </Hero>
        <div className="how-it-works">
          <div ref={givingRef} className="hit-section">
            <div className="hit-header giving">
              <img src={StarsYellow} alt="Stars" />
              GIVING A <br />
              SPECIAL CARD
            </div>
            <div className="hit-content">
              <div>
                <div className="hit-image heart">
                  <img src={FindThePerfectGift} alt="Find the perfect gift" />
                </div>
                <div className="hit-title">Find the perfect gift</div>
                <div className="hit-description">
                  From big brands to local heroes, Special Cards can be used at
                  retailers of all kinds across Australia – online and in-store.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img src={TurnUpTheFun} alt="Turn up the fun " />
                </div>
                <div className="hit-title">Turn up the fun</div>
                <div className="hit-description">
                  Choose a Special Card design and celebration animation that
                  plays when the gift is opened. Say something Special with a
                  personal message.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img
                    src={ScheduleForASpecialOccasion}
                    alt="Schedule for a Special occasion"
                  />
                </div>
                <div className="hit-title">Schedule for a Special occasion</div>
                <div className="hit-description">
                  Buy a gift today, then schedule delivery for the day and time
                  that means the most.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img
                    src={ShowSomeoneSpecial}
                    alt="Show someone they’re Special"
                  />
                </div>
                <div className="hit-title">Show someone they’re Special</div>
                <div className="hit-description">
                  Surprise and delight with a virtually wrapped Special Card,
                  they can unwrap and spend on something they like from a place
                  they love.
                </div>
              </div>
            </div>
          </div>
          <div ref={receivingRef} className="hit-section">
            <div className="hit-header receiving">
              RECEIVING A <br />
              SPECIAL CARD
              <img src={StarsGreen} alt="Stars" />
            </div>
            <div className="hit-content">
              <div>
                <div className="hit-image">
                  <img
                    src={SignedSealedDeliverd}
                    alt="Signed, sealed, delivered"
                  />
                </div>
                <div className="hit-title">Signed, sealed, delivered</div>
                <div className="hit-description">
                  All Special Cards are sent digitally via email or SMS,
                  complete with a personalised message.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img
                    src={SomethingExciting}
                    alt="Something exciting is inside"
                  />
                </div>
                <div className="hit-title">Something exciting is inside</div>
                <div className="hit-description">
                  Special Cards are sent wrapped with customisable unwrapping
                  animations and graphics.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img
                    src={SpendInStoreOnline}
                    alt="Spend in-store
                    or online"
                  />
                </div>
                <div className="hit-title">Spend in-store or online</div>
                <div className="hit-description">
                  Special Cards can be spent at participating stores using a
                  smartphone wallet, or by entering card details to shop online.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img src={GiftsDesign} alt="Gifts designed to be loved" />
                </div>
                <div className="hit-title">Gifts designed to be loved</div>
                <div className="hit-description">
                  From big brands to local heroes, Special Cards can be used at
                  businesses of all kinds. They're made to make people feel
                  Special.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};
export default HowItWorks;
