import React, { useEffect, useMemo } from 'react';
import { Alert } from '../../../components/Alert';

import { useApi } from '../../../api/ApiProvider';
import InfoSvg from '../../../assets/svg/alert/Info.svg';
import Loader from '../../../components/Loader';
import {
  MerchantLocationDetailsVM,
  MerchantLocationVM,
} from '../../../generated';
import { getLocationAddress, getValidStates } from '../../../helpers/cards';
import { MAX_PAGINATION_TAKE_SIZE } from '../../../shared/const';
import BrandRetailersHeader from './BrandRetailersHeader';
import { StateNames, States } from './constants';
import {
  LocationAddress,
  LocationBlock,
  LocationName,
  LocationsWrapper,
  StateGrid,
  StatePill,
  StickySection,
} from './styles';
import { IBrandRetailersProps } from './types';
import ContentLoader from 'react-content-loader';

const DEFAULT_ALERT_MESSAGE =
  'This card can be spent in store and online for this brand.';

const BrandRetailers: React.FC<IBrandRetailersProps> = ({
  giftCardId,
  merchantId,
  onBackClose,
  sideDrawerRef,
  brandName,
  hasOnlineStore = false,
}) => {
  const [selectedState, setSelectedState] = React.useState<StateNames>('ALL');
  const [validStates, setValidStates] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [merchantLocations, setMerchantLocations] = React.useState<
    (MerchantLocationVM | MerchantLocationDetailsVM)[]
  >([]);
  const [locationsToShow, setLocationsToShow] = React.useState<
    (MerchantLocationVM | MerchantLocationDetailsVM)[]
  >([]);

  const { merchantApi } = useApi();
  const onStateClick = (state: StateNames) => {
    if (state === selectedState) {
      return;
    }
    if (state === 'ALL') {
      setLocationsToShow(merchantLocations);
      setSelectedState('ALL');
    } else {
      const filteredLocations = merchantLocations.filter(
        (location) => location.state === state
      );
      setLocationsToShow(filteredLocations);
    }
    setSelectedState(state);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const merchantLocationResponse = await merchantApi.getMerchantLocations(
          merchantId,
          undefined,
          undefined,
          undefined,
          undefined,
          MAX_PAGINATION_TAKE_SIZE,
          undefined
        );
        const allLocations = merchantLocationResponse.data.results || [];
        setMerchantLocations(allLocations);
        setLocationsToShow(allLocations);
        setValidStates(getValidStates(allLocations));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log('Error: ', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  const alertMessage = useMemo(() => {
    if (merchantLocations.length > 0 && hasOnlineStore) {
      return DEFAULT_ALERT_MESSAGE;
    } else if (merchantLocations.length > 0 && !hasOnlineStore) {
      return 'This card can be spent in store for this brand.';
    } else if (merchantLocations.length === 0 && hasOnlineStore) {
      return 'This card can be spent online for this brand.';
    } else {
      return '';
    }
  }, [merchantLocations, hasOnlineStore]);

  return (
    <div>
      <StickySection>
        <BrandRetailersHeader
          onBackClose={onBackClose}
          sideDrawerRef={sideDrawerRef}
          total={locationsToShow.length}
          brandName={brandName}
        />
        {alertMessage && (
          <Alert title={alertMessage} role="Info" icon={InfoSvg} />
        )}
        {isLoading ? (
          <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox="0 0 476 80"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="80" />
          </ContentLoader>
        ) : (
          <StateGrid style={{ marginTop: !alertMessage ? '2rem' : 0 }}>
            {Object.entries(States).map(([key, state]) => {
              const isPresent = validStates.includes(state.label);
              const onClick = isPresent
                ? () => onStateClick(state.label)
                : undefined;
              return (
                <StatePill
                  key={key}
                  isSelected={state.label === selectedState}
                  onClick={
                    state.label === 'ALL'
                      ? () => onStateClick(state.label)
                      : onClick
                  }
                  isPresent={state.label === 'ALL' ? true : isPresent}>
                  {state.label}
                </StatePill>
              );
            })}
          </StateGrid>
        )}
      </StickySection>
      <LocationsWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {locationsToShow.length > 0 &&
              locationsToShow.map((location) => (
                <LocationBlock key={location.id}>
                  {brandName && location.suburb && (
                    <LocationName>
                      {!!location.name
                        ? location.name
                        : `${brandName} - ${location.suburb}`}
                    </LocationName>
                  )}
                  <LocationAddress>
                    {getLocationAddress(location)}
                  </LocationAddress>
                </LocationBlock>
              ))}
          </div>
        )}
      </LocationsWrapper>
    </div>
  );
};

export default BrandRetailers;
