import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { ReactComponent as ByeSvg } from '../../assets/svg/delete-account/Bye.svg';

import {
  ButtonWrapper,
  ByeContainer,
  ByeWrapper,
  Divider,
  EyeStyles,
  MainSection,
  Message,
  PaddedWrapper,
  SorryText,
} from './styles';
import { Button } from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';

interface OwnProps {}

const Bye: React.FC<OwnProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!location.state) {
      navigate('/', { replace: true });
    }
  }, [location.state, navigate]);

  return (
    <ByeWrapper>
      <ByeContainer>
        <div style={{ width: '100%' }}>
          <Header displayMobileMenu={false} />
          <PaddedWrapper>
            <MainSection>
              <SorryText>Sorry to see you go.</SorryText>
              <EyeStyles>
                <ByeSvg height={'100%'} width={'100%'} />
              </EyeStyles>
            </MainSection>
            <Divider />
            <Message>You're Special to us and we'll miss you!</Message>
          </PaddedWrapper>
        </div>
        <PaddedWrapper>
          <ButtonWrapper>
            <Button
              title="BACK TO HOME"
              startIconClass={'heart-icon'}
              role="Primary"
              onClick={onClick}
            />
          </ButtonWrapper>
        </PaddedWrapper>
      </ByeContainer>
    </ByeWrapper>
  );
};

export default Bye;
