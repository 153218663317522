import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../../../../api/ApiProvider';
import ArrowBack from '../../../../components/ArrowBack';
import { Button } from '../../../../components/Button';
import { ErrorText } from '../../../../components/ErrorText';
import { Input } from '../../../../components/Input';
import Colors from '../../../../const/Colors';
import { useAccountDeletion } from '../../../../context/AccountDeletionProvider';
import { useAuth } from '../../../../context/AuthProvider';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { RudderStack } from '../../../../services/shared/RudderStack';
import Cancel from '../common/Cancel';
import { HeaderWrapper } from '../Legal/styles';
import { HeaderText, SubText } from '../styles';
import { CancelStyles, FooterNote, InputWrapper } from './styles';

const CONFIRMATION_TEXT = 'Delete';
const INPUT_LABEL_THRESHOLD = 310;

const Confirm = () => {
  const navigate = useNavigate();
  const { signOut, isLoggedIn, userDetailsVM } = useAuth();
  const { userApi } = useApi();
  const { deletionRequestReason } = useAccountDeletion();
  const [isSigningOut, setIsSigningOut] = useState(false);

  const onNext = async () => {
    if (confirmText !== CONFIRMATION_TEXT) {
      setHasError(true);
      return;
    }
    RudderStack.track('Account Deletion Completed');
    if (!userDetailsVM?.id) {
      return;
    }
    setIsSigningOut(true);
    try {
      await userApi.requestUserDeletion(
        userDetailsVM.id,
        undefined,
        deletionRequestReason
      );
      await signOut();
      setIsSigningOut(false);
      navigate('/bye', { state: 'routing from confirm' });
    } catch (error) {
      setIsSigningOut(false);
      console.log(error);
    }
  };

  const [confirmText, setConfirmText] = useState('');
  const onConfirmTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
    setHasError(false);
  };

  const [hasError, setHasError] = useState(false);
  const onBlur = () => {
    if (confirmText !== CONFIRMATION_TEXT) {
      setHasError(true);
    }
    setLabelStyles(initialLabelStyle);
  };

  const { windowWidth } = useWindowDimensions();
  const labelText = useMemo(
    () =>
      windowWidth < INPUT_LABEL_THRESHOLD
        ? 'Type “Delete”'
        : 'Type the word “Delete”',
    [windowWidth]
  );

  const [labelStyles, setLabelStyles] = useState<
    React.CSSProperties | undefined
  >(initialLabelStyle);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div>
      <ArrowBack text="BACK" />
      <HeaderWrapper>
        <HeaderText>Delete account</HeaderText>
      </HeaderWrapper>
      <SubText>Type “Delete” below if you want to proceed:</SubText>
      <InputWrapper>
        <Input
          type="text"
          label={labelText}
          onChange={onConfirmTextChange}
          error={hasError}
          value={confirmText}
          labelStyles={labelStyles}
          onFocus={() => setLabelStyles(undefined)}
          onBlur={onBlur}
        />

        {hasError && (
          <ErrorText
            style={{ paddingBottom: 0 }}
            text="Please type the word “Delete”"
          />
        )}
      </InputWrapper>
      <FooterNote>
        By continuing, you understand and agree to the{' '}
        <Link
          to="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}>
          Special Privacy Policy
        </Link>{' '}
        and the{' '}
        <Link
          to="/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}>
          Special Terms and Conditions
        </Link>
        .
      </FooterNote>

      <div>
        <Button
          title="DELETE ACCOUNT"
          role="Secondary"
          disabled={!confirmText}
          loading={isSigningOut}
          style={{
            backgroundColor: Colors.black,
            opacity: confirmText ? 1 : 0.65,
          }}
          onClick={onNext}
        />
        <CancelStyles>
          <Cancel
            onCancel={() => {
              RudderStack.track('Account Deletion Aborted', {
                interaction_location: 'Account Deletion Confirmation',
              });
            }}
          />
        </CancelStyles>
      </div>
    </div>
  );
};

const linkStyle: React.CSSProperties = {
  color: Colors.black,
};

const initialLabelStyle: React.CSSProperties = {
  paddingTop: '0.5rem',
};

export default Confirm;
