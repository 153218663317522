import Flower from '../../assets/svg/Flower.svg';
import './loader.scss';

type props = {
  isLoading?: boolean;
  loadingTextStyle?: React.CSSProperties;
};

const Loader = ({ isLoading = true, loadingTextStyle }: props) => (
  <div className="content loader-wrapper">
    {isLoading && (
      <>
        <img className="loader" src={Flower} alt="loading" />
        <p style={loadingTextStyle}>Loading...</p>
      </>
    )}
  </div>
);
export default Loader;
