import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import { COMMON_ERR_MSG, EMAIL_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';

type props = {
  open: boolean;
  setOpen(value: boolean): void;
};

const EmailModal = ({ open, setOpen }: props) => {
  const { userDetailsVM, setUserDetailsVM } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: userDetailsVM?.email || '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { userApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const onSubmit = async (data: { email: string }) => {
    setIsLoading(true);
    const { email } = data;
    try {
      await userApi.updateEmail(email);
      if (userDetailsVM) {
        setUserDetailsVM({
          ...userDetailsVM,
          email: email,
        });
      }
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      alert.error(COMMON_ERR_MSG);
    }
  };

  return (
    <>
      <div className="Modal">
        <p className="ModalTitle">Update or edit your email</p>
        <p className="ModalSubTitle">Enter your new email address.</p>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: EMAIL_REGEX,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="Email address"
              type={'text'}
              onBlur={onBlur}
              onChange={onChange}
              value={value.toLowerCase()}
              inputMode="email"
              error={!!errors.email}
            />
          )}
          name="email"
        />
        {errors.email && (
          <div>
            <ErrorText text="Email is required" />
          </div>
        )}
        <Button
          title="Update Email"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        />
        <div className="auth-cancel" onClick={() => setOpen(false)}>
          CANCEL
        </div>
      </div>
    </>
  );
};

export default EmailModal;
