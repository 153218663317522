import React, { useCallback, useEffect, useMemo } from 'react';
import { ReactComponent as BlackColorLogo } from '../../../assets/svg/logo/FullColorBlack.svg';
import { ReactComponent as AllBlackLogo } from '../../../assets/svg/logo/Black.svg';
import { ReactComponent as Pointer } from '../../../assets/svg/activation/unwrap/pointer.svg';
import { ReactComponent as BottomStars } from '../../../assets/svg/activation/unwrap/bottomStars.svg';
import { ReactComponent as TopStars } from '../../../assets/svg/activation/unwrap/topStars.svg';
import { ReactComponent as Present } from '../../../assets/svg/activation/unwrap/present.svg';
import { ReactComponent as Shadow } from '../../../assets/svg/activation/unwrap/shadow.svg';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Breakpoints from '../../../const/breakpoints';

import './styles.scss';
import { RudderStack } from '../../../services/shared/RudderStack';

type Props = {};

const Unwrap = (props: Props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/activate/card-reveal/');
  };

  useEffect(() => {
    RudderStack.page("Activation_Unwrap");
  },[])

  const { windowHeight, windowWidth } = useWindowDimensions();

  const nonMobileWithSmallHeight = useMemo(
    () => windowHeight < 745,
    [windowHeight]
  );

  const mobileWithSmallHeight = useMemo(
    () => windowHeight < 620,
    [windowHeight]
  );

  const getMobileDimensions = useCallback(() => {
    const defaultDimensions = {
      height: '30.6rem',
      width: '28.7rem',
    };
    const mobileDimensions = 0.4 * windowHeight;

    if (mobileWithSmallHeight) {
      return {
        height: mobileDimensions,
        width: mobileDimensions,
      };
    }
    return defaultDimensions;
  }, [windowHeight, mobileWithSmallHeight]);

  const getNonMobileDimensions = useCallback(() => {
    const defaultDimensions = {
      height: '45rem',
      width: '42rem',
    };
    const nonMobileDimensions = 0.51 * windowHeight;

    if (nonMobileWithSmallHeight) {
      return {
        height: nonMobileDimensions,
        width: nonMobileDimensions,
      };
    }
    return defaultDimensions;
  }, [windowHeight, nonMobileWithSmallHeight]);

  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  const presentDimensions = useMemo(() => {
    if (isMobileView) {
      return getMobileDimensions();
    } else {
      return getNonMobileDimensions();
    }
  }, [isMobileView, getMobileDimensions, getNonMobileDimensions]);

  return (
    <div className="unwrap">
      <div className="top">
        <div className="colorLogo">
          <BlackColorLogo width={116} height={34} />
        </div>
        <div className="blackLogo">
          <AllBlackLogo width={116} height={34} />
        </div>

        <div className="topText">Tap anywhere to unwrap your gift</div>
      </div>

      <div className="giftWrapper" onClick={onClick}>
        <div className="gift">
          <Pointer className="pointer" />
          <TopStars className="topStars" />
          <Present
            className="present"
            height={presentDimensions.height}
            width={presentDimensions.width}
          />
          <Shadow className="shadow" />
          <BottomStars className="bottomStars" />
        </div>
      </div>
    </div>
  );
};

export default Unwrap;
