import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useMemo } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { Button } from '../Button';
import './SocialLogin.scss';

const isFacebookApp = () => {
  const ua = navigator.userAgent || navigator.vendor;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
};

const SocialLogin = () => {
  const auth = useAuth();

  const googleLogin = () => {
    signIn(CognitoHostedUIIdentityProvider.Google);
  };

  const appleLogin = () => {
    signIn(CognitoHostedUIIdentityProvider.Apple);
  };

  const facebookLogin = () => {
    signIn(CognitoHostedUIIdentityProvider.Facebook);
  };

  const signIn = async (provider: CognitoHostedUIIdentityProvider) => {
    await auth.federatedSignin(provider);
  };

  const showSocialSignInWarning = useMemo(() => {
    return isFacebookApp();
  }, []);

  return (
    <>
      <div className="social-container">
        <Button
          role="Social"
          color="white"
          startIconClass="apple-icon"
          onClick={appleLogin}
        />
        <Button
          role="Social"
          color="white"
          startIconClass="facebook-icon"
          onClick={facebookLogin}
        />
        <Button
          role="Social"
          color="white"
          startIconClass="google-icon"
          onClick={googleLogin}
        />
      </div>
      {showSocialSignInWarning && (
        <div className="social-sign-in-warning">
          Please note, Google Sign-In may not work in this browser.
        </div>
      )}
    </>
  );
};

export default SocialLogin;
