import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as WarningSvg } from '../../../../assets/svg/Warning.svg';
import Colors from '../../../../const/Colors';
import { map } from '../../../../const/theme';

interface OwnProps {
  style?: React.CSSProperties;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.6rem 2.4rem;
  background: rgba(255, 165, 0, 0.05);
  border: 1px solid rgba(255, 165, 0, 0.4);
  border-radius: 9px;
  width: min(79vw, 33.3rem);
  ${map({ tablet: 'unset' }, (width) => `width: ${width};`)};
  max-width: 33.3rem;
  ${map({ tablet: '42.1rem' }, (maxWidth) => `max-width: ${maxWidth};`)};
`;

const Text = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  margin-left: 1.1rem;
  letter-spacing: 0.04em;
  color: ${Colors.black};
`;

const InfoBubble: React.FC<OwnProps> = ({ style }) => {
  return (
    <Wrapper style={style}>
      <WarningSvg height={19} width={19} style={svgStyle} />
      <Text>
        This card cannot be used, please contact our{' '}
        <Link to="/contact-us" target="_blank" style={linkStyle}>
          support
        </Link>
        .
      </Text>
    </Wrapper>
  );
};

const svgStyle: React.CSSProperties = {
  minWidth: 19,
  minHeight: 19,
};

const linkStyle: React.CSSProperties = {
  color: Colors.black,
};

export default InfoBubble;
