import {
  EMAIL_ATTRIBUTE,
  PHONE_NUMBER_ATTRIBUTE,
  PHONE_NUMBER_VERIFIED,
} from '../../const/auth';
import { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import EnvConfig from '../../config/EnvConfig';

export const SignIn = async (
  username: string,
  password: string
): Promise<CognitoUser> => {
  const user = await Auth.signIn(username, password);
  return user;
};

export const ForgotPassword = async (email: string): Promise<any> => {
  return await Auth.forgotPassword(email);
};

export const ForgotPasswordSubmit = async (
  email: string,
  code: string,
  password: string
): Promise<any> => {
  return await Auth.forgotPasswordSubmit(email, code, password);
};

export const ChangePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<any> => {
  return await Auth.changePassword(
    await Auth.currentAuthenticatedUser(),
    oldPassword,
    newPassword
  );
};

export const IsPhoneVerified = (user: CognitoUser): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    user.getUserAttributes((error, attributes) => {
      const phoneVerified =
        attributes?.find(
          (attribute) => attribute.Name === PHONE_NUMBER_VERIFIED
        )?.Value === 'true';
      if (phoneVerified) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

export const GetCurrentAuthenticatedUser = async (params?: any) => {
  return await Auth.currentAuthenticatedUser(params);
};

export const GetUserName = async (): Promise<string> => {
  const user = await GetCurrentAuthenticatedUser();
  if (!user) {
    return '';
  }
  return user.getUsername();
};

export const GetJWTToken = async (): Promise<string> => {
  try {
    const user: CognitoUserSession | undefined = await Auth.currentSession();
    if (!user) {
      return '';
    }
    return user.getAccessToken().getJwtToken();
  } catch (err: any) {
    return '';
  }
};

export const VerifyPhone = (phone: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const user = await GetCurrentAuthenticatedUser();

    if (!user) {
      reject('No current user.');
      return;
    }
    user.updateAttributes(
      [
        new CognitoUserAttribute({
          Name: PHONE_NUMBER_ATTRIBUTE,
          Value: phone,
        }),
      ],
      (error: any, result: any) => {
        if (error) {
          reject(error);
        }

        resolve(true);
      }
    );
  });
};

export const VerifyEmail = (): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const user = await GetCurrentAuthenticatedUser();

    if (!user) {
      reject('No current user.');
      return;
    }
    user.getAttributeVerificationCode(EMAIL_ATTRIBUTE, {
      onSuccess: () => {
        resolve(true);
      },
      onFailure: (err: any) => {
        reject(err);
      },
    });
  });
};

export const VerifyEmailSubmit = (code: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const user = await GetCurrentAuthenticatedUser();
    if (!user) {
      reject('No current user.');
      return;
    }
    user.verifyAttribute(EMAIL_ATTRIBUTE, code, {
      onSuccess: () => {
        resolve(true);
      },
      onFailure: (error: any) => {
        reject('Invalid verification code');
      },
    });
  });
};

export const ReVerifyPhone = (): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const user = await GetCurrentAuthenticatedUser();

    if (!user) {
      reject('No current user.');
      return;
    }

    user.getAttributeVerificationCode(PHONE_NUMBER_ATTRIBUTE, {
      onSuccess: () => {
        resolve(true);
      },
      onFailure: (err: any) => {
        reject(err);
      },
    });
  });
};

export const VerifyPhoneSubmit = (code: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const user = await GetCurrentAuthenticatedUser();
    if (!user) {
      reject('No current user.');
      return;
    }
    user.verifyAttribute(PHONE_NUMBER_ATTRIBUTE, code, {
      onSuccess: () => {
        resolve(true);
      },
      onFailure: (error: any) => {
        reject('Invalid verification code');
      },
    });
  });
};

export const Logout = async () => {
  return await Auth.signOut();
};

export const FederatedLogin = async (
  provider: CognitoHostedUIIdentityProvider
) => {
  await Auth.federatedSignIn({ provider });
};

export const ConfigureAuth = async () => {
  Auth.configure({
    region: EnvConfig.awsRegion,
    userPoolId: EnvConfig.userPoolId,
    userPoolWebClientId: EnvConfig.userClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: EnvConfig.awsDomain,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      responseType: 'token',
      redirectSignIn: EnvConfig.webUrl,
      redirectSignOut: EnvConfig.webUrl,
      options: {
        AdvancedSecurityDataCollectionFlag: true,
      },
    },
  });
};
