import React, { useEffect } from 'react';
import ActivationTopBar from '../common/Topbar';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import { ReactComponent as SpecialIcon } from '../../../assets/svg/activation/success/SpecialIcon.svg';
import DownloadApp from '../../../components/DownloadApp';
import { Button } from '../../../components/Button';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './styles.scss';
import Colors from '../../../const/Colors';
import ActivationCard from '../common/ActivationCard';
import { RudderStack } from '../../../services/shared/RudderStack';
import { useActivationBrands } from '../hooks/useActivationBrands';
import {
  ActivationTopBarWrapper,
  AffiliationText,
  Logo,
  LogoWrapper,
  StaticAffilationText,
} from '../common/styles';
import {
  ACTIVATION_AFFILIATION_TEXT,
  ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
} from '../constants';

const Success = () => {
  const navigate = useNavigate();

  const onSeeGift = () => {
    navigate('/my-cards');
  };

  useEffect(() => {
    RudderStack.page('Activation_Success');
  }, []);

  const {
    affiliationTextColour,
    backgroundColor,
    displayAffiliationText,
    logo,
  } = useActivationBrands();

  return (
    <div className="activationSuccess" style={{ backgroundColor }}>
      <ActivationTopBarWrapper>
        <ActivationTopBar showFAQ={false} showBack={false} />
      </ActivationTopBarWrapper>
      <LogoWrapper>
        <Logo src={logo || ''} alt="" />
      </LogoWrapper>
      <div className="successCardWrapper">
        <ActivationCard />
      </div>
      {displayAffiliationText && (
        <AffiliationText
          style={{
            color:
              affiliationTextColour ||
              ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
          }}>
          {ACTIVATION_AFFILIATION_TEXT}
        </AffiliationText>
      )}
      <Border height="62" fill={Colors.white} className="scallop" />

      <div className="details">
        <div className="detailsWrapper">
          <div className="title">
            Your Special Gift Card is now active. Happy spending!
          </div>

          <div className="subTitle">
            To use your Special card in-store, it needs to be connected to your
            Apple or Google wallet via the
            <span className="thick"> Special mobile app.</span>
          </div>

          <div className="specialIcon">
            <SpecialIcon height={83} width={83} />
          </div>

          <div className="successDownloadApp">
            <DownloadApp />
          </div>

          <div className="seeGift">
            <Button
              title="SEE MY GIFT CARD"
              startIconClass="heart-icon"
              role="Primary"
              onClick={onSeeGift}
            />
            {displayAffiliationText && (
              <StaticAffilationText>
                {ACTIVATION_AFFILIATION_TEXT}
              </StaticAffilationText>
            )}
          </div>

          <div className="shop">
            <div className="shopItem">
              <div className="itemTitle">Shop in-store</div>
              <div className="itemSubTitle">
                Download the mobile app, so you can add gift cards to your
                smartphone wallet and simply tap and pay instore.
              </div>
            </div>

            <div className="shopItem">
              <div className="itemTitle">Shop online</div>
              <div className="itemSubTitle">
                Go to{' '}
                <Link to="/my-cards" className="myCardsLink">
                  My Cards
                </Link>{' '}
                in the app or browser to find card details and enter them into
                the payment section at checkout.
              </div>
            </div>
          </div>
        </div>
        <Footer hideCtaBanner theme="black" />
      </div>
    </div>
  );
};

export default Success;
