import './heroWrapper.scss';

type props = {
  showBorder: boolean;
  children?: any;
};

const Hero = ({ children, showBorder }: props) => {
  return (
    <div className={`hero-wrapper ${!showBorder && 'no-border'}`}>
      <div className={`${showBorder && 'border'}`}>{children}</div>
    </div>
  );
};
Hero.defaultProps = {
  showBorder: true,
};

export default Hero;
