export const HasItem = (key: string) => {
  const value = localStorage[key];
  return !!value;
};

export const SaveItem = (key: string, value: any) => {
  localStorage[key] = value;
};

export const GetItem = (key: string) => {
  const value = localStorage[key];
  return value;
};

export const RemoveItem = (key: string) => {
  localStorage[key] = undefined;
};

export const GetKeyForDraftCard = (id: number | string) => {
  return `Draft_Card_${id}`;
};
