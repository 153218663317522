import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useApi } from '../../../api/ApiProvider';
import { Datum } from '../../../generated';
import MyCardsDetailsBackButton from '../../../assets/svg/my-cards/MyCardsDetailsBackButton.svg';
import { UserGiftCard } from '..';
import TransactionLoader from '../MyCardDetails/TransactionLoader';
import TransactionItem from '../MyCardDetails/TransactionItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  SideDrawerContainer,
  SideDrawerHeader,
} from '../../../components/SideDrawer';

type Props = {
  userGiftCard?: UserGiftCard;
};

const RecentActivityContainer = styled.div`
  margin-top: 2rem;
  .no-activity {
    font-weight: bold;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colours.line1};
  }
  .view-all {
    font-weight: bold;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colours.black70};
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    img {
      width: 1.6rem;
    }
  }
`;

const Divider = styled.div`
  margin-bottom: 2rem;
  border-top: 1px solid ${(props) => props.theme.colours.border};
`;

const RecentActivities = ({ userGiftCard }: Props) => {
  const { cardTransactionApi } = useApi();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<Datum[]>([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const getCardTransactions = useCallback(
    async (userGiftCardId: number) => {
      const { data } = await cardTransactionApi.getCardTransactions(
        userGiftCardId,
        transactions.length
      );
      const userTransactions = data?.data || [];
      const newTransactions = [...transactions, ...userTransactions];
      setHasMore(!data.lastPage);
      setTransactions(newTransactions);
    },
    [cardTransactionApi, transactions]
  );

  useEffect(() => {
    (async () => {
      setLoadingTransactions(true);
      const { data } = await cardTransactionApi.getCardTransactions(
        userGiftCard?.id ?? -1,
        0
      );
      const userTransactions = data?.data || [];
      setHasMore(!data.lastPage);
      setTransactions(userTransactions);
      setLoadingTransactions(false);
    })();
  }, [userGiftCard, cardTransactionApi]);

  if (!userGiftCard) {
    return <></>;
  }
  return (
    <>
      <SideDrawerHeader>
        <img
          onClick={() => {
            navigate(-1);
          }}
          src={MyCardsDetailsBackButton}
          alt="Back"
        />
        <div className="header-title">Recent Activity</div>
      </SideDrawerHeader>
      <SideDrawerContainer>
        <RecentActivityContainer className="recent-activity-container">
          {loadingTransactions ? (
            <>
              <TransactionLoader />
              <TransactionLoader />
              <TransactionLoader />
            </>
          ) : transactions.length > 0 ? (
            <InfiniteScroll
              scrollableTarget="SideDrawerInnerContainer"
              dataLength={transactions.length} //This is important field to render the next data
              next={async () => {
                getCardTransactions(userGiftCard?.id ?? -1);
              }}
              hasMore={hasMore}
              loader={<TransactionLoader />}>
              {transactions.map((transaction) => (
                <>
                  <TransactionItem key={transaction.id} data={transaction} />
                  <Divider />
                </>
              ))}
            </InfiniteScroll>
          ) : (
            <div className="no-activity">NO RECENT ACTIVITY</div>
          )}
        </RecentActivityContainer>
      </SideDrawerContainer>
    </>
  );
};

export default RecentActivities;
