import styled from 'styled-components';
import { map } from '../../const/theme';

export const BrandWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${map(
    { tablet: 'repeat(4, 1fr)' },
    (gridTemplateColumns) => `grid-template-columns: ${gridTemplateColumns}}`
  )};
  grid-row-gap: 2.4rem;
  ${map({ tablet: '7rem' }, (gridRowGap) => `grid-row-gap: ${gridRowGap}}`)};
  row-gap: 2.4rem;
  grid-column-gap: 2rem;
  column-gap: 2rem;
`;

export const AffiliationText = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 6rem;
  margin-bottom: 10rem;
  text-align: center;
`;
