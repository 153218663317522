import moment from 'moment';
import {
  GiftCardDetailsVM,
  GiftCardProgramType,
  OrderVM,
  SpecialTimezoneVM,
} from '../generated';

export const allEqualArray = (arr: string[]) => arr.every((v) => v === arr[0]);

export const hasSameDigits = (pin: string) => {
  const pinArray = pin.split('');
  return allEqualArray(pinArray);
};

export const hasSequence = (pin: string) => {
  var numbers = '0123456789';
  var numbersRev = '9876543210';
  return numbers.includes(pin) || numbersRev.includes(pin);
};

export const isStartingSequence = (pin: string) => {
  return pin === '1234';
};

export const existsInPhoneNumber = (phoneNumber: string, pin: string) => {
  return phoneNumber.includes(pin);
};

export const matchesPanLastFour = (panLastFour: string, pin: string) => {
  return panLastFour === pin;
};

export const validatePin = (
  pin: string,
  pinLength: number,
  phoneNumber: string,
  panLastFour: string
) => {
  return (
    !hasSameDigits(pin) &&
    !isStartingSequence(pin) &&
    !existsInPhoneNumber(phoneNumber, pin) &&
    !matchesPanLastFour(panLastFour, pin) &&
    pin.length === pinLength
  );
};

export const getFormattedTime = (time: string) => {
  const timeInInteger = parseInt(time ?? '0');
  if (timeInInteger === 0) {
    return '00';
  }
  if (timeInInteger) {
    const timeAppend = timeInInteger < 10 ? '0' : '';
    return `${timeAppend}${timeInInteger}`;
  }
  return time;
};

export const getInitialTime = (
  format: string,
  date?: string,
  selectedTimezone?: SpecialTimezoneVM
) => {
  if (!selectedTimezone) {
    const freshDate = moment(new Date());
    return freshDate.format(format);
  } else {
    const currentDate = moment.utc(date);
    return currentDate
      .add(selectedTimezone?.timeZoneDifference ?? 0, 'minutes')
      .format(format);
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length < 11) {
    return phoneNumber;
  }
  const countryCode = phoneNumber.substring(0, 2);
  const firstThree = phoneNumber.substring(2, 5);
  const secondThree = phoneNumber.substring(5, 8);
  const lastThree = phoneNumber.substring(8);

  const formattedNumber = `+${countryCode} ${firstThree} ${secondThree} ${lastThree}`;
  return formattedNumber;
};

export const getOrderDate = (
  order: OrderVM,
  timezones: SpecialTimezoneVM[]
) => {
  const selectedTimezone = timezones.find(
    (x) => x.id === order.userGiftCardPersonalization?.specialTimeZone?.id
  );
  if (selectedTimezone) {
    const momentDate = moment
      .utc(order.deliveryDate)
      .add(selectedTimezone.timeZoneDifference ?? 0, 'minutes')
      .format('D/M/YYYY [at] h:mma');
    return momentDate;
  } else {
    return moment.utc(order.deliveryDate).local().format('D/M/YYYY [at] h:mma');
  }
};

export const getCardStarColors = (card?: GiftCardDetailsVM) => {
  if (!card) {
    return {
      starOneColour: undefined,
      starTwoColour: undefined,
    };
  }
  const programProfiles = card.giftCardProgram?.programProfiles ?? [];
  const starOneColour = programProfiles[0]?.starOneColour;
  const starTwoColour = programProfiles[0]?.starTwoColour;
  return {
    starOneColour,
    starTwoColour,
  };
};

export const getGiftCardEssentials = (card?: GiftCardDetailsVM) => {
  if (!card) {
    return {
      giftCardProgamType: GiftCardProgramType.Category,
      displayAffiliationText: false,
      brandMerchant: undefined,
    };
  }

  const giftCardProgamType = card.giftCardProgram?.giftCardProgramType;
  const displayAffiliationText = card.giftCardProgram?.displayAffiliationText;
  const brandMerchant = card.giftCardProgram?.brandMerchant;
  return {
    giftCardProgamType,
    displayAffiliationText,
    brandMerchant,
  };
};

export const isBrandMerchant = (card?: GiftCardDetailsVM) => {
  if (!card) {
    return false;
  }
  const giftCardProgramType =
    card.giftCardProgram?.giftCardProgramType || GiftCardProgramType.Category;
  return giftCardProgramType === GiftCardProgramType.Brand;
};
