import styled from 'styled-components';
import Colors from '../../const/Colors';
import { map } from '../../const/theme';

export const ButtonWrapper = styled.div`
  padding-bottom: 1.4rem;

  > * {
    margin-bottom: 1.6rem;
  }
`;

export const HowItWorks = styled.p`
  font-size: 2rem;
  margin-bottom: 3rem;
  line-height: 2.8rem;
  font-family: Agrandir-TextBold;
  font-weight: bold;
  text-align: center;
  ${map({ tablet: 'start' }, (align) => `text-align: ${align};`)};
`;

export const Faq = styled.div`
  display: flex;
  flex-direction: column;
  ${map({ tablet: 'row' }, (direction) => `flex-direction: ${direction};`)};
  align-items: center;
  ${map(
    { tablet: 'flex-start' },
    (alignItems) => `text-align: ${alignItems};`
  )};
  padding-bottom: 5.5rem;
  ${map(
    { tablet: '3rem' },
    (paddingBottom) => `padding-bottom: ${paddingBottom};`
  )};
  color: ${(props) => props.theme.colours.black70};
  font-size: 1.7rem;
  line-height: 2.7rem;
  text-align: center;
  ${map({ tablet: 'start' }, (textAlign) => `text-align: ${textAlign};`)};
  img {
    margin-bottom: 2rem;
    ${map(
      { tablet: '0' },
      (marginBottom) => `margin-bottom: ${marginBottom};`
    )};
    margin-right: 0rem;
    ${map(
      { tablet: '2rem' },
      (marginRight) => `margin-right: ${marginRight};`
    )};
  }
`;

export const Terms = styled.div`
  font-size: 1.3rem;
  padding: 3rem 0 0 0;
  color: ${(props) => props.theme.colours.black70};
  text-align: center;
  ${map({ tablet: 'start' }, (textAlign) => `text-align: ${textAlign};`)};

  a {
    margin-left: 0.5rem;
    color: ${(props) => props.theme.colours.black70};
  }
`;

export const LinkWrapper = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  span {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`;

export const ImportantHeader = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 700;
  margin-bottom: 1.35rem;
  color: ${Colors.black};
`;

export const ImportantText = styled.div`
  font-size: 1.7rem;
  line-height: 3.1rem;
  color: ${Colors.black70};
`;
export const StaticImportantText = styled.div`
  font-size: 1.7rem;
  line-height: 3.1rem;
  color: ${Colors.black};
  font-weight: 700;
  margin-top: 3rem;
`;

export const DividerWrapper = styled.div`
  margin: 5rem 0;
`;
