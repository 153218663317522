import * as React from 'react';
import Colors from '../../../const/Colors';

interface OwnProps {
  height?: number;
  width?: number;
  fill?: string;
}

const SvgHeart: React.FC<OwnProps> = (props) => {
  const { height = 32, width = 32, fill = Colors.poppyRed } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}>
      <path
        d="M13.2723 27.9689L15.021 29.6105C15.289 29.8612 15.6379 30 15.9997 30C16.3616 30 16.7105 29.8612 16.9785 29.6105L18.7967 27.9019C25.8875 21.3019 31.9995 15.612 31.9995 9.74518C32.0263 7.18873 31.0669 4.72598 29.3324 2.89857C27.5979 1.07117 25.2303 0.0287492 22.7503 0.000581062H22.5447C19.1276 0.000581062 17.3557 1.87332 15.9997 3.60579C14.6437 1.87332 12.8734 0.000581062 9.45481 0.000581062C6.97453 -0.0274719 4.58501 0.961249 2.8119 2.74925C1.03879 4.53725 0.0273245 6.97807 0 9.53479V9.74518C0 15.6343 6.15374 21.3657 13.2723 27.9689Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgHeart;
