import React, { createContext, useState, useContext } from 'react';

type ManageCardContextData = {
  phoneVerified: boolean;
  setPhoneVerified: React.Dispatch<React.SetStateAction<boolean>>;
  initialPin: string;
  setInitialPin: React.Dispatch<React.SetStateAction<string>>;
};

const ManageCardContext = createContext<ManageCardContextData>(
  {} as ManageCardContextData
);

export interface ISignInReidrectPage {
  parentScreen: string;
  params?: any;
  childScreen?: string;
}

const ManageCardProvider: React.FC = ({ children }) => {
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false);
  const [initialPin, setInitialPin] = useState<string>('');
  return (
    <ManageCardContext.Provider
      value={{ phoneVerified, setPhoneVerified, initialPin, setInitialPin }}>
      {children}
    </ManageCardContext.Provider>
  );
};

function useManageCard(): ManageCardContextData {
  const context = useContext(ManageCardContext);

  if (!context) {
    throw new Error('useManagePin must be used within an ManagePinProvider');
  }

  return context;
}

export { ManageCardContext, ManageCardProvider, useManageCard };
