import { To, useMatch, useResolvedPath } from 'react-router-dom';
import { Link } from '../../Link';

export const MenuLink = ({ ...props }) => {
  const toPath: To = props?.to || '';
  const resolved = useResolvedPath(toPath);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      {...props}
      noHighLightColor={props.noHighLightColor}
      className={props.className}
      to={toPath}
      active={!!match && !!toPath}>
      {props.children}
    </Link>
  );
};
