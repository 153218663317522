import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserGiftCard } from '..';
import SpendOnlineBackButton from '../../../assets/svg/my-cards/SpendOnlineBackButton.svg';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import CardDetailsItem from './CardDetailsItem';
import {
  SideDrawerContainer,
  SideDrawerHeader,
} from '../../../components/SideDrawer';
import axios from 'axios';
import { useApi } from '../../../api/ApiProvider';
import { UserGiftCardDetailsVM } from '../../../generated';

type Props = {
  userGiftCard?: UserGiftCard;
};

const Paragraph = styled.div`
  font-size: 1.7rem;
  line-height: 2.7rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
`;
const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colours.line1};
`;

const formatExpiryDate = (expiryDate: string) => {
  const date = moment(expiryDate, 'YYYYMM').format('MM/YY');
  return date;
};

const SpendOnline = ({ userGiftCard }: Props) => {
  const navigate = useNavigate();
  const [cvv, setCVV] = useState<string>('loading...');
  const [pan, setPan] = useState<string>('loading...');
  const { userGiftCardApi } = useApi();
  const [userGiftCardDetails, setUserGiftCardDetails] = useState<
    UserGiftCardDetailsVM | undefined
  >(undefined);

  const { expiry, privateTokenId, privateCallbackUrl } = useMemo(() => {
    if (!!userGiftCardDetails) {
      return {
        expiry: formatExpiryDate(userGiftCardDetails.cardDetails?.expiry ?? ''),
        privateCallbackUrl:
          userGiftCardDetails.cardDetails?.privateCallbackUrl ?? '',
        privateTokenId: userGiftCardDetails.cardDetails?.privateTokenId ?? '',
      };
    }
    return {
      expiry: 'loading...',
    };
  }, [userGiftCardDetails]);

  useEffect(() => {
    (async () => {
      const result = await userGiftCardApi.getUserGiftCard(
        userGiftCard?.id ?? -1
      );
      setUserGiftCardDetails(result.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGiftCard]);

  useEffect(() => {
    (async () => {
      if (!!privateCallbackUrl && !!privateTokenId) {
        const body = { tokenId: privateTokenId };
        const result: any = await axios.post(privateCallbackUrl, body);
        setCVV(result.data.cvv2);
        setPan(result.data.pan);
      }
    })();
  }, [privateCallbackUrl, privateTokenId]);

  return (
    <>
      <SideDrawerHeader>
        <img
          onClick={() => {
            navigate(-1);
          }}
          src={SpendOnlineBackButton}
          alt="Back"
        />
        <div className="header-title">Spend online</div>
      </SideDrawerHeader>
      <SideDrawerContainer>
        <Paragraph>
          Copy and paste the card details into the credit card section of the
          online checkout to pay with your gift card. Your order value must be
          equal or lower than the gift card’s balance.
        </Paragraph>

        <CardDetailsItem text={pan} desc="Card number" />
        <Divider />
        <CardDetailsItem text={expiry} desc="Expiry date" />
        <Divider />
        <CardDetailsItem text={cvv} desc="CVV" />
      </SideDrawerContainer>
    </>
  );
};

export default SpendOnline;
