import './timeline.scss';

const Timeline = () => {
  return (
    <div className="timeline-wrapper">
      <div>
        <p>Download the<br/> Special app from<br/> App Store</p>
      </div>
      <div>
        <p>Log in and go to<br/> “My Cards”</p>
      </div>
      <div>
        <p>
          Tap the “Add to Apple Wallet button” to add your card to Apple Wallet
        </p>
      </div>
      <div>
        <p>We may verify your identity via SMS/email as part of the setup</p>
      </div>
      <div>
        <p>
          If you have an Apple Watch, you will be prompted to set this up at the
          end
        </p>
      </div>
    </div>
  );
};
export default Timeline;
