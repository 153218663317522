import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DiscountTag from '../ShopCard/DiscountTag';
import RightThinArrowBack from '../../assets/svg/RightThinArrowBack.svg';
import {
  BrandWrapper,
  BrandDetailsWrapper,
  BrandName,
  PriceRange,
  TagWrapper,
  Image,
  BrandArrow,
} from './styles';
import { IBrandCardProps } from './types';

const BrandCard: React.FC<IBrandCardProps> = ({
  name,
  logoUrl,
  minimalValue,
  maximalValue,
  tag,
  id,
  displayAffiliationText = true,
  slug,
}) => {
  const navigate = useNavigate();
  const onBrandClick = () => {
    if (!slug) {
      return;
    }
    navigate(`/shop/${slug}`);
  };

  const brandName = useMemo(
    () => (displayAffiliationText ? `${name}*` : name),
    [displayAffiliationText, name]
  );
  return (
    <BrandWrapper onClick={onBrandClick}>
      <Image src={logoUrl} alt="" />
      <BrandDetailsWrapper>
        <div>
          <BrandName>{brandName}</BrandName>
          <PriceRange>
            ${minimalValue}-${maximalValue}
          </PriceRange>
          {tag && (
            <TagWrapper>
              <DiscountTag discountText={tag} />
            </TagWrapper>
          )}
        </div>
        <BrandArrow
          src={RightThinArrowBack}
          className="brand-arrow"
          alt="right arrow black"
        />
      </BrandDetailsWrapper>
    </BrandWrapper>
  );
};

export default BrandCard;
