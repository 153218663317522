import React from 'react';

import { ReactComponent as SingleStar } from '../../../../assets/svg/my-cards/SingleStar.svg';
import { NoRetailersWrapper, SubTitle, Title } from './styles';

interface OwnProps {}

const NoRetailer: React.FC<OwnProps> = () => {
  return (
    <NoRetailersWrapper>
      <SingleStar />
      <Title>Oops, no results found.</Title>
      <SubTitle>Try entering a different retailer.</SubTitle>
    </NoRetailersWrapper>
  );
};

export default NoRetailer;
