import styled from 'styled-components';
import Colors from '../../../../const/Colors';
import { map } from '../../../../const/theme';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
`;

export const InputWrapper = styled.div`
  margin-top: 1.6rem;
  ${map({ mobile: '2.8rem' }, (marginTop) => `margin-top: ${marginTop};`)}
  margin-bottom: 1.6rem;
  ${map(
    { mobile: '2.8rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )}
`;

export const FooterNote = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  color: ${Colors.black};
`;

export const CancelStyles = styled.div`
  margin-top: 2rem;
`;
