import React from 'react';
import QRCode from '../../assets/svg/QRCode.svg';
import { AppleUrl, GoogleUrl } from '../../util/StoreUrls';
import { Button } from '../Button';

import './styles.scss';

interface OwnProps {}

const DownloadApp: React.FC<OwnProps> = (props) => {
  return (
    <div className="downloadApp">
      <div className="qrCode">
        <img width={78} height={78} src={QRCode} alt="special QRCode" />
      </div>
      <div className="middleText">
        <span>Scan to download or</span>
      </div>
      <div className="appButton">
        <div className="appButtonWrapper">
          <a href={AppleUrl} target="_blank" rel="noreferrer">
            <Button
              role="App-Store"
              color="transparent"
              startIconClass="apple-store-black-icon"
            />
          </a>
          <a href={GoogleUrl} target="_blank" rel="noreferrer">
            <Button
              role="App-Store"
              color="transparent"
              startIconClass="google-play-black-icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
