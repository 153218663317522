import React, { useImperativeHandle, useState } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import Colors from '../../const/Colors';

interface IProps {
  width: string;
  children: JSX.Element;
}

interface IDropdownComponentProps {
  width: string;
  show: boolean;
}

export interface IDropdownHandle {
  toggleShow: () => void;
}

const DropdownContainer = styled.div<IDropdownComponentProps>`
  background-color: ${Colors.white};
  border-radius: 8px;
  position: absolute;
  top: calc(100% + 1rem);
  right: 0px;
  padding: 2.6rem 3rem;
  opacity: ${(props) => (props.show ? '1' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  z-index: 11;
  width: ${(props) => props.width};
  box-shadow: 2px 1rem 3rem rgba(0, 0, 0, 0.05);
  border: 1px solid ${Colors.black};
  transition: opacity 0.4s, visibility 0.4s;
`;

const BackDropContainer = styled.div`
  position: fixed;
  z-index: 1;
  height: 100vmax;
  width: 100vmax;
  display: block;
  left: 0;
  top: 0;
`;

const DropDown = forwardRef(
  (props: IProps, ref: React.Ref<IDropdownHandle>) => {
    useImperativeHandle(
      ref,
      () => {
        return {
          toggleShow: () => {
            setShow((pre) => !pre);
          },
        };
      },
      []
    );
    const [show, setShow] = useState<boolean>(false);

    return (
      <>
        {show && (
          <BackDropContainer
            onClick={() => {
              setShow(false);
            }}
          />
        )}

        <DropdownContainer width={props.width} show={show}>
          {props.children}
        </DropdownContainer>
      </>
    );
  }
);
export default DropDown;
