import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { ErrorText } from '../../../components/ErrorText';
import { Input } from '../../../components/Input';
import { EMAIL_REGEX, MOBILE_REGEX } from '../../../const/shared';
import { IOptInOutData, useOptInOut } from '../../../context/OptInOutProvider';
import OptOutWrapper from '../OptOutWrapper';

interface IProps {}

const OptOutInfo: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  const { optInOutData: optOutData, saveOptInOutData: saveOptOutData } =
    useOptInOut();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      businessName: optOutData?.businessName,
      name: optOutData?.name,
      jobTitle: optOutData?.jobTitle,
      email: optOutData?.email,
      phoneNumber: optOutData?.phoneNumber,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  return (
    <OptOutWrapper>
      <p className="header">Confirm your business information</p>
      <p className="desc">Please provide your business information.</p>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-out-input"
            label="Business name"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.businessName}
          />
        )}
        name="businessName"
      />
      {errors.businessName && (
        <ErrorText text="Business name is required."></ErrorText>
      )}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-out-input"
            label="Your name"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.name}
          />
        )}
        name="name"
      />
      {errors.name && <ErrorText text="Your name is required."></ErrorText>}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-out-input"
            label="Your job title"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.jobTitle}
          />
        )}
        name="jobTitle"
      />
      {errors.jobTitle && (
        <ErrorText text="Your job title is required."></ErrorText>
      )}
      <Controller
        control={control}
        rules={{
          required: true,
          pattern: EMAIL_REGEX,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-out-input"
            label="Email"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value?.toLowerCase()}
            inputMode="email"
            error={!!errors.email}
          />
        )}
        name="email"
      />
      {errors.email && (
        <ErrorText
          text={
            errors.email.type === 'pattern'
              ? 'Your email is invalid.'
              : 'Your email is required.'
          }></ErrorText>
      )}
      <Controller
        control={control}
        rules={{
          pattern: MOBILE_REGEX,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-out-input"
            label="Phone number (optional)"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.phoneNumber}
          />
        )}
        name="phoneNumber"
      />
      {errors.phoneNumber && (
        <ErrorText text="Your phone number is invalid"></ErrorText>
      )}
      <Button
        title="Next"
        disabled={!isValid}
        onClick={() => {
          handleSubmit((data) => {
            saveOptOutData(data as IOptInOutData);
            navigate('/opt-out/reason');
          })();
        }}
        role="Secondary"
        style={{ marginTop: '2.5rem' }}
      />
    </OptOutWrapper>
  );
};

export default OptOutInfo;
