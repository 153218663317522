export const COMMON_ERR_MSG =
  'Oops, something went wrong, please try again later.';

export const TEXT_REGEX = /^[^0-9]+$/g;
export const NO_LINK_TEXT_REGEX = /^[^.0-9\\/:]+$/g;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const NAME_REGEX = /^[a-zA-Z-']{1,30}$/;
export const MOBILE_REGEX = /^(\+?\d{1,3}[- ]?)?\d{10}$/;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const DefaultStepColors = [
  '#4200FF',
  '#FFA500',
  '#E9C0FF',
  '#FF4500',
  '#001F73',
  '#00CCA8',
];

export const accent = '#00cca8';
export const orange = '#ffa500';
export const red = '#ff4500';
export const electricBlue = '#4200ff';
export const black = '#000000';
export const _default = '#cbd0d6';
export const secondaryText = '#728291';

export const passwordComplexityBorderColor = {
  default: _default,
  error: red,
  fair: orange,
  good: accent,
  excellent: accent,
};

export const GIFT_CARD_WIDTH_HEIGHT_RATIO = 0.6;
export const NAME_CHAR_LIMIT = 30;
export const INVALID_PIN_ERROR_STRING = 'Invalid verification code';

export const PIN_LENGTH = 4;
export const DEFAULT_PIN_ERR_MSG = `Your PIN combination doesn’t meet our requirements - please try again.`;

export const DEFAULT_PIN_WARNING_MSG = `Your PIN must be hard to guess and can't be your date of birth or obvious combinations. Don't share your PIN or write it down, or you may be liable for unauthorised transactions.`;

export const UNMATCHED_PIN_MSG = `Oops, that PIN doesn't match. Try again.`;

export const ScrollerMessage =
  'no fees • awesome designs • special cards $5-$1000 • ';

export const SHOW_BRAND_LOGO_IN_ANIMATION = false;
export const SHOW_AFFILIATION_TEXT_IN_ANIMATION = false;
