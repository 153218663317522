import { UserGiftCardDetailsVM, UserGiftCardStatus } from '../../../generated';
import styled from 'styled-components';
import { GIFT_CARD_WIDTH_HEIGHT_RATIO } from '../../../const/shared';
import { useEffect, useMemo } from 'react';
import { useApi } from '../../../api/ApiProvider';
import Spinner from '../../../components/Spinner';
import { UserGiftCard } from '..';
import { IsMobileView } from '../../../util/helper';
import { getMoneyDisplay } from '../../../util/helpers';
import Scroller from '../../../components/Scroller';
import { map } from '../../../const/theme';
import InfoBubble from './InfoBubble';
import Colors from '../../../const/Colors';

type Props = {
  userGiftCard: UserGiftCard;
  setDetails: (details: UserGiftCardDetailsVM) => void;
  isActive: boolean;
  isSelected: boolean;
  onClick: () => void;
  width?: number;
};

type MyCardImgContainerProps = {
  width: number;
  height: number;
  isActive: boolean;
  isMobileView: boolean;
};

const MyCardContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MyCardImgContainer = styled.div<MyCardImgContainerProps>`
  position: relative;
  overflow: hidden;
  padding-top: 2rem;
  transition: width 0.5s;
  transition: all 0.2s ease-in-out;
  transform: scale(${(props) => (props.isActive ? 1.2 : 1)});
  position: relative;
  ${(props) =>
    !props.isMobileView &&
    ` 
  &:hover {
    cursor: pointer;
    transform: scale(${props.isActive ? 1.2 : 1})
      rotate(${props.isActive ? '2deg' : '0deg'})
      translate(0, ${props.isActive ? '0' : '-2rem'});

    > .manage-button {
      background: ${props.theme.colours.white};
      color: ${props.theme.colours.black};
    }

    &:before {
      left: ${props.width + 200}px;
    }
  }
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -200px;
    width: 120px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transition: all 0.5s ease-in-out;
    transform: skewX(-30deg);
    animation-timing-function: linear;

    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 10%,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.3) 30%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 60%,
      rgba(255, 255, 255, 0.3) 70%,
      rgba(255, 255, 255, 0.2) 80%,
      rgba(255, 255, 255, 0.1) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .scroller-container {
    height: 3rem;
    ${map({ tablet: '4.6rem' }, (height) => `height: ${height};`)};
    padding: 0rem;
    ${map({ tablet: '1rem' }, (padding) => `padding: ${padding};`)};
    margin: 0;
    position: absolute;
    top: calc(50% - 2rem);
    left: -2%;
    transform: rotate(-5deg);
    width: 103%;
    * {
      font-size: 1.6rem;
      ${map({ tablet: '2.8rem' }, (fontSize) => `font-size: ${fontSize};`)};
      line-height: 3rem;
      ${map(
        { tablet: 'unset' },
        (lineHeight) => `line-height: ${lineHeight};`
      )};
    }
  }

  > img {
    border-radius: 2rem;
    ${map(
      { tablet: '3.6rem' },
      (borderRadius) => `border-radius: ${borderRadius};`
    )};
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
    ${(props) =>
      props.isMobileView &&
      `
        height: auto; 
        width: min(65vw, 31.3rem);
        max-width: 33.3rem;
    `}
  }
  > .manage-button {
    position: absolute;
    right: 1.7rem;
    ${map({ tablet: '3rem' }, (right) => `right: ${right};`)};
    bottom: 1.5rem;
    ${map({ tablet: '2.5rem' }, (bottom) => `bottom: ${bottom};`)};
    z-index: 2;
    background: ${(props) => props.theme.colours.black};
    color: ${(props) => props.theme.colours.white};
    font-size: 1rem;
    ${map({ tablet: '1.1rem' }, (fontSize) => `font-size: ${fontSize};`)};
    font-weight: bold;
    padding: 0.5rem 1rem;
    ${map(
      { tablet: '0.8rem 1.2rem 0.6rem 1.2rem' },
      (padding) => `padding: ${padding};`
    )};
    border-radius: 8px;
  }

  @keyframes slide {
    0% {
      left: -100px;
      top: 0;
    }
    100% {
      left: 550px;
      top: 0;
    }
  }
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5.2rem;
  ${map(
    { tablet: '2rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
`;

const CardName = styled.div`
  margin-top: 4.8rem;
  ${map({ tablet: '4rem' }, (marginTop) => `margin-top: ${marginTop};`)};
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.8rem;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  color: ${Colors.black70};
  max-width: 40rem;
`;
const CardValue = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.8rem;
  text-align: center;
  margin-bottom: 4.3rem;
  ${map(
    { tablet: '1.3rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
  white-space: nowrap;
`;

const PanContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 1.7rem;
  ${map({ tablet: '3rem' }, (left) => `left: ${left};`)};
  bottom: 1.5rem;
  ${map({ tablet: '2.5rem' }, (bottom) => `bottom: ${bottom};`)};
`;

const getProperScroller = (
  isPastCard: boolean,
  cardStatus?: UserGiftCardStatus
) => {
  let scrollerText = '';
  if (isPastCard) {
    if (cardStatus === UserGiftCardStatus.Blocked) {
      scrollerText = 'SUSPENDED • SUSPENDED • ';
    } else if (cardStatus === UserGiftCardStatus.Cancelled) {
      scrollerText = 'CANCELLED • CANCELLED • ';
    } else {
      scrollerText = 'PAST CARDS • AWESOME DESIGNS • ';
    }
    return <Scroller content={scrollerText} />;
  }
};

const AffiliationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: ${Colors.black};
  text-align: center;
  margin-top: 1.2rem;
`;

const MyCardItem = ({
  isSelected,
  isActive,
  userGiftCard,
  setDetails,
  onClick,
  width = 450,
}: Props) => {
  const isPastCard = useMemo(() => {
    return (
      userGiftCard.status === UserGiftCardStatus.Past ||
      userGiftCard.status === UserGiftCardStatus.Blocked ||
      userGiftCard.status === UserGiftCardStatus.Cancelled
    );
  }, [userGiftCard]);
  const widthToUse = useMemo(() => {
    if (width < 300) {
      return 300;
    }
    return width;
  }, [width]);
  const api = useApi();

  useEffect(() => {
    if (!isActive && !isSelected) {
      return;
    }
    (async () => {
      const result = await api.userGiftCardApi.getUserGiftCard(
        userGiftCard.id || -1
      );
      setDetails(result.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isSelected, api.userGiftCardApi, userGiftCard.id]);
  const isMobileView = IsMobileView();

  const cardSubText = useMemo(() => {
    if (userGiftCard.status === UserGiftCardStatus.Cancelled) {
      return 'Card cancelled';
    } else if (userGiftCard.status === UserGiftCardStatus.Blocked) {
      return 'Card suspended';
    }
  }, [userGiftCard.status]);

  const { displayAffiliationText } = userGiftCard;
  const cardName = useMemo(() => {
    return displayAffiliationText
      ? `${userGiftCard.giftCardName}*`
      : `${userGiftCard.giftCardName}`;
  }, [displayAffiliationText, userGiftCard.giftCardName]);

  return (
    <MyCardContainer>
      <MyCardImgContainer
        onClick={onClick}
        isMobileView={isMobileView}
        isActive={isActive}
        width={widthToUse}
        height={widthToUse * GIFT_CARD_WIDTH_HEIGHT_RATIO}>
        <img
          src={userGiftCard.frontImage ?? ''}
          alt={userGiftCard.giftCardName ?? ''}
        />
        <div className="manage-button">MANAGE</div>
        {getProperScroller(isPastCard, userGiftCard.status)}
        {userGiftCard.details?.cardDetails?.panLast4 && (
          <PanContainer style={{ color: userGiftCard.lastFourColor ?? '#fff' }}>
            •••• {userGiftCard.details?.cardDetails?.panLast4}
          </PanContainer>
        )}
      </MyCardImgContainer>

      {isActive && (
        <CardDetailsContainer style={{ width: widthToUse }}>
          <CardName>{cardName}</CardName>
          {userGiftCard.status === UserGiftCardStatus.Cancelled ||
          userGiftCard.status === UserGiftCardStatus.Blocked ? (
            <div>
              <CardValue>{cardSubText}</CardValue>
              <InfoBubble
                style={{
                  marginBottom: 15,
                }}
              />
            </div>
          ) : !!userGiftCard.details ? (
            <CardValue>
              {getMoneyDisplay(userGiftCard.details.cardBalances)} remaining
            </CardValue>
          ) : (
            <Spinner size={45} />
          )}
          {displayAffiliationText && (
            <AffiliationText>
              *This brand is not affiliated with Special
            </AffiliationText>
          )}
        </CardDetailsContainer>
      )}
    </MyCardContainer>
  );
};

export default MyCardItem;
