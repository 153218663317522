import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SideDrawerHeader } from '../../../components/SideDrawer';
import ManageYourPinBackButton from '../../../assets/svg/my-cards/ManageYourPinBackButton.svg';

export const ManagePinParagraph = styled.div`
  font-size: 1.7rem;
  line-height: 2.7rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
`;
export const ManagePinParagraphHeader = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.4rem;
  margin-top: 2rem;
`;

export const ManagePinHeader = () => {
  const navigate = useNavigate();
  return (
    <SideDrawerHeader>
      <img
        onClick={() => {
          navigate(-1);
        }}
        src={ManageYourPinBackButton}
        alt="Back"
      />
      <div className="header-title">Manage your PIN</div>
    </SideDrawerHeader>
  );
};

export const TextDesc = styled.div`
  margin-top: 3rem;
`;
