const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export const DefaultStepColors = [
  '#4200FF',
  '#FFA500',
  '#E9C0FF',
  '#FF4500',
  '#001F73',
  '#00CCA8',
];

const Colors = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  border: '#CBD0D6',
  disabled: '#CBD0D6',
  electricBlue: '#4200FF',
  black: '#000000',
  black70: '#4D4D4D',
  white: '#ffffff',
  applicationBackground: '#fff',
  danger: '#FF4500',
  danger20: '#fed9cc',
  poppyRed: '#FF4500',
  orangeSun: '#FFA500',
  softLillac: '#E9C0FF',
  seaGreen: '#00CCA8',
  line1: '#728291',
  line2: '#CBD0D6',
  grey1: '#D3DCE1',
  lightGrey: '#F8F7FA',
  grey25: '#F6F5FA',
  starOrange: '#F7A600',
  red: '#ff4500',
  orange: '#ffa500',
  lilac: '#e9c0ff',
  socialBorderColor: '#DAD6E7',
  counterColor: '#778899',
  midnightBlue: '#001F73',
  bone: '#FFFAF0',
};

export default Colors;
