import Banner from '../Banner';
import CardExample from '../../assets/svg/CardExample.svg';
import './footer.scss';
import { Link } from 'react-router-dom';
import { Logo } from '../Logo';
import { useMemo } from 'react';
import { Button } from '../Button';
import FooterSocialLinks from './FooterSocialLinks';
import { AppleUrl, GoogleUrl } from '../../util/StoreUrls';
import { CAREERS_LINK } from '../../const/links';

interface FooterProps {
  theme?: 'electric-blue' | 'white' | 'sea-green' | 'soft-lilac' | 'black';
  hideCtaBanner?: boolean;
}

const Footer = ({ theme, hideCtaBanner = false }: FooterProps) => {
  const logoColor = useMemo(() => {
    switch (theme) {
      case 'electric-blue':
        return 'white';
      case 'white':
        return 'fullColorBlack';
      case 'sea-green':
      case 'soft-lilac':
        return 'black';
      case 'black':
        return 'fullColorWhite';
      default:
        return 'white';
    }
  }, [theme]);

  const appColor = useMemo(() => {
    switch (theme) {
      case 'electric-blue':
        return 'white';
      case 'white':
      case 'sea-green':
      case 'soft-lilac':
        return 'black';
      default:
        return 'white';
    }
  }, [theme]);

  function isLight() {
    return theme === 'electric-blue' || theme === 'black';
  }

  return (
    <>
      {hideCtaBanner ? (
        <></>
      ) : (
        <Banner>
          <div className="cta-container">
            <img src={CardExample} alt="Special Example Card" />
            <div>
              <h2 className="footer-heading">Find the perfect Special Card.</h2>
              <p>
                Give someone you care about a gift from our huge range of
                Special Cards – so they can see just how Special they are.
              </p>
            </div>
          </div>
        </Banner>
      )}
      <div
        className={`footer-container ${theme} ${
          hideCtaBanner ? 'ridged' : ''
        }`}>
        <div className="footer-inner">
          <div className="main">
            <div className="logo-section">
              <Link to="/">
                <Logo logoClass={logoColor + ' header'}></Logo>
              </Link>
              <FooterSocialLinks isMobile={true} isLight={!isLight()} />
            </div>
            <div className="main-links">
              <div className="main-link-section">
                <p className="section-header">special</p>
                <Link to="/our-mission">Our mission</Link>
                <Link to="/how-it-works">How it works</Link>
                <a href={CAREERS_LINK} target="_blank" rel="noreferrer">
                  Careers
                </a>
                <Link to="/apple-pay">Apple Pay</Link>
              </div>
              <div className="main-link-section">
                <p className="section-header">support</p>
                <a
                  href="https://special.freshdesk.com/support/home"
                  target="_blank"
                  rel="noreferrer">
                  FAQs
                </a>
                <Link to="/contact-us">Contact us</Link>
              </div>
              <div className="main-link-section">
                <p className="section-header">retailers</p>
                <Link to="/claim-my-business">Claim your business</Link>
                <Link to="/opt-out">Opt out</Link>
              </div>
            </div>
            <div className="app-links">
              <p className="section-header">Get the app</p>
              <a href={AppleUrl} target="_blank" rel="noreferrer">
                <Button
                  role="App-Store"
                  color="transparent"
                  startIconClass={`apple-store-${appColor}-icon link`}
                />
              </a>
              <a href={GoogleUrl} target="_blank" rel="noreferrer">
                <Button
                  role="App-Store"
                  color="transparent"
                  startIconClass={`google-play-${appColor}-icon link`}
                />
              </a>
            </div>
          </div>
          <div className="base">
            <div className="social-legal">
              <FooterSocialLinks isLight={isLight()} />
              <div className="legal-links">
                <Link className="link" to="/privacy-policy">
                  Privacy
                </Link>
                <Link className="link" to="/terms-conditions">
                  Terms
                </Link>
              </div>
            </div>
            <p className="legal-notice">
              © SPECIAL.COM.AU PTY LTD ABN 99 652 350 715 (Special). Issued by
              545490 Pty. Ltd., ABN 83 648 605 225 trading as Karta Co (Karta)
              and distributed by Special. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
