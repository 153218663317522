import React, { useMemo } from 'react';
import styled from 'styled-components';
import Colors from '../../const/Colors';

type Props = {
  header: string;
  color: 'red' | 'orange' | 'lilac' | 'seaGreen';
  description?: string;
  onClick?: () => void;
};

type ColorIndicatorProp = {
  color: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const InnerContainer = styled.div`
  cursor: pointer;
`;

const Header = styled.div`
  font-family: Agrandir-TextBold;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 180%;
`;
const ColorIndicator = styled.div<ColorIndicatorProp>`
  height: 2rem;
  width: 2rem;
  border: 0.4rem solid;
  border-radius: 50%;
  margin-right: 1.8rem;
  border-color: ${(props) => props.color};
`;
const Description = styled.div`
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 140%;
  color: ${(props) => props.theme.colours.black70};
`;

const SectionItem = ({ header, color, description, onClick }: Props) => {
  const colorToUse = useMemo(() => {
    return Colors[color];
  }, [color]);
  return (
    <Container>
      <ColorIndicator color={colorToUse} />
      <InnerContainer onClick={onClick}>
        <Header>{header}</Header>
        {description && <Description>{description}</Description>}
      </InnerContainer>
    </Container>
  );
};

export default SectionItem;
