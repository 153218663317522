import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import './money-input.scss';
import { NUMBER_REGEX } from '../../shared/const';

interface MoneyInputProps extends React.InputHTMLAttributes<any> {
  label?: string;
  placeholder?: string;
  minValue?: number;
  maxValue?: number;
  errorText?: string;
}

const MoneyInput = ({
  label,
  placeholder,
  minValue = 5,
  maxValue = 1000,
  onChange,
  value,
  errorText,
  ...props
}: MoneyInputProps) => {
  const parsedInputValue = useMemo(() => {
    return parseInt(value?.toString().replace('$ ', '') || '');
  }, [value]);
  const [inputValue, setInputValue] = useState<number>(parsedInputValue || 0);
  const [labelValue, setLabelValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const errorClass = error ? 'error' : '';
  const inputRef = useRef<null | HTMLInputElement>(null);

  const validInput = useCallback(
    () => !inputValue || (inputValue >= minValue && inputValue <= maxValue),
    [inputValue, minValue, maxValue]
  );

  const toNumber = (value: string) => {
    return parseFloat(parseFloat(value).toFixed(0));
  };

  function validateIsNumber(event: any) {
    let key = event.keyCode || event.which;
    key = String.fromCharCode(key);

    if (!NUMBER_REGEX.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  }

  useEffect(() => {
    setError(!validInput() || !!errorText);
  }, [inputValue, validInput, errorText]);

  const handleOnFocus = () => label && setLabelValue(label);

  useEffect(() => {
    if (inputRef) {
      inputRef.current?.addEventListener('keyup', (keyboardEvent) => {
        const key = keyboardEvent.key;
        if (key === 'Enter') {
          inputRef.current?.blur();
        }
      });
    }
  });

  return (
    <label className="special-money-input">
      <input
        {...props}
        className={`field ${errorClass}`}
        type="number"
        min={minValue}
        max={maxValue}
        step={1}
        placeholder={placeholder}
        value={!!inputValue ? inputValue : ''}
        onChange={(e) => {
          onChange && onChange(e);
          setInputValue(toNumber(e.target.value));
        }}
        onKeyPress={(e) => validateIsNumber(e)}
        onFocus={() => handleOnFocus()}
        pattern="^\d*?$"
        inputMode="numeric"
        enterKeyHint="done"
        ref={inputRef}
      />
      <span className={`label ${errorClass}`}>{labelValue}</span>
      <label className={`dollar-sign ${errorClass}`}>$</label>
    </label>
  );
};

export default MoneyInput;
