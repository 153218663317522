import styled from 'styled-components';
import Colors from '../../../../const/Colors';
import { map } from '../../../../const/theme';

export const HeaderText = styled.div`
  padding-bottom: 2rem;
  font-size: 2.9rem;
  text-align: center;
  line-height: 4.1rem;
  font-weight: bold;
  padding-top: 2rem;
  ${map({ tablet: '0rem' }, (paddingTop) => `padding-top: ${paddingTop};`)};
`;

export const OrderText = styled.div`
  color: ${Colors.line1};
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-align: center;
  margin-bottom: 2rem;
  ${map(
    { tablet: '1.5rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
  font-weight: bold;
`;

export const Text = styled.div`
  font-size: 2rem;
  text-align: center;
  line-height: 2.8rem;
  margin-bottom: 1.5rem;
  ${map(
    { tablet: '2.5rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
`;

export const CardDetailsContainer = styled.div`
  border: 0.1rem solid ${Colors.line2};
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  margin-bottom: 3rem;
`;

export const GiftDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 0.1rem solid ${Colors.line2};
`;

export const GiftCardDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  ${map({ tablet: 'center' }, (alignItems) => `align-items: ${alignItems};`)};
  ${map({ tablet: 'row' }, (direction) => `flex-direction: ${direction};`)};
`;

export const CardDetailTitle = styled.div`
  font-size: 1.3rem;
  color: ${Colors.black};
  letter-spacing: 0.1rem;
  line-height: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${map({ tablet: '0' }, (marginBottom) => `margin-bottom: ${marginBottom};`)};
  width: 100%;
  ${map({ tablet: '15.7rem' }, (width) => `width: ${width};`)};
  ${map({ tablet: '15.7rem' }, (minWidth) => `min-width: ${minWidth};`)};
`;

export const CardDetailText = styled.div`
  font-size: 2rem;
  color: ${Colors.black};
  line-height: 2.8rem;
  word-break: break-word;
  letter-spacing: 0.1rem;
  &.promo-code {
    margin-left: 3.2rem;
    ${map({ tablet: '0' }, (x) => `margin-left: ${x};`)};
  }
`;

export const StoreCard = styled.div`
  img {
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    height: 4rem;
    width: 6.4rem;
    border-radius: 0.4rem;
  }
`;

export const BrandCard = styled.div`
  img {
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    height: 4rem;
    width: 4rem;
    border-radius: 0.4rem;
  }
`;

export const CardDetail = styled.div`
  padding: 2rem;
  border-bottom: 0.1rem solid ${Colors.line2};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${map({ tablet: 'center' }, (alignItems) => `align-items: ${alignItems};`)};
  justify-content: space-between;
  ${map({ tablet: 'row' }, (direction) => `flex-direction: ${direction};`)};
  ${map({ tablet: 'flex-start' }, (justify) => `justify-content: ${justify};`)};
`;

export const SingleSection = styled.div`
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  color: ${Colors.black};
  font-weight: bold;
  &.discount-section {
    .promo-code {
      margin-left: 5rem;
    }
  }
`;

export const SingleValue = styled.div`
  font-size: 1.7rem;
  color: ${Colors.black};
  font-weight: bold;
  &.red-variant {
    color: ${Colors.red};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2.4rem;
  ${map({ tablet: 'row' }, (direction) => `flex-direction: ${direction};`)};

  .special-button.secondary {
    padding: 0;
  }
  button {
    width: 100%;
    margin-bottom: 1.5rem;
    ${map({ tablet: '48%' }, (width) => `width: ${width};`)};
  }

  > :first-child {
    ${map({ tablet: 2 }, (order) => `order: ${order};`)};
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.2rem;
  ${map(
    { tablet: '1.7rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
`;

export const ButtomSectionWrapper = styled.div`
  margin-bottom: 4rem;
  padding-bottom: 5.5rem;
`;
