import { createContext, useContext, useState } from 'react';
import { NewDeletionRequest } from '../generated';

type AccountDeletionContextData = {
  deletionRequestReason?: NewDeletionRequest;
  setDeletionRequestReason: (deletionRequest?: NewDeletionRequest) => void;
  showDeleteAccountModal: boolean;
  setShowDeleteAccountModal: (value: boolean) => void;
};
const AccountDeletionContext = createContext<AccountDeletionContextData>(
  {} as AccountDeletionContextData
);

const AccountDeletionProvider = ({ children }: any) => {
  const [deletionRequestReason, setDeletionRequestReason] = useState<
    NewDeletionRequest | undefined
  >({} as NewDeletionRequest);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  return (
    <AccountDeletionContext.Provider
      value={{
        deletionRequestReason,
        setDeletionRequestReason,
        showDeleteAccountModal,
        setShowDeleteAccountModal,
      }}>
      {children}
    </AccountDeletionContext.Provider>
  );
};

function useAccountDeletion(): AccountDeletionContextData {
  const context = useContext(AccountDeletionContext);

  if (!context) {
    throw new Error(
      'useAccountDeletion must be used within an AccountDeletionProvider'
    );
  }

  return context;
}

export { AccountDeletionContext, AccountDeletionProvider, useAccountDeletion };
