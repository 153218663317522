import React, { useRef } from 'react';
import { useAuth } from '../../../context/AuthProvider';
import Colors from '../../../const/Colors';
import User from '../../../assets/svg/header/User.svg';
import ChevronDown from '../../../assets/svg/header/ChevronDown.svg';
import ChevronDownBlack from '../../../assets/svg/header/ChevronDownBlack.svg';
import DropDown, { IDropdownHandle } from '../../DropDown';
import styled from 'styled-components';
import { Link } from '../../Link';
import WelcomeBack from '../../../assets/svg/header/WelcomeBack.svg';
import { useNavigate } from 'react-router-dom';
import { IsMobileView } from '../../../util/helper';
import { useMemo } from 'react';
import { map } from '../../../const/theme';

type Props = {
  theme: 'black' | 'white';
};

type ButtonProps = {
  bgColor: string;
  textColor: string;
  children: React.ReactNode;
};

const Button = styled.button<ButtonProps>`
  background-color: ${(props) => props.bgColor};
  border-radius: 8px;
  padding: 1rem 2rem 1rem 2.5rem;
  border: 1px solid ${Colors.black};
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-color: ${Colors.seaGreen};
  }

  span {
    margin-top: 0.3rem;
    color: ${(props) => props.textColor};
    letter-spacing: 0.04em;
    font-weight: bold;
    padding: 0 1rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 1.95rem;
    max-width: 10rem;
    ${map({ tablet: '15rem' }, (maxWidth) => `max-width: ${maxWidth};`)}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: ${Colors.seaGreen};
    }
  }

  img.chevron-down-icon {
    margin-top: 0.3rem;
  }
`;

const UserMenuContainer = styled.div`
  position: relative;
`;

const UserMenuDropdownContainer = styled.div`
  .header {
    margin-bottom: 0.5rem;
  }
  .email {
    color: ${Colors.line1};
    margin-bottom: 1.5rem;
    word-break: break-all;
  }
  .special-link {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${Colors.line1};
    width: 100%;
    margin: 1rem 0;
    font-size: 12.5px;

    > span {
      display: block;
      width: 100%;
    }
  }
`;

const SignOutButton = styled.div`
  background-color: ${Colors.black};
  border-radius: 8px;
  padding: 0.7rem 1.2rem 0.4rem 1.2rem;
  border: 2px solid ${Colors.black};
  display: inline-flex;
  align-items: center;
  color: ${Colors.white};
  letter-spacing: 0.15rem;
  font-size: 1.3rem;
  margin-top: 2rem;

  &:hover {
    cursor: pointer;
    border-color: ${Colors.seaGreen};
    color: ${Colors.seaGreen};
    border-color: ${Colors.seaGreen};
  }
`;

const UserMenu = ({ theme }: Props) => {
  const auth = useAuth();
  const dropdownRef = useRef<IDropdownHandle>(null);
  const navigate = useNavigate();
  const isMobileView = IsMobileView();
  const { bgColor, textColor, chevron } = useMemo(() => {
    if (theme === 'white') {
      return {
        bgColor: Colors.white,
        textColor: Colors.black,
        chevron: ChevronDownBlack,
      };
    }
    return {
      bgColor: Colors.black,
      textColor: Colors.white,
      chevron: ChevronDown,
    };
  }, [theme]);

  if (!auth.isLoggedIn || !auth.userDetailsVM) {
    return (
      <Link to="/auth">
        <Button bgColor={bgColor} textColor={textColor}>
          <span>LOG IN</span>
        </Button>
      </Link>
    );
  }
  return (
    <UserMenuContainer>
      <Button
        bgColor={bgColor}
        textColor={textColor}
        onClick={() => {
          dropdownRef.current?.toggleShow();
        }}>
        <img alt="user" src={User}></img>
        <span>{auth.userDetailsVM.firstName}</span>
        {!isMobileView && (
          <img className="chevron-down-icon" alt="user" src={chevron}></img>
        )}
      </Button>
      <DropDown
        width={isMobileView ? 'calc(100vw - 4rem)' : '300px'}
        ref={dropdownRef}>
        <UserMenuDropdownContainer>
          <div className="header">
            <img src={WelcomeBack} alt="Welcome back" />
          </div>
          <div className="email">{auth.userDetailsVM.email}</div>
          <Link to="/my-cards">MY GIFT CARDS</Link>
          <Link to="/my-orders">ORDERS</Link>
          <Link to="/settings">ACCOUNT DETAILS</Link>
          <SignOutButton
            onClick={async () => {
              navigate('/');
              await auth.signOut();
            }}>
            SIGN OUT
          </SignOutButton>
        </UserMenuDropdownContainer>
      </DropDown>
    </UserMenuContainer>
  );
};

export default UserMenu;
