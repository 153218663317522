import './privacyPolicy.scss';

const PrivacyPolicy = () => (
  <div className="privacy-wrapper">
    <p>
      <b>Special Privacy Policy</b>
    </p>
    <p>
      <b>Dated 6 March 2022</b>
    </p>
    <p>
      <b>Introduction</b>
    </p>
    <p>
      SPECIAL.COM.AU PTY LTD, ABN 99 652 350 715 (Special, us, we, our), and all
      its related companies, are committed to protecting your privacy. We
      maintain robust physical, electronic, and procedural safeguards to protect
      personal information. This Privacy Policy applies to the Special website,
      our mobile applications and products and services, and governs data
      collection and usage. We adopted this Privacy Policy (“Policy”) to manage
      personal information in an open and transparent manner for use on the
      website and our mobile applications in relation to any products and our
      services (“Products and Services”).
    </p>
    <p>
      ‘Special Card’ and ‘Card’ means the Special Physical Card & Pays-enabled
      Card issued by 545490 Pty Ltd, ABN 83 648 605 225 trading as Karta Co
      (‘Karta’) and distributed by Special and applies to our Products and
      Services.
    </p>
    <p>
      We are bound by the Privacy Act 1988 (Cth) (‘Privacy Act’) and will
      protect your personal information we collect from you, or that you provide
      to us, in accordance with the Australian Privacy Principles. These
      principles govern how we collect, use, store and disclose your personal
      information, as well as how we ensure the quality and security of your
      personal information.
    </p>
    <b>What is personal information?</b>
    <p>
      Personal information includes any information or opinion, about an
      identified individual or an individual who can be reasonably identified
      from their information.
    </p>
    <p>
      Personal Information may include the following:
      <ol type="a">
        <li>name</li>
        <li>address</li>
        <li>telephone number</li>
        <li>email address</li>
        <li>date of birth</li>
        <li>gender</li>
        <li>marital status</li>
        <li>occupation</li>
        <li>bank account details</li>
        <li>contact details</li>
        <li>
          any other information we consider necessary to carry out our functions
          and activities.
        </li>
      </ol>
      These principles govern how we collect, use, store and disclose your
      personal information, as well as how we ensure the quality and security of
      your personal information.
    </p>
    <b>How do we collect personal information?</b>
    <p>
      We are a program manager of non-reloadable prepaid card Products and
      Services in Australia, and our main functions and activities we perform as
      a program manager include but is not limited to:
      <ul>
        <li>Card issuing, payment clearing and settlement;</li>
        <li>Platform Hosting and transaction processing;</li>
        <li>Application support and development;</li>
        <li>Program maintenance and reporting;</li>
        <li>Account management services;</li>
        <li>Cardholder and card program customer service; and</li>
        <li>Fraud and transaction monitoring.</li>
      </ul>
    </p>
    <p>
      We have direct contact with the individual cardholder, and we may need to
      collect personal information about you from other people or organisations.
      This may happen without your direct involvement. For instance, we may
      collect personal information about you from other organisations, who
      jointly with us, provide Products or Services to you.
    </p>
    <p>
      The circumstances in which we will collect personal information about you,
      as the cardholder, includes when:
      <ul>
        <li>you contact us; or</li>
        <li>
          you create an account or activate a Card, as defined in our Terms
        </li>
        <li>and Conditions; or</li>
        <li>you use a Card for transactions and balance enquiries.</li>
      </ul>
    </p>
    <b>What personal information do we collect?</b>
    <p>
      We collect and/or record the following types of personal information:
      <ul>
        <li>
          The personal information you have provided us through our online Card
          activation process including:
        </li>
        <li>e-mail address;</li>
        <li>name;</li>
        <li>telephone number.</li>
      </ul>
    </p>
    <p>
      There is also information about your computer hardware and software that
      is automatically collected by us. This information can include:
      <ul>
        <li>your IP address;</li>
        <li>browser type;</li>
        <li>domain names;</li>
        <li>device details;</li>
        <li>access times; and</li>
        <li>
          information we receive from third parties including card program
          sponsors, credit bureaus and information services and aggregation
          businesses, regarding verification of identification details
        </li>
      </ul>
    </p>
    <p>
      Throughout the life of your Product or Services, we may collect and hold
      additional personal information about you. This could include transaction
      information or making a record of queries or complaints you make.
    </p>
    <b>
      For what purposes do we collect, store, use, and disclose personal
      information?
    </b>
    <p>
      The main reason we collect, use, store and disclose personal information
      is to provide you with Products and Services.
    </p>
    <p>
      This includes:
      <ul>
        <li>checking whether you are eligible for the Product or Services;</li>
        <li>for direct marketing purposes;</li>
        <li>providing the Product or Services; and</li>
        <li>assisting you withyour inquiries or concerns.</li>
      </ul>
    </p>
    <p>
      We may also collect, use, and exchange your information so that we can:
      <ul>
        <li>establish your identity;</li>
        <li>
          manage our risks and help identify and investigate illegalactivity,
          such as fraud;
        </li>
        <li>contact you;</li>
        <li>
          comply with our legal obligations and assist government and law
          enforcement agencies or regulators;
        </li>
        <li>conduct research and training; or</li>
        <li>provide general statistics regarding use of our website.</li>
      </ul>
    </p>
    <p>
      We encourage you to review the privacy statements of websites you choose
      to link to, and from us so that you can understand how those websites
      collect, use and share your information. We are not responsible for the
      privacy statements or other content on websites outside of our website.
    </p>
    <p>
      We only retain personal data for so long as it is necessary in accordance
      with the time frames stipulated in the laws that impact us, such as
      privacy, AML/CTF and tax laws. For example, we may require your personal
      information to be collected and verified under the Anti-Money Laundering &
      Counter-Terrorism Financing Act 2006 (Cth).
    </p>
    <b>Sharing your information with related entities and third parties</b>
    <p>
      We may share your personal information within Special and its related
      companies (“the Group”). This helps us provide you with information about
      other Products and Services within the Group, verify your personal
      information and offer a streamlined customer experience across our Group
      and entities. This depends on the Product or Services you have with us,
      and the related corporate entity you're dealing with. From time to time,
      we also need to share your information with organisations outside of our
      corporate entities which are third parties and those that help us provide
      the Product or Services you have with us. For your security, we always
      take measures to ensure our service providers take appropriate steps to
      protect that information, and restrict the way they can use it. For
      instance, to protect your personal information, we select providers that
      we expect to comply with applicable Privacy Laws and to only use the
      personal information we disclose to them for the specific role we ask them
      to perform. We also have agreements in place which set out the terms we
      expect our service providers and related entities to comply with.
    </p>
    <b>Is the information disclosed to third parties?</b>
    <p>
      We may disclose your personal information to third parties:
      <ul>
        <li>
          who are service providers, contractors, or card program sponsors of
          ours;
        </li>
        <li>
          to facilitate the operation of the Card and the completion and
          settlement of transactions using the Card;{' '}
        </li>
        <li>
          for anti-money laundering and counter-terrorism financing
          requirements, the detection of crime, legislative and compliance
          regulations, and fraud prevention purposes; and
        </li>
        <li>when required or allowed by law.</li>
      </ul>
    </p>
    <p>
      When your personal information is shared with service providers or
      contractors, it will only be to the extent reasonably necessary for the
      purpose of the services they are contracted to provide. When your personal
      information is shared with program sponsors, it will only be to the extent
      reasonably necessary for the purpose of performing any necessary
      cardholder customer support, conducting statistical analysis, improving
      their product, services, and practices.
    </p>
    <p>
      We may also disclose Personal Information to other third parties in
      circumstances where:
      <ul>
        <li>
          We must fulfil our legal obligations (forexample, disclosure to
          Australian (and international) enforcement bodies such as the
          Australian Securities and Investments Commission (ASIC),the Australian
          Taxation Office (ATO), the Australian Transaction Reports and Analysis
          Centre (AUSTRAC) or the Courts);{' '}
        </li>
        <li>
          It is in the public interest (that is, to protect our interests or
          where we have a duty to the public to disclose, or where it is
          necessary in proceedings before a court or tribunal) and where a crime
          or fraud is committed or is suspected; or <br />
        </li>
        <li>
          It can be reasonably inferred from the circumstances that the
          Cardholder has consented to their Personal Information being disclosed
          to a third party.
        </li>
      </ul>
      We do not use or disclose the Personal Information for any other purpose
      unless one of the following applies:
      <ul>
        <li>The individual has consented;</li>
        <li>
          The individual would reasonably expect us to use or disclose the
          Personal Information for a purpose that is related to the primary
          purpose;
        </li>
        <li>For insurance purposes we may be required to have;</li>
        <li>external dispute resolution schemes;</li>
        <li>
          fraud bureaus or other organisations to identify, investigate or
          prevent fraud or other misconduct; or{' '}
        </li>
        <li>use or disclosure is required under Australian law.</li>
      </ul>
    </p>
    <p>
      As a matter of course, we do not disclose personal information to overseas
      recipients. If this position changes, we will only send your personal
      information outside Australia, where, for example:
      <ul>
        <li>
          You have requested or consented to us sending your Personal
          Information;{' '}
        </li>
        <li>
          We outsource a function or service to an overseas contractor with whom
          we have a contractual relationship; and{' '}
        </li>
        <li>
          We will not send your personal information outside Australia unless it
          is authorised by law, and we are satisfied that the recipient of the
          Personal Information has adequate data protection arrangements in
          place.
        </li>
      </ul>
    </p>
    <p>
      Where we disclose Personal Information about an individual to a recipient
      who is not in Australia, we must ensure that the overseas recipient does
      not breach the Australian Privacy Principles. We will continue to keep
      your personal information as is reasonably necessary, for the purposes
      mentioned above, after the expiry of the Card.
    </p>
    <p>
      We may collect, use, or disclose sensitive personal information, such as
      race, religion, or political affiliations. Sensitive information is a
      sub-set of personal information and is given a higher level of protection
      under the privacy principles. Unless required by law, we will only collect
      sensitive information with your consent. Sensitive information is
      information about a person’s racial or ethnic origin, political opinions,
      membership of a political association, religious beliefs or affiliations,
      philosophical beliefs, membership of a professional or trade association
      or trade union, sexual preferences or practices, criminal record, health
      information, genetic or biometric information.
    </p>
    <p>
      When we disclose or store information overseas, we take reasonable steps
      to ensure that your information is provided with the same level of
      protection as it is within Australia. We do this by only engaging with
      third parties located in a country which we believe has similar privacy
      laws to Australia, or by ensuring the third party can provide the same
      level of protection consistent with our Privacy Laws. We also have
      agreements in place which set out the terms we expect them to comply with,
      which include compliance with privacy and other Australian laws. Before,
      and during the agreement, we may ask for information to satisfy ourselves
      that they can and are complying with the terms of the agreement.
    </p>
    <b>Your consent is important </b>
    <p>
      We may require your consent to use and/or disclose your information in
      particular ways. We need your consent if we use your information for a
      purpose that is not related to the purpose for which we collected your
      information in the first instance. Depending on the circumstances, this
      consent may be express (for example, you expressly agree to the specific
      use of your information by ticking a box) or implied by some action you
      take or do not take (for example your agreement is implied by the fact
      that you have agreed to your product terms and conditions which contains
      information about the use of disclosure).
    </p>
    <b>Do we collect personal information electronically?</b>
    <p>
      Each time you visit our website, we collect information about your use of
      the website, which may include the following:
      <ul>
        <li>The date and time of visits;</li>
        <li>Which pages are viewed; </li>
        <li>How users navigate through the site and interact with pages;</li>
        <li>Information about the device used to visit our website; and</li>
        <li>IP addresses. </li>
      </ul>
      This information is obtained using tools such as Google Analytics.
    </p>
    <b>Security of your Personal Information </b>
    <p>
      We protect your personal information from unauthorised access, misuse, and
      disclosure. With regard to personal information security, our focus is to
      ensure we are taking reasonable steps to protect personal information from
      misuse, interference, and loss, as well as unauthorised access,
      modification, or disclosure. Our security safeguards include taking
      reasonable steps to destroy or de-identify personal information it holds
      where:
      <ol>
        <li>
          we no longer need the personal information for any purpose for which
          the information may be used or disclosed by us; and{' '}
        </li>
        <li>the information is not contained in a Commonwealth record; and</li>
        <li>
          we not required to retain that information under an Australian law, or
          a Court/Tribunal Order.
        </li>
      </ol>
    </p>
    <b>System security</b>
    <p>
      When you transact with us, we encrypt data sent from your computer to our
      systems and payment providers. Where appropriate, we have firewalls,
      password protection, intrusion detection systems and virus scanning tools
      to protect against unauthorised persons and viruses accessing our systems.
      When personal information is transmitted to other websites, it is
      protected by encryption, using industry standard security measures to
      safeguard and protect your information. We also limit access by requiring
      the use of passwords and access control. We do not store credit card
      numbers in our systems.
    </p>
    <b>Destroying data when no longer required</b>
    <p>
      Where practical, we keep information only for as long as required (for
      example, to meet legal requirements or our internal needs) and take
      reasonable steps to destroy or de-identify personal information. This is
      the same case where we receive unsolicited personal information that we do
      not need to deliver Products and Services to you (ie. In a correspondence
      you have sent to us), we will, where reasonable to do so, destroy and
      de-identify this information. Where information is retained, it will be
      subject to this Policy.
    </p>
    <b>Payment Card Industry (PCI) Data Security Standard (DSS)</b>
    <p>
      The PCI Data Security Standard represents a common set of industry tools
      and measurements to help ensure the safe handling of sensitive
      information. The standard provides an actionable framework for developing
      a robust account data security process – including preventing, detecting,
      and reacting to security incidents.
    </p>
    <b>Training and education</b>
    <p>
      We train and provide refreshers to our staff of their privacy obligations
      regarding your personal information. We specifically cover how we can
      collect, use, store and disclose personal information, and how we can
      handle personal information in day-to-day work in away that complies with
      the Privacy Act and the Australian Privacy Principles. It also covers
      agency obligations around storing personal information in a secure manner,
      the retention of personal information and destroying and/or de-identifying
      personal information. This allows us to educate our staff on identifying
      safe personal information handling practices.
    </p>
    <b>Protecting your privacy</b>
    <p>
      You can help us to protect your privacy by observing our security
      requirements and contacting us immediately if your contact details change.
      We require you to keep your personal identification number (PIN),
      passwords and access codes confidential and secure at all times. This
      means that you should not disclose you PIN, passwords, or access codes to
      any other person. You should also contact us immediately if you believe
      that your PIN, passwords, or access codes may have been compromised or if
      you would like to change your PIN or password.
    </p>
    <b>Our mobile application (“App”)</b>
    <p>
      Our mobile application is covered by this Policy for us to stay compliant
      with the Privacy Act and international privacy laws, as well as App Store
      guidelines. These terms and conditions govern your use of the App and the
      products, features, apps, services, technologies and software we offer,
      except where expressly stated that separate terms (and not these) apply.
    </p>
    <p>
      The terms and conditions establish the rules users must follow when
      downloading and using the App and Special reserves the right to:
      <ul>
        <li>
          Suspend or delete abusive accounts if they violate the terms and
          conditions;
        </li>
        <li>
          Update its terms regarding the operation of the App and establishes
          guidelines for dealing with consumer issues such as late delivery,
          payment problems and refunds;
        </li>
        <li>
          Establish the jurisdiction in which the laws govern the App; and
        </li>
        <li>
          Specify the intellectual property rights and the actions taken where
          this is infringed on.
        </li>
      </ul>
    </p>
    <p>
      To access and use the Products and Services, you must register for a
      Special account (‘Account’) by providing your full legal name, a valid
      email address, phone number and any other information indicated as
      required. Special may reject your application for an Account, or cancel an
      existing Account, for any reason, in our sole discretion.
    </p>
    <p>
      A breach or violation of any terms and conditions as determined in the
      sole discretion of Special may result in an immediate termination of your
      Products and Services.
    </p>
    <b>Reasonable use</b>
    <p>
      Special wants people to use the App to buy and expend our Products and
      Services on behalf of a recipient but not at the expense of the safety and
      well-being of others of the integrity of the Special customers. You
      therefore agree not to engage in conduct described below (or to facilitate
      or support others in doing so):
    </p>
    <ol>
      <li>
        You may not use our Product and Services to do or share anything:
        <ol type="a">
          <li>That violates these terms and conditions</li>
          <li>That is unlawful, misleading, discriminatory or fraudulent; </li>
          <li>That infringes or violates someone else’s rights.</li>
        </ol>
      </li>
      <li>
        You may not upload viruses or malicious code or do anything that could
        disable, overburden or impair the proper working or appearance of our
        services.{' '}
      </li>
      <li>
        You may not access or collect data from our Products and Services using
        automated means (without our prior written consent) or attempt to access
        data you do not have permission to access.{' '}
      </li>
      <li>
        You can’t impersonate others or provide inaccurate information
        <ol type="a">
          <li>
            You don’t have to disclose your identity on the Special platform but
            you must provide us with accurate and up to date information
            (including email address). Also, you may not impersonate someone you
            aren’t, and you can’t create an account for someone else or gift to
            someone else without having their express permission or consenting
            to take responsibility for the provision of information and
            indemnifying Special with regards to this information.{' '}
          </li>
        </ol>
      </li>
      <li>
        You cannot do anything to interfere with or impair the intended
        operation of the Product and Services.{' '}
      </li>
      <li>
        You cannot attempt to create accounts or access or collect information
        in an unauthorised way
        <ol type="a">
          <li>
            This includes creating accounts or collecting information in an
            automated way without our express permission.
          </li>
        </ol>
      </li>
    </ol>
    <b>Trademark information</b>
    <p>
      Trademarks, service marks and all graphical elements, including the look
      and feel appearing on the online and App services, are distinctive and
      protected trademarks or trade dress of Special or its licensors. The
      online services may also contain various third-party names, trademarks and
      service marks that are the property of their respective owners.
    </p>
    <b>Limitation of liability</b>
    <p>
      <ol>
        <li>
          Our service is provided “as is” and we can’t guarantee it will be safe
          and secure or will perfectly all the time.
        </li>
        <li>
          Our responsibility for anything that happens on the Product and
          Services (also called ‘liability’) is limited as much as the law will
          allow. If there is an issue with our Product and Services, we can’t
          know what all the possible impacts might be. You agree we won’t be
          liable for any lost profits, punitive or incidental damages arising
          out of or related to these Terms, even if we know they are possible.
          This includes when we delete your content, information or account.
        </li>
      </ol>
    </p>
    <b>Other clauses</b>
    <p>
      Special software including the app may be solely used for authorised
      gifting and purchasing of our Products and Services from Special through
      Special ready devices. This software may vary by device and medium, and
      functionalities may also differ between devices. By using our Products and
      Services, you agree to receive, without further notice or prompting,
      updated versions of the Special and related third-party software. If you
      do not accept the foregoing terms, please do not use our Products and
      Services.
    </p>
    <b>Access to and request to delete your personal information</b>
    <p>
      You are entitled to ask us to supply you with any personal information
      that we hold about you. You can also request to delete your data by
      emailing our Privacy Officer. <br /> You must submit your request in
      writing to the appropriate address as below:
    </p>
    <b>Privacy Officer</b>
    <p>
      Our privacy officer is contactable at{' '}
      <a href="mailto:privacy@special.com.au">privacy@special.com.au</a>. We
      maintain the quality of your personal information by taking reasonable
      steps to ensure that the information collected, used, and disclosed is
      accurate, complete, and up to date. Alternatively, you may also update
      your personal information at any time or request to delete your data by
      emailing{' '}
      <a href="mailto:privacy@special.com.au">privacy@special.com.au</a>{' '}
      directly.
    </p>
    <b>Complaints</b>
    <p>
      Additionally, if you wish to make a general complaint about our Products
      and Services, please contact us directly at{' '}
      <a href="mailto:customer.service@special.com.au">
        customer.service@special.com.au
      </a>{' '}
      addressing your complaint to the Complaints Officer. If you need to
      resolve an issue or make a complaint about how we collect, use or store
      your personal information, please contact us directly at{' '}
      <a href="mailto:customer.service@special.com.au">
        customer.service@special.com.au
      </a>{' '}
      addressing your complaint to the Privacy Officer or alternatively at{' '}
      <a href="mailto:privacy@special.com.au">privacy@special.com.au</a>.{' '}
    </p>
    <b>How to make a complaint</b>
    <p>
      If you wish to resolve an issue about the way in which we manage your
      personal information or any other matters, please contact us directly on
      1300 54 54 90. If you are not satisfied with our response or would like to
      make a complaint, please ask to speak to our Complaints Officer.
    </p>
    <p>
      We will let you know if we need any further information from you to assist
      in investigating and resolving your complaint. We aim to resolve
      complaints as quickly as possible. We strive to resolve complaints within
      five business days, but some complaints take longer to resolve. If your
      complaint is taking longer, we will let you know what is happening and a
      date by which you can reasonably expect a response.{' '}
    </p>
    <p>
      If you are not satisfied with our response or prefer to raise a complaint
      with our Privacy Officer directly, please email{' '}
      <a href="mailto: privacy@special.com.au">privacy@special.com.au</a>.
    </p>
    <p>
      If you have followed these steps and are still not satisfied with the
      outcome, you can contact the Office of the Australian Information
      Commissioner GPO Box 5218, Sydney NSW 2001
      <br />
      <br />
      (p) <a href="tel:1300363992">1300 363 992</a>
      <br />
      (e) <a href="mailto: enquiries@oaic.gov.au ">
        enquiries@oaic.gov.au{' '}
      </a>{' '}
      <br />
      (w){' '}
      <a href="https://www.oaic.gov.au" target="_blank" rel="noreferrer">
        www.oaic.gov.au
      </a>{' '}
      (where you can make a complaint online)
    </p>
    <b>Changes to this Policy</b>
    <p>
      From time to time it may be necessary for us to review and revise our
      privacy policy to reflect company, client and regulatory feedback. We
      encourage you to periodically review the website and the Privacy Policy to
      be informed of how we are protecting your information. We will publish the
      updated version on our website and by continuing to deal with us, we
      confirm that you accept this Privacy Policy as it applies at that point in
      time. Alternatively, if you would like a copy of this Privacy Policy,
      please contact us.
    </p>
    <b>Candidates and employees</b>
    <p>
      <b>How we collect information</b>
      <ul>
        <li>
          If you are a candidate for employment or prospective contractor, when
          you complete forms in relation to the recruitment and selection
          process, for the purpose of assessment.
        </li>
      </ul>
    </p>
    <p>
      <b>Purpose</b>
      <ul>
        <li>
          If you are a candidate for employment or prospective contractor, to
          assess your suitability for a position at Karta or one of our related
          companies or brands. In addition, if you are employee at the Group, we
          may contact you after your employment ends to conduct an exit-survey.
        </li>
      </ul>
    </p>
    <p>
      <b>Third Parties</b>
      <ul>
        <li>
          If you are a candidate for employment or prospective contractor, we
          may collect information about you from your nominated referees,
          confirm working rights with the relevant government departments, and
          complete employee due diligence screening, where you have authorised
          us to do so.
        </li>
      </ul>
    </p>
    <p>
      <b>Disclosure</b>
      <ul>
        <li>
          If you are a candidate for an employment position involving a third
          party with whom we have an agreement, to that third party to assess
          your application.
        </li>
      </ul>
    </p>
    <p>
      If you are a candidate for employment, you may alternatively contact us in
      relation to this Privacy Policy or to access, update or amend your
      personal information. This Privacy Policy does not apply to current and
      former employees of the Group. If you are a current or former employee and
      have a query about your personal information, please contact our People
      and Culture team.{' '}
    </p>
  </div>
);

export default PrivacyPolicy;
