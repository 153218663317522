import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  ISideDrawerHandle,
  SideDrawerContainer,
} from '../../../components/SideDrawer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useApi } from '../../../api/ApiProvider';
import { from, Subscription } from 'rxjs';
import { AxiosResponse } from 'axios';
import { MerchantVM, MerchantVMIPagedList } from '../../../generated';
import RetailersHeader from './RetailersHeader';
import RetailersItem from './RetaliersItem';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { Input } from '../../../components/Input';
import { useDebounce } from '../../../hooks/useDebounce';
import { SEARCH_DEBOUNCE_DELAY } from '../../../shared/const';
import { Alert } from '../../../components/Alert';
import Info from '../../../assets/svg/alert/Info.svg';
import NoRetailer from './NoRetailer';

type Props = {
  giftCardId: number;
  sideDrawerRef: RefObject<ISideDrawerHandle>;
  onBackClose: boolean;
  retailersDescription: string;
  locationCount: number;
  slug?: string | null;
};

const RetailersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10%;
`;

const RetailersLoaderContainer = styled.div`
  margin-top: 2rem;
`;

const RetailersSearchContainer = styled.div`
  margin-top: 3.5rem;
  input {
    margin-bottom: 0;
  }
`;

const RetailersLoader = () => (
  <RetailersLoaderContainer>
    <ContentLoader
      speed={2}
      width={400}
      height={60}
      viewBox="0 0 400 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="4" y="27" rx="3" ry="3" width="88" height="6" />
      <rect x="3" y="7" rx="3" ry="3" width="52" height="6" />
      <rect x="286" y="28" rx="3" ry="3" width="88" height="6" />
      <rect x="285" y="8" rx="3" ry="3" width="52" height="6" />
    </ContentLoader>
  </RetailersLoaderContainer>
);

const Retailers = ({
  giftCardId,
  retailersDescription,
  onBackClose,
  sideDrawerRef,
  locationCount,
  slug,
}: Props) => {
  let query = slug ? slug : giftCardId.toString();

  const { merchantApi } = useApi();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [merchants, setMerchants] = useState<Array<MerchantVM>>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchTermToUse = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY);
  const scrollRef = useRef<null | InfiniteScroll>(null);
  const headerRef = useRef<null | HTMLDivElement>(null);
  const [showHeaderInput, setShowHeaderInput] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const subscription: Subscription = from(
      merchantApi.getMerchantsForGiftCard(
        query,
        undefined,
        undefined,
        undefined,
        searchTermToUse,
        100
      )
    ).subscribe((result: AxiosResponse<MerchantVMIPagedList, any>) => {
      const merchantsData = result.data?.results ?? [];
      setMerchants(merchantsData);
      setHasMore((result.data?.total ?? 0) > merchantsData.length);
      setIsLoading(false);
      onScroll();
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantApi, giftCardId, searchTermToUse]);

  const getMerchants = async () => {
    const result = await merchantApi.getMerchantsForGiftCard(
      query,
      undefined,
      undefined,
      undefined,
      searchTermToUse,
      100,
      merchants.length
    );
    const newMerchants = result.data?.results ?? [];
    const merchantsData = [...merchants, ...newMerchants];
    setMerchants(merchantsData);
    setHasMore((result.data?.total ?? 0) > merchantsData.length);
  };

  const onScroll = () => {
    if (scrollRef.current && headerRef.current) {
      const scrollerTopPosition =
        scrollRef.current?.getScrollableTarget()?.scrollTop;
      const filterVisibilityThreshold =
        headerRef.current.getBoundingClientRect().bottom;
      if (
        scrollerTopPosition &&
        scrollerTopPosition > filterVisibilityThreshold - 25
      ) {
        setShowHeaderInput(true);
      } else if (
        scrollerTopPosition &&
        scrollerTopPosition < filterVisibilityThreshold - 25
      ) {
        setShowHeaderInput(false);
      } else {
        setShowHeaderInput(false);
      }
    }
  };

  return (
    <>
      <div
        ref={headerRef}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
        }}>
        <RetailersHeader
          onBackClose={onBackClose}
          sideDrawerRef={sideDrawerRef}
          total={locationCount}
          showHeaderInput={showHeaderInput}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <SideDrawerContainer>
        <RetailersSearchContainer>
          <Input
            label=""
            placeholder="Filter by retailer name"
            type="text"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            value={searchTerm}
          />
        </RetailersSearchContainer>
        <Alert title={retailersDescription} role="Info" icon={Info} />
        {isLoading ? (
          <>
            <RetailersLoader />
            <RetailersLoader />
            <RetailersLoader />
          </>
        ) : merchants.length > 0 ? (
          <InfiniteScroll
            ref={scrollRef}
            onScroll={onScroll}
            scrollableTarget="SideDrawerInnerContainer"
            dataLength={merchants.length}
            next={async () => {
              getMerchants();
            }}
            hasMore={hasMore}
            loader={<></>}>
            <RetailersContainer>
              {merchants.map((merchant) => (
                <RetailersItem
                  key={merchant.id}
                  data={merchant}></RetailersItem>
              ))}
            </RetailersContainer>
          </InfiniteScroll>
        ) : (
          <NoRetailer />
        )}
      </SideDrawerContainer>
    </>
  );
};

export default Retailers;
