import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './mobile-input.scss';

interface MobileInputProps {
  placeholder?: string;
  error?: boolean;
  value?: string;
  country?: string;
  onChange?: (number: string) => void;
  onBlur?: (number: string) => void;
}

const MobileInput = ({
  placeholder,
  value,
  country,
  error = false,
  onChange,
  onBlur,
}: MobileInputProps) => {
  const errorClass = error ? 'error' : '';
  const [valueToUse, setValueToUse] = useState('');
  useEffect(() => {
    setValueToUse(value || '');
  }, [value]);

  const formatNumber = (number: string): string => {
    number = number.replace(/\s/g, '');
    if (number.startsWith('61610')) {
      number = `61${number.slice(5)}`;
    } else if (number.startsWith('6161')) {
      number = number.slice(2);
    } else if (number.startsWith('610')) {
      number = `61${number.slice(3)}`;
    }
    return number;
  };

  return (
    <>
      <PhoneInput
        disableDropdown
        country={'au'}
        masks={{ au: '- ... ... ......' }}
        inputProps={{
          required: true,
          autoFocus: true,
        }}
        inputClass={`special-mobile-input ${errorClass}`}
        buttonClass="special-mobile-button"
        value={valueToUse}
        onChange={(number) => {
          onChange && onChange(formatNumber(number));
          setValueToUse(formatNumber(number));
        }}
        countryCodeEditable={false}
        placeholder={placeholder}
        onBlur={(number) => {
          onBlur && onBlur(formatNumber(number.target.value));
        }}
      />
    </>
  );
};

export default MobileInput;
