import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../const/Colors';
import { usePurchase } from '../../../../context/PurchaseProvider';
import { UserGiftCardSendingMethod } from '../../../../generated';
import { formatPhoneNumber } from '../../../../helpers/utils';

const SummaryContainer = styled.div`
  background-color: #f6f5fa73;
  border: 0.1rem solid #cbd0d673;
  border-radius: 0.9rem;
  margin-bottom: 2rem;
  padding: 2rem;
  word-break: break-word;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2.2rem;
`;

const HeaderText = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${Colors.black};
`;

const Edit = styled.div`
  color: ${Colors.electricBlue};
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
`;

const SummaryTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${Colors.black70};
  min-width: 30%;
  width: 30%;
`;

const SummaryValue = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${Colors.black};
  text-align: right;
`;

const Message = styled.div`
  padding-top: 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${Colors.black};
`;

const MessageRow = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const TimeZone = styled.span`
  font-size: 1.6rem;
  margin-top: 1rem;
  display: inline-block;
`;

interface TimezoneOption {
  label: string;
  value: number | undefined;
}

interface OwnProps {
  setIsEditMode: (editMode: boolean) => void;
  momentDate: Moment;
  timezoneOptions: TimezoneOption[];
}

const NormalMode: React.FC<OwnProps> = ({
  setIsEditMode,
  momentDate,
  timezoneOptions,
}) => {
  const purchase = usePurchase();
  return (
    <SummaryContainer>
      <Row>
        <HeaderText>SUMMARY</HeaderText>
        <Edit onClick={() => setIsEditMode(true)}>EDIT</Edit>
      </Row>
      <Row>
        <SummaryTitle>Value</SummaryTitle>
        <SummaryValue>${purchase.newPurchaseDetails.value}</SummaryValue>
      </Row>
      <Row>
        <SummaryTitle>To</SummaryTitle>
        <SummaryValue>
          {purchase.newPurchaseDetails.receiverFirstName}{' '}
          {purchase.newPurchaseDetails.receiverLastName}
        </SummaryValue>
      </Row>
      <Row>
        <SummaryTitle>From</SummaryTitle>
        <SummaryValue>{purchase.newPurchaseDetails.from}</SummaryValue>
      </Row>
      {purchase.newPurchaseDetails.sendingMethod ===
      UserGiftCardSendingMethod.Sms ? (
        <Row>
          <SummaryTitle>
            {purchase.newPurchaseDetails.receiverFirstName}'s number
          </SummaryTitle>
          <SummaryValue>
            {formatPhoneNumber(
              purchase.newPurchaseDetails.receiverPhoneNumber || ''
            )}
          </SummaryValue>
        </Row>
      ) : (
        <Row>
          <SummaryTitle>
            {purchase.newPurchaseDetails.receiverFirstName}'s email
          </SummaryTitle>
          <SummaryValue>
            {purchase.newPurchaseDetails.receiverEmail}
          </SummaryValue>
        </Row>
      )}
      <Row>
        <SummaryTitle>Scheduled delivery</SummaryTitle>
        <div style={{ textAlign: 'end' }}>
          {purchase.newPurchaseDetails.sendNow ? (
            <span>Now</span>
          ) : (
            <>
              <SummaryValue>
                {momentDate.format('DD-MM-YY | h:mm a')}
              </SummaryValue>
              <TimeZone>
                {
                  timezoneOptions.find(
                    (el) =>
                      el.value === purchase.newPurchaseDetails.specialTimezoneId
                  )?.label
                }
              </TimeZone>
            </>
          )}
        </div>
      </Row>
      <MessageRow>
        <SummaryTitle>Message</SummaryTitle>
        <Message>{purchase.newPurchaseDetails.message}</Message>
      </MessageRow>
    </SummaryContainer>
  );
};

export default NormalMode;
