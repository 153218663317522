import { useEffect, useMemo } from 'react';
import Body from '../../components/Body';
import { Hero, HeroTitle, HeroSubtitle } from '../../components/Hero';
import NoMorePlastics from '../../assets/svg/opt-out/NoMorePlastics.svg';
import CustomerJourney from '../../assets/svg/opt-out/CustomerJourney.svg';
import ImageCard from '../../assets/svg/opt-out/ImageCard.svg';
import WomenWithGlasses from '../../assets/svg/opt-out/WomenWithGlasses.svg';
import ColorfulStars from '../../assets/svg/opt-out/ColorfulStars.svg';
import Online from '../../assets/svg/opt-out/Online.svg';
import Illustration from '../../assets/svg/opt-out/Illustration.svg';
import './opt-out.scss';
import { RudderStack } from '../../services/shared/RudderStack';
import { Helmet } from 'react-helmet';
import { Button } from '../../components/Button';
import Banner from '../../components/Banner';
import CardExample from '../../assets/svg/CardExample.svg';
import { useNavigate } from 'react-router-dom';
import Colors from '../../const/Colors';
import { useOptInOut } from '../../context/OptInOutProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Breakpoints from '../../const/breakpoints';

const OptOut: React.FC = () => {
  const navigate = useNavigate();
  const { startOptInOut: startOptOut } = useOptInOut();
  useEffect(() => {
    RudderStack.page('OptOut');
  }, []);
  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  return (
    <Body theme="soft-lilac" hideCtaBanner={true}>
      <Helmet>
        <title>Special Gift Cards | Opt Out</title>
      </Helmet>
      <div className="content">
        <Hero>
          <HeroTitle>
            {isMobileView ? (
              <>
                OPT{' '}
                <img
                  className="hit-icon illustration-icon"
                  src={Illustration}
                  alt="Illustration"
                />{' '}
                OUT
              </>
            ) : (
              <>
                OPT OUT{' '}
                <img
                  className="hit-icon illustration-icon"
                  src={Illustration}
                  alt="Illustration"
                />
              </>
            )}
          </HeroTitle>
          <div className="opt-out-subtitle">
            <HeroSubtitle>
              Gift Cards just became{' '}
              <strong style={{ position: 'relative' }}>
                Special<span className="trade">&trade;</span>
              </strong>
            </HeroSubtitle>
          </div>
        </Hero>
        <div className="opt-out">
          <div className="hit-section">
            <div className="hit-header">
              What Makes Special,
              <br />
              Special?
            </div>
            <div className="hit-content">
              <div>
                <div className="hit-image heart">
                  <img src={CustomerJourney} alt="Find the perfect gift" />
                </div>
                <div className="hit-title">
                  The customer
                  <br /> journey
                </div>
                <div className="hit-description">
                  Special brings back the emotion, excitement, and experience to
                  the gift card industry, allow customers to personalise the
                  gifting experience in a way that means the most.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img src={NoMorePlastics} alt="Turn up the fun " />
                </div>
                <div className="hit-title">No more plastics</div>
                <div className="hit-description">
                  No more drawers at home full of plastic expired gift cards!
                  Special is excited to provide a complete digital gifting
                  experience by adding your gift card to your Apple or Google
                  wallet, ensuring that your Special someone makes the most of
                  their gift.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img src={Online} alt="Schedule for a Special occasion" />
                </div>
                <div className="hit-title">Online & In-store</div>
                <div className="hit-description">
                  Special Cards can be spent at participating stores using Apple
                  and Google wallet, or by entering the Special Card details at
                  the checkout when shopping online.
                </div>
              </div>
            </div>
          </div>
          <div className="opt-out-container">
            <div>
              <div className="opt-out-header">
                Don't want to see your business here?
              </div>
              <div className="out-out-description">
                Click the below button and complete the questionaire for us. Our
                commercial teams will be in touch with next steps.
              </div>
              <Button
                title="OPT OUT"
                onClick={() => {
                  startOptOut();
                  navigate('/opt-out/info');
                }}
                role="Primary"
              />
            </div>
            <img src={ImageCard} className="image-card" alt="ImageCard"></img>
          </div>
          <div className="opt-out-bottom-container">
            <img
              src={ColorfulStars}
              className="colorful-stars"
              alt="ColorfulStars"></img>
            <div className="out-out-container">
              <div className="out-out-description">
                Special is an Australian digital gifting company that is on a
                mission to connect people through special moments and happiness.
              </div>
              <div className="out-out-description">
                Gifting plays a big role in strengthening relationships with
                those we care about, and in an age where people have a lot of
                material things already, many prefer a gift card that is
                meaningful and flexible – something that can be spent on
                something they want from a place they love, so they feel the way
                you want them to feel. Special.
              </div>
            </div>
            <img
              src={WomenWithGlasses}
              className="women-with-glasses"
              alt="WomenWithGlasses"></img>
          </div>
        </div>
      </div>
      <Banner className="custom-banner">
        <div className="cta-container">
          <img src={CardExample} alt="Join Special Today" />
          <div>
            <h2
              className="footer-heading"
              style={{ marginTop: isMobileView ? '2rem' : '0' }}>
              Join Special today
            </h2>
            <p>
              Special is an Australian digital gifting company that is on a
              mission to connect people through special moments and happiness.
            </p>
          </div>
          {!isMobileView && (
            <div className="flex-fill">
              <Button
                title="Claim my business"
                startIconClass="red-heart-icon"
                onClick={() => {
                  navigate('/claim-my-business');
                }}
                role="Primary"
                color="white"
                style={{ color: Colors.black, width: '27.5rem' }}
              />
            </div>
          )}
        </div>
        {isMobileView && (
          <Button
            title="Claim my business"
            startIconClass="red-heart-icon"
            onClick={() => {
              navigate('/claim-my-business/info');
            }}
            role="Primary"
            color="white"
            style={{
              color: Colors.black,
              marginBottom: '3rem',
              marginTop: '2rem',
            }}
          />
        )}
      </Banner>
    </Body>
  );
};
export default OptOut;
