import { Route, Routes } from 'react-router';
import OptOut from '../containers/OptOut';
import OptOutConfirmation from '../containers/OptOut/OptOutConfirmation';
import OptOutInfo from '../containers/OptOut/OptOutInfo';
import OptOutReason from '../containers/OptOut/OptOutReason';
import { OptInOutProvider } from '../context/OptInOutProvider';

const OptOutRoutes = () => {
  return (
    <OptInOutProvider>
      <Routes>
        <Route path="/info" element={<OptOutInfo />} />
        <Route path="/reason" element={<OptOutReason />} />
        <Route path="/confirmation" element={<OptOutConfirmation />} />
        <Route path="*" element={<OptOut />} />
      </Routes>
    </OptInOutProvider>
  );
};

export default OptOutRoutes;
