import styled from 'styled-components';
import Colors from '../../../../const/Colors';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
`;

export const BoldText = styled.span`
  font-size: 1.7rem;
  line-height: 2.4rem;
  color: ${Colors.black};
  font-weight: bold;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export const CancelStyles = styled.div`
  margin-top: 2rem;
`;
