import React, { useEffect, useMemo } from 'react';
import { ReactComponent as BlackColorLogo } from '../../../assets/svg/logo/FullColorBlack.svg';
import FailedArt from '../../../assets/svg/activation/failed/ActivationFailedArt.svg';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import Faq from '../common/Faq';
import './styles.scss';
import OrderSummary from '../common/OrderSummary';
import { useActivation } from '../../../context/ActivationProvider';
import { Button } from '../../../components/Button';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../const/Colors';
import { RudderStack } from '../../../services/shared/RudderStack';

interface OwnProps {}

const TITLE = `Oops!
Something went wrong.`;

const Failed: React.FC<OwnProps> = () => {
  const activation = useActivation();
  const navigate = useNavigate();
  const frontImage = useMemo(
    () => activation.activationData.activationDetails.frontImage,
    [activation.activationData.activationDetails.frontImage]
  );

  useEffect(() => {
    RudderStack.page("Activation_Failed");
  },[])

  const onContact = () => {
    navigate('/contact-us');
  };
  return (
    <div className="activationFailed">
      <div className="topBar">
        <BlackColorLogo width={116} height={34} onClick={() => navigate('/')} />
        <Faq heartColor={Colors.seaGreen} reverseOrder />
      </div>

      <div className="failedArt">
        <img src={FailedArt} alt="failed art" />
      </div>

      <Border height="62" fill={Colors.white} className="scallop" />

      <div className="details">
        <div className="title">{TITLE}</div>
        <div className="subTitle">
          Please contact the Special customer service on
          customer.service@special.com.au so we can help fix this for you.
        </div>

        <div className="summary">
          <OrderSummary
            name={
              activation.activationData.activationDetails.cardProgramName || ''
            }
            image={frontImage || ''}
            value={parseInt(
              activation.activationData.activationDetails.value || ''
            )}
          />
        </div>

        <div className="contactButton">
          <Button
            title="CONTACT US"
            startIconClass="heart-icon"
            role="Primary"
            onClick={onContact}
          />
        </div>
      </div>

      <Footer hideCtaBanner theme="black" />
    </div>
  );
};

export default Failed;
