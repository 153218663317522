import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserGiftCard } from '..';
import { Button } from '../../../components/Button';
import { SideDrawerContainer } from '../../../components/SideDrawer';
import {
  ManagePinHeader,
  ManagePinParagraph,
  ManagePinParagraphHeader,
  TextDesc,
} from '../shared-components/manage-pin';
import CardPin, { CardPinStatus } from '../../../components/CardPin';
import { validatePin } from '../../../helpers/utils';
import { useManageCard } from '../../../context/ManagePinProvider';
import {
  DEFAULT_PIN_ERR_MSG,
  DEFAULT_PIN_WARNING_MSG,
  PIN_LENGTH,
} from '../../../const/shared';
import { ErrorText } from '../../../components/ErrorText';
import { useAuth } from '../../../context/AuthProvider';

type Props = {
  userGiftCard?: UserGiftCard;
};

const SetPin = ({ userGiftCard }: Props) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<CardPinStatus>();
  const { setInitialPin, phoneVerified } = useManageCard();

  const { userDetailsVM } = useAuth();
  const userPhoneNumber = userDetailsVM?.mobileNumber || '';
  const panLastFour = userGiftCard?.details?.cardDetails?.panLast4 || '';
  useEffect(() => {
    if (phoneVerified) {
      return;
    }
    navigate(`/my-cards/${userGiftCard?.id}`);
  }, [phoneVerified, userGiftCard, navigate]);
  const onComplete = (pin: string) => {
    if (validatePin(pin, PIN_LENGTH, userPhoneNumber, panLastFour)) {
      setInitialPin(pin);
      navigate(`/my-cards/${userGiftCard?.id}/manage-pin/confirm-pin`);
    } else {
      setStatus('error');
    }
  };

  return (
    <>
      <ManagePinHeader />
      <SideDrawerContainer>
        <ManagePinParagraphHeader>Set a new PIN</ManagePinParagraphHeader>
        <ManagePinParagraph>Enter a new PIN for your card.</ManagePinParagraph>
        <CardPin
          length={4}
          placeholder=""
          onComplete={onComplete}
          hideValue
          status={status}
          size="small"></CardPin>
        {status === 'error' && (
          <div className="pinErrorText">
            <ErrorText
              text={DEFAULT_PIN_ERR_MSG}
              style={{ paddingLeft: 0, marginTop: '0.8rem' }}
            />
          </div>
        )}
        <Button
          title="SET PIN"
          onClick={() => {
            setStatus('error');
          }}
          style={{ width: '25rem', height: '4.5rem', marginTop: '3rem' }}
          role="Secondary"></Button>
        <TextDesc>{DEFAULT_PIN_WARNING_MSG}</TextDesc>
      </SideDrawerContainer>
    </>
  );
};

export default SetPin;
