import { ApiProvider } from './api/ApiProvider';
import { AppProvider } from './context/AppProvider';
import { AuthProvider } from './context/AuthProvider';
import './styles/styles.scss';
import AppRouting from './util/AppRouting';
import ScrollToTop from './util/ScollToTop';
import 'dotenv';
import { ThemeProvider } from 'styled-components';
import { theme } from './const/theme';
import { PurchaseProvider } from './context/PurchaseProvider';
import { ActivationProvider } from './context/ActivationProvider';
import { PaymentProvider } from './context/PaymentProvider';
import { Elements as StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EnvConfig from './config/EnvConfig';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { Toaster } from './components/Toaster';
import { useMemo, useState } from 'react';
import { AccountDeletionProvider } from './context/AccountDeletionProvider';
import { PromotionCodeVM } from './generated';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '50px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1002,
  },
};

const { stripeConsumerKey, stripeOfferKey, stripeUseDefaultKey } = EnvConfig;

function App() {
  const [key, setKey] = useState(stripeConsumerKey);
  const [promo, setPromo] = useState<any>();
  const stripePromise = useMemo(() => loadStripe(key), [key]);

  const changePaymentProvider = (
    isPcloEnabled: boolean,
    promoCodeEnabled: boolean,
    promo: PromotionCodeVM
  ) => {
    if (isPcloEnabled && !promoCodeEnabled && !stripeUseDefaultKey) {
      setKey(stripeOfferKey);
    } else {
      setKey(stripeConsumerKey);
    }

    setPromo(promo);
  };

  return (
    <ApiProvider>
      <StripeProvider
        key={key}
        stripe={stripePromise}
        options={{
          fonts: [
            {
              src: 'url("../src/assets/fonts/Agrandir-Narrow.otf") format("opentype")',
              family: 'Agrandir-Narrow',
              weight: 'normal',
              style: 'normal',
            },
          ],
        }}>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <AlertProvider template={Toaster} {...options}>
              <AuthProvider>
                <PaymentProvider>
                  <PurchaseProvider>
                    <ActivationProvider>
                      <AccountDeletionProvider>
                        <ScrollToTop />
                        <AppRouting
                          changeProviderKey={changePaymentProvider}
                          promo={promo}
                        />
                      </AccountDeletionProvider>
                    </ActivationProvider>
                  </PurchaseProvider>
                </PaymentProvider>
              </AuthProvider>
            </AlertProvider>
          </ThemeProvider>
        </AppProvider>
      </StripeProvider>
    </ApiProvider>
  );
}

export default App;
