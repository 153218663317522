import { LottieOptions, useLottie } from 'lottie-react';

import { Confetti as ConfettiAnimations } from '../../../../assets/animations/Confetti';
import { Fireworks as FireworksAnimations } from '../../../../assets/animations/Fireworks';
import { GoldConfetti as GoldConfettiAnimations } from '../../../../assets/animations/GoldConfetti';

interface OwnProps {
  animationType?: string | null;
}

const getAnimation = (animtaionName?: string | null) => {
  switch (animtaionName) {
    case 'confetti':
      return ConfettiAnimations;
    case 'fireworks':
      return FireworksAnimations;
    case 'goldConfetti':
      return GoldConfettiAnimations;
    default:
      return ConfettiAnimations;
  }
};

const CelebrationAnimation: React.FC<OwnProps> = ({ animationType }) => {
  const options: LottieOptions = {
    animationData: getAnimation(animationType),
    loop: false,
    autoPlay: true,
  };
  const { View } = useLottie(options);
  return View;
};

export default CelebrationAnimation;
