import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

import './body.scss';

interface BodyProps extends React.BaseHTMLAttributes<any> {
  theme: 'electric-blue' | 'white' | 'sea-green' | 'soft-lilac';
  headerVariant?: 'my-cards' | 'default' | 'purchase' | 'message';
  isHome?: boolean;
  hideCtaBanner?: boolean;
  disableFooter?: boolean;
  footerButton?: {
    text: string;
    buttonImage: string;
    onClick: () => void;
    to: string;
    disabled?: boolean;
  };
  headerDisableShopNow?: boolean;
  headerMessage?: string;
  displayMobileMenu?: boolean;
  displayCareers?: boolean;
}

const Body = ({
  theme,
  disableFooter,
  footerButton,
  headerMessage = '',
  headerDisableShopNow = false,
  headerVariant = 'default',
  displayMobileMenu = true,
  displayCareers = true,
  ...props
}: BodyProps) => {
  return (
    <div className={`body ${theme}`}>
      <Header
        variant={headerVariant}
        theme={theme}
        disableShopNow={headerDisableShopNow}
        message={headerMessage}
        displayMobileMenu={displayMobileMenu}
        displayCareers={displayCareers}
        footerButton={footerButton}
      />
      <div className="main-content">{props.children}</div>
      {!disableFooter && (
        <Footer
          theme={props.isHome ? 'black' : theme}
          hideCtaBanner={!!props.isHome || props.hideCtaBanner}
        />
      )}
    </div>
  );
};

export default Body;
