import { useState, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import { PasswordComplexity } from '../../components/PasswordComplexity';
import { SMS_SENDING_GAP_IN_SECONDS } from '../../const/auth';
import { PASSWORD_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import './Auth.scss';
import CardPin from '../../components/CardPin';
import { useAlert } from 'react-alert';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';

type FormValues = {
  pin: string;
  password: string;
};

const ForgotPasswordSubmit = () => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      pin: '',
      password: '',
    },
  });
  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    RudderStack.page("Auth_ForgotPasswordSubmit");
  },[])

  const { lastSMSSent, forgotPassword, forgotPasswordSubmit, signOut } =
    useAuth();
  const [currentTime, setCurrentTime] = useState<Date>();
  const [isPosting, setIsPosting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isInvalidPin, setIsInvalidPin] = useState(false);
  const { emailApi } = useApi();
  const alert = useAlert();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isUpdate, userName = '' } = state as {
    isUpdate: boolean;
    deliveryMedium: string;
    destination: string;
    userName: string;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();

      setCurrentTime(currentTime);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onReVerifyPhone = async () => {
    if (isResending) {
      return;
    }
    if (timeGap !== null && timeGap < SMS_SENDING_GAP_IN_SECONDS) {
      return;
    }

    setIsResending(true);

    try {
      await forgotPassword(userName);
      setIsResending(false);
      alert.success('Code sent');
    } catch (error: any) {
      setIsResending(false);
      alert.error(error.message.toString());
    }
  };

  const timeGap: number | null = useMemo(() => {
    if (currentTime instanceof Date && lastSMSSent instanceof Date) {
      const gap = Math.ceil(
        (currentTime.getTime() - lastSMSSent.getTime()) / 1000
      );
      return gap >= SMS_SENDING_GAP_IN_SECONDS
        ? SMS_SENDING_GAP_IN_SECONDS
        : gap;
    }
    return null;
  }, [lastSMSSent, currentTime]);

  const timerDisplay = useMemo(() => {
    if (timeGap !== null) {
      if (SMS_SENDING_GAP_IN_SECONDS - timeGap === 0) {
        return '';
      }
      return ` (${SMS_SENDING_GAP_IN_SECONDS - timeGap}s)`;
    }
    return '';
  }, [timeGap]);

  const [passwordComplexityText, setPasswordComplexityText] = useState('');

  const updateComplexityText = (score: number) => {
    switch (score) {
      case 2:
        setPasswordComplexityText('Fair');
        break;
      case 3:
        setPasswordComplexityText('Good');
        break;
      case 4:
        setPasswordComplexityText('Excellent');
        break;
      default:
        setPasswordComplexityText('');
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isPosting) {
      return;
    }
    setIsPosting(true);
    try {
      await forgotPasswordSubmit(userName, data.pin, data.password);
    } catch (err: any) {
      if (err) {
        setIsInvalidPin(true);
      }
      setIsPosting(false);
      return;
    }

    try {
      if (isUpdate) {
        await emailApi.sendUpdatePasswordEmail(userName);
      } else {
        await emailApi.sendResetPasswordEmail(userName);
      }
    } catch (err: any) {
      reset();
      await signOut();
      navigate('/auth/login');
      setIsPosting(false);
    }

    reset();
    await signOut();
    navigate('/auth/login');
    setIsPosting(false);
  };

  return (
    <AuthWrapper>
      <>
        <p className="auth-create-account-title">Check your phone</p>
        <p className="auth-create-account-subtitle">
          A verification code has been sent to the phone number associated with:{' '}
          {userName}
        </p>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <div className="auth-input">
              <CardPin
                size="small"
                length={6}
                placeholder={'0'}
                onComplete={async (value) => {
                  onChange(value);
                }}
              />
              {isInvalidPin && (
                <div>
                  <ErrorText text="Oops, that code doesn’t match. Try again." />
                </div>
              )}
            </div>
          )}
          name="pin"
        />
        <p style={{ paddingBottom: '2rem' }}>
          Code didn't arrive?{' '}
          <span className="auth-link" onClick={() => onReVerifyPhone()}>
            Resend code
          </span>
          {timerDisplay}
        </p>
        <Controller
          control={control}
          rules={{ required: true, pattern: PASSWORD_REGEX }}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                className="auth-input"
                type={'password'}
                onChange={onChange}
                value={value}
                label="New Password"
                maxLength={50}
              />
              <PasswordComplexity
                text={passwordComplexityText}
                password={value}
                onUpdate={(score) => {
                  updateComplexityText(score);
                }}
              />
            </>
          )}
          name="password"
        />
        {errors.password && (
          <ErrorText text="Enter a password that is at least 8 characters, includes one number and special character, and is not a commonly used password." />
        )}

        <div
          style={{ paddingBottom: '3rem', fontSize: 13, lineHeight: '22px' }}>
          If you don't receive the code via SMS it may be for one of the
          following reasons: no verified phone number, incorrect email address
          or you use Apple ID, Google or Facebook to log in.
        </div>
        <Button
          startIconClass={'heart-icon'}
          title="Reset Password"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
        />
      </>
    </AuthWrapper>
  );
};

export default ForgotPasswordSubmit;
