import React from 'react';

import './styles.scss';

interface OwnProps {
  name: string;
  image: string;
  value: number;
  isBranded?: boolean;
}

const OrderSummary: React.FC<OwnProps> = ({
  name,
  image,
  value,
  isBranded = false,
}) => {
  return (
    <div className="orderSummary">
      <div className="section">
        <div className="topSection">
          <div className="left">
            <div className="sectionTitle">CARD DETAILS:</div>
            <div className="sectionValue">{name}</div>
          </div>
          <div className="right">
            {isBranded ? (
              <img className="brandCard" src={image} alt="small brand card" />
            ) : (
              <img className="smallCard" src={image} alt="small gift card" />
            )}
          </div>
        </div>
        <div className="bottomSection">
          <div className="left">
            <div className="sectionTitle">CARD VALUE: </div>
          </div>
          <div className="right">${value.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
