import './alert.scss';

interface AlertProps extends React.HTMLAttributes<HTMLSpanElement> {
  title: string;
  role?: 'Success' | 'Info' | 'Warning' | 'Error';
  icon?: string;
}

export const Alert = ({ title, role, icon, ...props }: AlertProps) => {
  const mode = role?.toLocaleLowerCase();
  return (
    <span className={`special-alert ${mode}`} {...props}>
      {!!icon && <img className="alert-icon" src={icon} alt={mode} />}
      {title}
    </span>
  );
};
