import React, { useEffect, useRef } from 'react';

import './styles.scss';

interface OwnProps {
  pin: Array<number | undefined>;
  onPinChange: (pinEntry: number | undefined, index: number) => void;
  pinInputLength: number;
  placeholder: string;
  status?: 'error' | 'success';
  size?: 'small' | 'big';
  hideValue?: boolean;
  onPinFocus: (index: number) => void;
}

const PIN_MIN_VALUE = 0;
const PIN_MAX_VALUE = 9;
const BACKSPACE_KEY = 'Backspace';

const PinInputGrid: React.FC<OwnProps> = ({
  pin,
  onPinChange,
  pinInputLength,
  placeholder,
  status,
  size,
  hideValue,
  onPinFocus,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const changePinFocus = (pinIndex: number) => {
    const ref = inputRefs.current[pinIndex];
    if (ref) {
      ref.focus();
    }
  };

  useEffect(() => {
    changePinFocus(0);
  }, []);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const values = event.target.value.split('') ?? [];
    let indexFocused = index;

    for (let i = 0; i < values.length; i++) {
      if (indexFocused >= pinInputLength) {
        break;
      }

      const value = values[i];
      const pinNumber = Number(value.trim());
      if (isNaN(pinNumber) || value.length === 0) {
        return;
      }

      if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
        onPinChange(pinNumber, indexFocused);

        if (indexFocused < pinInputLength - 1) {
          changePinFocus(indexFocused + 1);
        }
      }

      if (indexFocused === pinInputLength - 1) {
        event.target.blur();
      }

      indexFocused++;
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const keyCode = event.key;

    if (keyCode !== BACKSPACE_KEY) {
      return;
    }

    if (pin[index]) {
      onPinChange(undefined, index);
      changePinFocus(index - 1);
    }

    if (pin[index] === undefined) {
      changePinFocus(index - 1);
    }
  };

  return (
    <div className="pinInputGrid">
      {Array.from({ length: pinInputLength }, (_, index) => (
        <div key={index} style={{ position: 'relative' }}>
          <input
            className={`singleInput ${status ?? ''} ${size}`}
            onKeyDown={(event) => onKeyDown(event, index)}
            inputMode="numeric"
            key={index}
            ref={(el) => {
              if (el) {
                inputRefs.current[index] = el;
              }
            }}
            onChange={(event) => onChange(event, index)}
            value={
              hideValue && (pin[index] || pin[index] === 0)
                ? '✦'
                : pin[index]?.toString() || ''
            }
            placeholder={
              hideValue && (pin[index] || pin[index] === 0) ? '' : placeholder
            }
            onClick={() => onPinFocus(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default PinInputGrid;
