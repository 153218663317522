import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import SocialLogin from '../../components/SocialLogin';
import './Auth.scss';
import AuthWrapper from './AuthWrapper';

const SessionTimeOut = () => {
  const navigate = useNavigate();

  return (
    <AuthWrapper
      className="auth-page-pre session"
      containerClassName="auth-container-pre">
      <>
        <p className="auth-title">Your session has expired</p>
        <p className="auth-subtitle">Please log in to continue.</p>
        <Button
          title="LOG IN WITH EMAIL"
          startIconClass={'heart-icon'}
          role="Secondary"
          onClick={() => navigate('/auth/login')}
        />
        <SocialLogin />
      </>
    </AuthWrapper>
  );
};

export default SessionTimeOut;
