import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SpendInStoreBackButton from '../../../assets/svg/my-cards/SpendInStoreBackButton.svg';
import DownloadApp from '../../../components/DownloadApp';
import {
  SideDrawerContainer,
  SideDrawerHeader,
} from '../../../components/SideDrawer';
import Colors from '../../../const/Colors';

const DownloadHeader = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.4rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.div`
  font-size: 1.7rem;
  line-height: 2.7rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1.5rem 0;
`;

const Info = styled.div``;

interface IInfoNumberProps {
  color: string;
}

const InfoNumber = styled.div<IInfoNumberProps>`
  flex: 0 0 2.5rem;
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextUnderLine = styled.span`
  text-decoration: underline;
`;

const SpendInStore = () => {
  const navigate = useNavigate();

  return (
    <>
      <SideDrawerHeader>
        <img
          onClick={() => {
            navigate(-1);
          }}
          src={SpendInStoreBackButton}
          alt="Back"
        />
        <div className="header-title">Spend in-store</div>
      </SideDrawerHeader>
      <SideDrawerContainer>
        <Paragraph>
          To use your Special card in-store, it needs to be connected to your
          Apple or Google wallet via the Special Mobile App.
        </Paragraph>
        <InfoContainer>
          <InfoNumber color={Colors.softLillac}>1</InfoNumber>
          <Info>
            Get our mobile app - available for both{' '}
            <TextUnderLine>iOS</TextUnderLine> &{' '}
            <TextUnderLine>Android</TextUnderLine>.
          </Info>
        </InfoContainer>
        <DownloadHeader>Download the app now:</DownloadHeader>
        <DownloadApp />
        <InfoContainer>
          <InfoNumber color={Colors.electricBlue}>2</InfoNumber>
          <Info>Sign in with your Special account.</Info>
        </InfoContainer>
        <InfoContainer>
          <InfoNumber color={Colors.red}>3</InfoNumber>
          <Info>Go to this card and tap ‘Spend in-store’.</Info>
        </InfoContainer>
        <InfoContainer>
          <InfoNumber color={Colors.orangeSun}>4</InfoNumber>
          <Info>
            Follow the prompts to connect the card to your Apple or Google
            wallet.
          </Info>
        </InfoContainer>
        <InfoContainer>
          <InfoNumber color={Colors.seaGreen}>5</InfoNumber>
          <Info>🎉 Happy spending!</Info>
        </InfoContainer>
      </SideDrawerContainer>
    </>
  );
};

export default SpendInStore;
