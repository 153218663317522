import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SideDrawerHeader } from '../../../../components/SideDrawer';
import SvgCircleBack from '../../../../components/Svg/CircleBack';
import LocationSvg from '../../../../assets/svg/my-cards/Location.svg';
import { BrandRetailersHeaderContainer } from './styles';
import { IBrandRetailersHeaderProps } from './types';

const BrandRetailersHeader: React.FC<IBrandRetailersHeaderProps> = ({
  total,
  onBackClose,
  sideDrawerRef,
  brandName,
}) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (onBackClose) {
      sideDrawerRef.current?.toggleShow();
    } else {
      navigate(-1);
    }
  };

  return (
    <BrandRetailersHeaderContainer>
      <SideDrawerHeader>
        <div className="left-container">
          <SvgCircleBack onClick={onBackClick} />
          <div className="header-title">{brandName}</div>
        </div>
        <div className="right-container">
          <img src={LocationSvg} alt="Location" />
          <div className="location">
            {total.toLocaleString('en-US')} locations
          </div>
        </div>
      </SideDrawerHeader>
    </BrandRetailersHeaderContainer>
  );
};

export default BrandRetailersHeader;
