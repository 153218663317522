import './password-complexity.scss';
import zxcvbn from 'zxcvbn';
import { useEffect, useState } from 'react';
interface PasswordComplexityProps {
  text?: string;
  role?:
    | 'None'
    | 'Error-Simple-1'
    | 'Error-Simple-2'
    | 'Fair'
    | 'Good'
    | 'Excellent';
  password?: string;
  onUpdate?: (score: number) => void;
}

export const PasswordComplexity = ({
  text,
  role,
  password,
  onUpdate,
  ...props
}: PasswordComplexityProps) => {
  const [roleClass, setRoleClass] = useState(role?.toLocaleLowerCase());

  useEffect(() => {
    if (password === '') {
      return;
    }
    const { score } = zxcvbn(password || '');

    if(onUpdate) {
      onUpdate(score);
    }

    switch (score) {
      case 0:
        setRoleClass('error-simple-1')
        break;
      case 1:
        setRoleClass('error-simple-2')
        break;
      case 2:
        setRoleClass('fair')
        break;
      case 3:
        setRoleClass('good')
        break;
      case 4:
        setRoleClass('excellent')
        break;
      default:
    }
  }, [password, onUpdate])

  return (
    <div className={`special-password-complexity ${roleClass}`} {...props}>
      <div className="lines">
        <span className="line-1"></span>
        <span className="line-2"></span>
        <span className="line-3"></span>
        <span className="line-4"></span>
        <span className="line-5"></span>
      </div>
      <div className="text">{text}</div>
    </div>
  );
};
