import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackWrapper } from '../../../components/Svg/Waves/styles';
import '../opt-out.scss';
import { ReactComponent as BackIcon } from '../../../assets/svg/authWrapper/BackArrow.svg';
import { ReactComponent as SpecialLogo } from '../../../assets/svg/opt-out/SpecialLogo.svg';

type Props = {
  className?: string;
  containerClassName?: string;
  hideBackButton?: boolean;
};

const OptOutWrapper: React.FC<Props> = ({
  className = 'opt-out-page',
  containerClassName = 'opt-out-message-container',
  hideBackButton = false,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <div className={className}>
      <SpecialLogo
        className="logo"
        onClick={() => {
          navigate('/');
        }}
      />
      <div className={containerClassName}>
        {!hideBackButton && (
          <BackWrapper
            className="back-wrapper"
            onClick={() => {
              navigate(-1);
            }}>
            <BackIcon />
            <span>BACK</span>
          </BackWrapper>
        )}
        {children}
      </div>
    </div>
  );
};

export default OptOutWrapper;
