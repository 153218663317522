import { DeletionRequestReason } from '../../../../generated';
import { IReason } from './types';

export const RequestReasons: IReason = {
  Unspecified: {
    id: 0,
    text: 'Unspecified',
    deletionRequestReason: DeletionRequestReason.Unspecified,
    eventMessage: 'Not specified',
  },
  NoLongerInterested: {
    id: 1,
    text: 'I no longer want to use Special',
    deletionRequestReason: DeletionRequestReason.NoLongerInterested,
    eventMessage: 'No longer want to use Special',
  },
  TechnicalIssues: {
    id: 2,
    text: 'I had issues using Special',
    deletionRequestReason: DeletionRequestReason.TechnicalIssues,
    eventMessage: 'Issues using Special',
  },
  IncorrectAccount: {
    id: 3,
    text: 'I created an account with incorrect details',
    deletionRequestReason: DeletionRequestReason.IncorrectAccount,
    eventMessage: 'Created an account with incorrect details',
  },
  Other: {
    id: 4,
    text: 'Other',
    deletionRequestReason: DeletionRequestReason.Other,
    eventMessage: 'Other',
  },
};
