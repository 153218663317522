import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../../const/Colors';
import { map } from '../../../../../const/theme';
import { ReactComponent as Oops } from '../../../../../assets/svg/purchase/Oops.svg';
import { Button } from '../../../../../components/Button';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${map({ tablet: 'center' }, (alignment) => `align-items: ${alignment};`)}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6.2rem;
  position: absolute;
  width: 100%;
  height: 55rem;
  position: absolute;
  ${map({ tablet: '49.5rem' }, (width) => `width: ${width};`)}
  ${map({ tablet: '39.5rem' }, (height) => `height: ${height};`)}
  background: ${Colors.white};
  border: 0.2rem solid ${Colors.white};
  ${map({ tablet: Colors.black }, (x) => `border: 0.2rem solid ${x};`)}
  box-shadow: 0.9rem 0.9rem 0px ${Colors.black};
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  ${map({ tablet: '9px' }, (x) => `border-radius: ${x};`)}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${map({ tablet: 'row' }, (direction) => `flex-direction: ${direction};`)};
`;

const HeaderText = styled.div`
  font-weight: bold;
  font-size: 2.9rem;
  line-height: 3.5rem;
  text-align: center;
  color: ${Colors.black};
`;

const SubText = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 16px 0px;
  margin-bottom: 3rem;
`;

const OopsWrapper = styled.div`
  min-width: 9rem;
  margin-bottom: 1.7rem;
`;

interface OwnProps {
  onHide: () => void;
}
const SomethingWrong: React.FC<OwnProps> = ({ onHide }) => {
  return (
    <Wrapper>
      <Body>
        <Header>
          <OopsWrapper>
            <Oops height={90} width={90} style={{ minWidth: '9rem' }} />
          </OopsWrapper>
          <HeaderText>Oops! Something went wrong.</HeaderText>
        </Header>
        <SubText>
          Seems there's been an issue with your payment. Our apologies. Don't
          worry though, you can try again 👇
        </SubText>
        <Button
          title="TRY ANOTHER PAYMENT METHOD"
          role="Primary"
          onClick={onHide}
        />
      </Body>
    </Wrapper>
  );
};

export default SomethingWrong;
