import './logo.scss';

interface LogoProps {
  logoClass: string;
}

export const Logo = ({ logoClass, ...props }: LogoProps) => {
  return (
    <span className="special-logo" {...props}>
      <span className={logoClass} />
    </span>
  );
};
