import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const LogoWrapper = styled.div`
  position: absolute;
  animation: logo-animate-mobile 6s ease-in-out forwards 1 alternate;
  top: -11rem;
  ${map({ tablet: '-3rem' }, (top) => `top: ${top};`)}
  ${map(
    { tablet: 'logo-animate 6s linear forwards 1 alternate' },
    (animation) => `animation: ${animation};`
  )}

  @keyframes logo-animate-mobile {
    0% {
      transform: scale(1.5);
      top: -11rem;
    }

    50% {
      transform: scale(1.5);
      top: -11rem;
    }

    75% {
      transform: scale(1);
      top: -11rem;
    }

    100% {
      top: -12rem;
    }
  }

  @keyframes logo-animate {
    0% {
      transform: scale(1.5);
      top: -3rem;
    }

    50% {
      transform: scale(1.5);
      top: -3rem;
    }

    75% {
      transform: scale(1);
      top: -3rem;
    }

    100% {
      top: -5rem;
    }
  }
`;

export const Logo = styled.img`
  max-width: 16rem;
`;

export const AffiliationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  position: absolute;
  top: 80%;
  ${map({ tablet: '96%' }, (top) => `top: ${top};`)}
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: affiliation-animation-mobile 1.1s ease-in-out 5.5s 1 forwards
    alternate;
  ${map(
    {
      tablet:
        'affiliation-animation 1.21s ease-in-out 5.515s 1 forwards alternate',
    },
    (animation) => `animation: ${animation};`
  )}
  @keyframes affiliation-animation-mobile {
    from {
      top: 80%;
    }

    to {
      top: 25.5rem;
    }
  }
  @keyframes affiliation-animation {
    from {
      top: 96%;
    }

    to {
      top: 38.5rem;
    }
  }
`;

export const StaticAffilationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: ${Colors.black};
  margin-top: 3rem;
  ${map({ tablet: '5rem' }, (marginTop) => `margin-top: ${marginTop};`)}
  text-align: center;
`;
