import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../api/ApiProvider';
import { Button } from '../../../components/Button';
import { ErrorText } from '../../../components/ErrorText';
import { Input } from '../../../components/Input';
import {
  COMMON_ERR_MSG,
  EMAIL_REGEX,
  MOBILE_REGEX,
} from '../../../const/shared';
import { IOptInOutData } from '../../../context/OptInOutProvider';
import OptInWrapper from '../OptInWrapper';

interface IProps {}

const OptInInfo: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const onSubmit = async (data: IOptInOutData) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      await emailApi.sendOptInEmail(undefined, {
        ...data,
      });
      navigate('/claim-my-business/confirmation');
    } catch {
      alert.error(COMMON_ERR_MSG);
      setIsSubmitting(false);
    }
  };

  const { emailApi } = useApi();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      businessName: '',
      name: '',
      jobTitle: '',
      email: '',
      phoneNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  return (
    <OptInWrapper>
      <p className="header">Confirm your business information</p>
      <p className="desc">
        Please confirm that your business information is correct.
      </p>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-in-input"
            label="Business name"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.businessName}
          />
        )}
        name="businessName"
      />
      {errors.businessName && (
        <ErrorText text="Business name is required."></ErrorText>
      )}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-in-input"
            label="Your name"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.name}
          />
        )}
        name="name"
      />
      {errors.name && <ErrorText text="Your name is required."></ErrorText>}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-in-input"
            label="Your job title"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.jobTitle}
          />
        )}
        name="jobTitle"
      />
      {errors.jobTitle && (
        <ErrorText text="Your job title is required."></ErrorText>
      )}
      <Controller
        control={control}
        rules={{
          required: true,
          pattern: EMAIL_REGEX,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-in-input"
            label="Email"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value?.toLowerCase()}
            inputMode="email"
            error={!!errors.email}
          />
        )}
        name="email"
      />
      {errors.email && (
        <ErrorText
          text={
            errors.email.type === 'pattern'
              ? 'Your email is invalid.'
              : 'Your email is required.'
          }></ErrorText>
      )}
      <Controller
        control={control}
        rules={{
          pattern: MOBILE_REGEX,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            className="opt-in-input"
            label="Phone number (optional)"
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputMode="text"
            error={!!errors.phoneNumber}
          />
        )}
        name="phoneNumber"
      />
      {errors.phoneNumber && (
        <ErrorText text="Your phone number is invalid"></ErrorText>
      )}
      <Button
        title="CLAIM MY BUSINESS"
        disabled={!isValid}
        onClick={() => {
          handleSubmit((data) => {
            onSubmit(data);
          })();
        }}
        role="Secondary"
        style={{ marginTop: '2.5rem' }}
      />
    </OptInWrapper>
  );
};

export default OptInInfo;
