import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../../assets/svg/logo/Black.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/authWrapper/Close.svg';
import { ReactComponent as BackIcon } from '../../../assets/svg/authWrapper/BackArrow.svg';
import { SESSION_HAS_TIMED_OUT } from '../../../const/auth';

type TopLeftComponentType = 'Logo' | 'Close' | 'Back';
type Props = {
  className?: string;
  containerClassName?: string;
  topLeftComponent?: TopLeftComponentType;
};

const LogoImg = styled.img`
  cursor: pointer;
`;

const CloseStyle = styled.div`
  position: fixed;
  top: 3.2rem;
  left: 3.2rem;
  cursor: pointer;
`;

const BackWrapper = styled.div`
  position: fixed;
  top: 3.2rem;
  left: 3.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.7rem;
    text-transform: uppercase;
    margin-left: 0.9rem;
  }
`;

const AuthWrapper: React.FC<Props> = ({
  className = 'auth-page',
  containerClassName = 'auth-container',
  topLeftComponent = 'Back',
  children,
}) => {
  const navigate = useNavigate();
  const sessionStatus = useLocation().state;

  const getProperTopLeftComponent = useMemo(() => {
    if (topLeftComponent === 'Logo') {
      return (
        <LogoImg
          src={Logo}
          className="auth-logo"
          alt="logo"
          onClick={() => {
            navigate('/');
          }}
        />
      );
    } else if (topLeftComponent === 'Close') {
      return (
        <CloseStyle>
          <CloseIcon
            onClick={() => {
              if (sessionStatus === SESSION_HAS_TIMED_OUT) {
                navigate('/');
              } else {
                navigate(-1);
              }
            }}
          />
        </CloseStyle>
      );
    } else {
      return (
        <BackWrapper
          onClick={() => {
            if (sessionStatus === SESSION_HAS_TIMED_OUT) {
              navigate('/auth', { state: sessionStatus });
            } else {
              navigate(-1);
            }
          }}>
          <BackIcon />
          <span>BACK</span>
        </BackWrapper>
      );
    }
  }, [navigate, topLeftComponent, sessionStatus]);
  return (
    <div className={className}>
      {getProperTopLeftComponent}
      <div className={containerClassName}>{children}</div>
    </div>
  );
};

export default AuthWrapper;
