import React, { useEffect, useMemo, useState } from 'react';
import { useActivation } from '../../../context/ActivationProvider';
import ActivationTopBar from '../common/Topbar';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import { useNavigate } from 'react-router-dom';
import { ErrorText } from '../../../components/ErrorText';
import { useApi } from '../../../api/ApiProvider';
import {
  COMMON_ERR_MSG,
  DEFAULT_PIN_WARNING_MSG,
  SHOW_AFFILIATION_TEXT_IN_ANIMATION,
  SHOW_BRAND_LOGO_IN_ANIMATION,
  UNMATCHED_PIN_MSG,
} from '../../../const/shared';
import CardPin, { CardPinStatus } from '../../../components/CardPin';
import { Button } from '../../../components/Button';
import Breakpoints from '../../../const/breakpoints';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ArrowBack from '../../../components/ArrowBack';

import './styles.scss';
import Colors from '../../../const/Colors';
import { RudderStack } from '../../../services/shared/RudderStack';
import ActivationCard from '../common/ActivationCard';
import { useActivationBrands } from '../hooks/useActivationBrands';
import {
  ActivationTopBarWrapper,
  AffiliationText,
  Logo,
  LogoWrapper,
  StaticAffilationText,
} from '../common/styles';
import {
  ACTIVATION_AFFILIATION_TEXT,
  ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
} from '../constants';

type Props = {};

const ConfirmPin = (props: Props) => {
  const activation = useActivation();
  const { id, token, pin: initialPin } = activation.activationData;

  useEffect(() => {
    RudderStack.page('Activation_ConfirmPin');
  }, []);

  const navigate = useNavigate();
  const { giftCardWorkflowApi } = useApi();
  const [status, setStatus] = useState<CardPinStatus>();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPin, setConfirmPin] = useState('');
  const [showActivateButton, setShowActivateButton] = useState(false);

  const { windowWidth } = useWindowDimensions();

  const pinSize = useMemo(
    () => (windowWidth < Breakpoints.tablet ? 'small' : 'big'),
    [windowWidth]
  );

  const onComplete = (pin: string) => {
    if (pin === initialPin) {
      setShowActivateButton(true);
      setStatus('success');
      setConfirmPin(pin);
    } else {
      setShowActivateButton(false);
      setStatus('error');
    }
  };
  const onActivate = async () => {
    if (confirmPin === initialPin) {
      setStatus('success');
      setIsLoading(true);
      try {
        const response = await giftCardWorkflowApi.setPin(
          parseInt(id),
          token,
          undefined,
          { pin: initialPin }
        );
        const { isSuccessful, status: responseStatus } = response.data;
        setIsLoading(false);
        if (isSuccessful && responseStatus === 'Success') {
          RudderStack.track('Card Activated', {
            product_type: 'Digital Gift Card',
            order_id: activation.activationData.id,
            card_name:
              activation.activationData.activationDetails.cardProgramName ?? '',
            card_image_url:
              activation.activationData.activationDetails.frontImage ?? '',
            card_currency: 'AUD',
            card_value: +(
              activation.activationData.activationDetails.value ?? 0
            ),
          });
          navigate('/activate/success');
        } else {
          console.log(COMMON_ERR_MSG);
          navigate('/activate/failed');
        }
      } catch (error) {
        console.log(`${COMMON_ERR_MSG}: `, error);
        setIsLoading(false);
        setStatus('success');
        navigate('/activate/failed');
      }
    } else {
      setStatus('error');
    }
  };

  const {
    affiliationTextColour,
    backgroundColor,
    displayAffiliationText,
    logo,
  } = useActivationBrands();

  return (
    <div className="activationConfirmPin" style={{ backgroundColor }}>
      <ActivationTopBarWrapper>
        <ActivationTopBar showFAQ={false} backText="BACK" />
      </ActivationTopBarWrapper>
      <div className="nonTopBack">
        <ArrowBack text="BACK" />
      </div>
      {SHOW_BRAND_LOGO_IN_ANIMATION && (
        <LogoWrapper>
          <Logo src={logo || ''} alt="" />
        </LogoWrapper>
      )}
      <div className="confirmPinCardWrapper">
        <ActivationCard />
      </div>
      {SHOW_AFFILIATION_TEXT_IN_ANIMATION && displayAffiliationText && (
        <AffiliationText
          style={{
            color:
              affiliationTextColour ||
              ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
          }}>
          {ACTIVATION_AFFILIATION_TEXT}
        </AffiliationText>
      )}
      <Border height="62" fill={Colors.white} className="scallop" />

      <div className="pinSection">
        <div className="pinSectionWrapper">
          <div className="title">Confirm PIN to activate Card</div>

          <div className="pinInput">
            <CardPin
              length={4}
              placeholder=""
              onComplete={onComplete}
              hideValue
              status={status}
              size={pinSize}
            />
            {status === 'error' && (
              <div className="pinErrorText">
                <ErrorText text={UNMATCHED_PIN_MSG} />
              </div>
            )}
            {showActivateButton && (
              <div className="activateButton">
                <Button
                  title="ACTIVATE"
                  startIconClass="heart-icon"
                  onClick={onActivate}
                  role="Primary"
                  loading={isLoading}
                />
              </div>
            )}
          </div>

          <div className="pinWarning">{DEFAULT_PIN_WARNING_MSG}</div>
          {displayAffiliationText && (
            <StaticAffilationText>
              {ACTIVATION_AFFILIATION_TEXT}
            </StaticAffilationText>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmPin;
