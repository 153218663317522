import * as React from 'react';
import { SVGProps } from 'react';
import Colors from '../../../const/Colors';
import { IsMobileView } from '../../../util/helper';
import ArrowBack from '../../ArrowBack';
import {
  WavesWrapper,
  ContentWrapper,
  LogoWrapper,
  Logo,
  BackWrapper,
} from './styles';

interface OwnProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  backTextColor?: string;
  logoUrl?: string;
  to?: string;
}

const SvgWaves: React.FC<OwnProps> = ({
  fill = Colors.seaGreen,
  backTextColor = Colors.black,
  logoUrl,
  to,
  ...props
}) => {
  const isMobile = IsMobileView();
  return (
    <WavesWrapper>
      {!isMobile ? (
        <svg viewBox="0 70 1440 359" fill="none" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m.276 335.017 59.989-.004c59.988-.003 179.965-.009 299.932-33.443 119.967-33.434 239.923-100.296 359.892-128.954 119.968-28.659 239.948-19.115 359.931 9.531 119.99 28.645 239.98 76.392 299.97 100.266l60 23.873-.07-229.217-59.99.004-299.94.016-359.93.02-359.931.019-299.942.016-59.989.003.078 257.87Z"
            fill={fill}
            fillOpacity={0.6}
          />
          <path
            opacity={0.5}
            fillRule="evenodd"
            clipRule="evenodd"
            d="m1440.06 147.645-59.98 21.444c-59.98 21.444-179.94 64.332-299.91 78.632-119.97 14.301-239.948.013-359.923-7.127-119.975-7.141-239.949-7.134-359.913 21.46s-240.032 69.367-300.01 97.958L.346 388.602.341 19.079l59.986-.003 299.933-.017 359.92-.019 359.92-.02 299.93-.016 59.99-.003.04 128.644Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m.077 276.884 59.997-.003c59.996-.004 179.99-.01 299.973-33.431s239.956-100.256 359.941-128.903c119.984-28.648 239.981-19.107 359.982 9.527 120 28.634 240.01 76.363 300.01 100.227l60.01 23.864L1440-.077l-60 .003-299.98.016-359.983.02-359.98.02-299.983.016L.077 0v276.883Z"
            fill={fill}
          />
        </svg>
      ) : (
        <svg viewBox="0 0 414 248" fill="none" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m-202.768 223.053 50.315-.003c50.315-.003 150.944-.008 251.565-28.051 100.621-28.042 201.234-84.122 301.856-108.159 100.623-24.037 201.255-16.032 301.891 7.995 100.637 24.026 201.279 64.073 251.6 84.097l50.321 20.024-.06-192.254-50.314.003a2570737128.97 2570737128.97 0 0 1-553.462.03L99.055 6.75c-100.63.005-201.259.01-251.574.014l-50.314.002.065 216.286Z"
            fill={fill}
            fillOpacity={0.6}
          />
          <path
            opacity={0.5}
            fillRule="evenodd"
            clipRule="evenodd"
            d="m905.835 72.898-50.308 17.986c-50.307 17.986-150.923 53.958-251.546 65.952-100.622 11.994-201.252.011-301.881-5.978-100.628-5.989-201.254-5.983-301.874 18-100.619 23.983-201.324 58.181-251.63 82.161l-50.306 23.98-.005-309.935 50.314-.002L.165-34.952l301.879-.016 301.879-.017 251.566-.013 50.313-.003.033 107.9Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m-52.925 131.295 50.322-.003c50.321-.003 150.965-.008 251.599-28.039C349.631 75.221 450.258 19.163 550.894-4.864 651.53-28.892 752.175-20.89 852.826 3.127c100.651 24.017 201.304 64.049 251.634 84.064l50.33 20.016-.06-192.178-50.32.003-251.611.013-301.93.017-301.93.016-251.608.014-50.322.002.066 216.201Z"
            fill={fill}
          />
        </svg>
      )}
      <ContentWrapper>
        <BackWrapper>
          <ArrowBack
            text="BACK"
            textColor={backTextColor}
            to={to}
            arrowColor={backTextColor}
          />
        </BackWrapper>
        <LogoWrapper>
          <Logo src={logoUrl} alt="" />
        </LogoWrapper>
      </ContentWrapper>
    </WavesWrapper>
  );
};

export default SvgWaves;
