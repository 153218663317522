import React from 'react';
import BottomStars from './BottomStars';
import TopStars from './TopStars';
import styled from 'styled-components';

import './styles.scss';
import { map } from '../../const/theme';

const TopStarsStyle = styled.div`
  position: absolute;
  width: 5rem;
  top: -4rem;
  left: -4rem;
  ${map({ tablet: '7rem', laptop: '10rem' }, (size) => `width: ${size};`)}
  ${map({ tablet: '-6rem', laptop: '-8rem' }, (space) => `top: ${space};`)}
    ${map({ tablet: '-6rem', laptop: '-7rem' }, (space) => `left: ${space};`)}
`;

const BottomStarsStyle = styled.div`
  position: absolute;
  right: -10rem;
  width: 5rem;
  bottom: -3rem;
  right: -5rem;
  ${map({ tablet: '7rem', laptop: '10rem' }, (size) => `width: ${size};`)}
  ${map({ tablet: '-5rem', laptop: '-7rem' }, (space) => `bottom: ${space};`)}
    ${map({ tablet: '-7rem', laptop: '-9rem' }, (space) => `right: ${space};`)}
`;
interface OwnProps {
  topStarsColor?: string;
  bottomStarsColor?: string;
  imageURL?: string | null;
  cardRotation?: number;
  borderRadius?: number;
  topStarHeight?: number;
  topStarWidth?: number;
  topStarTopPosition?: number;
  topStarLeftPosition?: number;
  bottomStarHeight?: number;
  bottomStarWidth?: number;
  bottomStarBottomPosition?: number;
  bottomStarRightPosition?: number;
}
const DynamicCard: React.FC<OwnProps> = ({
  topStarsColor,
  bottomStarsColor,
  imageURL,
  cardRotation = -17,
  borderRadius = 11,
  topStarHeight,
  topStarWidth,
  topStarTopPosition,
  topStarLeftPosition,
  bottomStarHeight,
  bottomStarWidth,
  bottomStarBottomPosition,
  bottomStarRightPosition,
}) => {
  return (
    <div className="dynamicCard">
      <TopStarsStyle
        style={{
          height: topStarHeight,
          width: topStarWidth,
          top: topStarTopPosition,
          left: topStarLeftPosition,
        }}>
        <TopStars color={topStarsColor} />
      </TopStarsStyle>
      <div
        className="card"
        style={{
          transform: `rotate(${cardRotation}deg)`,
        }}>
        <img src={imageURL || ''} alt="Gift Card" style={{ borderRadius }} />
      </div>
      <BottomStarsStyle
        style={{
          height: bottomStarHeight,
          width: bottomStarWidth,
          bottom: bottomStarBottomPosition,
          right: bottomStarRightPosition,
        }}>
        <BottomStars color={bottomStarsColor} />
      </BottomStarsStyle>
    </div>
  );
};

export default DynamicCard;
