import momentTz from 'moment-timezone';

export const DEFAULT_TIMEZONE = 'Australia/Melbourne';

export const BANNER_EXPIRY_DATE = momentTz.tz(
  '2022-09-04 23:59:59',
  'Australia/Melbourne'
);

export const BANNER_START_DATE = momentTz.tz(
  '2022-08-03 23:59:59',
  'Australia/Melbourne'
);
