export const GoldConfetti = {
  v: '5.6.5',
  fr: 30,
  ip: 0,
  op: 120,
  w: 1920,
  h: 1080,
  nm: 'Main Comp',
  ddd: 1,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [17],
                },
                { t: 99.0000040323527, s: [85] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.469 },
                  o: { x: 0.271, y: 0 },
                  t: 9,
                  s: [1449.379, -34.734, 0],
                  to: [0, 9.882, 0],
                  ti: [0, -255.833, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.39 },
                  t: 39,
                  s: [1529.379, 452.206, 0],
                  to: [0, 358.635, 0],
                  ti: [0, -7.782, 0],
                },
                { t: 69.0000028104276, s: [1449.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 2,
                  s: [53],
                },
                { t: 92.0000037472368, s: [108] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.428 },
                  o: { x: 0.285, y: 0 },
                  t: 2,
                  s: [1129.379, -34.734, 0],
                  to: [0, 5.775, 0],
                  ti: [0, -114.862, 0],
                },
                {
                  i: { x: 0.679, y: 0.646 },
                  o: { x: 0.329, y: 0.267 },
                  t: 19,
                  s: [1073.379, 163.86, 0],
                  to: [0, 131.21, 0],
                  ti: [-9.561, -174.942, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.432, y: 0.414 },
                  t: 40,
                  s: [1170.496, 649.38, 0],
                  to: [16.468, 301.316, 0],
                  ti: [0, -6.383, 0],
                },
                { t: 62.0000025253118, s: [1129.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2.00000008146167,
          op: 152.000006191087,
          st: 2.00000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 11,
                  s: [37],
                },
                { t: 101.000004113814, s: [103] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.61, y: 0.4 },
                  o: { x: 0.253, y: 0 },
                  t: 11,
                  s: [889.379, -154.734, 0],
                  to: [0, 0.425, 0],
                  ti: [-22.792, -143.026, 0],
                },
                {
                  i: { x: 0.667, y: 0.65 },
                  o: { x: 0.324, y: 0.284 },
                  t: 29,
                  s: [891.833, 87.938, 0],
                  to: [15.968, 100.206, 0],
                  ti: [0, -124.298, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.424, y: 0.409 },
                  t: 44,
                  s: [969.379, 434.15, 0],
                  to: [0, 332.779, 0],
                  ti: [0, -6.993, 0],
                },
                { t: 71.0000028918893, s: [889.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 11.0000004480392,
          op: 161.000006557664,
          st: 11.0000004480392,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5,
                  s: [41],
                },
                { t: 95.0000038694293, s: [142] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.621, y: 0.444 },
                  o: { x: 0.262, y: 0 },
                  t: 5,
                  s: [867.734, -64.266, 0],
                  to: [0, 11.744, 0],
                  ti: [0, -162.479, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.396, y: 0.336 },
                  t: 26,
                  s: [787.734, 230.195, 0],
                  to: [0, 375.382, 0],
                  ti: [0, -9.306, 0],
                },
                { t: 65.0000026475043, s: [867.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 155.000006313279,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 18,
                  s: [56],
                },
                { t: 108.00000439893, s: [91] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.463 },
                  o: { x: 0.272, y: 0 },
                  t: 18,
                  s: [1426.02, -93.98, 0],
                  to: [1.001, 29.035, 0],
                  ti: [0.128, -244.325, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.391 },
                  t: 48,
                  s: [1475.083, 392.552, 0],
                  to: [-0.188, 359.115, 0],
                  ti: [0, -7.935, 0],
                },
                { t: 78.0000031770051, s: [1426.02, 1186.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 18.000000733155,
          op: 168.00000684278,
          st: 18.000000733155,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [6],
                },
                { t: 102.000004154545, s: [41] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.624, y: 0.427 },
                  o: { x: 0.274, y: 0 },
                  t: 12,
                  s: [868.34, -31.66, 0],
                  to: [0, 3.069, 0],
                  ti: [-14.839, -122.106, 0],
                },
                {
                  i: { x: 0.703, y: 0.676 },
                  o: { x: 0.334, y: 0.264 },
                  t: 29,
                  s: [815.34, 176.277, 0],
                  to: [23.221, 191.078, 0],
                  ti: [0, -229.161, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 58,
                  s: [948.34, 883.938, 0],
                  to: [0, 203.602, 0],
                  ti: [0, -3.189, 0],
                },
                { t: 72.0000029326201, s: [868.34, 1248.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 10,
                  s: [156],
                },
                { t: 100.000004073084, s: [191] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.663, y: 0.523 },
                  o: { x: 0.269, y: 0 },
                  t: 10,
                  s: [1123.598, -53.402, 0],
                  to: [-2.715, 38.01, 0],
                  ti: [-0.72, -318.195, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 48,
                  s: [1281.689, 638.799, 0],
                  to: [0.675, 298.506, 0],
                  ti: [0, -6.454, 0],
                },
                { t: 70.0000028511585, s: [1123.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10.0000004073083,
          op: 160.000006516934,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [185],
                },
                { t: 94.0000038286985, s: [220] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.625, y: 0.428 },
                  o: { x: 0.26, y: 0 },
                  t: 4,
                  s: [1243.598, -53.402, 0],
                  to: [-0.681, 9.529, 0],
                  ti: [-23.715, -196.552, 0],
                },
                {
                  i: { x: 0.676, y: 0.666 },
                  o: { x: 0.336, y: 0.304 },
                  t: 28,
                  s: [1253.71, 305.086, 0],
                  to: [11.99, 99.375, 0],
                  ti: [-0.091, -109.184, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.422 },
                  t: 41,
                  s: [1322.878, 624.304, 0],
                  to: [0.255, 304.892, 0],
                  ti: [0, -4.017, 0],
                },
                { t: 64.0000026067734, s: [1243.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [148],
                },
                { t: 90.0000036657751, s: [183] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.69, y: 0.578 },
                  o: { x: 0.257, y: 0 },
                  t: 0,
                  s: [824.75, -34.336, 0],
                  to: [0.533, 15.452, 0],
                  ti: [0.177, -372.851, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 46,
                  s: [744.95, 880.207, 0],
                  to: [-0.097, 203.942, 0],
                  ti: [0, -3.466, 0],
                },
                { t: 60.0000024438501, s: [824.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [-19],
                },
                { t: 94.0000038286985, s: [22] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.376 },
                  o: { x: 0.285, y: 0 },
                  t: 4,
                  s: [529.379, -34.734, 0],
                  to: [0, 5.741, 0],
                  ti: [36.579, -113.749, 0],
                },
                {
                  i: { x: 0.679, y: 0.653 },
                  o: { x: 0.33, y: 0.269 },
                  t: 21,
                  s: [498.92, 161.829, 0],
                  to: [-42.913, 133.444, 0],
                  ti: [0, -178.445, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 42,
                  s: [369.379, 657.638, 0],
                  to: [0, 298.302, 0],
                  ti: [0, -6.325, 0],
                },
                { t: 64.0000026067734, s: [529.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 6,
                  s: [13],
                },
                { t: 96.0000039101602, s: [64] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.619, y: 0.465 },
                  o: { x: 0.237, y: 0 },
                  t: 6,
                  s: [809.379, -154.734, 0],
                  to: [0, 0.625, 0],
                  ti: [0, -252.608, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.414, y: 0.386 },
                  t: 34,
                  s: [889.379, 312.208, 0],
                  to: [0, 362.539, 0],
                  ti: [0, -7.858, 0],
                },
                { t: 66.0000026882351, s: [809.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 156.00000635401,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Shape Layer 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 11,
                  s: [17],
                },
                { t: 101.000004113814, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.621, y: 0.377 },
                  o: { x: 0.269, y: 0 },
                  t: 11,
                  s: [707.734, -64.266, 0],
                  to: [0, 9.595, 0],
                  ti: [39.322, -118.316, 0],
                },
                {
                  i: { x: 0.673, y: 0.655 },
                  o: { x: 0.325, y: 0.274 },
                  t: 28,
                  s: [677.734, 145.67, 0],
                  to: [-40.655, 122.326, 0],
                  ti: [0, -159.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.407 },
                  t: 47,
                  s: [547.734, 589.09, 0],
                  to: [0, 312.184, 0],
                  ti: [0, -6.64, 0],
                },
                { t: 71.0000028918893, s: [707.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 11.0000004480392,
          op: 161.000006557664,
          st: 11.0000004480392,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 11,
                  s: [35],
                },
                { t: 101.000004113814, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.627, y: 0.437 },
                  o: { x: 0.255, y: 0 },
                  t: 11,
                  s: [427.734, -64.266, 0],
                  to: [0, 14.806, 0],
                  ti: [45.608, -226.932, 0],
                },
                {
                  i: { x: 0.699, y: 0.698 },
                  o: { x: 0.349, y: 0.313 },
                  t: 38,
                  s: [406.702, 364.719, 0],
                  to: [-31.883, 158.639, 0],
                  ti: [0, -152.073, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.401 },
                  t: 57,
                  s: [267.734, 853.462, 0],
                  to: [0, 201.592, 0],
                  ti: [0, -4.706, 0],
                },
                { t: 71.0000028918893, s: [427.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 11.0000004480392,
          op: 161.000006557664,
          st: 11.0000004480392,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [-19],
                },
                { t: 93.0000037879676, s: [16] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.387 },
                  o: { x: 0.284, y: 0 },
                  t: 3,
                  s: [986.02, -133.98, 0],
                  to: [0.598, 17.353, 0],
                  ti: [37.228, -116.241, 0],
                },
                {
                  i: { x: 0.68, y: 0.664 },
                  o: { x: 0.332, y: 0.268 },
                  t: 21,
                  s: [994.546, 82.741, 0],
                  to: [-42.194, 131.75, 0],
                  ti: [0.18, -169.275, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 41,
                  s: [826.916, 558.055, 0],
                  to: [-0.318, 298.557, 0],
                  ti: [0, -6.45, 0],
                },
                { t: 63.0000025660426, s: [986.02, 1146.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 153.000006231818,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 6,
                  s: [17],
                },
                { t: 96.0000039101602, s: [52] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.426 },
                  o: { x: 0.283, y: 0 },
                  t: 6,
                  s: [632.34, -71.66, 0],
                  to: [0, 9.589, 0],
                  ti: [-14.999, -122.689, 0],
                },
                {
                  i: { x: 0.704, y: 0.677 },
                  o: { x: 0.34, y: 0.267 },
                  t: 24,
                  s: [579.694, 146.125, 0],
                  to: [23.168, 189.513, 0],
                  ti: [0, -224.182, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.434 },
                  t: 52,
                  s: [712.34, 838.048, 0],
                  to: [0, 205.461, 0],
                  ti: [0, -4.766, 0],
                },
                { t: 66.0000026882351, s: [632.34, 1208.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 156.00000635401,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 6,
                  s: [27],
                },
                { t: 96.0000039101602, s: [62] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.62, y: 0.45 },
                  o: { x: 0.269, y: 0 },
                  t: 6,
                  s: [443.598, -13.402, 0],
                  to: [-0.62, 8.681, 0],
                  ti: [0.118, -120.21, 0],
                },
                {
                  i: { x: 0.687, y: 0.658 },
                  o: { x: 0.328, y: 0.269 },
                  t: 23,
                  s: [362.548, 198.697, 0],
                  to: [-0.157, 160.398, 0],
                  ti: [-17.705, -203.006, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.426 },
                  t: 48,
                  s: [475.92, 788.378, 0],
                  to: [22.341, 256.165, 0],
                  ti: [0, -2.942, 0],
                },
                { t: 66.0000026882351, s: [443.598, 1266.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 156.00000635401,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 7,
                  s: [34],
                },
                { t: 97.000003950891, s: [153] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.449 },
                  o: { x: 0.285, y: 0 },
                  t: 7,
                  s: [279.598, -53.402, 0],
                  to: [-1.202, 16.834, 0],
                  ti: [0.292, -106.297, 0],
                },
                {
                  i: { x: 0.687, y: 0.652 },
                  o: { x: 0.332, y: 0.264 },
                  t: 24,
                  s: [357.451, 145.009, 0],
                  to: [-0.416, 151.641, 0],
                  ti: [15.989, -198.244, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.423 },
                  t: 48,
                  s: [247.081, 709.658, 0],
                  to: [-21.851, 270.927, 0],
                  ti: [0, -5.94, 0],
                },
                { t: 67.0000027289659, s: [279.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7.00000028511585,
          op: 157.000006394741,
          st: 7.00000028511585,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [47],
                },
                { t: 99.0000040323527, s: [82] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.641, y: 0.46 },
                  o: { x: 0.273, y: 0 },
                  t: 9,
                  s: [384.75, -34.336, 0],
                  to: [1.162, 33.703, 0],
                  ti: [0.087, -219.458, 0],
                },
                {
                  i: { x: 0.707, y: 0.694 },
                  o: { x: 0.354, y: 0.324 },
                  t: 37,
                  s: [466.119, 403.941, 0],
                  to: [-0.068, 169.484, 0],
                  ti: [18.588, -156.525, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.435, y: 0.435 },
                  t: 57,
                  s: [382.228, 919.916, 0],
                  to: [-21.808, 183.632, 0],
                  ti: [0, -4.51, 0],
                },
                { t: 69.0000028104276, s: [384.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: 'Adjustment Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [959, 530, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [-101, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 1',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [0],
                },
                { t: 63.0000025660426, s: [360] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -10,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 83.0000033806593, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 0.485 },
                  o: { x: 0.296, y: 0 },
                  t: 3,
                  s: [529.379, -34.734, 0],
                  to: [0, 23.736, 0],
                  ti: [0, -253.33, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.405 },
                  t: 37,
                  s: [609.379, 478.693, 0],
                  to: [0, 300.554, 0],
                  ti: [0, -21.581, 0],
                },
                { t: 63.0000025660426, s: [529.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [56, 56, 56], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 153.000006231818,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 2',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [0],
                },
                { t: 64.0000026067734, s: [360] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -4,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 89.0000036250443, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.63, y: 0.461 },
                  o: { x: 0.248, y: 0 },
                  t: 4,
                  s: [409.379, -114.734, 0],
                  to: [0, 1.522, 0],
                  ti: [33.721, -260.13, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.393 },
                  t: 34,
                  s: [386.079, 375.007, 0],
                  to: [-44.709, 344.885, 0],
                  ti: [0, -4.17, 0],
                },
                { t: 64.0000026067734, s: [249.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [56, 56, 56], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 3',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 14,
                  s: [0],
                },
                { t: 87.0000035435826, s: [270] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 95.0000038694293, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.652, y: 0.487 },
                  o: { x: 0.27, y: 0 },
                  t: 14,
                  s: [427.734, -64.266, 0],
                  to: [0, 38.876, 0],
                  ti: [0, -269.037, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.427, y: 0.408 },
                  t: 55,
                  s: [507.734, 497.415, 0],
                  to: [0, 297.088, 0],
                  ti: [0, -65.524, 0],
                },
                { t: 87.0000035435826, s: [427.734, 1175.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [67, 67, 67], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 14.0000005702317,
          op: 164.000006679857,
          st: 14.0000005702317,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 4',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 23,
                  s: [0],
                },
                { t: 75.0000030548126, s: [360] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 98.0000039916218, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.658, y: 0.45 },
                  o: { x: 0.297, y: 0 },
                  t: 23,
                  s: [746.02, -53.98, 0],
                  to: [2.473, 71.711, 0],
                  ti: [-5.935, -172.109, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.423, y: 0.379 },
                  t: 48,
                  s: [719.652, 341.348, 0],
                  to: [10.065, 291.897, 0],
                  ti: [-4.194, -121.622, 0],
                },
                { t: 75.0000030548126, s: [786.02, 1106.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [61, 61, 61], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 23.0000009368092,
          op: 173.000007046434,
          st: 23.0000009368092,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 5',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 1,
                  s: [0],
                },
                { t: 72.0000029326201, s: [43] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -5,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 88.0000035843135, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.665, y: 0.473 },
                  o: { x: 0.296, y: 0 },
                  t: 1,
                  s: [632.34, -31.66, 0],
                  to: [0, 37.975, 0],
                  ti: [0, -244.601, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.428, y: 0.403 },
                  t: 40,
                  s: [672.34, 474.069, 0],
                  to: [0, 299.285, 0],
                  ti: [0, -63.589, 0],
                },
                { t: 72.0000029326201, s: [632.34, 1168.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [56, 56, 56], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1.00000004073083,
          op: 151.000006150356,
          st: 1.00000004073083,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 6',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: { a: 0, k: 0, ix: 10 },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.66, y: 0.456 },
                  o: { x: 0.297, y: 0 },
                  t: 10,
                  s: [443.598, -13.402, 0],
                  to: [-5.262, 73.669, 0],
                  ti: [12.805, -179.267, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.425, y: 0.388 },
                  t: 55,
                  s: [446.039, 400.424, 0],
                  to: [-19.641, 274.97, 0],
                  ti: [8.071, -112.998, 0],
                },
                { t: 99.0000040323527, s: [363.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [70, 70, 70], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10.0000004073083,
          op: 160.000006516934,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 7',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: { a: 0, k: 0, ix: 10 },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -12,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 81.0000032991976, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.297, y: 0 },
                  t: 6,
                  s: [643.598, -53.402, 0],
                  to: [-5.262, 73.669, 0],
                  ti: [8.071, -112.998, 0],
                },
                { t: 78.0000031770051, s: [723.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [60, 60, 60], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 156.00000635401,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 8',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: { a: 0, k: 0, ix: 10 },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 93.0000037879676, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: { a: 0, k: [960, 540, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [56, 56, 56], ix: 6 },
          },
          ao: 0,
          shapes: [],
          ip: 10.0000004073083,
          op: 160.000006516934,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 9',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [0],
                },
                { t: 78.0000031770051, s: [70] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.669, y: 0.483 },
                  o: { x: 0.296, y: 0 },
                  t: 9,
                  s: [384.75, -34.336, 0],
                  to: [3.113, 90.268, 0],
                  ti: [-7.761, -225.076, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.41 },
                  t: 49,
                  s: [363.097, 497.714, 0],
                  to: [8.862, 256.987, 0],
                  ti: [-3.554, -103.066, 0],
                },
                { t: 78.0000031770051, s: [424.75, 1125.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [56, 56, 56], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 1,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 10,
                  s: [0],
                },
                { t: 70.0000028511585, s: [360] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 0.485 },
                  o: { x: 0.296, y: 0 },
                  t: 10,
                  s: [529.379, -34.734, 0],
                  to: [0, 23.736, 0],
                  ti: [0, -253.33, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.405 },
                  t: 44,
                  s: [609.379, 478.693, 0],
                  to: [0, 300.554, 0],
                  ti: [0, -21.581, 0],
                },
                { t: 70.0000028511585, s: [529.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10.0000004073083,
          op: 160.000006516934,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5,
                  s: [0],
                },
                { t: 65.0000026475043, s: [360] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.63, y: 0.461 },
                  o: { x: 0.248, y: 0 },
                  t: 5,
                  s: [409.379, -114.734, 0],
                  to: [0, 1.522, 0],
                  ti: [33.721, -260.13, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.393 },
                  t: 35,
                  s: [386.079, 375.007, 0],
                  to: [-44.709, 344.885, 0],
                  ti: [0, -4.17, 0],
                },
                { t: 65.0000026475043, s: [249.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 155.000006313279,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [0],
                },
                { t: 82.0000033399285, s: [270] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.652, y: 0.487 },
                  o: { x: 0.27, y: 0 },
                  t: 9,
                  s: [427.734, -64.266, 0],
                  to: [0, 38.876, 0],
                  ti: [0, -269.037, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.427, y: 0.408 },
                  t: 50,
                  s: [507.734, 497.415, 0],
                  to: [0, 297.088, 0],
                  ti: [0, -65.524, 0],
                },
                { t: 82.0000033399285, s: [427.734, 1175.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 15,
                  s: [0],
                },
                { t: 67.0000027289659, s: [360] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.658, y: 0.45 },
                  o: { x: 0.297, y: 0 },
                  t: 15,
                  s: [746.02, -53.98, 0],
                  to: [2.473, 71.711, 0],
                  ti: [-5.935, -172.109, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.423, y: 0.379 },
                  t: 40,
                  s: [719.652, 341.348, 0],
                  to: [10.065, 291.897, 0],
                  ti: [-4.194, -121.622, 0],
                },
                { t: 67.0000027289659, s: [786.02, 1106.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 15.0000006109625,
          op: 165.000006720588,
          st: 15.0000006109625,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [0],
                },
                { t: 74.0000030140818, s: [43] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.665, y: 0.473 },
                  o: { x: 0.296, y: 0 },
                  t: 3,
                  s: [632.34, -31.66, 0],
                  to: [0, 37.975, 0],
                  ti: [0, -244.601, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.428, y: 0.403 },
                  t: 42,
                  s: [672.34, 474.069, 0],
                  to: [0, 299.285, 0],
                  ti: [0, -63.589, 0],
                },
                { t: 74.0000030140818, s: [632.34, 1168.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 153.000006231818,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: { a: 0, k: 0, ix: 10 },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.66, y: 0.456 },
                  o: { x: 0.297, y: 0 },
                  t: 10,
                  s: [443.598, -13.402, 0],
                  to: [-5.262, 73.669, 0],
                  ti: [12.805, -179.267, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.425, y: 0.388 },
                  t: 55,
                  s: [446.039, 400.424, 0],
                  to: [-19.641, 274.97, 0],
                  ti: [8.071, -112.998, 0],
                },
                { t: 99.0000040323527, s: [363.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10.0000004073083,
          op: 160.000006516934,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: { a: 0, k: 0, ix: 10 },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.297, y: 0 },
                  t: 15,
                  s: [643.598, -53.402, 0],
                  to: [-5.262, 73.669, 0],
                  ti: [8.071, -112.998, 0],
                },
                { t: 87.0000035435826, s: [723.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 15.0000006109625,
          op: 165.000006720588,
          st: 15.0000006109625,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: { a: 0, k: 0, ix: 10 },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: { a: 0, k: [960, 540, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [],
          ip: 7.00000028511585,
          op: 157.000006394741,
          st: 7.00000028511585,
          bm: 0,
        },
        {
          ddd: 1,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            rx: { a: 0, k: 0, ix: 8 },
            ry: { a: 0, k: 0, ix: 9 },
            rz: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [0],
                },
                { t: 78.0000031770051, s: [70] },
              ],
              ix: 10,
            },
            or: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [0, 0, 0],
                  to: [0, 0, 0],
                  ti: [24, 0, 0],
                },
                { t: 90.0000036657751, s: [17, 24, 29] },
              ],
              ix: 7,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.669, y: 0.483 },
                  o: { x: 0.296, y: 0 },
                  t: 9,
                  s: [384.75, -34.336, 0],
                  to: [3.113, 90.268, 0],
                  ti: [-7.761, -225.076, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.41 },
                  t: 49,
                  s: [363.097, 497.714, 0],
                  to: [8.862, 256.987, 0],
                  ti: [-3.554, -103.066, 0],
                },
                { t: 78.0000031770051, s: [424.75, 1125.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                { t: 60.0000024438501, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 0.485 },
                  o: { x: 0.296, y: 0 },
                  t: 0,
                  s: [529.379, -34.734, 0],
                  to: [0, 23.736, 0],
                  ti: [0, -253.33, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.405 },
                  t: 34,
                  s: [609.379, 478.693, 0],
                  to: [0, 300.554, 0],
                  ti: [0, -21.581, 0],
                },
                { t: 60.0000024438501, s: [529.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 10,
                  s: [0],
                },
                { t: 70.0000028511585, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.63, y: 0.461 },
                  o: { x: 0.248, y: 0 },
                  t: 10,
                  s: [409.379, -114.734, 0],
                  to: [0, 1.522, 0],
                  ti: [33.721, -260.13, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.393 },
                  t: 40,
                  s: [386.079, 375.007, 0],
                  to: [-44.709, 344.885, 0],
                  ti: [0, -4.17, 0],
                },
                { t: 70.0000028511585, s: [249.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10.0000004073083,
          op: 160.000006516934,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [0],
                },
                { t: 77.0000031362743, s: [270] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.652, y: 0.487 },
                  o: { x: 0.27, y: 0 },
                  t: 4,
                  s: [427.734, -64.266, 0],
                  to: [0, 38.876, 0],
                  ti: [0, -269.037, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.427, y: 0.408 },
                  t: 45,
                  s: [507.734, 497.415, 0],
                  to: [0, 297.088, 0],
                  ti: [0, -65.524, 0],
                },
                { t: 77.0000031362743, s: [427.734, 1175.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [0],
                },
                { t: 60.0000024438501, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.658, y: 0.45 },
                  o: { x: 0.297, y: 0 },
                  t: 8,
                  s: [746.02, -53.98, 0],
                  to: [2.473, 71.711, 0],
                  ti: [-5.935, -172.109, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.423, y: 0.379 },
                  t: 33,
                  s: [719.652, 341.348, 0],
                  to: [10.065, 291.897, 0],
                  ti: [-4.194, -121.622, 0],
                },
                { t: 60.0000024438501, s: [786.02, 1106.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.00000032584668,
          op: 158.000006435472,
          st: 8.00000032584668,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [0],
                },
                { t: 79.000003217736, s: [43] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.665, y: 0.473 },
                  o: { x: 0.296, y: 0 },
                  t: 8,
                  s: [632.34, -31.66, 0],
                  to: [0, 37.975, 0],
                  ti: [0, -244.601, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.428, y: 0.403 },
                  t: 47,
                  s: [672.34, 474.069, 0],
                  to: [0, 299.285, 0],
                  ti: [0, -63.589, 0],
                },
                { t: 79.000003217736, s: [632.34, 1168.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.00000032584668,
          op: 158.000006435472,
          st: 8.00000032584668,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.66, y: 0.456 },
                  o: { x: 0.297, y: 0 },
                  t: 0,
                  s: [443.598, -13.402, 0],
                  to: [-5.262, 73.669, 0],
                  ti: [12.805, -179.267, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.425, y: 0.388 },
                  t: 45,
                  s: [446.039, 400.424, 0],
                  to: [-19.641, 274.97, 0],
                  ti: [8.071, -112.998, 0],
                },
                { t: 89.0000036250443, s: [363.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.297, y: 0 },
                  t: 0,
                  s: [643.598, -53.402, 0],
                  to: [-5.262, 73.669, 0],
                  ti: [8.071, -112.998, 0],
                },
                { t: 72.0000029326201, s: [723.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 540, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                { t: 69.0000028104276, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.669, y: 0.483 },
                  o: { x: 0.296, y: 0 },
                  t: 0,
                  s: [384.75, -34.336, 0],
                  to: [3.113, 90.268, 0],
                  ti: [-7.761, -225.076, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.41 },
                  t: 40,
                  s: [363.097, 497.714, 0],
                  to: [8.862, 256.987, 0],
                  ti: [-3.554, -103.066, 0],
                },
                { t: 69.0000028104276, s: [424.75, 1125.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 71, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [0],
                },
                { t: 80.0000032584668, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.652, y: 0.48 },
                  o: { x: 0.27, y: 0 },
                  t: 8,
                  s: [1369.379, -34.734, 0],
                  to: [0, 11.102, 0],
                  ti: [-27.948, -264.961, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.427, y: 0.402 },
                  t: 48.449,
                  s: [1343.58, 491.111, 0],
                  to: [31.803, 301.507, 0],
                  ti: [0, -11.486, 0],
                },
                { t: 80.0000032584668, s: [1489.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [80, 80, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.00000032584668,
          op: 158.000006435472,
          st: 8.00000032584668,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 62, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [0],
                },
                { t: 73.000002973351, s: [391] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.613, y: 0.429 },
                  o: { x: 0.242, y: 0 },
                  t: 3,
                  s: [1249.379, -114.734, 0],
                  to: [0, 0.55, 0],
                  ti: [39.995, -206.111, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.403, y: 0.362 },
                  t: 31.315,
                  s: [1218.083, 253.26, 0],
                  to: [-70.75, 364.609, 0],
                  ti: [0, -2.664, 0],
                },
                { t: 73.000002973351, s: [1009.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 153.000006231818,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 34, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [0],
                },
                { t: 73.000002973351, s: [228] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.65, y: 0.532 },
                  o: { x: 0.248, y: 0 },
                  t: 12,
                  s: [1267.734, -64.266, 0],
                  to: [0, 20.545, 0],
                  ti: [21.07, -292.262, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.413 },
                  t: 49.696,
                  s: [1064.321, 553.041, 0],
                  to: [-18.8, 260.769, 0],
                  ti: [0, -30.896, 0],
                },
                { t: 73.000002973351, s: [1187.734, 1095.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [80, 80, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 81, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [0],
                },
                { t: 78.0000031770051, s: [312] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.643, y: 0.46 },
                  o: { x: 0.273, y: 0 },
                  t: 4,
                  s: [1586.02, -53.98, 0],
                  to: [1.012, 29.346, 0],
                  ti: [-30.981, -209.854, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.252 },
                  t: 47,
                  s: [1524.289, 364.444, 0],
                  to: [44.724, 302.947, 0],
                  ti: [-2.478, -71.851, 0],
                },
                { t: 78.0000031770051, s: [1746.02, 1106.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 21, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                { t: 85.000003462121, s: [81] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.642, y: 0.456 },
                  o: { x: 0.272, y: 0 },
                  t: 0,
                  s: [1472.34, -31.66, 0],
                  to: [0, 15.483, 0],
                  ti: [23.629, -226.031, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.347 },
                  t: 43,
                  s: [1508.764, 401.611, 0],
                  to: [-34.327, 328.366, 0],
                  ti: [0, -37.663, 0],
                },
                { t: 85.000003462121, s: [1352.34, 1168.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5,
                  s: [27],
                },
                { t: 83.0000033806593, s: [67] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.469 },
                  o: { x: 0.281, y: 0 },
                  t: 5,
                  s: [1283.598, -13.402, 0],
                  to: [-1.435, 20.087, 0],
                  ti: [12.458, -113.855, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.396, y: 0.208 },
                  t: 34,
                  s: [1180.731, 204.538, 0],
                  to: [-33.233, 303.712, 0],
                  ti: [5.871, -82.187, 0],
                },
                { t: 73.000002973351, s: [1163.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [80, 80, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 155.000006313279,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 74, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [57],
                },
                { t: 85.000003462121, s: [310] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.674, y: 0.646 },
                  o: { x: 0.269, y: 0 },
                  t: 0,
                  s: [1483.598, -53.402, 0],
                  to: [-3.086, 43.206, 0],
                  ti: [-35.774, -307.07, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.432, y: 0.281 },
                  t: 47,
                  s: [1640.872, 649.544, 0],
                  to: [25.222, 216.498, 0],
                  ti: [3.338, -46.725, 0],
                },
                { t: 65.0000026475043, s: [1603.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 86, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                { t: 65.0000026475043, s: [141] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.638, y: 0.465 },
                  o: { x: 0.275, y: 0 },
                  t: 0,
                  s: [1224.75, -34.336, 0],
                  to: [1.098, 31.851, 0],
                  ti: [19.796, -173.642, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.412, y: 0.274 },
                  t: 32.5,
                  s: [1110.993, 312.124, 0],
                  to: [-36.306, 318.467, 0],
                  ti: [-2.3, -66.699, 0],
                },
                { t: 65.0000026475043, s: [1104.75, 1125.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 71, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [0],
                },
                { t: 80.0000032584668, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.652, y: 0.48 },
                  o: { x: 0.27, y: 0 },
                  t: 8,
                  s: [1369.379, -34.734, 0],
                  to: [0, 11.102, 0],
                  ti: [-27.948, -264.961, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.427, y: 0.402 },
                  t: 48.449,
                  s: [1343.58, 491.111, 0],
                  to: [31.803, 301.507, 0],
                  ti: [0, -11.486, 0],
                },
                { t: 80.0000032584668, s: [1489.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.00000032584668,
          op: 158.000006435472,
          st: 8.00000032584668,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 62, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [0],
                },
                { t: 73.000002973351, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.613, y: 0.429 },
                  o: { x: 0.242, y: 0 },
                  t: 3,
                  s: [1249.379, -114.734, 0],
                  to: [0, 0.55, 0],
                  ti: [39.995, -206.111, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.403, y: 0.362 },
                  t: 31.315,
                  s: [1218.083, 253.26, 0],
                  to: [-70.75, 364.609, 0],
                  ti: [0, -2.664, 0],
                },
                { t: 73.000002973351, s: [1009.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 153.000006231818,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 34, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [0],
                },
                { t: 73.000002973351, s: [270] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.65, y: 0.532 },
                  o: { x: 0.248, y: 0 },
                  t: 12,
                  s: [1267.734, -64.266, 0],
                  to: [0, 20.545, 0],
                  ti: [21.07, -292.262, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.413 },
                  t: 49.696,
                  s: [1064.321, 553.041, 0],
                  to: [-18.8, 260.769, 0],
                  ti: [0, -30.896, 0],
                },
                { t: 73.000002973351, s: [1187.734, 1095.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 81, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [0],
                },
                { t: 78.0000031770051, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.643, y: 0.46 },
                  o: { x: 0.273, y: 0 },
                  t: 4,
                  s: [1586.02, -53.98, 0],
                  to: [1.012, 29.346, 0],
                  ti: [-30.981, -209.854, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.252 },
                  t: 47,
                  s: [1524.289, 364.444, 0],
                  to: [44.724, 302.947, 0],
                  ti: [-2.478, -71.851, 0],
                },
                { t: 78.0000031770051, s: [1746.02, 1106.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 21, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                { t: 85.000003462121, s: [43] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.642, y: 0.456 },
                  o: { x: 0.272, y: 0 },
                  t: 0,
                  s: [1472.34, -31.66, 0],
                  to: [0, 15.483, 0],
                  ti: [23.629, -226.031, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.419, y: 0.347 },
                  t: 43,
                  s: [1508.764, 401.611, 0],
                  to: [-34.327, 328.366, 0],
                  ti: [0, -37.663, 0],
                },
                { t: 85.000003462121, s: [1352.34, 1168.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.469 },
                  o: { x: 0.281, y: 0 },
                  t: 5,
                  s: [1283.598, -13.402, 0],
                  to: [-1.435, 20.087, 0],
                  ti: [12.458, -113.855, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.396, y: 0.208 },
                  t: 34,
                  s: [1180.731, 204.538, 0],
                  to: [-33.233, 303.712, 0],
                  ti: [5.871, -82.187, 0],
                },
                { t: 73.000002973351, s: [1163.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 155.000006313279,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 74, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.674, y: 0.646 },
                  o: { x: 0.269, y: 0 },
                  t: 0,
                  s: [1483.598, -53.402, 0],
                  to: [-3.086, 43.206, 0],
                  ti: [-35.774, -307.07, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.432, y: 0.281 },
                  t: 47,
                  s: [1640.872, 649.544, 0],
                  to: [25.222, 216.498, 0],
                  ti: [3.338, -46.725, 0],
                },
                { t: 65.0000026475043, s: [1603.598, 1106.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 86, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                { t: 65.0000026475043, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.638, y: 0.465 },
                  o: { x: 0.275, y: 0 },
                  t: 0,
                  s: [1224.75, -34.336, 0],
                  to: [1.098, 31.851, 0],
                  ti: [19.796, -173.642, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.412, y: 0.274 },
                  t: 32.5,
                  s: [1110.993, 312.124, 0],
                  to: [-36.306, 318.467, 0],
                  ti: [-2.3, -66.699, 0],
                },
                { t: 65.0000026475043, s: [1104.75, 1125.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_6',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5,
                  s: [17],
                },
                { t: 95.0000038694293, s: [85] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.469 },
                  o: { x: 0.271, y: 0 },
                  t: 5,
                  s: [1449.379, -34.734, 0],
                  to: [0, 9.882, 0],
                  ti: [0, -255.833, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.39 },
                  t: 35,
                  s: [1529.379, 452.206, 0],
                  to: [0, 358.635, 0],
                  ti: [0, -7.782, 0],
                },
                { t: 65.0000026475043, s: [1449.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 155.000006313279,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 2,
                  s: [53],
                },
                { t: 92.0000037472368, s: [108] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.428 },
                  o: { x: 0.285, y: 0 },
                  t: 2,
                  s: [1129.379, -34.734, 0],
                  to: [0, 5.775, 0],
                  ti: [0, -114.862, 0],
                },
                {
                  i: { x: 0.679, y: 0.646 },
                  o: { x: 0.329, y: 0.267 },
                  t: 19,
                  s: [1073.379, 163.86, 0],
                  to: [0, 131.21, 0],
                  ti: [-9.561, -174.942, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.432, y: 0.414 },
                  t: 40,
                  s: [1170.496, 649.38, 0],
                  to: [16.468, 301.316, 0],
                  ti: [0, -6.383, 0],
                },
                { t: 62.0000025253118, s: [1129.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2.00000008146167,
          op: 152.000006191087,
          st: 2.00000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 7,
                  s: [37],
                },
                { t: 97.000003950891, s: [103] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.61, y: 0.4 },
                  o: { x: 0.253, y: 0 },
                  t: 7,
                  s: [889.379, -154.734, 0],
                  to: [0, 0.425, 0],
                  ti: [-22.792, -143.026, 0],
                },
                {
                  i: { x: 0.667, y: 0.65 },
                  o: { x: 0.324, y: 0.284 },
                  t: 25,
                  s: [891.833, 87.938, 0],
                  to: [15.968, 100.206, 0],
                  ti: [0, -124.298, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.424, y: 0.409 },
                  t: 40,
                  s: [969.379, 434.15, 0],
                  to: [0, 332.779, 0],
                  ti: [0, -6.993, 0],
                },
                { t: 67.0000027289659, s: [889.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7.00000028511585,
          op: 157.000006394741,
          st: 7.00000028511585,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [41],
                },
                { t: 102.000004154545, s: [142] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.621, y: 0.444 },
                  o: { x: 0.262, y: 0 },
                  t: 12,
                  s: [867.734, -64.266, 0],
                  to: [0, 11.744, 0],
                  ti: [0, -162.479, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.396, y: 0.336 },
                  t: 33,
                  s: [787.734, 230.195, 0],
                  to: [0, 375.382, 0],
                  ti: [0, -9.306, 0],
                },
                { t: 72.0000029326201, s: [867.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 16,
                  s: [56],
                },
                { t: 106.000004317469, s: [91] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.463 },
                  o: { x: 0.272, y: 0 },
                  t: 16,
                  s: [1426.02, -93.98, 0],
                  to: [1.001, 29.035, 0],
                  ti: [0.128, -244.325, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.391 },
                  t: 46,
                  s: [1475.083, 392.552, 0],
                  to: [-0.188, 359.115, 0],
                  ti: [0, -7.935, 0],
                },
                { t: 76.0000030955435, s: [1426.02, 1186.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 16.0000006516934,
          op: 166.000006761319,
          st: 16.0000006516934,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [6],
                },
                { t: 99.0000040323527, s: [41] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.624, y: 0.427 },
                  o: { x: 0.274, y: 0 },
                  t: 9,
                  s: [868.34, -31.66, 0],
                  to: [0, 3.069, 0],
                  ti: [-14.839, -122.106, 0],
                },
                {
                  i: { x: 0.703, y: 0.676 },
                  o: { x: 0.334, y: 0.264 },
                  t: 26,
                  s: [815.34, 176.277, 0],
                  to: [23.221, 191.078, 0],
                  ti: [0, -229.161, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 55,
                  s: [948.34, 883.938, 0],
                  to: [0, 203.602, 0],
                  ti: [0, -3.189, 0],
                },
                { t: 69.0000028104276, s: [868.34, 1248.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5,
                  s: [156],
                },
                { t: 95.0000038694293, s: [191] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.663, y: 0.523 },
                  o: { x: 0.269, y: 0 },
                  t: 5,
                  s: [1123.598, -53.402, 0],
                  to: [-2.715, 38.01, 0],
                  ti: [-0.72, -318.195, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 43,
                  s: [1281.689, 638.799, 0],
                  to: [0.675, 298.506, 0],
                  ti: [0, -6.454, 0],
                },
                { t: 65.0000026475043, s: [1123.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 155.000006313279,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 18,
                  s: [185],
                },
                { t: 108.00000439893, s: [220] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.625, y: 0.428 },
                  o: { x: 0.26, y: 0 },
                  t: 18,
                  s: [1243.598, -53.402, 0],
                  to: [-0.681, 9.529, 0],
                  ti: [-23.715, -196.552, 0],
                },
                {
                  i: { x: 0.676, y: 0.666 },
                  o: { x: 0.336, y: 0.304 },
                  t: 42,
                  s: [1253.71, 305.086, 0],
                  to: [11.99, 99.375, 0],
                  ti: [-0.091, -109.184, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.422 },
                  t: 55,
                  s: [1322.878, 624.304, 0],
                  to: [0.255, 304.892, 0],
                  ti: [0, -4.017, 0],
                },
                { t: 78.0000031770051, s: [1243.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 18.000000733155,
          op: 168.00000684278,
          st: 18.000000733155,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 6,
                  s: [148],
                },
                { t: 96.0000039101602, s: [183] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.69, y: 0.578 },
                  o: { x: 0.257, y: 0 },
                  t: 6,
                  s: [824.75, -34.336, 0],
                  to: [0.533, 15.452, 0],
                  ti: [0.177, -372.851, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 52,
                  s: [744.95, 880.207, 0],
                  to: [-0.097, 203.942, 0],
                  ti: [0, -3.466, 0],
                },
                { t: 66.0000026882351, s: [824.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 156.00000635401,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [-19],
                },
                { t: 102.000004154545, s: [22] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.376 },
                  o: { x: 0.285, y: 0 },
                  t: 12,
                  s: [529.379, -34.734, 0],
                  to: [0, 5.741, 0],
                  ti: [36.579, -113.749, 0],
                },
                {
                  i: { x: 0.679, y: 0.653 },
                  o: { x: 0.33, y: 0.269 },
                  t: 29,
                  s: [498.92, 161.829, 0],
                  to: [-42.913, 133.444, 0],
                  ti: [0, -178.445, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 50,
                  s: [369.379, 657.638, 0],
                  to: [0, 298.302, 0],
                  ti: [0, -6.325, 0],
                },
                { t: 72.0000029326201, s: [529.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [13],
                },
                { t: 94.0000038286985, s: [64] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.619, y: 0.465 },
                  o: { x: 0.237, y: 0 },
                  t: 4,
                  s: [809.379, -154.734, 0],
                  to: [0, 0.625, 0],
                  ti: [0, -252.608, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.414, y: 0.386 },
                  t: 32,
                  s: [889.379, 312.208, 0],
                  to: [0, 362.539, 0],
                  ti: [0, -7.858, 0],
                },
                { t: 64.0000026067734, s: [809.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Shape Layer 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 18,
                  s: [17],
                },
                { t: 108.00000439893, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.621, y: 0.377 },
                  o: { x: 0.269, y: 0 },
                  t: 18,
                  s: [707.734, -64.266, 0],
                  to: [0, 9.595, 0],
                  ti: [39.322, -118.316, 0],
                },
                {
                  i: { x: 0.673, y: 0.655 },
                  o: { x: 0.325, y: 0.274 },
                  t: 35,
                  s: [677.734, 145.67, 0],
                  to: [-40.655, 122.326, 0],
                  ti: [0, -159.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.407 },
                  t: 54,
                  s: [547.734, 589.09, 0],
                  to: [0, 312.184, 0],
                  ti: [0, -6.64, 0],
                },
                { t: 78.0000031770051, s: [707.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 18.000000733155,
          op: 168.00000684278,
          st: 18.000000733155,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [35],
                },
                { t: 99.0000040323527, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.627, y: 0.437 },
                  o: { x: 0.255, y: 0 },
                  t: 9,
                  s: [427.734, -64.266, 0],
                  to: [0, 14.806, 0],
                  ti: [45.608, -226.932, 0],
                },
                {
                  i: { x: 0.699, y: 0.698 },
                  o: { x: 0.349, y: 0.313 },
                  t: 36,
                  s: [406.702, 364.719, 0],
                  to: [-31.883, 158.639, 0],
                  ti: [0, -152.073, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.401 },
                  t: 55,
                  s: [267.734, 853.462, 0],
                  to: [0, 201.592, 0],
                  ti: [0, -4.706, 0],
                },
                { t: 69.0000028104276, s: [427.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [-19],
                },
                { t: 90.0000036657751, s: [16] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.387 },
                  o: { x: 0.284, y: 0 },
                  t: 0,
                  s: [986.02, -133.98, 0],
                  to: [0.598, 17.353, 0],
                  ti: [37.228, -116.241, 0],
                },
                {
                  i: { x: 0.68, y: 0.664 },
                  o: { x: 0.332, y: 0.268 },
                  t: 18,
                  s: [994.546, 82.741, 0],
                  to: [-42.194, 131.75, 0],
                  ti: [0.18, -169.275, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 38,
                  s: [826.916, 558.055, 0],
                  to: [-0.318, 298.557, 0],
                  ti: [0, -6.45, 0],
                },
                { t: 60.0000024438501, s: [986.02, 1146.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [17],
                },
                { t: 102.000004154545, s: [52] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.426 },
                  o: { x: 0.283, y: 0 },
                  t: 12,
                  s: [632.34, -71.66, 0],
                  to: [0, 9.589, 0],
                  ti: [-14.999, -122.689, 0],
                },
                {
                  i: { x: 0.704, y: 0.677 },
                  o: { x: 0.34, y: 0.267 },
                  t: 30,
                  s: [579.694, 146.125, 0],
                  to: [23.168, 189.513, 0],
                  ti: [0, -224.182, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.434 },
                  t: 58,
                  s: [712.34, 838.048, 0],
                  to: [0, 205.461, 0],
                  ti: [0, -4.766, 0],
                },
                { t: 72.0000029326201, s: [632.34, 1208.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [27],
                },
                { t: 94.0000038286985, s: [62] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.62, y: 0.45 },
                  o: { x: 0.269, y: 0 },
                  t: 4,
                  s: [443.598, -13.402, 0],
                  to: [-0.62, 8.681, 0],
                  ti: [0.118, -120.21, 0],
                },
                {
                  i: { x: 0.687, y: 0.658 },
                  o: { x: 0.328, y: 0.269 },
                  t: 21,
                  s: [362.548, 198.697, 0],
                  to: [-0.157, 160.398, 0],
                  ti: [-17.705, -203.006, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.426 },
                  t: 46,
                  s: [475.92, 788.378, 0],
                  to: [22.341, 256.165, 0],
                  ti: [0, -2.942, 0],
                },
                { t: 64.0000026067734, s: [443.598, 1266.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 154.000006272549,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 7,
                  s: [34],
                },
                { t: 97.000003950891, s: [153] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.449 },
                  o: { x: 0.285, y: 0 },
                  t: 7,
                  s: [279.598, -53.402, 0],
                  to: [-1.202, 16.834, 0],
                  ti: [0.292, -106.297, 0],
                },
                {
                  i: { x: 0.687, y: 0.652 },
                  o: { x: 0.332, y: 0.264 },
                  t: 24,
                  s: [357.451, 145.009, 0],
                  to: [-0.416, 151.641, 0],
                  ti: [15.989, -198.244, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.423 },
                  t: 48,
                  s: [247.081, 709.658, 0],
                  to: [-21.851, 270.927, 0],
                  ti: [0, -5.94, 0],
                },
                { t: 67.0000027289659, s: [279.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7.00000028511585,
          op: 157.000006394741,
          st: 7.00000028511585,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [47],
                },
                { t: 90.0000036657751, s: [82] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.641, y: 0.46 },
                  o: { x: 0.273, y: 0 },
                  t: 0,
                  s: [384.75, -34.336, 0],
                  to: [1.162, 33.703, 0],
                  ti: [0.087, -219.458, 0],
                },
                {
                  i: { x: 0.707, y: 0.694 },
                  o: { x: 0.354, y: 0.324 },
                  t: 28,
                  s: [466.119, 403.941, 0],
                  to: [-0.068, 169.484, 0],
                  ti: [18.588, -156.525, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.435, y: 0.435 },
                  t: 48,
                  s: [382.228, 919.916, 0],
                  to: [-21.808, 183.632, 0],
                  ti: [0, -4.51, 0],
                },
                { t: 60.0000024438501, s: [384.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_7',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 53, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 17,
                  s: [17],
                },
                { t: 107.000004358199, s: [85] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.469 },
                  o: { x: 0.271, y: 0 },
                  t: 17,
                  s: [1449.379, -34.734, 0],
                  to: [0, 9.882, 0],
                  ti: [0, -255.833, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.39 },
                  t: 47,
                  s: [1529.379, 452.206, 0],
                  to: [0, 358.635, 0],
                  ti: [0, -7.782, 0],
                },
                { t: 77.0000031362743, s: [1449.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 17.0000006924242,
          op: 167.000006802049,
          st: 17.0000006924242,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 66, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [53],
                },
                { t: 112.000004561854, s: [108] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.428 },
                  o: { x: 0.285, y: 0 },
                  t: 22,
                  s: [1129.379, -34.734, 0],
                  to: [0, 5.775, 0],
                  ti: [0, -114.862, 0],
                },
                {
                  i: { x: 0.679, y: 0.646 },
                  o: { x: 0.329, y: 0.267 },
                  t: 39,
                  s: [1073.379, 163.86, 0],
                  to: [0, 131.21, 0],
                  ti: [-9.561, -174.942, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.432, y: 0.414 },
                  t: 60,
                  s: [1170.496, 649.38, 0],
                  to: [16.468, 301.316, 0],
                  ti: [0, -6.383, 0],
                },
                { t: 82.0000033399285, s: [1129.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 22.0000008960784,
          op: 172.000007005704,
          st: 22.0000008960784,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 25, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 28,
                  s: [37],
                },
                { t: 118.000004806239, s: [103] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.61, y: 0.4 },
                  o: { x: 0.253, y: 0 },
                  t: 28,
                  s: [889.379, -154.734, 0],
                  to: [0, 0.425, 0],
                  ti: [-22.792, -143.026, 0],
                },
                {
                  i: { x: 0.667, y: 0.65 },
                  o: { x: 0.324, y: 0.284 },
                  t: 46,
                  s: [891.833, 87.938, 0],
                  to: [15.968, 100.206, 0],
                  ti: [0, -124.298, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.424, y: 0.409 },
                  t: 61,
                  s: [969.379, 434.15, 0],
                  to: [0, 332.779, 0],
                  ti: [0, -6.993, 0],
                },
                { t: 88.0000035843135, s: [889.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 28.0000011404634,
          op: 178.000007250089,
          st: 28.0000011404634,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 53, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 20,
                  s: [41],
                },
                { t: 110.000004480392, s: [142] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.621, y: 0.444 },
                  o: { x: 0.262, y: 0 },
                  t: 20,
                  s: [867.734, -64.266, 0],
                  to: [0, 11.744, 0],
                  ti: [0, -162.479, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.396, y: 0.336 },
                  t: 41,
                  s: [787.734, 230.195, 0],
                  to: [0, 375.382, 0],
                  ti: [0, -9.306, 0],
                },
                { t: 80.0000032584668, s: [867.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 20.0000008146167,
          op: 170.000006924242,
          st: 20.0000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 66, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 26,
                  s: [56],
                },
                { t: 116.000004724777, s: [91] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.463 },
                  o: { x: 0.272, y: 0 },
                  t: 26,
                  s: [1426.02, -93.98, 0],
                  to: [1.001, 29.035, 0],
                  ti: [0.128, -244.325, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.391 },
                  t: 56,
                  s: [1475.083, 392.552, 0],
                  to: [-0.188, 359.115, 0],
                  ti: [0, -7.935, 0],
                },
                { t: 86.0000035028518, s: [1426.02, 1186.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 26.0000010590017,
          op: 176.000007168627,
          st: 26.0000010590017,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 25, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 18,
                  s: [6],
                },
                { t: 108.00000439893, s: [41] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.624, y: 0.427 },
                  o: { x: 0.274, y: 0 },
                  t: 18,
                  s: [868.34, -31.66, 0],
                  to: [0, 3.069, 0],
                  ti: [-14.839, -122.106, 0],
                },
                {
                  i: { x: 0.703, y: 0.676 },
                  o: { x: 0.334, y: 0.264 },
                  t: 35,
                  s: [815.34, 176.277, 0],
                  to: [23.221, 191.078, 0],
                  ti: [0, -229.161, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 64,
                  s: [948.34, 883.938, 0],
                  to: [0, 203.602, 0],
                  ti: [0, -3.189, 0],
                },
                { t: 78.0000031770051, s: [868.34, 1248.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 18.000000733155,
          op: 168.00000684278,
          st: 18.000000733155,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 53, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 32,
                  s: [156],
                },
                { t: 122.000004969162, s: [191] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.663, y: 0.523 },
                  o: { x: 0.269, y: 0 },
                  t: 32,
                  s: [1123.598, -53.402, 0],
                  to: [-2.715, 38.01, 0],
                  ti: [-0.72, -318.195, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 70,
                  s: [1281.689, 638.799, 0],
                  to: [0.675, 298.506, 0],
                  ti: [0, -6.454, 0],
                },
                { t: 92.0000037472368, s: [1123.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 32.0000013033867,
          op: 182.000007413012,
          st: 32.0000013033867,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 25, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 32,
                  s: [185],
                },
                { t: 122.000004969162, s: [220] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.625, y: 0.428 },
                  o: { x: 0.26, y: 0 },
                  t: 32,
                  s: [1243.598, -53.402, 0],
                  to: [-0.681, 9.529, 0],
                  ti: [-23.715, -196.552, 0],
                },
                {
                  i: { x: 0.676, y: 0.666 },
                  o: { x: 0.336, y: 0.304 },
                  t: 56,
                  s: [1253.71, 305.086, 0],
                  to: [11.99, 99.375, 0],
                  ti: [-0.091, -109.184, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.422 },
                  t: 69,
                  s: [1322.878, 624.304, 0],
                  to: [0.255, 304.892, 0],
                  ti: [0, -4.017, 0],
                },
                { t: 92.0000037472368, s: [1243.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 32.0000013033867,
          op: 182.000007413012,
          st: 32.0000013033867,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 66, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 19,
                  s: [148],
                },
                { t: 109.000004439661, s: [183] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.69, y: 0.578 },
                  o: { x: 0.257, y: 0 },
                  t: 19,
                  s: [824.75, -34.336, 0],
                  to: [0.533, 15.452, 0],
                  ti: [0.177, -372.851, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 65,
                  s: [744.95, 880.207, 0],
                  to: [-0.097, 203.942, 0],
                  ti: [0, -3.466, 0],
                },
                { t: 79.000003217736, s: [824.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 19.0000007738859,
          op: 169.000006883511,
          st: 19.0000007738859,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 53, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [-19],
                },
                { t: 102.000004154545, s: [22] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.376 },
                  o: { x: 0.285, y: 0 },
                  t: 12,
                  s: [529.379, -34.734, 0],
                  to: [0, 5.741, 0],
                  ti: [36.579, -113.749, 0],
                },
                {
                  i: { x: 0.679, y: 0.653 },
                  o: { x: 0.33, y: 0.269 },
                  t: 29,
                  s: [498.92, 161.829, 0],
                  to: [-42.913, 133.444, 0],
                  ti: [0, -178.445, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 50,
                  s: [369.379, 657.638, 0],
                  to: [0, 298.302, 0],
                  ti: [0, -6.325, 0],
                },
                { t: 72.0000029326201, s: [529.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 66, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [13],
                },
                { t: 112.000004561854, s: [64] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.619, y: 0.465 },
                  o: { x: 0.237, y: 0 },
                  t: 22,
                  s: [809.379, -154.734, 0],
                  to: [0, 0.625, 0],
                  ti: [0, -252.608, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.414, y: 0.386 },
                  t: 50,
                  s: [889.379, 312.208, 0],
                  to: [0, 362.539, 0],
                  ti: [0, -7.858, 0],
                },
                { t: 82.0000033399285, s: [809.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 22.0000008960784,
          op: 172.000007005704,
          st: 22.0000008960784,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Shape Layer 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 25, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 27,
                  s: [17],
                },
                { t: 117.000004765508, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.621, y: 0.377 },
                  o: { x: 0.269, y: 0 },
                  t: 27,
                  s: [707.734, -64.266, 0],
                  to: [0, 9.595, 0],
                  ti: [39.322, -118.316, 0],
                },
                {
                  i: { x: 0.673, y: 0.655 },
                  o: { x: 0.325, y: 0.274 },
                  t: 44,
                  s: [677.734, 145.67, 0],
                  to: [-40.655, 122.326, 0],
                  ti: [0, -159.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.407 },
                  t: 63,
                  s: [547.734, 589.09, 0],
                  to: [0, 312.184, 0],
                  ti: [0, -6.64, 0],
                },
                { t: 87.0000035435826, s: [707.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 27.0000010997325,
          op: 177.000007209358,
          st: 27.0000010997325,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 66, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 24,
                  s: [35],
                },
                { t: 114.000004643315, s: [70] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.627, y: 0.437 },
                  o: { x: 0.255, y: 0 },
                  t: 24,
                  s: [427.734, -64.266, 0],
                  to: [0, 14.806, 0],
                  ti: [45.608, -226.932, 0],
                },
                {
                  i: { x: 0.699, y: 0.698 },
                  o: { x: 0.349, y: 0.313 },
                  t: 51,
                  s: [406.702, 364.719, 0],
                  to: [-31.883, 158.639, 0],
                  ti: [0, -152.073, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.401 },
                  t: 70,
                  s: [267.734, 853.462, 0],
                  to: [0, 201.592, 0],
                  ti: [0, -4.706, 0],
                },
                { t: 84.0000034213901, s: [427.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 24.00000097754,
          op: 174.000007087165,
          st: 24.00000097754,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 25, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 21,
                  s: [-19],
                },
                { t: 111.000004521123, s: [16] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.387 },
                  o: { x: 0.284, y: 0 },
                  t: 21,
                  s: [986.02, -133.98, 0],
                  to: [0.598, 17.353, 0],
                  ti: [37.228, -116.241, 0],
                },
                {
                  i: { x: 0.68, y: 0.664 },
                  o: { x: 0.332, y: 0.268 },
                  t: 39,
                  s: [994.546, 82.741, 0],
                  to: [-42.194, 131.75, 0],
                  ti: [0.18, -169.275, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 59,
                  s: [826.916, 558.055, 0],
                  to: [-0.318, 298.557, 0],
                  ti: [0, -6.45, 0],
                },
                { t: 81.0000032991976, s: [986.02, 1146.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 21.0000008553475,
          op: 171.000006964973,
          st: 21.0000008553475,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 53, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [17],
                },
                { t: 102.000004154545, s: [52] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.426 },
                  o: { x: 0.283, y: 0 },
                  t: 12,
                  s: [632.34, -71.66, 0],
                  to: [0, 9.589, 0],
                  ti: [-14.999, -122.689, 0],
                },
                {
                  i: { x: 0.704, y: 0.677 },
                  o: { x: 0.34, y: 0.267 },
                  t: 30,
                  s: [579.694, 146.125, 0],
                  to: [23.168, 189.513, 0],
                  ti: [0, -224.182, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.434 },
                  t: 58,
                  s: [712.34, 838.048, 0],
                  to: [0, 205.461, 0],
                  ti: [0, -4.766, 0],
                },
                { t: 72.0000029326201, s: [632.34, 1208.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12.00000048877,
          op: 162.000006598395,
          st: 12.00000048877,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 25, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 21,
                  s: [27],
                },
                { t: 111.000004521123, s: [62] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.62, y: 0.45 },
                  o: { x: 0.269, y: 0 },
                  t: 21,
                  s: [443.598, -13.402, 0],
                  to: [-0.62, 8.681, 0],
                  ti: [0.118, -120.21, 0],
                },
                {
                  i: { x: 0.687, y: 0.658 },
                  o: { x: 0.328, y: 0.269 },
                  t: 38,
                  s: [362.548, 198.697, 0],
                  to: [-0.157, 160.398, 0],
                  ti: [-17.705, -203.006, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.426 },
                  t: 63,
                  s: [475.92, 788.378, 0],
                  to: [22.341, 256.165, 0],
                  ti: [0, -2.942, 0],
                },
                { t: 81.0000032991976, s: [443.598, 1266.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 21.0000008553475,
          op: 171.000006964973,
          st: 21.0000008553475,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 66, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 7,
                  s: [34],
                },
                { t: 97.000003950891, s: [153] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.449 },
                  o: { x: 0.285, y: 0 },
                  t: 7,
                  s: [279.598, -53.402, 0],
                  to: [-1.202, 16.834, 0],
                  ti: [0.292, -106.297, 0],
                },
                {
                  i: { x: 0.687, y: 0.652 },
                  o: { x: 0.332, y: 0.264 },
                  t: 24,
                  s: [357.451, 145.009, 0],
                  to: [-0.416, 151.641, 0],
                  ti: [15.989, -198.244, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.423 },
                  t: 48,
                  s: [247.081, 709.658, 0],
                  to: [-21.851, 270.927, 0],
                  ti: [0, -5.94, 0],
                },
                { t: 67.0000027289659, s: [279.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7.00000028511585,
          op: 157.000006394741,
          st: 7.00000028511585,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 53, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [47],
                },
                { t: 90.0000036657751, s: [82] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.641, y: 0.46 },
                  o: { x: 0.273, y: 0 },
                  t: 0,
                  s: [384.75, -34.336, 0],
                  to: [1.162, 33.703, 0],
                  ti: [0.087, -219.458, 0],
                },
                {
                  i: { x: 0.707, y: 0.694 },
                  o: { x: 0.354, y: 0.324 },
                  t: 28,
                  s: [466.119, 403.941, 0],
                  to: [-0.068, 169.484, 0],
                  ti: [18.588, -156.525, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.435, y: 0.435 },
                  t: 48,
                  s: [382.228, 919.916, 0],
                  to: [-21.808, 183.632, 0],
                  ti: [0, -4.51, 0],
                },
                { t: 60.0000024438501, s: [384.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_8',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 53, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.428 },
                  o: { x: 0.285, y: 0 },
                  t: 0,
                  s: [1129.379, -34.734, 0],
                  to: [0, 5.775, 0],
                  ti: [0, -114.862, 0],
                },
                {
                  i: { x: 0.679, y: 0.646 },
                  o: { x: 0.329, y: 0.267 },
                  t: 17,
                  s: [1073.379, 163.86, 0],
                  to: [0, 131.21, 0],
                  ti: [-9.561, -174.942, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.432, y: 0.414 },
                  t: 38,
                  s: [1170.496, 649.38, 0],
                  to: [16.468, 301.316, 0],
                  ti: [0, -6.383, 0],
                },
                { t: 60.0000024438501, s: [1129.379, 1245.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [83, 83, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: { a: 0, k: 37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.61, y: 0.4 },
                  o: { x: 0.253, y: 0 },
                  t: 8,
                  s: [889.379, -154.734, 0],
                  to: [0, 0.425, 0],
                  ti: [-22.792, -143.026, 0],
                },
                {
                  i: { x: 0.667, y: 0.65 },
                  o: { x: 0.324, y: 0.284 },
                  t: 19.7,
                  s: [891.833, 87.938, 0],
                  to: [15.968, 100.206, 0],
                  ti: [0, -124.298, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.424, y: 0.409 },
                  t: 29.45,
                  s: [969.379, 434.15, 0],
                  to: [0, 332.779, 0],
                  ti: [0, -6.993, 0],
                },
                { t: 47.0000019143492, s: [889.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [60, 60, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.00000032584668,
          op: 158.000006435472,
          st: 8.00000032584668,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 56, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.644, y: 0.463 },
                  o: { x: 0.272, y: 0 },
                  t: 13,
                  s: [1426.02, -93.98, 0],
                  to: [1.001, 29.035, 0],
                  ti: [0.128, -244.325, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.421, y: 0.391 },
                  t: 32.5,
                  s: [1475.083, 392.552, 0],
                  to: [-0.188, 359.115, 0],
                  ti: [0, -7.935, 0],
                },
                { t: 52.0000021180034, s: [1426.02, 1186.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 13.0000005295009,
          op: 163.000006639126,
          st: 13.0000005295009,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 148, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.69, y: 0.707 },
                  o: { x: 0.257, y: 0 },
                  t: 3,
                  s: [824.75, -34.336, 0],
                  to: [0.533, 15.452, 0],
                  ti: [0.177, -372.851, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 35,
                  s: [744.95, 880.207, 0],
                  to: [-0.097, 203.942, 0],
                  ti: [0, -3.466, 0],
                },
                { t: 49.0000019958109, s: [824.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [83, 83, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 153.000006231818,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 13, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.619, y: 0.465 },
                  o: { x: 0.237, y: 0 },
                  t: 11,
                  s: [809.379, -154.734, 0],
                  to: [0, 0.625, 0],
                  ti: [0, -252.608, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.414, y: 0.386 },
                  t: 39,
                  s: [889.379, 312.208, 0],
                  to: [0, 362.539, 0],
                  ti: [0, -7.858, 0],
                },
                { t: 71.0000028918893, s: [809.379, 1125.266, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
            s: { a: 0, k: [83, 83, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [50.758, 10.531], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-110.621, -54.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 11.0000004480392,
          op: 161.000006557664,
          st: 11.0000004480392,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: { a: 0, k: -19, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.387 },
                  o: { x: 0.284, y: 0 },
                  t: 9,
                  s: [986.02, -133.98, 0],
                  to: [0.598, 17.353, 0],
                  ti: [37.228, -116.241, 0],
                },
                {
                  i: { x: 0.68, y: 0.664 },
                  o: { x: 0.332, y: 0.268 },
                  t: 19.2,
                  s: [994.546, 82.741, 0],
                  to: [-42.194, 131.75, 0],
                  ti: [0.18, -169.275, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.41 },
                  t: 30.534,
                  s: [826.916, 558.055, 0],
                  to: [-0.318, 298.557, 0],
                  ti: [0, -6.45, 0],
                },
                { t: 43.0000017514259, s: [986.02, 1146.02, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16.039, 16.039], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-213.98, -285.98], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 159.000006476203,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: { a: 0, k: 17, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.426 },
                  o: { x: 0.283, y: 0 },
                  t: 0,
                  s: [632.34, -71.66, 0],
                  to: [0, 9.589, 0],
                  ti: [-14.999, -122.689, 0],
                },
                {
                  i: { x: 0.704, y: 0.677 },
                  o: { x: 0.34, y: 0.267 },
                  t: 12.6,
                  s: [579.694, 146.125, 0],
                  to: [23.168, 189.513, 0],
                  ti: [0, -224.182, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.429, y: 0.434 },
                  t: 32.2,
                  s: [712.34, 838.048, 0],
                  to: [0, 205.461, 0],
                  ti: [0, -4.766, 0],
                },
                { t: 42.0000017106951, s: [632.34, 1208.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [83, 83, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 59, ix: 11 },
            r: { a: 0, k: 6, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.624, y: 0.427 },
                  o: { x: 0.274, y: 0 },
                  t: 0,
                  s: [868.34, -31.66, 0],
                  to: [0, 3.069, 0],
                  ti: [-14.839, -122.106, 0],
                },
                {
                  i: { x: 0.703, y: 0.676 },
                  o: { x: 0.334, y: 0.264 },
                  t: 10.484,
                  s: [815.34, 176.277, 0],
                  to: [23.221, 191.078, 0],
                  ti: [0, -229.161, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.43, y: 0.434 },
                  t: 28.366,
                  s: [948.34, 883.938, 0],
                  to: [0, 203.602, 0],
                  ti: [0, -3.189, 0],
                },
                { t: 37.0000015070409, s: [868.34, 1248.34, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
            s: { a: 0, k: [83, 83, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8.68, 8.68], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-167.66, -195.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 35, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.627, y: 0.437 },
                  o: { x: 0.255, y: 0 },
                  t: 0,
                  s: [427.734, -64.266, 0],
                  to: [0, 14.806, 0],
                  ti: [45.608, -226.932, 0],
                },
                {
                  i: { x: 0.699, y: 0.698 },
                  o: { x: 0.349, y: 0.313 },
                  t: 18.45,
                  s: [406.702, 364.719, 0],
                  to: [-31.883, 158.639, 0],
                  ti: [0, -152.073, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.431, y: 0.401 },
                  t: 31.434,
                  s: [267.734, 853.462, 0],
                  to: [0, 201.592, 0],
                  ti: [0, -4.706, 0],
                },
                { t: 41.0000016699642, s: [427.734, 1215.734, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [23.469, 23.469], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-492.266, -124.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 87, ix: 11 },
            r: { a: 0, k: 47, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.641, y: 0.46 },
                  o: { x: 0.273, y: 0 },
                  t: 0,
                  s: [384.75, -34.336, 0],
                  to: [1.162, 33.703, 0],
                  ti: [0.087, -219.458, 0],
                },
                {
                  i: { x: 0.707, y: 0.694 },
                  o: { x: 0.354, y: 0.324 },
                  t: 15.866,
                  s: [466.119, 403.941, 0],
                  to: [-0.068, 169.484, 0],
                  ti: [18.588, -156.525, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.435, y: 0.435 },
                  t: 27.2,
                  s: [382.228, 919.916, 0],
                  to: [-21.808, 183.632, 0],
                  ti: [0, -4.51, 0],
                },
                { t: 34.0000013848484, s: [384.75, 1245.664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
            s: { a: 0, k: [83, 83, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [33.5, 11.328], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-455.25, -254.336], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 34, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.633, y: 0.449 },
                  o: { x: 0.285, y: 0 },
                  t: 0,
                  s: [279.598, -53.402, 0],
                  to: [-1.202, 16.834, 0],
                  ti: [0.292, -106.297, 0],
                },
                {
                  i: { x: 0.687, y: 0.652 },
                  o: { x: 0.332, y: 0.264 },
                  t: 11.616,
                  s: [357.451, 145.009, 0],
                  to: [-0.416, 151.641, 0],
                  ti: [15.989, -198.244, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.433, y: 0.423 },
                  t: 28.016,
                  s: [247.081, 709.658, 0],
                  to: [-21.851, 270.927, 0],
                  ti: [0, -5.94, 0],
                },
                { t: 41.0000016699642, s: [279.598, 1226.598, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [9.195, 9.195], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-116.402, -29.402], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150.000006109625,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Confetti - 08',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'Confetti - 03',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 70, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'Confetti - 03',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 80, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 6.00000024438501,
      op: 156.00000635401,
      st: 6.00000024438501,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: 'Confetti - 02',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 70, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [720, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 13.0000005295009,
      op: 163.000006639126,
      st: 13.0000005295009,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: 'Confetti - 01',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1160, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 6.00000024438501,
      op: 156.00000635401,
      st: 6.00000024438501,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: 'Confetti - 04',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 4.00000016292334,
      op: 154.000006272549,
      st: 4.00000016292334,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: 'Confetti - 05',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 9.00000036657752,
      op: 159.000006476203,
      st: 9.00000036657752,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'Confetti - 06',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'Confetti - 07',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 15.0000006109625,
      op: 165.000006720588,
      st: 15.0000006109625,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: 'Confetti - 08',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 3.00000012219251,
      op: 153.000006231818,
      st: 3.00000012219251,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: 'Confetti - 09',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [-100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 16.0000006516934,
      op: 166.000006761319,
      st: 16.0000006516934,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Shape Layer 9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 69.0000028104276, s: [70] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.669, y: 0.483 },
              o: { x: 0.296, y: 0 },
              t: 0,
              s: [384.75, -34.336, 0],
              to: [3.113, 90.268, 0],
              ti: [-7.761, -225.076, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.429, y: 0.41 },
              t: 40,
              s: [363.097, 497.71400000000006, 0],
              to: [8.862, 256.987, 0],
              ti: [-3.554, -103.066, 0],
            },
            { t: 69.0000028104276, s: [424.74999999999994, 1125.664, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-455.25, -254.336, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [33.5, 11.328], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-455.25, -254.336], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Shape Layer 8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Shape Layer 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.297, y: 0 },
              t: 0,
              s: [643.598, -53.40200000000001, 0],
              to: [-5.262, 73.669, 0],
              ti: [8.071, -112.998, 0],
            },
            { t: 72.0000029326201, s: [723.598, 1106.598, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [9.195, 9.195], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-116.402, -29.402], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Shape Layer 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.66, y: 0.456 },
              o: { x: 0.297, y: 0 },
              t: 0,
              s: [443.59799999999996, -13.402, 0],
              to: [-5.262, 73.669, 0],
              ti: [12.805, -179.267, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.425, y: 0.388 },
              t: 45,
              s: [446.03900000000004, 400.4239999999999, 0],
              to: [-19.641, 274.97, 0],
              ti: [8.071, -112.998, 0],
            },
            { t: 89.0000036250443, s: [363.598, 1106.598, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-116.402, -29.402, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [9.195, 9.195], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-116.402, -29.402], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Shape Layer 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 8,
              s: [0],
            },
            { t: 79.000003217736, s: [43] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.665, y: 0.473 },
              o: { x: 0.296, y: 0 },
              t: 8,
              s: [632.34, -31.66, 0],
              to: [0, 37.975, 0],
              ti: [0, -244.601, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.428, y: 0.403 },
              t: 47,
              s: [672.34, 474.06899999999996, 0],
              to: [0, 299.285, 0],
              ti: [0, -63.589, 0],
            },
            { t: 79.000003217736, s: [632.34, 1168.34, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-167.66, -195.66, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [8.68, 8.68], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-167.66, -195.66], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 8.00000032584668,
      op: 158.000006435472,
      st: 8.00000032584668,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 8,
              s: [0],
            },
            { t: 60.0000024438501, s: [360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.658, y: 0.45 },
              o: { x: 0.297, y: 0 },
              t: 8,
              s: [746.02, -53.98, 0],
              to: [2.473, 71.711, 0],
              ti: [-5.935, -172.109, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.423, y: 0.379 },
              t: 33,
              s: [719.652, 341.348, 0],
              to: [10.065, 291.897, 0],
              ti: [-4.194, -121.622, 0],
            },
            { t: 60.0000024438501, s: [786.02, 1106.02, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-213.98, -285.98, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [16.039, 16.039], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-213.98, -285.98], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 8.00000032584668,
      op: 158.000006435472,
      st: 8.00000032584668,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 4,
              s: [0],
            },
            { t: 77.0000031362743, s: [270] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.652, y: 0.487 },
              o: { x: 0.27, y: 0 },
              t: 4,
              s: [427.734, -64.266, 0],
              to: [0, 38.876, 0],
              ti: [0, -269.037, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.427, y: 0.408 },
              t: 45,
              s: [507.7339999999999, 497.41499999999996, 0],
              to: [0, 297.088, 0],
              ti: [0, -65.524, 0],
            },
            { t: 77.0000031362743, s: [427.734, 1175.734, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-492.266, -124.266, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [23.469, 23.469], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-492.266, -124.266], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 4.00000016292334,
      op: 154.000006272549,
      st: 4.00000016292334,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 10,
              s: [0],
            },
            { t: 70.0000028511585, s: [360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.63, y: 0.461 },
              o: { x: 0.248, y: 0 },
              t: 10,
              s: [409.379, -114.73400000000001, 0],
              to: [0, 1.522, 0],
              ti: [33.721, -260.13, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.419, y: 0.393 },
              t: 40,
              s: [386.079, 375.007, 0],
              to: [-44.709, 344.885, 0],
              ti: [0, -4.17, 0],
            },
            { t: 70.0000028511585, s: [249.37899999999996, 1125.266, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [50.758, 10.531], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-110.621, -54.734], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 10.0000004073083,
      op: 160.000006516934,
      st: 10.0000004073083,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 60.0000024438501, s: [360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 0.485 },
              o: { x: 0.296, y: 0 },
              t: 0,
              s: [529.379, -34.734, 0],
              to: [0, 23.736, 0],
              ti: [0, -253.33, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.429, y: 0.405 },
              t: 34,
              s: [609.379, 478.693, 0],
              to: [0, 300.554, 0],
              ti: [0, -21.581, 0],
            },
            { t: 60.0000024438501, s: [529.379, 1125.266, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-110.621, -54.734, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [50.758, 10.531], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.992156982422, 0.772548959769, 0.172548989689, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-110.621, -54.734], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
