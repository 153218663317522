import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApi } from '../api/ApiProvider';
import { SpecialTimezoneVM } from '../generated';
import shortid from 'shortid';
import { useCookies } from 'react-cookie';

type AppContextData = {
  timezones: Array<SpecialTimezoneVM>;
  sessionId: string;
  purchaseAttemptId: string;
  startNewPurchaseAttempt: () => void;
};

const sessionCookieName = 'session-cookie';
const AppContext = createContext<AppContextData>({} as AppContextData);

const AppProvider: React.FC = ({ children }) => {
  const [purchaseAttemptId, setPurchaseAttemptId] = useState<string>(
    shortid.generate()
  );
  const [cookies, setCookie] = useCookies([sessionCookieName]);

  useEffect(() => {
    if (cookies[sessionCookieName] === undefined) {
      setCookie(sessionCookieName, shortid.generate(), { path: '/' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const startNewPurchaseAttempt = () => {
    setPurchaseAttemptId(shortid.generate());
  };
  const [timezones, setTimezones] = useState<Array<SpecialTimezoneVM>>([]);
  const { timezoneApi } = useApi();

  const loadTimezones = async () => {
    setTimezones((await timezoneApi.getTimezones()).data);
  };

  useEffect(() => {
    (async () => {
      await loadTimezones();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <AppContext.Provider
      value={{
        timezones,
        sessionId: cookies[sessionCookieName],
        purchaseAttemptId,
        startNewPurchaseAttempt,
      }}>
      {children}
    </AppContext.Provider>
  );
};

function useAppContext(): AppContextData {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
}

export { AppContext, AppProvider, useAppContext };
