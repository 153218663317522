import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Body from '../../../components/Body';
import Divider from '../../../components/Divider';
import { PurchaseWrapper } from '../../../components/Purchase';
import { usePurchase } from '../../../context/PurchaseProvider';
import {
  getDiscountAmount,
  getSelectedProfileImage,
} from '../../../helpers/cards';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import SomethingWrong from './common/SomethingWrong';
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { usePayment } from '../../../context/PaymentProvider';
import {
  GiftCardProgramType,
  PromotionCodeVM,
  UserGiftCardType,
} from '../../../generated';
import { useApi } from '../../../api/ApiProvider';
import { COMMON_ERR_MSG } from '../../../const/shared';
import Colors from '../../../const/Colors';
import { useAlert } from 'react-alert';
import { useAuthGuard } from '../../../hooks/useAuthGuard';
import { Input } from '../../../components/Input';
import { ErrorText } from '../../../components/ErrorText';
import Promo from '../../../assets/svg/purchase/Promo.svg';
import Spinner from '../../../components/Spinner';
import { Alert } from '../../../components/Alert';
import Success from '../../../assets/svg/alert/Success.svg';
import { RudderStack } from '../../../services/shared/RudderStack';
import EnvConfig from '../../../config/EnvConfig';
import { isBrandMerchant } from '../../../helpers/utils';
import PurchaseBack from '../common/Back';

const BottomText = styled.div`
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-bottom: 3.2rem;
  margin-top: 3.2rem;
`;

const DividerMarginWrapper = styled.div`
  margin: 3.2rem 0;
`;

const PromotionInputWrapper = styled.div`
  margin: 3.2rem 0;
  position: relative;

  input {
    height: 5rem;
    text-indent: 3rem;
    padding-right: 7rem !important;
  }

  img.promo-icon {
    position: absolute;
    z-index: 5;
    top: 1.3rem;
    left: 1.5rem;
  }

  div.apply-btn {
    position: absolute;
    z-index: 5;
    top: 1.4rem;
    font-weight: bold;
    font-size: 1.5rem;
    right: 1.5rem;
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colours.seaGreen};
    }
  }

  div.remove-btn {
    position: absolute;
    z-index: 5;
    top: 1.4rem;
    font-weight: bold;
    font-size: 1.5rem;
    right: 1.5rem;
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colours.red};
    }
  }
  div.apply-spinner {
    position: absolute;
    z-index: 5;
    top: 1.2rem;
    font-weight: bold;
    font-size: 1.5rem;
    right: 1.5rem;
  }
`;

const CardValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3.2rem 0;
  div {
    font-size: 2rem;

    &.right-container {
      font-weight: bold;
    }
  }
`;

const CardElementWrapper = styled.div`
  .StripeElement {
    border: 1px solid ${(props) => props.theme.colours.line2};
    border-radius: 9px;
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .StripeElement--focus {
    border: 2px solid ${(props) => props.theme.colours.black};
  }

  .StripeElement--invalid {
    border: 1px solid ${(props) => props.theme.colours.red};
  }
`;

const ApplePayAndGooglePayWrapper = styled.div`
  margin-top: 2rem;
`;

const PriceWrapper = styled.span`
  color: ${(props) => props.theme.colours.white};
`;

const OriginalPriceWrapper = styled.span`
  margin-left: 0.5rem;
  color: #999;
  text-decoration: line-through;
`;

const ButtonWrapper = styled.div`
  &:hover .button-text {
    color: ${(props) => props.theme.colours.seaGreen};
  }
`;

const Payment: React.FC<{
  changeProviderKey: any;
  promo: PromotionCodeVM;
}> = ({ changeProviderKey, promo }) => {
  const purchase = usePurchase();
  const navigate = useNavigate();
  const { cardDetails: card } = purchase;
  const stripe = useStripe();
  const {
    paymentRequest,
    paymentOptions,
    createPaymentRequest,
    updatePaymentRequest,
    onSubmitCardPayment,
    paymentMethod,
  } = usePayment();

  const [isPaying, setIsPaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState<string>('');
  const [promotionInUse, setPromotionInUse] = useState<
    PromotionCodeVM | undefined
  >(undefined);
  const { giftCardWorkflowApi, promotionApi } = useApi();
  const alert = useAlert();
  const valueToPay = useMemo(() => {
    const value = purchase.newPurchaseDetails.value ?? 0;
    let discount = 0;
    if (!!promotionInUse) {
      discount = getDiscountAmount(value, promotionInUse);
    }
    return value - discount;
  }, [purchase.newPurchaseDetails.value, promotionInUse]);

  const buttonText = useMemo(() => {
    return `SUBMIT PAYMENT • $${valueToPay.toFixed(2)}`;
  }, [valueToPay]);

  useAuthGuard();

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(
      card,
      purchase.newPurchaseDetails.profileId ?? -1
    );
  }, [card, purchase.newPurchaseDetails.profileId]);

  const postPayment = useCallback(
    async (
      success: boolean,
      paymentIntentId?: string | null,
      error?: string
    ) => {
      // if payment works
      if (success && paymentIntentId) {
        try {
          const createPurchaseDetails = { ...purchase.newPurchaseDetails };
          const value = createPurchaseDetails.value ?? 0;
          createPurchaseDetails.deliveryDate = createPurchaseDetails.sendNow
            ? new Date().toISOString()
            : purchase.newPurchaseDetails.deliveryDate;
          createPurchaseDetails.cardType = UserGiftCardType.Digital;
          createPurchaseDetails.paymentIntentId = paymentIntentId;
          createPurchaseDetails.promoCode = promotionInUse?.promoCode;
          createPurchaseDetails.discountAmount = Number.parseFloat(
            (value - valueToPay).toFixed(2)
          );
          createPurchaseDetails.paymentProvider =
            card.isPcloPromotion && !promo ? 'StripeOffer' : 'StripeConsumer';

          const result = await giftCardWorkflowApi.createGiftCard(
            undefined,
            createPurchaseDetails
          );
          // if our backend works
          if (result.data) {
            let trackingData: any = {
              product_type: 'Digital Gift Card',
              order_id: result.data.toString(),
              subtotal: purchase.newPurchaseDetails.value,
              total: valueToPay,
              revenue: valueToPay,
              currency: 'AUD',
              card_name: card.name ?? '',
              card_category:
                card.giftCardProgram?.specialCategories?.[0]?.name ?? '',
              card_family:
                card.giftCardProgram?.giftCardProgramType ===
                GiftCardProgramType.Brand
                  ? 'Brand'
                  : 'Category',
              card_image_url: cardFrontImage,
              delivery_channel: purchase.newPurchaseDetails.sendingMethod,
              deliver_immediately: purchase.newPurchaseDetails.sendNow,
              delivery_time: purchase.newPurchaseDetails.deliveryDate,
              payment_method: paymentMethod?.current || 'App Pay',
              products: [
                {
                  product_id: purchase.cardDetails.id?.toString(),
                  sku: card.giftCardProgram?.referenceId,
                  name: card.name,
                  price: purchase.newPurchaseDetails.value,
                  quantity: 1,
                  category:
                    card.giftCardProgram?.specialCategories?.[0]?.name ?? '',
                  url: `${EnvConfig.webUrl}/shop/${purchase.cardDetails.id}`,
                  image_url: cardFrontImage,
                },
              ],
            };

            if (
              card.giftCardProgram?.giftCardProgramType ===
              GiftCardProgramType.Brand
            ) {
              trackingData = {
                ...trackingData,
                use_instore:
                  !!purchase.merchantLocations &&
                  purchase.merchantLocations > 0,
                use_online:
                  card.giftCardProgram?.brandMerchant?.hasOnlineStore ?? false,
              };
            }

            if (purchase.newPurchaseDetails.sendingMethod === 'Email') {
              trackingData = {
                ...trackingData,
                recipient_email:
                  purchase.newPurchaseDetails.receiverEmail ?? '',
              };
            } else {
              trackingData = {
                ...trackingData,
                recipient_phone:
                  purchase.newPurchaseDetails.receiverPhoneNumber ?? '',
              };
            }
            if (!!promotionInUse) {
              trackingData = {
                ...trackingData,
                coupon: promoCode,
                discount: getDiscountAmount(value, promotionInUse),
              };
            }
            RudderStack.track('Order Completed', trackingData);
            navigate('/purchase/success', {
              state: {
                order: result.data,
                promoCode: createPurchaseDetails.promoCode,
                discountAmount: createPurchaseDetails.discountAmount,
              },
            });
          } else {
            alert.error(COMMON_ERR_MSG);
            navigate('/purchase/failed');
          }
        } catch (err) {
          console.log(err);
          setIsPaying(false);
        }
      } else if (error) {
        setHideModal(false);
      }
    },
    [
      alert,
      giftCardWorkflowApi,
      purchase.newPurchaseDetails,
      navigate,
      promotionInUse,
      valueToPay,
      card.name,
      cardFrontImage,
      promoCode,
      paymentMethod,
      card.giftCardProgram?.specialCategories,
      card.giftCardProgram?.referenceId,
      card.giftCardProgram?.giftCardProgramType,
      card.giftCardProgram?.brandMerchant?.hasOnlineStore,
      purchase.merchantLocations,
      purchase.cardDetails.id,
      card.isPcloPromotion,
      promo,
    ]
  );

  useEffect(() => {
    RudderStack.page('Purchase_Payment');
  }, []);

  useEffect(() => {
    if (isLoading && !!stripe && !paymentRequest) {
      createPaymentRequest('Special', valueToPay);
      setIsLoading(false);
    }
  }, [createPaymentRequest, isLoading, stripe, valueToPay, paymentRequest]);

  const [hideModal, setHideModal] = useState(true);
  const [promoErrorText, setPromoErrorText] = useState('');
  const [isApplying, setIsApplying] = useState<boolean>(false);

  useEffect(() => {
    updatePaymentRequest(
      purchase.cardDetails.id ?? -1,
      purchase.cardDetails.name ?? '',
      purchase.newPurchaseDetails.profileId ?? -1,
      valueToPay,
      promotionInUse?.promoCode ?? '',
      purchase.newPurchaseDetails.receiverEmail ?? '',
      `${purchase.newPurchaseDetails.receiverFirstName} ${purchase.newPurchaseDetails.receiverLastName}`,
      purchase.newPurchaseDetails.receiverPhoneNumber ?? '',
      card.isPcloPromotion && !promo ? 'StripeOffer' : 'StripeConsumer',
      async (success, paymentIntentId, error) => {
        setIsPaying(true);
        try {
          await postPayment(success, paymentIntentId, error);
          setIsPaying(false);
        } catch {
          alert.error(COMMON_ERR_MSG);
          setIsPaying(false);
        }
      }
    );
  }, [
    valueToPay,
    purchase.newPurchaseDetails.profileId,
    purchase.cardDetails,
    postPayment,
    alert,
    updatePaymentRequest,
    promotionInUse,
    purchase.newPurchaseDetails.receiverEmail,
    purchase.newPurchaseDetails.receiverFirstName,
    purchase.newPurchaseDetails.receiverLastName,
    purchase.newPurchaseDetails.receiverPhoneNumber,
    promo,
    card.isPcloPromotion,
  ]);

  const onHide = () => {
    setHideModal(true);
  };

  const removePromo = () => {
    setPromoCode('');
    setPromotionInUse(undefined);

    if (card.isPcloPromotion) changeProviderKey(true, false, undefined);
  };

  const applyPromo = async () => {
    if (isApplying) {
      return;
    }
    setIsApplying(true);
    try {
      const result = await promotionApi.validatePromoCode(promoCode, card.id);

      if (!result.data) {
        setPromoErrorText('Invalid promo code');
        if (card.isPcloPromotion) changeProviderKey(true, false, undefined);
      }

      if (promotionInUse === undefined) {
        setPromoErrorText('');
        setPromotionInUse(result.data);
        if (card.isPcloPromotion) changeProviderKey(true, true, result.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'Invalid promo code';
      setPromoErrorText(errorMessage);
      setIsApplying(false);
    }

    setIsApplying(false);
  };

  useEffect(() => {
    if (card.isPcloPromotion && !promo) {
      changeProviderKey(true, false, undefined);
    } else if (promo) {
      setPromoErrorText('');
      setPromoCode(promo.promoCode ?? '');
      setPromotionInUse(promo);
    }
  }, [card.isPcloPromotion, promoCode, changeProviderKey, promo]);

  const backLink = '/purchase/summary';
  const isBranded = isBrandMerchant(card);

  return (
    <Body
      theme="white"
      disableFooter
      headerDisableShopNow
      headerVariant="purchase"
      displayMobileMenu={false}>
      <div className="content">
        {!hideModal && <SomethingWrong onHide={onHide} />}
        <div className="purchase-delivery">
          <PurchaseBack isBranded={isBranded} backLink={backLink} />
          <PurchaseWrapper
            giftCard={card}
            showBackButton={false}
            cardImage={cardFrontImage}
            waveBackLink={backLink}>
            <div className="purchase-flow-header">Payment</div>
            <div className="purchase-flow-description">
              Enter your payment details & complete your order.
            </div>

            <CardValueWrapper>
              <div className="left-container">Gift Card value</div>
              <div className="right-container">{`$${purchase.newPurchaseDetails.value?.toFixed(
                2
              )}`}</div>
            </CardValueWrapper>
            <PromotionInputWrapper>
              <img className="promo-icon" src={Promo} alt="Promo"></img>
              {!!promotionInUse ? (
                <>
                  <div onClick={removePromo} className="remove-btn">
                    REMOVE
                  </div>
                </>
              ) : (
                <>
                  {isApplying ? (
                    <div className="apply-spinner">
                      <Spinner size={25} />
                    </div>
                  ) : (
                    <div onClick={applyPromo} className="apply-btn">
                      APPLY
                    </div>
                  )}
                </>
              )}

              <Input
                type="text"
                label="Promo code"
                placeholder="Enter promo code"
                value={promoCode}
                editable={promotionInUse === undefined}
                onChange={(e) => {
                  setPromoErrorText('');
                  setPromoCode(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value && applyPromo();
                }}
                error={!!promoErrorText}
                className="inputSection"
              />
              {promoErrorText && (
                <div className="errorText">
                  <ErrorText
                    style={{ paddingBottom: 0 }}
                    text={promoErrorText}
                  />
                </div>
              )}
              {promotionInUse && (
                <>
                  <Alert
                    style={{ width: '100%' }}
                    title="Your code was successfully entered. Enjoy!"
                    role="Success"
                    icon={Success}
                  />
                </>
              )}
            </PromotionInputWrapper>
            {paymentRequest && paymentOptions.googlePay && (
              <>
                <DividerMarginWrapper>
                  <Divider text="PAY WITH GOOGLE PAY" />
                </DividerMarginWrapper>

                <ApplePayAndGooglePayWrapper>
                  <PaymentRequestButtonElement
                    options={{ paymentRequest: paymentRequest }}
                  />
                </ApplePayAndGooglePayWrapper>
              </>
            )}
            {paymentRequest && paymentOptions.applePay && (
              <>
                <DividerMarginWrapper>
                  <Divider text="PAY WITH APPLE PAY" />
                </DividerMarginWrapper>

                <ApplePayAndGooglePayWrapper>
                  <PaymentRequestButtonElement
                    options={{ paymentRequest: paymentRequest }}
                  />
                </ApplePayAndGooglePayWrapper>
              </>
            )}
            <DividerMarginWrapper>
              <Divider
                text={`${
                  !!paymentRequest &&
                  (!!paymentOptions.applePay || !!paymentOptions.googlePay)
                    ? 'OR '
                    : ''
                }PAY WITH CARD`}
              />
            </DividerMarginWrapper>
            <CardElementWrapper>
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: '16px',
                      color: Colors.black,
                      letterSpacing: '0.025em',
                      fontFamily: 'Agrandir-Narrow',
                      '::placeholder': {
                        color: Colors.line1,
                      },
                    },
                    invalid: {
                      color: Colors.red,
                    },
                  },
                }}
              />
            </CardElementWrapper>
            <BottomText>
              By continuing, I understand and agree to the{' '}
              <Link to="/privacy-policy">Special Privacy Policy</Link> and the{' '}
              <Link to="/terms-conditions">Special Terms and Conditions</Link>{' '}
              and that I am authorised to provide information on behalf of the
              recipient.
            </BottomText>

            <ButtonWrapper>
              <Button
                role="Primary"
                loading={isPaying}
                onClick={async () => {
                  if (
                    !purchase.cardDetails.id ||
                    purchase.cardDetails.id <= 0 ||
                    !purchase.newPurchaseDetails.profileId ||
                    purchase.newPurchaseDetails.profileId <= 0 ||
                    !purchase.newPurchaseDetails.value
                  ) {
                    alert.error('Invalid data');
                    return;
                  }
                  if (
                    purchase.newPurchaseDetails.value <
                      (purchase.cardDetails.minimumValue ?? 5) ||
                    purchase.newPurchaseDetails.value >
                      (purchase.cardDetails.maximumValue ?? 1000)
                  ) {
                    alert.error('Invalid data');
                    return;
                  }
                  if (isPaying) {
                    return;
                  }
                  setIsPaying(true);
                  const { success, paymentIntentId, error } =
                    await onSubmitCardPayment(
                      purchase.cardDetails.id,
                      purchase.newPurchaseDetails.profileId,
                      valueToPay,
                      promotionInUse?.promoCode ?? '',
                      purchase.newPurchaseDetails.receiverEmail ?? '',
                      `${purchase.newPurchaseDetails.receiverFirstName} ${purchase.newPurchaseDetails.receiverLastName}`,
                      purchase.newPurchaseDetails.receiverPhoneNumber ?? '',
                      card.isPcloPromotion && !promo
                        ? 'StripeOffer'
                        : 'StripeConsumer'
                    );
                  try {
                    await postPayment(success, paymentIntentId, error);
                  } catch (err: any) {
                    console.log(err);
                    setIsPaying(false);
                  }
                  setIsPaying(false);
                  changeProviderKey(undefined, undefined, undefined);
                }}>
                <PriceWrapper className="button-text">
                  {buttonText}
                </PriceWrapper>
                {promotionInUse && (
                  <OriginalPriceWrapper className="button-text">
                    ${purchase.newPurchaseDetails.value?.toFixed(2)}
                  </OriginalPriceWrapper>
                )}
              </Button>
            </ButtonWrapper>
          </PurchaseWrapper>
        </div>
      </div>
    </Body>
  );
};

export default Payment;
