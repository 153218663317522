import React, { useEffect, useMemo, useState } from 'react';
import { useActivation } from '../../../context/ActivationProvider';
import ActivationTopBar from '../common/Topbar';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import CardPin, { CardPinStatus } from '../../../components/CardPin';
import { useNavigate } from 'react-router-dom';
import { validatePin } from '../../../helpers/utils';
import { ErrorText } from '../../../components/ErrorText';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Breakpoints from '../../../const/breakpoints';
import ArrowBack from '../../../components/ArrowBack';

import './styles.scss';
import Colors from '../../../const/Colors';
import {
  DEFAULT_PIN_ERR_MSG,
  DEFAULT_PIN_WARNING_MSG,
  PIN_LENGTH,
  SHOW_AFFILIATION_TEXT_IN_ANIMATION,
  SHOW_BRAND_LOGO_IN_ANIMATION,
} from '../../../const/shared';
import ActivationCard from '../common/ActivationCard';
import { RudderStack } from '../../../services/shared/RudderStack';
import { useActivationBrands } from '../hooks/useActivationBrands';
import {
  ActivationTopBarWrapper,
  AffiliationText,
  Logo,
  LogoWrapper,
  StaticAffilationText,
} from '../common/styles';
import {
  ACTIVATION_AFFILIATION_TEXT,
  ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
} from '../constants';
import { useAuth } from '../../../context/AuthProvider';

type Props = {};

const SetPin = (props: Props) => {
  const activation = useActivation();
  const navigate = useNavigate();
  const { userDetailsVM } = useAuth();
  const userPhoneNumber = userDetailsVM?.mobileNumber || '';
  const panLast4 = activation.activationData.activationDetails.panLast4 || '';
  const [status, setStatus] = useState<CardPinStatus>();
  const { windowWidth } = useWindowDimensions();
  const pinSize = useMemo(
    () => (windowWidth < Breakpoints.tablet ? 'small' : 'big'),
    [windowWidth]
  );

  useEffect(() => {
    RudderStack.page('Activation_SetPin');
  }, []);

  const onComplete = (pin: string) => {
    if (validatePin(pin, PIN_LENGTH, userPhoneNumber, panLast4)) {
      activation.setActivationData({
        ...activation.activationData,
        pin,
      });
      navigate('/activate/confirm-pin');
    } else {
      setStatus('error');
    }
  };

  const {
    affiliationTextColour,
    backgroundColor,
    displayAffiliationText,
    logo,
  } = useActivationBrands();

  return (
    <div className="activationSetPin" style={{ backgroundColor }}>
      <ActivationTopBarWrapper>
        <ActivationTopBar showFAQ={false} backText="BACK" />
      </ActivationTopBarWrapper>
      <div className="nonTopBack">
        <ArrowBack text="BACK" />
      </div>
      {SHOW_BRAND_LOGO_IN_ANIMATION && (
        <LogoWrapper>
          <Logo src={logo || ''} alt="" />
        </LogoWrapper>
      )}
      <div className="setPinCardWrapper">
        <ActivationCard />
      </div>
      {SHOW_AFFILIATION_TEXT_IN_ANIMATION && displayAffiliationText && (
        <AffiliationText
          style={{
            color:
              affiliationTextColour ||
              ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
          }}>
          {ACTIVATION_AFFILIATION_TEXT}
        </AffiliationText>
      )}
      <Border height="62" fill={Colors.white} className="scallop" />

      <div className="pinSection">
        <div className="pinSectionWrapper">
          <div className="title">Set PIN to activate Card</div>

          <div className="pinInput">
            <CardPin
              length={4}
              placeholder=""
              onComplete={onComplete}
              hideValue
              status={status}
              size={pinSize}
            />
            {status === 'error' && (
              <div className="pinErrorText">
                <ErrorText text={DEFAULT_PIN_ERR_MSG} />
              </div>
            )}
          </div>

          <div className="pinWarning">{DEFAULT_PIN_WARNING_MSG}</div>
          {displayAffiliationText && (
            <StaticAffilationText>
              {ACTIVATION_AFFILIATION_TEXT}
            </StaticAffilationText>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetPin;
