import React, { useEffect, useState } from 'react';
import PinInputGrid from './PinInputGrid';

import './styles.scss';

export type CardPinStatus = 'success' | 'error' | undefined;

interface CardInputProps {
  length: number;
  placeholder: string;
  status?: CardPinStatus;
  size?: 'small' | 'big';
  onComplete: (pin: string) => void;
  hideValue?: boolean;
}

const CardPin: React.FC<CardInputProps> = ({
  length = 4,
  placeholder = '',
  status,
  size = 'small',
  onComplete,
  hideValue = false,
}) => {
  const [pin, setPin] = useState<Array<number | undefined>>(new Array(length));
  const [completePin, setCompletePin] = useState<string | undefined>(undefined);

  const onPinChange = (pinEntry: number | undefined, index: number) => {
    setPin((oldpin) => {
      const newPin = [...oldpin];
      newPin[index] = pinEntry;
      return newPin;
    });
  };

  const onPinFocus = (index: number) => {
    setPin((oldpin) => {
      const newPin = [...oldpin];
      newPin.fill(undefined, index, length);
      return newPin;
    });
  };

  useEffect(() => {
    completePin && onComplete(completePin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completePin]);

  useEffect(() => {
    if (!pin.includes(undefined)) {
      const cardPin = pin.join('');
      if (cardPin !== completePin) {
        setCompletePin(cardPin);
      }
    } else {
      setCompletePin(undefined);
    }
  }, [pin, completePin]);

  return (
    <div className="cardPin">
      <PinInputGrid
        onPinChange={onPinChange}
        pin={pin}
        pinInputLength={length}
        placeholder={placeholder}
        status={status}
        size={size}
        hideValue={hideValue}
        onPinFocus={onPinFocus}
      />
    </div>
  );
};

export default CardPin;
