import React from 'react';
import ContentLoader from 'react-content-loader';

type Props = {};

const TransactionLoader = (props: Props) => {
  return (
    <ContentLoader viewBox="0 0 380 40">
      <rect x="1" y="22" rx="3" ry="3" width="88" height="6" />
      <rect x="2" y="6" rx="3" ry="3" width="52" height="6" />
      <rect x="307" y="6" rx="3" ry="3" width="52" height="6" />
    </ContentLoader>
  );
};

export default TransactionLoader;
