import React, { CSSProperties } from 'react';
import Colors from '../../../../../const/Colors';

interface OwnProps {
  styles?: CSSProperties;
}
const Divider: React.FC<OwnProps> = ({ styles }) => {
  return (
    <div
      style={{
        height: 1,
        backgroundColor: Colors.socialBorderColor,
        ...styles,
      }}
    />
  );
};

export default Divider;
