import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const Wrapper = styled.div`
  background: ${Colors.softLillac};
`;

export const Container = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  color: ${Colors.black};
  min-height: 100vh;
  ${map({ tablet: 'flex' }, (display) => `display: ${display};`)};
  ${map(
    { tablet: 'column' },
    (flexDirection) => `flex-direction: ${flexDirection};`
  )};
  ${map(
    { tablet: 'flex-start' },
    (alignItems) => `align-items: ${alignItems};`
  )};
  ${map(
    { tablet: 'space-between' },
    (justifyContent) => `justify-content: ${justifyContent};`
  )};
`;

export const PaddedWrapper = styled.div`
  padding: 0 1.6rem;
  ${map({ tablet: '0 3.2rem' }, (padding) => `padding: ${padding};`)}
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  ${map(
    { tablet: 'row' },
    (flexDirection) => `flex-direction: ${flexDirection};`
  )};
  ${map(
    { tablet: 'space-between' },
    (justifyContent) => `justify-content: ${justifyContent};`
  )};
  margin-top: 5.2rem;
`;

export const OopsText = styled.div`
  font-size: 8.8rem;
  ${map({ tablet: '17.2rem' }, (fontSize) => `font-size: ${fontSize};`)}
  line-height: 7.9rem;
  ${map({ tablet: '14.6rem' }, (lineHeight) => `line-height: ${lineHeight};`)}
  ${map({ tablet: '95rem' }, (maxWidth) => `max-width: ${maxWidth};`)}
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Kobe';
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const EyeStyles = styled.div`
  width: 12.8rem;
  ${map({ tablet: '23rem' }, (width) => `width: ${width};`)};
  ${map({ tablet: '23rem' }, (minWidth) => `min-width: ${minWidth};`)};
  height: 10.2rem;
  ${map({ tablet: '18.3rem' }, (height) => `height: ${height};`)};
  ${map({ tablet: '18.3rem' }, (minHeight) => `min-height: ${minHeight};`)};
  align-self: flex-end;
  ${map({ tablet: 'flex-end' }, (alignSelf) => `align-self: ${alignSelf};`)};
  margin-top: 2.4rem;
  ${map({ tablet: '0rem' }, (marginTop) => `margin-top: ${marginTop};`)};
`;

export const SomethingText = styled.div`
  font-size: 2.4rem;
  ${map({ tablet: '3.2rem' }, (fontSize) => `font-size: ${fontSize};`)};
  line-height: 3.2rem;
  ${map({ tablet: '4.3rem' }, (lineHeight) => `line-height: ${lineHeight};`)};
  letter-spacing: 0.05em;
  color: ${Colors.black};
  font-weight: bold;
  margin-top: 1.76rem;
  ${map({ tablet: '6.14rem' }, (marginTop) => `margin-top: ${marginTop};`)};
`;

export const Divider = styled.div`
  height: 0.1rem;
  background-color: ${Colors.black};
  margin-top: 3rem;
  margin-bottom: 3rem;
  ${map(
    { tablet: '4rem' },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
  ${map({ tablet: '4.4rem' }, (marginTop) => `margin-top: ${marginTop};`)};
`;

export const Message = styled.div`
  font-size: 2.4rem;
  line-height: 3.4rem;
  margin-bottom: 3rem;
`;

export const ButtonWrapper = styled.div`
  padding-bottom: 3.8rem;
  ${map({ tablet: '22.6rem' }, (maxWidth) => `max-width: ${maxWidth};`)};
`;
