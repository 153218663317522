import { useEffect, useMemo } from 'react';
import Body from '../../components/Body';
import { Hero, HeroSubtitle } from '../../components/Hero';
import NoMorePlastics from '../../assets/svg/opt-in/NoMorePlastics.svg';
import CustomerJourney from '../../assets/svg/opt-in/CustomerJourney.svg';
import ImageCard from '../../assets/svg/opt-in/ImageCard.svg';
import RedHeart from '../../assets/svg/opt-in/RedHeart.svg';
import Online from '../../assets/svg/opt-in/Online.svg';
import TopStars from '../../assets/svg/opt-in/TopStars.svg';
import BottomStars from '../../assets/svg/opt-in/BottomStars.svg';
import './opt-in.scss';
import { RudderStack } from '../../services/shared/RudderStack';
import { Helmet } from 'react-helmet';
import { Button } from '../../components/Button';
import Banner from '../../components/Banner';
import CardExample from '../../assets/svg/CardExample.svg';
import { useNavigate } from 'react-router-dom';
import Colors from '../../const/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Breakpoints from '../../const/breakpoints';
import RightBlackCircleArrow from '../../assets/svg/opt-in/RightBlackCircleArrow.svg';

const OptIn: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RudderStack.page('ClaimMyBusiness');
  }, []);
  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  return (
    <Body theme="sea-green" hideCtaBanner={true}>
      <Helmet>
        <title>Special Gift Cards | Claim my business</title>
      </Helmet>
      <div className="content">
        <Hero>
          <div className="opt-in-hero-title">
            CLAIM YOUR BUSINESS ON SPECIAL
          </div>

          <div className="opt-in-hero-title opt-in-inverse">
            SIGN UP F
            <span className="big-o">
              O<img src={RedHeart} className="red-heart" alt="RedHeart"></img>
            </span>
            R FREE
          </div>
          <div className="opt-in-subtitle">
            <div className="right-subtitle">
              <div
                onClick={() => {
                  navigate('/claim-my-business/info');
                }}>
                <span>Claim now</span>
                <img src={RightBlackCircleArrow} alt="RightBlackCircleArrow" />
              </div>
            </div>
            <HeroSubtitle>
              Gift Cards just became{' '}
              <strong>
                Special<span>&trade;</span>
              </strong>
            </HeroSubtitle>
          </div>
        </Hero>
        <div className="opt-in">
          <div className="hit-section">
            <div className="hit-header">
              <img className="top-stars" src={TopStars} alt="TopStars" />3
              simple {isMobileView && <br />} steps to
              <br />
              claim your {isMobileView && <br />} business
            </div>
            <Button
              title="CLAIM MY BUSINESS"
              startIconClass="heart-icon"
              onClick={() => {
                navigate('/claim-my-business/info');
              }}
              role="Secondary"
              style={{ width: 'auto', margin: '5rem auto 0 auto' }}
            />
            <div className="hit-content">
              <div>
                <div className="hit-image heart">
                  <img src={CustomerJourney} alt="Find the perfect gift" />
                </div>
                <div className="hit-title">
                  Marketing
                  <br /> Opportunities
                </div>
                <div className="hit-description">
                  Special will promote your brand to millions of eyeballs
                  through our various marketing campaigns across multiple
                  channels, completely for free.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img src={NoMorePlastics} alt="Turn up the fun " />
                </div>
                <div className="hit-title">Distribution Growth</div>
                <div className="hit-description">
                  Special will push your branded cards to new distribution
                  channels, acquiring new customers in the process.
                </div>
              </div>
              <div>
                <div className="hit-image">
                  <img src={Online} alt="Schedule for a Special occasion" />
                </div>
                <div className="hit-title">
                  Enriched Business <br /> Content
                </div>
                <div className="hit-description">
                  By claiming your business, this will allow you to access your
                  brand page, creating enriched content that will update and
                  educate your customers with your latest brand information.
                </div>
              </div>
            </div>
          </div>
          <div className="opt-in-container">
            <img src={ImageCard} className="image-card" alt="ImageCard"></img>
            <div style={{ position: 'relative' }}>
              {!isMobileView && (
                <img
                  className="bottom-stars"
                  src={BottomStars}
                  alt="BottomStars"
                />
              )}
              <div className="opt-in-header">What is Shop By Brand? </div>
              <div className="out-out-description">
                Specials Shop By Brand creates a closed-loop Gift Card for the
                biggest names in Australian retail, from local heroes to
                industry legends. These cards are customised to each brand,
                ensuring customers who receive these gift cards can use them
                both in-store and online at the destination of their choice.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner className="custom-banner">
        <div className="cta-container">
          <img src={CardExample} alt="Join Special Today" />
          <div>
            <h2
              className="footer-heading"
              style={{ marginTop: isMobileView ? '2rem' : '0' }}>
              Join Special today
            </h2>
            <p>
              Special is an Australian digital gifting company that is on a
              mission to connect people through special moments and happiness.
            </p>
          </div>
          {!isMobileView && (
            <div className="flex-fill">
              <Button
                title="Claim my business"
                startIconClass="red-heart-icon"
                onClick={() => {
                  navigate('/claim-my-business/info');
                }}
                role="Primary"
                color="white"
                style={{ color: Colors.black, width: '27.5rem' }}
              />
            </div>
          )}
        </div>
        {isMobileView && (
          <Button
            title="Claim my business"
            startIconClass="red-heart-icon"
            onClick={() => {
              navigate('/claim-my-business/info');
            }}
            role="Primary"
            color="white"
            style={{
              color: Colors.black,
              marginBottom: '3rem',
              marginTop: '2rem',
            }}
          />
        )}
      </Banner>
    </Body>
  );
};
export default OptIn;
