import React, { createContext, useContext } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

export interface IOptInOutData {
  businessName: string;
  name: string;
  jobTitle: string;
  email: string;
  phoneNumber?: string;
}

interface IOptInOutContextData {
  optInOutData?: IOptInOutData;
  saveOptInOutData: (optInOutData: IOptInOutData) => void;
  startOptInOut: () => void;
}

const OptInOutContext = createContext<IOptInOutContextData>(
  {} as IOptInOutContextData
);

const defaultOptInOutData = {
  businessName: '',
  name: '',
  jobTitle: '',
  email: '',
  phoneNumber: '',
};

const OptInOutProvider: React.FC = ({ children }) => {
  const [data, setData] = useLocalStorage<IOptInOutData>(
    'optInOutData',
    defaultOptInOutData
  );
  return (
    <OptInOutContext.Provider
      value={{
        optInOutData: data,
        saveOptInOutData: setData,
        startOptInOut: () => setData(defaultOptInOutData),
      }}>
      {children}
    </OptInOutContext.Provider>
  );
};

function useOptInOut(): IOptInOutContextData {
  const context = useContext(OptInOutContext);

  if (!context) {
    throw new Error('useOptInOut must be used within an AuthProvider');
  }

  return context;
}

export { OptInOutContext, OptInOutProvider, useOptInOut };
