import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const PurchaseSucess = styled.div`
  background-color: ${Colors.seaGreen};
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const CelebrationAnimationsStyle = styled.div`
  position: absolute;
  width: 100%;
  z-index: 3;
`;

export const CardSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  position: relative;
`;

export const Woohoo = styled.div`
  position: absolute;
  animation: woohoo-text-mobile 4s ease-in-out 1 forwards alternate;
  ${map(
    { tablet: 'woohoo-text 4s ease-in-out 1 forwards alternate' },
    (animation) => `animation: ${animation};`
  )}
  @keyframes woohoo-text-mobile {
    from {
      left: 100%;
    }
    to {
      left: -80rem;
    }
  }
  @keyframes woohoo-text {
    from {
      left: 100%;
    }
    to {
      left: -165rem;
    }
  }
`;

export const StaticAffilationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: ${Colors.black};
  margin-top: 1.5rem;
  ${map({ tablet: '3.5rem' }, (marginTop) => `margin-top: ${marginTop};`)}
  text-align: center;
`;

export const AffiliationText = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  position: absolute;
  top: 80%;
  ${map({ tablet: '96%' }, (top) => `top: ${top};`)}
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: affiliation-animation-mobile 1.1s ease-in-out 5.5s 1 forwards
    alternate;
  ${map(
    {
      tablet:
        'affiliation-animation 1.21s ease-in-out 5.515s 1 forwards alternate',
    },
    (animation) => `animation: ${animation};`
  )}
  @keyframes affiliation-animation-mobile {
    from {
      top: 80%;
    }

    to {
      top: 34.5rem;
    }
  }
  @keyframes affiliation-animation {
    from {
      top: 96%;
    }

    to {
      top: 38.5rem;
    }
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  animation: logo-animate-mobile 6s ease-in-out forwards 1 alternate;
  top: -5rem;
  ${map({ tablet: '-3rem' }, (top) => `top: ${top};`)}
  ${map(
    { tablet: 'logo-animate 6s linear forwards 1 alternate' },
    (animation) => `animation: ${animation};`
  )}

  @keyframes logo-animate-mobile {
    0% {
      transform: scale(1.5);
      top: -5rem;
    }

    50% {
      transform: scale(1.5);
      top: -5rem;
    }

    75% {
      transform: scale(1);
      top: -5rem;
    }

    100% {
      top: -6rem;
    }
  }

  @keyframes logo-animate {
    0% {
      transform: scale(1.5);
      top: -3rem;
    }

    50% {
      transform: scale(1.5);
      top: -3rem;
    }

    75% {
      transform: scale(1);
      top: -3rem;
    }

    100% {
      top: -5rem;
    }
  }
`;

export const Logo = styled.img`
  max-width: 16rem;
`;

export const WoohooText = styled.div`
  color: ${Colors.white};
  font-size: 14.5rem;
  line-height: 20.4rem;
  font-family: 'Kobe';
  font-weight: bold;
  ${map({ tablet: '30rem' }, (size) => `font-size: ${size};`)}
  ${map({ tablet: '42rem' }, (lineHeight) => `line-height: ${lineHeight};`)}
`;

export const CardWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  animation: card-animate-mobile 6s ease-in-out forwards 1 alternate;
  ${map(
    { tablet: 'card-animate 6s ease-in-out forwards 1 alternate' },
    (animation) => `animation: ${animation};`
  )}
  ${map({ tablet: 'absolute' }, (position) => `position: ${position};`)}

  @keyframes card-animate-mobile {
    0% {
      transform: translate(0, -50%) rotate(34deg) scale(1);
      top: 50%;
    }
    50% {
      transform: translate(0, -50%) rotate(0deg) scale(1);
      top: 50%;
    }
    100% {
      top: 6rem;
    }
  }

  @keyframes card-animate {
    0% {
      transform: translate(0, -50%) rotate(34deg) scale(1.5);
      top: 50%;
    }
    50% {
      transform: translate(0, -50%) rotate(0deg) scale(1.5);
      top: 50%;
    }

    75% {
      transform: translate(0, -50%) scale(1);
      top: 50%;
    }

    100% {
      top: 8rem;
    }
  }
`;

export const Scallop = styled.div`
  position: relative;
  top: 7rem;
  z-index: 4;
  animation: scallop-animation 2s ease-in-out 5s 1 forwards alternate;
  ${map(
    { tablet: 'scallop-animation 2s ease-in-out 5s 1 forwards alternate' },
    (animation) => `animation: ${animation};`
  )}
  @keyframes scallop-animation {
    from {
      top: 100%;
    }

    to {
      top: 35px;
      height: 62px;
    }
  }
`;

export const Details = styled.div`
  word-break: break-word;
  background-color: ${Colors.white};
  position: relative;
  top: 100%;
  width: 100%;
  height: 0;
  overflow-y: scroll;
  z-index: 5;
  max-height: 95rem;
  ${map({ tablet: 'unset' }, (maxHeight) => `max-height: ${maxHeight};`)};
  animation: details-animation-mobile 2s ease-in-out 5s 1 forwards alternate;
  ${map(
    { tablet: 'details-animation 2s ease-in-out 5s 1 forwards alternate;' },
    (animation) => `animation: ${animation};`
  )};

  @keyframes details-animation-mobile {
    from {
      top: 100%;
    }

    to {
      top: 0;
      height: calc(100% - 40.5rem);
      overflow: visible;
    }
  }

  @keyframes details-animation {
    from {
      top: 100%;
    }

    to {
      top: 0;
      height: calc(100% - 44.5rem);
      overflow: visible;
    }
  }
`;

export const DetailsWrapper = styled.div`
  padding: 0 3rem;
  padding-top: 2rem;
  ${map({ tablet: '6rem' }, (paddingTop) => `padding-top: ${paddingTop};`)};
  ${map({ tablet: '60rem' }, (maxWidth) => `max-width: ${maxWidth};`)};
  ${map({ tablet: '0 auto' }, (margin) => `margin: ${margin};`)};
  ${map({ tablet: '2rem' }, (marginTop) => `margin-top: ${marginTop};`)};
  ${map({ tablet: '0' }, (padding) => `padding: ${padding};`)};
  padding-bottom: 0.1rem;
`;
