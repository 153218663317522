import { useEffect, useState } from 'react';
import Body from '../../components/Body';
import { useAuth } from '../../context/AuthProvider';
import './AccountSettings.scss';
import ProfileFrame from '../../assets/svg/ProfileFrame.svg';
import NameModal from './NameModal';
import EmailModal from './EmailModal';
import PhoneModal from './PhoneModal';
import PasswordModal from './PasswordModal';
import { partialHidePhone } from '../../util/helpers';
import RightArrow from '../../assets/svg/RightThinArrowBack.svg';
import { useAuthGuard } from '../../hooks/useAuthGuard';
import { RudderStack } from '../../services/shared/RudderStack';
import DeleteAccountModal from './DeleteAccount';
import { useNavigate } from 'react-router-dom';
import { BASE_DELETE_ACCOUNT_ROUTE } from './DeleteAccount/const';
import { useAccountDeletion } from '../../context/AccountDeletionProvider';

const AccountSettings = () => {
  const { userDetailsVM, isSocialAccount, socialProvider } = useAuth();
  const [showNameModal, setShowNameModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { showDeleteAccountModal, setShowDeleteAccountModal } =
    useAccountDeletion();
  const navigate = useNavigate();
  useAuthGuard();
  useEffect(() => {
    RudderStack.page('AccountSettings');
  }, []);

  useEffect(() => {
    if (showDeleteAccountModal) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.touchAction = 'unset';
    };
  }, [showDeleteAccountModal]);
  return (
    <>
      <Body
        theme="white"
        headerVariant="my-cards"
        disableFooter
        displayMobileMenu={false}>
        <div className="settingsPage">
          <div className="settingsContent">
            {/*Title*/}
            <div className="settingsHero">
              <img
                alt="settingsIcon"
                className="settingsIcon"
                src={ProfileFrame}
              />
              <div>
                <p className="settingsHeroTitle">{userDetailsVM?.fullName}</p>
                <p className="settingsHeroSubTitle">
                  Member since{' '}
                  {new Date(userDetailsVM?.dateJoined || '').getFullYear()}
                </p>
              </div>
            </div>
            <div className="settingsDivider" />
            {/*Details*/}
            <p className="settingsHeader">Manage you Details</p>
            <div
              className="settingsField"
              onClick={() => setShowNameModal(true)}>
              <div className="settingsDot red" />
              <div>
                <p className="settingsFieldTitle">{userDetailsVM?.fullName}</p>
                <p className="settingsFieldSubTitle">Update your name</p>
              </div>
              <div style={{ flexGrow: 1 }} />
              <div>
                <img src={RightArrow} className="settingsArrow" alt="" />
              </div>
            </div>
            <div
              className="settingsField"
              onClick={() => setShowEmailModal(true)}>
              <div className="settingsDot orange" />
              <div>
                <p className="settingsFieldTitle">{userDetailsVM?.email}</p>
                <p className="settingsFieldSubTitle">Update your email</p>
              </div>
              <div style={{ flexGrow: 1 }} />
              <div>
                <img src={RightArrow} className="settingsArrow" alt="" />
              </div>
            </div>
            <div
              className="settingsField"
              onClick={() => setShowPhoneModal(true)}>
              <div className="settingsDot lillac" />
              <div>
                <p className="settingsFieldTitle">
                  {partialHidePhone(userDetailsVM?.mobileNumber ?? '', 'X')}
                </p>
                <p className="settingsFieldSubTitle">
                  Update your phone number
                </p>
              </div>
              <div style={{ flexGrow: 1 }} />
              <div>
                <img src={RightArrow} className="settingsArrow" alt="" />
              </div>
            </div>
            <div className="settingsDivider" />
            {/*Account*/}
            <p className="settingsHeader">Manage your Account</p>
            {isSocialAccount ? (
              <>
                <div className="settingsField">
                  <div className="settingsDot seaGreen" />
                  <div>
                    <p className="settingsFieldTitle">Social account</p>
                    <p className="settingsFieldSubTitle">
                      Connected with {socialProvider}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="settingsField"
                  onClick={() => setShowPasswordModal(true)}>
                  <div className="settingsDot seaGreen" />
                  <div>
                    <p className="settingsFieldTitle">Password</p>
                    <p className="settingsFieldSubTitle">
                      Change your password
                    </p>
                  </div>
                  <div style={{ flexGrow: 1 }} />
                  <div>
                    <img src={RightArrow} className="settingsArrow" alt="" />
                  </div>
                </div>
              </>
            )}
            <div
              className="settingsField"
              onClick={() => {
                navigate(BASE_DELETE_ACCOUNT_ROUTE);
                setShowDeleteAccountModal(true);
              }}>
              <div className="settingsDot seaGreen" />
              <div>
                <p className="settingsFieldTitle">Account settings</p>
                <p className="settingsFieldSubTitle">Manage your settings</p>
              </div>
              <div style={{ flexGrow: 1 }} />
              <div>
                <img src={RightArrow} className="settingsArrow" alt="" />
              </div>
            </div>
          </div>
        </div>
      </Body>
      {(showPhoneModal ||
        showNameModal ||
        showEmailModal ||
        showPasswordModal ||
        showDeleteAccountModal) && (
        <div className="settingsModalContainer">
          {showNameModal && <NameModal setOpen={setShowNameModal} />}
          {showEmailModal && (
            <EmailModal open={showEmailModal} setOpen={setShowEmailModal} />
          )}
          {showPhoneModal && <PhoneModal setOpen={setShowPhoneModal} />}
          {showPasswordModal && (
            <PasswordModal setOpen={setShowPasswordModal} />
          )}
          {showDeleteAccountModal && <DeleteAccountModal />}
        </div>
      )}
    </>
  );
};

export default AccountSettings;
