export type StateNames =
  | 'ALL'
  | 'NSW'
  | 'VIC'
  | 'QLD'
  | 'SA'
  | 'WA'
  | 'TAS'
  | 'NT'
  | 'ACT';

export type TState = {
  id: number;
  value?: string;
  label: StateNames;
};

export const States: Record<TState['label'], TState> = {
  ALL: {
    id: 0,
    value: undefined,
    label: 'ALL',
  },
  NSW: {
    id: 1,
    value: 'NSW',
    label: 'NSW',
  },
  VIC: {
    id: 2,
    value: 'VIC',
    label: 'VIC',
  },
  QLD: {
    id: 3,
    value: 'QLD',
    label: 'QLD',
  },
  SA: {
    id: 4,
    value: 'SA',
    label: 'SA',
  },
  WA: {
    id: 5,
    value: 'WA',
    label: 'WA',
  },
  TAS: {
    id: 6,
    value: 'TAS',
    label: 'TAS',
  },
  NT: {
    id: 7,
    value: 'NT',
    label: 'NT',
  },
  ACT: {
    id: 8,
    value: 'ACT',
    label: 'ACT',
  },
};
