import styled from 'styled-components';

export const NoRetailersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 33.4rem;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 0 1.3rem;
`;

export const Title = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  text-align: center;
  font-weight: bold;
  margin-top: 2.3rem;
  margin-bottom: 0.5rem;
`;

export const SubTitle = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  text-align: center;
  opacity: 0.7;
`;
