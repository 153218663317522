export const Fireworks = {
  v: '5.7.4',
  fr: 30,
  ip: 0,
  op: 54,
  w: 978,
  h: 996,
  nm: 'Firework',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Star_yellow Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [516.801, 536.546, 0], ix: 2, l: 2 },
        a: { a: 0, k: [410.919, 404.788, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 3.333] },
              t: 13,
              s: [80, 80, 100],
            },
            { t: 40, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.549, 0.167],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.475],
                    [0, 0],
                    [0, 0],
                    [0.344, -0.461],
                    [0, 0],
                    [0, 0],
                    [0.543, 0.19],
                    [0, 0],
                    [0, 0],
                    [-0.009, 0.577],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.172, -0.551],
                    [0, 0],
                    [0, 0],
                    [0.574, -0.003],
                    [0, 0],
                    [0, 0],
                    [0.182, 0.55],
                    [0, 0],
                    [0, 0],
                    [-0.462, 0.342],
                    [0, 0],
                    [0, 0],
                    [-0.468, -0.337],
                  ],
                  v: [
                    [-7.875, -1.61],
                    [-3.051, -3.08],
                    [-1.533, -7.914],
                    [-0.304, -8.101],
                    [2.592, -3.936],
                    [7.639, -3.964],
                    [8.199, -2.845],
                    [5.165, 1.199],
                    [6.766, 6.016],
                    [5.883, 6.894],
                    [1.11, 5.229],
                    [-2.945, 8.234],
                    [-4.051, 7.658],
                    [-3.966, 2.585],
                    [-8.074, -0.376],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [482.585, 711.659], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 13,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 22,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 31,
                    s: [96.987, 96.987],
                  },
                  { t: 46, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.76, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.063, -0.825],
                    [0, 0],
                    [0, 0],
                    [0.799, -0.191],
                    [0, 0],
                    [0, 0],
                    [0.431, 0.706],
                    [0, 0],
                    [0, 0],
                    [-0.534, 0.628],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.69, -0.449],
                    [0, 0],
                    [0, 0],
                    [0.639, 0.523],
                    [0, 0],
                    [0, 0],
                    [-0.294, 0.771],
                    [0, 0],
                    [0, 0],
                    [-0.822, -0.045],
                    [0, 0],
                    [0, 0],
                    [-0.214, -0.799],
                  ],
                  v: [
                    [-7.638, -10.532],
                    [-0.964, -7.74],
                    [5.089, -11.678],
                    [6.62, -10.759],
                    [6.062, -3.521],
                    [11.678, 1.063],
                    [11.287, 2.808],
                    [4.268, 4.489],
                    [1.685, 11.261],
                    [-0.087, 11.421],
                    [-3.867, 5.222],
                    [-11.079, 4.822],
                    [-11.784, 3.176],
                    [-7.102, -2.336],
                    [-8.975, -9.355],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [407.119, 12.377], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 16,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 25,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 34,
                    s: [96.987, 96.987],
                  },
                  { t: 49, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.76, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.063, -0.824],
                    [0, 0],
                    [0, 0],
                    [0.8, -0.192],
                    [0, 0],
                    [0, 0],
                    [0.431, 0.706],
                    [0, 0],
                    [0, 0],
                    [-0.533, 0.627],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.689, -0.449],
                    [0, 0],
                    [0, 0],
                    [0.641, 0.522],
                    [0, 0],
                    [0, 0],
                    [-0.295, 0.772],
                    [0, 0],
                    [0, 0],
                    [-0.822, -0.046],
                    [0, 0],
                    [0, 0],
                    [-0.213, -0.8],
                  ],
                  v: [
                    [-7.64, -10.532],
                    [-0.965, -7.739],
                    [5.088, -11.678],
                    [6.619, -10.759],
                    [6.062, -3.522],
                    [11.677, 1.064],
                    [11.286, 2.809],
                    [4.267, 4.49],
                    [1.685, 11.261],
                    [-0.087, 11.421],
                    [-3.868, 5.222],
                    [-11.08, 4.822],
                    [-11.785, 3.176],
                    [-7.103, -2.335],
                    [-8.977, -9.355],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [513.802, 78.334], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 13,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 22,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 31,
                    s: [96.987, 96.987],
                  },
                  { t: 46, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.761, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.063, -0.824],
                    [0, 0],
                    [0, 0],
                    [0.799, -0.192],
                    [0, 0],
                    [0, 0],
                    [0.431, 0.706],
                    [0, 0],
                    [0, 0],
                    [-0.534, 0.627],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.688, -0.448],
                    [0, 0],
                    [0, 0],
                    [0.638, 0.523],
                    [0, 0],
                    [0, 0],
                    [-0.294, 0.772],
                    [0, 0],
                    [0, 0],
                    [-0.822, -0.046],
                    [0, 0],
                    [0, 0],
                    [-0.213, -0.8],
                  ],
                  v: [
                    [-7.639, -10.532],
                    [-0.964, -7.739],
                    [5.09, -11.678],
                    [6.62, -10.759],
                    [6.063, -3.52],
                    [11.679, 1.064],
                    [11.288, 2.809],
                    [4.269, 4.49],
                    [1.686, 11.262],
                    [-0.086, 11.421],
                    [-3.867, 5.222],
                    [-11.079, 4.822],
                    [-11.783, 3.176],
                    [-7.1, -2.335],
                    [-8.976, -9.355],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [640.267, 134.069], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 16,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 25,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 34,
                    s: [96.987, 96.987],
                  },
                  { t: 49, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.101, -0.393],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.217],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.147, 0.377],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.249, -0.319],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.139],
                    [0, 0],
                    [0, 0],
                    [-0.014, 0.405],
                    [0, 0],
                    [0, 0],
                    [-0.388, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.225, -0.337],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.943],
                    [0.43, -5.739],
                    [1.284, -5.558],
                    [2.174, -2.114],
                    [5.498, -0.887],
                    [5.592, -0.015],
                    [2.612, 1.889],
                    [2.491, 5.442],
                    [1.696, 5.8],
                    [-1.036, 3.532],
                    [-4.434, 4.501],
                    [-5.021, 3.851],
                    [-3.728, 0.545],
                    [-5.707, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [642.888, 555.338], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 18,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 27,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 36,
                    s: [96.987, 96.987],
                  },
                  { t: 51, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.845, -0.054],
                    [0, 0],
                    [0, 0],
                    [-0.212, -0.824],
                    [0, 0],
                    [0, 0],
                    [0.713, -0.455],
                    [0, 0],
                    [0, 0],
                    [0.653, 0.542],
                    [0, 0],
                    [0, 0],
                    [-0.31, 0.791],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.521, -0.669],
                    [0, 0],
                    [0, 0],
                    [0.795, 0.293],
                    [0, 0],
                    [0, 0],
                    [-0.029, 0.85],
                    [0, 0],
                    [0, 0],
                    [-0.814, 0.232],
                    [0, 0],
                    [0, 0],
                    [-0.473, -0.707],
                  ],
                  v: [
                    [-11.079, -6.657],
                    [-3.665, -6.182],
                    [0.904, -12.056],
                    [2.698, -11.676],
                    [4.565, -4.44],
                    [11.547, -1.863],
                    [11.747, -0.034],
                    [5.487, 3.965],
                    [5.231, 11.43],
                    [3.563, 12.182],
                    [-2.174, 7.417],
                    [-9.313, 9.454],
                    [-10.545, 8.089],
                    [-7.831, 1.145],
                    [-11.987, -5.061],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [809.128, 372.22], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.818, 0.818] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 13,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 24,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 33,
                    s: [96.987, 96.987],
                  },
                  { t: 48, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.075, 1.03],
                    [0, 0],
                    [0, 0],
                    [-0.997, 0.242],
                    [0, 0],
                    [0, 0],
                    [-0.542, -0.878],
                    [0, 0],
                    [0, 0],
                    [0.663, -0.786],
                    [0, 0],
                    [0, 0],
                    [0.951, 0.393],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.649],
                    [0, 0],
                    [0, 0],
                    [0.363, -0.964],
                    [0, 0],
                    [0, 0],
                    [1.026, 0.053],
                    [0, 0],
                    [0, 0],
                    [0.27, 0.997],
                    [0, 0],
                    [0, 0],
                    [-0.858, 0.563],
                  ],
                  v: [
                    [-8.201, 13.446],
                    [-7.539, 4.409],
                    [-14.569, -1.288],
                    [-14.087, -3.467],
                    [-5.336, -5.598],
                    [-2.143, -14.063],
                    [0.07, -14.271],
                    [4.817, -6.55],
                    [13.82, -6.084],
                    [14.706, -4.033],
                    [8.888, 2.869],
                    [11.259, 11.622],
                    [9.596, 13.097],
                    [1.252, 9.642],
                    [-6.285, 14.586],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [661.364, 216.97], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.818, 0.818] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 15,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 26,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 35,
                    s: [96.987, 96.987],
                  },
                  { t: 50, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.075, 1.029],
                    [0, 0],
                    [0, 0],
                    [-0.998, 0.243],
                    [0, 0],
                    [0, 0],
                    [-0.54, -0.88],
                    [0, 0],
                    [0, 0],
                    [0.662, -0.785],
                    [0, 0],
                    [0, 0],
                    [0.951, 0.393],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.649],
                    [0, 0],
                    [0, 0],
                    [0.363, -0.964],
                    [0, 0],
                    [0, 0],
                    [1.026, 0.053],
                    [0, 0],
                    [0, 0],
                    [0.27, 0.997],
                    [0, 0],
                    [0, 0],
                    [-0.859, 0.563],
                  ],
                  v: [
                    [-8.2, 13.447],
                    [-7.539, 4.41],
                    [-14.568, -1.287],
                    [-14.088, -3.467],
                    [-5.335, -5.597],
                    [-2.142, -14.062],
                    [0.068, -14.269],
                    [4.817, -6.549],
                    [13.82, -6.084],
                    [14.707, -4.033],
                    [8.888, 2.869],
                    [11.259, 11.622],
                    [9.596, 13.097],
                    [1.252, 9.642],
                    [-6.285, 14.586],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.47, 76.118], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.867, 0.867] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 13,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 21,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 30,
                    s: [96.987, 96.987],
                  },
                  { t: 45, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.075, 1.029],
                    [0, 0],
                    [0, 0],
                    [-0.998, 0.243],
                    [0, 0],
                    [0, 0],
                    [-0.542, -0.879],
                    [0, 0],
                    [0, 0],
                    [0.662, -0.786],
                    [0, 0],
                    [0, 0],
                    [0.95, 0.393],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.649],
                    [0, 0],
                    [0, 0],
                    [0.363, -0.964],
                    [0, 0],
                    [0, 0],
                    [1.026, 0.053],
                    [0, 0],
                    [0, 0],
                    [0.269, 0.996],
                    [0, 0],
                    [0, 0],
                    [-0.858, 0.563],
                  ],
                  v: [
                    [-8.2, 13.447],
                    [-7.539, 4.41],
                    [-14.568, -1.287],
                    [-14.088, -3.467],
                    [-5.335, -5.597],
                    [-2.142, -14.062],
                    [0.07, -14.27],
                    [4.817, -6.549],
                    [13.82, -6.084],
                    [14.707, -4.032],
                    [8.888, 2.869],
                    [11.26, 11.623],
                    [9.597, 13.098],
                    [1.252, 9.643],
                    [-6.285, 14.587],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [97.833, 339.889], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 13,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 22,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 31,
                    s: [96.987, 96.987],
                  },
                  { t: 46, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.075, 1.029],
                    [0, 0],
                    [0, 0],
                    [-0.995, 0.243],
                    [0, 0],
                    [0, 0],
                    [-0.542, -0.879],
                    [0, 0],
                    [0, 0],
                    [0.662, -0.786],
                    [0, 0],
                    [0, 0],
                    [0.95, 0.394],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.648],
                    [0, 0],
                    [0, 0],
                    [0.364, -0.965],
                    [0, 0],
                    [0, 0],
                    [1.026, 0.052],
                    [0, 0],
                    [0, 0],
                    [0.27, 0.997],
                    [0, 0],
                    [0, 0],
                    [-0.859, 0.563],
                  ],
                  v: [
                    [-8.2, 13.447],
                    [-7.539, 4.41],
                    [-14.568, -1.288],
                    [-14.089, -3.467],
                    [-5.335, -5.597],
                    [-2.142, -14.062],
                    [0.07, -14.27],
                    [4.817, -6.549],
                    [13.82, -6.083],
                    [14.707, -4.033],
                    [8.888, 2.869],
                    [11.26, 11.622],
                    [9.597, 13.097],
                    [1.252, 9.642],
                    [-6.285, 14.586],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [366.265, 572.585], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 18,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 27,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 36,
                    s: [96.987, 96.987],
                  },
                  { t: 51, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.096, 1.31],
                    [0, 0],
                    [0, 0],
                    [-1.269, 0.309],
                    [0, 0],
                    [0, 0],
                    [-0.689, -1.119],
                    [0, 0],
                    [0, 0],
                    [0.844, -1.001],
                    [0, 0],
                    [0, 0],
                    [1.21, 0.501],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-1.019, -0.827],
                    [0, 0],
                    [0, 0],
                    [0.462, -1.227],
                    [0, 0],
                    [0, 0],
                    [1.304, 0.068],
                    [0, 0],
                    [0, 0],
                    [0.345, 1.268],
                    [0, 0],
                    [0, 0],
                    [-1.094, 0.717],
                  ],
                  v: [
                    [-10.439, 17.117],
                    [-9.597, 5.613],
                    [-18.546, -1.639],
                    [-17.935, -4.413],
                    [-6.792, -7.125],
                    [-2.727, -17.901],
                    [0.087, -18.165],
                    [6.132, -8.337],
                    [17.592, -7.745],
                    [18.72, -5.133],
                    [11.312, 3.652],
                    [14.331, 14.794],
                    [12.214, 16.671],
                    [1.592, 12.274],
                    [-8.002, 18.567],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [129.955, 790.043], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 15,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 24,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 33,
                    s: [96.987, 96.987],
                  },
                  { t: 48, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.046, 0.646],
                    [0, 0],
                    [0, 0],
                    [-0.627, 0.152],
                    [0, 0],
                    [0, 0],
                    [-0.339, -0.552],
                    [0, 0],
                    [0, 0],
                    [0.416, -0.494],
                    [0, 0],
                    [0, 0],
                    [0.597, 0.247],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.504, -0.408],
                    [0, 0],
                    [0, 0],
                    [0.229, -0.606],
                    [0, 0],
                    [0, 0],
                    [0.645, 0.033],
                    [0, 0],
                    [0, 0],
                    [0.17, 0.627],
                    [0, 0],
                    [0, 0],
                    [-0.539, 0.354],
                  ],
                  v: [
                    [-5.152, 8.447],
                    [-4.736, 2.77],
                    [-9.152, -0.809],
                    [-8.85, -2.178],
                    [-3.352, -3.517],
                    [-1.346, -8.834],
                    [0.044, -8.965],
                    [3.027, -4.114],
                    [8.682, -3.822],
                    [9.24, -2.533],
                    [5.583, 1.802],
                    [7.074, 7.3],
                    [6.029, 8.228],
                    [0.786, 6.057],
                    [-3.949, 9.163],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [9.906, 495.765], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 18,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 27,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 36,
                    s: [96.987, 96.987],
                  },
                  { t: 51, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.08, 1.113],
                    [0, 0],
                    [0, 0],
                    [-1.079, 0.262],
                    [0, 0],
                    [0, 0],
                    [-0.586, -0.951],
                    [0, 0],
                    [0, 0],
                    [0.717, -0.851],
                    [0, 0],
                    [0, 0],
                    [1.029, 0.426],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.866, -0.702],
                    [0, 0],
                    [0, 0],
                    [0.394, -1.044],
                    [0, 0],
                    [0, 0],
                    [1.109, 0.057],
                    [0, 0],
                    [0, 0],
                    [0.292, 1.079],
                    [0, 0],
                    [0, 0],
                    [-0.93, 0.61],
                  ],
                  v: [
                    [-8.876, 14.554],
                    [-8.16, 4.772],
                    [-15.77, -1.394],
                    [-15.25, -3.753],
                    [-5.775, -6.058],
                    [-2.32, -15.221],
                    [0.074, -15.446],
                    [5.214, -7.09],
                    [14.958, -6.585],
                    [15.918, -4.365],
                    [9.619, 3.105],
                    [12.186, 12.579],
                    [10.387, 14.175],
                    [1.354, 10.436],
                    [-6.803, 15.787],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [253.623, 36.372], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 18,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 27,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 36,
                    s: [96.987, 96.987],
                  },
                  { t: 51, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 13,
      op: 54,
      st: 13,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Star_Red Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [478.564, 487.525, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.402, 411.619, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 7,
              s: [80, 80, 100],
            },
            { t: 34, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.027, 0.362],
                    [0, 0],
                    [0, 0],
                    [-0.351, 0.085],
                    [0, 0],
                    [0, 0],
                    [-0.191, -0.31],
                    [0, 0],
                    [0, 0],
                    [0.232, -0.277],
                    [0, 0],
                    [0, 0],
                    [0.335, 0.138],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.281, -0.228],
                    [0, 0],
                    [0, 0],
                    [0.129, -0.339],
                    [0, 0],
                    [0, 0],
                    [0.361, 0.018],
                    [0, 0],
                    [0, 0],
                    [0.095, 0.35],
                    [0, 0],
                    [0, 0],
                    [-0.302, 0.198],
                  ],
                  v: [
                    [-2.883, 4.729],
                    [-2.65, 1.551],
                    [-5.124, -0.453],
                    [-4.954, -1.219],
                    [-1.876, -1.969],
                    [-0.753, -4.945],
                    [0.025, -5.018],
                    [1.695, -2.304],
                    [4.861, -2.139],
                    [5.173, -1.418],
                    [3.127, 1.009],
                    [3.96, 4.088],
                    [3.375, 4.606],
                    [0.441, 3.391],
                    [-2.21, 5.13],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [68.516, 448.815], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 7,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 16,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 25,
                    s: [96.987, 96.987],
                  },
                  { t: 40, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.026, 0.362],
                    [0, 0],
                    [0, 0],
                    [-0.351, 0.086],
                    [0, 0],
                    [0, 0],
                    [-0.19, -0.31],
                    [0, 0],
                    [0, 0],
                    [0.234, -0.277],
                    [0, 0],
                    [0, 0],
                    [0.334, 0.138],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.282, -0.227],
                    [0, 0],
                    [0, 0],
                    [0.129, -0.34],
                    [0, 0],
                    [0, 0],
                    [0.361, 0.019],
                    [0, 0],
                    [0, 0],
                    [0.096, 0.351],
                    [0, 0],
                    [0, 0],
                    [-0.302, 0.199],
                  ],
                  v: [
                    [-2.884, 4.729],
                    [-2.652, 1.55],
                    [-5.124, -0.454],
                    [-4.954, -1.22],
                    [-1.876, -1.969],
                    [-0.755, -4.945],
                    [0.023, -5.018],
                    [1.693, -2.304],
                    [4.861, -2.14],
                    [5.172, -1.418],
                    [3.126, 1.009],
                    [3.96, 4.087],
                    [3.374, 4.606],
                    [0.441, 3.39],
                    [-2.21, 5.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [164.994, 560.377], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, -0.261],
                    [0, 0],
                    [0, 0],
                    [-0.013, -0.88],
                    [0, 0],
                    [0, 0],
                    [0.827, -0.282],
                    [0, 0],
                    [0, 0],
                    [0.525, 0.704],
                    [0, 0],
                    [0, 0],
                    [-0.504, 0.717],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.686, -0.543],
                    [0, 0],
                    [0, 0],
                    [0.727, 0.49],
                    [0, 0],
                    [0, 0],
                    [-0.236, 0.845],
                    [0, 0],
                    [0, 0],
                    [-0.874, 0.033],
                    [0, 0],
                    [0, 0],
                    [-0.304, -0.825],
                  ],
                  v: [
                    [-9.365, -10.1],
                    [-2.032, -7.798],
                    [3.99, -12.561],
                    [5.698, -11.738],
                    [5.811, -4.022],
                    [12.197, 0.281],
                    [11.952, 2.167],
                    [4.689, 4.634],
                    [2.614, 12.057],
                    [0.754, 12.4],
                    [-3.847, 6.207],
                    [-11.515, 6.492],
                    [-12.42, 4.819],
                    [-8.002, -1.475],
                    [-10.665, -8.722],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [597.417, 809.885], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 20,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 29,
                    s: [96.987, 96.987],
                  },
                  { t: 44, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.328, -0.021],
                    [0, 0],
                    [0, 0],
                    [-0.083, -0.321],
                    [0, 0],
                    [0, 0],
                    [0.277, -0.177],
                    [0, 0],
                    [0, 0],
                    [0.255, 0.211],
                    [0, 0],
                    [0, 0],
                    [-0.121, 0.308],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.202, -0.26],
                    [0, 0],
                    [0, 0],
                    [0.309, 0.114],
                    [0, 0],
                    [0, 0],
                    [-0.01, 0.33],
                    [0, 0],
                    [0, 0],
                    [-0.316, 0.091],
                    [0, 0],
                    [0, 0],
                    [-0.184, -0.275],
                  ],
                  v: [
                    [-4.312, -2.59],
                    [-1.427, -2.406],
                    [0.353, -4.691],
                    [1.05, -4.543],
                    [1.776, -1.727],
                    [4.493, -0.725],
                    [4.571, -0.013],
                    [2.134, 1.543],
                    [2.034, 4.448],
                    [1.385, 4.74],
                    [-0.846, 2.886],
                    [-3.624, 3.678],
                    [-4.103, 3.147],
                    [-3.048, 0.446],
                    [-4.664, -1.969],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [859.705, 367.283], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 20,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 29,
                    s: [96.987, 96.987],
                  },
                  { t: 44, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.329, -0.021],
                    [0, 0],
                    [0, 0],
                    [-0.083, -0.32],
                    [0, 0],
                    [0, 0],
                    [0.278, -0.177],
                    [0, 0],
                    [0, 0],
                    [0.254, 0.211],
                    [0, 0],
                    [0, 0],
                    [-0.12, 0.307],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.203, -0.26],
                    [0, 0],
                    [0, 0],
                    [0.309, 0.114],
                    [0, 0],
                    [0, 0],
                    [-0.011, 0.331],
                    [0, 0],
                    [0, 0],
                    [-0.317, 0.09],
                    [0, 0],
                    [0, 0],
                    [-0.184, -0.275],
                  ],
                  v: [
                    [-4.311, -2.59],
                    [-1.427, -2.406],
                    [0.352, -4.691],
                    [1.05, -4.543],
                    [1.776, -1.727],
                    [4.493, -0.725],
                    [4.571, -0.013],
                    [2.135, 1.543],
                    [2.035, 4.447],
                    [1.386, 4.74],
                    [-0.847, 2.885],
                    [-3.624, 3.679],
                    [-4.104, 3.147],
                    [-3.047, 0.445],
                    [-4.665, -1.969],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [764.863, 495.849], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 20,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 29,
                    s: [96.987, 96.987],
                  },
                  { t: 44, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.621, -0.04],
                    [0, 0],
                    [0, 0],
                    [-0.157, -0.606],
                    [0, 0],
                    [0, 0],
                    [0.524, -0.335],
                    [0, 0],
                    [0, 0],
                    [0.481, 0.399],
                    [0, 0],
                    [0, 0],
                    [-0.228, 0.582],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.383, -0.492],
                    [0, 0],
                    [0, 0],
                    [0.585, 0.216],
                    [0, 0],
                    [0, 0],
                    [-0.021, 0.625],
                    [0, 0],
                    [0, 0],
                    [-0.598, 0.17],
                    [0, 0],
                    [0, 0],
                    [-0.348, -0.52],
                  ],
                  v: [
                    [-8.149, -4.896],
                    [-2.695, -4.548],
                    [0.666, -8.867],
                    [1.985, -8.588],
                    [3.359, -3.265],
                    [8.493, -1.371],
                    [8.641, -0.024],
                    [4.036, 2.917],
                    [3.847, 8.407],
                    [2.62, 8.96],
                    [-1.599, 5.455],
                    [-6.85, 6.954],
                    [-7.756, 5.949],
                    [-5.76, 0.842],
                    [-8.817, -3.722],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [845.704, 540.753], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 20,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 29,
                    s: [96.987, 96.987],
                  },
                  { t: 44, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.761, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.063, -0.825],
                    [0, 0],
                    [0, 0],
                    [0.799, -0.191],
                    [0, 0],
                    [0, 0],
                    [0.431, 0.706],
                    [0, 0],
                    [0, 0],
                    [-0.533, 0.628],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.689, -0.449],
                    [0, 0],
                    [0, 0],
                    [0.639, 0.523],
                    [0, 0],
                    [0, 0],
                    [-0.294, 0.771],
                    [0, 0],
                    [0, 0],
                    [-0.823, -0.045],
                    [0, 0],
                    [0, 0],
                    [-0.213, -0.8],
                  ],
                  v: [
                    [-7.639, -10.532],
                    [-0.964, -7.74],
                    [5.089, -11.678],
                    [6.619, -10.759],
                    [6.063, -3.522],
                    [11.678, 1.063],
                    [11.287, 2.808],
                    [4.268, 4.489],
                    [1.685, 11.261],
                    [-0.087, 11.421],
                    [-3.869, 5.221],
                    [-11.079, 4.821],
                    [-11.784, 3.176],
                    [-7.102, -2.336],
                    [-8.976, -9.355],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [209.452, 73.078], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.76, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.064, -0.824],
                    [0, 0],
                    [0, 0],
                    [0.8, -0.192],
                    [0, 0],
                    [0, 0],
                    [0.431, 0.706],
                    [0, 0],
                    [0, 0],
                    [-0.534, 0.628],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.691, -0.449],
                    [0, 0],
                    [0, 0],
                    [0.64, 0.521],
                    [0, 0],
                    [0, 0],
                    [-0.294, 0.772],
                    [0, 0],
                    [0, 0],
                    [-0.822, -0.046],
                    [0, 0],
                    [0, 0],
                    [-0.213, -0.799],
                  ],
                  v: [
                    [-7.64, -10.532],
                    [-0.965, -7.738],
                    [5.088, -11.678],
                    [6.619, -10.759],
                    [6.062, -3.522],
                    [11.678, 1.064],
                    [11.286, 2.809],
                    [4.268, 4.488],
                    [1.685, 11.262],
                    [-0.087, 11.421],
                    [-3.868, 5.222],
                    [-11.08, 4.822],
                    [-11.784, 3.176],
                    [-7.103, -2.335],
                    [-8.977, -9.355],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [786.66, 184.794], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.79, -0.05],
                    [0, 0],
                    [0, 0],
                    [-0.199, -0.77],
                    [0, 0],
                    [0, 0],
                    [0.666, -0.426],
                    [0, 0],
                    [0, 0],
                    [0.61, 0.508],
                    [0, 0],
                    [0, 0],
                    [-0.288, 0.74],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.486, -0.625],
                    [0, 0],
                    [0, 0],
                    [0.743, 0.274],
                    [0, 0],
                    [0, 0],
                    [-0.027, 0.795],
                    [0, 0],
                    [0, 0],
                    [-0.761, 0.217],
                    [0, 0],
                    [0, 0],
                    [-0.443, -0.661],
                  ],
                  v: [
                    [-10.361, -6.226],
                    [-3.427, -5.782],
                    [0.848, -11.274],
                    [2.523, -10.919],
                    [4.271, -4.152],
                    [10.799, -1.742],
                    [10.987, -0.031],
                    [5.132, 3.708],
                    [4.893, 10.688],
                    [3.333, 11.391],
                    [-2.034, 6.935],
                    [-8.709, 8.841],
                    [-9.862, 7.563],
                    [-7.323, 1.07],
                    [-11.209, -4.732],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [682.236, 75.127], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.1, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.217],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.146, 0.377],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.248, -0.319],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.139],
                    [0, 0],
                    [0, 0],
                    [-0.014, 0.405],
                    [0, 0],
                    [0, 0],
                    [-0.388, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.225, -0.336],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.943],
                    [0.43, -5.739],
                    [1.283, -5.559],
                    [2.173, -2.113],
                    [5.498, -0.887],
                    [5.592, -0.015],
                    [2.612, 1.889],
                    [2.49, 5.442],
                    [1.696, 5.8],
                    [-1.035, 3.531],
                    [-4.434, 4.501],
                    [-5.022, 3.851],
                    [-3.728, 0.545],
                    [-5.707, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [624.589, 102.969], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.101, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.217],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.147, 0.376],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.248, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.14],
                    [0, 0],
                    [0, 0],
                    [-0.012, 0.404],
                    [0, 0],
                    [0, 0],
                    [-0.387, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.225, -0.336],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.944],
                    [0.431, -5.74],
                    [1.285, -5.559],
                    [2.174, -2.114],
                    [5.497, -0.887],
                    [5.593, -0.016],
                    [2.612, 1.888],
                    [2.49, 5.442],
                    [1.695, 5.8],
                    [-1.035, 3.531],
                    [-4.435, 4.501],
                    [-5.021, 3.851],
                    [-3.728, 0.545],
                    [-5.707, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [706.205, 117.696], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.901, 0.901] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.87, 0.87] },
                    o: { x: [0.578, 0.578], y: [0.061, 0.061] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 37, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.101, -0.393],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.216],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.259],
                    [0, 0],
                    [0, 0],
                    [-0.146, 0.376],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.248, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.14],
                    [0, 0],
                    [0, 0],
                    [-0.014, 0.405],
                    [0, 0],
                    [0, 0],
                    [-0.388, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.226, -0.337],
                  ],
                  v: [
                    [-5.274, -3.169],
                    [-1.744, -2.944],
                    [0.431, -5.74],
                    [1.285, -5.558],
                    [2.174, -2.113],
                    [5.499, -0.888],
                    [5.592, -0.016],
                    [2.613, 1.888],
                    [2.491, 5.442],
                    [1.697, 5.799],
                    [-1.035, 3.531],
                    [-4.434, 4.501],
                    [-5.021, 3.851],
                    [-3.728, 0.545],
                    [-5.706, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [231.247, 216.071], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.309, 1.309] },
                    o: { x: [0.172, 0.172], y: [1.109, 1.109] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.87, 0.87] },
                    o: { x: [0.578, 0.578], y: [0.061, 0.061] },
                    t: 23,
                    s: [96.987, 96.987],
                  },
                  { t: 34, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.101, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.217],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.147, 0.376],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.248, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.14],
                    [0, 0],
                    [0, 0],
                    [-0.014, 0.404],
                    [0, 0],
                    [0, 0],
                    [-0.387, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.225, -0.337],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.944],
                    [0.431, -5.74],
                    [1.285, -5.559],
                    [2.174, -2.114],
                    [5.497, -0.887],
                    [5.593, -0.016],
                    [2.612, 1.888],
                    [2.491, 5.442],
                    [1.695, 5.8],
                    [-1.035, 3.531],
                    [-4.435, 4.501],
                    [-5.021, 3.851],
                    [-3.728, 0.545],
                    [-5.707, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [417.956, 114.225], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.309, 1.309] },
                    o: { x: [0.172, 0.172], y: [1.109, 1.109] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.906, 0.906] },
                    o: { x: [0.578, 0.578], y: [0.044, 0.044] },
                    t: 23,
                    s: [96.987, 96.987],
                  },
                  { t: 31, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.101, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.218],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.147, 0.376],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.247, -0.319],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.139],
                    [0, 0],
                    [0, 0],
                    [-0.013, 0.404],
                    [0, 0],
                    [0, 0],
                    [-0.387, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.224, -0.337],
                  ],
                  v: [
                    [-5.276, -3.169],
                    [-1.746, -2.943],
                    [0.431, -5.739],
                    [1.284, -5.559],
                    [2.173, -2.114],
                    [5.497, -0.887],
                    [5.592, -0.015],
                    [2.612, 1.888],
                    [2.49, 5.442],
                    [1.695, 5.8],
                    [-1.036, 3.531],
                    [-4.435, 4.501],
                    [-5.021, 3.851],
                    [-3.729, 0.545],
                    [-5.708, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [758.196, 246.687], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 10,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 19,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.859, 0.859] },
                    o: { x: [0.578, 0.578], y: [0.066, 0.066] },
                    t: 28,
                    s: [96.987, 96.987],
                  },
                  { t: 40, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.102, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.339, -0.216],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.147, 0.376],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.248, -0.319],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.139],
                    [0, 0],
                    [0, 0],
                    [-0.015, 0.405],
                    [0, 0],
                    [0, 0],
                    [-0.388, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.226, -0.335],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.944],
                    [0.431, -5.739],
                    [1.286, -5.559],
                    [2.174, -2.114],
                    [5.497, -0.887],
                    [5.594, -0.016],
                    [2.612, 1.888],
                    [2.491, 5.442],
                    [1.695, 5.8],
                    [-1.035, 3.531],
                    [-4.433, 4.501],
                    [-5.021, 3.851],
                    [-3.728, 0.545],
                    [-5.706, -2.41],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [828.977, 466.883], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.859, 0.859] },
                    o: { x: [0.578, 0.578], y: [0.066, 0.066] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 38, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.101, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.217],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.259],
                    [0, 0],
                    [0, 0],
                    [-0.147, 0.376],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.247, -0.319],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.14],
                    [0, 0],
                    [0, 0],
                    [-0.013, 0.405],
                    [0, 0],
                    [0, 0],
                    [-0.388, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.226, -0.335],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.945],
                    [0.432, -5.74],
                    [1.285, -5.56],
                    [2.174, -2.115],
                    [5.497, -0.888],
                    [5.593, -0.016],
                    [2.612, 1.888],
                    [2.491, 5.441],
                    [1.695, 5.799],
                    [-1.035, 3.53],
                    [-4.433, 4.5],
                    [-5.021, 3.851],
                    [-3.728, 0.544],
                    [-5.706, -2.411],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [819.207, 587.224], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 20,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.859, 0.859] },
                    o: { x: [0.578, 0.578], y: [0.066, 0.066] },
                    t: 29,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.403, -0.025],
                    [0, 0],
                    [0, 0],
                    [-0.102, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.339, -0.217],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.148, 0.377],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.247, -0.319],
                    [0, 0],
                    [0, 0],
                    [0.377, 0.14],
                    [0, 0],
                    [0, 0],
                    [-0.013, 0.405],
                    [0, 0],
                    [0, 0],
                    [-0.388, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.225, -0.337],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.943],
                    [0.431, -5.739],
                    [1.285, -5.559],
                    [2.174, -2.114],
                    [5.498, -0.887],
                    [5.593, -0.015],
                    [2.613, 1.888],
                    [2.49, 5.442],
                    [1.696, 5.8],
                    [-1.036, 3.532],
                    [-4.434, 4.501],
                    [-5.02, 3.851],
                    [-3.729, 0.546],
                    [-5.707, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [786.252, 630.999], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 13,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 22,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.859, 0.859] },
                    o: { x: [0.578, 0.578], y: [0.066, 0.066] },
                    t: 31,
                    s: [96.987, 96.987],
                  },
                  { t: 43, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.402, -0.026],
                    [0, 0],
                    [0, 0],
                    [-0.102, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.34, -0.218],
                    [0, 0],
                    [0, 0],
                    [0.311, 0.258],
                    [0, 0],
                    [0, 0],
                    [-0.148, 0.377],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.248, -0.318],
                    [0, 0],
                    [0, 0],
                    [0.378, 0.139],
                    [0, 0],
                    [0, 0],
                    [-0.015, 0.406],
                    [0, 0],
                    [0, 0],
                    [-0.387, 0.111],
                    [0, 0],
                    [0, 0],
                    [-0.226, -0.337],
                  ],
                  v: [
                    [-5.275, -3.169],
                    [-1.745, -2.944],
                    [0.431, -5.74],
                    [1.285, -5.559],
                    [2.174, -2.114],
                    [5.498, -0.887],
                    [5.593, -0.015],
                    [2.613, 1.888],
                    [2.491, 5.441],
                    [1.696, 5.8],
                    [-1.035, 3.531],
                    [-4.434, 4.501],
                    [-5.02, 3.851],
                    [-3.728, 0.545],
                    [-5.707, -2.409],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [753.913, 703.622], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 11,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 20,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.859, 0.859] },
                    o: { x: [0.578, 0.578], y: [0.066, 0.066] },
                    t: 29,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.844, -0.054],
                    [0, 0],
                    [0, 0],
                    [-0.212, -0.824],
                    [0, 0],
                    [0, 0],
                    [0.713, -0.456],
                    [0, 0],
                    [0, 0],
                    [0.653, 0.543],
                    [0, 0],
                    [0, 0],
                    [-0.31, 0.79],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.521, -0.668],
                    [0, 0],
                    [0, 0],
                    [0.796, 0.294],
                    [0, 0],
                    [0, 0],
                    [-0.03, 0.851],
                    [0, 0],
                    [0, 0],
                    [-0.813, 0.232],
                    [0, 0],
                    [0, 0],
                    [-0.473, -0.707],
                  ],
                  v: [
                    [-11.081, -6.657],
                    [-3.666, -6.183],
                    [0.904, -12.056],
                    [2.697, -11.676],
                    [4.564, -4.44],
                    [11.546, -1.864],
                    [11.747, -0.033],
                    [5.487, 3.965],
                    [5.231, 11.429],
                    [3.562, 12.181],
                    [-2.175, 7.416],
                    [-9.315, 9.454],
                    [-10.546, 8.088],
                    [-7.831, 1.144],
                    [-11.988, -5.061],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [711.789, 661.854], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.496, -0.032],
                    [0, 0],
                    [0, 0],
                    [-0.125, -0.483],
                    [0, 0],
                    [0, 0],
                    [0.419, -0.267],
                    [0, 0],
                    [0, 0],
                    [0.384, 0.319],
                    [0, 0],
                    [0, 0],
                    [-0.181, 0.464],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.305, -0.392],
                    [0, 0],
                    [0, 0],
                    [0.466, 0.173],
                    [0, 0],
                    [0, 0],
                    [-0.017, 0.499],
                    [0, 0],
                    [0, 0],
                    [-0.478, 0.136],
                    [0, 0],
                    [0, 0],
                    [-0.279, -0.415],
                  ],
                  v: [
                    [-6.504, -3.909],
                    [-2.151, -3.63],
                    [0.533, -7.078],
                    [1.585, -6.855],
                    [2.681, -2.607],
                    [6.78, -1.094],
                    [6.898, -0.02],
                    [3.223, 2.328],
                    [3.072, 6.711],
                    [2.091, 7.151],
                    [-1.276, 4.354],
                    [-5.467, 5.55],
                    [-6.191, 4.749],
                    [-4.597, 0.672],
                    [-7.037, -2.971],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [640.689, 693.969], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.897, 0.274],
                    [0, 0],
                    [0, 0],
                    [-0.54, -0.775],
                    [0, 0],
                    [0, 0],
                    [0.565, -0.753],
                    [0, 0],
                    [0, 0],
                    [0.888, 0.309],
                    [0, 0],
                    [0, 0],
                    [-0.016, 0.944],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.283, -0.9],
                    [0, 0],
                    [0, 0],
                    [0.938, -0.006],
                    [0, 0],
                    [0, 0],
                    [0.298, 0.896],
                    [0, 0],
                    [0, 0],
                    [-0.755, 0.559],
                    [0, 0],
                    [0, 0],
                    [-0.763, -0.551],
                  ],
                  v: [
                    [-12.868, -2.632],
                    [-4.985, -5.033],
                    [-2.508, -12.932],
                    [-0.5, -13.236],
                    [4.234, -6.432],
                    [12.477, -6.476],
                    [13.393, -4.649],
                    [8.437, 1.957],
                    [11.052, 9.829],
                    [9.609, 11.263],
                    [1.813, 8.542],
                    [-4.813, 13.451],
                    [-6.62, 12.51],
                    [-6.482, 4.222],
                    [-13.195, -0.615],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [715.385, 755.942], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.55, 0.167],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.475],
                    [0, 0],
                    [0, 0],
                    [0.347, -0.461],
                    [0, 0],
                    [0, 0],
                    [0.543, 0.19],
                    [0, 0],
                    [0, 0],
                    [-0.009, 0.578],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.173, -0.55],
                    [0, 0],
                    [0, 0],
                    [0.575, -0.003],
                    [0, 0],
                    [0, 0],
                    [0.183, 0.549],
                    [0, 0],
                    [0, 0],
                    [-0.462, 0.343],
                    [0, 0],
                    [0, 0],
                    [-0.467, -0.338],
                  ],
                  v: [
                    [-7.876, -1.61],
                    [-3.052, -3.08],
                    [-1.536, -7.915],
                    [-0.305, -8.101],
                    [2.591, -3.937],
                    [7.636, -3.964],
                    [8.197, -2.845],
                    [5.164, 1.199],
                    [6.763, 6.016],
                    [5.882, 6.894],
                    [1.109, 5.229],
                    [-2.946, 8.233],
                    [-4.052, 7.657],
                    [-3.967, 2.584],
                    [-8.076, -0.376],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [512.989, 636.017], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.55, 0.167],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.474],
                    [0, 0],
                    [0, 0],
                    [0.345, -0.461],
                    [0, 0],
                    [0, 0],
                    [0.543, 0.19],
                    [0, 0],
                    [0, 0],
                    [-0.01, 0.578],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.172, -0.551],
                    [0, 0],
                    [0, 0],
                    [0.575, -0.004],
                    [0, 0],
                    [0, 0],
                    [0.183, 0.548],
                    [0, 0],
                    [0, 0],
                    [-0.462, 0.343],
                    [0, 0],
                    [0, 0],
                    [-0.467, -0.338],
                  ],
                  v: [
                    [-7.876, -1.611],
                    [-3.052, -3.081],
                    [-1.534, -7.914],
                    [-0.305, -8.102],
                    [2.592, -3.937],
                    [7.637, -3.964],
                    [8.198, -2.846],
                    [5.165, 1.198],
                    [6.765, 6.016],
                    [5.883, 6.893],
                    [1.109, 5.229],
                    [-2.945, 8.232],
                    [-4.051, 7.656],
                    [-3.967, 2.583],
                    [-8.076, -0.377],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [94.853, 28.74], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.549, 0.168],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.474],
                    [0, 0],
                    [0, 0],
                    [0.346, -0.46],
                    [0, 0],
                    [0, 0],
                    [0.545, 0.189],
                    [0, 0],
                    [0, 0],
                    [-0.01, 0.577],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.173, -0.551],
                    [0, 0],
                    [0, 0],
                    [0.574, -0.004],
                    [0, 0],
                    [0, 0],
                    [0.182, 0.549],
                    [0, 0],
                    [0, 0],
                    [-0.461, 0.341],
                    [0, 0],
                    [0, 0],
                    [-0.467, -0.338],
                  ],
                  v: [
                    [-7.876, -1.61],
                    [-3.052, -3.08],
                    [-1.534, -7.914],
                    [-0.305, -8.101],
                    [2.591, -3.936],
                    [7.638, -3.963],
                    [8.198, -2.845],
                    [5.164, 1.199],
                    [6.766, 6.016],
                    [5.882, 6.894],
                    [1.111, 5.229],
                    [-2.946, 8.234],
                    [-4.052, 7.658],
                    [-3.966, 2.584],
                    [-8.076, -0.376],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [409.675, 8.825], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 24',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.55, 0.168],
                    [0, 0],
                    [0, 0],
                    [-0.329, -0.474],
                    [0, 0],
                    [0, 0],
                    [0.346, -0.461],
                    [0, 0],
                    [0, 0],
                    [0.543, 0.19],
                    [0, 0],
                    [0, 0],
                    [-0.01, 0.578],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.172, -0.551],
                    [0, 0],
                    [0, 0],
                    [0.575, -0.003],
                    [0, 0],
                    [0, 0],
                    [0.183, 0.548],
                    [0, 0],
                    [0, 0],
                    [-0.461, 0.343],
                    [0, 0],
                    [0, 0],
                    [-0.468, -0.337],
                  ],
                  v: [
                    [-7.875, -1.611],
                    [-3.051, -3.081],
                    [-1.533, -7.914],
                    [-0.306, -8.102],
                    [2.59, -3.937],
                    [7.637, -3.964],
                    [8.198, -2.844],
                    [5.163, 1.198],
                    [6.764, 6.016],
                    [5.883, 6.893],
                    [1.111, 5.229],
                    [-2.947, 8.232],
                    [-4.051, 7.657],
                    [-3.966, 2.583],
                    [-8.075, -0.377],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [455.225, 728.413], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 25',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.076, 1.029],
                    [0, 0],
                    [0, 0],
                    [-0.997, 0.243],
                    [0, 0],
                    [0, 0],
                    [-0.54, -0.88],
                    [0, 0],
                    [0, 0],
                    [0.662, -0.786],
                    [0, 0],
                    [0, 0],
                    [0.952, 0.394],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.648],
                    [0, 0],
                    [0, 0],
                    [0.363, -0.964],
                    [0, 0],
                    [0, 0],
                    [1.025, 0.053],
                    [0, 0],
                    [0, 0],
                    [0.27, 0.997],
                    [0, 0],
                    [0, 0],
                    [-0.859, 0.563],
                  ],
                  v: [
                    [-8.2, 13.446],
                    [-7.539, 4.409],
                    [-14.568, -1.289],
                    [-14.089, -3.467],
                    [-5.335, -5.597],
                    [-2.142, -14.062],
                    [0.068, -14.269],
                    [4.817, -6.549],
                    [13.82, -6.083],
                    [14.707, -4.034],
                    [8.888, 2.869],
                    [11.259, 11.622],
                    [9.595, 13.096],
                    [1.252, 9.641],
                    [-6.286, 14.586],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [486.253, 163.833], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 26',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 26,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.075, 1.029],
                    [0, 0],
                    [0, 0],
                    [-0.997, 0.243],
                    [0, 0],
                    [0, 0],
                    [-0.542, -0.879],
                    [0, 0],
                    [0, 0],
                    [0.662, -0.785],
                    [0, 0],
                    [0, 0],
                    [0.95, 0.394],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.649],
                    [0, 0],
                    [0, 0],
                    [0.364, -0.964],
                    [0, 0],
                    [0, 0],
                    [1.026, 0.052],
                    [0, 0],
                    [0, 0],
                    [0.27, 0.997],
                    [0, 0],
                    [0, 0],
                    [-0.859, 0.563],
                  ],
                  v: [
                    [-8.2, 13.447],
                    [-7.539, 4.41],
                    [-14.568, -1.287],
                    [-14.088, -3.467],
                    [-5.335, -5.597],
                    [-2.142, -14.062],
                    [0.07, -14.27],
                    [4.818, -6.549],
                    [13.82, -6.083],
                    [14.708, -4.033],
                    [8.888, 2.869],
                    [11.26, 11.622],
                    [9.597, 13.097],
                    [1.253, 9.642],
                    [-6.285, 14.586],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [768.633, 413.553], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 27',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 27,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.075, 1.029],
                    [0, 0],
                    [0, 0],
                    [-0.997, 0.243],
                    [0, 0],
                    [0, 0],
                    [-0.541, -0.879],
                    [0, 0],
                    [0, 0],
                    [0.664, -0.787],
                    [0, 0],
                    [0, 0],
                    [0.95, 0.393],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.801, -0.649],
                    [0, 0],
                    [0, 0],
                    [0.364, -0.964],
                    [0, 0],
                    [0, 0],
                    [1.025, 0.054],
                    [0, 0],
                    [0, 0],
                    [0.269, 0.998],
                    [0, 0],
                    [0, 0],
                    [-0.858, 0.563],
                  ],
                  v: [
                    [-8.201, 13.446],
                    [-7.539, 4.409],
                    [-14.569, -1.288],
                    [-14.088, -3.468],
                    [-5.335, -5.598],
                    [-2.143, -14.063],
                    [0.069, -14.27],
                    [4.817, -6.55],
                    [13.82, -6.085],
                    [14.706, -4.033],
                    [8.887, 2.869],
                    [11.26, 11.621],
                    [9.596, 13.097],
                    [1.251, 9.642],
                    [-6.286, 14.586],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [173.866, 194.794], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 28',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 28,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.063, 0.85],
                    [0, 0],
                    [0, 0],
                    [-0.823, 0.2],
                    [0, 0],
                    [0, 0],
                    [-0.446, -0.725],
                    [0, 0],
                    [0, 0],
                    [0.547, -0.648],
                    [0, 0],
                    [0, 0],
                    [0.784, 0.326],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.662, -0.535],
                    [0, 0],
                    [0, 0],
                    [0.3, -0.796],
                    [0, 0],
                    [0, 0],
                    [0.846, 0.043],
                    [0, 0],
                    [0, 0],
                    [0.222, 0.822],
                    [0, 0],
                    [0, 0],
                    [-0.708, 0.465],
                  ],
                  v: [
                    [-6.766, 11.094],
                    [-6.219, 3.638],
                    [-12.02, -1.063],
                    [-11.624, -2.861],
                    [-4.402, -4.619],
                    [-1.768, -11.603],
                    [0.057, -11.774],
                    [3.975, -5.404],
                    [11.404, -5.02],
                    [12.134, -3.328],
                    [7.333, 2.367],
                    [9.29, 9.589],
                    [7.917, 10.805],
                    [1.033, 7.955],
                    [-5.186, 12.034],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [12.931, 390.843], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 29',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 29,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.063, 0.85],
                    [0, 0],
                    [0, 0],
                    [-0.823, 0.2],
                    [0, 0],
                    [0, 0],
                    [-0.447, -0.726],
                    [0, 0],
                    [0, 0],
                    [0.547, -0.648],
                    [0, 0],
                    [0, 0],
                    [0.784, 0.325],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.662, -0.536],
                    [0, 0],
                    [0, 0],
                    [0.301, -0.796],
                    [0, 0],
                    [0, 0],
                    [0.846, 0.043],
                    [0, 0],
                    [0, 0],
                    [0.223, 0.822],
                    [0, 0],
                    [0, 0],
                    [-0.709, 0.465],
                  ],
                  v: [
                    [-6.765, 11.094],
                    [-6.22, 3.638],
                    [-12.02, -1.062],
                    [-11.624, -2.861],
                    [-4.402, -4.619],
                    [-1.767, -11.602],
                    [0.058, -11.773],
                    [3.975, -5.404],
                    [11.403, -5.02],
                    [12.134, -3.328],
                    [7.334, 2.367],
                    [9.29, 9.589],
                    [7.918, 10.805],
                    [1.033, 7.956],
                    [-5.186, 12.034],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [309.779, 726.599], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 30',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 30,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.073, 0.997],
                    [0, 0],
                    [0, 0],
                    [-0.967, 0.235],
                    [0, 0],
                    [0, 0],
                    [-0.524, -0.852],
                    [0, 0],
                    [0, 0],
                    [0.643, -0.761],
                    [0, 0],
                    [0, 0],
                    [0.921, 0.382],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.775, -0.628],
                    [0, 0],
                    [0, 0],
                    [0.353, -0.934],
                    [0, 0],
                    [0, 0],
                    [0.993, 0.052],
                    [0, 0],
                    [0, 0],
                    [0.263, 0.965],
                    [0, 0],
                    [0, 0],
                    [-0.831, 0.545],
                  ],
                  v: [
                    [-7.945, 13.025],
                    [-7.303, 4.271],
                    [-14.113, -1.247],
                    [-13.647, -3.358],
                    [-5.169, -5.421],
                    [-2.076, -13.621],
                    [0.065, -13.822],
                    [4.665, -6.343],
                    [13.385, -5.893],
                    [14.244, -3.906],
                    [8.608, 2.779],
                    [10.904, 11.258],
                    [9.294, 12.686],
                    [1.211, 9.34],
                    [-6.09, 14.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [139.699, 637.73], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 31',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 31,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.082, 1.114],
                    [0, 0],
                    [0, 0],
                    [-1.08, 0.264],
                    [0, 0],
                    [0, 0],
                    [-0.585, -0.952],
                    [0, 0],
                    [0, 0],
                    [0.718, -0.851],
                    [0, 0],
                    [0, 0],
                    [1.028, 0.425],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.867, -0.703],
                    [0, 0],
                    [0, 0],
                    [0.394, -1.043],
                    [0, 0],
                    [0, 0],
                    [1.109, 0.058],
                    [0, 0],
                    [0, 0],
                    [0.293, 1.079],
                    [0, 0],
                    [0, 0],
                    [-0.929, 0.61],
                  ],
                  v: [
                    [-8.876, 14.554],
                    [-8.16, 4.772],
                    [-15.769, -1.394],
                    [-15.249, -3.754],
                    [-5.775, -6.059],
                    [-2.318, -15.221],
                    [0.075, -15.446],
                    [5.214, -7.09],
                    [14.96, -6.585],
                    [15.918, -4.364],
                    [9.62, 3.105],
                    [12.187, 12.579],
                    [10.387, 14.176],
                    [1.355, 10.436],
                    [-6.803, 15.788],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.621, 286.56], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.734, 0.734], y: [0.851, 0.851] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 8,
                    s: [0, 0],
                  },
                  {
                    i: { x: [0.704, 0.704], y: [1.463, 1.463] },
                    o: { x: [0.172, 0.172], y: [1.664, 1.664] },
                    t: 17,
                    s: [91.675, 91.675],
                  },
                  {
                    i: { x: [0.85, 0.85], y: [0.823, 0.823] },
                    o: { x: [0.578, 0.578], y: [0.083, 0.083] },
                    t: 26,
                    s: [96.987, 96.987],
                  },
                  { t: 41, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 32',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 32,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 7,
      op: 48,
      st: 7,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Yellow Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 10,
              s: [100],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 33,
              s: [100],
            },
            { t: 53, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [508, 484.082, 0], ix: 2, l: 2 },
        a: { a: 0, k: [399.849, 357.626, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.198, 0.198, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 5.208] },
              t: 0,
              s: [55, 55, 100],
            },
            { t: 43, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.688, -26.382],
                    [52.624, 12.591],
                  ],
                  o: [
                    [-48.855, -24.366],
                    [30.835, -5.538],
                  ],
                  v: [
                    [75.438, 31.365],
                    [-75.438, -25.827],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [323.473, 330.402],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [143.473, 218.402],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [43.473, 175.402] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-29.271, -62.547],
                    [57.227, 28.041],
                  ],
                  o: [
                    [-39.143, -48.769],
                    [52.667, 12.879],
                  ],
                  v: [
                    [70.279, 59.801],
                    [-70.279, -59.801],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [452.615, 424.114],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [632.615, 498.114],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [677.029, 529.122] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-63.401, 1.741],
                    [45.912, -41.882],
                  ],
                  o: [
                    [-58.877, 18.503],
                    [38.506, -48.389],
                  ],
                  v: [
                    [79.143, -44.233],
                    [-79.144, 44.233],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [433.712, 320.594],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [593.712, 134.594],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [673.712, 84.594] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [19.844, -64.038],
                    [-52.218, 43.231],
                  ],
                  o: [
                    [7.637, -58.314],
                    [-39.548, 54.267],
                  ],
                  v: [
                    [-46.433, 89.832],
                    [46.433, -89.832],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [409.06, 315.608],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [429.06, 209.608],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [469.06, 169.608] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [57.427, -39.326],
                    [-71.563, 11.774],
                  ],
                  o: [
                    [49.267, -54.95],
                    [-65.691, 23.09],
                  ],
                  v: [
                    [-91.911, 47.589],
                    [91.911, -47.589],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [324.115, 385.221],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [164.115, 439.221],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [84.115, 477.221] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [113.773, -29.07],
                    [-62.41, -37.437],
                  ],
                  o: [
                    [106.255, -49.619],
                    [-43.572, -11.935],
                  ],
                  v: [
                    [-135.036, 14.778],
                    [135.036, 34.841],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [315.286, 350.28],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 21,
                    s: [135.286, 304.28],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 52, s: [15.286, 304.28] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 21,
                    s: [100, 100],
                  },
                  { t: 52, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [20.415, -108.202],
                    [34.554, 99.299],
                  ],
                  o: [
                    [0.714, -104.656],
                    [48.362, 98.552],
                  ],
                  v: [
                    [11.899, 154.934],
                    [-32.314, -154.934],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [372.33, 290.176],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 21,
                    s: [352.33, 184.176],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 52, s: [352.33, 104.176] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 21,
                    s: [100, 100],
                  },
                  { t: 52, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-51.602, 61.319],
                    [1.929, -106.579],
                  ],
                  o: [
                    [-38.715, 98.823],
                    [-15.47, -67.246],
                  ],
                  v: [
                    [41.31, -154.976],
                    [-25.84, 154.977],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [363.626, 446.025],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [323.626, 560.025],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [279.626, 710.025] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-94.066, 37.131],
                    [51.724, -85.063],
                  ],
                  o: [
                    [-80.113, 58.052],
                    [40.49, -89.897],
                  ],
                  v: [
                    [100.091, -106.02],
                    [-100.091, 106.02],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [325.905, 428.598],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [245.905, 502.598],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [121.905, 602.598] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-77.466, -50.91],
                    [89.347, -18.23],
                  ],
                  o: [
                    [-86.142, -27.046],
                    [84.553, -34.863],
                  ],
                  v: [
                    [131.362, 27.233],
                    [-131.363, 7.63],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [452.311, 369.241],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [552.311, 363.241],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [642.311, 393.241] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.346, 0.297],
                    [-44.72, -127.824],
                    [98.435, 58.241],
                  ],
                  o: [
                    [121.613, 59.308],
                    [-59.883, -98.915],
                    [0.347, -0.298],
                  ],
                  v: [
                    [-123.29, -141.208],
                    [124.33, 141.208],
                    [-124.33, -140.315],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [434.838, 428.274],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [514.838, 542.274],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [594.838, 622.274] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-134.985, -44.687],
                    [122.772, -63.893],
                  ],
                  o: [
                    [-137.384, -4.962],
                    [108.435, -84.859],
                  ],
                  v: [
                    [200.217, -18.618],
                    [-200.217, 63.305],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [478.784, 334.32],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [598.589, 302.766],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [680.589, 262.766] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-52, -144.654],
                    [124.634, 91.925],
                  ],
                  o: [
                    [-73.495, -137.055],
                    [147.383, 59.337],
                  ],
                  v: [
                    [153.49, 172.177],
                    [-153.49, -172.177],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.92666721718, 0.823600739124, 0.575448010015, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [319.077, 298.427],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 28,
                    s: [199.077, 172.427],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 59, s: [135.077, 72.427] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [33, 33],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 28,
                    s: [100, 100],
                  },
                  { t: 59, s: [33, 33] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 54,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Red Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 6,
              s: [100],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 32,
              s: [100],
            },
            { t: 50, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500.191, 497.138, 0], ix: 2, l: 2 },
        a: { a: 0, k: [393.187, 388.25, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.198, 0.198, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 0,
              s: [55, 55, 100],
            },
            { t: 50, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [-41.782, 52.898],
                      [29.068, -61.218],
                    ],
                    o: [
                      [-49.115, 50.898],
                      [26.604, -62.554],
                    ],
                    v: [
                      [347.111, 408.214],
                      [271.928, 512.33],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [-84.654, 107.177],
                      [24.219, -131.034],
                    ],
                    o: [
                      [-99.768, 88.596],
                      [34.918, -85.882],
                    ],
                    v: [
                      [332.879, 434.956],
                      [141.581, 776.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [-28.218, 49.748],
                      [-8.585, -47.137],
                    ],
                    o: [
                      [-76.218, 61.748],
                      [-4.585, -47.137],
                    ],
                    v: [
                      [201.214, 627.365],
                      [141.581, 776.25],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [22.592, -11.88],
                      [-21.457, 1.652],
                    ],
                    o: [
                      [24.695, -11.759],
                      [-23.486, -5.219],
                    ],
                    v: [
                      [464.011, 359.904],
                      [518.107, 347.648],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 18,
                s: [
                  {
                    i: [
                      [58.291, -16.145],
                      [-54.301, -23.853],
                    ],
                    o: [
                      [59.89, -4.295],
                      [-47.014, -38.62],
                    ],
                    v: [
                      [615.935, 333.956],
                      [786.124, 368.616],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 2,
          ty: 'sh',
          ix: 3,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [2.99, -52],
                      [14.305, 33.722],
                    ],
                    o: [
                      [13.129, -52.903],
                      [9.783, 34.144],
                    ],
                    v: [
                      [391.735, 533.625],
                      [369.644, 404.179],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [3.924, -71.392],
                      [28.983, 68.324],
                    ],
                    o: [
                      [17.232, -72.632],
                      [19.821, 69.179],
                    ],
                    v: [
                      [398.584, 637.99],
                      [378.532, 426.781],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [-9.588, -32.877],
                      [23.259, 51.628],
                    ],
                    o: [
                      [12.412, -42.877],
                      [5.259, 51.628],
                    ],
                    v: [
                      [398.584, 637.99],
                      [385.737, 517.484],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 3',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 3,
          ty: 'sh',
          ix: 4,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [31.107, 37.375],
                      [-4.655, -24.295],
                    ],
                    o: [
                      [18.107, 37.375],
                      [1.031, -23.421],
                    ],
                    v: [
                      [321.889, 213.737],
                      [353.076, 355.196],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [48.738, 37.218],
                      [-9.431, -49.225],
                    ],
                    o: [
                      [25.698, 42.535],
                      [2.089, -47.453],
                    ],
                    v: [
                      [285.908, 163.113],
                      [344.963, 327.536],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [43.088, 50],
                      [-13.742, -71.533],
                    ],
                    o: [
                      [13.088, 48],
                      [3.044, -68.958],
                    ],
                    v: [
                      [285.908, 107.113],
                      [328.823, 259.264],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 4',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 4,
          ty: 'sh',
          ix: 5,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [37.371, -44.53],
                      [-35.612, 8.114],
                    ],
                    o: [
                      [43.442, -33.279],
                      [-38.654, 2.197],
                    ],
                    v: [
                      [238.467, 452.727],
                      [351.719, 392.544],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [49.05, -61.136],
                      [-72.154, 16.44],
                    ],
                    o: [
                      [57.019, -45.689],
                      [-78.316, 4.452],
                    ],
                    v: [
                      [151.476, 502.211],
                      [342.214, 403.207],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [15.521, -59.098],
                      [-103.822, 65.885],
                    ],
                    o: [
                      [43.521, -59.098],
                      [-127.822, 83.885],
                    ],
                    v: [
                      [83.476, 624.211],
                      [244.818, 443.227],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 5',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 5,
          ty: 'sh',
          ix: 6,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [0.094, -43.404],
                      [28.889, 58.739],
                    ],
                    o: [
                      [6.094, -36.404],
                      [20.182, 59.783],
                    ],
                    v: [
                      [385.902, 252.516],
                      [381.27, 118.112],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [1.598, -85.69],
                      [37.918, 80.644],
                    ],
                    o: [
                      [17.279, -87.67],
                      [26.489, 82.077],
                    ],
                    v: [
                      [385.902, 252.516],
                      [353.347, 0.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [0.521, -59.134],
                      [29.649, 68.862],
                    ],
                    o: [
                      [20.521, -55.134],
                      [9.649, 64.862],
                    ],
                    v: [
                      [384.475, 174.246],
                      [381.347, -1.75],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 6',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 6,
          ty: 'sh',
          ix: 7,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [34.548, 3.021],
                      [-2.468, -0.742],
                      [-78.314, -81.807],
                    ],
                    o: [
                      [0.321, 0.338],
                      [34.195, 10.903],
                      [-66.98, -95.699],
                    ],
                    v: [
                      [378.243, 397.311],
                      [386.553, 400.159],
                      [554.486, 502.859],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [69.997, 6.12],
                      [-5.001, -1.503],
                      [-78.314, -81.807],
                    ],
                    o: [
                      [0.65, 0.685],
                      [69.282, 22.091],
                      [-66.98, -95.699],
                    ],
                    v: [
                      [395.954, 412.865],
                      [412.791, 418.635],
                      [630.486, 564.859],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [47.602, 25.968],
                      [-3.072, -2.607],
                      [-20.632, -56.321],
                    ],
                    o: [
                      [0.248, 0.684],
                      [42.169, 37.009],
                      [-12.184, -60.655],
                    ],
                    v: [
                      [568.265, 500.508],
                      [578.389, 509.785],
                      [647.07, 626.096],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 7',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 7,
          ty: 'sh',
          ix: 8,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [57.672, 6.097],
                      [-30.743, -34.531],
                    ],
                    o: [
                      [67.042, 26.055],
                      [-17.797, -38.206],
                    ],
                    v: [
                      [198.216, 243.319],
                      [352.417, 375.838],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [75.696, 8.371],
                      [-62.289, -69.964],
                    ],
                    o: [
                      [87.995, 35.771],
                      [-36.059, -77.409],
                    ],
                    v: [
                      [123.583, 203.726],
                      [343.628, 369.359],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [75.696, 8.371],
                      [-69.881, -64.928],
                    ],
                    o: [
                      [63.413, 37.387],
                      [-49.881, -72.928],
                    ],
                    v: [
                      [51.583, 127.726],
                      [228.877, 256.04],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 8',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 8,
          ty: 'sh',
          ix: 9,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [74.885, -9.564],
                      [-64.673, -25.435],
                    ],
                    o: [
                      [48.423, 3.47],
                      [-28.79, -26.125],
                    ],
                    v: [
                      [381.899, 390.332],
                      [553.669, 420.547],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [151.724, -19.378],
                      [-83.087, -50.76],
                    ],
                    o: [
                      [98.109, 7.031],
                      [-37.788, -35.867],
                    ],
                    v: [
                      [403.362, 398.725],
                      [676.754, 496.472],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [122.14, -5.401],
                      [-51.281, -40.716],
                    ],
                    o: [
                      [87.789, 7.645],
                      [-23.396, -28.861],
                    ],
                    v: [
                      [588.699, 428.229],
                      [753.083, 515.889],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 9',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 9,
          ty: 'sh',
          ix: 10,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [38.263, 35.257],
                      [-26.982, -59.22],
                    ],
                    o: [
                      [31.735, 47.718],
                      [-11.982, -65.22],
                    ],
                    v: [
                      [373.28, 403.293],
                      [438.978, 516.333],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [77.525, 71.435],
                      [-24.812, -116.189],
                    ],
                    o: [
                      [64.298, 96.682],
                      [0.515, -108.158],
                    ],
                    v: [
                      [385.898, 424.984],
                      [531.469, 747.422],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [63.527, 114.238],
                      [-10.473, -70.31],
                    ],
                    o: [
                      [47.527, 101.238],
                      [0.515, -108.158],
                    ],
                    v: [
                      [450.469, 509.874],
                      [529.469, 803.422],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 10',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 10,
          ty: 'sh',
          ix: 11,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [-68.448, 51.664],
                      [20.52, -54.509],
                    ],
                    o: [
                      [-73.401, 50.498],
                      [22.94, -42.102],
                    ],
                    v: [
                      [496.397, 235.614],
                      [370.202, 367.468],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [-89.84, 70.93],
                      [41.575, -110.441],
                    ],
                    o: [
                      [-106.625, 51.619],
                      [46.478, -85.302],
                    ],
                    v: [
                      [604.208, 81.941],
                      [379.662, 352.4],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [-41.542, 28.433],
                      [34.865, -72.003],
                    ],
                    o: [
                      [-47.322, 18.475],
                      [35.603, -54.489],
                    ],
                    v: [
                      [595.821, 69.729],
                      [486.559, 168.78],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 11',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 11,
          ty: 'sh',
          ix: 12,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [15.478, -18.557],
                      [-75.784, 24.237],
                    ],
                    o: [
                      [3.894, -3.431],
                      [-51.784, 31.237],
                    ],
                    v: [
                      [381.146, 366.784],
                      [495.78, 292.875],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [31.359, -37.598],
                      [-197.077, 20.32],
                    ],
                    o: [
                      [7.889, -6.951],
                      [-69.434, 4.859],
                    ],
                    v: [
                      [401.835, 351.015],
                      [697.9, 175.659],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [36.577, -27.905],
                      [-125.506, -16.076],
                    ],
                    o: [
                      [8.656, -4.711],
                      [-43.874, -7.121],
                    ],
                    v: [
                      [555.564, 246.83],
                      [757.125, 199.666],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 12',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 12,
          ty: 'sh',
          ix: 13,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [-54.281, -10.124],
                      [68.746, -26.938],
                    ],
                    o: [
                      [-58.998, -19.789],
                      [77.746, -24.938],
                    ],
                    v: [
                      [351.761, 389.507],
                      [166.25, 398.051],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [-109.979, -20.512],
                      [64.172, -64.149],
                    ],
                    o: [
                      [-119.536, -40.094],
                      [82.844, -38.939],
                    ],
                    v: [
                      [342.299, 397.052],
                      [0.25, 468.332],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [-149.945, 40.828],
                      [64.172, -64.149],
                    ],
                    o: [
                      [-173.945, 6.828],
                      [74.746, -61.219],
                    ],
                    v: [
                      [232.941, 388.284],
                      [-51.75, 510.332],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 13',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ind: 13,
          ty: 'sh',
          ix: 14,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [100.321, 16.803],
                      [-43.32, -32.107],
                    ],
                    o: [
                      [88.936, 12.309],
                      [-37.177, -37.343],
                    ],
                    v: [
                      [190.675, 299.309],
                      [351.337, 381.499],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                t: 18,
                s: [
                  {
                    i: [
                      [138.139, -12.313],
                      [-87.771, -65.053],
                    ],
                    o: [
                      [116.731, 16.899],
                      [-75.325, -75.66],
                    ],
                    v: [
                      [3.433, 250.392],
                      [341.44, 380.828],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 50,
                s: [
                  {
                    i: [
                      [138.139, -12.313],
                      [-44.693, -19.595],
                    ],
                    o: [
                      [116.731, 16.899],
                      [-52.693, -35.595],
                    ],
                    v: [
                      [-56.567, 246.392],
                      [145.689, 284.707],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 2,
          },
          nm: 'Path 14',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ty: 'fl',
          c: {
            a: 0,
            k: [0.930225267597, 0.529750449985, 0.418845322553, 1],
            ix: 4,
          },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 0,
      op: 54,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
