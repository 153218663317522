import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserGiftCard } from '..';
import { useAuth } from '../../../context/AuthProvider';
import { partialHidePhone } from '../../../util/helpers';
import { useAlert } from 'react-alert';
import { Button } from '../../../components/Button';
import { SMS_SENDING_GAP_IN_SECONDS } from '../../../const/auth';
import { SideDrawerContainer } from '../../../components/SideDrawer';
import {
  ManagePinHeader,
  ManagePinParagraph,
  ManagePinParagraphHeader,
} from '../shared-components/manage-pin';

type Props = {
  userGiftCard?: UserGiftCard;
};

const ManagePin = ({ userGiftCard }: Props) => {
  const navigate = useNavigate();
  const { userDetailsVM, lastSMSSent } = useAuth();
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const { reVerifyPhone } = useAuth();
  const alert = useAlert();

  const [currentTime, setCurrentTime] = useState<Date>();
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();

      setCurrentTime(currentTime);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const timeGap: number | null = useMemo(() => {
    if (currentTime instanceof Date && lastSMSSent instanceof Date) {
      const gap = Math.ceil(
        (currentTime.getTime() - lastSMSSent.getTime()) / 1000
      );
      return gap >= SMS_SENDING_GAP_IN_SECONDS
        ? SMS_SENDING_GAP_IN_SECONDS
        : gap;
    }
    return null;
  }, [lastSMSSent, currentTime]);

  const onSubmit = async () => {
    if (isPosting) {
      return;
    }

    if (timeGap !== null && timeGap < SMS_SENDING_GAP_IN_SECONDS) {
      navigate(`/my-cards/${userGiftCard?.id}/manage-pin/verify`);
      return;
    }

    setIsPosting(true);

    try {
      await reVerifyPhone();
      setIsPosting(false);
      alert.success('Code Sent');
      navigate(`/my-cards/${userGiftCard?.id}/manage-pin/verify`);
    } catch (error: any) {
      setIsPosting(false);
      alert.error(error.message.toString());
    }
  };

  return (
    <>
      <ManagePinHeader />
      <SideDrawerContainer>
        <ManagePinParagraphHeader>
          Send a verification code
        </ManagePinParagraphHeader>
        <ManagePinParagraph>
          A verification code will be sent to your phone{' '}
          {partialHidePhone(userDetailsVM?.mobileNumber || '', 'X')}.
        </ManagePinParagraph>
        <Button
          title="SEND CODE"
          style={{ width: '25rem', height: '4.5rem' }}
          loading={isPosting}
          role="Secondary"
          onClick={onSubmit}></Button>
      </SideDrawerContainer>
    </>
  );
};

export default ManagePin;
