import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '../../../../../const/Colors';
import { useAccountDeletion } from '../../../../../context/AccountDeletionProvider';

const CancelStyle = styled.div`
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-transform: uppercase;
  text-align: center;
  color: ${Colors.black};
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
`;

interface OwnProps {
  onCancel?: () => void;
}
const Cancel: React.FC<OwnProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const { setShowDeleteAccountModal, setDeletionRequestReason } =
    useAccountDeletion();
  const onClick = () => {
    onCancel && onCancel();
    setDeletionRequestReason(undefined);
    setShowDeleteAccountModal(false);
    navigate('/settings');
  };
  return <CancelStyle onClick={onClick}>Cancel</CancelStyle>;
};

export default Cancel;
