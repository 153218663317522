import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../api/ApiProvider';
import { Button } from '../../../components/Button';
import { TextAreaInput } from '../../../components/Input';
import { COMMON_ERR_MSG } from '../../../const/shared';
import { useOptInOut } from '../../../context/OptInOutProvider';
import OptOutWrapper from '../OptOutWrapper';

interface IProps {}

const OptOutReason: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  const { optInOutData: optOutData } = useOptInOut();
  const { emailApi } = useApi();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const alert = useAlert();
  const onSubmit = async (message?: string) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      await emailApi.sendOptOutEmail(undefined, {
        ...optOutData,
        message,
      });
      navigate('/opt-out/confirmation');
    } catch {
      alert.error(COMMON_ERR_MSG);
      setIsSubmitting(false);
    }
  };
  const { control, handleSubmit } = useForm({
    defaultValues: {
      message: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  return (
    <OptOutWrapper>
      <p className="header">Why are you leaving?</p>
      <p className="desc">Please let us know why you want to leave Special.</p>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextAreaInput
            className="opt-out-input"
            label="Tell us more..."
            onChange={onChange}
            style={{ height: 200 }}
            onBlur={() => {
              onChange(value?.trim());
              onBlur();
            }}
            value={value}
          />
        )}
        name="message"
      />
      <Button
        title="Next"
        loading={isSubmitting}
        onClick={() => {
          handleSubmit((data) => {
            onSubmit(data.message);
          })();
        }}
        role="Secondary"
        style={{ marginTop: '2.5rem' }}
      />
      <Button
        title="Skip"
        onClick={() => {
          onSubmit();
        }}
        role="Secondary"
        color="white"
        style={{ marginTop: '0.5rem', borderWidth: 0 }}
      />
    </OptOutWrapper>
  );
};

export default OptOutReason;
