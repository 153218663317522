import '../Banner/banner.scss';

interface BannerProps {
  className?: string;
  children: React.ReactNode;
}

const Banner = ({ children, className }: BannerProps) => {
  return (
    <div className="banner">
      <div className={`content-container ${className}`}>{children}</div>
    </div>
  );
};
export default Banner;
