import React from 'react';
import Colors from '../../../../../const/Colors';
import {
  InnerCircleStyle,
  OptionStyles,
  RadioStyles,
  TextStyles,
} from './styles';

interface OwnProps {
  text: string;
  isSelected?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Option: React.FC<OwnProps> = ({ text, isSelected, style, onClick }) => {
  return (
    <OptionStyles style={style} onClick={onClick}>
      <RadioStyles
        style={{
          borderColor: isSelected ? Colors.electricBlue : Colors.black,
        }}>
        <InnerCircleStyle
          style={{
            backgroundColor: isSelected ? Colors.electricBlue : undefined,
          }}
        />
      </RadioStyles>
      <TextStyles>{text}</TextStyles>
    </OptionStyles>
  );
};

export default Option;
