import styled from 'styled-components';
import Colors from '../../../../const/Colors';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
`;

export const Skip = styled.div`
  font-size: 1.7rem;
  line-height: 2.2rem;
  color: ${Colors.line1};
  margin-left: 1.5rem;
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  margin-top: 2.2rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 3.2rem;
`;

export const CancelStyles = styled.div`
  margin-top: 2rem;
`;

export const CounterStyles = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: right;
  margin-top: 0.8rem;
  color: ${Colors.counterColor};
  margin-bottom: 1.25rem;
`;
