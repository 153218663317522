import './scroller.scss';
import Marquee from 'react-fast-marquee';

interface ScrollerProps {
  content: string;
  inverse?: boolean;
  speed?: number;
}

const Scroller = ({ content, inverse, speed }: ScrollerProps) => {
  return (
    <div className="scroller-outer">
      <div className={`scroller-container ${inverse ? 'inverse' : ''}`}>
        <Marquee speed={speed || 100} gradient={false}>
          {[...Array(10)].map((e, i) => (
            <span key={i}>{content}</span>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
export default Scroller;
