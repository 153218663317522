import React from 'react';
import './option.scss';

interface DefaultProps extends React.InputHTMLAttributes<any> {
  label?: string;
}
interface OptionProps extends DefaultProps {
  type: 'radio' | 'checkbox';
}

interface SquareProps extends DefaultProps {
  variant: 'small' | 'big';
}

export const Option = ({ label, type, ...props }: OptionProps) => {
  return (
    <label className="special-options">
      <input type={type} {...props} />
      <span> </span>
      {label}
    </label>
  );
};

export const Square = ({ label, variant, ...props }: SquareProps) => {
  return (
    <label className="special-options-square">
      <input type="radio" {...props} />
      <div className={`box ${variant}`}>{label}</div>
    </label>
  );
};
