import styled from 'styled-components';

const Header = styled.h1`
  font-size: 4rem;
  line-height: 4.8rem;
  margin-bottom: 1rem;
`;

type props = {
  children: HTMLElement | string | null | undefined;
  containerStyle?: React.CSSProperties;
};

const PurchaseHeader = ({ children, containerStyle }: props) => (
  <Header style={containerStyle}>{children}</Header>
);
export default PurchaseHeader;
