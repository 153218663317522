import { useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useApi } from '../../api/ApiProvider';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import { COMMON_ERR_MSG, NAME_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import './Modal.scss';

type props = {
  setOpen(value: boolean): void;
};

const NameModal = ({ setOpen }: props) => {
  const { userDetailsVM, setUserDetailsVM } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userDetailsVM?.firstName || '',
      lastName: userDetailsVM?.lastName || '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { userApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const onSubmit = async (data: { firstName: any; lastName: any }) => {
    setIsLoading(true);
    const { firstName, lastName } = data;
    try {
      await userApi.updateUser(userDetailsVM?.id || '', undefined, data);
      if (userDetailsVM) {
        setUserDetailsVM({
          ...userDetailsVM,
          firstName: firstName,
          lastName: lastName,
          fullName: `${firstName} ${lastName}`,
        });
      }
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      alert.error(COMMON_ERR_MSG);
    }
  };

  return (
    <>
      <div className="Modal">
        <p className="ModalTitle">What can we call you?</p>
        <p className="ModalSubTitle">
          Enter your preferred first and last names.
        </p>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: NAME_REGEX,
            minLength: 2,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="First name"
              type={'text'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!errors.firstName}
              maxLength={30}
            />
          )}
          name="firstName"
        />
        {errors.firstName?.type === 'required'  && (
          <div>
            <ErrorText text="First name is required" />
          </div>
        )}
        {errors.firstName?.type === 'minLength' && (
          <div>
            <ErrorText text="A minimum of 2 characters is required" />
          </div>
        )}
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: NAME_REGEX,
            minLength: 2,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="Surname"
              type={'text'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!errors.lastName}
              maxLength={30}
            />
          )}
          name="lastName"
        />
        {errors.lastName?.type === 'required' && (
          <div>
            <ErrorText text="Surname is required" />
          </div>
        )}
        {errors.lastName?.type === 'minLength' && (
          <div>
            <ErrorText text="A minimum of 2 characters is required" />
          </div>
        )}
        <Button
          title="Update Name"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        />
        <div className="auth-cancel" onClick={() => setOpen(false)}>
          CANCEL
        </div>
      </div>
    </>
  );
};

export default NameModal;
