import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import Sun from '../../assets/svg/loader/Sun.svg';
import Colors from '../../const/Colors';

type Props = {
  isLoading: boolean;
};

type ProgressProps = {
  progress: number;
  color: string;
};

type ContainerProps = {
  hide: boolean;
};

const Container = styled.div<ContainerProps>`
  position: fixed;
  background: ${(props) => props.theme.colours.white};
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Kobe-bold;
  font-weight: bold;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.hide ? 0 : 1)};
  font-size: 2rem;
  transition: visibility 0s 2s, opacity 0.5s linear;
  img {
    margin-bottom: 2rem;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const ProgressBar = styled.div<ProgressProps>`
  position: absolute;
  width: ${(props) => props.progress}vw;
  height: 0.5rem;
  background: ${(props) => props.color};
  bottom: 0;
  left: 0;
  transition: width 0.5s;
`;

const PageLoader = (props: Props) => {
  const [progress, setProgress] = useState<number>(0);
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!props.isLoading) {
      setProgress(100);
      timeout = setTimeout(() => {
        setHide(true);
      }, 500);
      return;
    }
    setProgress(30);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [props.isLoading]);

  const progressColor = useMemo(() => {
    if (progress > 50) {
      return Colors.orangeSun;
    }
    return Colors.electricBlue;
  }, [progress]);
  return (
    <Container hide={hide}>
      <img src={Sun} alt="Loading" />
      LOADING...
      <ProgressBar progress={progress} color={progressColor} />
    </Container>
  );
};

export default PageLoader;
