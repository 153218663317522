import { useState, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { MobileInput } from '../../components/Input';
import { SMS_SENDING_GAP_IN_SECONDS } from '../../const/auth';
import { COMMON_ERR_MSG } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import { GetCurrentAuthenticatedUser } from '../../services/auth/AuthServices';

type props = {
  setOpen(value: boolean): void;
  setSentCode(value: boolean): void;
  setNewNumber(value: string): void;
};

const PhoneNumberModal = ({ setOpen, setSentCode, setNewNumber }: props) => {
  const alert = useAlert();
  const { userDetailsVM, lastSMSSent, verifyPhone, reVerifyPhone } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const phoneNumber = userDetailsVM?.mobileNumber;

  const [currentTime, setCurrentTime] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();

      setCurrentTime(currentTime);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const timeGap: number | null = useMemo(() => {
    if (currentTime instanceof Date && lastSMSSent instanceof Date) {
      const gap = Math.ceil(
        (currentTime.getTime() - lastSMSSent.getTime()) / 1000
      );
      return gap;
    }
    return null;
  }, [lastSMSSent, currentTime]);

  const onSubmit = async (data: { phone: string }) => {
    const mobile = `+${data.phone}`;
    setIsLoading(true);
    if (mobile === phoneNumber) {
      setIsLoading(false);
      alert.error(
        'Phone number already connected. Please enter your new phone number.'
      );
      return;
    }
    if (timeGap !== null && timeGap < SMS_SENDING_GAP_IN_SECONDS) {
      return;
    }

    try {
      const user = await GetCurrentAuthenticatedUser();
      if (mobile === user?.attributes?.phone_number) {
        await reVerifyPhone();
      } else {
        await verifyPhone(mobile);
      }
      setNewNumber(mobile);
      setSentCode(true);
    } catch (error) {
      setIsLoading(false);
      alert.error(COMMON_ERR_MSG);
    }
  };

  return (
    <>
      <p className="ModalTitle">Let us know where to send verification codes</p>
      <p className="ModalSubTitle">Enter your phone number</p>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="ModalInput">
            <MobileInput
              onChange={onChange}
              value={value}
              error={!!errors.phone}
            />
          </div>
        )}
        name="phone"
      />
      {errors.phone && (
        <div>
          <ErrorText text="Phone is required" />
        </div>
      )}
      <Button
        title="Send Code"
        role="Secondary"
        onClick={handleSubmit(onSubmit)}
        loading={isLoading}
      />
      <div className="auth-cancel" onClick={() => setOpen(false)}>
        CANCEL
      </div>
    </>
  );
};

export default PhoneNumberModal;
