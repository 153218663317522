import React from 'react';
import styled from 'styled-components';
import { MerchantVM } from '../../../../generated';

type Props = {
  data: MerchantVM;
};

const RetailersItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colours.border};
  margin-top: 2.4rem;
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 2.4rem;
  flex: 0 0 45%;
`;

const RetailersItem = ({ data }: Props) => {
  return <RetailersItemContainer>{data.name}</RetailersItemContainer>;
};

export default RetailersItem;
