import styled from 'styled-components';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';

export const TagWrapper = styled.div`
  background-color: ${Colors.poppyRed};
  border-radius: 6rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.6rem;
  ${map({ tablet: '0.9rem' }, (marginTop) => `margin-top: ${marginTop};`)};
  padding-top: 0.58rem;
  ${map({ tablet: '0.62rem' }, (paddingTop) => `padding-top: ${paddingTop};`)};
  padding-bottom: 0.38rem;
`;

export const TextStyle = styled.span`
  font-family: 'Agrandir';
  font-size: 1.3rem;
  ${map(
    { tablet: 'min(1.5rem, 1.03vw)' },
    (fontSize) => `font-size: ${fontSize};`
  )}
  line-height: 1.7rem;
  ${map({ tablet: '1.9rem' }, (lineHeight) => `line-height: ${lineHeight};`)}
  text-transform: uppercase;
  color: ${Colors.white};
  font-weight: bold;
  margin-right: 1rem;
  ${map(
    { tablet: 'min(1rem, 1.0vw)' },
    (marginRight) => `margin-right: ${marginRight};`
  )};
  white-space: nowrap;
`;

export const TagIconWrapper = styled.div`
  height: 1.4rem;
  ${map({ tablet: '1.6rem' }, (height) => `height: ${height};`)}
  width: 1.4rem;
  ${map({ tablet: '1.6rem' }, (width) => `width: ${width};`)}
  margin-left: 1rem;
  ${map({ tablet: '1rem' }, (marginLeft) => `margin-left: ${marginLeft};`)}
  margin-right: 0.7rem;
  ${map({ tablet: '0.8rem' }, (marginRight) => `margin-right: ${marginRight};`)}
  align-self: center;
`;
