import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ErrorText } from '../../components/ErrorText';
import { Input } from '../../components/Input';
import Stepper from '../../components/Stepper';
import { NAME_REGEX } from '../../const/shared';
import { useAuth } from '../../context/AuthProvider';
import './Auth.scss';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import AuthWrapper from './AuthWrapper';
import { RudderStack } from '../../services/shared/RudderStack';

const CreateAccountName = () => {
  const {
    newUserDetails,
    setNewUserDetails,
    navState,
    setNavState,
    createAccountStepperClicks,
  } = useAuth();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: newUserDetails.firstName,
      lastName: newUserDetails.lastName,
    },
    mode: 'onBlur',

    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    RudderStack.page('Auth_CreateAccountName');
  }, []);

  useKeyboardEvent((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  });

  const onSubmit = async (data: any) => {
    setNewUserDetails({
      ...newUserDetails,
      ...data,
    });
    navigate('/auth/createAccountEmail');
  };

  useEffect(() => {
    if (navState) {
      return;
    }
    setNavState(1);
  });

  return (
    <AuthWrapper>
      <>
        <Stepper
          numberOfSteps={5}
          currentStep={navState}
          containerStyle="auth-stepper"
          onStepClick={createAccountStepperClicks}
        />
        <p className="auth-create-account-title">Enter your name</p>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: NAME_REGEX,
            minLength: 2,
            maxLength: 30,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="First name"
              autoFocus
              type={'text'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!errors.firstName}
              maxLength={30}
            />
          )}
          name="firstName"
        />
        {errors.firstName?.type === 'required' && (
          <div>
            <ErrorText text="Enter your first name" />
          </div>
        )}
        {errors.firstName?.type === 'pattern' && (
          <div>
            <ErrorText text="First name must contain alpha characters only" />
          </div>
        )}
        {errors.firstName?.type === 'minLength' && (
          <div>
            <ErrorText text="A minimum of 2 characters is required" />
          </div>
        )}
        {errors.firstName?.type === 'maxLength' && (
          <div>
            <ErrorText text="A maximum of 30 characters is allowed" />
          </div>
        )}
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: NAME_REGEX,
            minLength: 2,
            maxLength: 30,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              className="auth-input"
              label="Surname"
              type={'text'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={!!errors.lastName}
              maxLength={30}
            />
          )}
          name="lastName"
        />
        {errors.lastName?.type === 'required' && (
          <div>
            <ErrorText text="Enter your surname" />
          </div>
        )}
        {errors.lastName?.type === 'pattern' && (
          <div>
            <ErrorText text="Surname must contain alpha characters only" />
          </div>
        )}
        {errors.lastName?.type === 'minLength' && (
          <div>
            <ErrorText text="A minimum of 2 characters is required" />
          </div>
        )}
        {errors.lastName?.type === 'maxLength' && (
          <div>
            <ErrorText text="A maximum of 30 characters is allowed" />
          </div>
        )}
        <Button
          title="Next"
          role="Secondary"
          onClick={handleSubmit(onSubmit)}
        />
      </>
    </AuthWrapper>
  );
};

export default CreateAccountName;
