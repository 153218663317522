import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '../../../../components/ArrowBack';
import { Button } from '../../../../components/Button';
import { TextAreaInput } from '../../../../components/Input';
import Colors from '../../../../const/Colors';
import { useAccountDeletion } from '../../../../context/AccountDeletionProvider';
import { DeletionRequestReason } from '../../../../generated';
import Cancel from '../common/Cancel';
import Divider from '../common/Divider';
import Option from '../common/Option';
import { BASE_DELETE_ACCOUNT_ROUTE } from '../const';
import { HeaderText, SubText } from '../styles';
import { RequestReasons } from './const';
import {
  ButtonWrapper,
  CancelStyles,
  CounterStyles,
  HeaderWrapper,
  OptionsWrapper,
  Skip,
} from './styles';
import { Reason } from './types';
import { RudderStack } from '../../../../services/shared/RudderStack';
import { apiObject } from 'rudder-sdk-js';

const MAX_INPUT_CHARACTERS = 500;
const TEXTAREA_LABEL = 'Please provide more details';

const QuestionAnswer = () => {
  const navigate = useNavigate();
  const { deletionRequestReason, setDeletionRequestReason } =
    useAccountDeletion();

  const initialSelectedReason = deletionRequestReason?.deletionRequestReason
    ? {
        ...RequestReasons[deletionRequestReason?.deletionRequestReason],
        message: deletionRequestReason.message,
      }
    : undefined;

  const initialIssueText =
    initialSelectedReason?.deletionRequestReason ===
    DeletionRequestReason.TechnicalIssues
      ? initialSelectedReason.message
      : undefined;

  const initialOtherText =
    initialSelectedReason?.deletionRequestReason === DeletionRequestReason.Other
      ? initialSelectedReason.message
      : undefined;

  const [selectedReason, setSelectedReason] = useState<Reason | undefined>(
    initialSelectedReason
  );

  const onPress = (reason: Reason) => {
    if (reason.id === selectedReason?.id) {
      setSelectedReason(undefined);
    } else {
      setSelectedReason(reason);
    }
  };

  const [labelColor, setLabelColor] = useState(Colors.line1);

  const [issueText, setIssueText] = useState<string | undefined>(
    initialIssueText || ''
  );

  const onIssueTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssueText(event.target.value);
  };
  const isIssueOptionSelected = useMemo(
    () => RequestReasons.TechnicalIssues.id === selectedReason?.id,
    [selectedReason?.id]
  );

  const [otherText, setOtherText] = useState<string | undefined>(
    initialOtherText || ''
  );

  const onOtherTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherText(event.target.value);
  };
  const isOtherOptionSelected = useMemo(
    () => RequestReasons.Other.id === selectedReason?.id,
    [selectedReason?.id]
  );

  const setDeletionReason = () => {
    let deletionReason: Reason | undefined;

    if (!selectedReason) {
      deletionReason = undefined;
    } else if (selectedReason?.id === 2) {
      deletionReason = {
        ...selectedReason,
        message: issueText ? issueText : undefined,
      };
    } else if (selectedReason.id === 4) {
      deletionReason = {
        ...selectedReason,
        message: otherText ? otherText : undefined,
      };
    } else {
      deletionReason = selectedReason;
    }

    const deleteReason: apiObject = {};
    const reasonDetail = deletionReason?.message ? deletionReason.message : '';

    switch (selectedReason?.id) {
      case RequestReasons.NoLongerInterested.id:
        deleteReason.reason = RequestReasons.NoLongerInterested.eventMessage;
        break;
      case RequestReasons.IncorrectAccount.id:
        deleteReason.reason = RequestReasons.IncorrectAccount.eventMessage;
        break;
      case RequestReasons.TechnicalIssues.id:
        deleteReason.reason = RequestReasons.TechnicalIssues.eventMessage;
        deleteReason.reason_detail = reasonDetail;
        break;
      case RequestReasons.Other.id:
        deleteReason.reason = RequestReasons.Other.eventMessage;
        deleteReason.reason_detail = reasonDetail;
        break;
    }

    RudderStack.track('Account Deletion Reason Submitted', deleteReason);
    setDeletionRequestReason(deletionReason);
  };

  const onNext = () => {
    setDeletionReason();
    navigate(`${BASE_DELETE_ACCOUNT_ROUTE}/legal`);
  };

  const onSkip = () => {
    RudderStack.track('Account Deletion Reason Skipped');
    setDeletionRequestReason(undefined);
    navigate(`${BASE_DELETE_ACCOUNT_ROUTE}/legal`);
  };

  return (
    <div>
      <ArrowBack text="BACK" />
      <HeaderWrapper>
        <HeaderText>Why are you leaving Special?</HeaderText>
        <Skip onClick={onSkip}>Skip</Skip>
      </HeaderWrapper>
      <SubText>
        We’re sorry to see you go! We’d love to know why you want to delete your
        account, so we can improve the website.
      </SubText>

      <OptionsWrapper>
        <div>
          <Option
            text={RequestReasons.NoLongerInterested.text}
            style={{ ...optionStyles, marginTop: 23 }}
            onClick={() => onPress(RequestReasons.NoLongerInterested)}
            isSelected={
              RequestReasons.NoLongerInterested.id === selectedReason?.id
            }
          />
          <Divider styles={dividerStyles} />
        </div>

        <div>
          <Option
            text={RequestReasons.TechnicalIssues.text}
            style={{ ...optionStyles }}
            onClick={() => onPress(RequestReasons.TechnicalIssues)}
            isSelected={
              RequestReasons.TechnicalIssues.id === selectedReason?.id
            }
          />
          {isIssueOptionSelected && (
            <div>
              <TextAreaInput
                label={TEXTAREA_LABEL}
                onChange={onIssueTextChange}
                onBlur={() => {
                  setLabelColor(Colors.line1);
                  setIssueText(issueText?.trim());
                }}
                value={issueText}
                maxLength={MAX_INPUT_CHARACTERS}
                style={textAreaStyles}
                labelStyle={{ whiteSpace: 'unset', color: labelColor }}
                onFocus={() => {
                  setLabelColor(Colors.white);
                }}
              />
              <CounterStyles>
                {`${issueText?.length || 0}  /  ${MAX_INPUT_CHARACTERS}`}
              </CounterStyles>
            </div>
          )}
          {!isIssueOptionSelected && <Divider styles={dividerStyles} />}
        </div>

        <div>
          <Option
            text={RequestReasons.IncorrectAccount.text}
            style={{ ...optionStyles }}
            onClick={() => onPress(RequestReasons.IncorrectAccount)}
            isSelected={
              RequestReasons.IncorrectAccount.id === selectedReason?.id
            }
          />
          <Divider styles={dividerStyles} />
        </div>

        <div>
          <Option
            text={RequestReasons.Other.text}
            style={{ ...optionStyles }}
            onClick={() => onPress(RequestReasons.Other)}
            isSelected={RequestReasons.Other.id === selectedReason?.id}
          />
          {isOtherOptionSelected && (
            <div>
              <TextAreaInput
                label={TEXTAREA_LABEL}
                onChange={onOtherTextChange}
                onBlur={() => {
                  setLabelColor(Colors.line1);
                  setOtherText(otherText?.trim());
                }}
                value={otherText}
                maxLength={MAX_INPUT_CHARACTERS}
                style={textAreaStyles}
                labelStyle={{ whiteSpace: 'unset', color: labelColor }}
                onFocus={() => {
                  setLabelColor(Colors.white);
                }}
              />
              <CounterStyles>
                {`${otherText?.length || 0}  /  ${MAX_INPUT_CHARACTERS}`}
              </CounterStyles>
            </div>
          )}
        </div>
      </OptionsWrapper>
      <ButtonWrapper>
        <Button
          title="Next"
          role="Secondary"
          disabled={!selectedReason}
          style={{
            backgroundColor: Colors.black,
            opacity: selectedReason ? 1 : 0.65,
          }}
          onClick={onNext}
        />
        <CancelStyles>
          <Cancel
            onCancel={() => {
              RudderStack.track('Account Deletion Aborted', {
                interaction_location: 'Account Deletion Reason',
              });
            }}
          />
        </CancelStyles>
      </ButtonWrapper>
    </div>
  );
};

const optionStyles: React.CSSProperties = {
  marginBottom: 20,
};
const dividerStyles: React.CSSProperties = {
  marginBottom: 22,
};
const textAreaStyles: React.CSSProperties = {
  height: 10,
};

export default QuestionAnswer;
