import React from 'react';

import './styles.scss';

interface OwnProps {
  text?: string;
}

const Divider: React.FC<OwnProps> = (props) => {
  const { text } = props;

  if (!text) {
    return <div className="singleDivider" />;
  }

  return (
    <div className="divider">
      <div className="singleDivider" />
      <span className="text">{text}</span>
      <div className="singleDivider" />
    </div>
  );
};

export default Divider;
