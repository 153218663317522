import React, { useMemo } from 'react';
import styled from 'styled-components';
import Colors from '../../../../const/Colors';

interface OwnProps {
  amount: number;
}

const AmountWrapper = styled.div`
  background: rgba(0, 204, 168, 0.08);
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
`;

const AmountStyles = styled.div`
  color: ${Colors.seaGreen};
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: bold;
`;

const Amount: React.FC<OwnProps> = ({ amount }) => {
  const parsedAmount = useMemo(() => Math.abs(amount).toFixed(2), [amount]);

  if (amount < 0) {
    return (
      <AmountStyles style={{ color: Colors.black }}>
        -${parsedAmount}
      </AmountStyles>
    );
  }
  return (
    <AmountWrapper>
      <AmountStyles>${parsedAmount}</AmountStyles>
    </AmountWrapper>
  );
};

export default Amount;
