import styled from 'styled-components';
import { map } from '../../../const/theme';

export const WavesWrapper = styled.div`
  position: relative;
  margin: 0rem -3.2rem;
`;

export const BackWrapper = styled.div`
  z-index: 2;
`;

export const LogoWrapper = styled.div`
  flex: 1;
  justify-content: center;
  margin-left: -6.8rem;
  display: flex;
  ${map(
    { tablet: 'flex-start' },
    (justifyContent) => `justify-content: ${justifyContent};`
  )}
  ${map({ tablet: '0rem' }, (marginLeft) => `margin-left: ${marginLeft};`)}
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 3.2rem;
  min-height: 50px;
  height: 50px;
  align-items: center;
  ${map(
    { tablet: 'flex-start' },
    (alignItems) => `align-items: ${alignItems};`
  )}
  width: 100%;
`;

export const Logo = styled.img`
  border-radius: 50%;
  max-width: min(16rem, 38.6vw);
  ${map(
    { tablet: 'min(14rem, 9.4vw)' },
    (maxWidth) => `max-width: ${maxWidth};`
  )}
`;
