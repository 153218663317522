import './tab.scss';

interface TabProps {
  title?: string;
  role?: 'Active' | 'Regular';
  disabled?: boolean;
  onClick?: () => void;
}

export const Tab = ({ title, role, disabled, ...props }: TabProps) => {
  const roleClass = role?.toLocaleLowerCase();
  return (
    <button
      role="tab"
      aria-selected="false"
      tabIndex={-1}
      className={`special-tab ${roleClass}`}
      disabled={disabled}
      {...props}>
      {title}
    </button>
  );
};
