import { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import Breakpoints from '../../../const/breakpoints';
import Colors from '../../../const/Colors';
import { map } from '../../../const/theme';
import { GiftCardDetailsVM } from '../../../generated';
import { getCardStarColors, isBrandMerchant } from '../../../helpers/utils';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CelebrationAnimation from '../../CelebrationAnimation';
import DynamicCard from '../../DynamicCard';
import { FixedCol } from '../../Layouts';
import GoBack from '../../Navigation/GoBack';
import SvgWaves from '../../Svg/Waves';

type props = {
  giftCard: GiftCardDetailsVM | undefined;
  children?: any;
  backButton?: {
    text: string;
    link: string;
  };
  showBackButton?: boolean;
  cardImage?: string;
  showAnimation?: boolean;
  animationType?: string;
  waveBackLink?: string;
};

const LeftColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  ${map({ tablet: '66%' }, (size) => `width: ${size};`)}

  span {
    position: sticky;
    top: 10rem;
    margin: 5rem 6rem 5rem 4rem;

    ${map({ tablet: '15rem 10rem 12rem 8rem' }, (size) => `margin: ${size};`)}
  }

  .star1 {
    position: absolute;
    width: 5rem;
    top: -5rem;
    left: -4rem;
    ${map({ tablet: '7rem', laptop: '10rem' }, (size) => `width: ${size};`)}
    ${map({ tablet: '-7rem', laptop: '-9rem' }, (space) => `top: ${space};`)}
    ${map({ tablet: '-6rem', laptop: '-7rem' }, (space) => `left: ${space};`)}
  }

  .star2 {
    position: absolute;
    right: -10rem;
    transform: scaleX(-1) rotate(15deg);
    width: 5rem;
    bottom: -3rem;
    right: -6rem;
    ${map({ tablet: '7rem', laptop: '10rem' }, (size) => `width: ${size};`)}
    ${map({ tablet: '-5rem', laptop: '-7rem' }, (space) => `bottom: ${space};`)}
    ${map({ tablet: '-8rem', laptop: '-10rem' }, (space) => `right: ${space};`)}
  }
`;
const RightColumn = styled.div`
  padding: 0 0rem 5rem 0rem;
  max-width: 100rem;
  z-index: 9;
  ${map({ tablet: '33%' }, (size) => `width: ${size};`)}
  ${map({ tablet: '34rem' }, (size) => `min-width: ${size};`)}

  > * {
    ${map({ tablet: '35rem' }, (size) => `max-width: ${size};`)}
  }
`;

const ImageLoading = {
  maxWidth: '47.5rem',
  maxHeight: '25.3rem',
  width: '100%',
  height: '100%',
  transform: 'rotate(-8deg)',
  borderRadius: '1.5rem',
  minWidth: '80%',
};

const AnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
`;

const ContentWrapper = styled.div`
  margin-top: 0;
`;

const PurchaseWrapper = ({
  giftCard,
  children,
  backButton,
  showBackButton = true,
  cardImage,
  showAnimation = false,
  animationType,
  waveBackLink,
}: props) => {
  const mapImage = () => {
    const images = giftCard?.giftCardProgram?.programProfiles;
    if (!images) return '';

    return images[0].frontsideImage || '';
  };

  const { starOneColour, starTwoColour } = useMemo(() => {
    return getCardStarColors(giftCard);
  }, [giftCard]);

  const isBranded = isBrandMerchant(giftCard);
  const {
    primaryColour,
    affiliationTextColour,
    squareCardColourLogo: logo,
  } = giftCard?.giftCardProgram?.brandMerchant || {};

  const topStarsColour = useMemo(() => {
    if (isBranded) {
      return primaryColour || Colors.seaGreen;
    }
    return starOneColour || Colors.starOrange;
  }, [isBranded, primaryColour, starOneColour]);

  const bottomStarsColour = useMemo(() => {
    if (isBranded) {
      return primaryColour || Colors.seaGreen;
    }
    return starTwoColour || Colors.electricBlue;
  }, [isBranded, primaryColour, starTwoColour]);

  const { windowWidth } = useWindowDimensions();
  const isMobileView = useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );
  const contentMarginTop = useMemo(() => {
    if (isBranded && !isMobileView) {
      return '-9rem';
    } else if (isBranded && isMobileView) {
      return '-3rem';
    } else return '0rem';
  }, [isBranded, isMobileView]);

  return (
    <>
      {isBranded ? (
        <SvgWaves
          fill={primaryColour || Colors.seaGreen}
          logoUrl={logo || ''}
          backTextColor={affiliationTextColour || Colors.white}
          to={waveBackLink}
        />
      ) : (
        showBackButton && (
          <GoBack link={backButton?.link || '/'} title={backButton?.text} />
        )
      )}
      <ContentWrapper style={{ marginTop: contentMarginTop }}>
        <FixedCol inverse={true} noStick={true}>
          <LeftColumn>
            <span>
              {cardImage || mapImage() ? (
                <DynamicCard
                  imageURL={cardImage || mapImage()}
                  cardRotation={-8}
                  borderRadius={18}
                  topStarsColor={topStarsColour}
                  bottomStarsColor={bottomStarsColour}
                />
              ) : (
                <div style={ImageLoading}>
                  <ContentLoader
                    speed={2}
                    width={'100%'}
                    height={'100%'}
                    viewBox="0 0 457 320"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="0" ry="0" width="457" height="320" />
                  </ContentLoader>
                </div>
              )}
              {showAnimation && (
                <AnimationWrapper>
                  <CelebrationAnimation loop animationType={animationType} />
                </AnimationWrapper>
              )}
            </span>
          </LeftColumn>
          <RightColumn>{children}</RightColumn>
        </FixedCol>
      </ContentWrapper>
    </>
  );
};
export default PurchaseWrapper;
