import * as React from 'react';
import { SVGProps } from 'react';
import Breakpoints from '../../../const/breakpoints';
import Colors from '../../../const/Colors';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface OwnProps extends SVGProps<SVGSVGElement> {
  circleStroke?: string;
  arrowColor?: string;
  onClick?: () => void;
}

const MOBILE_DIMENSIONS = 32;
const DESKTOP_DIMENSIONS = 40;

const SvgCircleBack: React.FC<OwnProps> = ({
  circleStroke = Colors.orangeSun,
  arrowColor = Colors.black,
  onClick,
  ...props
}) => {
  const { windowWidth } = useWindowDimensions();
  const isMobileView = React.useMemo(
    () => windowWidth < Breakpoints.tablet,
    [windowWidth]
  );

  const dimension = React.useMemo(() => {
    return isMobileView ? MOBILE_DIMENSIONS : DESKTOP_DIMENSIONS;
  }, [isMobileView]);

  return (
    <svg
      width={dimension}
      height={dimension}
      fill="none"
      onClick={onClick}
      viewBox="0 0 40 41"
      style={{ cursor: 'pointer' }}
      {...props}>
      <circle cx={20} cy={20.5} r={19} stroke={circleStroke} strokeWidth={2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 21a.625.625 0 0 0-.625-.625h-13.75a.625.625 0 1 0 0 1.25h13.75c.345 0 .625-.28.625-.625Z"
        fill={arrowColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.192 14.933a.625.625 0 0 0-.884 0l-5.625 5.625a.625.625 0 0 0 0 .884l5.625 5.625a.625.625 0 1 0 .884-.884L14.009 21l5.183-5.183a.625.625 0 0 0 0-.884Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default SvgCircleBack;
