import { Button } from "../Button";
import './modal.scss';

type props = {
  open: boolean;
  setOpen: ( open:boolean ) => void;
  title: string;
  text: string;
}

const Modal = ({open, setOpen, title, text}: props) => {
  

  return (
    <>
    {open && 
      <div className="modal-container">
        <div className="modal">
          <p className="modal-title">{title}</p>
          <p className="modal-text">{text}</p>
          <Button title="DONE" role="Secondary" onClick={()=>setOpen(false)}/>
        </div>
      </div>
    }
    </>
  );
}

export default Modal