import { Route, Routes } from 'react-router';
import Customise from '../containers/Purchase/Customise';
import Delivery from '../containers/Purchase/Delivery';
import Failed from '../containers/Purchase/Failed';
import Payment from '../containers/Purchase/Payment';
import Personalise from '../containers/Purchase/Personalise';
import SetValue from '../containers/Purchase/SetValue';
import Success from '../containers/Purchase/Success';
import Summary from '../containers/Purchase/Summary';
import { PromotionCodeVM } from '../generated';

const PurchaseRoutes: React.FC<{
  changeProviderKey: any;
  promo: PromotionCodeVM;
}> = ({ changeProviderKey, promo }) => {
  return (
    <>
      <Routes>
        <Route path="/set-value" element={<SetValue />} />
        <Route path="/personalise" element={<Personalise />} />
        <Route path="/customise" element={<Customise />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/summary" element={<Summary />} />
        <Route
          path="/payment"
          element={
            <Payment changeProviderKey={changeProviderKey} promo={promo} />
          }
        />
        <Route path="/success" element={<Success />} />
        <Route path="/failed" element={<Failed />} />
      </Routes>
    </>
  );
};

export default PurchaseRoutes;
