import PurchaseLinkIndicatorCustomiseActive from '../../../assets/svg/header/PurchaseLinkIndicatorCustomiseActive.svg';
import PurchaseLinkIndicatorDeliveryActive from '../../../assets/svg/header/PurchaseLinkIndicatorDeliveryActive.svg';
import PurchaseLinkIndicatorPaymentActive from '../../../assets/svg/header/PurchaseLinkIndicatorPaymentActive.svg';
import PurchaseLinkIndicatorPersonaliseActive from '../../../assets/svg/header/PurchaseLinkIndicatorPersonaliseActive.svg';
import PurchaseLinkIndicatorSetValueActive from '../../../assets/svg/header/PurchaseLinkIndicatorSetValueActive.svg';
import PurchaseLinkIndicatorSummaryActive from '../../../assets/svg/header/PurchaseLinkIndicatorSummaryActive.svg';

interface PurchaseStep {
  link: string;
  stepValue: number;
  stepName: string;
  stepActiveSvg: string;
}

export const PurchaseUrls = {
  SetValue: '/purchase/set-value',
  Personalise: '/purchase/personalise',
  Customise: '/purchase/customise',
  Delivery: '/purchase/delivery',
  Summary: '/purchase/summary',
  Payment: '/purchase/payment',
};

export const PurchaseSteps: Array<PurchaseStep> = [
  {
    link: PurchaseUrls.SetValue,
    stepName: 'Set value',
    stepValue: 0,
    stepActiveSvg: PurchaseLinkIndicatorSetValueActive,
  },
  {
    link: PurchaseUrls.Personalise,
    stepName: 'Personalise',
    stepValue: 1,
    stepActiveSvg: PurchaseLinkIndicatorPersonaliseActive,
  },
  {
    link: PurchaseUrls.Customise,
    stepName: 'Customise',
    stepValue: 2,
    stepActiveSvg: PurchaseLinkIndicatorCustomiseActive,
  },
  {
    link: PurchaseUrls.Delivery,
    stepName: 'Delivery',
    stepValue: 3,
    stepActiveSvg: PurchaseLinkIndicatorDeliveryActive,
  },
  {
    link: PurchaseUrls.Summary,
    stepName: 'Summary',
    stepValue: 4,
    stepActiveSvg: PurchaseLinkIndicatorSummaryActive,
  },
  {
    link: PurchaseUrls.Payment,
    stepName: 'Payment',
    stepValue: 5,
    stepActiveSvg: PurchaseLinkIndicatorPaymentActive,
  },
];
