import Body from '../../components/Body';
import { Hero, HeroSubtitle, HeroTitle } from '../../components/Hero';
import Image from '../../components/Image';
import FemaleBanner from '../../assets/svg/FemaleBanner.svg';
import Glasses from '../../assets/svg/home/Glasses.svg';
import FemaleFro from '../../assets/svg/FemaleFro.svg';
import FixedColumn from '../../components/Layouts/FixedColumn';
import { LayoutContent } from '../../components/Layouts';
import './our-mission.scss';
import { useEffect } from 'react';
import { RudderStack } from '../../services/shared/RudderStack';
import { Helmet } from 'react-helmet';

const OurMission = () => {
  useEffect(() => {
    RudderStack.page('OurMission');
  }, []);

  return (
    <Body theme="electric-blue">
      <Helmet>
        <title>Special Gift Cards | Our Mission</title>
        <meta
          name="description"
          content="We're Special. We're here to change the way the world thinks about gifting, helping people connect with those they care about by giving gifts that have meaning beyond the mundane.
"
        />
      </Helmet>
      <div className="content our-mission">
        <Hero>
          <HeroTitle uppercase={true} inverse={true}>
            Our <img className="icon" src={Glasses} alt="glasses" />
            <br /> Mission
          </HeroTitle>
          <HeroSubtitle inverse={true}>
            Gift Cards just became{' '}
            <span>
              Special<span>&trade;</span>
            </span>
          </HeroSubtitle>
        </Hero>
        <Image desktopImage={FemaleBanner} mobileImage={FemaleBanner} />
        <FixedColumn>
          <LayoutContent>
            We’re Special. We’re here to change the way the world thinks about
            gifting, helping people connect with those they care about by giving
            gifts that have meaning beyond the mundane. <br /> <br />
            Why? Because we believe those Special, happy moments play a big part
            in strengthening our relationships with those we care about. In an
            age when people are looking for something more than material, a
            Special Card can be spent on something they love from a place they
            adore. Make that happen, and they’ll feel the way you want them to
            feel – Special.
          </LayoutContent>
          <img
            src={FemaleFro}
            alt="female holding special card"
            style={{
              alignSelf: 'flex-end',
              height: 'min-content',
            }}
          />
        </FixedColumn>
      </div>
    </Body>
  );
};
export default OurMission;
