import { isIOS } from 'react-device-detect';
import './tag.scss';

export type TagRole = 'Red' | 'Orange' | 'Green' | 'GreenBorder';

interface TagProps {
  title: string;
  role?: TagRole;
  onClick?: () => void;
}

export const Tag = ({ title, role, ...props }: TagProps) => {
  const mode = role?.toLocaleLowerCase();

  if (isIOS) {
    return (
      <span
        className={`special-tag ${mode}`}
        {...props}
        style={{ paddingTop: 3 }}>
        {title}
      </span>
    );
  }
  return (
    <span className={`special-tag ${mode}`} {...props}>
      {title}
    </span>
  );
};
