import { Route, Routes } from 'react-router-dom';
import ApplePayLanding from '../containers/ApplePayLanding';
import Contact from '../containers/Contact';
import Homepage from '../containers/Homepage';
import HowItWorks from '../containers/HowItWorks';
import OurMission from '../containers/OurMission';
import Privacy from '../containers/Privacy';
import Terms from '../containers/Terms';
import NotFound from '../containers/404';
import InternalError from '../containers/500';
import ActivationRouting from '../containers/Activation';
import AuthRoutes from '../routes/AuthRoutes';
import Shop from '../containers/Shop';
import AccountSettings from '../containers/AccountSettings';
import Orders from '../containers/Orders';
import CardDetails from '../containers/CardDetails';
import PurchaseRoutes from '../routes/PurchaseRoutes';
import MyCards from '../containers/MyCards';
import Bye from '../containers/Bye';
import OptInRoutes from '../routes/OptInRoutes';
import OptOutRoutes from '../routes/OptoutRoutes';
import { PromotionCodeVM } from '../generated';

const AppRouting: React.FC<{
  changeProviderKey: any;
  promo: PromotionCodeVM;
}> = ({ changeProviderKey, promo }) => {
  return (
    <Routes>
      <Route index element={<Homepage />} />
      <Route path="terms-conditions" element={<Terms />} />
      <Route path="privacy-policy" element={<Privacy />} />
      <Route path="our-mission" element={<OurMission />} />
      <Route path="apple-pay" element={<ApplePayLanding />} />
      <Route path="how-it-works" element={<HowItWorks />} />
      <Route path="opt-out/*" element={<OptOutRoutes />} />
      <Route path="claim-my-business/*" element={<OptInRoutes />} />
      <Route path="contact-us" element={<Contact />} />
      <Route path="activate/*" element={<ActivationRouting />} />
      <Route path="shop" element={<Shop />} />
      <Route path="shop/:id" element={<CardDetails />} />
      <Route path="/500" element={<InternalError />} />
      <Route path="settings/*" element={<AccountSettings />} />
      <Route path="my-orders" element={<Orders />} />
      <Route path="auth/*" element={<AuthRoutes />} />
      <Route path="my-cards/*" element={<MyCards />} />
      <Route
        path="purchase/*"
        element={
          <PurchaseRoutes changeProviderKey={changeProviderKey} promo={promo} />
        }
      />
      <Route path="bye" element={<Bye />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default AppRouting;
