import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Body from '../../../components/Body';
import { PurchaseWrapper } from '../../../components/Purchase';
import { usePurchase } from '../../../context/PurchaseProvider';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import '../commonStyles.scss';
import { Input, TextAreaInput } from '../../../components/Input';
import { NAME_CHAR_LIMIT, NO_LINK_TEXT_REGEX } from '../../../const/shared';
import { ErrorText } from '../../../components/ErrorText';
import Info from '../../../assets/svg/alert/Info.svg';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { getSelectedProfileImage } from '../../../helpers/cards';

import './styles.scss';
import { RudderStack } from '../../../services/shared/RudderStack';
import { isBrandMerchant } from '../../../helpers/utils';
import PurchaseBack from '../common/Back';

type Props = {};

type FormValues = {
  from: string;
  receiverFirstName: string;
  receiverLastName: string;
  message: string;
};

export const MAX_MESSAGE_LENGTH = 2000;

const Personalise = (props: Props) => {
  const purchase = usePurchase();
  const navigate = useNavigate();
  const [selectedProfileId, setSelectedProfileId] = useState(
    purchase.newPurchaseDetails.profileId || 0
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      from: '',
      receiverFirstName: '',
      receiverLastName: '',
      message: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { cardDetails: card } = purchase;

  const cardFrontImage = useMemo(() => {
    return getSelectedProfileImage(card, selectedProfileId);
  }, [card, selectedProfileId]);

  const next = () => {
    navigate('/purchase/customise');
  };

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    purchase.setNewPurchaseDetails({
      ...purchase.newPurchaseDetails,
      ...data,
    });
    next();
  };

  useEffect(() => {
    RudderStack.page('Purchase_Personalise');
  }, []);

  useEffect(() => {
    const defValues = {
      from: purchase.newPurchaseDetails.from || '',
      receiverFirstName: purchase.newPurchaseDetails.receiverFirstName || '',
      receiverLastName: purchase.newPurchaseDetails.receiverLastName || '',
      message: purchase.newPurchaseDetails.message || '',
    };
    reset(defValues);
  }, [
    reset,
    purchase.newPurchaseDetails.from,
    purchase.newPurchaseDetails.message,
    purchase.newPurchaseDetails.receiverFirstName,
    purchase.newPurchaseDetails.receiverLastName,
  ]);

  useEffect(() => {
    setSelectedProfileId(purchase.newPurchaseDetails.profileId || 0);
  }, [purchase.newPurchaseDetails.profileId]);

  const backLink = '/purchase/set-value';
  const isBranded = isBrandMerchant(card);

  return (
    <Body
      theme="white"
      disableFooter
      headerDisableShopNow
      headerVariant="purchase"
      displayMobileMenu={false}>
      <div className="content">
        <div className="purchase-personalise">
          <PurchaseBack isBranded={isBranded} backLink={backLink} />
          <PurchaseWrapper
            giftCard={card}
            cardImage={cardFrontImage}
            showBackButton={false}
            waveBackLink={backLink}>
            <div className="purchase-flow-header">Personalise gift</div>
            <div className="purchase-flow-description">
              Tell us who it's to, from & add a thoughtful, personalised
              message.
            </div>
            <div className="customiseInputItem">
              <Controller
                control={control}
                rules={{ required: true, pattern: NO_LINK_TEXT_REGEX }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Input
                      label="Recipient first name"
                      onChange={onChange}
                      onBlur={() => {
                        onChange(value?.trim());
                        onBlur();
                      }}
                      error={!!errors.receiverFirstName}
                      value={value}
                      maxLength={NAME_CHAR_LIMIT}
                      type="text"
                    />
                    {errors.receiverFirstName && (
                      <div className="purchase-error-text">
                        {errors.receiverFirstName?.type === 'pattern' && (
                          <ErrorText text="Recipient first name contains invalid characters" />
                        )}
                        {errors.receiverFirstName?.type === 'required' && (
                          <ErrorText text="Please enter the recipient's first name" />
                        )}
                      </div>
                    )}
                  </>
                )}
                name="receiverFirstName"
              />
            </div>

            <div className="customiseInputItem">
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: NO_LINK_TEXT_REGEX,
                  minLength: 2,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Input
                      label="Recipient last name"
                      onChange={onChange}
                      onBlur={() => {
                        onChange(value?.trim());
                        onBlur();
                      }}
                      error={!!errors.receiverLastName}
                      value={value}
                      maxLength={NAME_CHAR_LIMIT}
                      type="text"
                    />
                    {errors.receiverLastName && (
                      <div className="purchase-error-text">
                        {errors.receiverLastName?.type === 'pattern' && (
                          <ErrorText text="Recipient last name contains invalid characters" />
                        )}
                        {errors.receiverLastName?.type === 'required' && (
                          <ErrorText text="Please enter the recipient's last name" />
                        )}
                        {errors.receiverLastName?.type === 'minLength' && (
                          <ErrorText text="A minimum of 2 characters is required" />
                        )}
                      </div>
                    )}
                  </>
                )}
                name="receiverLastName"
              />
            </div>

            <div className="customiseInputItem">
              <Controller
                control={control}
                rules={{ required: true, pattern: NO_LINK_TEXT_REGEX }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Input
                      label="Sender name"
                      onChange={onChange}
                      onBlur={() => {
                        onChange(value?.trim());
                        onBlur();
                      }}
                      error={!!errors.from}
                      value={value}
                      maxLength={NAME_CHAR_LIMIT}
                      type="text"
                    />
                    {errors.from && (
                      <div className="purchase-error-text">
                        {errors.from?.type === 'pattern' && (
                          <ErrorText text="Sender name contains invalid characters" />
                        )}
                        {errors.from?.type === 'required' && (
                          <ErrorText text="Please enter the Sender Name" />
                        )}
                      </div>
                    )}
                  </>
                )}
                name="from"
              />
            </div>

            <div className="customiseInputItem">
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <TextAreaInput
                      label="Your message..."
                      onChange={onChange}
                      onBlur={() => {
                        onChange(value?.trim());
                        onBlur();
                      }}
                      error={!!errors.message}
                      value={value}
                      style={{
                        height: 200,
                      }}
                      maxLength={MAX_MESSAGE_LENGTH}
                    />
                    <div className="messageCount">
                      {value.length || 0}/{MAX_MESSAGE_LENGTH}
                    </div>
                    {errors.message && (
                      <div className="purchase-error-text">
                        {errors.message?.type === 'pattern' && (
                          <ErrorText text="Message contains invalid characters" />
                        )}
                        {errors.message?.type === 'required' && (
                          <ErrorText text="Please write a personal message to the recipient" />
                        )}
                      </div>
                    )}
                  </>
                )}
                name="message"
              />
            </div>

            <Alert
              title="This gift will be delivered in the form of a digital gift card. "
              role="Info"
              icon={Info}
            />

            <Button
              title="CONTINUE TO CUSTOMISE"
              role="Primary"
              onClick={handleSubmit(onSubmit)}
            />
          </PurchaseWrapper>
        </div>
      </div>
    </Body>
  );
};

export default Personalise;
