import React, { useState } from 'react';
import styled from 'styled-components';
import Copy from '../../../assets/svg/my-cards/Copy.svg';

type Props = {
  text: string;
  desc: string;
};

type ICopyContainerProps = {
  isCopy: boolean;
};

const LeftContainer = styled.div``;

const CardDetailsItemContent = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.4rem;
`;

const CardDetailsItemDescription = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${(props) => props.theme.colours.line1};
`;

const CopyContainer = styled.div<ICopyContainerProps>`
  display: flex;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
  position: relative;

  img {
    opacity: ${(props) => (props.isCopy ? '0' : '0.4')};
    transition: 0.5s ease;
  }
  &::after {
    width: ${(props) => (props.isCopy ? '70px' : '0')};
    transition: 0.5s ease;
    font-weight: bold;
    font-size: 1.4rem;
    content: 'COPIED!';
  }
`;

const CardDetailsItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  align-items: center;
  padding: 1.5rem;
  border-radius: 8px;
  width: calc(100% + 3rem);
  position: relative;
  left: -1.5rem;
  &:hover {
    background: ${(props) => props.theme.colours.grey25};
    cursor: pointer;
  }
`;

const onCopy = (text: string) => {
  navigator.clipboard.writeText(text);
};

const CardDetailsItem = ({ text, desc }: Props) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  return (
    <CardDetailsItemContainer
      data-recording-sensitive
      onClick={() => {
        onCopy(text);
        setIsCopy(true);
        setTimeout(() => {
          setIsCopy(false);
        }, 2000);
      }}>
      <LeftContainer>
        <CardDetailsItemContent>{text}</CardDetailsItemContent>
        <CardDetailsItemDescription>{desc}</CardDetailsItemDescription>
      </LeftContainer>
      <CopyContainer isCopy={isCopy}>
        <img src={Copy} alt="Copy" />
      </CopyContainer>
    </CardDetailsItemContainer>
  );
};

export default CardDetailsItem;
