import React, { useEffect, useMemo, useRef } from 'react';
import { useActivation } from '../../../context/ActivationProvider';
import { ReactComponent as Border } from '../../../assets/svg/activation/Border.svg';
import { Button } from '../../../components/Button';

import './styles.scss';
import { useNavigate } from 'react-router-dom';
import ActivationTopBar from '../common/Topbar';
import OrderSummary from '../common/OrderSummary';
import Colors from '../../../const/Colors';
import CelebrationAnimation from '../common/CelebrationAnimation';
import ActivationCard from '../common/ActivationCard';
import { RudderStack } from '../../../services/shared/RudderStack';
import {
  LogoWrapper,
  Logo,
  AffiliationText,
  StaticAffilationText,
} from './styles';
import { useActivationBrands } from '../hooks/useActivationBrands';
import {
  ACTIVATION_AFFILIATION_TEXT,
  ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
} from '../constants';
import {
  SHOW_AFFILIATION_TEXT_IN_ANIMATION,
  SHOW_BRAND_LOGO_IN_ANIMATION,
} from '../../../const/shared';

type Props = {};
type Ref = null | HTMLDivElement;

const CardReveal = (props: Props) => {
  const cardRevealRef = useRef<Ref>(null);
  const animationRef = useRef<Ref>(null);
  const activation = useActivation();
  const navigate = useNavigate();

  useEffect(() => {
    RudderStack.page('Activation_CardReveal');
  }, []);

  const frontImage = useMemo(
    () => activation.activationData.activationDetails.frontImage,
    [activation.activationData.activationDetails.frontImage]
  );

  const animationType = useMemo(
    () => activation.activationData.activationDetails.animationType,
    [activation.activationData.activationDetails.animationType]
  );

  const onClick = () => {
    navigate('/activate/set-pin');
  };

  const onAnimationEnd = () => {
    if (cardRevealRef.current && animationRef.current) {
      cardRevealRef.current.style.position = 'unset';
      animationRef.current.style.display = 'none';
    }
  };

  const {
    affiliationTextColour,
    backgroundColor,
    displayAffiliationText,
    logo,
    isBranded,
  } = useActivationBrands();

  const cardName = useMemo(() => {
    return activation.activationData.activationDetails.cardProgramName || '';
  }, [activation.activationData.activationDetails.cardProgramName]);

  const cardValue = useMemo(() => {
    return activation.activationData.activationDetails.value || '';
  }, [activation.activationData.activationDetails.value]);

  const nameToDisplay = useMemo(() => {
    if (isBranded) {
      const baseText = 'Special Card for';
      const brandName = `${baseText} ${cardName}`;
      return displayAffiliationText ? `${brandName}*` : brandName;
    }
    return cardName;
  }, [isBranded, cardName, displayAffiliationText]);

  const title = useMemo(() => {
    return isBranded
      ? `You have received a $${cardValue} ${nameToDisplay}`
      : `You have received a $${cardValue} ${cardName} Gift Card!`;
  }, [nameToDisplay, cardValue, isBranded, cardName]);

  return (
    <div ref={cardRevealRef} className="cardReveal" style={{ backgroundColor }}>
      <ActivationTopBar showBack={false} showFAQ={false} />
      <div ref={animationRef} className="celebrationAnimations">
        <CelebrationAnimation animationType={animationType} />
      </div>
      <div className="revealCard">
        {SHOW_BRAND_LOGO_IN_ANIMATION && (
          <LogoWrapper>
            <Logo src={logo || ''} alt="" />
          </LogoWrapper>
        )}
        <div className="woohoo">
          <div className="text">WOOHOO!!</div>
        </div>
        <div className="revealCardWrapper">
          <ActivationCard />
        </div>
      </div>
      {SHOW_AFFILIATION_TEXT_IN_ANIMATION && displayAffiliationText && (
        <AffiliationText
          style={{
            color:
              affiliationTextColour ||
              ACTIVATION_DEFAULT_AFFILIATION_TEXT_COLOR,
          }}>
          {ACTIVATION_AFFILIATION_TEXT}
        </AffiliationText>
      )}
      <Border height="62" fill={Colors.white} className="scallop" />
      <div onAnimationEnd={onAnimationEnd} className="details">
        <div className="detailsWrapper">
          <div className="title">{title}</div>
          <OrderSummary
            name={nameToDisplay}
            image={isBranded ? logo || '' : frontImage || ''}
            value={parseInt(cardValue)}
            isBranded={isBranded}
          />
          <div className="outro">
            One last step to activate your card, we promise.
          </div>

          <div className="setPinButton">
            <Button
              title="SET PIN TO ACTIVATE CARD"
              role="Primary"
              onClick={onClick}
            />
            {displayAffiliationText && (
              <StaticAffilationText>
                {ACTIVATION_AFFILIATION_TEXT}
              </StaticAffilationText>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardReveal;
