import { CardBalance } from './../generated/api';
export const insertTextToString = (
  text: string,
  position: number,
  textToInsert: string
) => {
  return [text.slice(0, position), textToInsert, text.slice(position)].join('');
};

export const partialHidePhone = (phone: string, maskString: string) => {
  let partialHiddenPhone =
    phone.slice(0, 3) + phone.slice(3).replace(/.(?=...)/g, maskString);
  partialHiddenPhone = insertTextToString(partialHiddenPhone, 6, ' ');
  partialHiddenPhone = insertTextToString(partialHiddenPhone, 10, ' ');
  return partialHiddenPhone;
};

// retrieve AUD only for now
export const getMoneyDisplay = (
  cardBalances: CardBalance[] | null | undefined
) => {
  if (!Array.isArray(cardBalances)) {
    return '';
  }
  const aud = cardBalances.find(
    (x) => x.availableBalance?.currencyCode === 'AUD'
  );
  const balance = aud?.availableBalance?.amount ?? 0;
  return `${balance < 0 ? '-' : ''} $${Math.abs(balance).toFixed(2)}`;
};
